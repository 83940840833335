import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { Row, Col, Label, FormGroup, Button, UncontrolledPopover, PopoverBody } from "reactstrap";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Accordion } from "react-bootstrap";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { TbLoader } from "react-icons/tb";
import CustomizationService from "src/modules/3rd-party-management/apis/CustomizationService";
import BackgroundImage from "src/assets/images/background.svg";
import { reloadOrganizationActiveModuleSettingsFromServer } from "src/store/actions";
import Dropzone from "react-dropzone";
import AttachmentCard from "src/modules/3rd-party-management/components/MessageBox/components/AttachmentCard";

const TPDDCompanyBackgroundCustomization = (props) => {
  const { t, fetchOrganizationDetails, reloadActiveModuleSettings } = props;
  const details = fetchOrganizationDetails.data;
  const [signinImage, setSigninImage] = useState(details?.signin_picture);
  const [landingImage, setLandingImage] = useState(details?.landing_picture);

  const dropzoneStyle = {
    background: "#fff",
    border: "none",
    fontSize: "20px",
    paddingRight: 0,
    fontWeight: "400",
  };

  const prevImageStyle = {
    marginTop: "10px",
    maxWidth: "100%",
    maxHeight: "200px",
  };

  /*********************************** FUNCTIONS ***************************************** */

  // Submit Information
  const handleSubmitMutation = useMutation({
    mutationFn: async () => {
      const formData = new FormData();
      // Append logos to formData

      if (signinImage.length > 0 && typeof signinImage[0] === "object") {
        formData.append("signInBackground", signinImage[0]);
      }

      if (landingImage.length > 0 && typeof landingImage[0] === "object") {
        formData.append("landingBackground", landingImage[0]);
      }

      const service = CustomizationService.getInstance();
      return await service.editBackgrounds(formData);
    },
    onSuccess: () => {
      reloadActiveModuleSettings();
      toast(t("Data saved successfully."), {
        type: "success",
      });
    },
    onError: () => {
      toast(t("Failed to save data."), {
        type: "error",
      });
    },
  });

  // Restore Information
  const restore = () => {
    setSigninImage(details?.signin_picture);
    setLandingImage(details?.landing_picture);
  };

  // Submiting files
  const handleAcceptedFiles = (type, selectedFile) => {
    const file = selectedFile[0];
    return type === "signin"
      ? setSigninImage([file])
      : type === "landing"
      ? setLandingImage([file])
      : null;
  };

  // Delete file
  const handleClickDeleteFiles = (type) => {
    return type === "signin"
      ? setSigninImage([])
      : type === "landing"
      ? setLandingImage([])
      : null;
  };

  /*************************************** VIEW ****************************************** */
  return (
    <Row>
      <div className="page-tpdd-customization">
        <Row>
          <Col className="d-flex align-items-start customization-image-col">
            <img src={BackgroundImage} alt="Background" />
          </Col>
          <Col className="customization-accordion-col">
            <Accordion defaultActiveKey="0">
              <Accordion.Item
                className="border-none cusom-accordion-item"
                eventKey="0"
              >
                <Accordion.Header className="custom-accordion-header">
                  <div className="p-1 custom-div-header">
                    {`5. ${t("Background")}`}{" "}
                    <>
                      <span
                        id={`section-info-button-font`}
                        className="section-info-button"
                      >
                        <i className="ri-information-line" />
                      </span>

                      <UncontrolledPopover
                        placement="bottom"
                        trigger="hover"
                        target={`section-info-button-font`}
                      >
                        <PopoverBody>
                          <span>
                            {t(
                              "this is how it looks after uploading. uploader is like the one used in icon part"
                            )}
                          </span>
                        </PopoverBody>
                      </UncontrolledPopover>
                    </>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col lg="6" md="6" sm="12" className="mt-3">
                      <FormGroup>
                        <Label for="fontFamily">
                          {t("Sign In Background Picture") + ":"}
                        </Label>
                        <Dropzone
                          onDrop={(acceptedFiles) =>
                            handleAcceptedFiles("signin", acceptedFiles)
                          }
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()} className="dropzone tpdd">
                              <input {...getInputProps()} />
                              <Button style={dropzoneStyle} color="#505D69">
                                <i className="ri-attachment-line"></i>
                              </Button>
                            </div>
                          )}
                        </Dropzone>

                        {signinImage?.length ? (
                          typeof signinImage === "string" ? (
                            <img src={signinImage} style={prevImageStyle} />
                          ) : (
                            <Col xs={12} className="p-3">
                              <div className="d-flex flex-wrap gap-2">
                                <AttachmentCard
                                  attachment={signinImage[0]}
                                  handleClickDeleteFile={() =>
                                    handleClickDeleteFiles("signin")
                                  }
                                  t={t}
                                />
                              </div>
                            </Col>
                          )
                        ) : null}
                      </FormGroup>
                    </Col>

                    <Col lg="6" md="6" sm="12" className="mt-3">
                      <FormGroup>
                        <Label for="fontFamily">
                          {t("Landing Background Picture") + ":"}
                        </Label>
                        <Dropzone
                          onDrop={(acceptedFiles) =>
                            handleAcceptedFiles("landing", acceptedFiles)
                          }
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()} className="dropzone tpdd">
                              <input {...getInputProps()} />
                              <Button style={dropzoneStyle} color="#505D69">
                                <i className="ri-attachment-line"></i>
                              </Button>
                            </div>
                          )}
                        </Dropzone>

                        {landingImage?.length ? (
                          typeof landingImage === "string" ? (
                            <img src={landingImage} style={prevImageStyle} />
                          ) : (
                            <Col xs={12} className="p-3">
                              <div className="d-flex flex-wrap gap-2">
                                <AttachmentCard
                                  attachment={landingImage[0]}
                                  handleClickDeleteFile={() =>
                                    handleClickDeleteFiles("landing")
                                  }
                                  t={t}
                                />
                              </div>
                            </Col>
                          )
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                </Accordion.Body>
                <Row className="p-4">
                  <Col sm="12" className="d-flex justify-content-end">
                    <Button
                      onClick={restore}
                      color="primary"
                      type="button"
                      outline
                      className="me-2"
                      disabled={
                        handleSubmitMutation.isLoading ||
                        fetchOrganizationDetails.isLoading ||
                        fetchOrganizationDetails.isFetching
                      }
                    >
                      {t("Restore")}
                    </Button>

                    <Button
                      color="primary"
                      onClick={handleSubmitMutation.mutate}
                      type="submit"
                      disabled={
                        handleSubmitMutation.isLoading ||
                        fetchOrganizationDetails.isLoading ||
                        fetchOrganizationDetails.isFetching
                      }
                    >
                      {handleSubmitMutation.isLoading ? <TbLoader /> : t("Save")}
                    </Button>
                  </Col>
                </Row>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </div>
    </Row>
  );
};

const mapStatetoProps = (state) => {
  const { token } = state.Login;
  const organization = state.Organization;
  const App = state.App;
  const modules = state.Modules;
  return { organization, token, App, modules };
};

const mapDispachToProps = (dispach) => {
  return {
    reloadActiveModuleSettings: () =>
      dispach(reloadOrganizationActiveModuleSettingsFromServer()),
  };
};

export default withNamespaces()(
  withRouter(
    connect(
      mapStatetoProps,
      mapDispachToProps
    )(TPDDCompanyBackgroundCustomization)
  )
);
