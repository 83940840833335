import React from "react";
import axios from "axios";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
} from "reactstrap";
import { TbLoader } from "react-icons/tb";
import { errorNotification } from "../../../../store/actions";
import { 
  API_URL_ADMIN_DOWNLOAD_DASHBOARD_STATISTICS, 
  CM_API_URL_ADMIN_DOWNLOAD_DASHBOARD_STATISTICS 
} from "../../../../common/constants";

class DownloadStatistics extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      headers: {
        headers: {
          Authorization: `Bearer ${this.props.token}`,
        },
        responseType: "blob",
      },
      spinner: {
        id: "",
        active: false,
      },
      dropdown: {
        id: "",
        active: false,
      },
      activeWidgets: [
        {
          value: "all",
          title: "All",
        },
        // {
        // 	value: "top-5-categories",
        // 	title: "Top 5 categories",
        // },
        // {
        // 	value: "number-of-cases",
        // 	title: "Number of cases",
        // },
      ],
    };
  }

  handleClickDownloadStats = (id, name) => {
    if (this.state.spinner.active) return;
  
    this.setState({
      spinner: {
        id: `download-file-${id}`,
        active: true,
      },
    });
    
    const activeModule = localStorage.getItem('module');

    axios
      .get(
        `${activeModule === 'cm' ? CM_API_URL_ADMIN_DOWNLOAD_DASHBOARD_STATISTICS : API_URL_ADMIN_DOWNLOAD_DASHBOARD_STATISTICS }${
          this.props.filterQuery ? "?" + this.props.filterQuery : ""
        }`,
        this.state.headers
      )
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob.data]));
        const link = document.createElement("a");

        link.href = url;
        link.setAttribute("download", `dashboard-${Date.now()}.xlsx`);

        document.body.appendChild(link);

        link.click();
        link.parentNode.removeChild(link);

        this.setState({
          spinner: {
            id: "",
            active: false,
          },
        });
      })
      .catch((error) => {
        if (error.response) {
          const data = error.response.data;

          this.props.errorNotification({
            code: data.error || "unknown_error",
            message: this.props.t(data.message || data.title),
          });
        }

        this.setState({
          spinner: {
            id: "",
            active: false,
          },
        });
      });

    // ******************************************
    // axios
    // 	.get(`${API_URL_ADMIN_DOWNLOAD_DASHBOARD_STATISTICS}`, this.state.headers)
    // 	.then((blob) => {
    // 		const file = new Blob([blob.data], { type: "application/pdf" });
    // 		const fileURL = URL.createObjectURL(file);

    // 		window.open(fileURL);
    // 	})
    // 	.catch((error) => {
    // 		if (error.response) {
    // 			const data = error.response.data;

    // 			this.props.errorNotification({
    // 				code: data.error || "unknown_error",
    // 				message: this.props.t(data.message || data.title),
    // 			});
    // 		}
    // 	});

    // axios
    // 	.get(`${API_URL_ADMIN_DOWNLOAD_DASHBOARD_STATISTICS}?createdAt[from]=&createdAt[to]=&date=`, this.state.headers) // ?createdAt[from]=&createdAt[to]=&date=
    // 	.then((blob) => {
    // 		console.log(blob);

    // 		const url = window.URL.createObjectURL(new Blob([blob.data]));
    // 		const link = document.createElement("a");

    // 		link.href = url;
    // 		link.setAttribute("download", `dashboard.pdf`);

    // 		// Append to html link element page
    // 		document.body.appendChild(link);

    // 		// Start download
    // 		link.click();

    // 		// Clean up and remove the link
    // 		link.parentNode.removeChild(link);

    // 		this.setState({
    // 			spinner: {
    // 				id: `download-file-${id}`,
    // 				active: false,
    // 			},
    // 		});
    // 	})
    // 	.catch((error) => {
    // 		if (error.response) {
    // 			const data = error.response.data;

    // 			this.props.errorNotification({
    // 				code: data.error || "unknown_error",
    // 				message: this.props.t(data.message || data.title),
    // 			});
    // 		}

    // 		this.setState({
    // 			spinner: {
    // 				id: `download-file-${id}`,
    // 				active: false,
    // 			},
    // 		});
    // 	});
  };

  render() {
    return (
      <>
        <div style={{ textAlign: "end" }}>
          <div
            style={{
              display: "inline-flex",
              position: "relative",
              textAlign: "left",
            }}
          >
            <UncontrolledDropdown>
              <DropdownToggle
                color="primary"
                onClick={() => {
                  this.handleClickDownloadStats(0, "all");
                }}
              >
                {this.state.spinner.active ? (
                  <TbLoader />
                ) : (
                  this.props.t("Download")
                )}
              </DropdownToggle>
              {/* <DropdownMenu> */}
              {/* <DropdownItem header>{this.props.t("Dashboard Statistics")}</DropdownItem> */}
              {/* {this.state.activeWidgets.map((item, index) => (
                  <DropdownItem text key={`download-file-${index}`}>
                    <div
                      className="d-flex"
                      style={{
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span
                        onClick={() =>
                          this.handleClickDownloadStats(index, item.value)
                        }
                        style={{
                          width: "100%",
                          cursor: "pointer",
                          userSelect: "none",
                          fontSize: 14,
                        }}
                      >
                        {this.props.t(item.title)}
                      </span>
                      {this.state.spinner.id === `download-file-${index}` &&
                        this.state.spinner.active && (
                          <Spinner size="sm" color="primary" />
                        )}
                    </div>
                  </DropdownItem>
                ))} */}
              {/* </DropdownMenu> */}
            </UncontrolledDropdown>
          </div>
        </div>
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  const { token } = state.Login;

  return { token };
};

const mapDispachToProps = (dispach) => {
  return {
    errorNotification: (error) => dispach(errorNotification(error)),
  };
};

export default withNamespaces()(
  withRouter(connect(mapStatetoProps, mapDispachToProps)(DownloadStatistics))
);
