import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import {
    MODULE_TPDD_ACTION_TYPE_SAVE_QUESTIONNAIRE_CURRENT_STATE
} from "./actionTypes";

import {
    moduleTPDDUpdateQuestionnaireAllQuestionsAnsweredState,
    moduleTPDDUpdateQuestionnaireUnsavedStepsCount,
    moduleTPDDUpdateLatestQuestionnaireSaveStatus
} from "./actions";

import axios from "axios";

import {
    API_BASE_URL
} from "../../constants";

import store from "src/store";

const sendQuestionnaireSaveRequest = async ({
    reportCaseId,
    payload,
    token
}) => {
    return await axios.post(`${API_BASE_URL}/report/${ reportCaseId }/edit`, payload, {
        headers: {
            Authorization: `Bearer ${ token }`,
        }
    });
}

function* saveQuestionnaire() {
    const { 
        moduleTPDDQuestionnaire :   {
            payload : questionnaireData,
            unSavedStepsCount,
            reportCaseId,
            latestSaveRequestStatus,
            finalRequest
        },
        Login : {
            token
        }
    } = store.getState();

    if(unSavedStepsCount > 0 && questionnaireData && (latestSaveRequestStatus !== 'sending' || finalRequest)){
        yield put(moduleTPDDUpdateLatestQuestionnaireSaveStatus({
            status  :   'sending'
        }));

        const prevCount = unSavedStepsCount;

        try {
            const response = yield call(
                sendQuestionnaireSaveRequest,
                {
                    reportCaseId    :   reportCaseId,
                    payload         :   questionnaireData,
                    token           :   token
                }
            );

            yield put(moduleTPDDUpdateQuestionnaireUnsavedStepsCount({
                count   :   store.getState().moduleTPDDQuestionnaire.unSavedStepsCount - prevCount
            }));

            yield put(moduleTPDDUpdateQuestionnaireAllQuestionsAnsweredState({
                status  :   finalRequest
            }));
            
            // yield put(moduleTPDDUpdateQuestionnaireAllQuestionsAnsweredState({
            //     status  :   response.data.data.isReportComplete
            // }));

            yield put(moduleTPDDUpdateLatestQuestionnaireSaveStatus({
                status  :   'succeed'
            }));
        }
        catch(error){
            yield put(moduleTPDDUpdateLatestQuestionnaireSaveStatus({
                status  :   'failed'
            }));
        }
    }
}

export function* watchSaveQuestionnaireState() {
  yield takeEvery(MODULE_TPDD_ACTION_TYPE_SAVE_QUESTIONNAIRE_CURRENT_STATE, saveQuestionnaire);
}

function* moduleTPDDQuestionnaireSaga() {
  yield all([
    fork(watchSaveQuestionnaireState)
  ]);
}

export { 
    moduleTPDDQuestionnaireSaga
};
