import React, { useState } from "react";
import { withNamespaces } from "react-i18next";
import { Dropdown, DropdownToggle, DropdownMenu, Button } from "reactstrap";

import Form from "react-bootstrap/Form";
import { Nav, NavItem, NavLink } from "reactstrap";

import { icons} from "./constants";
import { connect } from "react-redux";
import { REPORT_STATUS_LIST } from "../../../../../../components/constants";
const TableFilter = ({
  t,
  onFilterChanged,
  onOkButtonClicked,
  defaultValues,
  categories,
  isAdmin
}) => {
  const [filters, setFilters] = useState({
    status: defaultValues.status || null,
    category: defaultValues.category || null,
    risk: defaultValues.risk || null,
  });

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("Category");

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const renderCategoryFilterList = () => {
    const result = [];
  
    for (const category of categories) {
      result.push(
        <div className="filter-item" key={category.id}>
          <Form.Check
            type="radio"
            id={`category-${category.id}-filter`}
            checked={filters.category === category.id}
            onChange={() => {
              setFilters((prevFilters) => ({
                ...prevFilters,
                category: category.id,
              }));
            }}
            label={t(category.title)}
          />
        </div>
      );
    }
  
    return result;
  };
  
  const renderStatusFilterList = () => {
    const result = [];
  
    for (const status of REPORT_STATUS_LIST) {
      result.push(
        <div className="filter-item" key={status.id}>
          <Form.Check
            type="radio"
            id={`status-${status.id}-filter`}
            checked={filters.status === status.id}
            onChange={() => {
              setFilters((prevFilters) => ({
                ...prevFilters,
                status: status.id,
              }));
            }}
            label={t(status.titleKey)}
          />
        </div>
      );
    }
  
    return result;
  };
  
  const renderRiskFilterList = () => {

    const riskRates = [
       {
         value: "",
         baseLabel: "All Risk",
         label: t("All Risk"),
       },
       {
         value: "high",
         baseLabel: "High",
         label: t("High"),
       },
       {
         value: "medium",
         baseLabel: "Medium",
         label: t("Medium"),
       },
       {
         value: "low",
         baseLabel: "Low",
         label: t("Low"),
       },
     ];

 
   // Render checkboxes
   return riskRates.map((item) => {
     return (
       <div className="filter-item" key={item.value}>
         <Form.Check
           type="radio"
           id={`risk-${item.value}-filter`}
           checked={filters.risk === item.value}
           onChange={() => {
             setFilters((prevFilters) => ({
               ...prevFilters,
               risk: item.value,
             }));
           }}
           label={item.label}
         />
       </div>
     );
   });
 };
 

  const renderNavContent = () => {
    switch (activeTab) {
      case "Category":
        return renderCategoryFilterList();

      case "Status":
        return renderStatusFilterList();

        case "Risk":
          return renderRiskFilterList();

      default:
        return null;
    }
  };




  return (
    <div className="custom-table-filter">
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle caret color="secondary" outline>
          <i className="ri-filter-3-fill"></i>
          <span>{t("Filter")}</span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-end">
          <div className="filters-container">

            <div className="navs-container d-flex justify-content-center">
              <Nav tabs>

                <NavItem
                  onClick={() => {
                    setActiveTab("Category");
                  }}
                  className={`${activeTab === "Category" && "active"}`}
                >
                  <NavLink>
                    <img
                      src={
                        activeTab === "Category"
                          ? icons.Category.active
                          : icons.Category.default
                      }
                      alt="category-filter"
                    />
                    <span className="mt-1">{t("Category")}</span>
                  </NavLink>
                </NavItem>
                {isAdmin  && 
                <NavItem
                  onClick={() => {
                    setActiveTab("Risk");
                    
                  }}
                  className={`${activeTab === "Risk" && "active"}`}
                >
                  <NavLink>
                    <img
                      src={
                        activeTab === "Risk"
                          ? icons.Risk.active
                          : icons.Risk.default
                      }
                      alt="risk-filter"
                    />
                    <span>{t("Risk")}</span>
                  </NavLink>
                </NavItem>}

                <NavItem
                  onClick={() => setActiveTab("Status")}
                  className={`${activeTab === "Status" && "active"}`}
                >
                  <NavLink>
                    <img
                      src={
                        activeTab === "Status"
                          ? icons.Status.active
                          : icons.Status.default
                      }
                      alt="status-filter"
                    />
                    <span>{t("Status")}</span>
                  </NavLink>
                </NavItem>

              

              </Nav>
            </div>

            <div className="navs-content-container">{renderNavContent()}</div>

            <div className="actions-container d-flex align-items-center justify-content-end">
            <Button
                color="secondry"
                size="sm"
                onClick={() => {
                 setFilters(  {  status: null,
                  category: null,
                })}}
              >
                {t("Reset")}
              </Button>

              <Button
                color="primary"
                size="sm"
                onClick={() => {
                  onOkButtonClicked && onOkButtonClicked(filters);
                  toggle();
                }}
              >
                {t("OK")}
              </Button>

            </div>
          </div>
        </DropdownMenu>
      </Dropdown>

    </div>
  );
};

const mapStatetoProps = (state) => {
  const { token } = state.Login;
  const { App, Feature, CMFeature } = state;

  const activeModule = localStorage.getItem("module");

  return {
    token,
    Feature:
      activeModule === "wb"
        ? Feature
        : activeModule === "cm"
        ? CMFeature
        : null,
  };
};


export default withNamespaces()(
    connect(mapStatetoProps)(TableFilter)
);
