import React, { Component } from "react";
import { Row, Col, Card, CardBody, CardTitle, Tooltip } from "reactstrap";
import { withNamespaces } from "react-i18next";

//Import Charts
import ReactApexChart from "react-apexcharts";
import "./dashboard.scss";

const defaultColors = [
  "#5664d2",
  "#1cbb8c",
  "#eeb902",
  "#FF7F50",
  "#9932CC",
  "#90A4AE",
];

class SalesAnalytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      series: [],
      options: {
        labels: [],
        plotOptions: {
          pie: {
            donut: {
              size: "75%",
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        colors: defaultColors,
      },
      months: [
        {
          name: "june",
          title: "June",
        },
      ],
      categories: [],
      topFiveCats: false,
    };
  }

  render() {
    return (
      <React.Fragment>
        <Card>
          <CardBody>
            {/*<div className="float-end">*/}
            {/*    <select className="form-select form-select-sm">*/}
            {/*        {*/}
            {/*            this.state.months.map((month, index) => {*/}
            {/*                return (*/}
            {/*                    <option key={ index } value={ month.name }>{ month.title }</option>*/}
            {/*                )*/}
            {/*            })*/}
            {/*        }*/}
            {/*    </select>*/}
            {/*</div>*/}
            {/* <div className="mb-4">
              <CardTitle>
                <div style={{ display: "flex", flexFlow: "row wrap" }}>
                  <div>{this.props.t("Top 5 Categories")}</div>
                  <div className="ms-2" style={{ alignSelf: "flex-end" }}>
                    <i
                      className="fa fa-info-circle"
                      aria-hidden="true"
                      id="infoIconU"
                    />
                    <Tooltip
                      placement="top"
                      isOpen={this.state.topFiveCats}
                      target="infoIconU"
                      toggle={() =>
                        this.setState({
                          topFiveCats: !this.state.topFiveCats,
                        })
                      }
                    >
                      {this.props.t(
                        "This chart draws the categories that the people reported the most"
                      )}
                    </Tooltip>
                  </div>
                </div>
              </CardTitle>
            </div> */}
            <div className="mb-4">
              <CardTitle>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>{this.props.t("Top 5 Categories")}</div>
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    id="infoIconU"
                  />
                  <Tooltip
                    placement="top"
                    isOpen={this.state.topFiveCats}
                    target="infoIconU"
                    toggle={() =>
                      this.setState({
                        topFiveCats: !this.state.topFiveCats,
                      })
                    }
                  >
                    {this.props.t(
                      "This chart draws the categories that the people reported the most"
                    )}
                  </Tooltip>
                </div>
              </CardTitle>
            </div>

            <div className="mb-4">
              <div id="donut-chart" className="apex-charts mb-3">
                <ReactApexChart
                  options={this.state.options}
                  series={this.state.series}
                  type="donut"
                  height="250"
                />
              </div>

              <Row>
                {/* {console.log("this.state.categories")} */}
                {/* {console.log(this.state.categories)} */}
                {this.state.categories.map((category, index) => {
                  return (
                    <Col key={index} xs={4}>
                      <div className="text-center mt-4">
                        <p className="mb-2 text-truncate">
                          <i
                            className="mdi mdi-circle font-size-10 me-1"
                            style={{ color: defaultColors[index] }}
                          ></i>
                          {this.props.t(category.title)}
                        </p>
                        <h5>{category.percent} %</h5>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.categories !== this.props.categories) {
      const categories = this.props.categories;
      const series = categories.map((category) => category.amount);
      const labels = categories.map((category) => category.title);
      this.setState({
        categories: categories,
        series: series,
        options: {
          ...this.state,
          labels: labels,
        },
      });
    }
  }
}

export default withNamespaces()(SalesAnalytics);
