import { memo } from "react";
import { withNamespaces } from "react-i18next";
import { TbLoader } from "react-icons/tb";
import {
  Button,
  Form,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

const DeleteMeasureConfirmationModal = (props) => {
  const { modal, setModal, t, id, deleteMutation } = props;
  const toggle = () => setModal(!modal);

  /************************ Delete Measure *************************** */
  const deleteMeasure = () => {
    deleteMutation.mutate(id);
  };

  /***************************** RENDER ****************************** */

  return (
    <>
      <Modal size="lg" scrollable={true} isOpen={modal} backdrop="static">
        <ModalHeader toggle={toggle}>{t("Delete measure?")}</ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Label>{t("Are you sure?")}</Label>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            className="waves-effect waves-light"
            type="submit"
            onClick={deleteMeasure}
          >
            {deleteMutation.isLoading ? <TbLoader /> : t("Delete")}
          </Button>
          <Button
            color="secondary"
            className="waves-effect waves-light"
            type="button"
            onClick={toggle}
          >
            {t("Cancel")}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default withNamespaces()(memo(DeleteMeasureConfirmationModal));
