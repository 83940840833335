import axios from 'axios';

import {
    API_BASE_URL
} from '../constants/index';

export default class SupplierHistoryLogService {
	static instance;

	static getInstance() {
		if (!SupplierHistoryLogService.instance) {
			SupplierHistoryLogService.instance = new SupplierHistoryLogService();
		}

		return SupplierHistoryLogService.instance;
	}

    async fetchLogs(supplierId, params = {}){
        const response = await axios.get(`${API_BASE_URL}/supplier/${supplierId}/logs`, {
            params  :   params,
            headers :   {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`
            },
        });
        return response.data.data;
    }   

    async fetchDetails(supplierId, logId, params){
        const response = await axios.get(`${API_BASE_URL}/log/${logId}/show`, {
            params  :   params,
            headers :   {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`
            },
        });
        
        return response.data.data;
    }   

    async fetchUsers(supplierId, params = {}){
        const response = await axios.get(`${API_BASE_URL}/users`, {
            params  :   params,
            headers :   {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`
            },
        });
        return response.data.data;
    }
}
