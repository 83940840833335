import { useEffect, useMemo, useRef, useState } from "react";
import { AvField } from "availity-reactstrap-validation";

const ExtendableAvField = (props) => {
  const [isMultiline, setIsMultiline] = useState(false);
  const [value, setValue] = useState("");
  const innerRef = useRef(null);

  useEffect(() => {
    if (!innerRef.current || !value) return undefined;
    const width = innerRef?.current?.offsetWidth;
    const scrollWidth = innerRef?.current?.scrollWidth;
    if (width <= scrollWidth && innerRef.current.type === "text") {
      setIsMultiline(true);
    }
  }, [value]);

  useEffect(() => {
    if (!isMultiline) return undefined;
    innerRef.current.focus();
    innerRef.current.selectionStart = -1;
  }, [isMultiline]);

  return (
    <AvField
      {...props}
      onChange={(e) => {
        const { onChange } = props;
        if(typeof onChange === 'function') {
          onChange(e);
        }
        setValue(e.target.value);
      }}
      innerRef={innerRef}
      type={isMultiline ? "textarea" : "text"}
    />
  );
};

export default ExtendableAvField;
