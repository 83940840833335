import { withNamespaces } from "react-i18next";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import Select from "react-select";
import { useEffect, useState } from "react";
import DataProtectionMailerService from "src/modules/data-protection/apis/DataProtectionMailerService";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";

const EmailSettings = (props) => {
  const { t } = props;
  const service = DataProtectionMailerService.getInstance();
  const [settings, setSettings] = useState({
    smtpHost: "",
    smtpPort: "",
    encryptionType: "",
    username: "",
    password: "",
    fromEmail: "",
    fromName: "",
  });

  const [initialSettings, setInitialSettings] = useState(null);

  /********************************************** FUNCTIONS ***************************************************/
  // Fetch Mailer Settings
  const handleFetchSettings = useQuery({
    queryKey: ["fetch-mailer-settings"],
    queryFn: async () => {
      return await service.fetchMailerSettings();
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      toast(props.t("An error occurred while fetching settings."), {
        type: "error",
      });
    },
  });

  // UseMutation for submitting data
  const submitMutation = useMutation({
    mutationFn: async () => {
      return await service.saveMailerSettings(settings);
    },
    onSuccess: () => {
      toast.success(t("Settings saved successfully."));
    },
    onError: (error) => {
      toast.error(t("An error occurred while saving settings."));
    },
  });

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setSettings((prevSettings) => ({
      ...prevSettings,
      [name]: value,
    }));
  };

  // Handle change for select input (react-select component)
  const handleSelectChange = (selectedOption) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      encryptionType: selectedOption ? selectedOption.value : "",
    }));
  };

  // Submit form data
  const handleSubmit = (e) => {
    e.preventDefault();
    submitMutation.mutate(); // Trigger the mutation function
  };

  // Restore data
  const handleRestore = () => {
    if (initialSettings) {
      setSettings(initialSettings); // Restore to the initial settings
    }
  };

  /********************************************** USE EFFECTS ***************************************************/
  useEffect(() => {
    const { data } = handleFetchSettings;
    if (data) {
      const defaultSettings = {
        smtpHost: data.smtpHost || "",
        smtpPort: data.smtpPort || "",
        encryptionType: data.encryptionType || "",
        username: data.username || "",
        password: "",
        fromEmail: data.fromEmail || "",
        fromName: data.fromName || "",
      };

      setSettings(defaultSettings);
      setInitialSettings(defaultSettings);
    }
  }, [handleFetchSettings.data]);

  /********************************************** UI VIEW ***************************************************/
  return (
    <div className="mb-3">
      <Form inline onSubmit={handleSubmit}>
        {/* **************************************** FIRST ROW ************************************************ */}
        <Row className="mb-3">
          <Col md="6" sm="12">
            <FormGroup>
              <Label for="fullName">{t("Full Name")}</Label>
              <Input
                type="text"
                name="fromName"
                id="fullName"
                value={settings.fromName}
                onChange={handleChange}
              />
            </FormGroup>
          </Col>
          <Col md="6" sm="12">
            <FormGroup>
              <Label for="email">{t("Email")}</Label>
              <Input
                type="email"
                name="fromEmail"
                id="email"
                value={settings.fromEmail}
                onChange={handleChange}
                required
              />
            </FormGroup>
          </Col>
        </Row>

        {/* **************************************** SECOND ROW ************************************************ */}
        <Row className="mb-3">
          <Col md="6" sm="12">
            <FormGroup>
              <Label for={"username"}>{t("Username")}</Label>
              <Input
                type="text"
                name={"username"}
                id={"username"}
                required
                value={settings.username}
                onChange={handleChange}
              />
            </FormGroup>{" "}
          </Col>

          <Col md="6" sm="12">
            <FormGroup>
              <Label for={"password"}>{t("Password")}</Label>
              <Input
                type="password"
                name={"password"}
                id={"password"}
                required
                value={settings.password}
                onChange={handleChange}
              />
            </FormGroup>{" "}
          </Col>
        </Row>

        {/* **************************************** THIRD ROW ************************************************ */}
        <Row className="mb-3">
          <Col md="6" sm="12">
            <FormGroup>
              <Label for={"smtpHost"}>{t("SMTP Host")}</Label>
              <Input
                type="text"
                name={"smtpHost"}
                id={"smtpHost"}
                required
                value={settings.smtpHost}
                onChange={handleChange}
              />
            </FormGroup>{" "}
          </Col>

          <Col md="6" sm="12">
            <FormGroup>
              <Label for={"smtpPort"}>{t("SMTP Port")}</Label>
              <Input
                type="number"
                name={"smtpPort"}
                id={"smtpPort"}
                required
                value={settings.smtpPort}
                onChange={handleChange}
              />
            </FormGroup>{" "}
          </Col>
        </Row>

        {/* **************************************** FORTH ROW ************************************************ */}
        <Row className="mb-3">
          <Col md="6" sm="12">
            <FormGroup>
              <Label for={"encryptionType"}>{t("Encryption Type")}</Label>
              <Select
                id={"encryptionType"}
                name={"encryptionType"}
                options={[
                  { value: "tls", label: t("TLS") },
                  { value: "ssl", label: t("SSL") },
                ]}
                isClearable
                required
                value={
                  settings.encryptionType
                    ? {
                        value: settings.encryptionType,
                        label: t(settings.encryptionType.toUpperCase()),
                      }
                    : null
                }
                onChange={handleSelectChange}
              />
            </FormGroup>{" "}
          </Col>
        </Row>

        {/* **************************************** BUTTONS ************************************************ */}
        <Row className="w-100">
          <div className="d-flex gap-3 mt-4 justify-content-end">
            <Button
              className="restoreBtn"
              disabled={
                handleFetchSettings.isLoading ||
                handleFetchSettings.isFetching ||
                submitMutation.isLoading
              }
              onClick={handleRestore}
              color="primary"
              outline
            >
              {t("Restore")}
            </Button>
            <Button
              className="saveBtn"
              color="primary"
              type="submit"
              disabled={
                handleFetchSettings.isLoading ||
                handleFetchSettings.isFetching ||
                submitMutation.isLoading
              }
            >
              {t("Save")}
            </Button>
          </div>
        </Row>
      </Form>
    </div>
  );
};

export default withNamespaces()(EmailSettings);
