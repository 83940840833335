import { withNamespaces } from 'react-i18next';

import { Col, Row, Button, Label, Card} from 'reactstrap';

import DateUtils from 'src/services/utils/DateUtils';

import { INTERNATIONAL_DATE_FORMAT } from 'src/common/constants';
import { Link } from 'react-router-dom';

const QuestionarConfirmForm = ({
    t,
    questions,
    answers,
    onConfirmButtonClicked,
    onBackButtonClicked,
    settings,
    isFormSubmiting
}) => {

    const dateUtils = new DateUtils();

    const findQuestionAnswers = (question) => {
        const item = (answers.questionAnswers && Array.isArray(answers.questionAnswers)) ? answers.questionAnswers.find((questionData) => {
            return questionData.question === question.id;
        }) : null;

        if(item){
            if(!question?.isReactSubQuestion){
                switch(question.type){
                    case 'textarea':
                    case 'text':
                    case 'date':
                    case 'select':
                    case 'country_list':
                    // case 'legal_entity':
                        return item.answer[0]?.title;
                    case 'legal_entity':
                        return  item.answer?.map(a => <p>{a.title}</p>);
                    case 'checkbox':
                    case 'category':
                    case 'file_upload':
                        return item.answer || [];

                    case 'radiobox':
                        const userAnswer = (item.answer && Array.isArray(item.answer) && item.answer.length > 0) ? item.answer[0] : null;

                        if(userAnswer){
                            const optionBaseInfos = question.options.find((option) => {
                                return option.id === userAnswer.value;
                            });

                            if(optionBaseInfos){
                                return {
                                    value       :   optionBaseInfos.id,
                                    title       :   optionBaseInfos.title,
                                    subTitle    :   (optionBaseInfos.type === 'date' || optionBaseInfos.type === 'text') ? userAnswer.title : null
                                };
                            }
                        }

                    break;
                
                }
            } else {
                const answer = item.answer[0];

                if(answer && answer?.children && Array.isArray(answer.children)){
                    let preFilledSubQuestionData = answer.children.find((subQuestionData) => {
                        return Number.parseInt(subQuestionData.id) === question.id;
                    });

                    if(preFilledSubQuestionData?.type === 'file_upload'){
                        return [{
                            title   :   preFilledSubQuestionData?.title,
                            name    :   preFilledSubQuestionData?.name
                        }];
                    }

                    return preFilledSubQuestionData?.title;
                }
            }
        }

        return null;
    }

    return (
        <Row>
            <Col sm="12">
                {
                    questions.map((question, index) => {
                        const {
                            title, type
                        } = question;

                        const userAnswers = findQuestionAnswers(question);

                        if (type === "textarea" || type === "text" || type === "country_list" || type === "select" || type === "legal_entity") {
                            return (
                                <Row key={ index } className="mb-2">
                                    <Col sm="12">
                                        <Label className="form-label">
                                            {t(title)}
                                        </Label>
                                        <p className="text-answers">
                                            {userAnswers}
                                        </p>
                                    </Col>
                                </Row>
                            );
                        }
                        else if(type === "date"){
                            return (
                                <Row key={ index } className="mb-2">
                                    <Col sm="12">
                                        <Label className="form-label">
                                            {t(title)}
                                        </Label>
                                        <p className="text-answers">
                                            {userAnswers && dateUtils.convertDateToDate(
                                                userAnswers,
                                                INTERNATIONAL_DATE_FORMAT
                                            )}
                                        </p>
                                    </Col>
                                </Row>
                            )
                        }
                        else if(type === "checkbox" || type === "category"){
                            return (
                                <Row key={ index } className="mb-2">
                                    <Col sm="12">
                                        <Label className="form-label">
                                            {t(title)}
                                        </Label>
                                        <Row>
                                            {
                                                userAnswers.map((eachCheckboxAnswer, checkboxIndex) => {
                                                    return (
                                                        <Col sm="12" md="6" key={ checkboxIndex }>
                                                            <p className="text-dark">
                                                                <i className="fa fa-check"></i>&nbsp;
                                                                {
                                                                    t(eachCheckboxAnswer?.title)
                                                                }
                                                            </p>
                                                        </Col>
                                                    );
                                                })
                                            }
                                        </Row>
                                    </Col>
                                </Row>
                            );
                        }
                        else if(type === "radiobox"){
                            return (
                                <Row key={ index } className="mb-2">
                                    <Col sm="12" lg="6">
                                        <Label className="form-label">
                                            {t(title)}
                                        </Label>
                                        <Row>
                                            {
                                                userAnswers && (
                                                    <Col sm="12" md="6">
                                                        <Label className="form-label" style={{ marginLeft: "8px"}}>
                                                            <i className="fa fa-check" style={{ marginRight: "8px"}}></i>
                                                            {t(userAnswers?.title)}
                                                        </Label>
                                                        <p className="text-dark" style={{ marginLeft: "15px" }}>
                                                            <span>
                                                                { t(userAnswers?.subTitle) }
                                                            </span>
                                                        </p>
                                                    </Col>
                                                )
                                            }
                                        </Row>
                                    </Col>
                                </Row>
                            );
                        }
                        else if(type === "file_upload"){
                            return (
                                <Row key={ index } className="mb-2">
                                    <Col sm="12">
                                        <Label className="form-label">
                                            {t(title)}
                                        </Label>

                                        <p className="text-answers">
                                            {
                                                userAnswers.map((f, i) => {
                                                    return (
                                                        <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                            key={i + "-file"}>
                                                            <div className="p-2">
                                                                <Row className="align-items-center">
                                                                    <Col>
                                                                        <Link to="#" className="text-muted fw-bold">
                                                                            {f.name}
                                                                        </Link>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </Card>
                                                    );
                                                })
                                            }
                                        </p>
                                    </Col>
                                </Row>
                            );
                        }
                    })
                }
            </Col>

            <Col sm="12" className="d-flex justify-content-between align-items-center col-sm-12">
                <Button
                    onClick={() => {
                        if(onBackButtonClicked){
                            onBackButtonClicked();
                        }
                    }}
                    disabled={isFormSubmiting}
                    color="btn btn-outline-light"
                    type="button">
                    {t(settings?.buttons?.back?.title || "Back")}
                </Button>

                <Button
                    onClick={() => {
                        if(onConfirmButtonClicked){
                            onConfirmButtonClicked();
                        }
                    }}
                    disabled={isFormSubmiting}
                    color="btn btn-primary"
                    type="button">
                    {t(settings?.buttons?.confirm?.title || "Confirm")}
                </Button>
            </Col>
        </Row>
    );
}

export default withNamespaces()(QuestionarConfirmForm);