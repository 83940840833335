import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import React, { memo } from "react";
import { Col, Container, Row } from "reactstrap";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import PageHeader from "src/modules/3rd-party-management/components/page-header";

import Tabs from "./components/tabs";

import List from "../suppliers/list";
import HistoryLog from './history-log';

const Manager = (props) => {
    const { t, suppliersType } = props;
    const location = useLocation();

    const renderSelectedTabContent = () => {
        const searchParams = new URLSearchParams(location.search);

        switch(searchParams.get('tab')){
            case 'history-log':
                return <HistoryLog suppliersType={suppliersType} />;
            default : 
                return <List suppliersType={suppliersType} />;
        }
    }

    return (
        <div className='page-content'>
            <Container fluid>
                <PageHeader 
                    title={t(suppliersType === 'own' ? 'Own Company Management' : 'Third Party Management')} 
                />

                <Row>
                    <Col lg="12">
                        <div className="thirdparties-management-page">
                            <Tabs 
                                suppliersType={suppliersType} 
                                t={t} 
                            />

                            { renderSelectedTabContent() }
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStatetoProps = (state) => {
    const { token } = state.Login;
    const { Organization } = state;
    return {
      user : state.Login.user,
      token,
      organization : Organization
    };
};

export default withNamespaces()(
    withRouter(connect(
        mapStatetoProps,
        {}
    )(memo(Manager)))
);