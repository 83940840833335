import { withNamespaces } from "react-i18next";
import axios from "axios";

import { toast } from "react-toastify";
import { useEffect, useRef, useState } from "react";

import { connect } from "react-redux";

import { withRouter } from "react-router-dom";
import OverviewHeader from "src/modules/data-protection/pages/software/overview-header";
import DPIAQuestionnaire from "src/modules/data-protection/pages/reporting/Components/DPIAQuestionnaire";
import { useQuery } from "@tanstack/react-query";
import DataProtectionSoftwareService from "src/modules/data-protection/apis/DataProtectionSoftwareService";

const DPIA = (props) => {
  const { t, assetId } = props;

  const overviewHeaderRef = useRef();

  const [showImpactAssessment, setShowImpactAssessment] = useState(false);

  const [questionnaireData, setQuestionnaireData] = useState({
    reportCaseId: null,
    questions: [],
    answers: null,
  });

  const [tabs, setTabs] = useState([
    {
      name: "company-details",
      title: "Company Details",
      completed: false,
      isCurrent: true,
    },
    {
      name: "fill-questions-step",
      title: "Fill Questionnaire",
      completed: false,
      isCurrent: false,
    },
    {
      name: "review-details",
      title: "Review Details",
      completed: false,
      isCurrent: false,
    },
    {
      name: "declaration",
      title: "Declaration",
      completed: false,
      isCurrent: false,
    },
  ]);

  const setActiveTab = (tab) => {
    setTabs((tabs) => {
      const currentActiveTabIndex = tabs.findIndex((t) => t.isCurrent);
      const nextActiveTabIndex = tabs.findIndex((t) => t.name === tab);

      if (currentActiveTabIndex > -1) {
        tabs[currentActiveTabIndex].completed = true;
        tabs[currentActiveTabIndex].isCurrent = false;
      }

      if (nextActiveTabIndex > -1) {
        tabs[nextActiveTabIndex].isCurrent = true;
      }

      return [...tabs];
    });
  };

  const handleFetchAssetBasicInformation = useQuery({
    queryKey: ["data-protection-supplier-overview-contnet"],
    queryFn: async () => {
      const service = DataProtectionSoftwareService.getInstance();
      return await service.details(assetId);
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      toast(t("An error occurred while fetching overview content."), {
        type: "error",
      });
    },
  });


  useEffect(() => {
    if (handleFetchAssetBasicInformation.data) {
      setShowImpactAssessment(handleFetchAssetBasicInformation.data?.show_impact_assessment)
    }
  }, [handleFetchAssetBasicInformation.data]);

  useEffect(() => {
    if (questionnaireData.answers) {
      setActiveTab("review-details");
    }
  }, [questionnaireData.answers]);

  

  
  return (
    <div className="p-4">
      <OverviewHeader assetId={assetId} actionsRef={ overviewHeaderRef } />

      <DPIAQuestionnaire
        showImpactAssessment={showImpactAssessment}
        onValidSubmit={(response) => {
            setShowImpactAssessment(!!response?.show_impact_assessment);
            
            if(overviewHeaderRef && overviewHeaderRef.current){
                overviewHeaderRef.current.reload();
            }
        }}
      />

    </div>
  );
};

const mapStatetoProps = (state) => {
  const { token, user } = state.Login;
  const { Organization, App } = state;

  return {
    token,
    user,
    Organization,
    App,
  };
};

export default withNamespaces()(withRouter(connect(mapStatetoProps, {})(DPIA)));
