import { memo, useCallback, useEffect, useState } from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import { Button, Card, CardHeader, Col, Container, Label, ListGroup, ListGroupItem, Row } from 'reactstrap';
import { convertToHTML } from 'draft-convert';
import { ContentState, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertFromHTML } from 'draft-js';
import { TbLoader } from 'react-icons/tb';
import {
	DESCRIPTION_NOT_UPDATED_SUCCESSFULLY,
	DESCRIPTION_UPDATED_SUCCESSFULLY,
	UPDATE_DAMAGE_SUCCESSFUL,
	UPDATE_DAMAGE_FAILED,
	UPDATE_LIKELIHOOD_SUCCESSFUL,
	UPDATE_LIKELIHOOD_FAILED,
	UPDATE_CATEGORY_SUCCESSFUL,
	UPDATE_CATEGORY_FAILED,
	FETCH_RISK_FAILED,
	UPDATE_OWNER_SUCCESSFUL,
	UPDATE_OWNER_FAILED,
	FETCH_COMMENTS_FAILED,
	SUBCATEGORY_IS_REQUIRED,
	CATEGORY_IS_REQUIRED,
	DESCRIPTION_IS_REQUIRED,
} from '../../../../../../common/constants';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { INTERNATIONAL_DATE_FORMAT } from '../../../../../../common/constants';
import { HiChevronDoubleDown, HiChevronDoubleUp, HiMenuAlt4 } from 'react-icons/hi';
import Select from 'react-select';
import axios from 'axios';
import DateUtils from '../../../../../../services/utils/DateUtils';
import DownloadAttachments from '../../CommonForTaskAndRisk/DownloadAttachments';
import { API_BASE_URL } from '../../../../constants';
import MemorizedCreateTaskModal from '../TaskComponents/CreateTaskModal';
import MemorizedCommentsComponent from '../CommonComponents/Comments';
import { formatTaskAndRiskId } from "src/modules/data-protection/constants/Common"
import MultiSelect from "src/modules/data-protection/components/MultiSelect";

const RiskDetails = function (props) {
	RiskDetails.displayName = 'Memorized Component - Risk Details';
	if (process.env.NODE_ENV === 'development') console.log('---- rendering memorized component >>> RiskDetails');
	const dateUtils = new DateUtils();
	const {
		t,
		categories,
		locations,
		damages,
		likelihoods,
		owners,
		reportId,
		riskId,
		authToken,
		tableColors,
		currentUser,
		errorNotification,
		successNotification,
		handleSetReportRiskField,
		back,
		module,
	} = props;

	const [createTaskModalStatus, setCreateTaskModalStatus] = useState(false);

	const [risk, setRisk] = useState(null);
	const [comments, setComments] = useState(null);

	const [selectedCategory, setSelectedCategory] = useState(null);
	const [selectedCategoryOtherString, setSelectedCategoryOtherString] = useState(null);
	// const [categoryError, setCategoryError] = useState(null);
	const [categoryOtherError, setCategoryOtherError] = useState(null);
	const [selectedSubCategory, setSelectedSubCategory] = useState(null);
	const [selectedSubCategoryOtherString, setSelectedSubCategoryOtherString] = useState(null);
	// const [subCategoryError, setSubCategoryError] = useState(null);
	const [subCategoryOtherError, setSubCategoryOtherError] = useState(null);
	const [selectedLocation, setSelectedLocation] = useState(null);
	const [selectedDamage, setSelectedDamage] = useState(null);
	const [selectedLikelihood, setSelectedLikelihood] = useState(null);
	const [description, setDescription] = useState(EditorState.createEmpty());
	const [descriptionError, setDescriptionError] = useState(null);
	const [selectedOwner, setSelectedOwner] = useState(null);

	const [loadingCategory, setLoadingCategory] = useState(false);
	const [loadingDamage, setLoadingDamage] = useState(false);
	const [loadingLikelihood, setLoadingLikelihood] = useState(false);
	const [loadingOwner, setLoadingOwner] = useState(false);
	const [loadingDescription, setLoadingDescription] = useState(false);
	const [loadingComments, setLoadingComments] = useState(false);

	/**
	 * this method updates category and checks the flow.
	 * @param {Object} category selected category.
	 */
	const handleChangeCategory = (category) => {
		if (category.baseLabel !== 'Other') {
			setSelectedCategoryOtherString('');
			setSelectedSubCategoryOtherString('');
		}

		setSelectedCategory(category);

		if (category.subCategories.length === 1) {
			handleChangeSubCategory(category.subCategories[0]);
		} else {
			handleChangeSubCategory(null);
		}
	};

	/**
	 * this method handles update sub category and checks the flow.
	 * @param {Object} subCategory selected sub category.
	 */
	const handleChangeSubCategory = (subCategory) => {
		if (subCategory && subCategory.baseLabel !== 'Other') {
			setSelectedSubCategoryOtherString(null);
		}

		setSelectedSubCategory(subCategory);
	};

	/**
	 * this method updates custom category staring.
	 * @param {String} value custom category string.
	 */
	const handleChangeOtherCategoryString = (value) => {
		setSelectedCategoryOtherString(value);
	};

	/**
	 * this method handle update custom sub category
	 * @param {String} value custom sub category staring.
	 */
	const handleChangeOtherSubCategoryString = (value) => {
		setSelectedSubCategoryOtherString(value);
	};

	/**
	 * this method submits the change category request to the server.
	 * @param {String} riskId the ID of the risk you want to change category for.
	 * @param {String} token authorization token
	 */
	const handleSubmitChangeCategory = async (riskId, token) => {
		try {
			if (
				(selectedCategory.baseLabel === 'Other' && !selectedCategoryOtherString) ||
				(selectedSubCategory.baseLabel === 'Other' && !selectedSubCategoryOtherString)
			) {
				if (!selectedCategoryOtherString) setCategoryOtherError(t(CATEGORY_IS_REQUIRED));
				if (!selectedSubCategoryOtherString) setSubCategoryOtherError(t(SUBCATEGORY_IS_REQUIRED));

				return;
			} else {
				setCategoryOtherError(null);
				setSubCategoryOtherError(null);
			}

			setLoadingCategory(true);
			const data = {
				category:
					selectedCategory.baseLabel === 'Other' ? null : selectedSubCategory.baseLabel === 'Other' ? selectedCategory.value : selectedSubCategory.value,
				otherCategory: selectedCategoryOtherString ? selectedCategoryOtherString : null,
				otherSubCategory: selectedSubCategoryOtherString ? selectedSubCategoryOtherString : null,
			};

			const result = await axios.post(
				`${API_BASE_URL}/risk_rate/${riskId}/change_category`,
				data,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			);

			if (result.status === 200) {
				successNotification({
					message: t(UPDATE_CATEGORY_SUCCESSFUL),
				});
			} else {
				errorNotification({
					message: t(UPDATE_CATEGORY_FAILED),
				});
			}
		} catch (error) {
			if (process.env.NODE_ENV === 'development') {
				console.error('this error only appears in the development environment:\nerror while updating category', error);
			}

			errorNotification({
				message: t(UPDATE_CATEGORY_FAILED),
			});
		}

		setLoadingCategory(false);
	};

	/**
	 * this method submits the change damage request to the server.
	 * @param {String} riskId the ID of the risk you want to change damage for.
	 * @param {Object} damage selected damage.
	 * @param {String} token authorization token.
	 */
	const handleChangeRiskDamage = async (riskId, damage, token) => {
		try {
			setLoadingDamage(true);
			// setSelectedDamage(damage);
			const result = await axios.post(
				`${API_BASE_URL}/risk_rate/${riskId}/update_damage`,
				{ damage: damage.value },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			);

			if (result.status === 200) {
				handleSetReportRiskField(result.data.data.reportRiskRate);

				const newRisk = Object.create(risk);
				newRisk.rating = result.data.data.riskRate;

				newRisk.damage = { id: damage.value, title: damage.label };
				setRisk(newRisk);

				successNotification({
					message: t(UPDATE_DAMAGE_SUCCESSFUL),
				});
			} else {
				errorNotification({
					message: t(UPDATE_DAMAGE_FAILED),
				});
			}
		} catch (error) {
			if (process.env.NODE_ENV === 'development') {
				console.error('this error only appears in the development environment:\nerror while updating damage', error);
			}

			errorNotification({
				message: t(UPDATE_DAMAGE_FAILED),
			});
		}

		setLoadingDamage(false);
	};

	/**
	 * this method submits the change likelihood request to the server.
	 * @param {String} riskId the ID of the risk you want to change likelihood for.
	 * @param {Object} likelihood selected likelihood.
	 * @param {String} token authorization token.
	 */
	const handleChangeRiskLikelihood = async (riskId, likelihood, token) => {
		try {
			setLoadingLikelihood(true);
			setSelectedLikelihood(likelihood);
			const result = await axios.post(
			  `${API_BASE_URL}/risk_rate/${riskId}/update_likelihood`,
				{ likelihood: likelihood.value },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			);

			if (result.status === 200) {
				handleSetReportRiskField(result.data.data.reportRiskRate);

				const newRisk = Object.create(risk);
				newRisk.rating = result.data.data.riskRate;
				setRisk(newRisk);

				successNotification({
					message: t(UPDATE_LIKELIHOOD_SUCCESSFUL),
				});
			} else {
				errorNotification({
					message: t(UPDATE_LIKELIHOOD_FAILED),
				});
			}
		} catch (error) {
			if (process.env.NODE_ENV === 'development') {
				console.error('this error only appears in the development environment:\nerror while updating likelihood', error);
			}

			errorNotification({
				message: t(UPDATE_LIKELIHOOD_FAILED),
			});
		}

		setLoadingLikelihood(false);
	};

	/**
	 * this method fetches a risk.
	 * @param {String} riskId the ID of the risk you want to fetch.
	 * @param {String} token authorization token.
	 */
	const handleFetchRisk = useCallback(
		async (riskId, token) => {
			try {
				const result = await axios.get(
					`${API_BASE_URL}/risk_rate/${riskId}/show`,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					},
				);

				if (result.status === 200) {
					if (result.data.data.report_case_id !== localStorage.getItem('caseId')) {
						localStorage.setItem('caseId', result.data.data.report_case_id);
					}
					setRisk(result.data.data);
				} else {
					errorNotification({
						message: t(FETCH_RISK_FAILED),
					});
				}
			} catch (error) {
				if (process.env.NODE_ENV === 'development') {
					console.error('this error only appears in the development environment:\nerror while fetching risk', error);
				}

				errorNotification({
					message: t(FETCH_RISK_FAILED),
				});

				back();
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[errorNotification],
	);

	/**
	 * this method submits the change owner request to the server.
	 * @param {String} riskId the ID of the risk you want to fetch.
	 * @param {Object} owner selected owner.
	 * @param {String} token authorization token.
	 */
	const handleChangeRiskOwner = async (riskId, owner, token) => {
		try {
			setLoadingOwner(true);
			setSelectedOwner(owner);
			const result = await axios.post(
				`${API_BASE_URL}/risk_rate/${riskId}/assign_analyst`,
				{ owner: owner.value },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			);

			if (result.status === 200) {
				successNotification({
					message: t(UPDATE_OWNER_SUCCESSFUL),
				});
			} else {
				errorNotification({
					message: t(UPDATE_OWNER_FAILED),
				});
			}
		} catch (error) {
			if (process.env.NODE_ENV === 'development') {
				console.error('this error only appears in the development environment:\nerror while updating owner', error);
			}

			errorNotification({
				message: t(UPDATE_OWNER_FAILED),
			});
		}

		setLoadingOwner(false);
	};

	/**
	 * this method submits the change description request to the server.
	 * @param {String} riskId the ID of the risk you want to change description for.
	 * @param {String} token authorizatio token.
	 */
	const handleDescriptionSubmit = async (riskId, token) => {
		try {
			if (!description.getCurrentContent().hasText()) {
				setDescriptionError(DESCRIPTION_IS_REQUIRED);
				return;
			} else {
				setDescriptionError(null);
			}

			setLoadingDescription(true);
			const result = await axios.post(
				`${API_BASE_URL}/risk_rate/${riskId}/edit`,
				{
					description: convertToHTML(description.getCurrentContent()),
					descriptionAttachments: [],
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			);

			if (result.status === 200) {
				successNotification({
					message: t(DESCRIPTION_UPDATED_SUCCESSFULLY),
				});
			} else {
				errorNotification({
					message: t(DESCRIPTION_NOT_UPDATED_SUCCESSFULLY),
				});
			}
		} catch (error) {
			console.log('error while updating description: ', error);
			errorNotification({
				message: t(DESCRIPTION_NOT_UPDATED_SUCCESSFULLY),
			});
		}

		setLoadingDescription(false);
	};

	/**
	 * this method fetches comments of a risk.
	 * @param {String} riskId the ID of the risk you want to change request for.
	 * @param {String} token authorization token.
	 */
	const handleFetchComments = useCallback(
		async (riskId, token) => {
			setComments(null);
			setLoadingComments(true);
			try {
				const result = await axios.get(
					`${API_BASE_URL}/risk_rate/${riskId}/comments`,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					},
				);

				if (result.status === 200) {
					setComments(result.data.data);
				} else {
					errorNotification({
						message: t(FETCH_COMMENTS_FAILED),
					});
				}
			} catch (error) {
				if (process.env.NODE_ENV === 'development') {
					console.error('this error only appears in the development environment:\nerror while fetching comments', error);
				}

				errorNotification({
					message: t(FETCH_COMMENTS_FAILED),
				});
			}
			setLoadingComments(false);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[errorNotification],
	);

	const handleUpdateAttachmentName = (name, id) => {
		const index = risk.description_attachments.findIndex((i) => i.id === id);

		if (index > -1) {
			const description_attachments = Array.from(risk.description_attachments);
			description_attachments[index].origin_name = name;

			setRisk({
				...risk,
				description_attachments,
			});
		}
	};

	useEffect(() => {
		if (riskId) {
			setRisk(null);
			handleFetchRisk(riskId, authToken);
			setComments(null);
			handleFetchComments(riskId, authToken);
		}
	}, [riskId, authToken, handleFetchRisk, handleFetchComments]);

	useEffect(() => {
		if (risk && categories) {
			if (risk.parent_category.is_other_category) {
				const category = categories.find((c) => c.baseLabel === 'Other');
				setSelectedCategory({
					value: category.value,
					baseLabel: category.baseLabel,
					label: t(category.baseLabel),
					subCategories: category.subCategories,
				});
				setSelectedCategoryOtherString(risk.parent_category.name);
				setSelectedSubCategory({
					value: category.subCategories[0].value,
					baseLabel: category.subCategories[0].baseLabel,
					label: t(category.subCategories[0].baseLabel),
				});
				setSelectedSubCategoryOtherString(risk.category.name);
			} else {
				const category = categories.find((c) => c.value === risk.parent_category.id);
				setSelectedCategory({
					value: category.value,
					baseLabel: category.baseLabel,
					label: t(category.baseLabel),
					subCategories: category.subCategories,
				});

				setSelectedCategoryOtherString('');

				if (risk.category.is_other_category) {
					const subCategory = category.subCategories.find((c) => c.baseLabel === 'Other');
					setSelectedSubCategory({
						value: subCategory.value,
						baseLabel: subCategory.baseLabel,
						label: t(subCategory.baseLabel),
					});
					setSelectedSubCategoryOtherString(risk.category.name);
				} else {
					const subCategory = category.subCategories.find((c) => c.value === risk.category.id);
					setSelectedSubCategory({
						value: subCategory.value,
						baseLabel: subCategory.baseLabel,
						label: t(subCategory.baseLabel),
					});
					setSelectedCategoryOtherString('');
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [risk, categories]);

	useEffect(() => {
		if (risk && locations) {
			const location = locations.find((l) => l.value === risk.location.id);
			setSelectedLocation(location);
		}
	}, [risk, locations]);

	useEffect(() => {
		if (risk && damages) {
			const damage = damages.find((d) => d.value === risk.damage.id);
			setSelectedDamage(damage);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [risk, damages]);

	useEffect(() => {
		if (risk && likelihoods) {
			const likelihood = likelihoods.find((l) => l.value === risk.likelihood.id);
			setSelectedLikelihood({
				value: likelihood.value,
				label: t(likelihood.label),
			});
		}
	}, [risk, likelihoods, t]);

	useEffect(() => {
		if (risk && owners) {
			const owner = owners.find((o) => o.label === risk.owner_name);
			setSelectedOwner(owner);
		}
	}, [risk, owners]);

	useEffect(() => {
		if (risk) {
			const blocksFromHTML = risk.description ? convertFromHTML(risk.description) : '';
			const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);

			setDescription(EditorState.createWithContent(state));
		}
	}, [risk]);

	useEffect(() => {
		if (selectedCategory) {
			setSelectedCategory({
				value: selectedCategory.value,
				baseLabel: selectedCategory.baseLabel,
				label: t(selectedCategory.baseLabel),
				subCategories: selectedCategory.subCategories,
			});
		}
		if (selectedSubCategory) {
			setSelectedSubCategory({
				value: selectedSubCategory.value,
				baseLabel: selectedSubCategory.baseLabel,
				label: t(selectedSubCategory.baseLabel),
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [t]);

	return (
		<>
			{/* create task modal */}
			<MemorizedCreateTaskModal
				t={props.t}
				authToken={authToken}
				isOpen={createTaskModalStatus}
				close={() => setCreateTaskModalStatus(false)}
				currentUser={currentUser}
				owners={owners}
				initialTaskStatus={{ value: 'NEW', label: props.t('New') }}
				priorities={[
					{
						value: 'low',
						label: (
							<>
								<HiChevronDoubleDown className='text-info' />
								<span style={{ marginLeft: 4 }}>{props.t('Low')}</span>
							</>
						),
					},
					{
						value: 'medium',
						label: (
							<>
								<HiMenuAlt4 className='text-success' />
								<span style={{ marginLeft: 4 }}>{props.t('Medium')}</span>
							</>
						),
					},
					{
						value: 'high',
						label: (
							<>
								<HiChevronDoubleUp className='text-danger' />
								<span style={{ marginLeft: 4 }}>{props.t('High')}</span>
							</>
						),
					},
				]}
				riskId={riskId}
				reportId={reportId}
				successCreation={() => handleFetchRisk(riskId, authToken)}
				successNotification={successNotification}
				errorNotification={errorNotification}
				module={module}
			/>

			<Row className='pt-2 ps-3'>
				<Col lg='2' md='2' sm='12'>
					{risk ? (
						<>
							{/* arrow */}
							<span
								style={{ cursor: 'pointer' }}
								color='bg-secondary'
								onClick={() => {
									back();
								}}
							>
								<span className='h3'>
									<IoIosArrowBack />
								</span>
							</span>

							{/* title */}
							<span
								style={{
									display: 'inline-block',
									position: 'relative',
									top: '-0.1rem',
									cursor: 'pointer',
								}}
								onClick={() => {
									back();
								}}
							> 
									{formatTaskAndRiskId('risk',risk?.questionnaire === "software" ? risk?.asset_id : risk?.case_secondary_id, risk.secondary_id, risk?.is_mitigation, risk.questionnaire, null )}
							</span>
						</>
					) : (
						<span style={{ height: 30, display: 'block' }}></span>
					)}
				</Col>
				<Col lg='8' md='8' sm='12'></Col>
				<Col lg='2' md='2' sm='12'>
					<Button
						color='primary'
						className='mb-3'
						style={{
							display: 'block',
							width: '100%',
						}}
						onClick={() => setCreateTaskModalStatus(true)}
						outline
					>
						{t('Create Task')}
					</Button>
				</Col>
			</Row>

			<div style={{ height: '1rem' }}></div>

			<Container>
				<Row>
					<Col lg='8' md='8' sm='12'>
						<AvForm>
							<Row className='mb-3'>
								{/* category */}
								<Col
									sm='12'
									md={selectedCategory && selectedCategory.baseLabel === 'Other' ? 2 : 5}
									lg={selectedCategory && selectedCategory.baseLabel === 'Other' ? 2 : 5}
								>
									<Label className='form-label text-dark'>{`${t('Risk category')}: `}</Label>
									{!loadingCategory && categories && risk ? (
										<Select
											name='category'
											classNamePrefix='select2-selection'
											options={categories.map((c) => {
												return {
													value: c.value,
													baseLabel: c.baseLabel,
													label: t(c.baseLabel),
													subCategories: c.subCategories,
												};
											})}
											value={selectedCategory}
											onChange={handleChangeCategory}
											placeholder={t('Select...')}
											menuPortalTarget={document.body}
										/>
									) : (
										<div className='dt-field dt-skeleton dt-select-list' style={{ marginBottom: 16 }}></div>
									)}
								</Col>

								{/* other category input */}
								<Col sm='12' md={3} lg={3} hidden={!selectedCategory || (selectedCategory && selectedCategory.baseLabel !== 'Other')}>
									<Label className='form-label text-dark'>{`${t('Other category')}: `}</Label>
									{!loadingCategory && categories && risk ? (
										<>
											<AvField
												errorMessage={t('This field cannot be blank')}
												className='form-control'
												name='otherCat'
												type='text'
												value={selectedCategoryOtherString}
												onChange={(e) => handleChangeOtherCategoryString(e.target.value)}
											/>
											<p className='text-danger'>{!selectedCategoryOtherString ? t(categoryOtherError) : ''}</p>
										</>
									) : (
										<div className='dt-field dt-skeleton dt-select-list' style={{ marginBottom: 16 }}></div>
									)}
								</Col>

								{/* sub category */}
								<Col
									sm='12'
									md={selectedSubCategory && selectedSubCategory.baseLabel === 'Other' ? 2 : 5}
									lg={selectedSubCategory && selectedSubCategory.baseLabel === 'Other' ? 2 : 5}
								>
									<Label className='form-label text-dark'>{`${t('Subcategory')}: `}</Label>
									{!loadingCategory && categories && risk ? (
										<Select
											name='categorsubcategory'
											classNamePrefix='select2-selection'
											placeholder={t('Select...')}
											options={
												selectedCategory && selectedCategory.subCategories
													? selectedCategory.subCategories.map((c) => {
															return {
																value: c.value,
																baseLabel: c.baseLabel,
																label: t(c.baseLabel),
																subCategories: c.subCategories,
															};
													  })
													: []
											}
											value={selectedSubCategory}
											onChange={handleChangeSubCategory}
											menuPortalTarget={document.body}
										/>
									) : (
										<div className='dt-field dt-skeleton dt-select-list' style={{ marginBottom: 16 }}></div>
									)}
								</Col>

								{/* other sub category input */}
								<Col sm='12' md={3} lg={3} hidden={!selectedSubCategory || selectedSubCategory.baseLabel !== 'Other'}>
									<Label className='form-label text-dark'>{`${t('Other')}: `}</Label>

									{!loadingCategory && categories && risk ? (
										<>
											<AvField
												errorMessage={t('This field cannot be blank')}
												className='form-control'
												name='otherCat'
												type='text'
												value={selectedSubCategoryOtherString}
												onChange={(e) => handleChangeOtherSubCategoryString(e.target.value)}
											/>
											<p className='text-danger'>{!selectedSubCategoryOtherString ? t(subCategoryOtherError) : ''}</p>
										</>
									) : (
										<div className='dt-field dt-skeleton dt-select-list' style={{ marginBottom: 16 }}></div>
									)}
								</Col>

								{/* submit categories */}
								<Col sm='12' md={2} lg={2} className='mt-4 pt-1'>
									{categories && risk ? (
										<Button style={{ width: '100%' }} color='primary' onClick={(e) => handleSubmitChangeCategory(risk.id, authToken)}>
											{t('Save')}
										</Button>
									) : null}
								</Col>
							</Row>
						</AvForm>

						<Row className='mt-5'>
							{/* location */}
							<Col sm='12' md='3' lg='3'>
								<Label className='form-label'>{`${t('location')} `}</Label>
								<br />
								{locations && risk ? (
									<Select
										name='location'
										classNamePrefix='select2-selection'
										options={locations}
										isDisabled={true}
										placeholder={t('Select...')}
										value={selectedLocation}
										menuPortalTarget={document.body}
									/>
								) : (
									<div className='dt-field dt-skeleton dt-select-list' style={{ marginBottom: 16 }}></div>
								)}
							</Col>

							{/* involved entities */}
							<Col sm='12' md='3' lg='3'>
								<Label className='form-label'>{`${t('Involved entities')} `}</Label>
								<br />
								{risk ? (
										   <MultiSelect
										   placeholder={"..."}
										   selectedValues={risk?.legal_entities}
										   options={[]}
										   isDisabled={true}
										   
										   />
								) : (
									<div className='dt-field dt-skeleton dt-select-list' style={{ marginBottom: 16 }}></div>
								)}
							</Col>


							{/* damage */}
							<Col sm='12' md='3' lg='3'>
								<Label className='form-label'>{`${t('Damage')} `}</Label>
								<br />
								{!loadingDamage && damages && risk ? (
									<Select
										name='damage'
										classNamePrefix='select2-selection'
										options={damages}
										placeholder={t('Select...')}
										value={selectedDamage}
										onChange={(e) => {
											handleChangeRiskDamage(risk.id, e, authToken);
										}}
										menuPortalTarget={document.body}
									/>
								) : (
									<div className='dt-field dt-skeleton dt-select-list'></div>
								)}
							</Col>

							{/* likelihood */}
							<Col sm='12' md='3' lg='3'>
								<Label className='form-label'>{`${t('Likelihood')} `}</Label>
								<br />
								{!loadingLikelihood && likelihoods && risk ? (
									<Select
										name='likelihood'
										classNamePrefix='select2-selection'
										options={likelihoods.map((l) => {
											return {
												value: l.value,
												label: t(l.label),
											};
										})}
										placeholder={t('Select...')}
										value={selectedLikelihood}
										onChange={(e) => {
											handleChangeRiskLikelihood(risk.id, e, authToken);
										}}
										menuPortalTarget={document.body}
									/>
								) : (
									<div className='dt-field dt-skeleton dt-select-list'></div>
								)}
							</Col>
						</Row>

						<Row className='mt-5'>
							<Col sm='12' md='12' lg='12'>
								{risk ? (
									<>
										<Label className='form-label'>{`${t('Description')} `}</Label>
										<br />
										<Editor
											editorState={description}
											readOnly={loadingDescription}
											toolbarClassName='toolbarClassName'
											wrapperClassName='wrapperClassName'
											editorClassName='editorClassName'
											onEditorStateChange={(e) => setDescription(e)}
											placeholder={t('Add a description...')}
											editorStyle={{
												height: '10rem',
												overflowY: 'scroll',
												borderColor: '#c9c9c9',
												paddingRight: '2em',
											}}
											toolbar={{
												options: ['inline', 'blockType', 'fontSize', 'fontFamily'],
												inline: {
													options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
													bold: { className: 'bordered-option-classname' },
													italic: { className: 'bordered-option-classname' },
													underline: { className: 'bordered-option-classname' },
													strikethrough: {
														className: 'bordered-option-classname',
													},
													code: { className: 'bordered-option-classname' },
												},
												blockType: {
													className: 'bordered-option-classname',
												},
												fontSize: {
													className: 'bordered-option-classname',
												},
												fontFamily: {
													className: 'bordered-option-classname',
												},
											}}
										/>
										<br />
										<p className='text-danger'>{descriptionError ? t(descriptionError) : ''}</p>
									</>
								) : null}
							</Col>
						</Row>
						<br />
						<Row>
							{/* submit description */}
							{risk ? (
								<Col lg='2' md='2' sm='6'>
									<Button color='primary' style={{ width: '100%', display: 'block' }} onClick={(e) => handleDescriptionSubmit(riskId, authToken)}>
										{loadingDescription ? <TbLoader /> : t('Save')}
									</Button>
								</Col>
							) : null}

							{/* attachments */}
							{risk && risk.description_attachments && risk.description_attachments.length > 0 ? (
								<Col lg='10' md='10' sm='6'>
									<DownloadAttachments type={'risk'} showAttachments={false} t={t} files={risk.description_attachments} module={module} />
								</Col>
							) : null}
						</Row>
					</Col>
					<Col lg='4' md='4' sm='12'>
						<Card outline body className='border border-primary rounded' style={{ width: 'auto' }}>
							<CardHeader>{t('Details')}</CardHeader>
							<br />
							<ListGroup flush style={{ borderRadius: '0' }}>
								{/* manager */}
								<ListGroupItem className='border-bottom border-primary'>
									<Row>
										<Col>
											<p style={{ marginBottom: 0, lineHeight: '26px' }}>{t('Risk Manager')}</p>
										</Col>
										<Col>
											{risk ? (
												<span className='badge bg-success text-white rounded p-2' style={{ marginBottom: 0, display: 'block' }}>
													{risk.manager}
												</span>
											) : (
												<div className='dt-field dt-skeleton' style={{ height: 27 }}></div>
											)}
										</Col>
									</Row>
								</ListGroupItem>

								{/* owner */}
								<ListGroupItem className='border-bottom border-primary '>
									<Row>
										<Col>
											<p style={{ marginBottom: 0, lineHeight: '36px' }}>{t('Risk owner')}</p>
										</Col>
										<Col>
											{!loadingOwner && owners && risk ? (
												<Select
													required
													name='owner'
													classNamePrefix='select2-selection'
													options={owners}
													isDisabled={`${currentUser.first_name} ${currentUser.last_name}`.trim() !== risk.manager.trim()}
													value={selectedOwner}
													onChange={(e) => {
														handleChangeRiskOwner(risk.id, e, authToken);
													}}
												></Select>
											) : (
												<div className='dt-field dt-skeleton dt-select-list'></div>
											)}
										</Col>
									</Row>
								</ListGroupItem>

								{/* risk rating */}
								<ListGroupItem className='border-bottom border-primary'>
									<Row>
										<Col>
											<p style={{ marginBottom: 0, lineHeight: '27px' }}>{t('Risk rating')}</p>
										</Col>
										<Col>
											{tableColors && risk ? (
												<span
													className='badge text-white rounded p-2'
													style={{
														marginBottom: 0,
														display: 'block',
														fontWeight: 'bold',
														backgroundColor: `#${tableColors.find((i) => i.label === risk.rating).color}`,
													}}
												>
													{t(risk.rating)}
												</span>
											) : (
												<div className='dt-field dt-skeleton' style={{ height: 26 }}></div>
											)}
										</Col>
									</Row>
								</ListGroupItem>

								{/* created at */}
								<ListGroupItem className='border-bottom border-primary'>
									<Row>
										<Col>
											<p style={{ marginBottom: 0, lineHeight: '26px' }}>{t('Created')}</p>
										</Col>
										<Col>
											{risk ? (
												<Label style={{ marginBottom: 0, lineHeight: '26px' }}>
													{dateUtils.convertTimeStampToDate(risk.created_at, INTERNATIONAL_DATE_FORMAT)}
												</Label>
											) : (
												<div className='dt-field dt-skeleton' style={{ height: 27 }}></div>
											)}
										</Col>
									</Row>
								</ListGroupItem>

								{/* tasks */}
								<ListGroupItem>
									<Row>
										<Col>
											<p style={{ marginBottom: 0, lineHeight: '26px' }}>{t('Risk Mitigation')}</p>
										</Col>
										<Col>
											{risk && risk.tasks ? (
												risk.tasks.map((t, i) => {
													const urlToArray = window.location.pathname.split('/');
													const newUrl = props?.taskUrl ? props?.taskUrl+t.id :`/${urlToArray[1]}/${urlToArray[2]}/${urlToArray[3]}/${urlToArray[4]}/task/${t.id}`;
													return (
														t.status === "DELETED" ?
														<span>
															{`T${t.secondary_id}`}
														{/* {formatTaskAndRiskId('task',risk.secondary_id, t?.is_mitigation, t?.questionnaire, t.secondary_id )} */}
															<span hidden={i === risk.tasks.length - 1}>{', '}</span>
														</span> :
														<a href={newUrl}>
															{`T${t.secondary_id}`}
														{/* {formatTaskAndRiskId('task',risk.secondary_id, t?.is_mitigation, t?.questionnaire, t.secondary_id )} */}
															<span hidden={i === risk.tasks.length - 1}>{', '}</span>
														</a>
													);
												})
											) : (
												<div className='dt-field dt-skeleton' style={{ height: 27 }}></div>
											)}
										</Col>
									</Row>
								</ListGroupItem>
							</ListGroup>
						</Card>
					</Col>
				</Row>

				<MemorizedCommentsComponent
					useCase={'risk'}
					t={t}
					authToken={authToken}
					successNotification={successNotification}
					errorNotification={errorNotification}
					id={riskId}
					owner={risk?.owner}
					manager={risk?.manager}
					descriptionAttachments={risk?.description_attachments}
					successDeletion={(fileId) => {
						setRisk({
							...risk,
							description_attachments: risk.description_attachments.filter((f) => f.id !== fileId),
						});
					}}
					creationDate={risk ? dateUtils.convertTimestampToOnlyDate(risk.created_at) : null}
					comments={comments}
					fetchComments={() => handleFetchComments(riskId, authToken)}
					loadingComments={loadingComments}
					updateAttachmentName={(name, id) => {
						handleUpdateAttachmentName(name, id);
					}}
					module={module}
				/>
			</Container>
		</>
	);
};

export default memo(RiskDetails);
