import {
    ORGANIZATION_MODULES_LOADED_SUCCESSFULLY,
    SET_ORGANIZATION_ACTIVE_MODULE,
    CHANGE_ORGANIZATION_ACTIVE_MODULE_ON_REDUX,
    ORGANIZATION_DOEST_HAVE_ACTIVE_MODULE_ERROR,
    SAVE_ORGANIZATION_ACTIVE_MODULE_ON_REMOTE,
    ACTIVE_MODULE_FEATURES_LOADED_SUCCESSFULLY,
    RELOAD_ORGANIZATION_CURRENT_MODULE_SETTINGS,
    SET_MODULE_LOADING_STATUS,
    CHANGE_ACTIVE_MODULE_FEATURES_LOADING_STATUS
} from "./actionTypes";
  
export const organizationModulesLoadedSuccessfully = ({
    allModules,
    organizationModules
}) => {
    return {
        type    :   ORGANIZATION_MODULES_LOADED_SUCCESSFULLY,
        payload :   {
            allModules,
            organizationModules
        }
    };
}

export const setModuleFeatures = ({
    module,
    features
}) => {
    return {
        type    :   ACTIVE_MODULE_FEATURES_LOADED_SUCCESSFULLY,
        payload :   {
            module,
            features
        }
    };
}

export const organizationDoestHaveActiveModuleError = () => {
    return {
        type    :   ORGANIZATION_DOEST_HAVE_ACTIVE_MODULE_ERROR
    };
}

export const setOrganizationActiveModule = (module) => {
    return {
        type    :   SET_ORGANIZATION_ACTIVE_MODULE,
        payload :   module
    };
}

export const changeOrganizationActiveModuleOnRedux = (module) => {
    return {
        type    :   CHANGE_ORGANIZATION_ACTIVE_MODULE_ON_REDUX,
        payload :   module
    };
}

export const saveOrganizationActiveModuleOnRemote = (module) => {
    return {
        type    :   SAVE_ORGANIZATION_ACTIVE_MODULE_ON_REMOTE,
        payload :   module
    }
}

export const reloadOrganizationActiveModuleSettingsFromServer = () => {
    return {
        type    :   RELOAD_ORGANIZATION_CURRENT_MODULE_SETTINGS
    }
}

export const setModuleLoadingStatus = ({
    status
}) => {
    return {
        type    :   SET_MODULE_LOADING_STATUS,
        payload :   {
            status : status
        }
    };
}

export const setFeaturesLoadingStatus = ({
    status
}) => {
    return {
        type    :   CHANGE_ACTIVE_MODULE_FEATURES_LOADING_STATUS,
        payload :   {
            status : status
        }
    };
}