export default class ResponseError extends Error {
    constructor(message, error) {
        super(message);
        this.error = error;
    }

    setMessage(message) {
        this.message = message;
    }
    
    setError(error) {
        this.error = error;
    }
    
    getError() {
        return this.error;
    }

    getMessage() {
        return this.message;
    }
}
