import { withRouter } from "react-router-dom";
import OverviewHeader from "../../../../components/overview-header";
import MessageBox from "src/modules/3rd-party-management/components/MessageBox";

const Communication = ({supplierId }) => {


  return (
    <div className="p-4 d-flex flex-column gap-4">
      <OverviewHeader supplierId={supplierId} />
      
      <MessageBox
        settings={{
            headerOptions           :   {
                displayFilters  :   false,
                title           :   'Communication with supplier'
            },
            messageListUrl        : '/supplier/comment/list',
            sendMessageUrl        : '/supplier/comment/add',
            attachmentDownloadUrl : '/supplier/attachment/',
            attachmentUploadUrl   : '/supplier/attachment/upload',
            attachmentDeleteUrl   : '/supplier/attachment/',
            supplierId            : supplierId,
            mention               : {
                enable  : false
            }
        }}
        />
    </div>
  );
};

export default (withRouter(Communication));