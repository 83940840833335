import React from "react";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import Navigator from "src/components/navigator";
import UserTable from "./components/uertable";

const ListUsers = (props) => {
  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col lg="12" className="mb-4">
            <Navigator
              backButtonOptions={() => {
                return {
                  enable: false,
                  // onClick: () => handleBack(),
                  title: props.t("Back"),
                  icon: <i className="ri-arrow-left-fill"></i>,
                };
              }}
              breadCrumbs={[
                { title: "SIP", link: "/" },
                { title: props.t("User Management"), link: "#" },
              ]}
            />
          </Col>

          <Col lg="12" className="mb-4">
            <div className="details-content-wrapper">
              <UserTable />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStatetoProps = (state) => {
  const { token } = state.Login;
  const { App, Organization } = state;
  return { token, App, Organization };
};

export default withNamespaces()(
  withRouter(connect(mapStatetoProps)(ListUsers))
);
