import { memo, useCallback } from 'react';
import { Container, Row, Col, Badge } from 'reactstrap';

const RiskMatrix = function (props) {
	RiskMatrix.displayName = 'Memorized Component - Risk Matrix';
	if (process.env.NODE_ENV === 'development') console.log('---- rendering memorized component >>> RiskMatrix');

	const { risks, selectRisk, matrix, consequences, currency, likelihoods, tableColors, t } = props;
	/**
	 * this method renders the bullets of the risks in matrix.
	 * @param {Object} likelihood the likelihood that may exist a risk
	 * @param {Object} damage the damage that may exist a risk
	 */
	const renderRiskBullets = useCallback(
		(likelihood, damage) => {
			const _risks = risks.filter((r) => r.likelihood.title === likelihood && r.damage.title === damage);

			if (_risks.length) {
				return _risks.map((r) =>
					!r.is_deleted ? (
						<span
							key={r.id}
							style={{ cursor: 'pointer' }}
							className='dt-matrix-risk-bullet'
							onClick={() => {
								selectRisk(r.id);
							}}
						>
							{'R' + r.secondary_id}
						</span>
					) : null,
				);
			}

			return null;
		},
		[risks, selectRisk],
	);

	return (
		<Container>
			<Row>
				<Col>
					{matrix && consequences && currency && likelihoods && props.risks && tableColors ? (
						<table className='dt-matrix'>
							{/* captions */}
							<caption
								style={{
									margin: 'auto',
									textAlign: 'center',
								}}
							>
								{tableColors.map((item) => {
									return (
										<Badge
											className='m-1 p-3 lg'
											style={{
												backgroundColor: `#${item.color}`,
												fontSize: '0.8rem',
												cursor: 'pointer',
											}}
											onClick={() => {
												props.filterRisks({ value: item.label, label: item.label });
											}}
										>
											{props.t(item.label)}
										</Badge>
									);
								})}
							</caption>

							<tbody>
								{matrix ? (
									<tr>
										<td></td>
										<td></td>
										<td></td>
										<td className='dt-matrix-x-axis-label' colSpan={matrix.length}>
											<div>{t('Damage in') + ` ${t(currency)}`}</div>
										</td>
									</tr>
								) : null}

								{/* consequences */}
								{consequences ? (
									<tr>
										<td></td>
										<td></td>
										<td></td>
										{consequences.map((i) => (
											<td key={i.id} className='dt-matrix-info dt-matrix-x-axis'>
												<div>
													<span className='dt-matrix-x-axis-title'>{t(i.label)}</span>
													<span className='dt-matrix-x-axis-value'>{'> ' + (Number(i.title) / 1000).toLocaleString("en-US") + 'K'}</span>
												</div>
											</td>
										))}
									</tr>
								) : null}

								{matrix
									? matrix.map((row, rowIndex) => {
											return (
												<>
													<tr key={row.rowIndex}>
														{rowIndex === 0 ? (
															<td
																style={{
																	width: '4%',
																	textAlign: 'center',
																}}
																className='dt-matrix-y-axis-label'
																rowSpan={matrix.length + 1}
															>
																{t('Likelihood')}
															</td>
														) : null}

														{/* likelihoods */}
														{likelihoods ? (
															<>
																<td style={{ width: '6%' }} className='dt-matrix-y-axis'>
																	<span className='dt-matrix-y-axis-value'>{likelihoods[rowIndex].label}%</span>
																</td>

																<td style={{ width: '10%' }} className='dt-matrix-y-axis'>
																	<span className='dt-matrix-y-axis-title'>{t(likelihoods[rowIndex].title)}</span>
																</td>
															</>
														) : null}

														{/* columns */}
														{row.map((col, colIndex) => {
															return (
																<td
																	key={col.id}
																	style={{
																		backgroundColor: `#${col.color}`,
																		width: `calc(80% / ${matrix.length} )`,
																	}}
																	className='dt-matrix-column'
																>
																	<div>{props.risks ? renderRiskBullets(likelihoods[rowIndex].title, consequences[colIndex].title) : null}</div>
																</td>
															);
														})}
													</tr>
												</>
											);
									  })
									: null}
							</tbody>
						</table>
					) : (
						<table className='dt-matrix'>
							{/* captions */}
							<caption
								style={{
									margin: 'auto',
									textAlign: 'center',
								}}
							>
								<Badge className='m-1 p-3 lg dt-skeleton dt-skeleton-table-caption-badge' style={{ display: 'inline-block' }}></Badge>
								<Badge className='m-1 p-3 lg dt-skeleton dt-skeleton-table-caption-badge' style={{ display: 'inline-block' }}></Badge>
								<Badge className='m-1 p-3 lg dt-skeleton dt-skeleton-table-caption-badge' style={{ display: 'inline-block' }}></Badge>
								<Badge className='m-1 p-3 lg dt-skeleton dt-skeleton-table-caption-badge' style={{ display: 'inline-block' }}></Badge>
								<Badge className='m-1 p-3 lg dt-skeleton dt-skeleton-table-caption-badge' style={{ display: 'inline-block' }}></Badge>
							</caption>

							<tbody>
								{/* x axis label */}
								<tr>
									<td></td>
									<td></td>
									<td></td>
									<td className='dt-matrix-x-axis-label' colSpan='5'>
										<div>
											Damage in <span className='dt-skeleton'></span>
										</div>
									</td>
								</tr>

								{/* x axis */}
								<tr>
									<td></td>
									<td></td>
									<td></td>
									<td className='dt-matrix-info dt-matrix-x-axis'>
										<div>
											<span className='dt-matrix-x-axis-title dt-skeleton'></span>
											<br />
											<span className='dt-matrix-x-axis-value dt-skeleton'></span>
										</div>
									</td>
									<td className='dt-matrix-info dt-matrix-x-axis'>
										<div>
											<span className='dt-matrix-x-axis-title dt-skeleton'></span>
											<br />
											<span className='dt-matrix-x-axis-value dt-skeleton'></span>
										</div>
									</td>
									<td className='dt-matrix-info dt-matrix-x-axis'>
										<div>
											<span className='dt-matrix-x-axis-title dt-skeleton'></span>
											<br />
											<span className='dt-matrix-x-axis-value dt-skeleton'></span>
										</div>
									</td>
									<td className='dt-matrix-info dt-matrix-x-axis'>
										<div>
											<span className='dt-matrix-x-axis-title dt-skeleton'></span>
											<br />
											<span className='dt-matrix-x-axis-value dt-skeleton'></span>
										</div>
									</td>
									<td className='dt-matrix-info dt-matrix-x-axis'>
										<div>
											<span className='dt-matrix-x-axis-title dt-skeleton'></span>
											<br />
											<span className='dt-matrix-x-axis-value dt-skeleton'></span>
										</div>
									</td>
								</tr>

								<tr>
									<td style={{ width: '4%' }} className='dt-matrix-y-axis-label' rowSpan='6'>
										Likelihood
									</td>
									<td style={{ width: '6%' }} className='dt-matrix-y-axis'>
										<div>
											<span className='dt-matrix-y-axis-value dt-skeleton'></span>
										</div>
									</td>
									<td style={{ width: '10%' }} className='dt-matrix-y-axis'>
										<div>
											<span className='dt-matrix-y-axis-title dt-skeleton'></span>
										</div>
									</td>
									<td style={{ width: '16%' }} className='dt-matrix-column dt-skeleton'></td>
									<td style={{ width: '16%' }} className='dt-matrix-column dt-skeleton'></td>
									<td style={{ width: '16%' }} className='dt-matrix-column dt-skeleton'></td>
									<td style={{ width: '16%' }} className='dt-matrix-column dt-skeleton'></td>
									<td style={{ width: '16%' }} className='dt-matrix-column dt-skeleton'></td>
								</tr>

								<tr>
									<td style={{ width: '6%' }} className='dt-matrix-y-axis'>
										<div>
											<span className='dt-matrix-y-axis-value dt-skeleton'></span>
										</div>
									</td>
									<td style={{ width: '10%' }} className='dt-matrix-y-axis'>
										<div>
											<span className='dt-matrix-y-axis-title dt-skeleton'></span>
										</div>
									</td>
									<td style={{ width: '16%' }} className='dt-matrix-column dt-skeleton'></td>
									<td style={{ width: '16%' }} className='dt-matrix-column dt-skeleton'></td>
									<td style={{ width: '16%' }} className='dt-matrix-column dt-skeleton'></td>
									<td style={{ width: '16%' }} className='dt-matrix-column dt-skeleton'></td>
									<td style={{ width: '16%' }} className='dt-matrix-column dt-skeleton'></td>
								</tr>
								<tr>
									<td style={{ width: '6%' }} className='dt-matrix-y-axis'>
										<div>
											<span className='dt-matrix-y-axis-value dt-skeleton'></span>
										</div>
									</td>
									<td style={{ width: '10%' }} className='dt-matrix-y-axis'>
										<div>
											<span className='dt-matrix-y-axis-title dt-skeleton'></span>
										</div>
									</td>
									<td style={{ width: '16%' }} className='dt-matrix-column dt-skeleton'></td>
									<td style={{ width: '16%' }} className='dt-matrix-column dt-skeleton'></td>
									<td style={{ width: '16%' }} className='dt-matrix-column dt-skeleton'></td>
									<td style={{ width: '16%' }} className='dt-matrix-column dt-skeleton'></td>
									<td style={{ width: '16%' }} className='dt-matrix-column dt-skeleton'></td>
								</tr>
								<tr>
									<td style={{ width: '6%' }} className='dt-matrix-y-axis'>
										<div>
											<span className='dt-matrix-y-axis-value dt-skeleton'></span>
										</div>
									</td>
									<td style={{ width: '10%' }} className='dt-matrix-y-axis'>
										<div>
											<span className='dt-matrix-y-axis-title dt-skeleton'></span>
										</div>
									</td>
									<td style={{ width: '16%' }} className='dt-matrix-column dt-skeleton'></td>
									<td style={{ width: '16%' }} className='dt-matrix-column dt-skeleton'></td>
									<td style={{ width: '16%' }} className='dt-matrix-column dt-skeleton'></td>
									<td style={{ width: '16%' }} className='dt-matrix-column dt-skeleton'></td>
									<td style={{ width: '16%' }} className='dt-matrix-column dt-skeleton'></td>
								</tr>
								<tr>
									<td style={{ width: '6%' }} className='dt-matrix-y-axis'>
										<div>
											<span className='dt-matrix-y-axis-value dt-skeleton'></span>
										</div>
									</td>
									<td style={{ width: '10%' }} className='dt-matrix-y-axis'>
										<div>
											<span className='dt-matrix-y-axis-title dt-skeleton'></span>
										</div>
									</td>
									<td style={{ width: '16%' }} className='dt-matrix-column dt-skeleton'></td>
									<td style={{ width: '16%' }} className='dt-matrix-column dt-skeleton'></td>
									<td style={{ width: '16%' }} className='dt-matrix-column dt-skeleton'></td>
									<td style={{ width: '16%' }} className='dt-matrix-column dt-skeleton'></td>
									<td style={{ width: '16%' }} className='dt-matrix-column dt-skeleton'></td>
								</tr>
							</tbody>
						</table>
					)}
				</Col>
			</Row>
		</Container>
	);
};

export default memo(RiskMatrix);
