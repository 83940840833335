import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Progress,
  FormGroup,
  Button,
  Alert,
  Label,
  Tooltip,
} from "reactstrap";
import { AvForm, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import classnames from "classnames";
import ContactForm from "./ContactForm";
import RecordingComponent from "./RecordingComponent";
import AttachmentsDropzone from "./AttachmentsDropzone";
import RegisterForm from "./RegisterForm";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import { errorNotification, successNotification } from "../../../store/actions";
import UserUtils from "../../../services/utils/UserUtils";
import {
  API_URL_GET_SPEECH_TO_TEXT,
  API_URL_GET_USER_DETAIL,
  API_URL_GET_VOICE_FILES,
  API_URL_SAVE_AI_TRANSCRIPTION,
  API_URL_SPEECH_CREATE,
  CM_API_URL_GET_USER_DETAIL,
  REPORT_CREATTION_FAILED,
} from "../../../common/constants";
import PerfectScrollbar from "react-perfect-scrollbar";
import ConsentModal from "../../Authentication/ConsentModal";

class VoiceReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [],
      progressValue: 0,
      isMounted: false,
      userSelection: "", // Track the user's selection of "Yes" or "No"
      contactForm: {
        title: "",
        relation: "",
        firstName: "",
        lastName: "",
        organization: "",
        position: "",
      },
      type: 0,
      email: "",
      // phone: null,
      isVoiceRecorded: false, // Track if at least one voice is recorded
      showProg: false,
      displayFollowUp: false,
      voice_ids: [],
      files: [],
      user: null,
      authorized: false,
      tabProccessed: false,
      isAnonymizing: false,
      isRecordingInProgress: false,
      isUploading: false,
      voicesForTranscription: [],
      transcribedVoices: [],
      attachmentSelection: null,
      defaultUserInfoFetched: false,
      // Consent
      regConsent: false,
      showConsentModal: false,
      loginConsent: false,
    };
    this.userUtils = new UserUtils();
  }

  componentDidMount() {
    this.renderTabs();
    this.setState({ isMounted: true });
    if (this.userUtils.isEmployee(this.props.user)) {
      this.setState({ userSelection: "no" });
    }
  }

  componentDidUpdate(prevProps) {
    const { defaultUserInfoFetched, contactForm } = this.state;
    const { user } = this.props;
    if (
      this.props.user &&
      this.props.user !== this.state.user &&
      !this.state.tabProccessed
    ) {
      // console.log("DID UPDATE")
      this.renderTabs();
      this.setState({ user: this.props.user });
    }

    if (!defaultUserInfoFetched && user) {
      if (
        user?.gender ||
        user?.first_name ||
        user?.last_name ||
        user?.organization ||
        user?.position
      ) {
        this.setState({
          ...this.state,
          defaultUserInfoFetched: true,
          contactForm: {
            ...this.state.contactForm,
            title: user?.gender ?? "",
            firstName: user?.first_name ?? "",
            lastName: user?.last_name ?? "",
            organization: user?.organization?.name ?? "",
            position: user?.position ?? "",
          },
        });
      } else {
        this.setState({
          ...this.state,
          ...this.state.contactForm,
          defaultUserInfoFetched: true,
        });
      }
    }

    if (prevProps.t !== this.props.t) {
      this.renderTabs();
    }
  }

  setGivenState = (state, val) => {
    this.setState({ [state]: val });
  };

  toggleTab = (tabId) => {
    const { tabs } = this.state;
    const newTabs = tabs.map((tab) => ({
      ...tab,
      active: tab.tabId === tabId,
    }));
    this.setState({ tabs: newTabs });
  };

  renderTabs = () => {
    this.setState({
      authorized: this.props.user ? true : false,
      tabs: !this.props.user
        ? [
            { tabId: 1, title: this.props.t("Create report"), active: true },
            { tabId: 2, title: this.props.t("Create account"), active: false },
            { tabId: 3, title: this.props.t("Finalize"), active: false },
          ]
        : [
            { tabId: 1, title: this.props.t("Create report"), active: true },
            { tabId: 2, title: this.props.t("Finalize"), active: false },
          ],
      progressValue: 0,
    });
  };

  handleBackButton = () => {
    const { tabs } = this.state;
    const activeTabIndex = tabs.findIndex((tab) => tab.active);
    const prevTabIndex = activeTabIndex - 1;
    if (prevTabIndex >= 0) {
      this.toggleTab(tabs[prevTabIndex].tabId);
    }
  };

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      contactForm: {
        ...prevState.contactForm,
        [name]: value,
      },
    }));
  };

  getFiles = async () => {
    const url = API_URL_GET_VOICE_FILES;
    const data = {
      voice_ids: this.state.voice_ids,
    };
    const response = axios.post(url, data).then((res) => {
      if (res.status === 200) {
        this.setState({ voicesForTranscription: res.data.voices });
      }
    });

    return response;
  };

  textToSpeech = async () => {
    const url = API_URL_GET_SPEECH_TO_TEXT;

    await this.getFiles();
    const voices = this.state.voicesForTranscription;
    const headers =
      this.props.user && this.props.token
        ? {
            headers: {
              Authorization: `Bearer ${this.props.token}`,
            },
          }
        : {};
    try {
      return axios
        .post(url, voices)
        .then((res) => {
          if (res.status === 200) {
            const results = res.data.results;
            this.setState({ transcribedVoices: results });
          }
          return res;
        })
        .catch(console.log);
    } catch (error) {
      console.error("Error converting to text:", error);
      this.setState({ loadingTranscribe: false });
    }
  };

  saveAiTranscript = async () => {
    const url = API_URL_SAVE_AI_TRANSCRIPTION;
    const { transcribedVoices } = this.state;

    try {
      const responses = await Promise.all(
        transcribedVoices.map(async (file) => {
          const response = await axios.put(
            url,
            {
              voiceId: file.voice_id,
              transcription: file.recognized_text,
            },
            {
              headers: {
                Authorization: `Bearer ${this.props.token}`,
              },
            }
          );
          return response;
        })
      );

      return responses;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  authUser = async () => {
    const url =
      localStorage.getItem("module") === "cm"
        ? CM_API_URL_GET_USER_DETAIL
        : API_URL_GET_USER_DETAIL;

    const response = axios.post(
      url,
      {
        organizationId: this.props.Organization?.id,
      },
      {
        headers: {
          Authorization: `Bearer ${this.props.token}`,
        },
      }
    );
    return response;
  };

  toggleConsentModal = () => {
    this.setState({
      showConsentModal: !this.state.showConsentModal,
    });

    this.toggleTab(1);
  };

  // checkConsent = async () => {
  //   const authResponse = await this.authUser();
  //   if (
  //     window.location.href.includes("third-party") &&
  //     authResponse?.data?.data?.consentStatus === false
  //   ) {
  //     return this.setState({ showConsentModal: true });
  //   } else {
  //     return this.createReport();
  //   }
  // }

  checkConsent = async () => {
    const authResponse = await this.authUser();
    if (
      window.location.href.includes("third-party") &&
      authResponse?.data?.data?.consentStatus === false
    ) {
      this.setState({ showConsentModal: true });
      return Promise.resolve(false); // Added to return a promise
    } else {
      return Promise.resolve(true); 
    }
  };

  createReport = async () => {
    const url = API_URL_SPEECH_CREATE;
    const { email } = this.state;

    if (email) {
      this.setState({
        ...this.state,
        contactDetails: {
          ...this.state.contactDetails,
          email: email,
        },
      });
    }

    const owner_id = this.props.user.id;
    const headers = {
      headers: {
        Authorization: `Bearer ${this.props.token}`,
      },
    };

    const data = {
      contactDetails: this.state.contactForm,
      voice_ids: this.state.voice_ids,
      files: this.state.files,
      module_id: Number(
        !localStorage.getItem("module") ||
          localStorage.getItem("module") === "wb"
          ? 1
          : 2
      ),
    };
    try {
      this.setState({ showConsentModal: false });
      const response = await axios.post(url, data, headers);
      const saveAiTranscription = await this.saveAiTranscript();
      const isSuccess = saveAiTranscription.every(
        (res) => res && (res.status === 200 || res.status === 201)
      );
      if (isSuccess) {
        return Promise.resolve({ success: true });
      } else {
        return Promise.reject({ success: false });
      }
    } catch (error) {
      this.props.errorNotification({
        message: this.props.t(REPORT_CREATTION_FAILED),
      });
      throw error;
    }
  };

  submitModal = async () => {
    const { tabs } = this.state;
    const activeTabIndex = tabs.findIndex((tab) => tab.active);
    const nextTabIndex = activeTabIndex + 1;
    console.log(tabs, activeTabIndex, nextTabIndex)
    const selectedTabTarget = nextTabIndex < tabs[nextTabIndex.length] ? tabs[nextTabIndex].tabId : undefined;
    console.log("Submit Modal")
    this.createReport()
      .then((response) => {
        console.log("Res", response)
        if (response ) {
          if (!selectedTabTarget) {
            this.toggleTab(2);
            this.updateProgressValue();
            this.setState({ showProg: false, authorized: true, tabProccessed: true });
            // this.toggleTab(tabs[tabs.length].tabId);
            // this.updateProgressValue(tabs[tabs.length].tabId);
            console.log("If")
            return;
          } else {
            console.log("Else")
            this.setState({ showProg: false });
            this.toggleTab(tabs[nextTabIndex].tabId);
            this.updateProgressValue(nextTabIndex + 1);
            return;
          }
        } else {
          this.toggleTab(tabs[0].tabId);
          this.updateProgressValue();
          return this.props.errorNotification({
            code: "",
            message: this.props.t(REPORT_CREATTION_FAILED),
          });
        }
      })
      .catch((error) => {
        this.toggleTab(tabs[0].tabId);
        this.updateProgressValue();
        return this.props.errorNotification({
          code: "",
          message: this.props.t(REPORT_CREATTION_FAILED),
        });
      });
  };

  goToNextTab = async () => {
    this.setState({ tabProccessed: true });
    if (this.props.user) {
      this.setState({ showProg: true }, async () => {
        const { tabs } = this.state;
        this.toggleTab(2);
        this.updateProgressValue();
        this.checkConsent();
        
        const consentCheck = await this.checkConsent();
        if (consentCheck) {
          const res = this.createReport()
            .then((response) => {
              if (response) {
                this.setState({ showProg: false });
                return;
              } else {
                this.toggleTab(tabs[0].tabId);
                this.updateProgressValue();
                return this.props.errorNotification({
                  code: "",
                  message: this.props.t(REPORT_CREATTION_FAILED),
                });
              }
            })
            .catch((error) => {
              this.toggleTab(tabs[0].tabId);
              this.updateProgressValue();
              return this.props.errorNotification({
                code: "",
                message: this.props.t(REPORT_CREATTION_FAILED),
              });
            });
        }
      });
    } else {
      const { tabs } = this.state;
      const activeTabIndex = tabs.findIndex((tab) => tab.active);
      if (this.state.isVoiceRecorded) {
        const nextTabIndex = activeTabIndex + 1;
        if (nextTabIndex < tabs.length) {
          this.toggleTab(tabs[nextTabIndex].tabId);
          this.updateProgressValue(nextTabIndex + 1);
        } else {
          console.log("Form submitted successfully");
        }
      } else {
        console.log("Please record at least one voice");
      }
    }
  };

  handleValidSubmit = async (event, values) => {
    const { tabs } = this.state;
    this.setState({ tabProccessed: true });
    const activeTabIndex = tabs.findIndex((tab) => tab.active);
    if (this.state.isVoiceRecorded) {
      const nextTabIndex = activeTabIndex + 1;
      if (nextTabIndex < tabs.length) {
        const consentCheck = await this.checkConsent();
        if (consentCheck) {
          const res = this.createReport()
            .then((response) => {
              if (response && response.success) {
                this.setState({ showProg: false });
                this.toggleTab(tabs[nextTabIndex].tabId);
                this.updateProgressValue(nextTabIndex + 1);
                return;
              } else {
                this.toggleTab(tabs[0].tabId);
                this.updateProgressValue();
                return this.props.errorNotification({
                  code: "",
                  message: this.props.t(REPORT_CREATTION_FAILED),
                });
              }
            })
            .catch((error) => {
              this.setState({ showProg: false });
              this.toggleTab(tabs[0].tabId);
              this.updateProgressValue();
              return this.props.errorNotification({
                code: "",
                message: this.props.t(REPORT_CREATTION_FAILED),
              });
            });
        }
      } else {
        console.log("Form submitted successfully");
      }
    } else {
      console.log("Please record at least one voice");
    }
  };

  handleRecordingComplete = () => {
    this.setState({ isVoiceRecorded: true });
  };

  handleUploadCompletion = () => {
    this.setState({ isUploadCompleted: true });
  };

  updateProgressValue = (activeTabIndex = 1) => {
    const { tabs } = this.state;
    const totalTabs = tabs.length;
    const progressValue = (activeTabIndex / totalTabs) * 100;

    this.setState({ progressValue });
  };

  getUserHome() {
    return this.userUtils.getUserHomeRoute(this.props.user);
  }

  render() {
    const {
      isMounted,
      tabs,
      contactForm,
      userSelection,
      showProg,
      voice_ids,
      files,
      authorized,
      isAnonymizing,
      isRecordingInProgress,
      isUploading,
      attachmentSelection,
    } = this.state;
    const { t, user } = this.props;

    if (!isMounted) {
      return null;
    }

    const activeTabIndex = tabs.findIndex((tab) => tab.active);
    const progressValue = ((activeTabIndex + 1) / tabs.length) * 100;

    return (
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div id="progrss-wizard" className="twitter-bs-wizard">
                    <ul className="twitter-bs-wizard-nav nav-justified nav nav-pills">
                      {tabs.map((tab) => (
                        <NavItem key={tab.tabId}>
                          <NavLink
                            className={classnames({ active: tab.active })}
                          >
                            <span className="step-number">{tab.tabId}</span>
                            <span className="step-title">{tab.title}</span>
                          </NavLink>
                        </NavItem>
                      ))}
                    </ul>

                    <div id="bar" className="mt-4">
                      <Progress
                        color="success"
                        striped
                        animated
                        value={progressValue}
                      />
                    </div>

                    {this.state.tabs.length > 0 && (
                      <TabContent
                        activeTab={tabs.find((tab) => tab.active).tabId}
                        className="mt-4"
                      >
                        {tabs.map((tab) => (
                          <TabPane key={tab.tabId} tabId={tab.tabId}>
                            {tab.tabId === 1 ? (
                              <AvForm>
                                <FormGroup
                                  hidden={this.userUtils.isEmployee(
                                    this.props.user
                                  )}
                                >
                                  <Label>
                                    {t(
                                      "Do you want to provide your contact details?"
                                    )}
                                    <span className="ms-2">
                                      <i
                                        className="fa fa-info-circle"
                                        aria-hidden="true"
                                        id="privacyIconU"
                                      />

                                      <Tooltip
                                        autohide={false}
                                        placement="top"
                                        isOpen={this.state.privacyTooltipOpen}
                                        target="privacyIconU"
                                        toggle={() =>
                                          this.setState({
                                            privacyTooltipOpen:
                                              !this.state.privacyTooltipOpen,
                                          })
                                        }
                                      >
                                        <PerfectScrollbar
                                          style={{
                                            maxHeight: "calc(100vh / 2)",
                                          }}
                                        >
                                          {
                                            <>
                                              <strong>
                                                {this.props.t(
                                                  "Your Privacy Matters"
                                                ) + ":"}
                                              </strong>
                                              <p>
                                                {this.props.t(
                                                  "We understand the importance of your privacy while using our web application. If you choose not to share your contact details, rest assured that your report will be submitted anonymously. Your trust is important to us, and we want to make sure you feel comfortable using our platform."
                                                )}
                                              </p>
                                              <strong>
                                                {this.props.t(
                                                  "Logged In and Secure"
                                                ) + ":"}
                                              </strong>
                                              <p>
                                                {this.props.t(
                                                  "Even if you're logged in with your account, please note that your account credentials will remain confidential. When our analyst reviews and processes your anonymous case, they will not have access to your personal account information. Your data security is a top priority for us. We're here to provide you with a safe and secure experience while using our web application. If you have any questions or concerns, don't hesitate to reach out to our support team. Your privacy and peace of mind are our commitment."
                                                )}
                                              </p>
                                            </>
                                          }
                                        </PerfectScrollbar>
                                      </Tooltip>
                                    </span>
                                  </Label>
                                  <AvRadioGroup name="contactDetails" required>
                                    <Row className="mb-3">
                                      <Col sm="3" lg="3">
                                        <AvRadio
                                          label={t("Yes")}
                                          value="yes"
                                          onChange={(e) =>
                                            this.setState({
                                              userSelection: e.target.value,
                                              type: 2,
                                            })
                                          }
                                        />
                                      </Col>

                                      <Col sm="3" lg="3">
                                        <div
                                          className="radio-label-container"
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <AvRadio
                                            label={t("No")}
                                            value="no"
                                            onChange={(e) =>
                                              this.setState({
                                                userSelection: e.target.value,
                                                type: 1,
                                              })
                                            }
                                          />
                                          <span
                                            className="tooltip-container"
                                            style={{ marginLeft: "10px" }}
                                          >
                                            <i
                                              className="fa fa-info-circle"
                                              aria-hidden="true"
                                              id="anonymityIconU"
                                            />
                                            <Tooltip
                                              placement="right"
                                              isOpen={
                                                this.state.anonymityTooltipOpen
                                              }
                                              target="anonymityIconU"
                                              toggle={() =>
                                                this.setState({
                                                  anonymityTooltipOpen:
                                                    !this.state
                                                      .anonymityTooltipOpen,
                                                })
                                              }
                                            >
                                              <p>
                                                {this.props.t(
                                                  "To ensure anonymity, your voice record will be processed with a voice distorter. The original voice file is not saved."
                                                )}
                                              </p>
                                            </Tooltip>
                                          </span>
                                        </div>
                                      </Col>
                                    </Row>
                                  </AvRadioGroup>
                                </FormGroup>
                                {userSelection === "yes" ||
                                userSelection === "no" ? (
                                  <>
                                    <div hidden={userSelection === "no"}>
                                      <ContactForm
                                        t={t}
                                        contactForm={contactForm}
                                        handleInputChange={
                                          this.handleInputChange
                                        }
                                        userSelection={userSelection}
                                      />
                                    </div>
                                    <RecordingComponent
                                      t={t}
                                      onComplete={this.handleRecordingComplete}
                                      user={user}
                                      voiceIds={voice_ids}
                                      errorNotification={
                                        this.props.errorNotification
                                      }
                                      successNotification={
                                        this.props.successNotification
                                      }
                                      isAnonymizing={isAnonymizing}
                                      isRecordingInProgress={
                                        isRecordingInProgress
                                      }
                                      setGivenState={this.setGivenState}
                                    />
                                    <FormGroup>
                                      <Label>
                                        {t(
                                          "Would you like to upload attachments?"
                                        )}
                                      </Label>
                                      <AvRadioGroup
                                        name="haveAttachments"
                                        // required
                                      >
                                        <Row className="mb-3">
                                          <Col sm="3" lg="3">
                                            <AvRadio
                                              label={t("Yes")}
                                              value="yes"
                                              onChange={(e) =>
                                                this.setState({
                                                  attachmentSelection:
                                                    e.target.value,
                                                })
                                              }
                                            />
                                          </Col>
                                          <Col sm="3" lg="3">
                                            <div
                                              className="radio-label-container"
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                            >
                                              <AvRadio
                                                label={t("No")}
                                                value="no"
                                                onChange={(e) =>
                                                  this.setState({
                                                    attachmentSelection:
                                                      e.target.value,
                                                    files: [],
                                                  })
                                                }
                                              />
                                            </div>
                                          </Col>
                                        </Row>
                                      </AvRadioGroup>
                                    </FormGroup>

                                    <div
                                      hidden={
                                        !attachmentSelection ||
                                        attachmentSelection == "no"
                                      }
                                    >
                                      <AttachmentsDropzone
                                        t={t}
                                        uploadedFiles={this.state.uploadedFiles}
                                        token={this.props.token}
                                        user={this.props.user}
                                        handleClickDeleteFiles={
                                          this.handleClickDeleteFiles
                                        }
                                        successNotification={
                                          this.props.successNotification
                                        }
                                        isUploadingInProgress={isUploading}
                                        setGivenState={this.setGivenState}
                                        errorNotification={
                                          this.props.errorNotification
                                        }
                                        updateFileState={(e) =>
                                          this.state.files.push(e)
                                        }
                                        files={files}
                                      />
                                    </div>
                                  </>
                                ) : null}
                                <Button
                                  onClick={this.goToNextTab}
                                  type="submit"
                                  color="primary"
                                  disabled={
                                    isAnonymizing ||
                                    isRecordingInProgress ||
                                    isUploading ||
                                    voice_ids.length === 0
                                  }
                                >
                                  {this.userUtils.isEmployee(this.props.user)
                                    ? t("Send")
                                    : t("Next")}
                                </Button>{" "}
                                {/* Use Button component from reactstrap */}
                              </AvForm>
                            ) : !authorized && tab.tabId === 2 ? (
                              // <>
                              !showProg ? (
                                <RegisterForm
                                  t={t}
                                  handleBackButton={this.handleBackButton}
                                  setGivenState={this.setGivenState}
                                  handleValidSubmit={this.handleValidSubmit}
                                />
                              ) : (
                                <p hidden={!this.state.showProg}>
                                  {t(
                                    "Thank you for your patience while your request is being processed."
                                  )}
                                </p>
                              )
                            ) : (!authorized && tab.tabId === 3) ||
                              (authorized && tab.tabId === 2) ? (
                              <div>
                                {!showProg ? (
                                  <Alert
                                    color="success"
                                    className="alert-dismissible fade show"
                                    role="alert"
                                  >
                                    <i className="mdi mdi-check-all me-2"></i>
                                    <b>{this.props.t("Report Submitted")}</b>
                                    <p>
                                      {this.props.t(
                                        "If you have any questions, please contact the technical support by sending an email to support@diss-co.tech."
                                      )}
                                    </p>
                                    <p>
                                      {this.props.t(
                                        "If you receive error messages or have a technical questions, please make a screenshot of the error message or your view including the URL and send your request by email to support@diss-co.tech"
                                      )}
                                    </p>
                                  </Alert>
                                ) : (
                                  <p hidden={!this.state.showProg}>
                                    {t(
                                      "Thank you for your patience while your request is being processed."
                                    )}
                                  </p>
                                )}

                                <Row
                                  hidden={
                                    isAnonymizing ||
                                    isUploading ||
                                    isRecordingInProgress ||
                                    showProg
                                  }
                                >
                                  <Col sm="12 m-t-15">
                                    <Link to={this.getUserHome()}>
                                      <Button color="secondary">
                                        {this.props.t("Back To reports")}
                                      </Button>
                                    </Link>
                                  </Col>
                                </Row>
                              </div>
                            ) : null}
                          </TabPane>
                        ))}
                      </TabContent>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <ConsentModal
          modal={this.state.showConsentModal}
          isTP={window.location.href.includes("thrid-party")}
          toggle={this.toggleConsentModal}
          consent={this.state.loginConsent}
          setConsent={() =>
            this.setState({ loginConsent: !this.state.loginConsent })
          }
          loginData={this.props.token}
          doLogin={this.submitModal}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { token, user } = state.Login;
  const { Organization, App } = state;
  return { token, Organization, user, App };
};

export default withNamespaces()(
  withRouter(
    connect(mapStateToProps, { errorNotification, successNotification })(
      VoiceReport
    )
  )
);
