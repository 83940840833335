import {
  GET_CHATS,
  GET_CHATS_FAIL,
  GET_CHATS_SUCCESS,
  START_CHAT,
  GET_GROUPS,
  GET_GROUPS_FAIL,
  GET_GROUPS_SUCCESS,
  GET_CONTACTS,
  GET_CONTACTS_FAIL,
  GET_CONTACTS_SUCCESS,
  GET_MESSAGES,
  GET_MESSAGES_FAIL,
  GET_MESSAGES_SUCCESS,
  POST_ADD_MESSAGE,
  POST_ADD_MESSAGE_FAIL,
  POST_ADD_MESSAGE_SUCCESS,
  START_CHAT_SUCCESS,
  START_CHAT_FAIL,
  SEND_MESSAGE,
  FINISH_CHAT,
  FINISH_CHAT_SUCCESS,
  FINISH_CHAT_FAIL,
  DELETE_CHAT,
  DELETE_CHAT_SUCCESS,
  CHANGE_CHATS_LOADING,
  CHANGE_MESSAGES_LOADING
} from "./actionTypes"

export const getChats = () => ({
  type: GET_CHATS,
})

export const changeChatLoading = status => ({
  type: CHANGE_CHATS_LOADING,
  payload: status,
})

export const getChatsSuccess = chats => ({
  type: GET_CHATS_SUCCESS,
  payload: chats,
})

export const getChatsFail = error => ({
  type: GET_CHATS_FAIL,
  payload: error,
})

export const startChat = (payload) => ({
  type: START_CHAT,
  payload: payload
})

export const startChatSuccess = chatId => ({
  type: START_CHAT_SUCCESS,
  payload: chatId,
})

export const startChatFail = error => ({
  type: START_CHAT_FAIL,
  payload: error,
})

export const finishChatSuccess = () => ({
  type: FINISH_CHAT_SUCCESS
})

export const finishChatFail = error => ({
  type: FINISH_CHAT_FAIL,
  payload: error,
})

export const finishChat = (id) => ({
  type: FINISH_CHAT,
  payload: id
})

export const getGroups = () => ({
  type: GET_GROUPS,
})

export const getGroupsSuccess = groups => ({
  type: GET_GROUPS_SUCCESS,
  payload: groups,
})

export const getGroupsFail = error => ({
  type: GET_GROUPS_FAIL,
  payload: error,
})

export const getContacts = () => ({
  type: GET_CONTACTS,
})

export const getContactsSuccess = contacts => ({
  type: GET_CONTACTS_SUCCESS,
  payload: contacts,
})

export const getContactsFail = error => ({
  type: GET_CONTACTS_FAIL,
  payload: error,
})

export const getMessages = chatId => ({
  type: GET_MESSAGES,
  chatId,
})

export const getMessagesSuccess = messages => ({
  type: GET_MESSAGES_SUCCESS,
  payload: messages,
})

export const changeMessagesLoading = status => ({
  type: CHANGE_MESSAGES_LOADING,
  payload: status,
})

export const getMessagesFail = error => ({
  type: GET_MESSAGES_FAIL,
  payload: error,
})

export const addMessage = message => ({
  type: POST_ADD_MESSAGE,
  message,
})

export const sendMessage = message => ({
  type: SEND_MESSAGE,
  message,
})

export const addMessageSuccess = message => ({
  type: POST_ADD_MESSAGE_SUCCESS,
  payload: message,
})

export const addMessageFail = error => ({
  type: POST_ADD_MESSAGE_FAIL,
  payload: error,
})

export const deleteChat = id => ({
  type: DELETE_CHAT,
  payload: id,
})

export const deleteChatSuccess = id => ({
  type: DELETE_CHAT_SUCCESS,
  payload: id,
})