import { memo, useEffect, useState } from "react";

import Select from "react-select";
import { Col, Row, Label, Tooltip } from "reactstrap";
import { withNamespaces } from "react-i18next";
import { AvField } from "availity-reactstrap-validation";
import { useQuery } from "@tanstack/react-query";
import CommonService from "src/modules/data-protection/apis/CommonService";

const CountryList = (props) => {
  const {
    id,
    title,
    isMandatory,
    key,
    elRefIdPrefix,
    options,
    defaultValue,
    helpText,
    onChange,
    extended_title,
    isMulti
  } = props;
  
  const [selectedCountry, setSelectedCountry] = useState(
    defaultValue && Array.isArray(defaultValue) && defaultValue.length > 0
    ? defaultValue.map(country => ({
        label: country.title,
        value: country.value,
      }))
    : []
  );

  const [helpTooltipTextStatus, setHelpTooltipTextStatus] = useState(false);


  return (
    <Row key={key}>
      <Col sm="12">
        <div className="mb-3 position-relative">
          <Label
            className="form-label"
            htmlFor={`${elRefIdPrefix}-question-${id}`}
          >
            {props.t(extended_title || title)}

            {helpText && (
              <span className="ms-2 tooltip-container help-text-icon">
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  id={`${elRefIdPrefix}-question-${id}-help-text`}
                />
                <Tooltip
                  style={{
                    backgroundColor: "#899ba1",
                    fontWeight: "bold",
                  }}
                  placement="top"
                  isOpen={helpTooltipTextStatus}
                  target={`${elRefIdPrefix}-question-${id}-help-text`}
                  toggle={() =>
                    setHelpTooltipTextStatus(!helpTooltipTextStatus)
                  }
                >
                  {<p>{props.t(helpText)}</p>}
                </Tooltip>
              </span>
            )}
          </Label>
          <Select
            className="mt-1 text-capitalize"
            placeholder={props.t("Select") + "..."}
            id={`${elRefIdPrefix}-question-${id}`}
            value={selectedCountry}
            onChange={(newCountry) => {
              setSelectedCountry(newCountry);
              onChange(newCountry);
            }}
            options={(options || []).map((optionItem) => {
              return {
                label: optionItem.name,
                value: optionItem.id,
              };
            })}
            menuPortalTarget={document.body}
            isMulti={isMulti}
          />
          <AvField
            className="d-none"
            name={"question[" + id + "]"}
            placeholder=""
            type="text"
            errorMessage={props.t("This field cannot be blank")}
            validate={{
              required: { value: isMandatory },
            }}
            value={selectedCountry}
          />
        </div>
      </Col>
    </Row>
  );
};

export default withNamespaces()(memo(CountryList));
