import React from "react";
import { VectorMap } from "react-jvectormap";
import { withNamespaces } from "react-i18next";
import i18next from "i18next";

import "./style.css";

const GlobalMap = (props) => {
	const { countries } = props;

  const hasCountryData = countries ? Object.keys(countries)?.length > 0 : false;
  
	const colorScale = hasCountryData
		? ["#e5d1f9", "#a667e5", "#843dcc", "#661ab2", "#5606a5"] 
		// ["#ddc6f4", "#5606A5"]  // Darkest color for the country with the largest number, lighter for others
		: ["#e8ecf4", "#e8ecf4"]; // Light color for all countries when there is no data
		

	return (
		<div>
			<div>
				<div className="map-container" style={{ overflow: "hidden" }}>
					<VectorMap
						map={"world_mill"}
						backgroundColor="transparent"
						zoomOnScroll={false}
						onRegionTipShow={(e, el, code) => {
							el.text(i18next.t(el.text()) + " (" + countries[code] + ")");
						}}
						onRegionOut={(e, code) => {
							Array.from(document.getElementsByClassName("jvectormap-tip")).forEach((el) => {
								el.style.display = "none";
							});
						}}
						containerClassName="map"
						regionStyle={{
							initial: {
								fill: "#e4e4e4",
								"fill-opacity": 0.9,
								stroke: "none",
								"stroke-width": 0,
								"stroke-opacity": 0,
								cursor: "pointer",
							},
							hover: {
								"fill-opacity": 0.8,
								cursor: "pointer",
							},
							selected: {
								fill: "#212121",
								stroke: "#d1d1d1",
								"stroke-width": 1,
							},
							selectedHover: {
								"fill-opacity": 0.8,
							},
						}}
						regionsSelectable={false}
						series={{
							regions: [
								{
									values: countries,
									// scale: ["#ddc6f4", "#5606A5"],
									scale: colorScale,
									normalizeFunction: "polynomial",
								},
							],
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export default withNamespaces()(GlobalMap);
