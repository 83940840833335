import React from "react";
import { Card, Row, Col, Progress } from "reactstrap"; 
import { FaFile } from "react-icons/fa"; 
import { Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";

const AttachmentCard = ({
  attachment,
  downloadUrl,
  handleClickDeleteFile,
  t,
}) => {
  return (
    <Card className="mb-0 p-2 px-3 shadow-none dz-processing dz-image-preview dz-success dz-complete attachment-Card">
      <div className="px-2">
        <Row className="align-items-center">
          <Col className="col-auto px-1">
            <FaFile size={32} />
          </Col>
          <Col>
            <Link
              to={`${downloadUrl}${attachment.id}/download`}
              className="file-name"
              target="_blank"
              rel="noopener noreferrer" 
              onClick={(e) => {
                if (attachment.id === null) {
                    e.preventDefault(); // Prevent the link from navigating if attachment.id is null
                }
            }}
            >
              {attachment.path}
            </Link>
            <p className="upload-details-container">
              <span className="file-size">
                {(attachment.formattedSize / 1024).toFixed(2)} KB
              </span>
              <>
                {attachment.status === "upload_succeed" && (
                  <span className="upload-status-message succeed">
                    {t("File(s) successfully uploaded.")}
                  </span>
                )}
                {attachment.status === "upload_failed" && (
                  <span className="upload-status-message failed">
                    {t("File(s) failed to upload.")}
                  </span>
                )}
                {attachment.status === "deleting" && (
                  <span className="upload-status-message failed mx-1">
                    {t("File(s) deleting")}
                  </span>
                )}
              </>
            </p>
          </Col>
          <Col className=" col-auto px-1 actions-container">
            {attachment.status === "uploading" && (
              <span className="uploaded-percent">
                {Math.ceil(attachment.uploadedPercent)}%
              </span>
            )}

            <button
              type="button"
              className="btn noborder"
              onClick={() =>
                attachment.status !== "uploading" &&
                attachment.status !== "deleting" &&
                handleClickDeleteFile(attachment)
              }
            >
              {attachment.status === "uploading" ||
              attachment.status === "deleting" ? (
                <i className="ri-forbid-line font-size-20"></i>
              ) : (
                <i className="ri-close-line font-size-20"></i>
              )}
            </button>
          </Col>
        </Row>
      </div>
      {attachment.status === "uploading" && (
        <Progress value={Math.ceil(attachment.uploadedPercent)} />
      )}
    </Card>
  );
};

export default withNamespaces()(AttachmentCard);
