import React, { useState, useEffect, memo } from "react";
import { Accordion } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { Input, Form, FormGroup, Label, Col, Row, Progress } from "reactstrap";
import Select from "react-select";
import DateUtils from "src/services/utils/DateUtils";

const BasicInformation = (props) => {
  const {
    t,
    handleFetchHeaderInformation,
    // Analysts
    users,
    handleFetchUsersListQuery,
    selectedAssignedAnalyst,
    updatingSelectedAssignedAnalyst,
    changeAssignedAnalyst,
    // Status
    changeStatus,
    updatingSelectedStatus,
    // Revision
    changeRevision,
    updatingSelectedRivision,
  } = props;

  const dateUtils = new DateUtils();

  const allStatuses = {
    IN_PROGRESS: t("In Progress"),
    APPROVED: t("Approved"),
    COMPLETED: t("Completed"),
  };

  const [completedQuestions, setCompletedQuestions] = useState({
    assignedAnalyst: false,
    status: false,
    nextRevision: false,
  });
  const [percentage, setPercentage] = useState(0);

  const [statuses, setStatuses] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedNextRevision, setSelectedNextRevision] = useState(null);

  /************************************* FUNCTIONS **************************** */
  const updateProgressBar = () => {
    const totalQuestions = Object.keys(completedQuestions)?.length;
    const completedCount =
      Object.values(completedQuestions).filter(Boolean)?.length;
    const completionPercentage = Math.round(
      (completedCount / totalQuestions) * 100
    );
    setPercentage(completionPercentage);
  };

  const handleFetchStatuses = () => {
    const status = Object.keys(allStatuses).map((key) => ({
      label: t(allStatuses[key]),
      value: key,
    }));
    setStatuses(status);
  };

  /************************************* USE EFFECTS **************************** */
  useEffect(() => {
    updateProgressBar();
  }, [completedQuestions]);

  useEffect(() => {
    setCompletedQuestions({
      assignedAnalyst: selectedAssignedAnalyst ? true : false,
      status: selectedStatus ? true : false,
      nextRevision: selectedNextRevision ? true : false,
    });
    updateProgressBar();
  }, [selectedAssignedAnalyst, selectedStatus, selectedNextRevision]);

  useEffect(() => {
    const { data } = handleFetchHeaderInformation;
    if (data?.status) {
      const status = statuses.find((status) => {
        return status.value.toLowerCase() === data.status.toLowerCase();
      });

      setSelectedStatus(status || null);
    }

    if (data?.next_revision) {
      setSelectedNextRevision(data?.next_revision);
    }
  }, [statuses, handleFetchHeaderInformation]);

  useEffect(() => {
    handleFetchStatuses();
  }, [t]);

  return (
    <Row>
      <Col sm="12">
        <Accordion defaultActiveKey="0">
          <Accordion.Item className="border-none" eventKey="0">
            <Accordion.Header>
              <div className="p-1 accordion_progress">
                {`${t("Basic Information")}`}{" "}
                <div className="inner_progress">
                  <Progress
                    bar
                    className="my-1 progress"
                    animated
                    value={percentage}
                    color={
                      percentage === 100
                        ? "success"
                        : percentage === "0"
                        ? ""
                        : "warning"
                    }
                  />
                  <span className="progress_label">{`${percentage}%`}</span>
                </div>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                <Col lg="4" md="4" sm="12" className="mt-3">
                  <FormGroup>
                    <Label
                      for="assignedAnalyst"
                      className={
                        !completedQuestions.assignedAnalyst ? "text-danger" : ""
                      }
                    >
                      {t("Assigned Analyst") + ":"}
                    </Label>
                    <Select
                      className={`mt-1 text-capitalize text-danger ${
                        !completedQuestions.assignedAnalyst
                          ? "border border-danger"
                          : ""
                      }`}
                      placeholder={t("Select") + "..."}
                      id="assignedAnalyst"
                      name="assignedAnalyst"
                      options={users}
                      isLoading={
                        handleFetchUsersListQuery.isLoading ||
                        handleFetchUsersListQuery.isFetching ||
                        handleFetchHeaderInformation.isLoading ||
                        handleFetchHeaderInformation.isFetching ||
                        updatingSelectedAssignedAnalyst
                      }
                      isError={handleFetchUsersListQuery.isError}
                      menuPortalTarget={document.body}
                      isDisabled={
                        handleFetchUsersListQuery.isLoading ||
                        handleFetchUsersListQuery.isFetching ||
                        handleFetchHeaderInformation.isLoading ||
                        handleFetchHeaderInformation.isFetching ||
                        updatingSelectedAssignedAnalyst
                      }
                      value={selectedAssignedAnalyst}
                      onChange={changeAssignedAnalyst}
                    />
                  </FormGroup>
                </Col>

                <Col lg="4" md="4" sm="12" className="mt-3">
                  <Form>
                    <FormGroup>
                      <Label
                        for="statuses"
                        className={
                          !completedQuestions.status ? "text-danger" : ""
                        }
                      >
                        {t("Status") + ":"}
                      </Label>
                      <Select
                        className={`mt-1 text-capitalize ${
                          !completedQuestions.status
                            ? "border border-danger"
                            : ""
                        }`}
                        placeholder={t("Select") + "..."}
                        id="statuses"
                        name="statuses"
                        value={selectedStatus}
                        onChange={changeStatus}
                        options={statuses}
                        menuPortalTarget={document.body}
                        isLoading={
                          handleFetchHeaderInformation.isLoading ||
                          handleFetchHeaderInformation.isFetching ||
                          updatingSelectedStatus
                        }
                        isDisabled={
                          handleFetchHeaderInformation.isLoading ||
                          handleFetchHeaderInformation.isFetching ||
                          updatingSelectedStatus
                        }
                      />
                    </FormGroup>
                  </Form>
                </Col>

                <Col lg="4" md="4" sm="12" className="mt-3">
                  <FormGroup>
                    <Label
                      for="next-revision"
                      className={
                        !completedQuestions.nextRevision ? "text-danger" : ""
                      }
                    >
                      {t("Next Revision") + ":"}
                    </Label>
                    <Input
                      id="next-revision"
                      type="date"
                      className={`mt-1 ${
                        !completedQuestions.nextRevision
                          ? "border border-danger"
                          : ""
                      }`}
                      isLoading={
                        handleFetchHeaderInformation.isLoading ||
                        handleFetchHeaderInformation.isFetching ||
                        updatingSelectedRivision
                      }
                      isDisabled={
                        handleFetchHeaderInformation.isLoading ||
                        handleFetchHeaderInformation.isFetching ||
                        updatingSelectedRivision
                      }
                      value={
                        selectedNextRevision
                          ? dateUtils
                              .convertTimestampToOnlyDate(selectedNextRevision)
                              .toString()
                              .split("/")
                              .reverse()
                              .join("-")
                          : ""
                      }
                      onChange={(e) => changeRevision(e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Col>
    </Row>
  );
};

const mapStatetoProps = (state) => {
  const { Organization } = state;

  return {
    Organization,
  };
};

export default withNamespaces()(
  connect(mapStatetoProps)(memo(BasicInformation))
);
