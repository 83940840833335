import { withRouter, useHistory } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import React, { useState, memo } from "react";
import { useQuery } from "@tanstack/react-query";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import AIGetHelpIcon from "src/modules/3rd-party-management/assets/images/microchip/primary.png";

import Accordion from 'react-bootstrap/Accordion';

import {
    Col, Row, Label, Button, Modal, ModalHeader, ModalBody,
    Spinner
  } from "reactstrap";

import DateUtils from "src/services/utils/DateUtils";

import {
    INTERNATIONAL_DATE_FORMAT
} from 'src/common/constants';

import uploadedFileIcon from 'src/assets/images/uploaded-file.svg';

import AIRiskAssessmentModal from "src/modules/3rd-party-management/components/RisksAndTasks/risks/ai/AssessmentModal";

import SupplierReportsService from "src/modules/3rd-party-management/apis/SupplierReportsService";

import { QuestionnaireSectionsImages } from 'src/modules/3rd-party-management/constants/Common';

import {
    CHAT_BOT_DOMAIN,
    API_URL_RISK_AI_GET_ADDITIONAL_DETAILS,
    API_BASE_URL
} from 'src/modules/3rd-party-management/constants';

import ReportDetailsPDF from './export/PDF';

import { pdf } from "@react-pdf/renderer";

import { saveAs } from "file-saver";

const SupplierReportDetails = (props) => {
    const {
        organization,
        t, 
        reportId,
        supplierInfos
    } = props;

    const history = useHistory();

    const [ activeAccordion, setActiveAccordion ] = useState(null);

    const [ exportPDFInprocess, setExportPDFInprocess ] = useState(false);

    const [ aiBasedRiskAssessmentModalStatus, setAiBasedRiskAssessmentModalStatus ] = useState(false);

    const dateUtils = new DateUtils();

    const handleFetchSupplierReportDetailsQuery = useQuery({
		queryKey: ['3rd-party-management-fetch-supplier-report-details', reportId],
		queryFn: async () => {
			const service = SupplierReportsService.getInstance();

            return await service.details(reportId, {});
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			toast(t('An error occurred while fetching report details.'), {
				type: 'error',
			});
		},
	});

    const {
        refetch: fetchReportPDFData
    } = useQuery({
		queryKey: ['3rd-party-management-fetch-supplier-report-pdf-details', reportId],
		queryFn: async () => {
            setExportPDFInprocess(true);
			const service = SupplierReportsService.getInstance();

            return await service.fetchPDFData(reportId);
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
        enabled: false,
		onError: (error) => {
			toast(t('An error occurred while fetching report details.'), {
				type: 'error',
			});

            setExportPDFInprocess(false);
		},
        onSuccess: async ({
            answers,
            financialEndYear,
            responsiblePerson
        }) => {
            const fileName = `supplier-${supplierInfos.id}_report-${reportId}.pdf`;
            const blob = await pdf( 
                <ReportDetailsPDF 
                    organization={organization} 
                    supplierType={supplierInfos.isOwnCompany ? 'own' : 'creditor'}
                    data={{
                        sections:  groupQuestionsBySection(answers),
                        financialEndYear,
                        responsiblePerson
                    }}
                /> 
            ).toBlob();
        
            saveAs(blob, fileName);

            setExportPDFInprocess(false);
        }
	});

    const findQuestionAnswers = (question) => {
        const answers = handleFetchSupplierReportDetailsQuery?.data || [];

        const questionIdToSearch = !question?.isReactSubQuestion ? question.id : question.parentQuestionId;

        const item = answers.find((questionData) => {
            return questionData.id === questionIdToSearch;
        });

        if(item){
            if(!question?.isReactSubQuestion){
                switch(question.type){
                    case 'textarea':
                    case 'text':
                    case 'date':
                    case 'select':
                    case 'country_list':
                        return item.answer[0]?.title;

                    case 'checkbox':
                    case 'category':
                    case 'file_upload':
                        return item.answer || [];

                    case 'radiobox':
                        const userAnswer = (item.answer && Array.isArray(item.answer) && item.answer.length > 0) ? item.answer[0] : null;

                        if(userAnswer){
                            const optionBaseInfos = question.options.find((option) => {
                                return option.id === userAnswer.value;
                            });

                            if(optionBaseInfos){
                                return {
                                    value       :   optionBaseInfos.id,
                                    title       :   optionBaseInfos.title,
                                    subTitle    :   (optionBaseInfos.type === 'date' || optionBaseInfos.type === 'text') ? userAnswer.title : null
                                };
                            }
                        }
                    break;
                    
                    default:
                        return null;
                }
            }
            else{
                const answer = item.answer[0];

                if(answer && answer?.children && Array.isArray(answer.children)){
                    let preFilledSubQuestionData = answer.children.find((subQuestionData) => {
                        return Number.parseInt(subQuestionData.id) === question.id;
                    });

                    if(preFilledSubQuestionData?.type === 'file_upload'){
                        return [{
                            title   :   preFilledSubQuestionData?.title,
                            name    :   preFilledSubQuestionData?.name
                        }];
                    }

                    return preFilledSubQuestionData?.title;
                }
            }
        }

        return null;
    }

    const groupQuestionsBySection = (questions) => {
        const groupedQuestionsBySection = [];

        const findSection = (sectionName) => {
            return groupedQuestionsBySection.find((section) => {
                return section.name === sectionName;
            });
        }

        for(let i = 0; i < questions.length; i++){
            const question = questions[i];
            let section = findSection(question.section || '');

            if(!section){
                section = {
                    name : question.section || '',
                    icon : question.icon,
                    items : []
                }
                groupedQuestionsBySection.push(section);
            }

            section.items.push(question);
        }

        return groupedQuestionsBySection;
    }

    const makeQuestionReadyForAI = (questions) => {
        let result = [];

        for(let i = 0; i < questions.length; i++){
            const question = questions[i];

            const questionResult = {
                question    :   question.title,
                answer      :   null
            };
            
            let answer = findQuestionAnswers(question);

            if(["checkbox", "category"].includes(question.type)){
                answer = (answer || []).map((ch) => {
                    return ch?.title;
                });
            }
            else if(question.type === 'radiobox'){
                const selectedOptionInfos = (question.options || []).find((o) => o.id === answer?.value);

                const children = ((selectedOptionInfos?.children && selectedOptionInfos.children.length > 0) ? selectedOptionInfos.children : []).map((child) => {
                    return {
                        ...child, 
                        isReactSubQuestion  :   true,
                        parentQuestionId    :   question.id
                    };
                });

                if(children.length > 0){
                    questionResult.children = makeQuestionReadyForAI(children);
                }

                answer = answer?.title;
            }
            else if(question.type === 'file_upload'){
                answer = (answer || []).length > 0 ? 'Yes' : 'No';
            }

            result.push({
                ...questionResult,
                answer  :   answer
            });
        }

        return result;
    }

    const renderLoadingSection = () => {
        return (
            <React.Fragment>
                <Row className="mb-4">
                    <Col lg="12">
                        <p style={{width: '90%'}} className={`dt-field dt-skeleton`}></p>
                    </Col>
                    <Col lg="6">
                        <p style={{width: '100%'}} className={`dt-field dt-skeleton`}></p>
                    </Col>
                    <Col lg="8">
                        <p style={{width: '100%'}} className={`dt-field dt-skeleton`}></p>
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col lg="12">
                        <p style={{width: '90%'}} className={`dt-field dt-skeleton`}></p>
                    </Col>
                    <Col lg="6">
                        <p style={{width: '100%'}} className={`dt-field dt-skeleton`}></p>
                    </Col>
                    <Col lg="8">
                        <p style={{width: '100%'}} className={`dt-field dt-skeleton`}></p>
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col lg="12">
                        <p style={{width: '90%'}} className={`dt-field dt-skeleton`}></p>
                    </Col>
                    <Col lg="6">
                        <p style={{width: '100%'}} className={`dt-field dt-skeleton`}></p>
                    </Col>
                    <Col lg="8">
                        <p style={{width: '100%'}} className={`dt-field dt-skeleton`}></p>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }

    const groupQuestionsBySubSection = (items) => {
        const result = [];

        const findSection = (sectionName) => {
            return result.find((section) => {
                return section.name === sectionName;
            });
        }

        for(let i = 0; i < items.length; i++){
            const question = items[i];
            let subSection = findSection(question.subSection || '');

            if(!subSection){
                subSection = {
                    name : question.subSection || '',
                    items : []
                }
                result.push(subSection);
            }

            subSection.items.push(question);
        }

        return result;
    }

    const renderQuestions = (questions, subSectionPrefix = null) => {
        return questions.map((question, index) => {
            const {
                title, type
            } = question;

            const userAnswers = findQuestionAnswers(question);

            if (type === "textarea" || type === "text" || type === "country_list" || type === "select") {
                return (
                    <div className="question" key={ index }>
                        <Label className="form-label question-title">
                            { `${subSectionPrefix ? subSectionPrefix + '.': ''}${index + 1}.` } {t(title)}
                        </Label>
                        <p>
                            { userAnswers }
                        </p>
                    </div>
                );
            }
            else if(type === "date"){
                return (
                    <div className="question" key={ index }>
                        <Label className="form-label question-title">
                            { `${subSectionPrefix ? subSectionPrefix + '.': ''}${index + 1}.` } {t(title)}
                        </Label>
                        <p>
                            {userAnswers && dateUtils.convertDateToDate(
                                userAnswers,
                                INTERNATIONAL_DATE_FORMAT
                            )}
                        </p>
                    </div>
                )
            }
            else if(type === "checkbox" || type === "category"){
                return (
                    <div className="question" key={ index }>
                        <Label className="form-label question-title">
                            { `${subSectionPrefix ? subSectionPrefix + '.': ''}${index + 1}.` } {t(title)}
                        </Label>
                        <Row>
                            {
                                userAnswers.map((eachCheckboxAnswer, checkboxIndex) => {
                                    return (
                                        <Col sm="12" md="6" key={ checkboxIndex }>
                                            <p>
                                                {
                                                    t(eachCheckboxAnswer?.title)
                                                }
                                            </p>
                                        </Col>
                                    );
                                })
                            }
                        </Row>
                    </div>
                );
            }
            else if(type === "radiobox"){
                const selectedOptionInfos = (question.options || []).find((o) => o.id === userAnswers?.value);

                const children = ((selectedOptionInfos?.children && selectedOptionInfos.children.length > 0) ? selectedOptionInfos?.children : []).map((child) => {
                    return {
                        ...child, 
                        isReactSubQuestion  :   true,
                        parentQuestionId    :   question.id
                    };
                });

                return (
                    <div className="question" key={ index }>
                        <Label className="form-label question-title">
                            { `${subSectionPrefix ? subSectionPrefix + '.': ''}${index + 1}.` } {t(title)}
                        </Label>
                        <Row>
                            {
                                userAnswers && (
                                    <Col sm="12">
                                        <Label className="form-label question-title">
                                            {t(userAnswers?.title)}
                                        </Label>
                                        <div className="sub-question-container">
                                            {
                                                (children.length > 0) && (
                                                    renderQuestionsGroup(children)
                                                )
                                            }
                                        </div>
                                    </Col>
                                )
                            }
                        </Row>
                    </div>
                );
            }
            else if(type === "file_upload"){
                return (
                    <div className="question" key={ index }>
                        <Label className="form-label question-title">
                            { `${subSectionPrefix ? subSectionPrefix + '.': ''}${index + 1}.` } {t(title)}
                        </Label>

                        <div className="files-container">
                            {
                                (userAnswers || []).map((f, i) => {
                                    return (
                                        <div className="file-preview" key={i + "-file"}>
                                            <span className="file-name mb-2">
                                                { f.name }
                                            </span>
                                            <img src={ uploadedFileIcon } alt="" />
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                );
            }

            return null;
        })
    }

    const renderQuestionsGroup = (items) => {
        const subSections = groupQuestionsBySubSection(items);

        if(subSections.length === 1){
            return (
                <>
                    { renderQuestions(subSections[0].items) }
                </>
            )
        }

        return subSections.map((subSection, subSectionIndex) => {
            const ch = String.fromCharCode(97 + subSectionIndex);

            return (
                <div className="sub-section" key={subSectionIndex}>
                    <Label className="sub-section-title">
                        {`${ch}.`} { t(subSection.name) }
                    </Label>

                    <div className="sub-section-body">
                        { renderQuestions(subSection.items, ch) }
                    </div>
                </div>
            )
        })
    }

    const renderAIRiskAssessmentModal = () => {
        return (
            aiBasedRiskAssessmentModalStatus && (
                <AIRiskAssessmentModal
                    closeModal={ () => setAiBasedRiskAssessmentModalStatus(false) }
                    onRisksCreated={ () => history.push({ search : '?tab=risk-assessment'}) }
                    isOpen={ aiBasedRiskAssessmentModalStatus }
                    translateResult={ true }
                    aiRequestOptions={{
                        requestUrl      :   `${CHAT_BOT_DOMAIN}api/tpdd/risks-associated-questioner.ai`,
                        payload    :   {
                            module              :   "TPDD_QUESTIONER",
                            // productGroup        :   (supplierInfos?.product || []).map((product) => {
                            //     return product?.name.productGroup;
                            // }),
                            productGroup        :   (supplierInfos?.industries || []).map((industry) => industry.id),
                            myLocation          :   organization?.countryId,
                            targetCountry       :   (supplierInfos?.locations || []).filter((location) => location.type === 'office')[0]?.country,
                            reportCaseId        :   reportId,
                            questions           :   makeQuestionReadyForAI(handleFetchSupplierReportDetailsQuery.data || []),
                            organizationId      :   organization.id
                        }
                    }}
                    aiTranlateRequestOptions={{
                        requestUrl      :   `${CHAT_BOT_DOMAIN}api/tpdd/risks-associated-translate.ai`,
                        payload         :   {
                            module              :   "TPDD_QUESTIONER",
                            organizationId      :   organization.id,
                            reportCaseId        :   reportId
                        }
                    }}
                    additionnalDetailsRequestOptions={{
                        requestUrl  :   API_URL_RISK_AI_GET_ADDITIONAL_DETAILS,
                        payload     :   {}
                    }}
                    createRisksRequestOptions={{
                        requestUrl      :   `${API_BASE_URL}/risk_rate/create_ai`,
                        payload         :   {
                            supplier    :   supplierInfos.id,
                            reportCase  :   reportId
                        }
                    }}>
                    {(result) => {
                        return (
                            <Modal className="ai-risk-result-modal mw-100" style={{width: '90%'}} isOpen={aiBasedRiskAssessmentModalStatus} size="xl">
                                <ModalHeader toggle={() => setAiBasedRiskAssessmentModalStatus(!aiBasedRiskAssessmentModalStatus)}>
                                    {t('Risk Assesment Support by AI')}
                                </ModalHeader>
                                <ModalBody>
                                    <result.type {...result.props} />
                                </ModalBody>
                            </Modal>
                        )
                    }}
                </AIRiskAssessmentModal>
            )
        )
    }

    const renderExportPDFWaitingModal = () => {
        return (
            <Modal isOpen={ exportPDFInprocess } toggle={ () => setExportPDFInprocess(false) }>
                <ModalHeader toggle={ () => setExportPDFInprocess(false) }>
                    <div>
                        <Spinner className="me-2" animation="border" variant="info" size="sm"/>
                        {t('Third Party Report')}
                    </div>
                </ModalHeader>
                
                <ModalBody>
                    {t('Exporting as PDF')}...
                </ModalBody>
            </Modal>
        )
    }

    if(handleFetchSupplierReportDetailsQuery.isFetching || handleFetchSupplierReportDetailsQuery.isLoading){
        return renderLoadingSection();
    }

    return (
        <React.Fragment>
            { renderAIRiskAssessmentModal() }

            { renderExportPDFWaitingModal() }
            
            <Row>
                <Col sm="12" className="d-flex flex-row-reverse justify-content-start align-items-center mb-3">
                    <Button color="primary" onClick={fetchReportPDFData}>
                        { t('Download Report PDF') }
                    </Button>

                    {/* <Button color="primary" outline className="me-2">
                        { t('Download Attachments') }
                    </Button> */}

                    {!supplierInfos.isOwnCompany && (
                        <Button color="primary" className="me-2 ai-base-risk-assessment-button"
                            onClick={ () => setAiBasedRiskAssessmentModalStatus(true) }
                            outline
                            >
                            <img alt="ai icon" src={AIGetHelpIcon} />
                            {t("Get help from AI")}
                        </Button>  
                    )}
                </Col>

                <Col sm="12">
                    <Accordion defaultActiveKey={ activeAccordion } className="questionnaire">
                        {
                            groupQuestionsBySection(handleFetchSupplierReportDetailsQuery.data || []).map((section, index) => {

                                const icon = section.icon ? `/images/questionnaire/pages/${section.icon}.svg` : QuestionnaireSectionsImages[`${(section.name.toString()).replaceAll(' ', '_').replaceAll('&','and').replaceAll(',','').replaceAll('-', '_').toLowerCase()}`];

                                return (
                                    <Accordion.Item onClick={() => setActiveAccordion(index)} className={activeAccordion === index ? 'active' : ''} eventKey={ index }>
                                        <Accordion.Header>
                                            <img src={icon} alt="" />

                                            <span>{`${index + 1}. ${t(section.name)}`}</span>
                                        </Accordion.Header>
                                        
                                        <Accordion.Body>
                                            { renderQuestionsGroup(section.items) }
                                        </Accordion.Body>
                                    </Accordion.Item>
                                )
                            })
                        }
                    </Accordion>
                </Col>
            </Row>
        </React.Fragment>
    )
};

const mapStatetoProps = (state) => {
    const { Organization } = state;
    const { token } = state.Login;

    return {
        user            :   state.Login.user,
        organization    :   Organization,
        token
    };
};

export default withNamespaces()(
    withRouter(connect(
        mapStatetoProps,
        {}
    )(memo(SupplierReportDetails)))
);