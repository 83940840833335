import { memo } from "react";
import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";

const DeletionTaskModal = function (props) {
  DeletionTaskModal.displayName = "Memorized Component - Deletion Task Modal";
  if (process?.env?.NODE_ENV === 'development') console.log("---- rendering memorized component >>> DeletionTaskModal");
  
  return (
    <>
      <Modal
        size="lg"
        scrollable={true}
        isOpen={props.isOpen}
        backdrop="static"
      >
        {/* header */}
        <ModalHeader toggle={() => props.close()}>
          {props.t("Delete Task")}
        </ModalHeader>

        {/* body */}
        <ModalBody>
          <AvForm className="needs-validation">
            <Row>
              <Label>{props.t("Are you sure?")}</Label>
            </Row>

            <ModalFooter>
              <Button
                color="danger"
                className="waves-effect waves-light"
                type="submit"
                disabled={props.isDeleting}
                onClick={() => {
                  props.acceptDeletion(props.taskId);
                }}
              >
                {props.t("Delete").toUpperCase()}
              </Button>
              <Button
                color="secondary"
                className="waves-effect waves-light"
                type="button"
                onClick={() => {
                  props.close();
                }}
              >
                {props.t("Cancel").toUpperCase()}
              </Button>
            </ModalFooter>
          </AvForm>
        </ModalBody>
      </Modal>
    </>
  );
};

export default memo(DeletionTaskModal);
