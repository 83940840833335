import React, { Component } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { successNotification, errorNotification } from "../../store/actions";
import { authCurrentUser } from "../../store/auth/login/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { Container, Row, Col, Card, CardBody, Button, Label } from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";
import axios from "axios";
import { AUTH_API_DOMAIN_URL } from "../../common/constants";
import UserUtils from "../../services/utils/UserUtils";
import Password from "../../components/Fields/Password";

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.userUtils = new UserUtils();
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      breadcrumbItems: [
        { title: "SIP", link: "/" },
        { title: this.props.t("Profile"), link: "profile" },
        { title: this.props.t("Change Password"), link: "#" },
      ],

      currentPassword: "",
      newPassword: "",
      repeatPassword: "",
      invalidPattern: false,
      notEqualPassword: false,
      currentPasswordIsRequired: false,
      formSubmitted: false,
    };
  }

  async handleSubmit() {
    let isValid = true;

    // current password is required
    if (!this.state.currentPassword) {
      this.setState({
        currentPasswordIsRequired: true,
      });
      isValid = false;
    } else {
      this.setState({
        currentPasswordIsRequired: false,
      });
    }

    // passwords are not equal
    if (this.state.newPassword !== this.state.repeatPassword) {
      this.setState({
        notEqualPassword: true,
      });
      isValid = false;
    } else {
      this.setState({
        notEqualPassword: false,
      });
    }

    // invalid pattern
    if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_\[\]+={}'";:/?.,<>|\\-]).{10,100}$/.test(
        this.state.newPassword
      )
    ) {
      this.setState({
        invalidPattern: true,
      });
      isValid = false;
    } else {
      this.setState({
        invalidPattern: false,
      });
    }

    if (!isValid) return false;

    try {
      const result = await axios.post(
        `${AUTH_API_DOMAIN_URL}user.changePassword`,
        {
          user_id: this.props.currentUser.id,
          password: this.state.currentPassword,
          new_password: this.state.newPassword,
          repeat_password: this.state.repeatPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${this.props.token}`,
          },
        }
      );

      if (result.status === 200) {
        this.setState({
          currentPassword: "",
          newPassword: "",
          repeatPassword: "",
          formSubmitted: true,
        }, () => {
          this.props.successNotification({
            message: this.props.t("Your password updated successfully."),
          });
        });
        

        
      }
    } catch (error) {
      if (process?.env?.NODE_ENV === "development") {
        console.error(
          "this error only appears in the development environment:\nerror while changing password:",
          error
        );
      }
      if (axios.isAxiosError(error)) {
        if (
          error.response.data.message?.password ===
          "validation.user_password.invalid"
        ) {
          this.props.errorNotification({
            message: this.props.t("Current password is incurrect."),
          });
        } else if (
          error.response.data.message?.new_password ===
          "password is same to current password"
        ) {
          this.props.errorNotification({
            message: this.props.t(
              "The new password cannot be the same as the current password."
            ),
          });
        } else {
          this.props.errorNotification({
            message: this.props.t("Failed to update password."),
          });
        }
      } else {
        this.props.errorNotification({
          message: this.props.t("Failed to update password."),
        });
      }
    }
  }

  render() {
    return (
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Change Password"
            breadcrumbItems={this.state.breadcrumbItems}
          />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    className="needs-validation"
                    onValidSubmit={this.handleSubmit}
                  >
                    {/* current password */}
                    <Row>
                      <Col sm="12" md="6">
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="current-password"
                          >
                            {`${this.props.t("Current Password")} `}
                          </Label>
                          {/* <AvField
                            value={this.state.currentPassword}
                            name="current-password"
                            placeholder={this.props.t(
                              "Please enter your current password"
                            )}
                            type="password"
                            className="form-control"
                            id="current-password"
                            onChange={(e) => {
                              this.setState({
                                currentPassword: e.target.value,
                              });
                            }}
                          /> */}
                          <Password
                            name="current-password"
                            placeholder={this.props.t(
                              "Please enter your current password"
                            )}
                            errorMessage={this.props.t(
                              "This field cannot be blank"
                            )}
                            validate={this.state.formSubmitted ? {} :{ required: { value: true } }}
                            id="current-password"
                            onChange={(e) => {
                              this.setState({
                                formSubmitted: false,
                                currentPassword: e.target.value,
                              });
                            }}
                            value={this.state.currentPassword}
                          />
                          {this.state.currentPasswordIsRequired && !this.state.formSubmitted ? (
                            <span className="text-danger">
                              {this.props.t(
                                "The current password is required."
                              )}
                            </span>
                          ) : null}
                        </div>
                      </Col>
                    </Row>

                    {/* new password */}
                    <Row>
                      <Col sm="12" md="6">
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="new-password">
                            {`${this.props.t("New Password")} `}
                          </Label>
                          {/* <AvField
                            value={this.state.newPassword}
                            name="new-password"
                            placeholder={this.props.t(
                              "Please enter your new password"
                            )}
                            type="password"
                            className="form-control"
                            id="new-password"
                            onChange={(e) => {
                              this.setState({
                                newPassword: e.target.value,
                              });
                            }}
                          /> */}
                          <Password
                            name="new-password"
                            placeholder={this.props.t(
                              "Please enter your new password"
                            )}
                            errorMessage={this.props.t(
                              "This field cannot be blank"
                            )}
                            validate={this.state.formSubmitted ? {} :{ required: { value: true } }}
                            id="new-password"
                            onChange={(e) => {
                              this.setState({
                                formSubmitted: false,
                                newPassword: e.target.value,
                              });
                            }}
                            value={this.state.newPassword}
                          />
                          {this.state.invalidPattern && !this.state.formSubmitted ? (
                            <span
                              className="text-danger"
                              style={{ display: "block" }}
                            >
                              {this.props.t(
                                "Password should be at least 10 characters and should contain at least one uppercase letter, one lowercase letter, one number and one special character"
                              )}
                            </span>
                          ) : null}
                          {this.state.notEqualPassword ? (
                            <span
                              className="text-danger"
                              style={{ display: "block" }}
                            >
                              {this.props.t(
                                "The new password and the repeat password must be equal."
                              )}
                            </span>
                          ) : null}
                        </div>
                      </Col>
                    </Row>

                    {/* repeat password */}
                    <Row>
                      <Col sm="12" md="6">
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="repeat-password"
                          >
                            {`${this.props.t("Repeat Password")} `}
                          </Label>
                          {/* <AvField
                            value={this.state.repeatPassword}
                            name="repeat-password"
                            placeholder={this.props.t(
                              "Please re-enter your new password"
                            )}
                            type="password"
                            className="form-control"
                            id="repeat-password"
                            onChange={(e) => {
                              this.setState({
                                repeatPassword: e.target.value,
                              });
                            }}
                          /> */}
                          <Password
                            name="repeat-password"
                            placeholder={this.props.t(
                              "Please re-enter your new password"
                            )}
                            errorMessage={this.props.t(
                              "This field cannot be blank"
                            )}
                            validate={this.state.formSubmitted ? {} :{ required: { value: true } }}
                            id="repeat-password"
                            onChange={(e) => {
                              this.setState({
                                formSubmitted:false,
                                repeatPassword: e.target.value,
                              });
                            }}
                            value={this.state.repeatPassword}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Button color="primary" type="submit">
                          {this.props.t("Update")}
                        </Button>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  const { token, user: currentUser } = state.Login;
  return { token, currentUser };
};

// const mapDispachToProps = (dispach) => {
// 	return {
// 		successNotification: (message) => dispach(successNotification(message)),
// 		authCurrentUser: () => dispach(authCurrentUser()),
// 	};
// };

export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
      successNotification,
      errorNotification,
    })(ChangePassword)
  )
);

// const ChangePassword = (props) => {
//   console.log("---- rendering memorized component >>> ChangePassword");

//   const breadcrumbItems = [
//     { title: "SIP", link: "/" },
//     { title: props.t("Profile"), link: "#" },
//   ];

//   return (
//     <div className="page-content">
//       <Container fluid>
//         <Breadcrumbs title="Profile" breadcrumbItems={breadcrumbItems} />
//         <Row>
//           <Col xl="12">123</Col>
//         </Row>
//       </Container>
//     </div>
//   );
// };

// export default memo(ChangePassword);
