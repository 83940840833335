import React from "react";
import DragandDrop from "src/modules/data-protection/assets/images/drag-drop-line.svg";

const DragDropArea = ({ isDragActive, t }) =>
  isDragActive ? (
    <div className="drag-main-div">
      <div className="drag-item-div">
        <img
          src={DragandDrop}
          style={{ maxWidth: "50px", maxHeight: "50px" }}
          alt="Drag-Drop"
        />
        <p>{t("Drag and drop files here to upload")}</p>
      </div>
    </div>
  ) : null;

export default DragDropArea;