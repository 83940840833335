export const CHECK_LOGIN = "check_login";
export const LOGIN_USER_SUCCESSFUL = "login_user_successfull";
export const LOGIN_ERROR_FLAG = "login_error_flag";

export const LOGOUT_USER = "LOGOUT_USER";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";

export const API_ERROR = "api_failed";

export const AUTH_CURRENT_USER = "AUTH_CURRENT_USER";
export const SET_USER_ACCESS_TOKEN = "SET_USER_ACCESS_TOKEN";

export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_FAILED = "register_user_failed";
export const REGISTER_USER_SUCCESSFUL = "register_user_successfull";
