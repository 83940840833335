import axios from 'axios';

import {
    API_BASE_URL
} from '../constants/index';

export default class SupplierExportService {
	static instance;

	static getInstance() {
		if (!SupplierExportService.instance) {
			SupplierExportService.instance = new SupplierExportService();
		}

		return SupplierExportService.instance;
	}

    async fetchExportData(supplierId, params = {}){
        const response = await axios.get(`${API_BASE_URL}/supplier/${supplierId}/download_report`, {
            params  :   params,
            headers :   {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`
            }
        });

        return response.data?.data || {};
    }
}
