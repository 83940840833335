import { memo, useEffect, useMemo, useState } from "react";
import { withNamespaces } from "react-i18next";
import { AvForm, AvField, AvInput, AvFeedback, AvGroup } from "availity-reactstrap-validation";
import { Button, ButtonDropdown, Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from "reactstrap";
import Select from "react-select";
import { connect } from "react-redux";
import LocalizedPhoneInput from "src/modules/3rd-party-management/components/LocalizedPhoneInput";
import { useMutation, useQuery } from "@tanstack/react-query";
import CommonService from "src/modules/3rd-party-management/apis/CommonService";
import { toast } from "react-toastify";

const OwnCompanyContactModal = ({
    t,
    displayModal,
    toggleModal,
    handleSubmit,
    memorizedAnalystList,
}) => {
    const [ selectedAnalyst, setSelectedAnalyst ] = useState(); 

    return (
        <Modal 
            isOpen={ displayModal } 
            backdrop="static" 
            keyboard={ false } 
            size='md'
            className="contact-person-creation-modal">
            
            <AvForm className="needs-validation m-2"
            onValidSubmit={ (e, values) => {
                handleSubmit(e, values)
            } }>
                <ModalHeader toggle={ () => toggleModal(false) }>
                    {t("Add A New Responsible Person")}
                </ModalHeader>

                <ModalBody>
                    <Row>
                        <Col sm="12">
                            <Label for="responsible-person-language">
                                {t("Select user")}
                            </Label>
                            <Select
                                placeholder={t("Select") + "..."}
                                classNamePrefix="select2-selection"
                                id='responsible-person-language'
                                options={ memorizedAnalystList }
                                onChange={(e) => setSelectedAnalyst(e)}
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                
                            />
                            <AvField
                                name={`responsible_person[user]`}
                                type="hidden"
                                errorMessage={t("This field cannot be blank")}
                                validate={{
                                    required: { value: true },
                                }}
                                value={ selectedAnalyst?.value }
                            />
                        </Col>
                    </Row>
                </ModalBody>

                <ModalFooter>
                    <Button onClick={ () => toggleModal(false) } color="primary" type="button" outline>
                        { t('Cancel') }
                    </Button>

                    <Button color="primary" type="submit" size="md">
                        { t('Add') }
                    </Button>
                </ModalFooter>
            </AvForm>
        </Modal>
    )
}

const OwnCompany = ({
    t,
    setCompanyDetailsTabCompleted,
    contacts,
    setContacts,
    goToPrevTab
}) => {
    const [ showCreationModal, setShowCreationModal ] = useState(false);

    const {
        data: analystList,
        isFetching: fetchingAnalysts,
        isLoading: loadingAnalysts,
        refetch: fetchAnalystsList
    } = useQuery({
		queryKey: ['3rd-party-management-fetch-supplier-analyst-list'],
		queryFn: async () => {
			const service = CommonService.getInstance();

            return await service.fetchAnalystAndAdminAnalystList();
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
        enabled: false,
		onError: () => {
			toast(t('An error occurred while fetching analysts list.'), {
				type: 'error',
			});
		},
	});

    const memorizedAnalystList = useMemo(() => {
        return (analystList || []).map((analyst) => {
            return {
                value: analyst.id,
                label: `${analyst.first_name} ${analyst.last_name}`
            }
        });
    }, [ analystList ])

    useEffect(() => {
        fetchAnalystsList(); 
    }, []);

    return (
        <>
            <Card className="wizard-steps-content-container">
                <CardBody className="p-0">
                    <p className="sub-header medium">
                        {t("Responsible Person")}
                    </p>

                    <Row>
                        <Col>
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>{t('First Name')}</th>
                                        <th>{t('Last Name')}</th>
                                        <th>{t('Action')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {contacts.map((contact, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{contact.firstName}</td>
                                                <td>{contact.lastName}</td>
                                                <td>
                                                    <Button
                                                        color="danger"
                                                        onClick={() => setContacts((currentState) => {
                                                            const newState = [...currentState];
                                                            newState.splice(index, 1);
                                                            return newState;
                                                        })}
                                                        outline
                                                    >
                                                        <span>{t("Delete Person")} </span>
                                                    </Button>
                                                </td>
                                            </tr>
                                        )
                                    })}

                                    {!contacts.length && (
                                        <tr>
                                            <td colSpan='4'>
                                                <div className="alert alert-warning">
                                                    <p className="m-0">{t('This list is empty')}</p>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>

                    <Row className="mt-4">
                        <Col md="10"></Col>
                        <Col md="2" style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button
                                disabled={fetchingAnalysts || loadingAnalysts}
                                color="primary" 
                                outline
                                onClick={() => setShowCreationModal(true)}
                            >
                                {" + " + t("Add A Person")}
                            </Button>
                        </Col>
                    </Row>
                </CardBody>
            </Card>

            <Row className="mt-2 mb-4">
                <FormGroup
                    style={{
                    display: "flex",
                    flexFlow: "row wrap",
                    justifyContent: "space-between",
                    }}
                    className="actions-buttons-container"
                >
                    
                    <Button color="primary" onClick={() => goToPrevTab(2)} outline>
                        {t("Back")}
                    </Button>
                    <Button color="primary" onClick={() => {
                        setCompanyDetailsTabCompleted("contact-person")
                    }}>
                        {t("Next")}
                    </Button>
                </FormGroup>
            </Row>

            <OwnCompanyContactModal 
                t={t}
                displayModal={showCreationModal}
                toggleModal={() => setShowCreationModal(!showCreationModal)}
                handleSubmit={(e, values) => {
                    setContacts((currentState) => {
                        const addedUser = analystList.find((user) => user.id === values.responsible_person.user)
                        return [...currentState, {
                            id: addedUser.id,
                            firstName: addedUser.first_name,
                            lastName: addedUser.last_name
                        }];
                    });

                    setShowCreationModal(false);
                }}
                memorizedAnalystList={memorizedAnalystList}
            />
        </>
    )
}

const Creditor = ({
    t,
    organization,
    setCompanyDetailsTabCompleted,
    contacts,
    setContacts,
    contactCount,
    setContactCount,
    goToPrevTab
}) => {
    const [ titleDrops, setTitleDrops ] = useState([]);

    const {
        mutate      :   checkUserExistenceMutation
    } = useMutation({
        mutationFn: async (payload) => {
            const service = CommonService.getInstance();

            return await service.checkUserExistence(payload);
        }
    });

    useEffect(() => {
        const difference = contactCount - contacts.length;
        if (difference > 0) {
            const newContacts = Array(difference)
                .fill(null)
                .map((_, index) => ({
                id: `factory-${Date.now() + index}`,
                title : "Mr.",
                firstName: "",
                lastName : "",
                position: "",
                language: "",
                email: "",
                phoneNumber: "",
                }));
            setContacts(contacts.concat(newContacts));
        }
    }, [contactCount]);

    const generateRandomName = () => Math.random().toString(36).substring(7)

    const languages = (organization?.languages || []).map((lng) => {
        return {
            value : lng?.language?.id,
            label : t(lng.name),
            code  : lng.language_code,
        }
    });

    const updateLanguage = (index, newValue) => {
        setContacts((currentContacts) => {
            const newContacts = [...currentContacts];
            newContacts[index] = { ...newContacts[index], language: newValue };
            return newContacts;
        });
    };

    const onDeleteOffice = (index) => {
        const newContacts = contacts.filter((_, i) => i !== index);
        setContacts(newContacts);
        setContactCount((prevCount) => prevCount - 1);
    };

    return (
        <AvForm
            className="needs-validation"
            onValidSubmit={() => {
                if(!contacts.filter((c) => c.emailValidationStatus !== 'valid').length){
                    return setCompanyDetailsTabCompleted("contact-person");
                }
            }}
            autocomplete="off"
            id="contact_person">

                <Card className="wizard-steps-content-container">
                    <CardBody className="p-0">
                        <p className="sub-header medium">
                        {t("Contact Person")}
                        </p>

                        {contacts.length > 0 ? (
                            <>
                                {contacts.map((item, i) => {
                                    return (
                                        <Row className="mb-4" key={item.id}>
                                            <Row className="mb-4">
                                                <Col md="4" sm="4">
                                                    <Label for={`firstName${i}`}>
                                                        {t("First Name")}: 
                                                    </Label>

                                                    <AvGroup>
                                                        <div className="d-flex flex-wrap position-relative">
                                                            <ButtonDropdown 
                                                                className="position-absolute" 
                                                                isOpen={ titleDrops[i] } 
                                                                toggle={() => setTitleDrops((drops) => {
                                                                    drops[i] = !drops[i];

                                                                    return [...drops];
                                                                })} 
                                                                style={{
                                                                    left: "0px",
                                                                    top: "0px",
                                                                    width: "94px",
                                                                    height: "34px"
                                                                }}
                                                            >
                                                                <DropdownToggle outline split style={{ border: 'none', borderRight: '1px solid #282828' }}>
                                                                    <span style={{marginRight: '8px'}}>{ t(contacts[i].title || 'Mr.') }</span>
                                                                </DropdownToggle>

                                                                <DropdownMenu>
                                                                    <DropdownItem onClick={() => { contacts[i].title = 'Mr.' }}>
                                                                    { t('Mr.') }
                                                                    </DropdownItem>
                                                                    <DropdownItem onClick={() => { contacts[i].title = 'Mrs.' }}>
                                                                    { t('Mrs.') }
                                                                    </DropdownItem>
                                                                    <DropdownItem onClick={() => { contacts[i].title = 'Mx.' }}>
                                                                    { t('Mx.') }
                                                                    </DropdownItem>
                                                                </DropdownMenu>
                                                            </ButtonDropdown>

                                                            <AvInput 
                                                                name={generateRandomName()}
                                                                autocomplete="invalid-type" // this is a trick to hide autocomplete in some browsers like edge
                                                                required
                                                                type="text"
                                                                className="form-control"
                                                                value={contacts[i]?.firstName}
                                                                onChange={(e) => { contacts[i].firstName = e.target.value; }}
                                                                style={{
                                                                    paddingLeft: '98px',
                                                                    flexBasis : '100%'
                                                                }}
                                                            />

                                                            <AvFeedback style={{
                                                                flexBasis : '100%'
                                                            }}>{t("This field cannot be blank")}</AvFeedback>
                                                        </div>
                                                    </AvGroup>
                                                </Col>

                                                <Col md="4" sm="12">
                                                    <Label for="website">
                                                        {t("Last Name")}: 
                                                    </Label>
                                                    <AvField
                                                        name={generateRandomName()}
                                                        autocomplete="invalid-type" // this is a trick to hide autocomplete in some browsers like edge
                                                        type="text"
                                                        errorMessage={t("This field cannot be blank")}
                                                        className="form-control"
                                                        validate={{
                                                        required: { value: true },
                                                        }}
                                                        onChange={(e) => {
                                                        contacts[i].lastName = e.target.value;
                                                        }}
                                                        value={contacts[i]?.lastName}
                                                    />
                                                </Col>

                                                {/* Position */}
                                                <Col md="4" sm="12">
                                                    <Label for="position">{t("Position")}: </Label>
                                                    <AvField
                                                        name={generateRandomName()}
                                                        autocomplete="invalid-type" // this is a trick to hide autocomplete in some browsers like edge
                                                        type="text"
                                                        errorMessage={t("This field cannot be blank")}
                                                        className="form-control"
                                                        validate={{
                                                        required: { value: true },
                                                        }}
                                                        onChange={(e) => {
                                                        contacts[i].position = e.target.value;
                                                        }}
                                                        value={contacts[i]?.position}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="mb-4">
                                                {/* Languages */}
                                                <Col md="4" sm="12">
                                                <Label for="language">{t("Language")}: </Label>
                                                <Select
                                                    placeholder={t("Select") + "..."}
                                                    classNamePrefix="select2-selection"
                                                    id={`language${i}`}
                                                    options={ languages }
                                                    menuPortalTarget={document.body}
                                                    onChange={(e) => {
                                                    updateLanguage(i, e);
                                                    }}
                                                    value={contacts[i]?.language}
                                                />
                                                <AvField
                                                    name={generateRandomName()}
                                                    placeholder=""
                                                    type="hidden"
                                                    errorMessage={t("This field cannot be blank")}
                                                    className="form-control"
                                                    validate={{
                                                        required: { value: true },
                                                    }}
                                                    value={contacts[i]?.language?.label}
                                                />
                                                </Col>

                                                {/* Email */}
                                                <Col md="4" sm="12">
                                                <Label for="email">{t("Email")}: </Label>
                                                <AvGroup>
                                                <AvField
                                                    name={generateRandomName()}
                                                    autocomplete="invalid-type" // this is a trick to hide autocomplete in some browsers like edge
                                                    type="email"
                                                    className="form-control"
                                                    validate={{
                                                    required: {
                                                        value: true,
                                                        errorMessage: t("This field cannot be blank"),
                                                    },
                                                    email: { errorMessage: t("Your email is invalid") }
                                                    }}
                                                    onChange={(e) => {
                                                    contacts[i].email = e.target.value;
                                                    }}
                                                    onBlur={(e) => {
                                                        if(!e.target.value){
                                                            setContacts((currentContacts) => {
                                                                const newContacts = [...currentContacts];
                                                                newContacts[i] = { ...newContacts[i], emailValidationStatus: null };
                                                                return newContacts;
                                                            });
                                                            return;
                                                        }

                                                        if(contacts[i].emailValidationStatus !== 'in_process'){
                                                            setContacts((currentContacts) => {
                                                                const newContacts = [...currentContacts];
                                                                newContacts[i] = { ...newContacts[i], emailValidationStatus: 'in_process' };
                                                                return newContacts;
                                                            });

                                                            checkUserExistenceMutation({
                                                                email : e.target.value
                                                            }, {
                                                                onSuccess: () => {
                                                                    setContacts((currentContacts) => {
                                                                        const newContacts = [...currentContacts];
                                                                        newContacts[i] = { ...newContacts[i], emailValidationStatus: 'valid' };
                                                                        return newContacts;
                                                                    });
                                                                },
                                                                onError: () => {
                                                                    setContacts((currentContacts) => {
                                                                        const newContacts = [...currentContacts];
                                                                        newContacts[i] = { ...newContacts[i], emailValidationStatus: 'invalid' };
                                                                        return newContacts;
                                                                    });
                                                                }
                                                            })
                                                        }
                                                    }}
                                                    disabled={contacts[i].emailValidationStatus === 'in_process'}
                                                    value={contacts[i]?.email}
                                                />
                                                    {contacts[i].emailValidationStatus === 'invalid' && (
                                                        <AvFeedback valid={false} className='d-block'>
                                                            {t('Email already exists')}
                                                        </AvFeedback>
                                                    )}
                                                </AvGroup>
                                                </Col>

                                                {/* Phone Number */}
                                                <Col md="4" sm="12">
                                                <Label for="phone">{t("Phone Number")}: </Label>
                                                <LocalizedPhoneInput
                                                    country={ "us" }
                                                    disableInitialCountryGuess={ false }
                                                    disableCountryGuess={ false }
                                                    inputClass="w-100"
                                                    onChange={ (e) => {
                                                    setContacts((currentContacts) => {
                                                        const newContacts = [...currentContacts];
                                                        newContacts[i] = { ...newContacts[i], phoneNumber: e };
                                                        return newContacts;
                                                    });
                                                    }}
                                                    value={ contacts[i]?.phoneNumber }
                                                />
                                                <AvField
                                                    name={generateRandomName()}
                                                    type="hidden"
                                                    errorMessage={t("This field cannot be blank")}
                                                    validate={{
                                                    required: { value: true },
                                                    }}
                                                    value={ contacts[i]?.phoneNumber }
                                                />
                                                </Col>
                                                
                                                {/* Delete */}
                                                <Col
                                                md="2"
                                                sm="12"
                                                className="pt-4 mt-2"
                                                >
                                                <Button
                                                    color="danger"
                                                    onClick={() => onDeleteOffice(i)}
                                                    outline
                                                >
                                                    {/* <RiDeleteBin5Line />  */}
                                                    <span>{t("Delete Contact")} </span>
                                                </Button>
                                                </Col>
                                            </Row>
                                        </Row>
                                    );
                                })}
                            </>
                        ) : (
                            <div className="alert alert-warning">
                                <p className="m-0">{t('This list is empty')}</p>
                            </div>
                        )}

                        <Row className="mt-4">
                            <Col md="10"></Col>
                            <Col md="2" style={{ display: "flex", justifyContent: "flex-end" }}>
                                <Button
                                    color="primary"
                                    onClick={() => {
                                        setContactCount(contactCount + 1);
                                    }} outline>
                                    {" + " + t("Add A Contact Person")}
                                </Button>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>

                <Row className="mt-2 mb-4">
                    <FormGroup
                        style={{
                        display: "flex",
                        flexFlow: "row wrap",
                        justifyContent: "space-between",
                        }}
                        className="actions-buttons-container"
                    >
                        <Button color="primary" onClick={() => goToPrevTab(2)} outline>
                            {t("Back")}
                        </Button>
                        <Button color="primary" type="submit" disabled={contacts.filter((c) => c.emailValidationStatus === 'in_process').length > 0}>
                            {t("Next")}
                        </Button>
                    </FormGroup>
                </Row>

        </AvForm>
    )
}

const ContactPerson = ({
    t,
    supplierType,
    ...elseProps
}) => {

    if(supplierType === 'own'){
        return (
            <OwnCompany 
                t={t}
                {...elseProps}
            />
        )
    }

    return (
        <Creditor 
            t={t}
            {...elseProps}
        />
    );
};

const mapStatetoProps = (state) => {
    const { Organization } = state;
    return {
        organization  : Organization
    };
};

export default withNamespaces()(
    connect(
        mapStatetoProps,
        {}
    )
(memo(ContactPerson)));
