import React, { useState } from "react";
import { ReactMic } from "react-mic";

const AudioVisualizer = ({onStop, isRecording}) => {
  return (
    <div className="recording-component ms-5" hidden={!isRecording}>
      <div>
        <ReactMic
          record={isRecording}
          className="sound-wave"
          strokeColor="#000000"
          backgroundColor="#FFFFFF"
          startButtonEnabled={false}
          stopButtonEnabled={false}
        />
      </div>
    </div>
  );
};

export default AudioVisualizer;
