import axios from 'axios';
import {
    API_BASE_URL,
  } from "src/modules/data-protection/constants/ApiRoutes";

export default class AdminstrationSettingsService {
    static instance;

    static getInstance() {
        if (!AdminstrationSettingsService.instance) {
            AdminstrationSettingsService.instance = new AdminstrationSettingsService();
        }
        return AdminstrationSettingsService.instance;
    }


    async FetchNewsSettings() {
        const accessToken = localStorage.getItem("LOCAL_STORAGE_KEY_ACCESS_TOKEN");
        const response = await axios.get(`${API_BASE_URL}/news_setting`,{
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
        return response.data?.data || []; 
    }

    async EditNewsSettings(payload) {

        const accessToken = localStorage.getItem("LOCAL_STORAGE_KEY_ACCESS_TOKEN");
        const response = await axios.post(`${API_BASE_URL}/news_setting/edit` ,
        {
            newsLanguages: payload.newsLanguages,
            newsCountries: payload.newsCountries,
            tags: payload.tags,
        },{
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
        return response?.ok || false; 
    }


    async RestorNewsSettings() {
        const accessToken = localStorage.getItem("LOCAL_STORAGE_KEY_ACCESS_TOKEN");
        const response = await axios.post(`${API_BASE_URL}/news_setting/restore` ,{},{
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
        return response.data?.data || []; 
    }

    async FetchRouterSettings(id) {
        const accessToken = localStorage.getItem("LOCAL_STORAGE_KEY_ACCESS_TOKEN");
        const response = await axios.get(`${API_BASE_URL}/organization/${id}/legal_entities`,{
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
        if (response.data && response?.data?.data) {
            return response?.data?.data;
        } else {
            return []; // Return an empty array or handle accordingly
        }
    }

    async FetchAnalystsAndAnalystAdmins() {
        const accessToken = localStorage.getItem("LOCAL_STORAGE_KEY_ACCESS_TOKEN");
        const response = await axios.post(`${API_BASE_URL}/analystAndAdminAnalyst.list` ,{},{
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
        return response.data?.data || []; 
    }


    async FetchRoutingDetails(id){
        const response = await axios.get(`${API_BASE_URL}/legal_entity/${id}/routing`, {
            headers :   {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`
            },
        });
        return response.data
    }

    async SaveRouterSettings(id, payload){
        const response = await axios.post(`${API_BASE_URL}/legal_entity/${id}/edit_routing`, payload, {
            headers :   {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`
            },
        });
        return response
    }


    async RestoreRouterSettings(id){
        const response = await axios.delete(`${API_BASE_URL}/legal_entity/${id}/clear_routing`, {
            headers :   {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`
            },
        });
        return response.data
    }

    async FetchLocation(){
        const response = await axios.post(`${API_BASE_URL}/report.country.list`, {
            headers :   {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`
            },
        });
        return response.data
    }


}
