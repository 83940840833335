import React from "react";
import Avatar from "./AvatarCard";
import moment from "moment";
import AttachmentCardInChat from "./AttachmentCardInChat";

const SendMessageWithDetails = ({ message, sameOwnerAsNext }) => {
  // Convert the backend timestamp to a Moment.js object
  const backendMoment = moment.unix(message.created_at);
  const currentDate = moment().format("YYYY-MM-DD");
  // Get the backend date
  const backendDate = backendMoment.format("YYYY-MM-DD");
  // Compare dates
  const isToday = currentDate === backendDate;

  return (
    <li className="message-show-fade-in">
      <div className="conversation-list" style={{ justifyContent: "flex-end" }}>
        <div className="ctext-wrap px-2">
          <div className="conversation-name" style={{ textAlign: "right" }}>
            {message.owner?.full_name ?? ""}
          </div>
          <div
            className="ctext-wrap-content"
            style={{ color: "#74788d", background: "#eff2f7" }}
          >
            <p className="mb-0">{message.content}</p>
            <AttachmentCardInChat
              message={message}
              isSender={!message.owner?.contactPerson}
            />
          </div>
          {sameOwnerAsNext === true ? null : (
            <p className="chat-time mb-0 d-flex gap-1 justify-content-end">
              <i className="mdi mdi-clock-outline align-middle me-1"></i>{" "}
              {isToday ? (
                // If the message is from today, display only the time
                new Date(message.created_at * 1000).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })
              ) : (
                // If the message is not from today, display time, month, and day
                <>
                  {new Date(message.created_at * 1000).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                  <i className="mdi mdi-calendar-month-outline align-middle ms-1 mb-1"></i>{" "}
                  {/* Date icon */}
                  {new Date(message.created_at * 1000).toLocaleDateString([], {
                    month: "short",
                    day: "numeric",
                  })}
                </>
              )}
            </p>
          )}
        </div>
        <Avatar src={message.owner?.avatar} alt={message.owner?.full_name} />
      </div>
    </li>
  );
};

export default SendMessageWithDetails;
