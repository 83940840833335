import React, { memo, useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  Spinner,
} from "reactstrap";
import { toast } from "react-toastify";
import DataProtectionOnboardingService from "src/modules/data-protection/apis/DataProtectionOnboarding";
import { useMutation, useQuery } from "@tanstack/react-query";
import AIImage from "src/assets/images/AI pic for SIP.png";
import AIProcess from "src/assets/images/AI_process.png";
import BottySuccess from "src/modules/data-protection/assets/images/bottyAi.png";
import BottyFailed from "src/modules/data-protection/assets/images/ai-botty/failed.png";

const OnboardingModal = (props) => {
  const { modal, setModal, t } = props;
  const [file, setFile] = useState(null);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [processingStatus, setProcessingStatus] = useState(null);

  const service = DataProtectionOnboardingService.getInstance();

  // Define the mutation for file upload
  const uploadFileMutation = useMutation({
    mutationFn: async () => {
      return await service.uploadOnboardingFile(file);
    },
    onSuccess: (data) => {
      setProcessingStatus("file_upload");
      setFileUploaded(true);
      toast(t("File uploaded successfully"), {
        type: "success",
      });
    },
    onError: () => {
      toast(t("An error occurred while uploading the file"), {
        type: "error",
      });
    },
  });

  // Define the mutation for reset the processing
  const resetProcessingMutation = useMutation({
    mutationFn: async () => {
      return await service.resetProcessing();
    },
    onSuccess: (data) => {
      setFileUploaded(false);
      setProcessingStatus(null);
      setProcessingStatus("ready_to_upload");
    },
    onError: () => {
      toast(t("An error occurred while reseting the process"), {
        type: "error",
      });
    },
  });

  // Define the mutation for starting processing
  const startProcessingMutation = useMutation({
    mutationFn: async () => {
      return await service.startProcessing();
    },
    onSuccess: () => {
      toast(t("Processing started successfully"), {
        type: "success",
      });
      setProcessingStatus("processing");
    },
    onError: () => {
      toast(t("An error occurred while starting the processing"), {
        type: "error",
      });
    },
  });

  // Query for fetching processing status
  const handleFetchProcessingStatus = useQuery({
    queryKey: ["data-protection-peocessing-status"],
    queryFn: async () => {
      return await service.getProcessingStatus();
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      if (process.env.NODE_ENV === "development") console.error(error);
      toast(t("An error occurred while fetching processing status."), {
        type: "error",
      });
    },
  });

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = () => {
    if (file) {
      uploadFileMutation.mutate();
    }
  };

  const handleStartProcessing = () => {
    setIsProcessing(true);
    startProcessingMutation.mutate();
  };

  const toggleModal = () => {
    setModal(!modal);
    setFileUploaded(false);
    setFile(null);
  };

  useEffect(() => {
    let intervalId;
    if (
      modal &&
      (processingStatus === "sent_to_queue_for_validate" ||
        processingStatus === "processing")
    ) {
      intervalId = setInterval(() => {
        handleFetchProcessingStatus.refetch();
      }, 10000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [modal, processingStatus]);

  useEffect(() => {
    if (handleFetchProcessingStatus?.data) {
      const status = handleFetchProcessingStatus?.data?.status;
      setProcessingStatus(status);

      if (status === "file_upload") {
        setFileUploaded(true);
      }
    }
  }, [handleFetchProcessingStatus?.data]);

  const renderStatusMessage = () => {
    switch (processingStatus) {
      case "sent_to_queue_for_validate":
        return (
          <p className="d-flex justify-content-center mt-3">
            {t("Your file has been sent for validation. Please wait.")}
          </p>
        );
      case "validate_master_file_successful":
        return (
          <p className="d-flex justify-content-center mt-3">
            {t("Your file has been validated successfully.")}
          </p>
        );
      case "validate_master_file_failed":
        return (
          <p className="d-flex justify-content-center mt-3">
            {t("Validation failed. Please try again or upload a new file.")}
          </p>
        );
      case "processing":
        return (
          <p className="d-flex justify-content-center mt-3">
            {t("Your file is being processed. Please wait for the result.")}
          </p>
        );
      default:
        return <Spinner />;
    }
  };

  const renderStepContent = () => {
    switch (processingStatus) {
      case "ready_to_upload":
        return (
          <Form>
            <FormGroup className="d-flex justify-content-center mt-3">
              <Input
                type="file"
                accept=".xlsx"
                onChange={handleFileChange}
                disabled={uploadFileMutation.isLoading}
                id="fileInput"
                style={{ display: "none" }}
              />
              <label htmlFor="fileInput" className="btn btn-primary">
                {file ? file.name : t("Choose File")}
              </label>
            </FormGroup>
          </Form>
        );
      case "file_upload":
        return (
          <p className="d-flex justify-content-center mt-3">
            {t(
              "Your file has been uploaded successfully. Click Next to start processing. Or reset to upload a new file."
            )}
          </p>
        );
      case "sent_to_queue_for_validate":
      case "validate_master_file_successful":
      case "validate_master_file_failed":
      case "processing":
        return renderStatusMessage();
      default:
        return (
          <p className="d-flex justify-content-center mt-3">
            <Spinner />
          </p>
        );
    }
  };

  return (
    <div>
      <Modal isOpen={modal} toggle={toggleModal} size="lg" centered>
        <ModalHeader toggle={toggleModal}>
          {t("Upload Your Excel File")}
        </ModalHeader>
        <ModalBody>
          <div className="d-flex justify-content-center">
            {processingStatus && (
              <img
                src={
                  processingStatus === "validate_master_file_failed"
                    ? BottyFailed
                    : processingStatus === "file_upload"
                    ? AIImage
                    : processingStatus === "validate_master_file_successful"
                    ? BottySuccess
                    : AIProcess
                }
                alt="Logo"
                className="modalHeaderImage"
              />
            )}
          </div>

          {renderStepContent()}
        </ModalBody>

        <ModalFooter>
          {processingStatus === "ready_to_upload" ? (
            <Button
              color="primary"
              onClick={handleUpload}
              disabled={!file || uploadFileMutation.isLoading}
            >
              {uploadFileMutation.isLoading ? (
                <Spinner size="sm" />
              ) : (
                t("Upload")
              )}
            </Button>
          ) : null}

          {fileUploaded && processingStatus === "file_upload" && (
            <Button
              color="primary"
              onClick={handleStartProcessing}
              disabled={isProcessing || startProcessingMutation.isLoading}
            >
              {startProcessingMutation.isLoading ? (
                <Spinner size="sm" />
              ) : (
                t("Next")
              )}
            </Button>
          )}

          {processingStatus !== "ready_to_upload" && (
            <Button
              color="secondary"
              onClick={() => resetProcessingMutation.mutate()}
            >
              {t("Reset")}
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default withNamespaces()(memo(OnboardingModal));
