import analystLightIcon from "./assets/filter_analyst_light.svg";
import analystBlueIcon from "./assets/filter_analyst_blue.svg";

import statusLightIcon from "./assets/filter_status_light.svg";
import statusBlueIcon from "./assets/filter_status_blue.svg";

import flagLightIcon from "./assets/filter_flag_light.svg";
import flagBlueIcon from "./assets/filter_flag_blue.svg";

import riskratingBlueIcon from "./assets/riskratingBlueIcon.svg";
import riskratingLightIcon from "./assets/riskratingLightIcon.svg";

export const icons = {
  Analyst: {
    default: analystLightIcon,
    active: analystBlueIcon,
  },
  Risk: {
    default: riskratingLightIcon,
    active: riskratingBlueIcon,
  },
  Flag: {
    default: flagLightIcon,
    active: flagBlueIcon,
  },
  Status: {
    default: statusLightIcon,
    active: statusBlueIcon,
  },
};

export const EMPTY_LIST = "This list is empty";

export const Statuses = {					
	0	:	{
		title	:	'New',
		color	:	'#FFEE93'
	},
	1	:	{
		title	:	'Under Review',
		color	:	'#74B8FF'
	},
	2	:	{
		title	:	'Approved',
		color	:	'#4CD4AC'
	},
	3	:	{
		title	:	'Rejected',
		color	:	'#BE4F59'
	}
}