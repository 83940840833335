import React, { useState, memo } from "react";
import { Accordion } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { Col, Row, Button, Spinner } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { EMPTY_LIST } from "src/common/constants";
import overlayFactory from "react-bootstrap-table2-overlay";
import SubContractorModal from "../modal/sub_contractor_modal";
import DeleteConfirmationModal from "../modal/deletion_modal";

const SubContractors = (props) => {
  const { t, itemNumber, handleFetchDetails, sub_contractors } = props;

  const [creationModal, setCreationModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deletionModal, setdeletionModal] = useState(false);

  const [selectedDataForEdition, setSelectedDataForEdition] = useState(null);
  const [selectedIdForDeletion, setSelectedIdForDeletion] = useState(null);

  const columns = [
    {
      dataField: "index",
      text: "#",
      sort: true,
      formatter: (cell, row, rowIndex) => rowIndex + 1,
    },
    {
      dataField: "area",
      text: t("Area"),
      sort: true,
    },
    {
      dataField: "name",
      text: t("Name"),
      sort: true,
    },
    {
      dataField: "website",
      text: t("Website"),
      sort: true,
    },
    {
      dataField: "g_t_c",
      text: t("GTC"),
      sort: true,
    },
    {
      dataField: "privacy_policy",
      text: t("Privacy Policy"),
      sort: true,
    },
    {
      dataField: "address",
      text: t("Address"),
      sort: true,
    },
    {
      dataField: "action",
      text: t("Action"),
      sort: false,
      formatter: (cellContent, row) => {
        return (
          <div className="d-flex justify-content-start">
            <Button
              onClick={() => {
                setSelectedIdForDeletion(row.id);
                setdeletionModal(true);
              }}
              outline
              color="danger"
              size="md"
              className="border-0"
            >
              <i className="ri-delete-bin-line font-size-20"></i>
            </Button>

            <Button
              onClick={() => {
                setSelectedDataForEdition(row);
                setEditModal(true);
              }}
              outline
              color="primary"
              size="md"
              className="border-0"
            >
              <i className="ri-pencil-line font-size-20"></i>
            </Button>
          </div>
        );
      },
    },
  ];

  const NoDataIndication = () =>
    handleFetchDetails.isFetched && !handleFetchDetails?.length ? (
      <div className="alert m-0" role="alert">
        <p
          style={{
            textAlign: "center",
            marginBottom: 0,
          }}
        >
          {t(EMPTY_LIST)}
        </p>
      </div>
    ) : (
      <></>
    );

  /**************************************************** FUNCTIONS ***************************************************** */

  // /******************************************* USE EFFECTS ********************************************************** */
  return (
    <Row>
      {/* Creation Modal */}
      <SubContractorModal
        modal={creationModal}
        setModal={setCreationModal}
        type="create"
        handleFetchDetails={handleFetchDetails}
      />

      {/* Edit Modal */}
      <SubContractorModal
        modal={editModal}
        setModal={setEditModal}
        type="edit"
        data={selectedDataForEdition}
        handleFetchDetails={handleFetchDetails}
      />

      {/* Deletion Modal */}
      <DeleteConfirmationModal
        modal={deletionModal}
        setModal={setdeletionModal}
        handleFetchDetails={handleFetchDetails}
        id={selectedIdForDeletion}
      />

      <Col sm="12">
        <Accordion defaultActiveKey="0">
          <Accordion.Item className="border-none" eventKey="0">
            <Accordion.Header>
              <div className="p-1 accordion_progress">
                {`${itemNumber}. ${t("List of subcontractors")}`}{" "}
              </div>
            </Accordion.Header>
            {sub_contractors && (
              <Accordion.Body>
                <div className="table-responsive contractors-list mb-4">
                  {sub_contractors && sub_contractors?.length && (
                    <BootstrapTable
                      loading={
                        handleFetchDetails.isFetching ||
                        handleFetchDetails.isLoading
                      }
                      bordered={false}
                      keyField="id"
                      data={sub_contractors}
                      columns={columns}
                      noDataIndication={() => <NoDataIndication />}
                      overlay={overlayFactory({
                        spinner: (
                          <Spinner
                            animation="border"
                            variant="primary"
                            size="md"
                          />
                        ),
                        text: `${t("Loading")}...`,
                      })}
                    />
                  )}
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "Hug (109.31px)",
                  }}
                >
                  {" "}
                  <Button
                    outline
                    color="primary"
                    onClick={() => setCreationModal(true)}
                  >{`+ ${t("Add New")}`}</Button>
                </div>
              </Accordion.Body>
            )}
          </Accordion.Item>
        </Accordion>
      </Col>
    </Row>
  );
};

const mapStatetoProps = (state) => {
  const { Organization } = state;

  return {
    Organization,
  };
};

export default withNamespaces()(connect(mapStatetoProps)(memo(SubContractors)));
