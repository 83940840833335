import React, { useState } from "react";
import { Card, Row, Col, Progress } from "reactstrap";
import { FaFile } from "react-icons/fa";
import { withNamespaces } from "react-i18next";
import { toast } from "react-toastify";
import axios from "axios";
import { API_BASE_URL } from "src/modules/3rd-party-management/constants/index";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

const AttachmentCardInChat = ({ message, token, isSender, t }) => {
  const [downloadFiles, setDownloadFiles] = useState([]);
  const [axiosCancelTokens, setAxiosCancelTokens] = useState({});

  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func.apply(this, args), delay);
    };
  };

  const handleDownloadFile = async (attachmentId, fileName, attachmentSize) => {
    const file = {
      id: attachmentId,
      name: fileName,
      percent: 0,
      status: "downloading",
    };

    setDownloadFiles((prevDownloads) =>
      prevDownloads ? [...prevDownloads, file] : [file]
    );
    let loadedBytes = 0;

    const updateProgress = debounce((percentCompleted) => {
      setDownloadFiles((prevDownloads) =>
        prevDownloads.map((d) =>
          d.id === attachmentId ? { ...d, percent: percentCompleted } : d
        )
      );
    }, 100);

    // Create a new Axios cancel token source
    const cancelToken = axios.CancelToken.source();
    setAxiosCancelTokens({
      ...axiosCancelTokens,
      [attachmentId]: cancelToken,
    });
    try {
      const response = await axios.post(
        `${API_BASE_URL}/supplier/attachment/${attachmentId}/download`,
        null,
        {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          onDownloadProgress: (progressEvent) => {
            loadedBytes = progressEvent.loaded;
            const percentCompleted = Math.round(
              (loadedBytes * 100) / attachmentSize
            );
            updateProgress(percentCompleted);
          },
          cancelToken: cancelToken.token, // Pass the cancel token
        }
      );

      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "*" })
      );
      const link = document.createElement("a");

      link.href = url;
      link.setAttribute("download", `${fileName.split(".")[0]}.zip`);

      document.body.appendChild(link);

      link.click();
      link.remove();
      setDownloadFiles((prevDownloads) =>
        prevDownloads.filter((d) => d.id !== attachmentId)
      );
    } catch (error) {
      if (axios.isCancel(error)) {
        toast(t("Download cancelled."), {
          type: "success",
        });
      } else {
        toast(t("An error occurred while downloading the file."), {
          type: "error",
        });
      }
      setDownloadFiles((prevDownloads) =>
        prevDownloads.filter((d) => d.id !== attachmentId)
      );
    }
  };

  const handleCancelDownload = (attachmentId) => {
    const cancelToken = axiosCancelTokens[attachmentId];
    if (cancelToken) {
      cancelToken.cancel("Download cancelled by user");
      delete axiosCancelTokens[attachmentId];
      setAxiosCancelTokens({ ...axiosCancelTokens });
      setDownloadFiles(
        downloadFiles.filter((file) => file.id !== attachmentId)
      );
    }
  };

  const AttachmentProgress = ({ isDownload }) => {
    const percent = isDownload
      ? Math.min(Math.ceil(isDownload.percent), 100)
      : 0;
    return (
      <>
        {percent > 0 && <Progress value={percent} animated className="pt-2" />}
      </>
    );
  };

  if (!message.attachments || message.attachments.length === 0) {
    return null;
  }

  return (
    <>
      {message.attachments.map((attachment, index) => {
        const isDownload = downloadFiles.find(
          (item) => item.id === attachment.id
        );

        return (
          <Card
            className="mb-0 p-0 mt-3 shadow-none dz-processing dz-image-preview dz-success dz-complete"
            key={index + "-file"}
            style={{
              color: isSender ? "#74788d" : "#fff",
              background: isSender ? "#eff2f7" : "#5664d2",
            }}
          >
            <div className="px-1">
              <Row className="align-items-center">
                <Col className="col-auto px-1">
                  <FaFile size={32} />
                </Col>
                <Col className="ps-1">
                  <span
                    onClick={() =>
                      handleDownloadFile(
                        attachment.id,
                        attachment.name,
                        attachment.size
                      )
                    }
                    className="file-name"
                  >
                    {attachment.name}
                  </span>
                  <p className="upload-details-container relative">
                    <span className="file-size px-1">
                      {(attachment.size / 1024).toFixed(2)} KB
                    </span>
                    {isDownload ? (
                      <>
                        <span className="upload-status-message px-2">
                          {t("Downloading")}
                        </span>
                        <div
                          style={{
                            position: "absolute",
                            right: "16px",
                            top: "20px",
                          }}
                        >
                          <span className="uploaded-percent">
                            {Math.ceil(
                              isDownload.percent === Infinity
                                ? 100
                                : isDownload.percent
                            )}
                            %
                          </span>
                          <button
                            type="button"
                            className="btn noborder p-0 ms-1"
                            onClick={() => handleCancelDownload(attachment.id)}
                          >
                            <i className="ri-close-line font-size-18"></i>
                          </button>
                        </div>
                      </>
                    ) : (
                      <span className="upload-status-message">
                        {t("File(s) successfully uploaded.")}
                      </span>
                    )}
                  </p>
                </Col>
              </Row>
            </div>
            <AttachmentProgress isDownload={isDownload} />
          </Card>
        );
      })}
    </>
  );
};

const mapStateToProps = (state) => {
  const { token } = state.Login;

  return {
    token,
  };
};

export default withNamespaces()(
  withRouter(connect(mapStateToProps, {})(AttachmentCardInChat))
);
