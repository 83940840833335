import React, { Component } from "react";
import {
  Row,
  Card,
  CardBody,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Form,
  Input,
  Button,
  Label,
} from "reactstrap";

//Import images
import defaultLogo from "../../assets/images/logo.svg";
import defAvatar from "../../assets/images/users/def.svg";

//Simple bar
import KScrollbar from "../Scrollbar";
import {
  AvField,
  AvForm,
  AvRadio,
  AvRadioGroup,
} from "availity-reactstrap-validation";
import { connect } from "react-redux";

// import { firebaseMessages } from "../../components/Firebase/Firebase";

import { INTERNATIONAL_DATE_TIME_FORMAT } from "../../common/constants/";

import {
  sendMessage,
  getMessages,
  startChat,
  finishChat,
} from "../../store/actions";

import DateUtils from "../../services/utils/DateUtils";
import CardLoading from "../Common/CardLoading";
import { withNamespaces } from "react-i18next";

class ChatBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearch: false,
      isSetting: false,
      isMore: false,
      chatStarted: false,
      chatAsName: "",
      chatAsType: "anonymous",
      curMessage: "",
      chatStarting: false,
      pinedMessages: [
        {
          isAnalyst: true,
          message: this.props.t(`We're pinging analysts, please stand by`),
          timestamp: null,
        },
      ],
    };
    this.startChating = this.startChating.bind(this);
    this.dateUtils = new DateUtils();
  }

  addMessage = () => {
    const { onAddMessage } = this.props;
    const message = {
      chatId: this.props.chat.chatId,
      message: this.state.curMessage,
    };
    this.setState({ curMessage: "" });
    onAddMessage(message);
  };

  scrollToBottom = () => {
    if (typeof this.messagesEnd !== "undefined") {
      this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    }
  };

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate(prevProps) {
    this.scrollToBottom();

    if (
      this.props.chat.chatId &&
      this.props.chat.chatId !== prevProps.chat.chatId
    ) {
      this.setState(
        {
          chatStarted: true,
          chatStarting: false,
        },
        () => {
          setTimeout(() => {
            const { list: messages } = this.props.chat.messages;
            if (!messages.map((message) => message.isAnalyst).length) {
              this.setState({
                pinedMessages: [
                  ...this.state.pinedMessages,
                  {
                    isAnalyst: true,
                    message: this.props.t(
                      `No analysts available currently. Please try again later`
                    ),
                    timestamp: null,
                  },
                ],
              });
            }
          }, 1000 * 60 * 4);
          this.props.chatStarted();
          // firebaseMessages(
          //   this.props.Organization.id,
          //   this.props.chat.chatId
          // ).on("value", (snapshot) => {
          //   this.props.onGetMessages(this.props.chat.chatId);
          // });
        }
      );
    }
  }

  componentWillUnmount() {
    if (this.props.chat.chatId) {
      this.props.onFinishChat(this.props.chat.chatId);
      // firebaseMessages().off();
    }
  }

  startChating(e, values) {
    console.log(values);

    this.setState({
      chatStarting: true,
    });
    this.props.onStartChat({
      organization_id: this.props.Organization.id,
      acceptedBy: false,
      chat_type: values.chat_as,
      name: this.state.chatAsName,
    });
  }

  chatAsValueChanged(value) {
    this.setState(
      {
        chatAsType: value,
      },
      () => {
        if (value === "confidential") {
          this.setState({
            chatAsName: this.getCurrentUserName(),
          });
        } else {
          this.setState({
            chatAsName: "",
          });
        }
      }
    );
  }

  getCurrentUserName() {
    if (this.props.user.first_name && this.props.user.last_name) {
      return `${this.props.user.first_name} ${this.props.user.last_name}`;
    }
    return "";
  }

  render() {
    const organization = this.props.Organization;
    const { list: messages, loading: messagesLoading } =
      this.props.chat.messages;
    const logo = organization.secondaryLogo
      ? organization.secondaryLogo
      : organization.logo
      ? organization.logo
      : defaultLogo;
    return (
      <React.Fragment>
        {this.state.chatStarted ? (
          <Card style={{ bottom: "46px" }}>
            <CardBody className="border-bottom">
              <div className="user-chat-border">
                <Row>
                  <Col md={5} xs={9}>
                    <h5 className="font-size-15 mb-1">
                      {this.getCurrentUserName()}
                    </h5>
                    <p className="text-muted mb-0">
                      <i className="mdi mdi-circle text-success align-middle me-1"></i>{" "}
                      Active now
                    </p>
                  </Col>
                  {/* <Col md={7} xs={3}>
                                            <ul className="list-inline user-chat-nav text-end mb-0">
                                                <li className="list-inline-item">
                                                    <Dropdown isOpen={this.state.isSearch}
                                                              toggle={() => this.setState({isSearch: !this.state.isSearch})}>
                                                        <DropdownToggle tag="i" className="btn nav-btn" type="button">
                                                            <i className="mdi mdi-magnify"></i>
                                                        </DropdownToggle>
                                                        <DropdownMenu right className=" dropdown-menu-md p-0">
                                                            <Form className="p-2">
                                                                <div className="search-box">
                                                                    <div className="position-relative">
                                                                        <Input type="text"
                                                                               className="form-control rounded bg-light border-0"
                                                                               placeholder="Search..."/>
                                                                        <i className="mdi mdi-magnify search-icon"></i>
                                                                    </div>
                                                                </div>
                                                            </Form>
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                </li>
                                            </ul>
                                        </Col> */}
                </Row>
              </div>
            </CardBody>
            <CardBody>
              <div className="chat-widget">
                <div className="chat-conversation">
                  <ul className="list-unstyled mb-0 pr-3">
                    <KScrollbar
                      style={{ height: "273px" }}
                      scrollToBottom={true}
                    >
                      {this.state.pinedMessages
                        .concat(messages)
                        .map((message, key) => {
                          return (
                            <li
                              key={key}
                              className={message.isAnalyst ? "right" : ""}
                              style={{ paddingRight: "20px" }}
                            >
                              <div className="conversation-list">
                                <div className="chat-avatar">
                                  <img
                                    src={
                                      message.image ? message.image : defAvatar
                                    }
                                    alt=""
                                  />
                                </div>

                                <div className="ctext-wrap">
                                  <div className="conversation-name">
                                    {message.sender}
                                  </div>
                                  <div className="ctext-wrap-content">
                                    <p className="mb-0">
                                      {"autoGenerate" in message &&
                                      message.autoGenerate
                                        ? `${
                                            message.username === "person"
                                              ? this.props.t("person")
                                              : message.username
                                          } ${this.props.t(message.message)}`
                                        : message.message}
                                    </p>
                                  </div>
                                  <p className="chat-time mb-0">
                                      {
                                    // SPIK-1202
                                    this.dateUtils.convertTimeStampToDate(
                                      message.timestamp,
                                      INTERNATIONAL_DATE_TIME_FORMAT
                                    )
                                    } 
                                  </p>
                                </div>
                              </div>
                            </li>
                          );
                        })}
                      <div
                        style={{ float: "left", clear: "both" }}
                        ref={(el) => {
                          this.messagesEnd = el;
                        }}
                      ></div>
                    </KScrollbar>
                  </ul>
                </div>
              </div>
            </CardBody>
            <div className="p-3 chat-input-section border-top">
              <Row>
                <Col>
                  <div>
                    <Input
                      disabled={!this.props.chat.chatId ? true : false}
                      value={this.state.curMessage}
                      onChange={(e) => {
                        this.setState({ curMessage: e.target.value });
                      }}
                      type="text"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          this.addMessage();
                        }
                      }}
                      className="form-control rounded chat-input pl-3"
                      placeholder="Enter Message..."
                    />
                  </div>
                </Col>
                <Col xs={{ size: "auto" }}>
                  <Button
                    color="primary"
                    type="button"
                    onClick={() => this.addMessage()}
                    className="chat-send w-md waves-effect waves-light"
                  >
                    <span className="d-inline-block me-2">
                      {this.props.t("Send")}
                    </span>
                  </Button>
                </Col>
              </Row>
            </div>
          </Card>
        ) : (
          <Card style={{ bottom: "46px" }}>
            {this.state.chatStarting ? <CardLoading /> : null}
            <AvForm
              className="needs-validation"
              onValidSubmit={this.startChating}
            >
              <CardBody className="border-bottom bg-dark">
                <img
                  src={logo}
                  alt=""
                  style={{maxWidth:150, maxHeight:50, objectFit:'contain'}}
                  className="auth-logo mx-auto"
                />
              </CardBody>
              <CardBody>
                <p>
                  {this.props.t(
                    "Welcome to our live chat support. You can ask us any general inquiries."
                  )}
                </p>

                <Row className="mb-3">
                  <Col sm="12">
                    <Label
                      className="form-check-label text-dark"
                      htmlFor="chat-as-anonymous"
                    >
                      {this.props.t("Identify in the chat as:")}
                    </Label>
                    <AvRadioGroup
                      name="chat_as"
                      defaultValue={this.state.chatAsType}
                    >
                      <Row>
                        <Col sm="12">
                          <AvRadio
                            onChange={() =>
                              this.chatAsValueChanged("anonymous")
                            }
                            label={this.props.t("Anonymous")}
                            value={"ANONYMOUS"}
                          />
                        </Col>
                        <Col sm="12">
                          <AvRadio
                            onChange={() =>
                              this.chatAsValueChanged("confidential")
                            }
                            label={this.props.t("Confidential")}
                            value={"CONFIDENTIAL"}
                          />
                        </Col>
                      </Row>
                    </AvRadioGroup>
                  </Col>
                </Row>

                <Row>
                  <Col sm="12">
                    <Label
                      className="form-check-label text-dark"
                      htmlFor="chat-as-alias"
                    >
                      {this.props.t("Alias")}
                    </Label>
                    <AvField
                      disabled={this.state.chatAsType === "confidential"}
                      name="alias"
                      placeholder=""
                      type="text"
                      errorMessage=""
                      className="form-control"
                      validate={{ required: { value: false } }}
                      id="chat-as-alias"
                      onChange={(e) =>
                        this.setState({ chatAsName: e.target.value })
                      }
                      value={this.state.chatAsName}
                    />
                  </Col>
                </Row>
              </CardBody>
              <div className="p-3 chat-input-section border-top">
                <Row>
                  <Col xs={{ size: "auto" }}>
                    <Button
                      color="primary"
                      type="submit"
                      className="chat-send w-md waves-effect waves-light"
                    >
                      <span className="d-sm-inline-block me-2">
                        {this.props.t("Start")}
                      </span>
                    </Button>
                  </Col>
                </Row>
              </div>
            </AvForm>
          </Card>
        )}
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { token, user } = state.Login;
  const { Organization, chat } = state;
  return {
    token,
    user,
    Organization,
    chat,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddMessage: (message) => dispatch(sendMessage(message)),
    onStartChat: (infos) => dispatch(startChat(infos)),
    onGetMessages: (id) => dispatch(getMessages(id)),
    onFinishChat: (id) => dispatch(finishChat(id)),
  };
};

export default withNamespaces()(
  connect(mapStatetoProps, mapDispatchToProps)(ChatBox)
);
