import React, { Component } from "react";
import { Row, Col, Card, CardBody, CardTitle, Tooltip } from "reactstrap";
import { withNamespaces } from "react-i18next";

//Import Charts
import ReactApexChart from "react-apexcharts";
import "./dashboard.scss";

const defaultColors = ["#5664d2", "#1cbb8c", "#eeb902", "#FF7F50", "#9932CC", "#90A4AE"];

class SalesAnalytics extends Component {
	constructor(props) {
		super(props);

		this.state = {
			series: [],
			options: {
				labels: [],
				plotOptions: {
					pie: {
						donut: {
							size: "75%",
						},
					},
				},
				dataLabels: {
					enabled: false,
				},
				legend: {
					show: false,
				},
				colors: defaultColors,
			},

			categories: [],
		};
	}

	render() {
		return (
			<>
				<ReactApexChart options={this.state.options} series={this.state.series} type="donut" height="250" />

				<Row>
					{this.state.categories.map((category, index) => {
						return (
							<Col key={index} xs={4}>
								<div className="text-center mt-4">
									<p className="mb-2 text-truncate">
										<i className="mdi mdi-circle font-size-10 me-1" style={{ color: defaultColors[index] }}></i>
										{this.props.t(category.title)}
									</p>
									<h5>{category.percent} %</h5>
								</div>
							</Col>
						);
					})}
				</Row>
			</>
		);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.data !== this.props.data) {
			const data = this.props.data;
			const series = [];
			const labels = [];
			const categories = [];

			for (const item in data) {
				series.push(data[item].amount);
				labels.push(data[item].title);
				categories.push({ title: data[item].title, amount: data[item].amount, percent: data[item].percent });
			}

			this.setState({
				series,
				options: {
					...this.state.options,
					labels: labels,
				},
				categories,
			});
		}
	}
}

export default withNamespaces()(SalesAnalytics);
