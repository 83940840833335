import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import {
  Row,
  Col,
  Label,
  Input,
  FormGroup,
  Button,
  Form,
  FormFeedback,
  UncontrolledPopover,
  PopoverBody,
} from "reactstrap";
import Select from "react-select";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Accordion } from "react-bootstrap";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { TbLoader } from "react-icons/tb";
import CustomizationService from "src/modules/data-protection/apis/CustomizationService";
import LocalizedPhoneInput from "src/modules/3rd-party-management/components/LocalizedPhoneInput";
import DpoImage from "src/assets/images/dpo.svg";

const DataProtectionDPOCustomization = (props) => {
  const { t, fetchOrganizationDetails, locations, getCountries } = props;
  const salutationOptions = [
    { label: t("Mr."), value: "mr" },
    { label: t("Mrs."), value: "mrs" },
  ];
  const details = fetchOrganizationDetails.data?.data_protection_responsible;
  const [fullName, setFullName] = useState(details?.name);
  const [phoneNumber, setPhoneNumber] = useState(details?.phone_number);
  const [email, setEmail] = useState(details?.email);
  const [salutation, setSalutation] = useState(details?.salutation);
  const [company, setCompany] = useState(details?.company);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [city, setCity] = useState(details?.city);
  const [country, setCountry] = useState({});
  const [address, setAddress] = useState(details?.address);

  /*********************************** FUNCTIONS ***************************************** */

  // Submit Information
  const handleSubmitMutation = useMutation({
    mutationFn: async () => {
      const payload = {
        name: fullName,
        email: email,
        company: company,
        phoneNumber: phoneNumber,
        address: address,
        city: city,
        country: country.value,
        salutation: salutation.value,
      };

      const service = CustomizationService.getInstance();
      return await service.editDpo(payload);
    },
    onSuccess: () => {
      toast(t("Data saved successfully."), {
        type: "success",
      });
    },
    onError: () => {
      toast(t("Failed to save data."), {
        type: "error",
      });
    },
  });

  // Restore Information
  const restore = () => {
    const { city, address, name, phone_number, email, company } = details;
    setCity(city);
    setSelectedCountry();
    setAddress(address);
    setFullName(name);
    setPhoneNumber(phone_number);
    setEmail(email);
    setCompany(company);
    setSelectedSalutation();
  };

  // Email Validation
  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  // Set Selected Country
  const setSelectedCountry = () => {
    const selectedCountry = locations.filter(
      (count) => count.value === details.country
    )[0];
    setCountry(selectedCountry);
  };

  // Set Selected Country
  const setSelectedSalutation = () => {
    const selectedSalutation = salutationOptions.filter(
      (sal) => sal.value === details.salutation
    )[0];
    setSalutation(selectedSalutation);
  };

  /*************************************** USE EFFECTS ****************************************** */
  useEffect(() => {
    if (details?.country && locations && locations?.length) {
      setSelectedCountry();
    }
  }, [locations]);

  useEffect(() => {
    if (details?.salutation) {
      setSelectedSalutation();
    }
  }, [details?.salutation]);

  /*************************************** VIEW ****************************************** */
  return (
    <Row>
      <div className="page-gdpr-customization">
        <Row>
          <Col className="d-flex align-items-start customization-image-col">
            <img src={DpoImage} alt="DPO / Data Protection Responsible" />
          </Col>
          <Col className="customization-accordion-col">
            <Accordion defaultActiveKey="0">
              <Accordion.Item
                className="border-none cusom-accordion-item customization-accordion"
                eventKey="0"
              >
                <Accordion.Header className="custom-accordion-header">
                  <div className="p-1 custom-div-header">
                    {`2. ${t("DPO / Data Protection Responsible")}`}{" "}
                    <>
                      <span
                        id={`section-info-button-dpo`}
                        className="section-info-button"
                      >
                        <i className="ri-information-line" />
                      </span>

                      <UncontrolledPopover
                        placement="bottom"
                        trigger="hover"
                        target={`section-info-button-dpo`}
                      >
                        <PopoverBody>
                          <span>
                            {t(
                              "This info will be needed in all modules. If the customer has purchased a second module, please overtake the info from the whole page instead of the intro text."
                            )}
                          </span>
                        </PopoverBody>
                      </UncontrolledPopover>
                    </>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <Form>
                    <Row>
                      <Row>
                        <Col lg="4" md="4" sm="12" className="mt-3">
                          <FormGroup>
                            <Label for="salutation">
                              {t("Salutation Field") + ":"}
                            </Label>
                            <Select
                              name="salutation"
                              value={salutation}
                              classNamePrefix="select2-selection"
                              options={salutationOptions}
                              placeholder={t("Select")}
                              readOnly
                              isDisabled={
                                handleSubmitMutation.isLoading ||
                                fetchOrganizationDetails.isLoading ||
                                fetchOrganizationDetails.isFetching
                              }
                              menuPortalTarget={document.body}
                              onChange={(e) => setSalutation(e)}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="4" md="4" sm="12" className="mt-3">
                          <FormGroup>
                            <Label for="fullName">{t("Full Name") + ":"}</Label>
                            <Input
                              name="fullName"
                              type="text"
                              disabled={
                                handleSubmitMutation.isLoading ||
                                fetchOrganizationDetails.isLoading ||
                                fetchOrganizationDetails.isFetching
                              }
                              value={fullName}
                              onChange={(e) => setFullName(e.target.value)}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="4" md="4" sm="12" className="mt-3">
                          <FormGroup>
                            <Label for="phoneNumber">
                              {t("Phone Number") + ":"}
                            </Label>
                            <LocalizedPhoneInput
                              inputClass="w-100"
                              country={"us"}
                              inputStyle={{
                                height: "38px",
                              }}
                              disableInitialCountryGuess={false}
                              disableCountryGuess={false}
                              onChange={(e) => setPhoneNumber(e)}
                              value={phoneNumber}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="4" md="4" sm="12" className="mt-3">
                          <FormGroup>
                            <Label for="email">{t("Email") + ":"}</Label>
                            <Input
                              name="email"
                              type="email"
                              invalid={!isEmailValid}
                              disabled={
                                handleSubmitMutation.isLoading ||
                                fetchOrganizationDetails.isLoading ||
                                fetchOrganizationDetails.isFetching
                              }
                              value={email}
                              onChange={(e) => {
                                const newEmail = e.target.value;
                                setEmail(newEmail);
                                setIsEmailValid(validateEmail(newEmail));
                              }}
                            />
                            {!isEmailValid && (
                              <FormFeedback>
                                {t("Please enter a valid email address.")}
                              </FormFeedback>
                            )}
                          </FormGroup>
                        </Col>

                        <Col lg="4" md="4" sm="12" className="mt-3">
                          <FormGroup>
                            <Label for="company">{t("Company") + ":"}</Label>
                            <Input
                              name="company"
                              type="text"
                              disabled={
                                handleSubmitMutation.isLoading ||
                                fetchOrganizationDetails.isLoading ||
                                fetchOrganizationDetails.isFetching
                              }
                              value={company}
                              onChange={(e) => setCompany(e.target.value)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="6" md="6" sm="12" className="mt-3">
                          <FormGroup>
                            <Label for="fullAddress">
                              {t("Full Address") + ":"}
                            </Label>
                            <Input
                              name="fullAddress"
                              type="text"
                              value={address}
                              disabled={
                                handleSubmitMutation.isLoading ||
                                fetchOrganizationDetails.isLoading ||
                                fetchOrganizationDetails.isFetching
                              }
                              onChange={(e) => setAddress(e.target.value)}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3" md="3" sm="12" className="mt-3">
                          <FormGroup>
                            <Label for="city">{t("City") + ":"}</Label>
                            <Input
                              name="city"
                              type="text"
                              value={city}
                              disabled={
                                handleSubmitMutation.isLoading ||
                                fetchOrganizationDetails.isLoading ||
                                fetchOrganizationDetails.isFetching
                              }
                              onChange={(e) => setCity(e.target.value)}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3" md="3" sm="12" className="mt-3">
                          <FormGroup>
                            <Label for="city">{t("Country") + ":"}</Label>
                            <Select
                              name="status"
                              value={country}
                              classNamePrefix="select2-selection"
                              options={locations}
                              placeholder={t("Select")}
                              readOnly
                              isDisabled={
                                getCountries.isFetching ||
                                getCountries.isLoading ||
                                handleSubmitMutation.isLoading ||
                                fetchOrganizationDetails.isLoading ||
                                fetchOrganizationDetails.isFetching
                              }
                              menuPortalTarget={document.body}
                              onChange={(e) => setCountry(e)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Row>
                  </Form>
                </Accordion.Body>
                <Row className="p-4">
                  <Col sm="12" className="d-flex justify-content-end">
                    <Button
                      onClick={restore}
                      color="primary"
                      type="button"
                      outline
                      className="me-2"
                      disabled={
                        handleSubmitMutation.mutating ||
                        fetchOrganizationDetails.isLoading ||
                        fetchOrganizationDetails.isFetching
                      }
                    >
                      {t("Restore")}
                    </Button>

                    <Button
                      color="primary"
                      onClick={handleSubmitMutation.mutate}
                      type="submit"
                      disabled={
                        handleSubmitMutation.isLoading ||
                        fetchOrganizationDetails.isLoading ||
                        fetchOrganizationDetails.isFetching
                      }
                    >
                      {handleSubmitMutation.isLoading ? <TbLoader /> : t("Save")}
                    </Button>
                  </Col>
                </Row>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </div>
    </Row>
  );
};

const mapStatetoProps = (state) => {
  const { token } = state.Login;
  const organization = state.Organization;
  const App = state.App;
  const modules = state.Modules;
  return { organization, token, App, modules };
};

export default withNamespaces()(
  withRouter(connect(mapStatetoProps)(DataProtectionDPOCustomization))
);
