import FullPageLayout from '../components/FullPageLayout';

import Login from '../pages/Authentication/Login';
import GetEmail from '../pages/Authentication/ResetPassword/GetEmail';
import ResetPage from '../pages/Authentication/ResetPassword/ResetPage';
import SetPasswordPage from '../pages/Authentication/SetPasswordPage';
import Logout from '../pages/Authentication/Logout';
import AzureCallback from '../pages/Authentication/AzureCallback';

import thirdPartyRoutes from './third-party-routes';
import Consent from '../pages/Authentication/Consent';

const routes = [
	...thirdPartyRoutes,
	{
		path: '/data_privacy',
		component: Consent,
		layout: FullPageLayout,
	},
	{
		path: '/user_policy',
		component: Consent,
		layout: FullPageLayout,
	},
	{
		path: '/set-password/:token',
		component: SetPasswordPage,
		layout: FullPageLayout,
	},
	{
		path: '/azure_callback',
		component: AzureCallback,
		layout: FullPageLayout,
	},

	{
		path: '/reset-password/:token',
		component: ResetPage,
		layout: FullPageLayout,
	},
	{
		path: '/reset-password',
		component: GetEmail,
		layout: FullPageLayout,
	},
	{
		path: '/signin',
		component: Login,
		layout: FullPageLayout,
	},
	{
		path: '/logout',
		component: Logout,
		layout: FullPageLayout,
	}
];

export default routes;
