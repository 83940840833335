import { memo, useState } from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";

const { Label, Col, Input, Button } = require("reactstrap");

const MeasuresList = ({
  measures,
  measureType,
  handleInputChange,
  toggleMeasure,
  onAddNewMeasure,
  onRemoveMeasure,
  subSectionId,
  t,
  areAllMeasuresUnchecked
}) => {
  const [newMeasure, setNewMeasure] = useState("");

  const handleNewMeasureChange = (e) => {
    setNewMeasure(e.target.value);
  };

  const handleAddNewMeasure = () => {
    if (newMeasure.trim()) {
      onAddNewMeasure(newMeasure, measureType, subSectionId);
      setNewMeasure("");
    }
  };


  const handleRemoveMeasure = (id) => {
    onRemoveMeasure(id);
  };

  return (
    <Col md={6} sm={12}>
      <Label className={`form-label ${areAllMeasuresUnchecked ? "text-danger" : ""}`}>
        {t(
          `${
            measureType.charAt(0).toUpperCase() + measureType.slice(1)
          } measures`
        ) + ":"}
      </Label>

      <ul>
        {measures?.map((measure, measure_index) => {
          const { is_checked, title, type, id } = measure;
          return (
            type === measureType && (
              <li className="p-1" key={id} style={{ listStyleType: "none" }}>
                <Input
                  type="checkbox"
                  defaultChecked={is_checked}
                  onChange={() => {
                    handleInputChange(`measures_${measure_index}`);
                    toggleMeasure(id);
                  }}
                  className="me-2"
                />
                {t(title)}
                <i
                  onClick={() => handleRemoveMeasure(id)}
                  className="fa fa-trash text-danger ms-3"
                  aria-hidden="true"
                  style={{cursor: "pointer"}}
                ></i>
              </li>
            )
          );
        })}
      </ul>
      <div className="d-flex align-items-center mt-2">
        <Input
          type="text"
          placeholder={t("Add New")}
          value={newMeasure}
          onChange={handleNewMeasureChange}
          style={{ width: "15rem" }}
        />
        <Button color="primary" onClick={handleAddNewMeasure} className="ms-2">
          {t("Add")}
        </Button>
      </div>
    </Col>
  );
};

const mapStatetoProps = (state) => {
  const { Organization } = state;

  return {
    Organization,
  };
};

export default withNamespaces()(connect(mapStatetoProps)(memo(MeasuresList)));
