import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import MenuGenerator from "../MenuGenerator";

class SidebarContent extends Component {
  render() {
    return (
      <React.Fragment>
        <div id="sidebar-menu">
          <MenuGenerator 
            layout="vertical"
          />
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(
    withNamespaces()(SidebarContent)
);
