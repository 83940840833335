import { withNamespaces } from "react-i18next";
import {  memo, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import "./style.scss";
import DataProtectionHardwareService from "../../../apis/DataProtectionHardwareService";

const Deletion = ({ t, modal, setModal, users, id, setDeletionComplete }) => {
  const toggle = () => setModal(!modal);

  // SELECTED
  const [selectedReason, setSelectedReason] = useState("");
  const [selectedErasedBy, setSelectedErasedBy] = useState(null);
  const [selectedDisposedBy, setSelectedDisposedBy] = useState(null);
  const [selectedErasedDate, setSelectedErasedDate] = useState(null);
  const [selectedDisposedDate, setSelectedDisposedDate] = useState(null);

  // ERROR MESSAGES
  const [erasedByError, setErasedByError] = useState("");
  const [disposedByError, setDisposedByError] = useState("");
  const [erasedDateError, setErasedDateError] = useState("");
  const [disposedDateError, setDisposedDateError] = useState("");
  const [deletionReasonError, setDeletionReasonError] = useState("");

  // ON CHANGES

  const onErasedByChange = (event) => {
    const { value } = event.target;
    setSelectedErasedBy(!value ? null : value);
    setErasedByError(value ? "" : t("This field is required"));
  };

  const onDisposedByChange = (event) => {
    const { value } = event.target;
    setSelectedDisposedBy(!value ? null : value);
    setDisposedByError(value ? "" : t("This field is required"));
  };

  const onErasedDateChange = (event) => {
    setSelectedErasedDate(event.target.value);
    setErasedDateError(event.target.value ? "" : t("This field is required"));
  };

  const onDisposedDateChange = (event) => {
    setSelectedDisposedDate(event.target.value);
    setDisposedDateError(event.target.value ? "" : t("This field is required"));
  };

  const onDeletionReasonChange = (event) => {
    setSelectedReason(event.target.value);
    setDeletionReasonError(
      event.target.value ? "" : t("This field is required")
    );
  };

  // Handle Delete
  const deleteHardware = useMutation({
    mutationFn: async () => {
      const service = DataProtectionHardwareService.getInstance();

      return await service.delete(
        id,
        selectedReason,
        selectedErasedBy,
        selectedErasedDate,
        selectedDisposedBy,
        selectedDisposedDate
      );
    },
    onSuccess: () => {
      setDeletionComplete(true);
      toast(t("Data is removed"), {
        type: "success",
      });
    },
    onError: (error) => {
      if (process.env.NODE_ENV === 'development') console.error(error);

      toast(t("An error occurred while deleting."), {
        type: "error",
      });
    },
  });

  const handleDelete = () => {
    // Validation Logic
    if (
      !selectedErasedBy ||
      !selectedDisposedBy ||
      !selectedErasedDate ||
      !selectedDisposedDate ||
      !selectedReason
    ) {
      toast.error(t("All fields are required"));
      setErasedByError(!selectedErasedBy ? t("This field is required") : "");
      setDisposedByError(
        !selectedDisposedBy ? t("This field is required") : ""
      );
      setErasedDateError(
        !selectedErasedDate ? t("This field is required") : ""
      );
      setDisposedDateError(
        !selectedDisposedDate ? t("This field is required") : ""
      );
      setDeletionReasonError(
        !selectedReason ? t("This field is required") : ""
      );
      return;
    }

    // Perform deletion action
    deleteHardware.mutate();
    // Close the modal
    toggle();
  };

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{t("Delete Asset")}</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="reason">{t("Deletion Reason") + ":"}</Label>
              <Input
                type="textarea"
                placeholder={t("Explain your reason") + "..."}
                name="text"
                id="reason"
                onChange={onDeletionReasonChange}
              />
              <div className="error-message">{deletionReasonError}</div>
            </FormGroup>
            <br></br>
            <FormGroup>
              <Label for="erasedBy">{t("Asset erased by") + ":"}</Label>
              <Input
                type="select"
                name="select"
                id="erasedBy"
                onChange={onErasedByChange}
              >
                <option value="">{t("Select user")}</option>
                {users.map((user) => {
                  return (
                    <option key={user.value} value={user.value}>
                      {user.label}
                    </option>
                  );
                })}
              </Input>
              <div className="error-message">{erasedByError}</div>
            </FormGroup>

            <br></br>
            <FormGroup>
              <Label for="erasedOn">{t("Asset erased on") + ":"}</Label>
              <Input
                name="erasedOn"
                id="erasedOn"
                type="date"
                // min={this.dateUtils.getCurrentDate()}
                // max={this.state.deadlineDate}
                onChange={onErasedDateChange}
              />
              <div className="error-message">{erasedDateError}</div>
            </FormGroup>

            <br></br>

            <FormGroup>
              <Label for="disposedOn">{t("Asset disposed on") + ":"}</Label>
              <Input
                name="disposedOn"
                id="disposedOn"
                type="date"
                onChange={onDisposedDateChange}
              />
              <div className="error-message">{disposedDateError}</div>
            </FormGroup>

            <br></br>
            <FormGroup>
              <Label for="disposedBy">{t("Asset disposed by") + ":"}</Label>
              <Input
                type="select"
                name="select"
                id="disposedBy"
                onChange={onDisposedByChange}
              >
                <option value="">{t("Select user")}</option>
                {users.map((user) => {
                  return (
                    <option key={user.value} value={user.value}>
                      {user.label}
                    </option>
                  );
                })}
              </Input>
              <div className="error-message">{disposedByError}</div>
            </FormGroup>
            <br></br>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>{t("Cancel")}</Button>{" "}
          <Button color="danger" onClick={handleDelete}>
            {t("Delete")}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default withNamespaces()(withRouter(memo(Deletion)));
