import React, { Fragment, useEffect, useState } from "react";
import {
  Row,
  Col,
  PaginationItem,
  PaginationLink,
  Pagination,
} from "reactstrap";
import { withNamespaces } from "react-i18next";
import BootstrapTable from "react-bootstrap-table-next";

const LEFT_PAGE = "LEFT";
const RIGHT_PAGE = "RIGHT";

const range = (from, to, step = 1) => {
  let i = from;
  const range = [];

  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
};

const TableWithPagination = (props) => {
  const {
    fetchData,
    data,
    columns,
    totalSize,
    sizePerPage,
    pageIndex: _pageIndex,
    remote,
    onTableChange,
    defaultSorted,
    loading,
  } = props;

  const [pageNeighbours, setPageNeighbours] = useState(
    Math.max(1, Math.min(0, 2))
  );
  const [pageIndex, setPageIndex] = useState(1);
  const nPage = Math.ceil(totalSize / sizePerPage);
  const pageOptions = {
    sizePerPage: sizePerPage,
    totalSize: totalSize,
    custom: true,
  };

  useEffect(() => {
    if (_pageIndex || _pageIndex === 0) setPageIndex(_pageIndex);
  }, [_pageIndex]);

  const gotoPage = (page) => {
    setPageIndex(page);
    return fetchData({ pageIndex: page });
  };

  const handleClick = (page, evt) => {
    evt.preventDefault();
    gotoPage(page);
  };

  const handleMoveLeft = (evt) => {
    evt.preventDefault();
    gotoPage(pageIndex - 1);
  };

  const handleMoveRight = (evt) => {
    evt.preventDefault();
    gotoPage(pageIndex + 1);
  };

  const fetchPageNumbers = () => {
    const totalPages = nPage;
    const currentPage = pageIndex;
    const totalNumbers = pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      let pages = [];

      const leftBound = currentPage - pageNeighbours;
      const rightBound = currentPage + pageNeighbours;
      const beforeLastPage = totalPages - 1;

      const startPage = leftBound > 2 ? leftBound : 2;
      const endPage = rightBound < beforeLastPage ? rightBound : beforeLastPage;

      pages = range(startPage, endPage);

      const pagesCount = nPage;
      const singleSpillOffset = totalNumbers - pagesCount - 1;

      const leftSpill = startPage > 2;
      const rightSpill = endPage < beforeLastPage;

      const leftSpillPage = LEFT_PAGE;
      const rightSpillPage = RIGHT_PAGE;

      if (leftSpill && !rightSpill) {
        const extraPages = range(startPage - singleSpillOffset, startPage - 1);
        pages = [leftSpillPage, ...extraPages, ...pages];
      } else if (!leftSpill && rightSpill) {
        const extraPages = range(endPage + 1, endPage + singleSpillOffset);
        pages = [...pages, ...extraPages, rightSpillPage];
      } else if (leftSpill && rightSpill) {
        pages = [leftSpillPage, ...pages, rightSpillPage];
      }

      return [1, ...pages, totalPages];
    }

    return range(1, totalPages);
  };

  if (totalSize === 0) return null;

  const currentPage = pageIndex;
  const pages = fetchPageNumbers();

  return (
    <Fragment>
      <BootstrapTable
        remote={remote}
        loading={loading}
        onTableChange={onTableChange}
        defaultSorted={defaultSorted}
        keyField={"id"}
        responsive
        bordered={false}
        data={data}
        striped={false}
        columns={columns}
        wrapperClasses="table-responsive"
        classes={"table"}
        headerWrapperClasses={"thead-light"}
        style={{
          overflowX: "auto",
        }}
      />

      <Row>
        <Col lg="2" md="2" hidden={data.length === 0}>
          <div
            className="p-2"
            style={{
              backgroundColor: "#74788d",
              textAlign: "center",
              verticalAlign: "middle",
              borderRadius: "10%",
              color: "#fff",
              width: "40px",
              height: "40px",
            }}
          >
            {sizePerPage}
          </div>
        </Col>
        <Col lg="10" md="10">
          <Pagination
            // style={{ float: "right" }}
            style={{
              float:
                localStorage.getItem("lang_dir") &&
                localStorage.getItem("lang_dir") === "rtl"
                  ? "left"
                  : "right",
            }}
            aria-label="Page navigation example"
          >
            {pages.map((page, index) => {
              if (page === LEFT_PAGE)
                return (
                  <PaginationItem key={index}>
                    <PaginationLink previous onClick={handleMoveLeft} />
                  </PaginationItem>
                );

              if (page === RIGHT_PAGE)
                return (
                  <PaginationItem key={index}>
                    <PaginationLink next onClick={handleMoveRight} />
                  </PaginationItem>
                );

              return (
                <PaginationItem key={index} disabled={pageIndex === page ? true : false} active={pageIndex === page ? true : false}>
                  <PaginationLink onClick={(e) => handleClick(page, e)}>
                    {page}
                  </PaginationLink>
                </PaginationItem>
              );
            })}
            {/* </ul>
          </nav> */}
          </Pagination>
        </Col>
      </Row>
    </Fragment>
  );
};

export default withNamespaces()(TableWithPagination);
