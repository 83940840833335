import { withNamespaces } from "react-i18next";
import React, { useEffect, useRef, useMemo, useState } from "react";
import Select from "react-select";
import IndustriesService from "src/modules/3rd-party-management/apis/IndustriesService";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { AvField, AvForm } from "availity-reactstrap-validation";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Row,
    Col,
    ModalFooter,
    Label
} from "reactstrap";
import {
    CompanySizes
} from "src/modules/3rd-party-management/constants";
import Spinner from 'react-bootstrap/Spinner';
import SupplierService from "src/modules/3rd-party-management/apis/SupplierService";

const EditSupplierModal = ({
    t,
    lng,
    supplierId,
    closeModal,
    onComplete
}) => {
    const editGeneralInfosFormRef = useRef();

    const [ selectedIndustries, setSelectedIndustries ] = useState([]);  

    const {
        isFetching  :   industiresAreFetching,
        data        :   industires,
        refetch     :   refetchIndustries
    } = useQuery({
        queryKey: [
            '3rd-party-management-fetch-industries-list-query',
            lng
        ],
        queryFn: async () => {
            const service = IndustriesService.getInstance();

            return await service.fetchList({
                language: lng !== 'en' ? lng : undefined
            });
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onError: () => {
            toast(t('An error occurred while fetching industires list.'), {
                type: 'error',
            });
        }
    });

    const {
        mutate      :   updateSupplierGeneralInfosMutation,
        isLoading   :   updateSupplierGeneralInfosMutationInProcess   
    } = useMutation({
        mutationFn: async (payload) => {
            const service = SupplierService.getInstance();
            return await service.updateGeneralInfos(payload);
        },
        onSuccess: () => {
            toast(t("General information of third party updated successfully."), {
                type: "success",
            });
            onComplete && onComplete();
            closeModal();
        },
        onError: () => {
            toast(t("An error occurred while updating General information."), {
                type: "error",
            });
        }
    });

    const {
        data        :   selectedSupplierToEditInfos,
        refetch     :   fetchSupplierInfos,
        isFetching  :   supplierInfosIsFetching
    } = useQuery({
		queryKey: ['3rd-party-management-supplier-details-basic-infos', supplierId],
		queryFn: async () => {
			const service = SupplierService.getInstance();

            return await service.fetchBasicInfos(supplierId, {});
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
        enabled: false,
		onError: () => {
			toast(t('An error occurred while fetching supplier basic information.'), {
				type: 'error',
			});
		}
	});

    const industiresMemoList = useMemo(() => {
        return (Array.isArray(industires) ? industires : []).map((industry) => {
            return {
                value : industry.id,
                label : `${industry.id} - ${industry.title}`
            };
        });
    }, [ industires ]);

    useEffect(() => {
        if(supplierId){
            fetchSupplierInfos();
        }
    }, [supplierId]);

    useEffect(() => {
        refetchIndustries();
    }, [ lng ]);

    useEffect(() => {
        if(industiresMemoList && selectedSupplierToEditInfos?.industries){
            const result = (selectedSupplierToEditInfos.industries || []).map((selectedIndustry) => {
                const translatedIndustry = industiresMemoList.find((industry) => industry.value === selectedIndustry.id);

                return {
                    value: selectedIndustry.id,
                    label: translatedIndustry?.label || selectedIndustry.name
                }
            });

            setSelectedIndustries(result);
        }
    }, [ industiresMemoList, selectedSupplierToEditInfos ]);

    const sizes = [];

    for(const sizeKey in CompanySizes){
        sizes.push({
            value : sizeKey,
            label : `${CompanySizes[sizeKey]?.title} ${t("Employees")}`
        });
    }

    return (
        <Modal isOpen={ supplierId } backdrop="static" keyboard={ false } size="xl">   
            <AvForm ref={ editGeneralInfosFormRef } onValidSubmit={(e, values) => {
                updateSupplierGeneralInfosMutation({
                    supplier    :   supplierId,
                    generalInfo :   values
                });
            }} className="needs-validation m-2">
                <ModalHeader toggle={closeModal}>
                    {t("General Information")}
                </ModalHeader>

                <ModalBody>
                    <Row className="mb-3">
                        <Col md="6" sm="12">
                            <Label for="edit-general-infos-name-input">
                                {t("Name")}
                            </Label>
                            {supplierInfosIsFetching ? (
                                <p style={{width: '100%', height: '34px'}} className={`dt-field dt-skeleton`}></p>
                            ) : (
                                <AvField
                                    name="name"
                                    type="text"
                                    errorMessage={t("This field cannot be blank")}
                                    className="form-control"
                                    validate={{
                                        required: { value: true },
                                    }}
                                    id="edit-general-infos-name-input"
                                    defaultValue={selectedSupplierToEditInfos?.name}
                                />
                            )}
                        </Col>

                        <Col md="6" sm="12">
                            <Label for="edit-general-infos-website-input">
                                {t("Website")}
                            </Label>
                            {supplierInfosIsFetching ? (
                                <p style={{width: '100%', height: '34px'}} className={`dt-field dt-skeleton`}></p>
                            ) : (
                                <AvField
                                    name="websiteUrl"
                                    type="text"
                                    errorMessage={t("This field cannot be blank")}
                                    className="form-control"
                                    validate={{
                                        required: { value: true },
                                    }}
                                    id="edit-general-infos-website-input"
                                    defaultValue={selectedSupplierToEditInfos?.websiteUrl}
                                />
                            )}
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col md="6" sm="12">
                            <Label for="edit-general-infos-industry-input">
                                {t("Industries")}
                            </Label>
                            {
                                supplierInfosIsFetching ? (
                                    <p style={{width: '100%', height: '34px'}} className={`dt-field dt-skeleton`}></p>
                                ) : (
                                    <>
                                        <Select
                                            isMulti
                                            isLoading={ industiresAreFetching }
                                            isDisabled={ industiresAreFetching }
                                            placeholder={t("Select") + "..."}
                                            classNamePrefix="select2-selection"
                                            id='edit-general-infos-industry-input'
                                            options={ industiresMemoList }
                                            menuPortalTarget={ document.body }
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                            onChange={(e) => {
                                                setSelectedIndustries(e)
                                            }}
                                            value={selectedIndustries}
                                        />
                                        <AvField
                                            name="industries"
                                            type="hidden" 
                                            errorMessage={t("This field cannot be blank")}
                                            validate={{
                                                required: { value: true },
                                            }}
                                            value={(selectedIndustries || []).map((industry) => industry.value.toString())}
                                        />
                                    </>
                                )
                            }
                        </Col>

                        <Col md="6" sm="12">
                            <Label for="edit-general-infos-company-size-input">
                                {t("Company Size")}
                            </Label>
                            {supplierInfosIsFetching ? (
                                <p style={{width: '100%', height: '34px'}} className={`dt-field dt-skeleton`}></p>
                            ) : (
                                <>
                                    <Select
                                        placeholder={t("Select") + "..."}
                                        classNamePrefix="select2-selection"
                                        id='edit-general-infos-company-size-input'
                                        options={ sizes }
                                        menuPortalTarget={ document.body }
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                        onChange={(e) => {
                                            const sizeInput = editGeneralInfosFormRef.current._inputs['companySize'];
                                            if(sizeInput){
                                                sizeInput.value = e.value;
                                                sizeInput.validate();
                                            }
                                        }}
                                        defaultValue={selectedSupplierToEditInfos?.employeeSize && {
                                            value   :   selectedSupplierToEditInfos.employeeSize,
                                            label   :   CompanySizes[selectedSupplierToEditInfos.employeeSize]?.title
                                        }}
                                    />
                                    <AvField
                                        name="companySize"
                                        type="hidden" 
                                        errorMessage={t("This field cannot be blank")}
                                        validate={{
                                            required: { value: true },
                                        }}
                                        defaultValue={ selectedSupplierToEditInfos?.employeeSize }
                                    />
                                </>
                            )}
                            
                        </Col>
                    </Row>

                </ModalBody>

                <ModalFooter>
                    <Button onClick={closeModal} color="primary" type="button" outline>
                        { t('Cancel') }
                    </Button>

                    <Button disabled={ updateSupplierGeneralInfosMutationInProcess } color="primary" type="submit" size="md">
                        {
                            updateSupplierGeneralInfosMutationInProcess ? (
                                <Spinner className="me-2" animation="border" variant="white" size="sm"/>
                            ) : (
                                <i className="ri-pencil-line align-middle me-2"></i>
                            )
                            
                        }
                        { t('Update') }
                    </Button>
                </ModalFooter>
            </AvForm>
        </Modal>
    )
};

export default withNamespaces()(EditSupplierModal);