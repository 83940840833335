import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Alert, Button, Col, Container, Row } from "reactstrap";
import Breadcrumbs from "src/components/Common/Breadcrumb";
import NewCustomization from "./NewVersion";
import OldCustomization from "./OldVersion";
import { useState } from "react";

const Customisation = ({
    t,
    user
}) => {
    const [ showNewVersion, setShowNewVersion ] = useState(!user.isOld)

    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs
                    title={t("Customisation")}
                    breadcrumbItems={[
                        { title: "SIP", link: "/" },
                        { title: t("Customisation"), link: "#" },
                    ]}
                />

                <Row>
                    <Col sm='12'>
                        <Alert color="info">
                            <h4 className="alert-heading">
                                {t('Note')}
                            </h4>
                            <p>
                                {t('You can switch between old and new version of the customisation page with clicking the button below')}.
                            </p>
                            <hr />
                            <div>
                                <Button color="primary" onClick={() => setShowNewVersion(!showNewVersion)}>
                                    {t(`Switch to ${showNewVersion ? 'old' : 'new'} version`)}
                                </Button>   
                            </div>
                        </Alert>
                    </Col>
                </Row>

                <Row>
                    <Col sm='12'>
                        {showNewVersion ? (
                            <NewCustomization />
                        ) : (
                            <OldCustomization />
                        )}
                    </Col>
                </Row>

            </Container>
        </div>
    )
}

const mapStatetoProps = (state) => {
    const { token, user } = state.Login;
    const organization = state.Organization;
    return { organization, token, user };
};

export default withNamespaces()(
    withRouter(connect(mapStatetoProps)(Customisation))
);
  