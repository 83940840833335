import React, { Component } from 'react';
import { Spinner } from 'reactstrap';

class CardLoading extends Component {
    render(){
        return (
            <div className="card-loading-container">
                <Spinner className="chat-messages-spinner-loading" color="info" />
            </div>
        )
    }
}

export default CardLoading;