import {TRANSLATION_KEY_GLOBAL_INFO as KEY_GLOBAL_INFO} from '../../common/constants/TranslationKeys';

const KEY_GLOBAL_COUNTRIES = KEY_GLOBAL_INFO + 'COUNTRIES.';

export const COUNTRY_LIST = [
    {
        id: 1,
        name: "Afghanistan",
        code: "AF",
        titleKey: KEY_GLOBAL_COUNTRIES + "AFGHANISTAN"
    },
    {
        id: 2,
        name: "Albania",
        code: "AL",
        titleKey: KEY_GLOBAL_COUNTRIES + "ALBANIA"
    },
    {
        id: 3,
        name: "Algeria",
        code: "DZ",
        titleKey: KEY_GLOBAL_COUNTRIES + "ALGERIA"
    },
    {
        id: 4,
        name: "American Samoa",
        code: "AS",
        titleKey: KEY_GLOBAL_COUNTRIES + "AMERICAN_SAMOA"
    },
    {
        id: 5,
        name: "Andorra",
        code: "AD",
        titleKey: KEY_GLOBAL_COUNTRIES + "ANDORRA"
    },
    {
        id: 6,
        name: "Angola",
        code: "AO",
        titleKey: KEY_GLOBAL_COUNTRIES + "ANGOLA"
    },
    {
        id: 7,
        name: "Anguilla",
        code: "AI",
        titleKey: KEY_GLOBAL_COUNTRIES + "ANGUILLA"
    },
    {
        id: 8,
        name: "Antarctica",
        code: "AQ",
        titleKey: KEY_GLOBAL_COUNTRIES + "ANTARCTICA"
    },
    {
        id: 9,
        name: "Antigua and Barbuda",
        code: "AG",
        titleKey: KEY_GLOBAL_COUNTRIES + "ANTIGUA_AND_BARBUDA"
    },
    {
        id: 10,
        name: "Argentina",
        code: "AR",
        titleKey: KEY_GLOBAL_COUNTRIES + "ARGENTINA"
    },
    {
        id: 11,
        name: "Armenia",
        code: "AM",
        titleKey: KEY_GLOBAL_COUNTRIES + "ARMENIA"
    },
    {
        id: 12,
        name: "Aruba",
        code: "AW",
        titleKey: KEY_GLOBAL_COUNTRIES + "ARUBA"
    },
    {
        id: 13,
        name: "Australia",
        code: "AU",
        titleKey: KEY_GLOBAL_COUNTRIES + "AUSTRALIA"
    },
    {
        id: 14,
        name: "Austria",
        code: "AT",
        titleKey: KEY_GLOBAL_COUNTRIES + "AUSTRIA"
    },
    {
        id: 15,
        name: "Azerbaijan",
        code: "AZ",
        titleKey: KEY_GLOBAL_COUNTRIES + "AZERBAIJAN"
    },
    {
        id: 16,
        name: "Bahamas (the)",
        code: "BS",
        titleKey: KEY_GLOBAL_COUNTRIES + "BAHAMAS_THE"
    },
    {
        id: 17,
        name: "Bahrain",
        code: "BH",
        titleKey: KEY_GLOBAL_COUNTRIES + "BAHRAIN"
    },
    {
        id: 18,
        name: "Bangladesh",
        code: "BD",
        titleKey: KEY_GLOBAL_COUNTRIES + "BANGLADESH"
    },
    {
        id: 19,
        name: "Barbados",
        code: "BB",
        titleKey: KEY_GLOBAL_COUNTRIES + "BARBADOS"
    },
    {
        id: 20,
        name: "Belarus",
        code: "BY",
        titleKey: KEY_GLOBAL_COUNTRIES + "BELARUS"
    },
    {
        id: 21,
        name: "Belgium",
        code: "BE",
        titleKey: KEY_GLOBAL_COUNTRIES + "BELGIUM"
    },
    {
        id: 22,
        name: "Belize",
        code: "BZ",
        titleKey: KEY_GLOBAL_COUNTRIES + "BELIZE"
    },
    {
        id: 23,
        name: "Benin",
        code: "BJ",
        titleKey: KEY_GLOBAL_COUNTRIES + "BENIN"
    },
    {
        id: 24,
        name: "Bermuda",
        code: "BM",
        titleKey: KEY_GLOBAL_COUNTRIES + "BERMUDA"
    },
    {
        id: 25,
        name: "Bhutan",
        code: "BT",
        titleKey: KEY_GLOBAL_COUNTRIES + "BHUTAN"
    },
    {
        id: 26,
        name: "Bolivia (Plurinational State of)",
        code: "BO",
        titleKey: KEY_GLOBAL_COUNTRIES + "BOLIVIA_PLURINATIONAL_STATE_OF"
    },
    {
        id: 27,
        name: "Bonaire, Sint Eustatius and Saba",
        code: "BQ",
        titleKey: KEY_GLOBAL_COUNTRIES + "BONAIRE_SINT_EUSTATIUS_AND_SABA"
    },
    {
        id: 28,
        name: "Bosnia and Herzegovina",
        code: "BA",
        titleKey: KEY_GLOBAL_COUNTRIES + "BOSNIA_AND_HERZEGOVINA"
    },
    {
        id: 29,
        name: "Botswana",
        code: "BW",
        titleKey: KEY_GLOBAL_COUNTRIES + "BOTSWANA"
    },
    {
        id: 30,
        name: "Bouvet Island",
        code: "BV",
        titleKey: KEY_GLOBAL_COUNTRIES + "BOUVET_ISLAND"
    },
    {
        id: 31,
        name: "Brazil",
        code: "BR",
        titleKey: KEY_GLOBAL_COUNTRIES + "BRAZIL"
    },
    {
        id: 32,
        name: "British Indian Ocean Territory (the)",
        code: "IO",
        titleKey: KEY_GLOBAL_COUNTRIES + "BRITISH_INDIAN_OCEAN_TERRITORY_THE"
    },
    {
        id: 33,
        name: "Brunei Darussalam",
        code: "BN",
        titleKey: KEY_GLOBAL_COUNTRIES + "BRUNEI_DARUSSALAM"
    },
    {
        id: 34,
        name: "Bulgaria",
        code: "BG",
        titleKey: KEY_GLOBAL_COUNTRIES + "BULGARIA"
    },
    {
        id: 35,
        name: "Burkina Faso",
        code: "BF",
        titleKey: KEY_GLOBAL_COUNTRIES + "BURKINA_FASO"
    },
    {
        id: 36,
        name: "Burundi",
        code: "BI",
        titleKey: KEY_GLOBAL_COUNTRIES + "BURUNDI"
    },
    {
        id: 37,
        name: "Cabo Verde",
        code: "CV",
        titleKey: KEY_GLOBAL_COUNTRIES + "CABO_VERDE"
    },
    {
        id: 38,
        name: "Cambodia",
        code: "KH",
        titleKey: KEY_GLOBAL_COUNTRIES + "CAMBODIA"
    },
    {
        id: 39,
        name: "Cameroon",
        code: "CM",
        titleKey: KEY_GLOBAL_COUNTRIES + "CAMEROON"
    },
    {
        id: 40,
        name: "Canada",
        code: "CA",
        titleKey: KEY_GLOBAL_COUNTRIES + "CANADA"
    },
    {
        id: 41,
        name: "Cayman Islands (the)",
        code: "KY",
        titleKey: KEY_GLOBAL_COUNTRIES + "CAYMAN_ISLANDS_THE"
    },
    {
        id: 42,
        name: "Central African Republic (the)",
        code: "CF",
        titleKey: KEY_GLOBAL_COUNTRIES + "CENTRAL_AFRICAN_REPUBLIC_THE"
    },
    {
        id: 43,
        name: "Chad",
        code: "TD",
        titleKey: KEY_GLOBAL_COUNTRIES + "CHAD"
    },
    {
        id: 44,
        name: "Chile",
        code: "CL",
        titleKey: KEY_GLOBAL_COUNTRIES + "CHILE"
    },
    {
        id: 45,
        name: "China",
        code: "CN",
        titleKey: KEY_GLOBAL_COUNTRIES + "CHINA"
    },
    {
        id: 46,
        name: "Christmas Island",
        code: "CX",
        titleKey: KEY_GLOBAL_COUNTRIES + "CHRISTMAS_ISLAND"
    },
    {
        id: 47,
        name: "Cocos (Keeling) Islands (the)",
        code: "CC",
        titleKey: KEY_GLOBAL_COUNTRIES + "COCOS_KEELING_ISLANDS_THE"
    },
    {
        id: 48,
        name: "Colombia",
        code: "CO",
        titleKey: KEY_GLOBAL_COUNTRIES + "COLOMBIA"
    },
    {
        id: 49,
        name: "Comoros (the)",
        code: "KM",
        titleKey: KEY_GLOBAL_COUNTRIES + "COMOROS_THE"
    },
    {
        id: 50,
        name: "Congo (the Democratic Republic of the)",
        code: "CD",
        titleKey: KEY_GLOBAL_COUNTRIES + "CONGO_THE_DEMOCRATIC_REPUBLIC_OF_THE"
    },
    {
        id: 51,
        name: "Congo (the)",
        code: "CG",
        titleKey: KEY_GLOBAL_COUNTRIES + "CONGO_THE"
    },
    {
        id: 52,
        name: "Cook Islands (the)",
        code: "CK",
        titleKey: KEY_GLOBAL_COUNTRIES + "COOK_ISLANDS_THE"
    },
    {
        id: 53,
        name: "Costa Rica",
        code: "CR",
        titleKey: KEY_GLOBAL_COUNTRIES + "COSTA_RICA"
    },
    {
        id: 54,
        name: "Croatia",
        code: "HR",
        titleKey: KEY_GLOBAL_COUNTRIES + "CROATIA"
    },
    {
        id: 55,
        name: "Cuba",
        code: "CU",
        titleKey: KEY_GLOBAL_COUNTRIES + "CUBA"
    },
    {
        id: 56,
        name: "Curaçao",
        code: "CW",
        titleKey: KEY_GLOBAL_COUNTRIES + "CURAÇAO"
    },
    {
        id: 57,
        name: "Cyprus",
        code: "CY",
        titleKey: KEY_GLOBAL_COUNTRIES + "CYPRUS"
    },
    {
        id: 58,
        name: "Czechia",
        code: "CZ",
        titleKey: KEY_GLOBAL_COUNTRIES + "CZECHIA"
    },
    {
        id: 59,
        name: "Côte d'Ivoire",
        code: "CI",
        titleKey: KEY_GLOBAL_COUNTRIES + "CÔTE_DIVOIRE"
    },
    {
        id: 60,
        name: "Denmark",
        code: "DK",
        titleKey: KEY_GLOBAL_COUNTRIES + "DENMARK"
    },
    {
        id: 61,
        name: "Djibouti",
        code: "DJ",
        titleKey: KEY_GLOBAL_COUNTRIES + "DJIBOUTI"
    },
    {
        id: 62,
        name: "Dominica",
        code: "DM",
        titleKey: KEY_GLOBAL_COUNTRIES + "DOMINICA"
    },
    {
        id: 63,
        name: "Dominican Republic (the)",
        code: "DO",
        titleKey: KEY_GLOBAL_COUNTRIES + "DOMINICAN_REPUBLIC_THE"
    },
    {
        id: 64,
        name: "Ecuador",
        code: "EC",
        titleKey: KEY_GLOBAL_COUNTRIES + "ECUADOR"
    },
    {
        id: 65,
        name: "Egypt",
        code: "EG",
        titleKey: KEY_GLOBAL_COUNTRIES + "EGYPT"
    },
    {
        id: 66,
        name: "El Salvador",
        code: "SV",
        titleKey: KEY_GLOBAL_COUNTRIES + "EL_SALVADOR"
    },
    {
        id: 67,
        name: "Equatorial Guinea",
        code: "GQ",
        titleKey: KEY_GLOBAL_COUNTRIES + "EQUATORIAL_GUINEA"
    },
    {
        id: 68,
        name: "Eritrea",
        code: "ER",
        titleKey: KEY_GLOBAL_COUNTRIES + "ERITREA"
    },
    {
        id: 69,
        name: "Estonia",
        code: "EE",
        titleKey: KEY_GLOBAL_COUNTRIES + "ESTONIA"
    },
    {
        id: 70,
        name: "Eswatini",
        code: "SZ",
        titleKey: KEY_GLOBAL_COUNTRIES + "ESWATINI"
    },
    {
        id: 71,
        name: "Ethiopia",
        code: "ET",
        titleKey: KEY_GLOBAL_COUNTRIES + "ETHIOPIA"
    },
    {
        id: 72,
        name: "Falkland Islands (the) [Malvinas]",
        code: "FK",
        titleKey: KEY_GLOBAL_COUNTRIES + "FALKLAND_ISLANDS_THE_MALVINAS"
    },
    {
        id: 73,
        name: "Faroe Islands (the)",
        code: "FO",
        titleKey: KEY_GLOBAL_COUNTRIES + "FAROE_ISLANDS_THE"
    },
    {
        id: 74,
        name: "Fiji",
        code: "FJ",
        titleKey: KEY_GLOBAL_COUNTRIES + "FIJI"
    },
    {
        id: 75,
        name: "Finland",
        code: "FI",
        titleKey: KEY_GLOBAL_COUNTRIES + "FINLAND"
    },
    {
        id: 76,
        name: "France",
        code: "FR",
        titleKey: KEY_GLOBAL_COUNTRIES + "FRANCE"
    },
    {
        id: 77,
        name: "French Guiana",
        code: "GF",
        titleKey: KEY_GLOBAL_COUNTRIES + "FRENCH_GUIANA"
    },
    {
        id: 78,
        name: "French Polynesia",
        code: "PF",
        titleKey: KEY_GLOBAL_COUNTRIES + "FRENCH_POLYNESIA"
    },
    {
        id: 79,
        name: "French Southern Territories (the)",
        code: "TF",
        titleKey: KEY_GLOBAL_COUNTRIES + "FRENCH_SOUTHERN_TERRITORIES_THE"
    },
    {
        id: 80,
        name: "Gabon",
        code: "GA",
        titleKey: KEY_GLOBAL_COUNTRIES + "GABON"
    },
    {
        id: 81,
        name: "Gambia (the)",
        code: "GM",
        titleKey: KEY_GLOBAL_COUNTRIES + "GAMBIA_THE"
    },
    {
        id: 82,
        name: "Georgia",
        code: "GE",
        titleKey: KEY_GLOBAL_COUNTRIES + "GEORGIA"
    },
    {
        id: 83,
        name: "Germany",
        code: "DE",
        titleKey: KEY_GLOBAL_COUNTRIES + "GERMANY"
    },
    {
        id: 84,
        name: "Ghana",
        code: "GH",
        titleKey: KEY_GLOBAL_COUNTRIES + "GHANA"
    },
    {
        id: 85,
        name: "Gibraltar",
        code: "GI",
        titleKey: KEY_GLOBAL_COUNTRIES + "GIBRALTAR"
    },
    {
        id: 86,
        name: "Greece",
        code: "GR",
        titleKey: KEY_GLOBAL_COUNTRIES + "GREECE"
    },
    {
        id: 87,
        name: "Greenland",
        code: "GL",
        titleKey: KEY_GLOBAL_COUNTRIES + "GREENLAND"
    },
    {
        id: 88,
        name: "Grenada",
        code: "GD",
        titleKey: KEY_GLOBAL_COUNTRIES + "GRENADA"
    },
    {
        id: 89,
        name: "Guadeloupe",
        code: "GP",
        titleKey: KEY_GLOBAL_COUNTRIES + "GUADELOUPE"
    },
    {
        id: 90,
        name: "Guam",
        code: "GU",
        titleKey: KEY_GLOBAL_COUNTRIES + "GUAM"
    },
    {
        id: 91,
        name: "Guatemala",
        code: "GT",
        titleKey: KEY_GLOBAL_COUNTRIES + "GUATEMALA"
    },
    {
        id: 92,
        name: "Guernsey",
        code: "GG",
        titleKey: KEY_GLOBAL_COUNTRIES + "GUERNSEY"
    },
    {
        id: 93,
        name: "Guinea",
        code: "GN",
        titleKey: KEY_GLOBAL_COUNTRIES + "GUINEA"
    },
    {
        id: 94,
        name: "Guinea-Bissau",
        code: "GW",
        titleKey: KEY_GLOBAL_COUNTRIES + "GUINEA-BISSAU"
    },
    {
        id: 95,
        name: "Guyana",
        code: "GY",
        titleKey: KEY_GLOBAL_COUNTRIES + "GUYANA"
    },
    {
        id: 96,
        name: "Haiti",
        code: "HT",
        titleKey: KEY_GLOBAL_COUNTRIES + "HAITI"
    },
    {
        id: 97,
        name: "Heard Island and McDonald Islands",
        code: "HM",
        titleKey: KEY_GLOBAL_COUNTRIES + "HEARD_ISLAND_AND_MCDONALD_ISLANDS"
    },
    {
        id: 98,
        name: "Holy See (the)",
        code: "VA",
        titleKey: KEY_GLOBAL_COUNTRIES + "HOLY_SEE_THE"
    },
    {
        id: 99,
        name: "Honduras",
        code: "HN",
        titleKey: KEY_GLOBAL_COUNTRIES + "HONDURAS"
    },
    {
        id: 100,
        name: "Hong Kong",
        code: "HK",
        titleKey: KEY_GLOBAL_COUNTRIES + "HONG_KONG"
    },
    {
        id: 101,
        name: "Hungary",
        code: "HU",
        titleKey: KEY_GLOBAL_COUNTRIES + "HUNGARY"
    },
    {
        id: 102,
        name: "Iceland",
        code: "IS",
        titleKey: KEY_GLOBAL_COUNTRIES + "ICELAND"
    },
    {
        id: 103,
        name: "India",
        code: "IN",
        titleKey: KEY_GLOBAL_COUNTRIES + "INDIA"
    },
    {
        id: 104,
        name: "Indonesia",
        code: "ID",
        titleKey: KEY_GLOBAL_COUNTRIES + "INDONESIA"
    },
    {
        id: 105,
        name: "Iran (Islamic Republic of)",
        code: "IR",
        titleKey: KEY_GLOBAL_COUNTRIES + "IRAN_ISLAMIC_REPUBLIC_OF"
    },
    {
        id: 106,
        name: "Iraq",
        code: "IQ",
        titleKey: KEY_GLOBAL_COUNTRIES + "IRAQ"
    },
    {
        id: 107,
        name: "Ireland",
        code: "IE",
        titleKey: KEY_GLOBAL_COUNTRIES + "IRELAND"
    },
    {
        id: 108,
        name: "Isle of Man",
        code: "IM",
        titleKey: KEY_GLOBAL_COUNTRIES + "ISLE_OF_MAN"
    },
    {
        id: 109,
        name: "Israel",
        code: "IL",
        titleKey: KEY_GLOBAL_COUNTRIES + "ISRAEL"
    },
    {
        id: 110,
        name: "Italy",
        code: "IT",
        titleKey: KEY_GLOBAL_COUNTRIES + "ITALY"
    },
    {
        id: 111,
        name: "Jamaica",
        code: "JM",
        titleKey: KEY_GLOBAL_COUNTRIES + "JAMAICA"
    },
    {
        id: 112,
        name: "Japan",
        code: "JP",
        titleKey: KEY_GLOBAL_COUNTRIES + "JAPAN"
    },
    {
        id: 113,
        name: "Jersey",
        code: "JE",
        titleKey: KEY_GLOBAL_COUNTRIES + "JERSEY"
    },
    {
        id: 114,
        name: "Jordan",
        code: "JO",
        titleKey: KEY_GLOBAL_COUNTRIES + "JORDAN"
    },
    {
        id: 115,
        name: "Kazakhstan",
        code: "KZ",
        titleKey: KEY_GLOBAL_COUNTRIES + "KAZAKHSTAN"
    },
    {
        id: 116,
        name: "Kenya",
        code: "KE",
        titleKey: KEY_GLOBAL_COUNTRIES + "KENYA"
    },
    {
        id: 117,
        name: "Kiribati",
        code: "KI",
        titleKey: KEY_GLOBAL_COUNTRIES + "KIRIBATI"
    },
    {
        id: 118,
        name: "Korea (the Democratic People's Republic of)",
        code: "KP",
        titleKey: KEY_GLOBAL_COUNTRIES + "KOREA_THE_DEMOCRATIC_PEOPLES_REPUBLIC_OF"
    },
    {
        id: 119,
        name: "Korea (the Republic of)",
        code: "KR",
        titleKey: KEY_GLOBAL_COUNTRIES + "KOREA_THE_REPUBLIC_OF"
    },
    {
        id: 120,
        name: "Kuwait",
        code: "KW",
        titleKey: KEY_GLOBAL_COUNTRIES + "KUWAIT"
    },
    {
        id: 121,
        name: "Kyrgyzstan",
        code: "KG",
        titleKey: KEY_GLOBAL_COUNTRIES + "KYRGYZSTAN"
    },
    {
        id: 122,
        name: "Lao People's Democratic Republic (the)",
        code: "LA",
        titleKey: KEY_GLOBAL_COUNTRIES + "LAO_PEOPLES_DEMOCRATIC_REPUBLIC_THE"
    },
    {
        id: 123,
        name: "Latvia",
        code: "LV",
        titleKey: KEY_GLOBAL_COUNTRIES + "LATVIA"
    },
    {
        id: 124,
        name: "Lebanon",
        code: "LB",
        titleKey: KEY_GLOBAL_COUNTRIES + "LEBANON"
    },
    {
        id: 125,
        name: "Lesotho",
        code: "LS",
        titleKey: KEY_GLOBAL_COUNTRIES + "LESOTHO"
    },
    {
        id: 126,
        name: "Liberia",
        code: "LR",
        titleKey: KEY_GLOBAL_COUNTRIES + "LIBERIA"
    },
    {
        id: 127,
        name: "Libya",
        code: "LY",
        titleKey: KEY_GLOBAL_COUNTRIES + "LIBYA"
    },
    {
        id: 128,
        name: "Liechtenstein",
        code: "LI",
        titleKey: KEY_GLOBAL_COUNTRIES + "LIECHTENSTEIN"
    },
    {
        id: 129,
        name: "Lithuania",
        code: "LT",
        titleKey: KEY_GLOBAL_COUNTRIES + "LITHUANIA"
    },
    {
        id: 130,
        name: "Luxembourg",
        code: "LU",
        titleKey: KEY_GLOBAL_COUNTRIES + "LUXEMBOURG"
    },
    {
        id: 131,
        name: "Macao",
        code: "MO",
        titleKey: KEY_GLOBAL_COUNTRIES + "MACAO"
    },
    {
        id: 132,
        name: "Madagascar",
        code: "MG",
        titleKey: KEY_GLOBAL_COUNTRIES + "MADAGASCAR"
    },
    {
        id: 133,
        name: "Malawi",
        code: "MW",
        titleKey: KEY_GLOBAL_COUNTRIES + "MALAWI"
    },
    {
        id: 134,
        name: "Malaysia",
        code: "MY",
        titleKey: KEY_GLOBAL_COUNTRIES + "MALAYSIA"
    },
    {
        id: 135,
        name: "Maldives",
        code: "MV",
        titleKey: KEY_GLOBAL_COUNTRIES + "MALDIVES"
    },
    {
        id: 136,
        name: "Mali",
        code: "ML",
        titleKey: KEY_GLOBAL_COUNTRIES + "MALI"
    },
    {
        id: 137,
        name: "Malta",
        code: "MT",
        titleKey: KEY_GLOBAL_COUNTRIES + "MALTA"
    },
    {
        id: 138,
        name: "Marshall Islands (the)",
        code: "MH",
        titleKey: KEY_GLOBAL_COUNTRIES + "MARSHALL_ISLANDS_THE"
    },
    {
        id: 139,
        name: "Martinique",
        code: "MQ",
        titleKey: KEY_GLOBAL_COUNTRIES + "MARTINIQUE"
    },
    {
        id: 140,
        name: "Mauritania",
        code: "MR",
        titleKey: KEY_GLOBAL_COUNTRIES + "MAURITANIA"
    },
    {
        id: 141,
        name: "Mauritius",
        code: "MU",
        titleKey: KEY_GLOBAL_COUNTRIES + "MAURITIUS"
    },
    {
        id: 142,
        name: "Mayotte",
        code: "YT",
        titleKey: KEY_GLOBAL_COUNTRIES + "MAYOTTE"
    },
    {
        id: 143,
        name: "Mexico",
        code: "MX",
        titleKey: KEY_GLOBAL_COUNTRIES + "MEXICO"
    },
    {
        id: 144,
        name: "Micronesia (Federated States of)",
        code: "FM",
        titleKey: KEY_GLOBAL_COUNTRIES + "MICRONESIA_FEDERATED_STATES_OF"
    },
    {
        id: 145,
        name: "Moldova (the Republic of)",
        code: "MD",
        titleKey: KEY_GLOBAL_COUNTRIES + "MOLDOVA_THE_REPUBLIC_OF"
    },
    {
        id: 146,
        name: "Monaco",
        code: "MC",
        titleKey: KEY_GLOBAL_COUNTRIES + "MONACO"
    },
    {
        id: 147,
        name: "Mongolia",
        code: "MN",
        titleKey: KEY_GLOBAL_COUNTRIES + "MONGOLIA"
    },
    {
        id: 148,
        name: "Montenegro",
        code: "ME",
        titleKey: KEY_GLOBAL_COUNTRIES + "MONTENEGRO"
    },
    {
        id: 149,
        name: "Montserrat",
        code: "MS",
        titleKey: KEY_GLOBAL_COUNTRIES + "MONTSERRAT"
    },
    {
        id: 150,
        name: "Morocco",
        code: "MA",
        titleKey: KEY_GLOBAL_COUNTRIES + "MOROCCO"
    },
    {
        id: 151,
        name: "Mozambique",
        code: "MZ",
        titleKey: KEY_GLOBAL_COUNTRIES + "MOZAMBIQUE"
    },
    {
        id: 152,
        name: "Myanmar",
        code: "MM",
        titleKey: KEY_GLOBAL_COUNTRIES + "MYANMAR"
    },
    {
        id: 153,
        name: "Namibia",
        code: "NA",
        titleKey: KEY_GLOBAL_COUNTRIES + "NAMIBIA"
    },
    {
        id: 154,
        name: "Nauru",
        code: "NR",
        titleKey: KEY_GLOBAL_COUNTRIES + "NAURU"
    },
    {
        id: 155,
        name: "Nepal",
        code: "NP",
        titleKey: KEY_GLOBAL_COUNTRIES + "NEPAL"
    },
    {
        id: 156,
        name: "Netherlands (the)",
        code: "NL",
        titleKey: KEY_GLOBAL_COUNTRIES + "NETHERLANDS_THE"
    },
    {
        id: 157,
        name: "New Caledonia",
        code: "NC",
        titleKey: KEY_GLOBAL_COUNTRIES + "NEW_CALEDONIA"
    },
    {
        id: 158,
        name: "New Zealand",
        code: "NZ",
        titleKey: KEY_GLOBAL_COUNTRIES + "NEW_ZEALAND"
    },
    {
        id: 159,
        name: "Nicaragua",
        code: "NI",
        titleKey: KEY_GLOBAL_COUNTRIES + "NICARAGUA"
    },
    {
        id: 160,
        name: "Niger (the)",
        code: "NE",
        titleKey: KEY_GLOBAL_COUNTRIES + "NIGER_THE"
    },
    {
        id: 161,
        name: "Nigeria",
        code: "NG",
        titleKey: KEY_GLOBAL_COUNTRIES + "NIGERIA"
    },
    {
        id: 162,
        name: "Niue",
        code: "NU",
        titleKey: KEY_GLOBAL_COUNTRIES + "NIUE"
    },
    {
        id: 163,
        name: "Norfolk Island",
        code: "NF",
        titleKey: KEY_GLOBAL_COUNTRIES + "NORFOLK_ISLAND"
    },
    {
        id: 164,
        name: "Northern Mariana Islands (the)",
        code: "MP",
        titleKey: KEY_GLOBAL_COUNTRIES + "NORTHERN_MARIANA_ISLANDS_THE"
    },
    {
        id: 165,
        name: "Norway",
        code: "NO",
        titleKey: KEY_GLOBAL_COUNTRIES + "NORWAY"
    },
    {
        id: 166,
        name: "Oman",
        code: "OM",
        titleKey: KEY_GLOBAL_COUNTRIES + "OMAN"
    },
    {
        id: 167,
        name: "Pakistan",
        code: "PK",
        titleKey: KEY_GLOBAL_COUNTRIES + "PAKISTAN"
    },
    {
        id: 168,
        name: "Palau",
        code: "PW",
        titleKey: KEY_GLOBAL_COUNTRIES + "PALAU"
    },
    {
        id: 169,
        name: "Palestine, State of",
        code: "PS",
        titleKey: KEY_GLOBAL_COUNTRIES + "PALESTINE_STATE_OF"
    },
    {
        id: 170,
        name: "Panama",
        code: "PA",
        titleKey: KEY_GLOBAL_COUNTRIES + "PANAMA"
    },
    {
        id: 171,
        name: "Papua New Guinea",
        code: "PG",
        titleKey: KEY_GLOBAL_COUNTRIES + "PAPUA_NEW_GUINEA"
    },
    {
        id: 172,
        name: "Paraguay",
        code: "PY",
        titleKey: KEY_GLOBAL_COUNTRIES + "PARAGUAY"
    },
    {
        id: 173,
        name: "Peru",
        code: "PE",
        titleKey: KEY_GLOBAL_COUNTRIES + "PERU"
    },
    {
        id: 174,
        name: "Philippines (the)",
        code: "PH",
        titleKey: KEY_GLOBAL_COUNTRIES + "PHILIPPINES_THE"
    },
    {
        id: 175,
        name: "Pitcairn",
        code: "PN",
        titleKey: KEY_GLOBAL_COUNTRIES + "PITCAIRN"
    },
    {
        id: 176,
        name: "Poland",
        code: "PL",
        titleKey: KEY_GLOBAL_COUNTRIES + "POLAND"
    },
    {
        id: 177,
        name: "Portugal",
        code: "PT",
        titleKey: KEY_GLOBAL_COUNTRIES + "PORTUGAL"
    },
    {
        id: 178,
        name: "Puerto Rico",
        code: "PR",
        titleKey: KEY_GLOBAL_COUNTRIES + "PUERTO_RICO"
    },
    {
        id: 179,
        name: "Qatar",
        code: "QA",
        titleKey: KEY_GLOBAL_COUNTRIES + "QATAR"
    },
    {
        id: 180,
        name: "Republic of North Macedonia",
        code: "MK",
        titleKey: KEY_GLOBAL_COUNTRIES + "REPUBLIC_OF_NORTH_MACEDONIA"
    },
    {
        id: 181,
        name: "Romania",
        code: "RO",
        titleKey: KEY_GLOBAL_COUNTRIES + "ROMANIA"
    },
    {
        id: 182,
        name: "Russian Federation (the)",
        code: "RU",
        titleKey: KEY_GLOBAL_COUNTRIES + "RUSSIAN_FEDERATION_THE"
    },
    {
        id: 183,
        name: "Rwanda",
        code: "RW",
        titleKey: KEY_GLOBAL_COUNTRIES + "RWANDA"
    },
    {
        id: 184,
        name: "Réunion",
        code: "RE",
        titleKey: KEY_GLOBAL_COUNTRIES + "RÉUNION"
    },
    {
        id: 185,
        name: "Saint Barthélemy",
        code: "BL",
        titleKey: KEY_GLOBAL_COUNTRIES + "SAINT_BARTHÉLEMY"
    },
    {
        id: 186,
        name: "Saint Helena, Ascension and Tristan da Cunha",
        code: "SH",
        titleKey: KEY_GLOBAL_COUNTRIES + "SAINT_HELENA_ASCENSION_AND_TRISTAN_DA_CUNHA"
    },
    {
        id: 187,
        name: "Saint Kitts and Nevis",
        code: "KN",
        titleKey: KEY_GLOBAL_COUNTRIES + "SAINT_KITTS_AND_NEVIS"
    },
    {
        id: 188,
        name: "Saint Lucia",
        code: "LC",
        titleKey: KEY_GLOBAL_COUNTRIES + "SAINT_LUCIA"
    },
    {
        id: 189,
        name: "Saint Martin (French part)",
        code: "MF",
        titleKey: KEY_GLOBAL_COUNTRIES + "SAINT_MARTIN_FRENCH_PART"
    },
    {
        id: 190,
        name: "Saint Pierre and Miquelon",
        code: "PM",
        titleKey: KEY_GLOBAL_COUNTRIES + "SAINT_PIERRE_AND_MIQUELON"
    },
    {
        id: 191,
        name: "Saint Vincent and the Grenadines",
        code: "VC",
        titleKey: KEY_GLOBAL_COUNTRIES + "SAINT_VINCENT_AND_THE_GRENADINES"
    },
    {
        id: 192,
        name: "Samoa",
        code: "WS",
        titleKey: KEY_GLOBAL_COUNTRIES + "SAMOA"
    },
    {
        id: 193,
        name: "San Marino",
        code: "SM",
        titleKey: KEY_GLOBAL_COUNTRIES + "SAN_MARINO"
    },
    {
        id: 194,
        name: "Sao Tome and Principe",
        code: "ST",
        titleKey: KEY_GLOBAL_COUNTRIES + "SAO_TOME_AND_PRINCIPE"
    },
    {
        id: 195,
        name: "Saudi Arabia",
        code: "SA",
        titleKey: KEY_GLOBAL_COUNTRIES + "SAUDI_ARABIA"
    },
    {
        id: 196,
        name: "Senegal",
        code: "SN",
        titleKey: KEY_GLOBAL_COUNTRIES + "SENEGAL"
    },
    {
        id: 197,
        name: "Serbia",
        code: "RS",
        titleKey: KEY_GLOBAL_COUNTRIES + "SERBIA"
    },
    {
        id: 198,
        name: "Seychelles",
        code: "SC",
        titleKey: KEY_GLOBAL_COUNTRIES + "SEYCHELLES"
    },
    {
        id: 199,
        name: "Sierra Leone",
        code: "SL",
        titleKey: KEY_GLOBAL_COUNTRIES + "SIERRA_LEONE"
    },
    {
        id: 200,
        name: "Singapore",
        code: "SG",
        titleKey: KEY_GLOBAL_COUNTRIES + "SINGAPORE"
    },
    {
        id: 201,
        name: "Sint Maarten (Dutch part)",
        code: "SX",
        titleKey: KEY_GLOBAL_COUNTRIES + "SINT_MAARTEN_DUTCH_PART"
    },
    {
        id: 202,
        name: "Slovakia",
        code: "SK",
        titleKey: KEY_GLOBAL_COUNTRIES + "SLOVAKIA"
    },
    {
        id: 203,
        name: "Slovenia",
        code: "SI",
        titleKey: KEY_GLOBAL_COUNTRIES + "SLOVENIA"
    },
    {
        id: 204,
        name: "Solomon Islands",
        code: "SB",
        titleKey: KEY_GLOBAL_COUNTRIES + "SOLOMON_ISLANDS"
    },
    {
        id: 205,
        name: "Somalia",
        code: "SO",
        titleKey: KEY_GLOBAL_COUNTRIES + "SOMALIA"
    },
    {
        id: 206,
        name: "South Africa",
        code: "ZA",
        titleKey: KEY_GLOBAL_COUNTRIES + "SOUTH_AFRICA"
    },
    {
        id: 207,
        name: "South Georgia and the South Sandwich Islands",
        code: "GS",
        titleKey: KEY_GLOBAL_COUNTRIES + "SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS"
    },
    {
        id: 208,
        name: "South Sudan",
        code: "SS",
        titleKey: KEY_GLOBAL_COUNTRIES + "SOUTH_SUDAN"
    },
    {
        id: 209,
        name: "Spain",
        code: "ES",
        titleKey: KEY_GLOBAL_COUNTRIES + "SPAIN"
    },
    {
        id: 210,
        name: "Sri Lanka",
        code: "LK",
        titleKey: KEY_GLOBAL_COUNTRIES + "SRI_LANKA"
    },
    {
        id: 211,
        name: "Sudan (the)",
        code: "SD",
        titleKey: KEY_GLOBAL_COUNTRIES + "SUDAN_THE"
    },
    {
        id: 212,
        name: "Suriname",
        code: "SR",
        titleKey: KEY_GLOBAL_COUNTRIES + "SURINAME"
    },
    {
        id: 213,
        name: "Svalbard and Jan Mayen",
        code: "SJ",
        titleKey: KEY_GLOBAL_COUNTRIES + "SVALBARD_AND_JAN_MAYEN"
    },
    {
        id: 214,
        name: "Sweden",
        code: "SE",
        titleKey: KEY_GLOBAL_COUNTRIES + "SWEDEN"
    },
    {
        id: 215,
        name: "Switzerland",
        code: "CH",
        titleKey: KEY_GLOBAL_COUNTRIES + "SWITZERLAND"
    },
    {
        id: 216,
        name: "Syrian Arab Republic",
        code: "SY",
        titleKey: KEY_GLOBAL_COUNTRIES + "SYRIAN_ARAB_REPUBLIC"
    },
    {
        id: 217,
        name: "Taiwan",
        code: "TW",
        titleKey: KEY_GLOBAL_COUNTRIES + "TAIWAN"
    },
    {
        id: 218,
        name: "Tajikistan",
        code: "TJ",
        titleKey: KEY_GLOBAL_COUNTRIES + "TAJIKISTAN"
    },
    {
        id: 219,
        name: "Tanzania, United Republic of",
        code: "TZ",
        titleKey: KEY_GLOBAL_COUNTRIES + "TANZANIA_UNITED_REPUBLIC_OF"
    },
    {
        id: 220,
        name: "Thailand",
        code: "TH",
        titleKey: KEY_GLOBAL_COUNTRIES + "THAILAND"
    },
    {
        id: 221,
        name: "Timor-Leste",
        code: "TL",
        titleKey: KEY_GLOBAL_COUNTRIES + "TIMOR-LESTE"
    },
    {
        id: 222,
        name: "Togo",
        code: "TG",
        titleKey: KEY_GLOBAL_COUNTRIES + "TOGO"
    },
    {
        id: 223,
        name: "Tokelau",
        code: "TK",
        titleKey: KEY_GLOBAL_COUNTRIES + "TOKELAU"
    },
    {
        id: 224,
        name: "Tonga",
        code: "TO",
        titleKey: KEY_GLOBAL_COUNTRIES + "TONGA"
    },
    {
        id: 225,
        name: "Trinidad and Tobago",
        code: "TT",
        titleKey: KEY_GLOBAL_COUNTRIES + "TRINIDAD_AND_TOBAGO"
    },
    {
        id: 226,
        name: "Tunisia",
        code: "TN",
        titleKey: KEY_GLOBAL_COUNTRIES + "TUNISIA"
    },
    {
        id: 227,
        name: "Turkey",
        code: "TR",
        titleKey: KEY_GLOBAL_COUNTRIES + "TURKEY"
    },
    {
        id: 228,
        name: "Turkmenistan",
        code: "TM",
        titleKey: KEY_GLOBAL_COUNTRIES + "TURKMENISTAN"
    },
    {
        id: 229,
        name: "Turks and Caicos Islands (the)",
        code: "TC",
        titleKey: KEY_GLOBAL_COUNTRIES + "TURKS_AND_CAICOS_ISLANDS_THE"
    },
    {
        id: 230,
        name: "Tuvalu",
        code: "TV",
        titleKey: KEY_GLOBAL_COUNTRIES + "TUVALU"
    },
    {
        id: 231,
        name: "Uganda",
        code: "UG",
        titleKey: KEY_GLOBAL_COUNTRIES + "UGANDA"
    },
    {
        id: 232,
        name: "Ukraine",
        code: "UA",
        titleKey: KEY_GLOBAL_COUNTRIES + "UKRAINE"
    },
    {
        id: 233,
        name: "United Arab Emirates (the)",
        code: "AE",
        titleKey: KEY_GLOBAL_COUNTRIES + "UNITED_ARAB_EMIRATES_THE"
    },
    {
        id: 234,
        name: "United Kingdom of Great Britain and Northern Ireland (the)",
        code: "GB",
        titleKey: KEY_GLOBAL_COUNTRIES + "UNITED_KINGDOM_OF_GREAT_BRITAIN_AND_NORTHERN_IRELAND_THE"
    },
    {
        id: 235,
        name: "United States Minor Outlying Islands (the)",
        code: "UM",
        titleKey: KEY_GLOBAL_COUNTRIES + "UNITED_STATES_MINOR_OUTLYING_ISLANDS_THE"
    },
    {
        id: 236,
        name: "United States of America (the)",
        code: "US",
        titleKey: KEY_GLOBAL_COUNTRIES + "UNITED_STATES_OF_AMERICA_THE"
    },
    {
        id: 237,
        name: "Uruguay",
        code: "UY",
        titleKey: KEY_GLOBAL_COUNTRIES + "URUGUAY"
    },
    {
        id: 238,
        name: "Uzbekistan",
        code: "UZ",
        titleKey: KEY_GLOBAL_COUNTRIES + "UZBEKISTAN"
    },
    {
        id: 239,
        name: "Vanuatu",
        code: "VU",
        titleKey: KEY_GLOBAL_COUNTRIES + "VANUATU"
    },
    {
        id: 240,
        name: "Venezuela (Bolivarian Republic of)",
        code: "VE",
        titleKey: KEY_GLOBAL_COUNTRIES + "VENEZUELA_BOLIVARIAN_REPUBLIC_OF"
    },
    {
        id: 241,
        name: "Viet Nam",
        code: "VN",
        titleKey: KEY_GLOBAL_COUNTRIES + "VIET_NAM"
    },
    {
        id: 242,
        name: "Virgin Islands (British)",
        code: "VG",
        titleKey: KEY_GLOBAL_COUNTRIES + "VIRGIN_ISLANDS_BRITISH"
    },
    {
        id: 243,
        name: "Virgin Islands (U.S.)",
        code: "VI",
        titleKey: KEY_GLOBAL_COUNTRIES + "VIRGIN_ISLANDS_US"
    },
    {
        id: 244,
        name: "Wallis and Futuna",
        code: "WF",
        titleKey: KEY_GLOBAL_COUNTRIES + "WALLIS_AND_FUTUNA"
    },
    {
        id: 245,
        name: "Western Sahara",
        code: "EH",
        titleKey: KEY_GLOBAL_COUNTRIES + "WESTERN_SAHARA"
    },
    {
        id: 246,
        name: "Yemen",
        code: "YE",
        titleKey: KEY_GLOBAL_COUNTRIES + "YEMEN"
    },
    {
        id: 247,
        name: "Zambia",
        code: "ZM",
        titleKey: KEY_GLOBAL_COUNTRIES + "ZAMBIA"
    },
    {
        id: 248,
        name: "Zimbabwe",
        code: "ZW",
        titleKey: KEY_GLOBAL_COUNTRIES + "ZIMBABWE"
    },
    {
        id: 249,
        name: "Åland Islands",
        code: "AX",
        titleKey: KEY_GLOBAL_COUNTRIES + "ÅLAND_ISLANDS"
    }
];
