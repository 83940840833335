import { withNamespaces } from "react-i18next";
import React, { memo, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { useHistory } from "react-router-dom";
// styles
import "./style.scss";
// components
import Tabs from "./tabs";

// Contents
import { withRouter, useLocation } from "react-router-dom";
import PageBody from "../../components/page-body";
import PageContent from "../../components/page-content";
import Navigator from "../../components/navigator";
import DataProtectionNewsList from "./tabs-content/latest-news";

const DataProtectionNews = (props) => {
  const history = useHistory();
  const location = useLocation();
  const { t } = props;

  // const [detailPage, setDetailPage] = useState(null);

  const [navigatorOptions, setNavigatorOptions] = useState({
    enable: true,
    backButtonOptions: {
        enable  :   true,
        onClick :   () => {
            const searchParams = new URLSearchParams(location.search);
            searchParams.delete('page');
            // history.push({
            //     search : searchParams.toString()
            // });
            history.push(`/admin/data-protection/incident-register?tab=all`);
        }
    },
    breadCrumbs: [
      {
        title: t("Latest News"),
        link: `/admin/data-protection/latest-news?tab=latest-news`,
      },
    ],
  });

  

  const renderContent = () => {
    const searchParams = new URLSearchParams(location.search);

    let result = null;

    switch (searchParams.get("tab")) {
      case "latest-news":
        result = <DataProtectionNewsList/>;
        break;

      default:
        result = <DataProtectionNewsList/>;
        break;
    }

    return (
      <React.Fragment>
        <Tabs t={t} />

        {result}
      </React.Fragment>
    );
  };

  return (
    <PageContent classNames="data-protection-software-details-page software-details-page">
      <Container fluid>
        {/* page header */}
        <Row>
          {navigatorOptions.enable && (
            <Col lg="12" className="mb-4">
              <Navigator
                backButtonOptions={(props) => {
                  return {
                    ...props,
                    ...navigatorOptions.backButtonOptions,
                  };
                }}
                breadCrumbs={navigatorOptions.breadCrumbs}
              />
            </Col>
          )}
        </Row>

        {/* page body */}
        <PageBody classNames="p-0">
          <div className="details-content-wrapper">{renderContent()}</div>
        </PageBody>
      </Container>
    </PageContent>
  );
};

export default withNamespaces()(
  withRouter(memo(DataProtectionNews))
);
