import { memo, useState } from 'react';

import Select from "react-select";
import { Col, Row, Label, Tooltip} from 'reactstrap';
import { withNamespaces } from 'react-i18next';
import { AvField } from 'availity-reactstrap-validation';

const CustomSelect = (props) => {
    const {
        questionIndex,
        id, 
        title, 
        isMandatory, 
        key,
        elRefIdPrefix,
        options,
        defaultValue,
        helpText,
        onChange
    } = props;

    const [selectedOption, setSelectedOption] = useState((defaultValue && Array.isArray(defaultValue) && defaultValue.length > 0) ? {
        label : props.t(defaultValue[0]?.title),
        value : defaultValue[0]?.value
    } : null);

    const [helpTooltipTextStatus, setHelpTooltipTextStatus] = useState(false);

    return (
        <Row key={key}>
            <Col sm="12">
                <div className="mb-3 position-relative">
                    <Label
                        className="form-label"
                        htmlFor={`${elRefIdPrefix}-question-${id}`}>
                        { questionIndex } {props.t(title)}

                        {
                            helpText && (
                                <span className="ms-2 tooltip-container help-text-icon">
                                    <i
                                        className="fa fa-info-circle"
                                        aria-hidden="true"
                                        id={`${elRefIdPrefix}-question-${id}-help-text`}
                                    />
                                    <Tooltip
                                        style={{
                                            backgroundColor: '#899ba1',
                                            fontWeight: 'bold'
                                        }}
                                        placement="top"
                                        isOpen={ helpTooltipTextStatus }
                                        target={`${elRefIdPrefix}-question-${id}-help-text`}
                                        toggle={() =>
                                            setHelpTooltipTextStatus(!helpTooltipTextStatus)
                                        }>
                                            {
                                                <p>
                                                    {props.t(helpText)}
                                                </p>
                                            }
                                    </Tooltip>
                                </span>
                            )
                        }
                    </Label>
                    <Select
                        className="mt-1 text-capitalize"
                        placeholder={props.t("Select") + "..."}
                        id={`${elRefIdPrefix}-question-${id}`}
                        value={selectedOption}
                        onChange={(newOption) => {
                            setSelectedOption(newOption);
                            onChange && onChange(newOption);
                        }}
                        options={(options || []).map((optionItem) => {
                            return {
                                label : props.t(optionItem.title),
                                value : optionItem.id
                            }
                        })}
                        menuPortalTarget={document.body}
                    />
                    <AvField
                        className="d-none"
                        name={"question[" + id + "]"}
                        placeholder=""
                        type="text"
                        errorMessage={props.t(
                            "This field cannot be blank"
                        )}
                        validate={{
                            required: { value: isMandatory }
                        }}
                        value={selectedOption} />
                </div>
            </Col>
        </Row>
    );
}

export default withNamespaces()(memo(CustomSelect));