import {
	CHANGE_WORKSPACE_EVENT,
	CHANGE_WORKSPACE_ACTION
} from "./actionTypes";


export const changeWorkspace = (workspace) => ({
	type: CHANGE_WORKSPACE_EVENT,
	payload: workspace
});


export const changeWorkspaceAction = (workspace) => ({
	type: CHANGE_WORKSPACE_ACTION,
	payload: workspace
});
