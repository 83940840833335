import { AvForm } from "availity-reactstrap-validation";
import { Component } from "react";
import { withNamespaces } from "react-i18next";
import { TbLoader } from "react-icons/tb";
import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

export class CreateModalOptions extends Component {
  /************************ COMPONENT INITIALIZATION ********************** */
  constructor(props) {
    super(props);
  }
  /***************************** RENDER ****************************** */
  render() {
    const { modalActivated, onModalClosed, t, setIsCreateAIOpen, createByAI, isCreateByAISubmiting } =
      this.props;
    return (
      <>
        <Modal
          size="md"
          scrollable={true}
          isOpen={modalActivated}
          // backdrop="static"
        >
          <ModalBody>
            <AvForm
              className="needs-validation"
              onValidSubmit={this.handleSubmit}
            >
              <Row>
                <Label>{t("Would you like to complete the form using AI suggesions?")}</Label>
              </Row>

              <ModalFooter>
                <Button
                  color="primary"
                  className="waves-effect waves-light"
                  outline
                  type="submit"
                  onClick={() => setIsCreateAIOpen(false)}
                >
                  {t("No")}
                </Button>
                <Button
                  color="primary"
                  className="waves-effect waves-light"
                  type="button"
                  // outline
                  onClick={createByAI}
                >
                  {isCreateByAISubmiting ? <TbLoader /> : t("Yes")}
                </Button>
              </ModalFooter>
            </AvForm>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default withNamespaces()(CreateModalOptions);
