import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { Col, Row } from "reactstrap";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import InformationCustomization from "./components/company-information";
import CompanyFontsAndDisplayCustomization from "./components/fonts-and-display";
import DPOCustomization from "./components/dpo";
import CompanyLogoCustomization from "./components/logo";
import CompanyBackgroundCustomization from "./components/background";
import IntroCustomization from "./components/intro";
import { getCurrentLanguage } from "src/i18n";
import axios from "axios";
import { API_URL_FETCH_COUNTRIES } from "src/common/constants";
import WhistleblowingCustomizationService from "src/apis/WhistleblowingCustomizationService";
import ComplaintCustomizationService from "src/apis/ComplaintCustomizationService";

const Customization = (props) => {
  const { t, token, modules } = props;
  const [locations, setLocations] = useState([]);

  /*********************************** FUNCTIONS ***************************************** */
  // Get Countries
  const getCountries = useQuery({
    queryKey: ["fetch-locations-query"],
    queryFn: async () => {
      const {
        data: { data },
      } = await axios.post(API_URL_FETCH_COUNTRIES, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const result = data?.map((country) => ({
        label: country.name,
        value: country.id,
        baseLabel: country.name
      }));
      return result || [];
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      toast(t("An error occurred while locations list."), {
        type: "error",
      });
    },
  });

  // Get Organization Details
  const fetchOrganizationDetails = useQuery({
    queryKey: ["fetch-organization-deatils-query"],
    queryFn: async () => {
      const service = modules.active === "cm" ? ComplaintCustomizationService.getInstance() : WhistleblowingCustomizationService.getInstance();
      const data = service.fetch();
      return data;
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    enabled: false,
    onError: (error) => {
      toast(t("An error occurred while fetching organization details."), {
        type: "error",
      });
    },
  });

  // Sort Countries By Alphabet
  const sortLocationsByLanguage = (language, locations) => {
    if (locations && Array.isArray(locations)) {
      const collator = new Intl.Collator(language, {
        sensitivity: "base",
      });
      return locations.map((location) => {
        return {
            ...location,
            label: t(location.baseLabel)
        }
      }).sort((a, b) => collator.compare(a.label, b.label));
    } else {
      return [];
    }
  };

  /*************************************** USE EFFECTS ****************************************** */
  useEffect(() => {
    setLocations(sortLocationsByLanguage(getCurrentLanguage(), locations));
  }, [t]);

  useEffect(() => {
    fetchOrganizationDetails.refetch();
  }, []);

  useEffect(() => {
    if (getCountries.data) {
      setLocations(
        sortLocationsByLanguage(getCurrentLanguage(), getCountries.data)
      );
    }
  }, [getCountries.data]);

  /*************************************** VIEW ****************************************** */
  return (
    <>
        {!fetchOrganizationDetails.isFetching &&
        !fetchOrganizationDetails.isLoading ? (
          <Row>
            <Col sm='12'>
                <div className="page-customization">
                {/* ****************************** HEADER *************************** */}
                <div className="header-section">
                    <h6>{t("Customize Your Platform")}</h6>
                </div>
                {/* ******************************** Company Information ********************** */}
                <InformationCustomization
                    fetchOrganizationDetails={fetchOrganizationDetails}
                    locations={locations}
                    getCountries={getCountries}
                />

                {/* ******************************** DPO ********************** */}
                <DPOCustomization
                    fetchOrganizationDetails={fetchOrganizationDetails}
                    locations={locations}
                    getCountries={getCountries}
                />

                {/* ******************************** FONT AND DISPLAY ********************** */}
                <CompanyFontsAndDisplayCustomization
                    fetchOrganizationDetails={fetchOrganizationDetails}
                />

                {/* ******************************** LOGO ********************** */}
                <CompanyLogoCustomization
                    fetchOrganizationDetails={fetchOrganizationDetails}
                />

                {/* ******************************** BACKGROUND ********************** */}
                <CompanyBackgroundCustomization
                    fetchOrganizationDetails={fetchOrganizationDetails}
                />

                {/* ******************************** INTRO ********************** */}
                <IntroCustomization
                    fetchOrganizationDetails={fetchOrganizationDetails}
                />
                </div>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col lg="12">
              <p
                style={{ width: "100%" }}
                className={`dt-field dt-skeleton`}
              ></p>
            </Col>
            <Col lg="12">
              <p
                style={{ width: "100%" }}
                className={`dt-field dt-skeleton`}
              ></p>
            </Col>
            <Col lg="12">
              <p
                style={{ width: "100%" }}
                className={`dt-field dt-skeleton`}
              ></p>
            </Col>
            <Col lg="12">
              <p
                style={{ width: "100%" }}
                className={`dt-field dt-skeleton`}
              ></p>
            </Col>
          </Row>
        )}
    </>
  );
};

const mapStatetoProps = (state) => {
  const { token } = state.Login;
  const organization = state.Organization;
  const App = state.App;
  const modules = state.Modules;
  return { organization, token, App, modules };
};

export default withNamespaces()(
  withRouter(connect(mapStatetoProps)(Customization))
);
