import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import FindingsListItem from "./FindingsListItem";
import { Alert, Button, Col, Row } from "reactstrap";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import ManageKeywordsModal from './manageKeyWords'
import { toast } from "react-toastify";
import FilteringComponent from "./FilteringComponent";
import FindingsService from "src/modules/3rd-party-management/apis/FindingsService";

import { useMutation, useQuery } from "@tanstack/react-query";

import Spinner from 'react-bootstrap/Spinner';

const FindingsList = (props) => {
    const [ filters, setFilters ] = useState({
        pageIndex: 1,
        pageSize: 100
    });

    const { t, supplierId, onItemSelectedToShow } = props;
    
    const [ items, setItems ] = useState([]);
    const [isModalKeyWordsOpen, setIsModalKeyWordsOpen] = useState(false);

    const fetchFindingsQuery = useQuery({
        queryKey: [
            '3rd-party-management-fetch-findings-list',
            supplierId,
            filters
        ],
        queryFn: async () => {
            const service = FindingsService.getInstance();
            return await service.list(supplierId, filters);
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onError: () => {
            toast(t('An error occurred while fetching findings.'), {
                type: 'error',
            });
        },
        onSuccess: (data) => {
            setItems(data?.news || [])
        }
    });

    const handleToggleFlagStatusMutation = useMutation({
        mutationFn: async (selectedItemsIds) => {
            const service = FindingsService.getInstance();

            return await service.bulkToggleFlag({
                news  : selectedItemsIds
            });
        },
        onSuccess: () => {
            toast(t("Flag status of selected items changed successfully."), {
                type: "success",
            });
        },
        onError: () => {
            toast(t("An error occurred while updating."), {
                type: "error",
            });
        }
    });

    const toggleCheckedItemsFlag = () => {
        const checkedItems = (items.filter((item) => !!item.checked)).map((item) => item.id);
        if(checkedItems.length > 0){
            handleToggleFlagStatusMutation.mutate(checkedItems, {
                onSuccess : () => {
                    const newList = [...items].map((item) => {
                        if(checkedItems.includes(item.id)){
                        return {
                            ...item,
                            checked : false,
                            flag  : !item.flag
                        }
                        }
                        return {...item};
                    });

                    setItems(newList);
                }
            });
        }
        else{
            toast(t('Please select at least one of the findings to continue'), {
                type: 'error',
            });
        }
    }

    useEffect(() => {
        fetchFindingsQuery.refetch();
    }, [ filters ]);

    const toggleKeywordsModal = () => {
        setIsModalKeyWordsOpen(!isModalKeyWordsOpen);
    };

    return ( 
        <React.Fragment>  
            <Row className="findings-bulk-actions mb-3">
                <Col sm="12" md="3">
                    <Button onClick={() => {
                        const status = ((items.filter((item) => !!item.checked)).length === items.length) ? false : true;
                        setItems([
                            ...items.map((item) => {
                                return {
                                    ...item,
                                    checked : status
                                }
                            })
                        ])
                        }} color="primary" outline>
                        {
                            ((items.filter((item) => !!item.checked)).length === items.length) ? (
                                t('Deselect All') 
                            ) : (
                                t('Select All') 
                            )
                        }
                    </Button>
                </Col>

                <Col sm="12" md="9" className="d-flex flex-row-reverse justify-content-start align-items-center">
                    <Button 
                        disabled={ handleToggleFlagStatusMutation.isLoading } 
                        onClick={toggleCheckedItemsFlag} color="primary"
                    >
                        {handleToggleFlagStatusMutation.isLoading && (
                            <Spinner animation="border" variant="white" size="sm" style={{marginRight: '4px'}} />
                        )}
                        { t('Flag/Unflag Selected Items') }
                    </Button>

                    <Button 
                        color="primary" 
                        outline 
                        className="me-2" 
                        onClick={toggleKeywordsModal}
                    >
                        { t('Manage Keywords') }
                    </Button>

                    <FilteringComponent 
                        onChange={(newFilters) => {
                            setFilters({
                                ...filters,
                                ...newFilters,
                                pageIndex: 1
                            });
                        }}
                    />
                </Col>
            </Row>

            <div className="findings-list d-flex flex-column">
                {!items.length && (
                    <Alert color="warning" className="text-center">
                        <p className="m-0">{t(`Findings list is empty.`)}</p>
                    </Alert>
                )}

                {
                    items?.map((item) => (
                        <FindingsListItem
                            key={item.id}
                            data={ item }
                            checked={ !!item?.checked }
                            onCheckStatusChanged={(status, item) => {
                                setItems((items) => {
                                    const itemIndex = items.findIndex((i) => i.id === item.id);
                                    const newItems = [...items];

                                    if(itemIndex > -1){
                                        newItems[itemIndex].checked = status;
                                    }

                                    return newItems;
                                });
                            }}
                            onSelectedToShow={(i) => onItemSelectedToShow && onItemSelectedToShow(i)}
                        />
                    ))
                }
            </div>

            {/* Render the ManageKeywordsModal */}
            <ManageKeywordsModal
                modal={isModalKeyWordsOpen}
                setModal={setIsModalKeyWordsOpen}
                toggle={toggleKeywordsModal}
                t={t}
            />

        </React.Fragment>
    );
};

export default withNamespaces()(FindingsList);
