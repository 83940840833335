import { withNamespaces } from "react-i18next";
import { memo, useCallback, useEffect, useState } from "react";
import OverviewHeader from "../../../overview-header";
import { withRouter } from "react-router-dom";
import { Card, CardBody, Row, Col, Label, Button } from "reactstrap";
import Dropzone, { useDropzone } from "react-dropzone";
import {
  AvCheckbox,
  AvCheckboxGroup,
  AvForm,
} from "availity-reactstrap-validation";
import DateInput from "src/components/Fields/DateInput";
import AttachmentCard from "src/modules/data-protection/components/MessageBox/components/AttachmentCard";
import axios from "axios";
import { API_BASE_URL } from "../../../../../constants";
import DataProtectionSoftwareService from "src/modules/data-protection/apis/DataProtectionSoftwareService";
import { useMutation, useQuery } from "@tanstack/react-query";
import debounce from "lodash.debounce";
import { toast } from "react-toastify";

const DPA = ({ t, assetId }) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  // Date
  const [selectedDate, setSelectedDate] = useState("");
  // Result
  const [inputResultValue, setInputResultValue] = useState("");
  const [resultRows, setResultRows] = useState(1);
  // Reason
  const [inputReasonValue, setInputReasonValue] = useState("");
  const [reasonRows, setReasonRows] = useState(1);
  const [dpaDetails, setDpaDetails] = useState(null);
  const [consultationChecked, setConsultationChecked] = useState(false);

  // Attachments
  const handleAcceptedFiles = (files, id) => {
    const headers = {
      headers: {},
    };

    const userToken = localStorage.getItem("LOCAL_STORAGE_KEY_ACCESS_TOKEN");

    if (userToken) {
      headers.headers.Authorization = `Bearer ${userToken}`;
    }

    files.map((file) => {
      const formData = new FormData();

      formData.append("file", file);

      file = {
        ...file,
        id: null,
        status: "uploading",
        name: file.name,
        type: file.type,
        questionId: id,
        uploadedPercent: 0,
        preview: URL.createObjectURL(file),
        formattedSize: file.size,
      };

      setUploadedFiles((uploadedFiles) => {
        return [...uploadedFiles, { ...file }];
      });
      axios
        .post(
          `${API_BASE_URL}/software/${assetId}/add_dpa_attachment`,
          formData,
          {
            ...headers,
            onUploadProgress: (e) => {
              onUploadProgress(e, file, setUploadedFiles);
            },
          }
        )
        .then((response) => {
          setUploadedFiles((uploadedFiles) => {
            const index = uploadedFiles.findIndex(
              (f) => f.preview === file.preview
            );

            if (index > -1) {
              if (response.status === 200) {
                const fileData = response.data.data;

                uploadedFiles[index].status = "upload_succeed";

                uploadedFiles[index].id = fileData.id;
              } else {
                uploadedFiles[index].status = "upload_failed";
              }

              return [...uploadedFiles];
            }

            return [...uploadedFiles];
          });
        })
        .catch((error) => {
          setUploadedFiles((uploadedFiles) => {
            const index = uploadedFiles.findIndex(
              (f) => f.preview === file.preview
            );

            if (index > -1) {
              uploadedFiles[index].status = "upload_failed";

              return [...uploadedFiles];
            }

            return [...uploadedFiles];
          });

          toast(t(error.response.data.message), {
            type: "error",
          });
        });
      return [...uploadedFiles];
    });
  };

  const onDrop = useCallback((acceptedFiles) => {
    // setDragFiles(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
  });

  const onUploadProgress = (e, file, setUploadedFiles) => {
    const { total, loaded, lengthComputable } = e;

    if (lengthComputable) {
      const uploadedPercent = (loaded / total) * 100;

      setUploadedFiles((uploadedFiles) => {
        const index = uploadedFiles.findIndex(
          (f) => f.preview === file.preview
        );

        if (index > -1) {
          uploadedFiles[index].uploadedPercent = uploadedPercent;

          return [...uploadedFiles];
        }

        return [...uploadedFiles];
      });
    }
  };

  const handleClickDeleteFiles = (file) => {
    const url = `${API_BASE_URL}/software/delete_attachment/${file.id}`;

    const headers = {
      headers: {},
    };

    const userToken = localStorage.getItem("LOCAL_STORAGE_KEY_ACCESS_TOKEN");
    if (userToken) {
      headers.headers.Authorization = `Bearer ${userToken}`;
    }

    setUploadedFiles((uploadedFiles) => {
      const index = uploadedFiles.findIndex((f) => f.id === file.id);

      if (index > -1) {
        uploadedFiles[index].status = "deleting";

        return [...uploadedFiles];
      }

      return [...uploadedFiles];
    });

    axios
      .delete(url, headers)
      .then(() => {
        handleFetchDPAQuery.refetch();
        setUploadedFiles([
          ...uploadedFiles.filter((f) => {
            return f.id !== file.id;
          }),
        ]);
        toast("File is removed successfully");
      })
      .catch((error) => {
        setUploadedFiles([
          ...uploadedFiles.filter((f) => {
            return f.id !== file.id;
          }),
        ]);
        toast(t("Error while removing the file"), {
          type: "error",
        });
      });
  };

  // Consultation
  const toggleConsultationFlagMutation = useMutation({
    mutationFn: async (assetId) => {
      const service = DataProtectionSoftwareService.getInstance();
      return await service.toggleConsultation(assetId);
    },
    onSuccess: () => {
      toast(t("Data status successfully changed."), {
        type: "success",
      });
    },
    onError: () => {
      toast(t("An error occurred while changing data."), {
        type: "error",
      });
    },
  });

  // Reason
  const toggleUpdateReasonMutation = useMutation({
    mutationFn: async ({ assetId, reason }) => {
      const service = DataProtectionSoftwareService.getInstance();
      return await service.toggleReason({
        software: Number(assetId),
        reason: reason,
      });
    },
    onSuccess: () => {
      toast(t("Reason is successfully updated."), {
        type: "success",
      });
    },
    onError: () => {
      toast(t("An error occurred while updateing reason."), {
        type: "error",
      });
    },
  });

  const handleReasonKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent default behavior (e.g., form submission)
    //   handlerMessage();
    }
  };

  const updateReasonRows = useCallback(
    debounce((value) => {
      if (value.trim() === "") {
        setReasonRows(1);
        return;
      }
      const lines = value.split("\n").length;
      const newRows = Math.max(lines, 1); // Ensure at least one line
      toggleUpdateReasonMutation.mutate({ assetId: assetId, reason: value });
      setReasonRows(newRows);
    }, 600),
    []
  );

  const handleReasonInputChange = (e) => {
    const { value } = e.target;
    setInputReasonValue(value); // Update input value immediately
    updateReasonRows(value); // Update rows with a debounced call
  };

  // Date
  const updateDateMutation = useMutation({
    mutationFn: async ({ assetId, date }) => {
      const service = DataProtectionSoftwareService.getInstance();
      return await service.updateDate({
        software: Number(assetId),
        date: date,
      });
    },
    onSuccess: () => {
      toast(t("Date is successfully updated."), {
        type: "success",
      });
    },
    onError: () => {
      toast(t("An error occurred while updateing Date."), {
        type: "error",
      });
    },
  });

  // Result
  const toggleUpdateResultMutation = useMutation({
    mutationFn: async ({ assetId, result }) => {
      const service = DataProtectionSoftwareService.getInstance();
      return await service.updateResult({
        software: Number(assetId),
        result: result,
      });
    },
    onSuccess: () => {
      toast(t("Result is successfully updated."), {
        type: "success",
      });
    },
    onError: () => {
      toast(t("An error occurred while updateing result."), {
        type: "error",
      });
    },
  });

  const handleResultKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent default behavior (e.g., form submission)
    //   handlerMessage();
    }
  };

  const updateResultRows = useCallback(
    debounce((value) => {
      if (value.trim() === "") {
        setResultRows(1);
        return;
      }
      const lines = value.split("\n").length;
      const newRows = Math.max(lines, 1); // Ensure at least one line
      toggleUpdateResultMutation.mutate({ assetId: assetId, result: value });
      setResultRows(newRows);
    }, 600),
    []
  );

  const handleResultInputChange = (e) => {
    const { value } = e.target;
    setInputResultValue(value); // Update input value immediately
    updateResultRows(value); // Update rows with a debounced call
  };

  // Details
  const handleFetchDPAQuery = useQuery({
    queryKey: ["data-protection-softwares-dpa-list"],
    queryFn: async () => {
      const service = DataProtectionSoftwareService.getInstance();
      const result = await service.listDPA(assetId);
      return result;
    },
    cacheTime: 0,
    refetchOnWindowFocus: true,
    onError: (error) => {
      if (process.env.NODE_ENV === 'development') console.error(error);

      toast(t("An error occurred while fetching softwares."), {
        type: "error",
      });
    },
  });

  useEffect(() => {
    if (handleFetchDPAQuery.data) {
      setDpaDetails(handleFetchDPAQuery.data);
      const { is_consultation_required, reason, result, date } =
        handleFetchDPAQuery?.data;
      // console.log(handleFetchDPAQuery.data);
      if (date?.timestamp) {
        // Ensure the timestamp is in milliseconds
        const timestamp = Number(date.timestamp) * 1000; // Adjust if your timestamp is already in milliseconds
        const dateObject = new Date(timestamp);

        // Check if the date is valid
        if (!isNaN(dateObject.getTime())) {
            const dateString = dateObject.toISOString().split('T')[0];
            setSelectedDate(dateString);
        } else {
            console.error('Invalid date:', date.timestamp);
        }
      }

      setConsultationChecked(is_consultation_required);
      setInputReasonValue(reason);
      setInputResultValue(result);
    }
  }, [handleFetchDPAQuery.data]);


  return (
    <div className="p-4">
      <OverviewHeader assetId={assetId} />

      <Card
        className="mt-3"
        style={{
          borderRadius: "8px",
          border: "1px solid #BDBDBD",
        }}
      >
        <CardBody>
          <Row>
            <Label>{t("General Information")}</Label>
          </Row>
          <Row>
            <AvForm>
              <AvCheckboxGroup name="consultation">
                <AvCheckbox
                  label={t("No consultation required")}
                  value="consultation"
                  onChange={(e) => {
                    setConsultationChecked(e.target.checked);
                    return toggleConsultationFlagMutation.mutate(assetId);
                  }}
                  checked={consultationChecked}
                />
              </AvCheckboxGroup>

              <Row className="m-2" hidden={!consultationChecked}>
                <Label>{t("Reason") + ":"}</Label>
                <Row className="w-100 mt-1 ms-1 message-input">
                  <Col xs={12} className="p-0  px-3">
                    <textarea
                      type="textarea"
                      className=" message-input "
                      placeholder={`${t("Please add a reason")}...`}
                      value={inputReasonValue}
                      onChange={handleReasonInputChange}
                      onKeyDown={handleReasonKeyDown}
                      rows={reasonRows}
                      maxLength={700}
                    />
                  </Col>
                </Row>
              </Row>
            </AvForm>
          </Row>
          <Row className="mt-2">
            <Col md="5">
              <Label>{t("Date") + ":"}</Label>
              <DateInput
                placeholder={t("Date Input")}
                name={"dateInput"}
                value={selectedDate ? new Date(selectedDate) : null}
                onValueChanged={(e) => {
                  const selecetdDate = new Date(e).toISOString().split("T")[0];
                  setSelectedDate(e);
                  updateDateMutation.mutate({ assetId, date: selecetdDate });
                }}
              />
            </Col>
            <Col md="1"></Col>
            <Col md="6">
              <Label>{t("Communication") + ":"}</Label>
              <Dropzone
                onDrop={(acceptedFiles) => handleAcceptedFiles(acceptedFiles)}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()} className="dropzone tpdd">
                    <input {...getInputProps()} />
                    <Button
                      style={{
                        background: "#fff",
                        border: "none",
                        fontSize: "20px",
                        paddingRight: 0,
                      }}
                      color="#505D69"
                    >
                      <i className="ri-attachment-line me-4"></i>
                    </Button>
                  </div>
                )}
              </Dropzone>

              {uploadedFiles.length > 0 ? (
                <Col xs={12} className="p-3">
                  <div className="d-flex flex-wrap gap-2">
                    {uploadedFiles.map((file, index) => (
                      <AttachmentCard
                        key={index}
                        attachment={file}
                        handleClickDeleteFile={handleClickDeleteFiles}
                        //   downloadUrl={downloadUrl}
                        t={t}
                      />
                    ))}
                  </div>
                </Col>
              ) : null}
            </Col>
            
          </Row>

          <Label>{t("Result") + ":"}</Label>
          <Row className="w-100 mt-1 ms-1 message-input">
            <Col xs={12} className="p-0  px-3">
              <textarea
                type="textarea"
                className=" message-input "
                placeholder={`${t("Enter Message")}...`}
                value={inputResultValue}
                onChange={handleResultInputChange}
                onKeyDown={handleResultKeyDown}
                rows={resultRows}
                maxLength={700}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

export default withNamespaces()(withRouter(memo(DPA)));
