import { withNamespaces } from 'react-i18next';
import { memo, useEffect, useRef, useState } from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { isFeatureEnabledForActiveModule } from 'src/helpers/module_helper';
import { connect } from "react-redux";
import PerfectScrollbar from 'react-perfect-scrollbar';

const LoadingComponent = () => {
    return (
        <div style={{
            backgroundColor: '#D1D2D6'
        }}>
            <div>
                <Nav tabs className="custom-style-1">
                    <NavItem className='active'>
                        <NavLink className='active'>
                            <div className="dt-field dt-skeleton"></div>
                        </NavLink>
                    </NavItem> 
                    <NavItem>
                        <NavLink>
                            <div className="dt-field dt-skeleton"></div>
                        </NavLink>
                    </NavItem>  
                    <NavItem>
                        <NavLink>
                            <div className="dt-field dt-skeleton"></div>
                        </NavLink>
                    </NavItem>  
                    <NavItem>
                        <NavLink>
                            <div className="dt-field dt-skeleton"></div>
                        </NavLink>
                    </NavItem>  
                    <NavItem>
                        <NavLink>
                            <div className="dt-field dt-skeleton"></div>
                        </NavLink>
                    </NavItem>  
                    <NavItem>
                        <NavLink>
                            <div className="dt-field dt-skeleton"></div>
                        </NavLink>
                    </NavItem>  
                </Nav>
            </div>
        </div>
    )
}

const Tabs = memo(({
    t, 
    modules, 
    isLoading, 
    supplierData
}) => {
    const [activeTab, setActiveTab] = useState("");
    const navBarRef = useRef();
    const scrollBarRef = useRef();
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        setActiveTab((new URLSearchParams(location.search)).get('tab'));
    }, [ location.search ]);

    useEffect(() => {
        if(!(new URLSearchParams(location.search)).get('tab')){
            history.push({
                search : '?tab=overview'
            })
        }
    }, []);

    if(modules.featuresLoadingStatus === 'loading' || isLoading){
        return (
            <LoadingComponent />
        )
    }

    if(!supplierData){
        return null;
    }

    const isActiveTab = (currentTab, tab) => currentTab === tab;

    let tabsLinks = [];
    const tabsShouldBeHidden = [
        'scoring'
    ];

    if(supplierData.isOwnCompany){
        tabsLinks = [
            {
                key     :   "overview",
                title   :   "Overview"
            },
            {
                key     :   "basic-information",
                title   :   "Basic Information"
            },
            // {
            //     key     :   "scoring",
            //     title   :   "Scoring"
            // },
            {
                key     :   "products",
                title   :   "Products"
            },
            {
                key     :   "findings",
                title   :   "Findings",
                feature :   "news_monitoring"
            },
            {
                key     :   "reports",
                title   :   "Reports"
            },
            {
                key     :   "bafa-questionnaire",
                title   :   "Report Details"
            },
            {
                key     :   "internal-communication",
                title   :   "Communication"
            },
            {
                key     :   "risk-assessment",
                title   :   "Risk Assessment"
            },
            {
                key     :   "task-manager",
                title   :   "Task Management"
            },
            {
                key     :   "history-log",
                title   :   "History Log"
            }
        ]
    }
    else{
        tabsLinks = [
            {
                key     :   "overview",
                title   :   "Overview"
            },
            {
                key     :   "basic-information",
                title   :   "Basic Information"
            },
            {
                key     :   "scoring",
                title   :   "Scoring"
            },
            {
                key     :   "products",
                title   :   "Products"
            },
            {
                key     :   "findings",
                title   :   "Findings",
                feature :   "news_monitoring"
            },
            {
                key     :   "reports",
                title   :   "Reports"
            },
            {
                key     :   "communication",
                title   :   "External Communication"
            },
            {
                key     :   "internal-communication",
                title   :   "Internal Communication"
            },
            {
                key     :   "risk-assessment",
                title   :   "Risk Assessment"
            },
            {
                key     :   "task-manager",
                title   :   "Task Management"
            },
            {
                key     :   "history-log",
                title   :   "History Log"
            }
        ];
    }

    if(parseInt(supplierData.score || 0) < 10){
        tabsShouldBeHidden.splice(tabsShouldBeHidden.findIndex((tabToHide) => tabToHide === 'scoring'), 1)
    }
    
    const linksToDisplay = [
        ...(modules.featuresLoadingStatus === 'loaded' ? 
            tabsLinks.filter((link) => {
                return (
                    !tabsShouldBeHidden.includes(link.key) && 
                    (!link.feature || isFeatureEnabledForActiveModule(link.feature))
                )
            }) 
        : 
        tabsLinks)
    ]

    const getNavWidth = () => {
        if(navBarRef && navBarRef.current){
            return ((navBarRef.current.querySelector('.nav'))?.scrollWidth || 0) + 'px';
        }
        return '0px';
    }

    return (
        <div style={{
            backgroundColor: '#D1D2D6'
        }}>
            <PerfectScrollbar ref={scrollBarRef}>
                <div ref={navBarRef} style={{
                    width: getNavWidth()
                }}>
                    <Nav tabs className="custom-style-1">
                        {
                            linksToDisplay.map((link) => {
                                return (
                                    <NavItem className={isActiveTab(activeTab, link.key) ? 'active': ''} key={link.key}>
                                        <NavLink
                                            className={isActiveTab(activeTab, link.key) ? 'active': ''}
                                            onClick={() => history.push({
                                                search : '?tab=' + link.key
                                            })}>
                                            {t(link.title)}
                                        </NavLink>
                                    </NavItem>
                                )
                            })
                        }   
                    </Nav>
                </div>
            </PerfectScrollbar>
        </div>
	);
});

const mapStatetoProps = (state) => {
    const { Modules } = state;

    return {
        modules  :   Modules
    };
};

export default withNamespaces()(connect(
    mapStatetoProps,
    {}
)(Tabs));
