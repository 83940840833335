import {useMutation} from '@tanstack/react-query';

const useUploadCSV = ({
  props, 
  Service, 
  onSuccess, 
  onError, 
  onStart, 
  onSettled
}) => {
  const openUploadDialog = (onFilePicked) => {
    const inputElemenet = document.createElement("input");
    inputElemenet.style.display = "none";
    inputElemenet.type = "file";

    inputElemenet.addEventListener("change", () => {
      if (inputElemenet.files) {
        onFilePicked(inputElemenet.files[0]);
      }
    });

    const teardown = () => {
      document.body.removeEventListener("focus", teardown, true);
      setTimeout(() => {
        document.body.removeChild(inputElemenet);
      }, 1000);
    };
    document.body.addEventListener("focus", teardown, true);

    document.body.appendChild(inputElemenet);
    inputElemenet.click();
  };

  const uploadCSV = useMutation({
    mutationKey: ['upload_csv'],
    mutationFn: (file) => {
      const service = Service.getInstance();
      const formData = new FormData();
      formData.append('csv_file', file);
      formData.append('token', props.token);

      formData.append('isPartner', 0);
      formData.append('partnerAnalysts', [(props.user.id).toString()]);
      formData.append('partner', props.organization.id);
      formData.append('hasDashboard', 0);
      formData.append('subscriptionType', 'test');

      if(onStart){
        onStart();
      }

      return service.uploadCSV(formData);
    },
    onSuccess,
    onError,
    onSettled
  });

  return {uploadCSV, openUploadDialog}
};


export default useUploadCSV;