import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Container } from "reactstrap";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import UserUtils from "src/services/utils/UserUtils";

const availableModules = [
    'wb',
    'cm',
    'tp'
];

const Footer = ({
    t,
    user,
    modules
}) => {
    const userUtils = new UserUtils();

    const renderManualLinks = () => {
        if(!modules.active || !availableModules.includes(modules.active)){
            return null;
        }

        let route = userUtils.isAnalystOrAnalystAdmin(user) ? "/admin/" : userUtils.isEmployee(user) ? "/employee/" : "/third-party/";

        return (
            <>
                <Link to={`${route}user_policy`} className="m-3">
                    {t("User policy")}
                </Link>
                <Link to={`${route}data_privacy`}>
                    {t("Data privacy")}
                </Link>
            </>
        )
    }

    return (
        <React.Fragment>
            <footer className="footer">
                <Container fluid>
                    <Row>
                        <Col md={8}>
                            {t( "© 2024 SMART INTEGRITY PLATFORM.  All rights reserved by DISS-CO ®." )}
                            {renderManualLinks()}
                        </Col>
                        <Col md={4} className="text-sm-left text-md-end">
                            {`${t("Version")}: ${process.env.REACT_APP_SIP_VERSION}`}
                        </Col>
                    </Row>
                </Container>
            </footer>
        </React.Fragment>
    );
};

const mapStatetoProps = (state) => {
    const { user } = state.Login;
    return { user, modules: state.Modules };
};

export default connect(mapStatetoProps, {})(withNamespaces()(Footer));
