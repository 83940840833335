export const ORGANIZATION_MODULES_LOADED_SUCCESSFULLY = "ORGANIZATION_MODULES_LOADED_SUCCESSFULLY";

export const ACTIVE_MODULE_FEATURES_LOADED_SUCCESSFULLY = "ACTIVE_MODULE_FEATURES_LOADED_SUCCESSFULLY";

export const ORGANIZATION_DOEST_HAVE_ACTIVE_MODULE_ERROR = "ORGANIZATION_DOEST_HAVE_ACTIVE_MODULE_ERROR";

export const SET_ORGANIZATION_ACTIVE_MODULE = "SET_ORGANIZATION_ACTIVE_MODULE";

export const CHANGE_ORGANIZATION_ACTIVE_MODULE_ON_REDUX = "CHANGE_ORGANIZATION_ACTIVE_MODULE_ON_REDUX";

export const SAVE_ORGANIZATION_ACTIVE_MODULE_ON_REMOTE = "SAVE_ORGANIZATION_ACTIVE_MODULE_ON_REMOTE";

export const RELOAD_ORGANIZATION_CURRENT_MODULE_SETTINGS = "RELOAD_ORGANIZATION_CURRENT_MODULE_SETTINGS";

export const SET_MODULE_LOADING_STATUS = "SET_MODULE_LOADING_STATUS";

export const CHANGE_ACTIVE_MODULE_FEATURES_LOADING_STATUS = "CHANGE_ACTIVE_MODULE_FEATURES_LOADING_STATUS";