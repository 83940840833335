import { withNamespaces } from "react-i18next";
import { Fragment, memo, useEffect, useState } from "react";
import { Col, Container, Row, Input } from "reactstrap";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import DataProtectionSoftwareService from "../../../../apis/DataProtectionSoftwareService";
// styles
import "./style.scss";

const DataProtectionHardwareOwners = (props) => {
  const { softwareId } = useParams();
  const { t } = props;

  return (
    <div className="p-4">
      {t("The owners list of the software will be displayed here.")}
    </div>
  );
};

export default withNamespaces()(memo(DataProtectionHardwareOwners));
