import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import { Card, CardBody, CardTitle } from "reactstrap";
import Knob from "./Knob";

class KnobChartCasesAssignedToMe extends Component {
	constructor(props) {
		super(props);

		this.state = {
			amount: 0,
			percent: 0,
		};
		// 	series: [],
		// 	options: {
		// 		chart: {
		// 			height: 390,
		// 			type: "radialBar",
		// 		},
		// 		plotOptions: {
		// 			radialBar: {
		// 				offsetY: 0,
		// 				startAngle: 0,
		// 				endAngle: 270,
		// 				hollow: {
		// 					margin: 5,
		// 					size: "30%",
		// 					background: "transparent",
		// 					image: undefined,
		// 				},
		// 				dataLabels: {
		// 					name: {
		// 						show: false,
		// 					},
		// 					value: {
		// 						show: false,
		// 					},
		// 				},
		// 			},
		// 		},
		// 		colors: ["#1ab7ea", "#0084ff", "#39539E", "#0077B5"],
		// 		labels: [],
		// 		legend: {
		// 			show: true,
		// 			floating: true,
		// 			fontSize: "16px",
		// 			position: "right",
		// 			offsetX: 160,
		// 			offsetY: 15,
		// 			labels: {
		// 				useSeriesColors: true,
		// 			},
		// 			markers: {
		// 				size: 0,
		// 			},
		// 			formatter: function (seriesName, opts) {
		// 				return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex];
		// 			},
		// 			itemMargin: {
		// 				vertical: 3,
		// 			},
		// 		},
		// 		responsive: [
		// 			{
		// 				breakpoint: 480,
		// 				options: {
		// 					legend: {
		// 						show: false,
		// 					},
		// 				},
		// 			},
		// 		],
		// 	},
		// };
	}

	render() {
		return (
			<>
				<Card>
					<CardBody>
						<div className="mb-5">
							<CardTitle>{this.props.t("Cases Assigned to Me")}</CardTitle>
						</div>
						<div className="text-center" style={{ marginBottom: 84 }}>
							<Knob value={this.state.percent} width={254} height={255} fgColor="#5664d2" lineCap="butt" readOnly={true} onChange={(e) => {}} thickness={0.2} />
						</div>
					</CardBody>
				</Card>
			</>
		);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.data !== this.props.data) {
			const data = this.props.data;

			this.setState({
				amount: data && data.amount && parseInt(data.amount),
				percent: data && data.percent && parseInt(data.percent),
			});
		}
	}
}

export default withNamespaces()(KnobChartCasesAssignedToMe);
