import {useMutation} from '@tanstack/react-query';

const useUploadCSV = (Service, onSuccess, onError) => {
  const openUploadDialog = (onFilePicked) => {
    const inputElemenet = document.createElement("input");
    inputElemenet.style.display = "none";
    inputElemenet.type = "file";

    inputElemenet.addEventListener("change", () => {
      if (inputElemenet.files) {
        onFilePicked(inputElemenet.files[0]);
      }
    });

    const teardown = () => {
      document.body.removeEventListener("focus", teardown, true);
      setTimeout(() => {
        document.body.removeChild(inputElemenet);
      }, 1000);
    };
    document.body.addEventListener("focus", teardown, true);

    document.body.appendChild(inputElemenet);
    inputElemenet.click();
  };

  const uploadCSV = useMutation({
    mutationKey: ['upload_csv'],
    mutationFn: (file) => {
      const service = Service.getInstance()
      const formData = new FormData()
      formData.append('csv_file', file)
      return service.uploadCSV(formData);
    },
    onSuccess,
    onError,
  });

  return {uploadCSV, openUploadDialog}
};


export default useUploadCSV;