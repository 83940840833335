import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { Label } from "reactstrap";
import UserUtils from "src/services/utils/UserUtils";
import ReportIncidenService from "../../../../components/api/ReportIncidenService";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import {  PRIORITY_UPDATED_SUCCESSFULLY,
  PRIORITY_NOT_UPDATED_SUCCESSFULLY, } from "src/common/constants"
import {
    HiChevronDoubleUp,
    HiChevronDoubleDown,
    HiMenuAlt4,
  } from "react-icons/hi";
  import Select from "react-select";


const Index = (props) => {
  const { t, report, caseId, currentUser, reportLoading } = props;
  const priorities = [
    {
      value: "LOW",
      label: (
        <span>
          {<HiChevronDoubleDown className="text-info mb-1" />}
          {" " + t("Low")}
        </span>
      ),
    },
    {
      value: "MEDIUM",
      label: (
        <span>
          {<HiMenuAlt4 className="text-success mb-1" />}
          {" " + t("Medium")}
        </span>
      ),
    },
    {
      value: "HIGH",
      label: (
        <span>
          {<HiChevronDoubleUp className="text-danger mb-1" />}
          {" " + t("High")}
        </span>
      ),
    },
  ]
 
  const [selectedPriority, setSelectedPriority] = useState(priorities[0]);

  const userUtils = new UserUtils();
  const isAnalystOrAnalystAdmin = () => {
    return userUtils.isAnalystOrAnalystAdmin(currentUser);
  };
  const handleUpdatePriorityMutation = useMutation({
    mutationFn: async (payload) => {
      const service = ReportIncidenService.getInstance();

      return await service.UpdatePriority(payload);
    },
  });



  const handleUpdatePriorityChange = (item) => {
    handleUpdatePriorityMutation.mutate(
      {
        caseId: caseId,
        priority: item.value,
      },
      {
        onSuccess: (response) => {
          if(response === true){
          toast(t(PRIORITY_UPDATED_SUCCESSFULLY), {
            type: "success",
          });
          props.reportRefetch();
          return;}
          toast(t(PRIORITY_NOT_UPDATED_SUCCESSFULLY), {
            type: "error",
          });
        },
      }
    );
  };


  useEffect(() => {
    if (report && report?.priority) {
      const currentPriority = priorities.find(
        (priority) => priority.value === report.priority
      );
      setSelectedPriority(currentPriority);
    }
  }, [report]);

  return (
    <div style={{ maxWidth: "396px" }}>
      {/* Add New Related Asset */}
      <Label>{t("Priority")}</Label>
      {!reportLoading ? (
       <Select
       value={selectedPriority}
       onChange={handleUpdatePriorityChange}
       options={priorities}
       isDisabled={
        report?.is_read_only_analyst ||
        report?.deleted_by_name ||
        report?.is_deleted_content
      }
     />
      ) : (
        // skeleton
        <>
          <p
            className={`dt-field dt-skeleton ${
              isAnalystOrAnalystAdmin ? "dt-select-list" : ""
            }`}
          ></p>
          <p className="dt-field dt-skeleton dt-update-date"></p>
        </>
      )}
    </div>
  );
};

const mapStatetoProps = (state) => {
  const { token, currentUser } = state.Login;
  return {
    currentUser,
    token,
  };
};

export default withNamespaces()(connect(mapStatetoProps)(Index));
