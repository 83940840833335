import React, { Component } from "react";
import "./style.css";

export default class KScrollbar extends Component {
  scrollToBottom = () => {
    if (typeof this.messagesEnd !== "undefined" && this.props.scrollToBottom) {
      this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    }
  };

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  render() {
    const scrollbarStyl = {
      overflowY: "auto",
      ...this.props.style,
    };

    return (
      <div style={scrollbarStyl} className="kscrollbar">
        {this.props.children}
        <div
          style={{ float: "left", clear: "both" }}
          ref={(el) => {
            this.messagesEnd = el;
          }}
        ></div>
      </div>
    );
  }
}

// export default function KScrollbar(props) {
// 	const [scrollEl, setScrollEl] = useState();
// 	const {style, scrollToBottom} = props;
//
// 	useEffect(() => {
// 		if (scrollEl) {
// 			if (scrollToBottom) {
// 				scrollEl.scrollTop = 10000;
// 			}
// 		}
// 	}, [scrollEl]);
//
// 	return (
// 		<PerfectScrollbar
// 			style={style}
// 			// containerRef={(ref) => {
// 			// 	setScrollEl(ref);
// 			// }}
// 		>
// 			{props.children}
// 		</PerfectScrollbar>
// 	);
// }
