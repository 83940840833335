import { useMutation } from "@tanstack/react-query";
import { memo, useState } from "react";
import { withNamespaces } from "react-i18next";
import { TbLoader } from "react-icons/tb";
import { toast } from "react-toastify";
import {
  Button,
  Form,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import DataProtectionTomService from "src/modules/data-protection/apis/DataProtectionTomService";

const DeleteConfirmationModal = (props) => {
  const { modal, setModal, t, id, handleFetchDetails } = props;
  const service = DataProtectionTomService.getInstance();
  const toggle = () => setModal(!modal);

  const [isProcessing, setIsProcessing] = useState(false);

  /************************ Delete Subcontractor *************************** */
  const deleteSubcontractorMutation = useMutation({
    mutationFn: async () => {
      return await service.deleteSubcontractor(id);
    },
    onSuccess: () => {
      setIsProcessing(false);
      toast(t("Subcontractor deleted successfully."), {
        type: "success",
      });
      handleFetchDetails.refetch();
      toggle();
    },
    onError: (error) => {
      setIsProcessing(false);
      toast(t("An error occurred while deleting the subcontractor."), {
        type: "error",
      });
    },
  });

  const deleteSubcontractor = () => {
    setIsProcessing(true);
    deleteSubcontractorMutation.mutate();
  };

  /***************************** RENDER ****************************** */

  return (
    <>
      <Modal size="lg" scrollable={true} isOpen={modal} backdrop="static">
        <ModalHeader toggle={toggle}>{t("Delete subcontractor?")}</ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Label>{t("Are you sure?")}</Label>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            className="waves-effect waves-light"
            type="submit"
            onClick={deleteSubcontractor}
          >
            {isProcessing ? <TbLoader /> : t("Delete")}
          </Button>
          <Button
            color="secondary"
            className="waves-effect waves-light"
            type="button"
            onClick={toggle}
          >
            {t("Cancel")}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default withNamespaces()(memo(DeleteConfirmationModal));
