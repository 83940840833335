import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import React, { memo, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import Select from "react-select";
import OverviewHeader from "../components/overview-header";
import {
  Container,
  Col,
  Row,
  Card,
  CardBody,
  Label,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardTitle,
  CardHeader,
  Button,
} from "reactstrap";
import {
  AvFeedback,
  AvField,
  AvForm,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation";
import { useMutation, useQuery } from "@tanstack/react-query";
import CommonService from "src/modules/3rd-party-management/apis/CommonService";
import LocalizedPhoneInput from "src/modules/3rd-party-management/components/LocalizedPhoneInput";
import SupplierContactPersonService from "src/modules/3rd-party-management/apis/SupplierContactPersonService";
import { toast } from "react-toastify";

const ReplaceContactPerson = ({ t, organization }) => {
  const [responsiblePersonTitleDrop, setResponsiblePersonTitleDrop] =
    useState(false);
  const [responsiblePerson, setResponsiblePerson] = useState({});
  const [creationFormInProcess, setCreationFormInProcess] = useState(false);
  const [emailIsValid, setEmailIsValid] = useState(true);
  const [displayModal, setDisplayModal] = useState(false);
  const [people, setPeople] = useState([]);
  const avFormRef = useRef();
  const languages = (organization?.languages || []).map((lng) => {
    return {
      value: lng?.language?.id,
      label: t(lng.name),
      code: lng.language_code,
    };
  });

//   const handleFetchContact = useQuery({
//     queryKey: ["3rd-party-management-fetch-supplier-contact-people"],
//     queryFn: async () => {
//       const service = SupplierContactPersonService.getInstance();

//       return await service.fetchContact({});
//     },
//     cacheTime: 0,
//     refetchOnWindowFocus: false,
//     onError: (error) => {
//       toast(t("An error occurred while fetching contact people list."), {
//         type: "error",
//       });
//     },
//   });

  const {
    mutate: checkUserExistenceMutation,
    isLoading: checkUserExistenceMutationInProcess,
  } = useMutation({
    mutationFn: async (payload) => {
      const service = CommonService.getInstance();

      return await service.checkUserExistence(payload);
    },
    onError: () => {
      setEmailIsValid(false);
    },
    onSuccess: () => {
      setEmailIsValid(true);
    },
  });

  const handleCreatePersonMutation = useMutation({
    mutationFn: async (payload) => {
      const service = SupplierContactPersonService.getInstance();

      setCreationFormInProcess(true);

      return await service.replace(payload);
    },
    onSuccess: () => {
      setDisplayModal(false);
    //   handleFetchContact.refetch();
      toast(t("New contact person created successfully."), {
        type: "success",
      });
    },
    onError: () => {
      toast(t("An error occurred while creating contact person."), {
        type: "error",
      });
    },
    onSettled: () => {
      setCreationFormInProcess(false);
    },
  });

  const handleCreationModalSubmit = (e, values) => {
    if (!emailIsValid || checkUserExistenceMutationInProcess) return;
    handleCreatePersonMutation.mutate({ ...values["responsible_person"] });
  };

//   useEffect(() => {
//     setPeople(handleFetchContact?.data?.contactPersons || []);
//   }, [handleFetchContact.data]);

  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col sm="12" className="mb-4">
            <OverviewHeader className="tp-view" />
          </Col>

          <Col sm="12">
            <Card>
              <CardHeader>
                <CardTitle>{t("Contact Person")}</CardTitle>
              </CardHeader>
              <CardBody>
                <AvForm
                  className="needs-validation m-2"
                  onValidSubmit={handleCreationModalSubmit}
                  ref={avFormRef}
                >
                  <Row className="mb-3">
                    <Col md="4" sm="12">
                      <Label for="responsible-person-firstname">
                        {t("First Name")}
                      </Label>

                      <div className="d-flex flex-wrap position-relative">
                        <ButtonDropdown
                          className="position-absolute"
                          isOpen={responsiblePersonTitleDrop}
                          toggle={() => {
                            setResponsiblePersonTitleDrop(
                              !responsiblePersonTitleDrop
                            );
                          }}
                          style={{
                            left: "0px",
                            top: "0px",
                            width: "74px",
                            height: "33px",
                          }}
                        >
                          <DropdownToggle
                            outline
                            split
                            style={{
                              border: "none",
                              borderRight: "1px solid rgb(206, 212, 218)",
                            }}
                          >
                            <span style={{ marginRight: "8px" }}>
                              {t(responsiblePerson.title || "Mr.")}
                            </span>
                          </DropdownToggle>

                          <DropdownMenu>
                            <DropdownItem
                              onClick={() => {
                                setResponsiblePerson({
                                  ...responsiblePerson,
                                  title: "Mr.",
                                });
                              }}
                            >
                              {t("Mr.")}
                            </DropdownItem>

                            <DropdownItem
                              onClick={() => {
                                setResponsiblePerson({
                                  ...responsiblePerson,
                                  title: "Mrs.",
                                });
                              }}
                            >
                              {t("Mrs.")}
                            </DropdownItem>

                            <DropdownItem
                              onClick={() => {
                                setResponsiblePerson({
                                  ...responsiblePerson,
                                  title: "Mx.",
                                });
                              }}
                            >
                              {t("Mx.")}
                            </DropdownItem>
                          </DropdownMenu>
                        </ButtonDropdown>

                        <AvInput
                          id={`responsible-person-title`}
                          name={`responsible_person[title]`}
                          type="hidden"
                          value={responsiblePerson.title || "Mr."}
                        />

                        <AvField
                          id={`responsible-person-firstname`}
                          name={`responsible_person[name]`}
                          type="text"
                          errorMessage={t("This field cannot be blank")}
                          className="form-control"
                          validate={{
                            required: { value: true },
                          }}
                          style={{
                            paddingLeft: "85px",
                          }}
                          groupAttrs={{
                            className: "w-100",
                          }}
                        />
                      </div>
                    </Col>

                    <Col md="4" sm="12">
                      <Label for="responsible-person-lastname">
                        {t("Last Name")}
                      </Label>
                      <AvField
                        name={`responsible_person[lastName]`}
                        type="text"
                        errorMessage={t("This field cannot be blank")}
                        className="form-control"
                        validate={{
                          required: { value: true },
                        }}
                        id="responsible-person-lastname"
                      />
                    </Col>

                    <Col md="4" sm="12">
                      <Label for="responsible-person-position">
                        {t("Position")}
                      </Label>
                      <AvField
                        name={`responsible_person[position]`}
                        type="text"
                        errorMessage={t("This field cannot be blank")}
                        className="form-control"
                        validate={{
                          required: { value: true },
                        }}
                        id="responsible-person-position"
                      />
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col md="4" sm="12">
                      <Label for="responsible-person-language">
                        {t("Language")}
                      </Label>
                      <Select
                        placeholder={t("Select") + "..."}
                        classNamePrefix="select2-selection"
                        id="responsible-person-language"
                        options={languages}
                        menuPortalTarget={document.body}
                        onChange={(e) => {
                          setResponsiblePerson({
                            ...responsiblePerson,
                            language: e,
                          });
                        }}
                        value={responsiblePerson?.language}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                      <AvField
                        name={`responsible_person[language]`}
                        type="hidden"
                        errorMessage={t("This field cannot be blank")}
                        validate={{
                          required: { value: true },
                        }}
                        value={responsiblePerson?.language?.value}
                      />
                    </Col>

                    <Col md="4" sm="12">
                      <Label for="responsible-person-email">{t("Email")}</Label>
                      <AvGroup>
                        <AvField
                          name={`responsible_person[email]`}
                          type="email"
                          className="form-control"
                          validate={{
                            required: {
                              value: true,
                              errorMessage: t("This field cannot be blank"),
                            },
                            email: { errorMessage: t("Your email is invalid") },
                          }}
                          onBlur={(e) => {
                            if (!e.target.value) {
                              return setEmailIsValid(true);
                            }

                            checkUserExistenceMutation({
                              email: e.target.value,
                            });
                          }}
                          disabled={checkUserExistenceMutationInProcess}
                          id={`responsible-person-email`}
                        />
                        {!checkUserExistenceMutationInProcess &&
                          !emailIsValid && (
                            <AvFeedback valid={false} className="d-block">
                              {t("Email already exists")}
                            </AvFeedback>
                          )}
                        {checkUserExistenceMutationInProcess && (
                          <AvFeedback className="d-block text-info">
                            <Spinner
                              className="me-1"
                              animation="border"
                              variant="info"
                              size="sm"
                            />
                            {t("Validating email address.please wait...")}
                          </AvFeedback>
                        )}
                      </AvGroup>
                    </Col>

                    <Col md="4" sm="12">
                      <Label for="responsible-person-phone">
                        {t("Phone Number")}
                      </Label>

                      <LocalizedPhoneInput
                        inputClass="w-100"
                        country={"us"}
                        inputStyle={{
                          height: "38px",
                        }}
                        disableInitialCountryGuess={false}
                        disableCountryGuess={false}
                        onChange={(e) => {
                          const input =
                            avFormRef.current?._inputs[
                              "responsible_person[phoneNumber]"
                            ];
                          if (input) {
                            input.value = e;
                            input.validate();
                          }
                        }}
                      />

                      <AvField
                        name={`responsible_person[phoneNumber]`}
                        type="hidden"
                        errorMessage={t("This field cannot be blank")}
                        validate={{
                          required: { value: true },
                        }}
                      />
                    </Col>
                  </Row>

                  <Button
                    disabled={creationFormInProcess}
                    color="primary"
                    type="submit"
                    size="md"
                  >
                    {creationFormInProcess ? (
                      <Spinner
                        className="me-2"
                        animation="border"
                        variant="white"
                        size="sm"
                      />
                    ) : (
                      <i className="ri-add-line align-middle me-1"></i>
                    )}
                    {t("Add")}
                  </Button>
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStatetoProps = (state) => {
  const { token } = state.Login;
  const { Organization } = state;
  return {
    user: state.Login.user,
    token,
    organization: Organization,
  };
};

export default withNamespaces()(
  withRouter(connect(mapStatetoProps, {})(memo(ReplaceContactPerson)))
);
