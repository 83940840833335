import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";

class Apaexlinecolumn extends Component {
	constructor(props) {
		super(props);

		this.state = {
			series: [],
			options: {},
		};
	}

	render() {
		return (
			<React.Fragment>
				<ReactApexChart options={this.state.options} series={this.state.series} type="bar" height={350} />
			</React.Fragment>
		);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.data !== this.props.data) {
			const data = this.props.data;

			console.log(data);

			// const seriesCM = [];
			// const seriesWB = [];
			// const categories = [];

			// for (const item in data) {
			// 	let newData = [];

			// 	for (const i in data[item]) {
			// 		if (item === "wb") {
			//             seriesWB.push(data[item][i].cnt);
			//         } else if (item === "cm") {
			//             seriesCM.push(data[item][i].cnt);
			//         }

			// 	}
			// }

			// this.setState({
			// 	series: [
			// 		{ name: "CM", data: seriesCM },
			// 		{ name: "WB", data: seriesWB },
			// 	],
			// 	options: {
			// 		xaxis: { categories: ["01 Jan", "02 Jan", "03 Jan", "04 Jan", "05 Jan", "06 Jan", "07 Jan", "08 Jan", "09 Jan", "10 Jan", "11 Jan", "12 Jan"] },
			// 	},
			// });

			const series = [];
			const categories = [];

			for (const item in data) {
				const newData = [];

				for (const i in data[item]) {
                    newData.push(data[item][i].cnt)
					categories.push(parseInt(data[item][i].date));
				}

				series.push({
					name: item,
					data: newData,
				});
			}

			this.setState({
				series,
				options: {
					xaxis: { categories },
				},
			});
		}
	}
}

export default Apaexlinecolumn;
