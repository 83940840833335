import { useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Row, Col, Button, Container } from 'reactstrap';
import { getCurrentLanguage } from 'src/i18n';
import homeBackgroundImage from "src/modules/3rd-party-management/assets/images/3rd-party-home-Background.png";
import ExistingQuestionnaireSVG from "src/modules/3rd-party-management/assets/images/ExistingQuestionnaire.svg";

import {
    ROUTE_PATH_THIRDPARTY_FILL_QUESTIONNAIRE
} from "src/modules/3rd-party-management/constants";

const Home = ({
    t, 
    Organization,
    modules
}) => {
    const [translation, setTranslation] = useState({});
    const [backgroundImage, setBackgroundImage] = useState(homeBackgroundImage);

    useEffect(() => {
        if (Organization.translations && Organization.translations.length > 0) {
            const selectedLanguage = getCurrentLanguage();

            setTranslation(Organization.translations.find(item => item.lang === selectedLanguage && item.module === 'tp') || {});
        }
    }, [Organization.translations, t]);

    useEffect(() => {   
        if(Organization.landingPicture){
            setBackgroundImage(Organization.landingPicture)
        }
    }, [ Organization.landingPicture ])

    return (
        <>
            <div className="third-party-home-page">
                <div className='background-image-container'>
                    <img src={backgroundImage} alt='background' />
                </div>

                <div className='actions'>
                    <div className='action-button-container'>
                        <img src={ExistingQuestionnaireSVG} alt='' />
                        <Link to={ROUTE_PATH_THIRDPARTY_FILL_QUESTIONNAIRE}>
                            <Button disabled={modules?.isLoading} color="btn btn-primary">
                                {t("Start")}
                            </Button>
                        </Link>
                    </div>
                </div>

                <div className='translation-content mt-3 mb-5'>
                    <Container>
                        {modules?.isLoading ? (
                            <Row>
                                <Col sm='12' className='mb-4'>
                                    <p style={{width: '30%'}} className={`dt-field dt-skeleton`}></p>
                                    <p style={{width: '100%'}} className={`dt-field dt-skeleton`}></p>
                                    <p style={{width: '100%'}} className={`dt-field dt-skeleton`}></p>
                                    <p style={{width: '50%'}} className={`dt-field dt-skeleton`}></p>
                                </Col>
                                <Col sm='12'>
                                    <p style={{width: '30%'}} className={`dt-field dt-skeleton`}></p>
                                    <p style={{width: '100%'}} className={`dt-field dt-skeleton`}></p>
                                    <p style={{width: '100%'}} className={`dt-field dt-skeleton`}></p>
                                    <p style={{width: '50%'}} className={`dt-field dt-skeleton`}></p>
                                </Col>
                            </Row>
                        ) : (
                            <Row>
                                <Col sm='12'>
                                    <div className='introText' dangerouslySetInnerHTML={{ __html: translation?.intro }} />
                                </Col>
                            </Row>
                        )}
                    </Container>
                </div>
            </div>
        </>
    );
};

const mapStatetoProps = (state) => {
	const { App, Organization, Modules : modules } = state;

	return {
		App,
		Organization,
		token: state.Login.token,
        modules
	};
};

export default withNamespaces()(withRouter(connect(mapStatetoProps, {
})(Home)));