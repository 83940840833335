import React from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { withNamespaces } from "react-i18next";

const Footer = ({ backgroundColor, t }) => {
    const styles = StyleSheet.create({
        footer: {
            backgroundColor,
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: '100%',
            height: 27,
            paddingLeft: 58,
            display: 'flex',
            justifyContent: 'center'
        },
        pageNumber : {
            fontSize: 9,
            color: '#000'
        }
    });

    return (
        <View style={styles.footer} fixed>
            <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => `${t('Page')} ${pageNumber}/${totalPages}`} />
        </View>
    );
};

export default withNamespaces()(Footer);