import React,{Component} from 'react';
import StringUtils from '../../services/utils/StringUtils';

class HTMLContent extends Component {

    constructor(props){
        super(props);
        const { content } = props;
        const stringUtils = new StringUtils();
        const contentHTML = stringUtils.sanitizeHTML(content);
        this.state = {
            contentHTML
        }
    }

    render(){
        return (
            <div className="comment-text" dangerouslySetInnerHTML={{__html: this.state.contentHTML}}/>
        )
    }
}

export default HTMLContent;
