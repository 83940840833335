import axios from 'axios';
import React from 'react';
import { withNamespaces } from 'react-i18next';
import { HiChevronDoubleDown } from 'react-icons/hi';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import { Col, Container, Row } from 'reactstrap';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import KanbanBoard from './components/KanbanBoard';
import { API_URL_GET_KANBAN_BOARD, CM_API_URL_GET_KANBAN_BOARD } from '../../../common/constants';
import { isFeatureEnabledForActiveModule } from 'src/helpers/module_helper';
import moment from 'moment';
import ReactDatePicker from 'react-datepicker';
import './kanban-style.scss';

class IndexKanban extends React.Component {

	constructor(props) {
		document.title = 'Kanban Board | SIP';
		super(props);
		this.state = {
			headers: {
				headers: {
					Authorization: `Bearer ${this.props.token}`,
				},
			},
			breadcrumbItems: [
				{ title: 'SIP', link: '/' },
				{ title: this.props.t('Kanban Board'), link: '#' },
			],

			loading: true,
			managerOptions: [],
			ownerOptions: [],
			columns: [],
			totalCount: 0,
			filter: {
				fromDate: '',
				toDate: '',
				owner: '',
				manager: '',
				sortBy: '',
			},
			sortBy: '',
			sortOptions: [],
			periorityOptions: [
				{
					value: 'priority',
					label: (
						<>
							<HiChevronDoubleDown className='text-info' />
							<span style={{ marginLeft: 9 }}>High</span>
						</>
					),
				},
				{
					value: 'priority',
					label: (
						<>
							<HiChevronDoubleDown className='text-info' />
							<span style={{ marginLeft: 9 }}>Medium</span>
						</>
					),
				},
				{
					value: 'priority',
					label: (
						<>
							<HiChevronDoubleDown className='text-info' />
							<span style={{ marginLeft: 9 }}>Low</span>
						</>
					),
				},
			],
		};
	}

	getSortOptions = () => {
		this.setState({
			sortOptions: [
				{
					value: 'priority',
					label: (
						<>
							<span>{this.props.t('Priority')}</span>
						</>
					),
				},
				{
					value: 'deadline',
					label: (
						<>
							<span>{this.props.t('Deadline')}</span>
						</>
					),
				},
			],
		});
	};

	handleFilterKanbanBoards(fromDate, toDate, owner, manager, sortBy) {
		this.setState(
			{
				filter: {
					fromDate,
					toDate,
					owner,
					manager,
					sortBy,
				},
			},
			() =>
				this.fetchTasks({
					fromDate: this.state.filter.fromDate ? moment(this.state.filter.fromDate).format('DD/MM/yyy') : '',
					toDate: this.state.filter.toDate ? moment(this.state.filter.toDate).format('DD/MM/yyy') : '',
					owner: this.state.filter.owner,
					manager: this.state.filter.manager,
					sortBy: this.state.filter.sortBy,
				}),
		);
	}

	async fetchTasks(filter) {
		this.setState({ loading: true });

		const content = {};
		const columns = [];
		const data = {};

		if (filter) {
			if (filter.fromDate) data['fromDate'] = filter.fromDate.split('/').reverse().join('-');
			if (filter.toDate) data['toDate'] = filter.toDate.split('/').reverse().join('-');
			if (filter.owner) data['owner'] = filter.owner.value;
			if (filter.manager) data['manager'] = filter.manager.value;
			if (filter.sortBy) data['sortBy'] = filter.sortBy.value;
		}

		try {
			const result = await axios.get(`${this.props.modules.active === 'wb' ? API_URL_GET_KANBAN_BOARD : CM_API_URL_GET_KANBAN_BOARD}`, {
				params: data,
				headers: {
					Authorization: `Bearer ${this.props.token}`,
				},
			});

			if (result.status === 200 && result.data.data) {
				const data = result.data.data;
				const totalCount = result.data.data.reduce((acc, current) => {
					return acc + current.data.length;
				}, 0);

				// eslint-disable-next-line array-callback-return
				await data.map((item, index) => {
					let obj = {
						id: index,
						title: item.title,
						cards: [],
						columnsubtitle: `${item.data.length}`,
					};

					if (item.data && item.data.length > 0) {
						let cards = item.data.map((x, j) => {
							let xx = x;
							let xxx = {
								id: x.id,
								date: x.ended_at,
								title: x.name,
								status: x.status,
								subtitle: 'subtitle',
								priority: x.priority,
								secondaryId: x.task_secondary_id,
								progressValue: 100,
								caseId: x.report_case.secondary_case_id,
								primaryCaseId: x.report_case.case_id,
								caseTitle: x.report_case.description,
								team: [
									{
										id: `task-owner-${x.report_case.secondary_case_id}-${x.ended_at}`,
										type: 'Task owner',
										name: x.owner_info?.name,
										avatar: x.owner_info?.avatar,
									},
									{
										id: `task-manager-${x.report_case.secondary_case_id}-${x.ended_at}`,
										type: 'Task Manager',
										name: x.manager_info?.name,
										avatar: x.manager_info?.avatar,
									},
								],
							};

							xx.id = `${item.title}-${x.secondary_id}`;
							xx.content = xxx;

							return xx;
						});

						obj.cards = cards;
					}

					columns.push(obj);
				});

				content.columns = columns;

				this.setState({
					content,
					columns,
					totalCount,
				});
			}
		} catch (error) {
			if (process?.env?.NODE_ENV === 'development') {
				console.error('this error only appears in the development environment:\nerror while fetching tasks:', error);
			}
		}

		this.setState({
			loading: false,
		});
	}

	async handleFetchAnalystsAndAnalystAdmins() {
		try {
			const result = await axios.post(
				this.props.modules.active === 'wb'
					? `${process.env.REACT_APP_CUSTOMER_API_ENDPOINT}api/analystAndAdminAnalyst.list`
					: `${process.env.REACT_APP_CUSTOMER_API_ENDPOINT}api/cm/analystAndAdminAnalyst.list`,
				{},
				this.state.headers,
			);

			if (result.status === 200) {
				if (result.data.data) {
					const analysts = result.data.data.map((item) => {
						return {
							label: `${item.first_name} ${item.last_name}`,
							value: item.id,
						};
					});

					this.setState({
						managerOptions: analysts,
					});
				}
			}
		} catch (error) {
			if (process?.env?.NODE_ENV === 'development') {
				console.error('this error only appears in the development environment:\nerror while fetching analysts and analyst admins:', error);
			}
		}
	}

	async handleFetchOwners() {
		this.setState({ ownersLoading: true });
		try {
			const result = await axios.post(
				this.props.modules.active === 'wb'
					? `${process.env.REACT_APP_CUSTOMER_API_ENDPOINT}api/analystAndAdminAnalystAndTaskOwners.list`
					: `${process.env.REACT_APP_CUSTOMER_API_ENDPOINT}api/cm/analystAndAdminAnalystAndTaskOwners.list`,
				{},
				this.state.headers,
			);

			if (result.status === 200) {
				if (result.data.data) {
					const ownerList = result.data.data.map((item) => {
						return {
							label: `${item.first_name} ${item.last_name}`,
							value: item.id,
						};
					});

					this.setState({
						ownerOptions: ownerList,
					});
				}
			}
		} catch (error) {
			if (process?.env?.NODE_ENV === 'development') {
				console.error('this error only appears in the development environment:\nerror while fetching analysts and analyst admins:', error);
			}
		}
		this.setState({ ownersLoading: false });
	}

	componentDidMount() {
		this.fetchTasks();
		this.handleFetchAnalystsAndAnalystAdmins();
		this.handleFetchOwners();
		this.getSortOptions();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.t !== this.props.t) {
			this.getSortOptions();
		}
	}

	render() {
		if (!isFeatureEnabledForActiveModule('kanban-board')) {
			return <div className='page-content'>{this.props.t('Please upgrade your plan to access this feature')}.</div>;
		}

		return (
			<React.Fragment>
				<div className='page-content'>
					<Container fluid style={{ minHeight: '500px' }}>
						<Breadcrumbs title={this.props.t('Kanban Board')} breadcrumbItems={this.state.breadcrumbItems} />
						<Row className='mb-3 '>
							<Col sm={6} md={4} lg={2}>
								<Select
									isClearable={true}
									name='sortBy'
									classNamePrefix='select2-selection'
									options={this.state.sortOptions}
									onChange={(e) => {
										this.handleFilterKanbanBoards(this.state.filter.fromDate, this.state.filter.toDate, this.state.filter.owner, this.state.filter.manager, e);
									}}
									placeholder={this.props.t('Sort by')}
								/>
							</Col>
							<Col sm={6} md={4} lg={2}>
								<Select
									isClearable={true}
									name='analysts and analystAdmins'
									classNamePrefix='select2-selection'
									options={this.state.ownerOptions}
									onChange={(e) => {
										this.handleFilterKanbanBoards(this.state.filter.fromDate, this.state.filter.toDate, e, this.state.filter.manager, this.state.filter.sortBy);
									}}
									placeholder={this.props.t('Task Owner')}
								/>
							</Col>
							<Col sm={6} md={4} lg={2}>
								<Select
									isClearable={true}
									name='managers'
									classNamePrefix='select2-selection'
									options={this.state.managerOptions}
									onChange={(e) => {
										this.handleFilterKanbanBoards(this.state.filter.fromDate, this.state.filter.toDate, this.state.filter.owner, e, this.state.filter.sortBy);
									}}
									placeholder={this.props.t('Task Manager')}
								/>
							</Col>{' '}
							<Col sm={6} md={6} lg={2}>
								<ReactDatePicker
									className='dt-datepicker'
									value={this.state.filter.fromDate}
									selected={this.state.filter.fromDate}
									placeholderText={this.props.t('Creation Date')}
									dateFormat='dd/MM/yyyy'
									onChange={(e) => {
										this.handleFilterKanbanBoards(e, this.state.filter.toDate, this.state.filter.owner, this.state.filter.manager, this.state.filter.sortBy);
									}}
								/>
							</Col>
							<Col sm={6} md={6} lg={2}>
								<ReactDatePicker
									className='dt-datepicker'
									value={this.state.filter.toDate}
									selected={this.state.filter.toDate}
									placeholderText={this.props.t('Deadline')}
									dateFormat='dd/MM/yyyy'
									onChange={(e) => {
										this.handleFilterKanbanBoards(this.state.filter.fromDate, e, this.state.filter.owner, this.state.filter.manager, this.state.filter.sortBy);
									}}
								/>
							</Col>
						</Row>
						{!this.state.loading && (
							<KanbanBoard
								t={this.props.t}
								totalCount={this.state.totalCount}
								board={{
									sortBy: this.state.sortBy,
									columns: this.state.columns,
									content: this.state.content,
									placeholder: this.state.sortOptions,
								}}
								content={this.state.columns}
							/>
						)}
					</Container>
				</div>
			</React.Fragment>
		);
	}
}

const mapStatetoProps = (state) => {
	const App = state.App;
	const { token, user } = state.Login;
    const { 
		Modules: modules 
	} = state;

	return {
		App,
		user,
		token,
        modules
	};
};

const mapDispachToProps = (dispach) => {
	return {};
};

export default withNamespaces()(withRouter(connect(mapStatetoProps, mapDispachToProps)(IndexKanban)));
