import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { Label } from "reactstrap";
import MultiSelect from "../../../../../../../components/MultiSelect";
import UserUtils from "src/services/utils/UserUtils";
import ReportIncidenService from "../../../../components/api/ReportIncidenService";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";

const Index = (props) => {
  const {
    t,
    report,
    caseId,
    currentUser,
    reportLoading,
    token,
  } = props;
  const [onlyAnalysts, setOnlyAnalysts] = useState([]);
  const userUtils = new UserUtils();
  const [onlyAnalystsList, setOnlyAnalystsList] =  useState([]);

  const isAnalystOrAnalystAdmin = () => {
    return userUtils.isAnalystOrAnalystAdmin(currentUser);
  };

  const handleFetcOnlyAnalystsList = useQuery({
    queryKey: ["fetch-only-analyst-list"],
    queryFn: async () => {
      const service = ReportIncidenService.getInstance();

      return await service.FetchOnlyPermission(caseId);
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      toast(t("An error occurred while fetching analyst admins list."), {
        type: "error",
      });
    },
  });


  useEffect(() => {
    
    if (
      handleFetcOnlyAnalystsList.data &&
      Array.isArray(handleFetcOnlyAnalystsList.data)
    ) {
      const newList = handleFetcOnlyAnalystsList.data.map(
        (analyst) => {
          return {
            value: analyst.id,
            label: `${analyst.firstName} ${analyst.lastName}`,
          };
        }
      );

      setOnlyAnalystsList(newList);
    }
  }, [handleFetcOnlyAnalystsList.data]);


  const handleChangeAnalystsAndAnalystAdminsMutation = useMutation({
    mutationFn: async (payload) => {
      const service = ReportIncidenService.getInstance();

      return await service.ChangeAnalystsAndAnalystAdmins(payload);
    },
  });

  const handleChangeAnalystsAndAnalystAdmins = (item) => {
    handleChangeAnalystsAndAnalystAdminsMutation.mutate(
      {
        caseId: caseId,
        readonlyAnalysts: item,
      },
      {
        onSuccess: () => {
          toast(t("Read-only analysts updated successfully."), {
            type: "success",
          });
        },
        onError: (error) => {
          toast(t("Failed to update read-only analysts."), {
            type: "error",
          });
          // Refetch link if mutation fails
          setOnlyAnalysts([...onlyAnalysts]);
        },
      }
    );
  };

  //checking for the add item or remove item then call the query based on them
  const handleMultiSelectChangeOnlyAnalyst = (selectedValues) => {
    const mappedValues = selectedValues.map((item) => item.value.toString());
    handleChangeAnalystsAndAnalystAdmins(mappedValues);
    setOnlyAnalysts(selectedValues);
  };

  // Update analysts list when loading state or list changes
  useEffect(() => {
    if (
      !reportLoading &&
      !handleFetcOnlyAnalystsList.isLoading &&
      onlyAnalystsList?.length > 0
    ) {
      const onlyAnalystsObjects = onlyAnalystsList
        .filter((item) => report?.readonly_analysts.includes(item.value))
        .map((item) => ({ ...item, title: item.label }));
      setOnlyAnalysts(onlyAnalystsObjects);
    }
  }, [handleFetcOnlyAnalystsList.isLoading, onlyAnalystsList, report, reportLoading]);

  return (
    <div style={{ maxWidth: "396px" }}>
      <Label>{t("Read-only Permissions")}</Label>
      {!handleFetcOnlyAnalystsList.isLoading && !reportLoading ? (
        <MultiSelect
          options={
            onlyAnalystsList && onlyAnalystsList?.length > 0
              ? onlyAnalystsList?.map((item) => ({
                  ...item,
                  title: item.label, // Change 'name' property to 'title'
                }))
              : []
          }
          selectedValues={onlyAnalysts}
          onChange={handleMultiSelectChangeOnlyAnalyst}
          placeholder={t("Select...")}
          isDisabled={report?.deleted_by_name || report?.is_deleted_content || report?.is_read_only_analyst}
        />
      ) : (
        // skeleton
        <>
          <p
            className={`dt-field dt-skeleton ${
              isAnalystOrAnalystAdmin ? "dt-select-list" : ""
            }`}
          ></p>
          <p className="dt-field dt-skeleton dt-update-date"></p>
        </>
      )}
    </div>
  );
};

const mapStatetoProps = (state) => {
  const { token, currentUser } = state.Login;
  return {
    currentUser,
    token,
  };
};

export default withNamespaces()(connect(mapStatetoProps)(Index));
