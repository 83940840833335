import { withNamespaces } from "react-i18next";
import { memo, useState } from "react";
import { Container } from "reactstrap";
import { Link, Switch, Route, useRouteMatch } from "react-router-dom";
import { withRouter } from "react-router-dom";

// components
import PageBody from "../../../components/page-body";
import PageHeader from "../../../components/page-header";
import PageContent from "../../../components/page-content";
import HardwareDetailsTabs from "./components/hardware-details-tabs";

import DataProtectionHardwareInformation from "../../hardware/details/information";
import DataProtectionHardwareOwners from "../../hardware/details/owners";

// styles
import "./style.scss";

const DataProtectionHardwareDetails = (props) => {
  const [assetId, setAssetId] = useState(null);
  const {t} = props;
  const {path} = useRouteMatch();

  return (
    <PageContent classNames="data-protection-hardware-details-page">
      <Container fluid>
        {/* page header */}
        <PageHeader title={assetId}>
          <Link to='/admin/data-protection/hardwares' className='btn btn-primary'>
            {t('Back to list')}
          </Link>
        </PageHeader>

        {/* page body */}
        <PageBody classNames="p-4">
          <HardwareDetailsTabs t={t}/>

            <Switch>
                <Route exact path={path}>
                    <DataProtectionHardwareInformation setAssetId={setAssetId} />
                </Route>
            </Switch>
        </PageBody>
      </Container>
    </PageContent>
  );
};

export default withNamespaces()(
  withRouter(memo(DataProtectionHardwareDetails))
);
