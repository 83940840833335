import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import axios from 'axios';
import { Alert, Container } from "reactstrap";
import { API_BASE_URL } from "../constants";

const UserManual = (props) => {
  const type = window.location.href.includes('user_manual') ? 'user_manual' :  "faq";
  const view = type==="faq"? "all" : window.location.href.includes('admin') ? 'admin' :  window.location.href.includes('employee') ? 'employee' : 'third_party';
  const {lng, token} = props;
  const langId = props?.organization?.languages.find(l => l.language_code === lng)?.language?.id;
  const [content, setContent] = useState("");


    const fetchDoc = async () => {
        const response = await axios.get(`${API_BASE_URL}/${type}/get_context?view=${view}&language=${lng}`, token ? {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        } : {});

        if (response.data.data) {
            setContent(response.data.data);
        }
    }

    useEffect(()=> {
        fetchDoc();
    }, [langId])

  return (
    <div className="ps-3">
      {content ? <div className= {type!=="faq" ? "mt-5 pt-5 pb-5 mb-5"  : "pb-5 mb-5"}  dangerouslySetInnerHTML={{ __html: content }} /> : 
        <Container className="page-content">
          <Alert className="text-center" color="warning">
            {props.t("There is no data")}
          </Alert>
        </Container>
      }
    </div>
  );
}

const mapStatetoProps = (state) => {
  const { user, token } = state.Login;
  const { App } = state;
  const organization = state.Organization;
  return { user, token, App, organization };
};

export default connect(mapStatetoProps, {})(withNamespaces()(UserManual));
