import FullPageLayout from '../components/FullPageLayout';

import Error404 from '../pages/Error404';
import FatalError from '../pages/FatalError';

const errorsRoutes = [
	{
        path: '/404',
        component: Error404,
        layout: FullPageLayout,
    },
    {
        path: '/fatal-error',
        component: FatalError,
        layout: FullPageLayout,
    }
];

export default errorsRoutes;
