import { CardBody } from "reactstrap";
import { Fragment, memo } from "react";

const CardHeader = function (props) {
  return (
		<Fragment>
			<CardBody>
				<h4 className='card-title'>{props.t(props.title)}</h4>
				<p className='mb-1'>{`${props.t(props.columnsubtitle)} ${props.t('Cards')}`}</p>
			</CardBody>
		</Fragment>
	);
};

export default memo(CardHeader);
