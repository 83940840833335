import axios from 'axios';

import {
    API_BASE_URL
} from '../constants/index';

export default class SupplierTeamService {
	static instance;

	static getInstance() {
		if (!SupplierTeamService.instance) {
			SupplierTeamService.instance = new SupplierTeamService();
		}

		return SupplierTeamService.instance;
	}

    async list(supplierId, params = {}){
        const response = await axios.get(`${API_BASE_URL}/supplier/${supplierId}/team_managers`, {
            params  :   params,
            headers :   {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`
            },
        });
        return response.data?.data || null;
    }

    async create(payload){
        const response = await axios.post(`${API_BASE_URL}/supplier/team_manager/add`, payload,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`,
              },
            }
        );
  
        return response.data?.data || null;
    }

    async update(supplierId, personId, payload){
        const response = await axios.put(`${API_BASE_URL}/supplier/team_manager/${personId}/edit`, payload,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`,
              },
            }
        );
  
        return response.data?.data || null;
    }

    async delete(supplierId, personId){
        const response = await axios.delete(`${API_BASE_URL}/supplier/team_manager/${personId}/delete`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`,
              },
            }
        );
  
        return response.data?.data || null;
    }

    async checkSanctions(supplierId, payload = {}){
        
        const response = await axios.post(`${API_BASE_URL}/sanction/team_manager/${supplierId}`, payload,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`
              }
            }
        );
  
        return response.data.data;
    }
}
