import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { toast } from "react-toastify";
import { useQuery, useMutation } from "@tanstack/react-query";
import Select from 'react-select';
import AdminstrationSettingsService from "../api/adminstrationSettingsService";
import {  Row, Col, Label } from "reactstrap";
import HardwareAssets from './components/HardwareAssets';
import SoftwareAssets from "./components/SoftwareAssets";
import IncidentReports from './components/IncidentReports';
import DataSubjectRequests from './components/DataSubjectRequests';
import UserUtils from "src/services/utils/UserUtils";

// Mapping object to convert specific keys
const keyMapping = {
  "default_hardware_owner": "defaultHardwareOwner",
  "default_software_owner": "defaultSoftwareOwner",
  "default_incident_owner": "defaultIncidentOwner",
  "default_dsr_owner": "defaultDsrOwner",
  "default_hardware_risk_owner": "defaultHardwareRiskOwner",
  "default_software_risk_owner": "defaultSoftwareRiskOwner",
  "default_incident_risk_owner": "defaultIncidentRiskOwner",
  "default_hardware_task_owner": "defaultHardwareTaskOwner",
  "default_software_task_owner": "defaultSoftwareTaskOwner",
  "default_incident_task_owner": "defaultIncidentTaskOwner",
  "default_dsr_task_owner": "defaultDsrTaskOwner"
};


const RouterSetting = (props) => {
  const { t, currentUser } = props;
  const [entityLevel, setEntityLevel] = useState();
  const [analystAdminsList, setAnalystAdminsList] = useState([]);
  const [routerSettings, setRouterSettings] = useState({
      defaultHardwareOwner: null,
      defaultSoftwareOwner: null,
      defaultIncidentOwner: null,
      defaultDsrOwner: null,
      defaultHardwareRiskOwner: null,
      defaultSoftwareRiskOwner: null,
      defaultIncidentRiskOwner: null,
      defaultHardwareTaskOwner: null,
      defaultSoftwareTaskOwner: null,
      defaultIncidentTaskOwner: null,
      defaultDsrTaskOwner: null
  });

  const [selectedEntity, setSelectedEntity] = useState(null);
  const [loading, setLoading] = useState(false);

  const userUtils = new UserUtils();
  const isAdmin =userUtils.isAnalystAdmin(currentUser)

  const service = AdminstrationSettingsService.getInstance();

  const handleFetchRouterSettings = useQuery({
    queryKey: ["fetch-entity-settings"],
    queryFn: async () => {
      return await service.FetchRouterSettings(props?.Organization?.id);
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      toast(props.t("An error occurred while fetching router settings."), {
        type: "error",
      });
    },
  });

  const handleFetchAnalystAndAdminAnalystList = useQuery({
    queryKey: ["fetch-analyst-and-admin-analyst-list"],
    queryFn: async () => {
      return await service.FetchAnalystsAndAnalystAdmins();
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      toast(t("An error occurred while fetching analyst admins list."), {
        type: "error",
      });
    },
  });
  useEffect(() => {

    if (handleFetchRouterSettings.data) {
      const options = handleFetchRouterSettings?.data?.map(entity => ({
        value: entity.id,
        label: entity.title
    }));
      setEntityLevel(options);
    }
  }, [handleFetchRouterSettings.data]);

  useEffect(() => {
    if (
      handleFetchAnalystAndAdminAnalystList.data &&
      Array.isArray(handleFetchAnalystAndAdminAnalystList.data)
    ) {
      const newList = handleFetchAnalystAndAdminAnalystList.data.map(
        (analyst) => {
          return {
            value: analyst.id,
            label: `${analyst.first_name} ${analyst.last_name}`,
          };
        }
      );

      setAnalystAdminsList(newList);
    }
  }, [handleFetchAnalystAndAdminAnalystList.data]);

  const transformSettings = (settings) => {
    return Object.keys(settings).reduce((acc, key) => {
      acc[key] = settings[key]?.value || null;
      return acc;
    }, {});
  };
  

  const saveSettingsMutation = useMutation({
    mutationFn: async (id) => {
      const transformedSettings = transformSettings(routerSettings);
      return await service.SaveRouterSettings(id, transformedSettings);
    },
    onError: () => {
      toast(t("An error occurred while saving settings."), {
        type: "error",
      });
    },
    onSuccess: () => {
      toast(t("Settings saved successfully."), {
        type: "success",
      });
    },
    onSettled: () => {
      setLoading(false); // Set loading to false on completion
    }
  });

  const restoreSettingsMutation = useMutation({
    mutationFn: async (id) => {
      return await service.RestoreRouterSettings(id);
    },
    onError: () => {
      toast(t("An error occurred while restoring settings."), {
        type: "error",
      });
    },
    onSuccess: (data) => {
      setRouterSettings({
        defaultHardwareOwner: null,
        defaultSoftwareOwner: null,
        defaultIncidentOwner: null,
        defaultDsrOwner: null,
        defaultHardwareRiskOwner: null,
        defaultSoftwareRiskOwner: null,
        defaultIncidentRiskOwner: null,
        defaultHardwareTaskOwner: null,
        defaultSoftwareTaskOwner: null,
        defaultIncidentTaskOwner: null,
        defaultDsrTaskOwner: null
    });
      toast(t("Settings restored successfully."), {
        type: "success",
      });
    },
    onSettled: () => {
      setLoading(false); // Set loading to false on completion
    }
  });

  const transformFetchedData = (data) => {
    const transformedData = {};
  
    // Initialize all keys with null values based on keyMapping
    Object.keys(keyMapping).forEach(mappedKey => {
      transformedData[keyMapping[mappedKey]] = null;
    });
  
    // Transform data from response
    for (const key in data) {
      const newKey = keyMapping[key] || key; // Use the mapped key or the original key if not in the mapping
      if (data[key] && typeof data[key] === 'object') {
        transformedData[newKey] = {
          value: data[key].id,
          label: data[key].full_name,
        };
      } else {
        transformedData[newKey] = data[key];
      }
    }
  
    return transformedData;
  };

  const fetchRoutingDetailsMutation = useMutation({
    mutationFn: async (id) => {
      return await service.FetchRoutingDetails(id);
    },
    onError: () => {
      toast(t("An error occurred while fetching routing details."), {
        type: "error",
      });
    },
    onSuccess: (data) => {
      if (Array.isArray(data.data)) {
        setRouterSettings({
          defaultHardwareOwner: null,
          defaultSoftwareOwner: null,
          defaultIncidentOwner: null,
          defaultDsrOwner: null,
          defaultHardwareRiskOwner: null,
          defaultSoftwareRiskOwner: null,
          defaultIncidentRiskOwner: null,
          defaultHardwareTaskOwner: null,
          defaultSoftwareTaskOwner: null,
          defaultIncidentTaskOwner: null,
          defaultDsrTaskOwner: null
      });
        return;
      }
      if(data?.data && typeof data.data === 'object'){
        const transformedData = transformFetchedData(data.data);
      setRouterSettings(transformedData);

      }
    },
    onSettled: () => {
      setLoading(false); // Set loading to false on completion
    }
  });


  const handleSave = () => {
    if(!selectedEntity){
      toast(t("Please select an entity"), {
        type: "error",
      });
      return;
    }
    setLoading(true);
    saveSettingsMutation.mutate(selectedEntity?.value);
  };

  const handleRestore = () => {
    if(!selectedEntity){
      toast(t("Please select an entity"), {
        type: "error",
      });
      return;
    }
    setLoading(true);
    restoreSettingsMutation.mutate(selectedEntity?.value);
  };

  const handleEntityChange = (selectedOption) => {
    setLoading(true);
    setSelectedEntity(selectedOption);
    fetchRoutingDetailsMutation.mutate(selectedOption.value);
  };


  return (
    <div className="p-4 settings">
       <Row className="w-100 mb-3">
        <Col sm="12" md="6" lg="6" className="p-2">
          <Label>{t("Entity")}</Label>
          <Select
            options={entityLevel}
            value={selectedEntity}
            onChange={handleEntityChange}
            placeholder={t("Select Entity")}
            isDisabled={!isAdmin}
          />
        </Col>
      </Row>
    
        <HardwareAssets
        t={t}
        routerSettings={routerSettings}
        selectedEntity={setRouterSettings}
        analystAdminsList={analystAdminsList}
        loading={loading}
        isAdmin={isAdmin}
      />
      <SoftwareAssets
        t={t}
        routerSettings={routerSettings}
        selectedEntity={setRouterSettings}
        analystAdminsList={analystAdminsList}
        loading={loading}
        isAdmin={isAdmin}
      />
       <IncidentReports
        t={t}
        routerSettings={routerSettings}
        selectedEntity={setRouterSettings}
        analystAdminsList={analystAdminsList}
        loading={loading}
        isAdmin={isAdmin}
      />
      <DataSubjectRequests
        t={t}
        routerSettings={routerSettings}
        selectedEntity={setRouterSettings}
        analystAdminsList={analystAdminsList}
        loading={loading}
        isAdmin={isAdmin}
      /> 

      <Row className="w-100">
        <div className="d-flex gap-3 mt-4 justify-content-end">
          <button className="restoreBtn" disabled={loading || !isAdmin} onClick={handleRestore}>
            {t("Restore")}
          </button>
          <button className="saveBtn" disabled={loading || !isAdmin} onClick={handleSave}>
            {t("Save")}
          </button>
        </div>
      </Row>
    </div>
  );
};

const mapStatetoProps = (state) => {
  const { token, user: currentUser } = state.Login;
  const { Organization } = state;
  return {
    currentUser,
    token,
    Organization
  };
};

export default withNamespaces()(connect(mapStatetoProps)(RouterSetting));
