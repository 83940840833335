import axios from 'axios';
import { API_BASE_URL } from '../../constants';

export default class QuestionarService {
	static instance;
	resource = `${API_BASE_URL}/`;
	questionnaireResource = `${API_BASE_URL}/questionnaire`;
	

	constructor() {}

	static getInstance() {
		if (!QuestionarService.instance) {
			QuestionarService.instance = new QuestionarService();
		}

		return QuestionarService.instance;
	}

	async questions(type, organization, params){
		const response = await axios.get(`${this.questionnaireResource}/${type}/organization/${organization}/detail`, {
			params: params,
			headers: {
				Authorization: `Bearer ${localStorage.getItem('LOCAL_STORAGE_KEY_ACCESS_TOKEN')}`,
			}
		});

		return response.data.data || [];
	}

    async list(){
		const response = await axios.get(`${this.questionnaireResource}/list`, {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('LOCAL_STORAGE_KEY_ACCESS_TOKEN')}`,
			},
		});

		return response.data.data;
	}

	/**
	 * 
	 * detail of questionnaire for admin and questionnaire designer
	 */
	async questionnaireDetail(type, params){
		const response = await axios.get(`${this.questionnaireResource}/${type}/admin/detail`, {
			params: params,
			headers: {
				Authorization: `Bearer ${localStorage.getItem('LOCAL_STORAGE_KEY_ACCESS_TOKEN')}`,
			}
		});

		return response.data.data || [];
	}

	async deleteQuestion(questionId){
		const response = await axios.delete(`${this.resource}question/${questionId}/delete`, {
			headers: {
			  Authorization: `Bearer ${localStorage.getItem(
				"LOCAL_STORAGE_KEY_ACCESS_TOKEN"
			  )}`,
			},
		});
	  
		return response.data.data;
	}

	async updateQuestionnaire(payload){
		const response = await axios.post(`${this.resource}questions/update`, payload, {
			headers: {
			  Authorization: `Bearer ${localStorage.getItem(
				"LOCAL_STORAGE_KEY_ACCESS_TOKEN"
			  )}`,
			},
		});
	  
		return response.data.data;
	}

	async deleteQuestionOption(optionId, payload = {}){
		const response = await axios.delete(`${this.resource}question/option/${optionId}/delete`, {
			headers: {
			  Authorization: `Bearer ${localStorage.getItem(
				"LOCAL_STORAGE_KEY_ACCESS_TOKEN"
			  )}`,
			},
			data: payload
		});
	  
		return response.data.data;
	}

	async fetchPrevAnswers(type, params = {}){
		const response = await axios.get(`${this.questionnaireResource}/${type}/detail`, {
			params: params,
			headers: {
				Authorization: `Bearer ${localStorage.getItem('LOCAL_STORAGE_KEY_ACCESS_TOKEN')}`,
			}
		});

		return response.data.data || [];
	}
}
