import React from "react";
import { Document, Page, Text, View, StyleSheet, Font, Image } from "@react-pdf/renderer";

import { withNamespaces } from "react-i18next";

import { TRANSLATION_KEY_REPORT_INFO as KEY_REPORT_INFO, TRANSLATION_KEY_COMMENT_INFO as KEY_COMMENT_INFO, TRANSLATION_KEY_USER_INFO as KEY_USER_INFO, FONT_OPEN_SANS_BOLD, FONT_OPEN_SANS_REGULAR, REPORT_FIELDS, FIELD_TYPE_TEXT, FIELD_TYPE_TEXT_AREA, FIELD_TYPE_REPORT_UPLOAD_FILE, FIELD_TYPE_MULTI_RADIO, DATE_FORMAT_DAY_OF_WEEK, FIELD_TYPE_MULTI_CHECKBOX, FIELD_TYPE_SELECT, IMAGE_USER_CIRCLE, FIELD_REPORT_ATTACHMENTS, REPORT_TYPE_ANONYMOUS, REPORT_PDF_DOWNLOAD_OPTIONS, ROLE_ANALYST_ADMIN, ROLE_ANALYST, ROLE_EMPLOYEE, REPORT_TYPE_CONFIDENT } from "../../components/constants";
import DateUtils from "../../services/utils/DateUtils";
import ReportUtils from "../../services/utils/ReportUtils";
import UserUtils from "../../services/utils/UserUtils";
import { parseDocument } from "htmlparser2";
import { isTag, Element, Node, isText, DataNode } from "domhandler";
import { INTERNATIONAL_DATE_FORMAT } from "../../common/constants";

Font.register({
	family: "Open Sans",
	fonts: [
		{
			src: FONT_OPEN_SANS_REGULAR,
			fontWeight: 400,
			fontStyle: "normal",
		},
		{
			src: FONT_OPEN_SANS_REGULAR,
			fontWeight: 400,
			fontStyle: "italic",
		},
		{
			src: FONT_OPEN_SANS_BOLD,
			fontWeight: 600,
			fontStyle: "normal",
		},
		{
			src: FONT_OPEN_SANS_BOLD,
			fontWeight: 600,
			fontStyle: "italic",
		},
	],
});

const styles = StyleSheet.create({
	header: {
		display: "flex",
		flexDirection: "column",
		textAlign: "center",
		marginBottom: 30,
		marginTop: 40,
	},
	logo: {
		maxWidth: 100,
		height: 40,
		objectFit: "contain",
		position: "absolute",
		left: 0,
		top: 0,
	},
	headerTop: {
		position: "relative",
	},
	headerBottom: {
		marginTop: 10,
		textTransform: "uppercase",
	},
	page: {
		flexDirection: "column",
		fontFamily: "Open Sans",
		fontSize: 11,
		paddingLeft: 40,
		paddingRight: 40,
		paddingBottom: 60,
	},
	section: {
		marginBottom: 30,
		display: "flex",
		flexDirection: "column",
	},
	title: {
		fontSize: 16,
		textTransform: "uppercase",
		marginBottom: 10,
		color: "#507099",
	},
	badge: {
		padding: 10,
		backgroundColor: "#E0E0E0",
		marginBottom: 20,
		fontSize: 12,
	},
	mb20: {
		marginBottom: 20,
	},
	mb10: {
		marginBottom: 10,
	},
	label: {
		fontWeight: 600,
	},
	rowLeft: {
		flex: 1,
		paddingRight: 10,
	},
	rowRight: {
		flex: 1,
		paddingLeft: 10,
	},
	row: {
		flexDirection: "row",
		marginBottom: 20,
	},
	commentRow: {
		flexDirection: "row",
		marginBottom: 20,
		alignItems: "flex-start",
		justifyContent: "flex-start",
		width: "100%",
	},
	subText: {
		fontSize: 12,
		color: "#828282",
	},
	commentAvatar: {
		width: 20,
		marginRight: 10,
		objectFit: "contain",
	},
	commentContent: {
		flexDirection: "column",
		flex: 1,
	},
	commentTime: {
		color: "#828282",
		fontSize: 10,
	},
	ownerName: {
		fontWeight: 600,
		marginRight: 10,
	},
	textBold: {
		fontWeight: 600,
	},
	textUnderline: {
		textDecoration: "underline",
	},
	textItalic: {
		fontStyle: "italic",
	},
	textUppercase: {
		textTransform: "uppercase",
	},
	ownerRow: {
		flexDirection: "row",
		display: "flex",
		alignItems: "center",
		marginBottom: 5,
	},
	list: {
		marginLeft: 20,
		flexDirection: "column",
		justifyContent: "flex-start",
		alignItems: "flex-start",
	},
	listItem: {
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
	},
	dotPrefix: {
		fontSize: 20,
		fontWeight: 600,
	},
	pageNumber: {
		position: "absolute",
		bottom: 40,
		right: 30,
		left: 30,
		textAlign: "right",
	},
	paragraph: {
		display: "block",
		marginBottom: 10,
	},
});

const ReportLogsPDF = (props) => {
	const { data, logo } = props;

	const dateUtils = new DateUtils();
	const reportUtils = new ReportUtils();
	const userUtils = new UserUtils();
	const { t } = props;

	const renderHeader = () => {
		// const organization = this.props.Organization;
		// const orgLogo = organization.logo ? organization.logo : null;

		// console.log(logo)
		// console.log('_________________________')

		return (
			<View style={styles.header} fixed>
				<View style={styles.headerTop}>
					{/* <Image source={{ url: logo, method: 'GET', headers: {}, body: '' }} style={styles.logo} /> */}
					{/* <Image src={logo} style={styles.logo} /> */}
					{/* <Image src={{uri:logo, method: 'GET', headers:{}}} style={styles.logo}/> */}
					{logo ? <Image src={logo} style={styles.logo} /> : ""}
					<View>
						<Text style={styles.textBold}>{t("Smart Integrity Platform")}</Text>
					</View>
					<View>
						<Text>{t("Case report")}</Text>
					</View>
				</View>
				<View style={styles.headerBottom}>
					<Text>{t("STRICTLY CONFIDENTIAL – FOR INTERNAL USE ONLY")}</Text>
				</View>
			</View>
		);
	};

	// const renderLogs = () => {
	//     data.map((item, index) => {
	//         return <Text key={index} style={styles.paragraph}>{`${item.action} by ${item.created_by.full_name}`}</Text>;
	//     });
	// };

	return (
		<Document>
			<Page size="A4" style={styles.page}>
				{/* {renderHeader()} */}
				{/* {renderLogs()} */}
				<View style={{ paddingTop: 60 }}>
					{data.map((item, index) => {
						return <Text key={index} style={styles.paragraph}>{`${item.action} by ${item.created_by.full_name}`}</Text>;
					})}
				</View>
				{/* {JSON.stringify(data)} */}

				{/* {renderGeneralSection()} */}

				{/* {renderReportQuestions()} */}

				{/* {renderAttachedFiles()} */}

				{/* {renderContactDetails()} */}

				{/* {renderComments()} */}

				{/* {renderInternalComments()} */}

				{/* {renderInternalFiles()} */}

				<Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => `Page ${pageNumber} / ${totalPages}`} fixed />
			</Page>
		</Document>
	);
};

export default withNamespaces()(ReportLogsPDF);
