import { useLocation, useHistory } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Col, FormGroup, Label, Input } from "reactstrap";
import Select from "react-select";
export function useFilterQuery(
  { Service, method, params, filterKey },
  onSuccess,
  onError
) {
  return useQuery({
    queryKey: filterKey,
    queryFn: async () => {
      const service = Service.getInstance();
      const response = await service[method](params);

      return response;
    },
    onSuccess,
    onError,
  });
}

export default function useFilters(inputFilters) {
  const location = useLocation();
  const history = useHistory();

  const searchParams = new URLSearchParams(location.search);

  const [filters, setFilters] = useState(inputFilters);

  useEffect(() => {
    if (!inputFilters) return;

    setFilters(inputFilters);
  }, [inputFilters]);

  useEffect(() => {
    if (!searchParams) return;
    const params = Object.fromEntries(searchParams.entries());

    Object.keys(params).forEach((key) => {
      if (!params[key]) return;
      setFilters((prevState) => {
        if (prevState[key].type === "select") {
          const value = !isNaN(params[key]) ? Number(params[key]) : params[key];
          const label = prevState[key].options?.find(
            (option) => option.value === value
          )?.label;

          return {
            ...prevState,
            [key]: {
              ...prevState[key],
              label,
              value,
            },
          };
        } else {
          return {
            ...prevState,
            [key]: {
              ...prevState[key],
              value: params[key],
            },
          };
        }
      });
    });
  }, [location.search, setFilters, inputFilters]);

  const filterForm = useMemo(() => {
    return Object.keys(filters)?.map((key) => {
      if (typeof filters[key] !== "object") return undefined;
      const filter = filters[key];

      return (
        <Col md={2} key={filter.baseLabel}>
          <FormGroup>
            <Label for={key}>{filter.baseLabel}</Label>
            {filter.type === "select" && (
              <Select
                id={key}
                name={key}
                placeholder={filter.baseLabel}
                options={filter.options}
                menuPortalTarget={document.body}
                value={{ label: filter.label, value: filter.value }}
                onChange={(selectedOption) => {
                  setFilters((prevState) => ({
                    ...prevState,
                    [key]: {
                      ...prevState[key],
                      ...selectedOption,
                    },
                  }));
                //   setSearchParams((prevState) => {
                //     if(!selectedOption || !selectedOption.value) {
                //       prevState.delete(key);
                //       return prevState;
                //     }
                //     prevState.set(key, selectedOption.value);
                //     return prevState;
                //   });
                }}
              />
            )}
            {filter.type === "date" && (
              <Input
                id={key}
                name={key}
                placeholder={filter.baseLabel}
                type="date"
                value={filter.value}
                onChange={(e) => {
                  setFilters((prevState) => ({
                    ...prevState,
                    [key]: {
                      ...prevState[key],
                      value: e.target.value,
                    },
                  }));

                //   setSearchParams((prevState) => {
                //     if(!e.target.value) {
                //       prevState.delete(key);
                //       return prevState;
                //     }
                //     prevState.set(key, e.target.value);
                //     return prevState;
                //   });
                }}
              />
            )}
            {filter.type === "text" && (
              <Input
                id={key}
                name={key}
                placeholder={filter.baseLabel}
                type="text"
                value={filter.value}
                onChange={(e) => {
                  setFilters((prevState) => ({
                    ...prevState,
                    [key]: {
                      ...prevState[key],
                      value: e.target.value,
                    },
                  }));
                //   setSearchParams((prevState) => {
                //     if(!e.target.value) {
                //       prevState.delete(key);
                //       return prevState;
                //     }
                //     prevState.set(key, e.target.value);
                //     return prevState;
                //   });
                }}
              />
            )}
            {filter.type === "number" && (
              <Input
                id={key}
                name={key}
                placeholder={filter.baseLabel}
                type="number"
                value={filter.value}
                onChange={(e) => {
                  setFilters((prevState) => ({
                    ...prevState,
                    [key]: {
                      ...prevState[key],
                      value: e.target.value,
                    },
                  }));
                //   setSearchParams((prevState) => {
                //     if(!e.target.value) {
                //       prevState.delete(key);
                //       return prevState;
                //     }
                //     prevState.set(key, e.target.value);
                //     return prevState;
                //   });
                }}
              />
            )}
          </FormGroup>
        </Col> 
      );
    });
  }, [filters, inputFilters]);

  const filterValues = useMemo(() => {
    const params = {};

    Object.keys(filters).forEach((key) => {
      if (typeof filters[key] === "object") {
        if (!filters[key].value) return;
        params[key] = filters[key].value;
      } else {
        params[key] = filters[key];
      }
    });

    return params;
  });

  return { filterForm, filters, filterValues, setFilters };
}

