import axios from 'axios';
import {
    API_BASE_URL
} from "src/modules/3rd-party-management/constants";


export default class EntityRoutingService {
    static instance;

    static getInstance() {
        if (!EntityRoutingService.instance) {
            EntityRoutingService.instance = new EntityRoutingService();
        }
        return EntityRoutingService.instance;
    }

    async saveRouterSettings(id, payload){
        const response = await axios.post(`${API_BASE_URL}/legal_entity/${id}/edit_routing`, payload, {
            headers :   {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`
            },
        });

        return response
    }

    async fetchRoutingDetails(id){
        const response = await axios.get(`${API_BASE_URL}/legal_entity/${id}/routing`, {
            headers :   {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`
            },
        });
        return response.data
    }

    async restoreRouterSettings(id){
        const response = await axios.delete(`${API_BASE_URL}/legal_entity/${id}/clear_routing`, {
            headers :   {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`
            },
        });
        return response.data
    }
}
