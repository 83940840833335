import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import {
  Card,
  CardBody,
  Tooltip,
} from "reactstrap";
import { withNamespaces } from "react-i18next";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

import { setCaseId } from "../../../../../../store/actions";
import DateUtils from "../../../../../../services/utils/DateUtils";


import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
    API_URL_ADMIN_DASHBOARD_LAST_CASES,
    API_URL_REPORT_CATEGORY_LIST_CASE
} from '../../../../constants'

import {
  INTERNATIONAL_DATE_FORMAT,
} from "../../../../../../common/constants";
import { REPORT_STATUS_LIST } from "../../../../../../components/constants";

import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";


class LatestCases extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      categories: [],
      latestCases: [],
      latestTooltip: false,
      module: localStorage.getItem("module"),
    };
    this.dateUtils = new DateUtils();
  }

  render() {
    const data = {
      columns: [
        {
          dataField: "secondaryCaseId",
          text: this.props.type ==="data_subject_request" ? this.props.t("Request ID") : this.props.t("Incident ID"),
          sort: true,
          formatter: (cell, row) => (
            <Link
              className="text-dark"
              to={this.props.type ==="data_subject_request" ? 
              `/admin/data-protection/data-subject-request/details/${row.caseId}` : 
              `/admin/data-protection/incident-register/details/${row.caseId}`}
              onClick={() => {
                localStorage.setItem("caseId", row.caseId);
                localStorage.setItem("secondaryCaseId", row.secondaryCaseId);
                this.props.onSetCaseId(row.caseId);
              }}
            >
              {cell}
            </Link>
          ),
          style: {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        },
        {
          dataField: "description",
          text: this.props.t("Description"),
          sort: true,
          formatter: (cell, row) => (
            <Link
              className="text-dark"
              to={`/admin/data-protection/report/detail`}
              onClick={() => {
                localStorage.setItem("caseId", row.caseId);
                localStorage.setItem("secondaryCaseId", row.secondaryCaseId);
                this.props.onSetCaseId(row.caseId);
              }}
            >
              {cell}
            </Link>
          ),
          style: {
            maxWidth: "50rem", // Adjust the width based on your preference
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        },
        {
          dataField: "category",
          sort: true,
          text: this.props.t("Category"),
          style: {
            display: "table-cell",
            maxWidth: "200px",
          },
          headerStyle: {
            maxWidth: "200px",
          },
        },
        {
          dataField: "createdAt",
          sort: true,
          text: this.props.t("Created At"),
          style: {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        },
        {
          dataField: "analyst_assigned",
          sort: true,
          text: this.props.t("Assigned analyst"),
        },
        {
          dataField: "status",
          sort: true,
          text: this.props.t("Status"),
        },
      ],
      rows: [],
    };

    const options = {
      hideSizePerPage: false,
      hidePageListOnlyOnePage: false,
      sizePerPageList: [
        {
          text: "5th",
          value: 5,
        },
        {
          text: "10th",
          value: 10,
        },
        {
          text: "All",
          value: data.rows.length,
        },
      ],
    };

    return (
      <React.Fragment>
        <Card className="card_box_Shadow">
          <CardBody>
            <h4 className="card-title mb-4">
              {
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>{this.props.type ==="data_subject_request" ? this.props.t("Latest DSRs") : this.props.t("Latest incidents")}</div>
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    id="infoIconLatest"
                  />
                  <Tooltip
                    placement="top"
                    isOpen={this.state.latestTooltip}
                    target="infoIconLatest"
                    toggle={() =>
                      this.setState({
                        latestTooltip: !this.state.latestTooltip,
                      })
                    }
                  >
                    {this.props.t(
                      "With these tickets, you can see the progression of cases that were reported"
                    )}
                  </Tooltip>
                </div>
              }
            </h4>
            <div
              className="table-responsive dissco-latest-cases"
              style={{ overflowX: "hidden", maxWidth: "100%" }}
            >
              <BootstrapTable
                keyField="id"
                data={this.state.latestCases}
                columns={data.columns}
                pagination={paginationFactory(options)}
                options={{
                  hideSizePerPage: true,
                }}
              />
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }

  renderReportStatus(report) {
    const status = REPORT_STATUS_LIST.find(
      (item) => item.id === parseInt(report)
    );
    return (
      <div className={status?.className}>{this.props.t(status?.titleKey)}</div>
    );
  }

  fetchCategoryList() {
    return axios.post(
      API_URL_REPORT_CATEGORY_LIST_CASE,
      {},
      {
        headers: {
          Authorization: `Bearer ${this.props.token}`,
        },
      }
    );
  }

  makeListReady() {
    this.fetchCategoryList()
      .then((response) => {
        this.setState({
          categories: response.data.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getReportCategories(reportCategories) {
    let result = [];
    if(reportCategories) {
      reportCategories.map((reportCategory) => {
        for (let i = 0; i < this.state.categories.length; i++) {
          if (
            parseInt(reportCategory?.id) === parseInt(this.state.categories[i].id)
          ) {
            result.push(this.props.t(this.state.categories[i].title));
            break;
          }
        }
      });
    }
    
    return result.join(", ");
  }

  getReportAnalystAssigned(assigneds) {
    let result = [];

    if (!assigneds) {
      assigneds = [];
    } else if (!Array.isArray(assigneds) && typeof assigneds === "object") {
      assigneds = [assigneds];
    }

    if (assigneds.length > 0) {
      assigneds.map((item) => {
        result.push(`${item.first_name} ${item.last_name}`);
      });
    }

    return result;
  }

  async fetchLatestCases() {
    const { type } = this.props;
    try {
      const response = await axios.get(
        `${
          API_URL_ADMIN_DASHBOARD_LAST_CASES
        }${this.props.orgIsAll ? "?partner=all" : ""}${type ?  "?questionnaire_name=" + type : ""}`,
        {
          headers: {
            Authorization: `Bearer ${this.props.token}`,
          },
        }
      );
      const lastCases = response.data.data;
      const newListCases = [];

      for (const item in lastCases) {
        newListCases.push({
          description: lastCases[item].description,
          category: this.getReportCategories(lastCases[item]?.categories),
          createdAt: this.dateUtils.convertTimeStampToDate(
            lastCases[item].created_at,
            INTERNATIONAL_DATE_FORMAT
          ),
          analyst_assigned:
            this.getReportAnalystAssigned(
              lastCases[item].analyst_assigned
            ).join(", ") || this.props.t("Unassigned"),
          status: this.renderReportStatus(lastCases[item].status),
          caseId: lastCases[item].case_id,
          secondaryCaseId: lastCases[item].secondary_case_id,
        });
      }

      this.setState({
        latestCases: newListCases,
      });
    } catch (err) {
      console.log(err);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.t !== prevProps.t) {
      this.makeListReady();
      this.fetchLatestCases();
    }

    if (prevProps.orgIsAll !== this.props.orgIsAll) this.fetchLatestCases();
  }

  componentDidMount() {
    this.makeListReady();
    this.fetchLatestCases();
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSetCaseId: (caseId) => dispatch(setCaseId(caseId)),
  };
};

const mapStatetoProps = (state) => {
  const { token } = state.Login;
  const { App } = state;
  return { token, App };
};

export default withNamespaces()(
  withRouter(connect(mapStatetoProps, mapDispatchToProps)(LatestCases))
);
