import { useState, memo } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Collapse, Label } from 'reactstrap';
import { Link } from 'react-router-dom';
import { INTERNATIONAL_DATE_FORMAT } from '../../../../../../common/constants';
import DateUtils from '../../../../../../services/utils/DateUtils';

const ReporterDetails = function (props) {
	ReporterDetails.displayName = 'Memorized Component - Reporter Details';
	if (process.env.NODE_ENV === 'development') console.log('---- rendering memorized component >>> ReporterDetails');

	const dateUtils = new DateUtils();
	const [isOpen, setIsOpen] = useState(false);

	/**
	 * this method renders user's available time.
	 * @param {Object[]} times user's available times.
	 * @param {Object[]} phoneAvailableTimes system default available times.
	 * @returns DOM nodes
	 */
	const renderPhoneAvailableTime = (times, phoneAvailableTimes) => {
		if (times && Array.isArray(times)) {
			const answers = times.map((item) => {
				if (item.optionId === 'custom') {
					return item.value;
				} else {
					const foundItem = phoneAvailableTimes.find((phoneItem) => phoneItem.optionId === item.optionId);
					if (foundItem) {
						return foundItem.value;
					}
				}
			});
			return <p className='text-answers'>{answers.join(', ')}</p>;
		}
		return <p className='text-answers'>---</p>;
	};
	// console.log(props.reportingType, props)
	return (
		<Row>
			<Col sm='12'>
				<Card className='mb-1 shadow-none'>
					{/* header */}
					<Link
						to='#'
						onClick={() => {
							setIsOpen(!isOpen);
						}}
						style={{ cursor: 'pointer' }}
						className='text-dark'
					>
						<CardHeader id='headingOne' className='bg-primary'>
							<h6 className='m-0 text-white font-14'>
								{props.t('Contact Details')}
								<i className={`float-end accor-plus-icon mdi ${isOpen ? 'mdi-minus' : 'mdi-plus'}`}></i>
							</h6>
						</CardHeader>
					</Link>

					<Collapse isOpen={isOpen}>
						<CardBody style={{ paddingLeft: '2px' }}>
							<Row>
								{/* email */}
								{!props.reportOwnerEmail && !props.reportEmail ? null : (
									<Col sm='12'>
										<div className='mb-3'>
											<Label className='form-label'>{props.t('Email address')}</Label>
											{props.reportingType === 'myself' ? (
												<p className='text-answers'>{props.reportOwnerEmail ? props.reportOwnerEmail : props.reportEmail}</p>
											) : (
												<p className='text-answers'>{props.reportEmail ? props.reportEmail : props.reportOwnerEmail}</p>
											)}
										</div>
									</Col>
								)}

								{/* title */}
								<Col sm='12'>
									<div className='mb-3'>
										<Label className='form-label'>{props.t('Title')}</Label>
										{props.reportingType === 'myself' ? (
											<p className='text-answers'>{props.reportOwnerGender ? props.t(props.reportOwnerGender) : props.t(props.reportTitle)} </p>
										) : (
											<p className='text-answers'>{props.reportTitle ? props.t(props.reportTitle) : props.t(props.reportOwnerGender)} </p>
										)}
									</div>
								</Col>

								{/* name */}
								<Col sm='12'>
									<div className='mb-3'>
										<Label className='form-label'>{props.t('Name')}</Label>
										{props.reportingType === 'myself' || !props.reportingType ? (
											<p className='text-answers'>{props.reportOwnerName}</p>
										) : (
											<p className='text-answers'>
												{props.reportFirstName} {props.reportLastName}
											</p>
										)}
									</div>
								</Col>

								{/* position */}
								<Col sm='12'>
									<div className='mb-3'>
										<Label className='form-label'>{props.t('Position')}</Label>
										{props.reportingType === 'myself' ? (
											<p className='text-answers'>{props.reportOwnerPosition ? props.t(props.reportOwnerPosition) : props.t(props.reportPosition)} </p>
										) : (
											<p className='text-answers'>{props.reportPosition ? props.t(props.reportPosition) : props.t(props.reportOwnerPosition)} </p>
										)}
									</div>
								</Col>

								{/* relation company */}
								<Col sm='12'>
									<div className='mb-3'>
										<Label className='form-label'>{props.t('Relation Company')}</Label>
										{props.reportRelationCompany ? (
											<p className='text-answers'>{props.t(props.reportRelationCompany)}</p>
										) : props.reportOwnerRoles === 'ROLE_THIRD_PARTY' ? (
											<p className='text-answers'>{props.t(props.reportOwnerRelationCompany)}</p>
										) : props.reportOwnerRoles === 'ROLE_ANALYST_ADMIN' ? (
											<p className='text-answers'>{props.reportOwnerRelationCompany}</p>
										) : props.reportOwnerRoles === 'ROLE_ANALYST' ? (
											<p className='text-answers'>{props.reportOwnerRelationCompany}</p>
										) : props.reportOwnerRoles === 'ROLE_EMPLOYEE' ? (
											<p className='text-answers'>{props.reportOwnerRelationCompany}</p>
										) : (
											'No Relation Company'
										)}
									</div>
								</Col>

								{/* organization */}
								{props.reportType === 2 ? (
									<Col sm='12'>
										<div className='mb-3'>
											<Label className='form-label'>{props.t('Organization')}</Label>
											<p className='text-answers'>
												{props.reportingType === 'other_one'
													? props.otherOrganization
													: props.reportOwnerRoles === 'ROLE_THIRD_PARTY'
													? props.reportingType === 'myself' && props.reportType === 2
														? props.organization.name
														: props.reportThirdPartyOrganization
													: props.reportingType === 'myself' && props.reportType === 2
													? props.organization.name
													: props.reportOwnerRelationCompany}
											</p>
										</div>
									</Col>
								) : null}

								{/* phone number */}
								{props.reportOwnerPhoneNumber && props.reportOwnerPhoneNumber !== 'null' ? (
									<Col sm='12'>
										<div className='mb-3'>
											<Label className='form-label'>{props.t('Phone number')}</Label>
											<p className='text-answers'>{`+${props.reportOwnerPhoneNumber}`}</p>
										</div>
									</Col>
								) : null}

								{/* date available time from */}
								<Col sm='12'>
									<div className='mb-3'>
										<Label className='form-label'>{props.t('I am available for a callback as of the following date')}</Label>
										<p className='text-answers'>{dateUtils.convertDateToDate(props.reportDateAvailableTimeFrom, INTERNATIONAL_DATE_FORMAT)}</p>
									</div>
								</Col>

								{/* date available time to */}
								<Col sm='12'>
									<div className='mb-3'>
										<Label className='form-label'>{props.t('I am available for a callback until the following date')}</Label>
										<p className='text-answers'>{dateUtils.convertDateToDate(props.reportDateAvailableTimeTo, INTERNATIONAL_DATE_FORMAT)}</p>
									</div>
								</Col>

								{/* available times for callback */}
								{props.reportPhoneAvailableTime && props.reportPhoneAvailableTime.length > 0 ? (
									<Col sm='12'>
										<div className='mb-3'>
											<Label className='form-label'>{props.t('Available times for callback')}</Label>
											{renderPhoneAvailableTime(props.reportPhoneAvailableTime, props.phoneAvailableTimes)}
										</div>
									</Col>
								) : null}
							</Row>
						</CardBody>
					</Collapse>
				</Card>
			</Col>
		</Row>
	);
};

export default memo(ReporterDetails);
