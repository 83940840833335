import { withNamespaces } from "react-i18next";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const AiRiskRightModal = function ({ 
    t, 
    handleModal, 
    showModal, 
    explanation 
}) {
    const processHtmlContent = (htmlContent) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');

        // Convert h1 to h2, h2 to h3, h3 to h4
        doc.querySelectorAll('h1, h2, h3').forEach(header => {
            switch (header.tagName) {
            case 'H1':
                header.outerHTML = `<h2>${header.innerHTML}</h2>`;
                break;
            case 'H2':
                header.outerHTML = `<h3>${header.innerHTML}</h3>`;
                break;
            case 'H3':
                header.outerHTML = `<h4>${header.innerHTML}</h4>`;
                break;
            default:
                break;
            }
        });

        return doc.body.innerHTML;
    };

    const processedExplanation = processHtmlContent(explanation);

    return (
        <div>
            <Modal isOpen={showModal} toggle={handleModal} size="xl">
                <ModalHeader toggle={handleModal}>
                    {t("Description")}
                </ModalHeader>
                
                <ModalBody dangerouslySetInnerHTML={{ __html: processedExplanation }} />

                <ModalFooter>
                    <Button variant="secondary" onClick={handleModal}>
                        {t("Close")}
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default withNamespaces()(
    AiRiskRightModal
);
