import { withNamespaces } from "react-i18next";
import { Text, View } from "@react-pdf/renderer";
import styles from "../../Styles";
import DateUtils from "src/services/utils/DateUtils";
import { INTERNATIONAL_DATE_FORMAT } from "src/components/constants";

const Risks = ({
    t,
    risks
}) => {
    const dateUtils = new DateUtils();

    return (
        <View break>
            <View style={styles.sectionSeparator}>
                <Text>{t('RISKS')}</Text>
            </View>

            <View style={styles.section}>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColHeader}>
                            <Text style={{
                                ...styles.tableCell,
                                ...styles.fontBold
                            }}>{t('Rating')}</Text>
                        </View>
                        <View style={{
                            ...styles.tableColHeader,
                            ...styles.borderRightNone
                        }}>
                            <Text style={{
                                ...styles.tableCell,
                                ...styles.fontBold
                            }}>{t('#')}</Text>
                        </View>
                        <View style={{
                            ...styles.tableColHeader,
                            ...styles.borderRightNone
                        }}>
                            <Text style={{
                                ...styles.tableCell,
                                ...styles.fontBold
                            }}>{t('Category')}</Text>
                        </View>
                        <View style={{
                            ...styles.tableColHeader,
                            ...styles.borderRightNone
                        }}>
                            <Text style={{
                                ...styles.tableCell,
                                ...styles.fontBold
                            }}>{t('Subcategory')}</Text>
                        </View>

                        <View style={{
                            ...styles.tableColHeader,
                            ...styles.borderRightNone
                        }}>
                            <Text style={{
                                ...styles.tableCell,
                                ...styles.fontBold
                            }}>{t('Risk Manager')}</Text>
                        </View>

                        <View style={{
                            ...styles.tableColHeader,
                            ...styles.borderRightNone
                        }}>
                            <Text style={{
                                ...styles.tableCell,
                                ...styles.fontBold
                            }}>{t('Risk Owner')}</Text>
                        </View>

                        <View style={{
                            ...styles.tableColHeader,
                            ...styles.borderRightNone
                        }}>
                            <Text style={{
                                ...styles.tableCell,
                                ...styles.fontBold
                            }}>{t('Mitigation Tasks')}</Text>
                        </View>

                        <View style={{
                            ...styles.tableColHeader,
                            ...styles.borderRightNone
                        }}>
                            <Text style={{
                                ...styles.tableCell,
                                ...styles.fontBold
                            }}>{t('Created On')}</Text>
                        </View>
                    </View>

                    {risks.map((risk, i) => {
                        const rowStyle = {
                            ...styles.tableRow,
                            ...(((i + 1) >= risks.length) && {...styles.tableLastRow})
                        }

                        return (
                            <View style={rowStyle} key={i}> 
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        
                                    </Text>
                                </View>

                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        R{risk.secondary_id}
                                    </Text>
                                </View>

                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        {risk.category.name}
                                    </Text>
                                </View>

                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        {risk.category?.parent?.name}
                                    </Text>
                                </View>

                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        {risk.manager}
                                    </Text>
                                </View>

                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        {risk.owner_name}
                                    </Text>
                                </View>

                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        {risk.tasks.map((t) => `T${t.secondary_id}`).join(', ')}
                                    </Text>
                                </View>

                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        {dateUtils.convertTimeStampToDate(risk.created_at, INTERNATIONAL_DATE_FORMAT)}
                                    </Text>
                                </View>
                            </View>
                        )
                    })}
                </View>
            </View>
        </View>
    )
};

export default withNamespaces()(Risks);

