
import { withNamespaces } from "react-i18next";
import { memo, useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import "./style.scss";
import DataProtectionHardwareService from "../../../apis/DataProtectionHardwareService";

const HandoverModal = ({ t, modal, setModal, users, id }) => {
  const toggle = () => setModal(!modal);

  // SELECTED
  const [selectedUser, setSelectedUser] = useState("");
  const [handOverDate, setHandoverDate] = useState("");

  // ERROR MESSAGES
  const [selectedUserError, setSelectedUserError] = useState("");
  const [handOverDateError, setHandoverDateError] = useState("");

  // ON CHANGES

  const onHandoverDateChange = (event) => {
    const { value } = event.target;
    setHandoverDate(!value ? null : value);
    setHandoverDateError(value ? "" : t("This field is required"));
  };

  const onSelectedUserChange = (event) => {
    const { value } = event.target;
    setSelectedUser(!value ? null : value);
    setSelectedUserError(value ? "" : t("This field is required"));
  };

  useEffect(() => {
    if(modal) return undefined
    setHandoverDate("");
    setHandoverDateError("");
    setSelectedUser("");
    setSelectedUserError("");
  })
  // Handle Delete
  const hanoverHardware = useMutation({
    mutationFn: async (data) => {
      const service = DataProtectionHardwareService.getInstance();
      
      return await service.handoverHardware(
        id,
        data
      );
    },
    onSuccess: () => {
      toast(t("Hardware successfully handed over to selected user"), {
        type: "success",
      });

      setHandoverDate("");
      setHandoverDateError("");
      setSelectedUser("");
      setSelectedUserError("");

    },
    onError: (error) => {
      if (process.env.NODE_ENV === 'development') console.error(error);

      toast(t("An error occured while handing over hardware."), {
        type: "error",
      });

      setHandoverDate("");
      setHandoverDateError("");
      setSelectedUser("");
      setSelectedUserError("");
    },
  });

  const handleSubmit = () => {
    // Validation Logic
    if (
      selectedUserError ||
      handOverDateError
    ) {
      toast.error(t("All fields are required"));
      setHandoverDateError(
        !handOverDate ? t("This field is required") : ""
      );
      setSelectedUserError(
        !selectedUser ? t("This field is required") : ""
      );
      return;
    }

    // Perform deletion action
    hanoverHardware.mutate({handoverTo: Number(selectedUser), handoverAt: handOverDate})
    // Close the modal
    toggle();
  };

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{t("Handover Hardware")}</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="handoverDate">{t("Handover Date") + ":"}</Label>
              <Input
                name="hanoverDate"
                id="handoverDate"
                type="date"
                onChange={onHandoverDateChange}
              />
              <div className="error-message">{handOverDateError}</div>
            </FormGroup>
            <br></br>
            <FormGroup>
              <Label for="handoverTo">{t("Hanover To") + ":"}</Label>
              <Input
                type="select"
                name="hanoverTo"
                id="handoverTo"
                onChange={onSelectedUserChange}
              >
                <option value="">{t("Select user")}</option>
                {users.map((user) => {
                  return (
                    <option key={user.value} value={user.value}>
                      {user.label}
                    </option>
                  );
                })}
              </Input>
              <div className="error-message">{selectedUserError}</div>
            </FormGroup>
            <br></br>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>{t("Cancel")}</Button>{" "}
          <Button color="primary" disabled={handOverDateError || selectedUserError || !selectedUser || !handOverDate} onClick={handleSubmit}>
            {t("Save")}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default withNamespaces()(withRouter(memo(HandoverModal)));