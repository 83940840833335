import { ajax } from "rxjs/ajax";
import { of, from } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { saveAs } from "file-saver";

import ResponseError from "../models/ResponseError";
import UrlUtils from "./utils/UrlUtils";

const REQUEST_METHOD_GET = "GET";
const REQUEST_METHOD_POST = "POST";
const REQUEST_METHOD_DELETE = "DELETE";
const REQUEST_METHOD_PUT = "PUT";
const HEADERS = {
  Accept: "application/json, application/xml, text/play, text/html, *.*",
  "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
};

const JSON_HEADERS = {
  Accept: "application/json, application/xml, text/play, text/html, *.*",
  "Content-Type": "application/json",
};

const UPLOAD_HEADERS = {};

export default class Request {

  constructor() {
    this.urlUtils = new UrlUtils();
  }

  sendRequest(
    url,
    method,
    data,
    headers
  ){
    return ajax({
      url: url,
      method: method,
      headers: headers,
      body: data,
    }).pipe(
      map((response) => {
        return response.response;
      }),
      catchError((error) => {
        return of(this.getErrorResponse(error));
      })
    );
  }

  sendJSON(
    url,
    method,
    data,
    headers
  ){
    return ajax({
      url: url,
      method: method,
      headers: headers,
      crossDomain: true,
      body: data ? JSON.stringify(data) : null,
    }).pipe(
      map((response) => {
        return response.response;
      }),
      catchError((error) => {
        return of(this.getErrorResponse(error));
      })
    );
  }

  sendJSONZipFile(
    url,
    method,
    data,
    token,
    options
  ) {
    const xhr = new XMLHttpRequest();
    return from(
      new Promise((resolve, reject) => {
        xhr.open(method, url, true);
        xhr.setRequestHeader("Content-type", "application/json");
        if (token) {
          xhr.setRequestHeader("Authorization", this.getBearerToken(token));
        }
        xhr.addEventListener("load", () => {
          const response = xhr.response;
          if (xhr.status === 200) {
            const disposition = xhr.getResponseHeader("content-disposition");
            const contentType = xhr.getResponseHeader("Content-Type");
            let fileName = "";
            if (disposition && disposition.indexOf("attachment") !== -1) {
              const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              const matches = filenameRegex.exec(disposition);
              if (matches != null && matches[1]) {
                fileName = matches[1].replace(/['"]/g, "");
              }
            }
            if (fileName) {
              const fileExtension = fileName.split(".").pop();
              if (fileExtension !== "zip") {
                fileName += ".zip";
              }
            }
            if (!fileName) {
              fileName = "download.zip";
            }
            const file = new Blob([xhr.response], {
              type: contentType || "application/json",
            });
            saveAs(file, fileName);
            resolve(file);
            return;
          }
          try {
            const resJSON = JSON.parse(response);
            reject(resJSON);
            return;
          } catch (e) {
            reject(e);
            return;
          }
        });

        if (options.responseType) {
          xhr.responseType = options.responseType || "";
        }

        xhr.send(JSON.stringify(data));
      })
    ).pipe(
      map((data) => data),
      catchError((error) => {
        return of(this.getErrorResponse(error));
      })
    );
  }

  getErrorResponse(error) {
    let message = "";
    let errorCode = "";

    if (error) {
      message = error.message || "";
      const response = error.response;

      if (response) {
        message = response.message || message;

        if(response.message && typeof response.message === 'object') {
          message = JSON.stringify(response.message);
        }
        
        errorCode = response.error;
      }
    }

    return new ResponseError(message, errorCode);
  }

  get(url, token) {
    delete HEADERS["Authorization"];

    if (token) {
      HEADERS["Authorization"] = this.getBearerToken(token);
    }

    return this.sendRequest(url, REQUEST_METHOD_GET, null, HEADERS);
  }

  post(url, data, token) {
    delete HEADERS["Authorization"];
    const dataString = this.urlUtils.getQueryStringFromObject(data);

    if (token) {
      HEADERS["Authorization"] = this.getBearerToken(token);
    }

    return this.sendRequest(url, REQUEST_METHOD_POST, dataString, HEADERS);
  }

  postJSON(url, data, token) {
    delete JSON_HEADERS["Authorization"];

    if (token) {
      JSON_HEADERS["Authorization"] = this.getBearerToken(token);
    }

    return this.sendJSON(url, REQUEST_METHOD_POST, data, JSON_HEADERS);
  }

  delete(url) {
    return this.sendRequest(url, REQUEST_METHOD_DELETE, null);
  }

  put(url, data, token) {
    delete HEADERS["Authorization"];
    const dataString = this.urlUtils.getQueryStringFromObject(data);

    if (token) {
      HEADERS["Authorization"] = this.getBearerToken(token);
    }

    return this.sendRequest(url, REQUEST_METHOD_PUT, dataString, HEADERS);
  }

  putJSON(url, data, token) {
    delete HEADERS["Authorization"];

    if (token) {
      HEADERS["Authorization"] = this.getBearerToken(token);
    }

    return this.sendJSON(url, REQUEST_METHOD_PUT, data, HEADERS);
  }

  getBearerToken(token) {
    return "Bearer " + token;
  }

  upload(url, data, token) {
    delete UPLOAD_HEADERS["Authorization"];

    if (token) {
      UPLOAD_HEADERS["Authorization"] = this.getBearerToken(token);
    }

    return this.sendRequest(url, REQUEST_METHOD_POST, data, UPLOAD_HEADERS);
  }

  downloadZip(
    url,
    method,
    data,
    token,
    options
  ) {
    return this.sendJSONZipFile(url, method, data, token, options);
  }
}
