import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";

import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { withNamespaces } from "react-i18next";

import Breadcrumbs from '../../../components/Common/Breadcrumb';

import HTMLContent from '../../../components/Common/HTMLContent';

import reportAIcon from '../../../assets/images/report_anonymously.svg';
import reportCIcon from '../../../assets/images/report_confidently.svg';

class AdminReportingChannel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : "SIP", link : "/" },
                { title : this.props.t("Home"), link : "#" },
            ]
        }
    }

    render() {
        
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title={ this.props.Organization.title_channel_report_tp } breadcrumbItems={this.state.breadcrumbItems} />
                        <Row>
                            <Col className="col-12 col-md-6">
                                <Card>
                                    <CardBody style={{
                                        minHeight: "140px"
                                    }}>
                                        <div className="d-flex">
                                            <div className="flex-1 overflow-hidden">
                                                <h4 className="mb-2">{this.props.t("Report anonymously")}</h4>
                                                <p className="font-size-14 mb-2">
                                                    {this.props.t("Please add a case that was reported anonymously to you.")}
                                                </p>
                                            </div>
                                            <div className="text-primary">
                                                <img src={reportAIcon} alt="" />
                                            </div>
                                        </div>
                                    </CardBody>

                                    <CardBody className="border-top py-3">
                                        <div className="text-truncate">
                                            <Link to="report-anonymously">
                          <Button color="info" style={{background:this.props.Organization.primaryColor}}>

                                                    {this.props.t("Send A Report")}
                                                </Button>
                                            </Link>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col className="col-12 col-md-6">
                                <Card>
                                    <CardBody style={{
                                        minHeight: "140px"
                                    }}>
                                        <div className="d-flex">
                                            <div className="flex-1 overflow-hidden">
                                                <h4 className="mb-2">{this.props.t("Report confidentially")}</h4>
                                                <p className="font-size-14 mb-2">
                                                    {this.props.t("Please add a case that was reported confidentially to you.")}
                                                </p>
                                            </div>
                                            <div className="text-primary">
                                                <img src={reportCIcon} alt="" />
                                            </div>
                                        </div>
                                    </CardBody>

                                    <CardBody className="border-top py-3">
                                        <div className="text-truncate">
                                            <Link to="/admin/report-confidentially">
                          <Button color="info" style={{background:this.props.Organization.primaryColor}}>

                                                    {this.props.t("Send A Report")}
                                                </Button>
                                            </Link>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        {
                            this.props.Organization.intro ? 
                                <Row>
                                    <Col lg="12">
                                        <Card>
                                            <CardBody>
                                                <HTMLContent content={this.props.Organization.intro} />
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row> 
                            : null
                        }
                    </Container> 
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { user, token } = state.Login;
    const { Organization } = state;
    return { token, user, Organization };
}

export default withNamespaces()(withRouter(connect(mapStatetoProps, null)(AdminReportingChannel)));
