import React from 'react';
import { Row, Col, Form } from "react-bootstrap";
import Select from 'react-select';
import Accordion from 'react-bootstrap/Accordion';

const Supplier = ({ t, routerSettings, selectedEntity, analystAdminsList, loading , isAdmin}) => {

  const handleSelectChange = (field) => (selectedOption) => {
    selectedEntity(prevAssignments => ({
      ...prevAssignments,
      [field]: selectedOption
    }));
  };

  return (
    <Accordion defaultActiveKey="1" className="accordionBody" >
      <Accordion.Item eventKey="1">
        <Accordion.Header>{t("Supplier")}</Accordion.Header>
        <Accordion.Body>
        {loading ?    <div
            className="dt-field dt-skeleton"
            style={{ width: "100%", height: "100px" }}
          ></div>
          :
          <Row className="mb-3">
            <Col sm="4">
              <Form.Label>{t("Default owner")}</Form.Label>
              <Select
                options={analystAdminsList}
                value={routerSettings.defaultSupplierOwner}
                onChange={handleSelectChange('defaultSupplierOwner')}
                placeholder={t("Select...")}
                isDisabled={!isAdmin}
              />
            </Col>
            <Col sm="4">
              <Form.Label>{t("Assign risks to")}</Form.Label>
              <Select
                options={analystAdminsList}
                value={routerSettings.defaultSupplierRiskOwner}
                onChange={handleSelectChange('defaultSupplierRiskOwner')}
                placeholder={t("Select...")}
                isDisabled={!isAdmin}
              />
            </Col>
            <Col sm="4">
              <Form.Label>{t("Assign tasks to")}</Form.Label>
              <Select
                options={analystAdminsList}
                value={routerSettings.defaultSupplierTaskOwner}
                onChange={handleSelectChange('defaultSupplierTaskOwner')}
                placeholder={t("Select...")}
                isDisabled={!isAdmin}
              />
            </Col>
          </Row>}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default Supplier;
