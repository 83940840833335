import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import {
  Row,
  Col,
  Label,
  Input,
  FormGroup,
  Button,
  UncontrolledPopover,
  PopoverBody,
} from "reactstrap";
import Select from "react-select";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Accordion } from "react-bootstrap";
import { useMutation, useQuery } from "@tanstack/react-query";
import { customFonts } from "src/modules/3rd-party-management/constants";
import { toast } from "react-toastify";
import { TbLoader } from "react-icons/tb";
import CustomizationService from "src/modules/3rd-party-management/apis/CustomizationService";
import FontImage from "src/assets/images/font.svg";
import { reloadOrganizationActiveModuleSettingsFromServer } from "src/store/actions";

const TPDDCompanyFontsAndDisplayCustomization = (props) => {
  const { t, fetchOrganizationDetails, reloadActiveModuleSettings } = props;
  const details = fetchOrganizationDetails.data;
  const [fonts, setFonts] = useState([]);
  const [fontSize, setFontSize] = useState(details?.font_size);
  const [primaryColor, setPrimaryColor] = useState(details?.primary_color || '#5664d2');
  const [fontColor, setFontColor] = useState(details?.font_color);

  const [font, setFont] = useState({});

  /*********************************** FUNCTIONS ***************************************** */

  // Submit Information
  const handleSubmitMutation = useMutation({
    mutationFn: async () => {
      const payload = {
        fontFamily: font.value,
        fontSize: fontSize,
        primaryColor: primaryColor,
        fontColor: fontColor,
      };

      const service = CustomizationService.getInstance();
      return await service.editFonts(payload);
    },
    onSuccess: () => {
      reloadActiveModuleSettings();
      toast(t("Data saved successfully."), {
        type: "success",
      });
    },
    onError: () => {
      toast(t("Failed to save data."), {
        type: "error",
      });
    },
  });

  // Restore Information
  const restore = () => {
    const { font_size, primary_color, font_color } = details;
    setSelectedFont();
    setFontSize(font_size);
    setPrimaryColor(primary_color);
    setFontColor(font_color);
  };

  // Set Selected Font
  const setSelectedFont = () => {
    const selectedFont = fonts.filter(
      (font) => font.value === details.font_family
    )[0];
    setFont(selectedFont);
  };

  /*************************************** USE EFFECTS ****************************************** */
  useEffect(() => {
    setFonts(
      customFonts.map((f) => ({
        label: f.family,
        value: f.family,
      }))
    );
  }, []);

  useEffect(() => {
    if (details?.font_family && fonts && fonts?.length) {
      setSelectedFont();
    }
  }, [fonts]);

  /*************************************** VIEW ****************************************** */
  return (
    <Row>
      <div className="page-tpdd-customization">
        <Row>
          <Col className="d-flex align-items-start customization-image-col">
            <img src={FontImage} alt="Font and Display" />
          </Col>
          <Col className="customization-accordion-col">
            <Accordion defaultActiveKey="0">
              <Accordion.Item
                className="border-none cusom-accordion-item"
                eventKey="0"
              >
                <Accordion.Header className="custom-accordion-header">
                  <div className="p-1 custom-div-header">
                    {`3. ${t("Font And Display")}`}{" "}
                    <>
                      <span
                        id={`section-info-button-font`}
                        className="section-info-button"
                      >
                        <i className="ri-information-line" />
                      </span>

                      <UncontrolledPopover
                        placement="bottom"
                        trigger="hover"
                        target={`section-info-button-font`}
                      >
                        <PopoverBody>
                          <span>
                            {t(
                              "Use a font family per language as not all fonts support all languages"
                            )}
                          </span>
                        </PopoverBody>
                      </UncontrolledPopover>
                    </>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col lg="6" md="6" sm="12" className="mt-3">
                      <FormGroup>
                        <Label for="fontFamily">{t("Font Family") + ":"}</Label>
                        <Select
                          name="fontFamily"
                          value={font}
                          classNamePrefix="select2-selection"
                          options={fonts}
                          placeholder={t("Select")}
                          readOnly
                          isDisabled={
                            handleSubmitMutation.isLoading ||
                            fetchOrganizationDetails.isLoading ||
                            fetchOrganizationDetails.isFetching
                          }
                          menuPortalTarget={document.body}
                          onChange={(e) => setFont(e)}
                        />
                      </FormGroup>
                    </Col>

                    <Col lg="6" md="6" sm="12" className="mt-3">
                      <FormGroup>
                        <Label for="fontSize">
                          {t("Best Font Size") + ":"}
                        </Label>
                        <Input
                          name="primaryColor"
                          type="text"
                          disabled={
                            handleSubmitMutation.isLoading ||
                            fetchOrganizationDetails.isLoading ||
                            fetchOrganizationDetails.isFetching
                          }
                          value={fontSize}
                          onChange={(e) => setFontSize(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="6" md="6" sm="12" className="mt-3">
                      <FormGroup>
                        <Label for="primaryColor">
                          {t("Primary Color") + ":"}
                          <>
                            <span
                              id={`section-info-button-primary-color`}
                              className="section-info-button"
                            >
                              <i className="ri-information-line" />
                            </span>

                            <UncontrolledPopover
                              placement="bottom"
                              trigger="hover"
                              target={`section-info-button-primary-color`}
                            >
                              <PopoverBody>
                                <span>
                                  {t("The color is used for the menu.")}
                                </span>
                              </PopoverBody>
                            </UncontrolledPopover>
                          </>
                        </Label>
                        <Input
                          name="primaryColor"
                          type="color"
                          value={primaryColor}
                          disabled={
                            handleSubmitMutation.isLoading ||
                            fetchOrganizationDetails.isLoading ||
                            fetchOrganizationDetails.isFetching
                          }
                          onChange={(e) => setPrimaryColor(e.target.value)}
                        />
                      </FormGroup>
                    </Col>

                    <Col lg="6" md="6" sm="12" className="mt-3">
                      <FormGroup>
                        <Label for="primaryColor">
                          {t("Font Color") + ":"}
                        </Label>
                        <Input
                          name="fontColor"
                          type="color"
                          value={fontColor}
                          disabled={
                            handleSubmitMutation.isLoading ||
                            fetchOrganizationDetails.isLoading ||
                            fetchOrganizationDetails.isFetching
                          }
                          onChange={(e) => setFontColor(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Accordion.Body>
                <Row className="p-4">
                  <Col sm="12" className="d-flex justify-content-end">
                    <Button
                      onClick={restore}
                      color="primary"
                      type="button"
                      outline
                      className="me-2"
                      disabled={
                        handleSubmitMutation.isLoading ||
                        fetchOrganizationDetails.isLoading ||
                        fetchOrganizationDetails.isFetching
                      }
                    >
                      {t("Restore")}
                    </Button>

                    <Button
                      color="primary"
                      onClick={handleSubmitMutation.mutate}
                      type="submit"
                      disabled={
                        handleSubmitMutation.isLoading ||
                        fetchOrganizationDetails.isLoading ||
                        fetchOrganizationDetails.isFetching
                      }
                    >
                      {handleSubmitMutation.isLoading ? <TbLoader /> : t("Save")}
                    </Button>
                  </Col>
                </Row>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </div>
    </Row>
  );
};

const mapStatetoProps = (state) => {
  const { token } = state.Login;
  const organization = state.Organization;
  const App = state.App;
  const modules = state.Modules;
  return { organization, token, App, modules };
};

const mapDispachToProps = (dispach) => {
  return {
    reloadActiveModuleSettings: () =>
      dispach(reloadOrganizationActiveModuleSettingsFromServer()),
  };
};

export default withNamespaces()(
  withRouter(
    connect(
      mapStatetoProps,
      mapDispachToProps
    )(TPDDCompanyFontsAndDisplayCustomization)
  )
);
