import axios from 'axios';

import {
    API_BASE_URL
} from '../constants/index';

export default class SupplierReportsService {
	static instance;

	static getInstance() {
		if (!SupplierReportsService.instance) {
			SupplierReportsService.instance = new SupplierReportsService();
		}

		return SupplierReportsService.instance;
	}

    async list(supplierId, pageIndex, pageSize, params = {}){
        const response = await axios.get(`${API_BASE_URL}/supplier/${supplierId}/report/request/list`, {
            params  :   {
                ...params,
                pageIndex   :   pageIndex,
                pageSize    :   pageSize 
            },
            headers :   {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`
            },
        });
  
        return response.data.data || []
    }

    async toggleFlag(reportId, payload = {}){
        const response = await axios.put(`${API_BASE_URL}/report/toggle_flag`, {
            ...payload,
            reportCaseId    :   reportId,
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`,
            },
        });
  
        return response.data.data
    }

    async details(reportId, params = {}){
        const response = await axios.post(`${API_BASE_URL}/report/question_answers`, {
            ...params,
            report_case_id  :   reportId
        }, {
            params: {},
            headers: {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`,
            },
        });
  
        return response.data.data;
    }

    async fetchPDFData(reportId, params = {}){
        const response = await axios.post(`${API_BASE_URL}/report/pdf`, {
            ...params,
            report_case_id  :   reportId
        }, {
            params: {},
            headers: {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`,
            },
        });
  
        return response.data.data;
    }
}
