import {
  GET_GROUPS_SUCCESS,
  GET_CHATS_SUCCESS,
  GET_GROUPS_FAIL,
  GET_CHATS_FAIL,
  GET_CONTACTS_SUCCESS,
  GET_CONTACTS_FAIL,
  GET_MESSAGES_SUCCESS,
  GET_MESSAGES_FAIL,
  POST_ADD_MESSAGE_SUCCESS,
  POST_ADD_MESSAGE_FAIL,
  START_CHAT_FAIL,
  START_CHAT_SUCCESS,
  FINISH_CHAT_FAIL,
  FINISH_CHAT_SUCCESS,
  DELETE_CHAT_SUCCESS,
  CHANGE_CHATS_LOADING,
  CHANGE_MESSAGES_LOADING
} from "./actionTypes"

const INIT_STATE = {
  chats: {
    loading: true,
    list: []
  },
  groups: [],
  contacts: [],
  messages: {
    loading: false,
    list :[]
  },
  error: {},
  chatId: null
}

const Calendar = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_CHATS_LOADING:
      return {
        ...state,
        chats: {
          ...state.chats,
          loading: action.payload
        }
      }
    case GET_CHATS_SUCCESS:
      return {
        ...state,
        chats: {
          list: action.payload,
          loading: false
        }
      }
      
    case DELETE_CHAT_SUCCESS:
      return {
        ...state,
        chats: {
          loading: true,
          list: state.chats.filter((item) => item.id !== action.payload),
        }
      }

    case GET_CHATS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_GROUPS_SUCCESS:
      return {
        ...state,
        groups: action.payload,
      }

    case START_CHAT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case START_CHAT_SUCCESS:
      return {
        ...state,
        chatId: action.payload,
      }

    case FINISH_CHAT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case FINISH_CHAT_SUCCESS:
      return {
        ...state,
        chatId: null,
      }

    case GET_GROUPS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_CONTACTS_SUCCESS:
      return {
        ...state,
        contacts: action.payload,
      }

    case GET_CONTACTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_MESSAGES_SUCCESS:
      return {
        ...state,
        messages: {
          list: action.payload,
          loading: false
        }
      }

    case GET_MESSAGES_FAIL:
      return {
        ...state,
        messages: {
          ...state.messages,
          loading: false
        }
      }

    case CHANGE_MESSAGES_LOADING:
      return {
        ...state,
        messages: {
          ...state.messages,
          loading: action.payload
        }
      }

    case POST_ADD_MESSAGE_SUCCESS:
      const newList = [
        ...state.messages.list,
        action.payload
      ]
      return {
        ...state,
        messages: {
          ...state.messages,
          list: newList
        }, 
    }

    case POST_ADD_MESSAGE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default Calendar
