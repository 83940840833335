import { withNamespaces } from "react-i18next";

import DateUtils from "src/services/utils/DateUtils";

import { INTERNATIONAL_DATE_FORMAT } from "src/common/constants";
import {
  AvatarGroup,
  AvatarItem,
} from "src/modules/data-protection/components/avatar-group";

import React, { useEffect, useImperativeHandle, useState } from "react";

import { Col, Row } from "reactstrap";

import OverviewHeaderContent from "src/modules/data-protection/components/overview-header";

import { createSVGFromText } from "src/modules/data-protection/helpers/common";

import { General_STATUS_LIST } from "src/modules/data-protection/constants";

const OverviewHeader = (props) => {
  const { t, actionsRef, handleFetchHeaderInformation, selectedHeader } = props;

  const dateUtils = new DateUtils();

  const renderLoadingSection = () => {
    return (
      <Row>
        <Col sm="12">
          <div className="loading-section p-5">
            <Row className="mb-5">
              <Col sm="12" md="3">
                <div
                  className="dt-field dt-skeleton mb-1"
                  style={{ width: "40%" }}
                ></div>
                <div
                  className="dt-field dt-skeleton"
                  style={{ width: "70%" }}
                ></div>
              </Col>
              <Col sm="12" md="3">
                <div
                  className="dt-field dt-skeleton mb-1"
                  style={{ width: "40%" }}
                ></div>
                <div
                  className="dt-field dt-skeleton"
                  style={{ width: "70%" }}
                ></div>
              </Col>
              <Col sm="12" md="3">
                <div
                  className="dt-field dt-skeleton mb-1"
                  style={{ width: "40%" }}
                ></div>
                <div
                  className="dt-field dt-skeleton"
                  style={{ width: "70%" }}
                ></div>
              </Col>
              <Col sm="12" md="3">
                <div
                  className="dt-field dt-skeleton mb-1"
                  style={{ width: "40%" }}
                ></div>
                <div
                  className="dt-field dt-skeleton"
                  style={{ width: "70%" }}
                ></div>
              </Col>
            </Row>
            <Row>
              <Col sm="12" md="4">
                <div
                  className="dt-field dt-skeleton mb-1"
                  style={{ width: "100%" }}
                ></div>
              </Col>
              <Col sm="12" md="4">
                <div
                  className="dt-field dt-skeleton mb-1"
                  style={{ width: "100%" }}
                ></div>
              </Col>
              <Col sm="12" md="4">
                <div
                  className="dt-field dt-skeleton mb-1"
                  style={{ width: "100%" }}
                ></div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    );
  };

  const renderAssignedAnalysts = () => {
    const analysts = selectedHeader?.analyst_assigned_object || {};
    const result = [];
    const analyst = analysts;

    const initials = analyst?.name
      ?.split(" ")
      ?.map((part) => part.charAt(0))
      ?.join("");

    var xml = new XMLSerializer().serializeToString(
      createSVGFromText(initials)
    );

    result.push(
      <AvatarItem
        id={`overview-analyst-avatar`}
        title={`${analysts?.name}`}
        src={`data:image/svg+xml;charset=utf-8,${xml}`}
        alt=""
      />
    );

    if (analysts?.avatar && analysts?.avatar !== "") {
      return (
        <div className="d-flex justify-content-center align-items-center flex-column">
          <div className="avatar-xs ">
            <span
              className="avatar-title bg-soft-primary text-primary rounded-circle text-capitalize"
              style={{ overflow: "hidden" }}
            >
              <img
                alt={analysts?.name}
                src={analysts?.avatar}
                style={{
                  width: "40px",
                  height: "40px",
                  display: "block",
                  objectFit: "cover",
                }}
              />
            </span>
          </div>
          <p className="fs-6">{analysts?.name} </p>
        </div>
      );
    }
    return <AvatarGroup max={4}>{result}</AvatarGroup>;
  };

  const handleRenderStatus = (status) => {
    const statusData = General_STATUS_LIST[status?.toUpperCase()];

    if (!statusData) {
      return "---";
    }

    return (
      <div
        style={{
          minWidth: "82px",
          fontSize: "10px",
          fontWeight: 700,
          padding: "3px 8px",
          background: statusData.backgroundColor,
          opacity: statusData.opacity || 1,
          color: "#1D1D1D",
          borderRadius: "7px",
          whiteSpace: "nowrap",
          textAlign: "center",
        }}
        className="dt-task-status"
      >
        {t(statusData.title)}
      </div>
    );
  };

  useImperativeHandle(actionsRef, () => {
    return {
      reload: () => {
        handleFetchHeaderInformation.refetch();
      },
    };
  });

  const updated_on = selectedHeader?.updated_at;
  const created_on = selectedHeader?.created_at;
  const next_revision = selectedHeader?.next_revision;
  const status = selectedHeader?.status;

  return (
    <React.Fragment>
      {handleFetchHeaderInformation.isFetching ||
      handleFetchHeaderInformation.isLoading ||
      !selectedHeader ? (
        <>{renderLoadingSection()}</>
      ) : (
        <OverviewHeaderContent
          items={[
            [
              {
                title: t("Created on"),
                value: (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexFlow: "column wrap",
                    }}
                  >
                    <span>
                      {dateUtils.convertTimeStampToDate(
                        created_on,
                        INTERNATIONAL_DATE_FORMAT
                      )}
                    </span>
                    {updated_on && (
                      <span>
                        {t(
                          `${t(
                            "Updated on"
                          )}: ${dateUtils.convertTimeStampToDate(
                            updated_on,
                            INTERNATIONAL_DATE_FORMAT
                          )}`
                        )}
                      </span>
                    )}
                  </div>
                ),
              },
              {
                title: t("Next Revision"),
                value: dateUtils.convertTimeStampToDate(
                  next_revision,
                  INTERNATIONAL_DATE_FORMAT
                ),
              },
              {
                title: t("Status"),
                value: <>{status ? handleRenderStatus(status) : ""}</>,
              },
              {
                title: t("Assigned Analyst"),
                value: renderAssignedAnalysts(),
              },
            ],
          ]}
        />
      )}
    </React.Fragment>
  );
};

export default withNamespaces()(OverviewHeader);
