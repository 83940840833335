import {
    MODULE_TPDD_ACTION_TYPE_RESET_QUESTIONNAIRE_STATE,
    MODULE_TPDD_ACTION_TYPE_UPDATE_QUESTIONNAIRE_PAYLOAD,
    MODULE_TPDD_ACTION_TYPE_UPDATE_QUESTIONNAIRE_UNSAVED_STEPS_COUNT,
    MODULE_TPDD_ACTION_TYPE_SAVE_QUESTIONNAIRE_CURRENT_STATE,
    MODULE_TPDD_ACTION_TYPE_UPDATE_QUESTIONNAIRE_ALL_QUESTIONS_ANSWERED_STATE,
    MODULE_TPDD_ACTION_TYPE_UPDATE_QUESTIONNAIRE_REPORT_CASE_ID,
    MODULE_TPDD_ACTION_TYPE_UPDATE_LATEST_QUESTIONNAIRE_SAVE_REQUEST_STATUS
} from "./actionTypes";

export const moduleTPDDResetQuestionnaireState = () => {
    return {
        type    :   MODULE_TPDD_ACTION_TYPE_RESET_QUESTIONNAIRE_STATE,
        payload :   null,
    };
};

export const moduleTPDDUpdateQuestionnaireLatestPayload = (payload) => {
    return {
        type    :   MODULE_TPDD_ACTION_TYPE_UPDATE_QUESTIONNAIRE_PAYLOAD,
        payload :   payload,
    };
};

export const moduleTPDDUpdateQuestionnaireUnsavedStepsCount = (payload) => {
    return {
        type    :   MODULE_TPDD_ACTION_TYPE_UPDATE_QUESTIONNAIRE_UNSAVED_STEPS_COUNT,
        payload :   payload,
    };
};

export const moduleTPDDUpdateQuestionnaireReportCaseId = (payload) => {
    return {
        type    :   MODULE_TPDD_ACTION_TYPE_UPDATE_QUESTIONNAIRE_REPORT_CASE_ID,
        payload :   payload,
    };
};

export const moduleTPDDUpdateQuestionnaireAllQuestionsAnsweredState = (payload) => {
    return {
        type    :   MODULE_TPDD_ACTION_TYPE_UPDATE_QUESTIONNAIRE_ALL_QUESTIONS_ANSWERED_STATE,
        payload :   payload,
    };
};

export const moduleTPDDSaveQuestionnaireCurrentState = (payload) => {
    return {
        type    :   MODULE_TPDD_ACTION_TYPE_SAVE_QUESTIONNAIRE_CURRENT_STATE,
        payload :   payload,
    };
};

export const moduleTPDDUpdateLatestQuestionnaireSaveStatus = (payload) => {
    return {
        type    :   MODULE_TPDD_ACTION_TYPE_UPDATE_LATEST_QUESTIONNAIRE_SAVE_REQUEST_STATUS,
        payload :   payload,
    };
};