import React from "react";
import { withNamespaces } from "react-i18next";
import { View, StyleSheet, Image, Svg, Path, Defs, LinearGradient, Stop, Text } from "@react-pdf/renderer";

const FirstPageHeader = ({ t, backgroundColor, logo, startColor, stopColor }) => {
    const styles = StyleSheet.create({
        header: {
            backgroundColor,
            color: "white",
            textAlign: "center",
            position: "relative",
            height: 180
        },
        logo: {
            position: "absolute",
            left: 50,
            bottom: 30,
            width: 100,
            height: 25,
            gap: 11
        },
        headerText: {
            position: "absolute",
            fontSize: 18,
            fontWeight: "bold",
            left:50,
            bottom: 6
        },
    });

    const GradientBackground = () => (
        <Svg width="468" height="187">
            <Path d="M463.919 137.759L462.836 135.154L461.631 132.567L460.305 129.999L458.861 127.453L457.302 124.931L455.631 122.434L453.854 119.965L451.974 117.524L449.995 115.113L447.925 112.734L445.767 110.387L443.528 108.073L441.214 105.794L438.831 103.55L436.387 101.34L433.888 99.1657L431.341 97.0263L428.755 94.9215L426.135 92.8508L423.49 90.8134L420.828 88.8082L418.155 86.834L415.48 84.8892L412.81 82.9721L410.151 81.0809L407.513 79.2134L404.9 77.3672L402.32 75.5399L399.78 73.7288L397.284 71.9309L394.841 70.1435L392.453 68.3633L390.127 66.5872L387.867 64.812L385.677 63.0343L383.559 61.2507L381.518 59.4579L379.555 57.6526L377.672 55.8315L375.87 53.9912L374.15 52.1288L372.512 50.2411L370.955 48.3253L369.477 46.3787L368.077 44.3987L366.752 42.3831L365.5 40.3298L364.315 38.2371L363.196 36.1034L362.135 33.9275L361.129 31.7085L360.171 29.446L359.255 27.1397L358.375 24.7898L357.524 22.3969L356.694 19.9621L355.877 17.4869L355.064 14.9739L354.246 12.4258L353.413 9.84626L352.556 7.23931L351.663 4.60964L350.725 1.96239L349.992 0L0.470337 0V187H461.181L462.014 185.25L463.121 182.682L464.122 180.097L465.014 177.496L465.794 174.88L466.46 172.251L467.009 169.611L467.44 166.962L467.75 164.305L467.937 161.642L468 158.975L467.937 156.307L467.747 153.639L467.43 150.974L466.984 148.313L466.409 145.659L465.706 143.013L464.876 140.38L463.919 137.759Z" fill="url('#myLinearGradient')"/>
            <Defs>
                <LinearGradient id="myLinearGradient">
                    <Stop stopColor={startColor}/>
                    <Stop offset="1" stopColor={stopColor}/>
                </LinearGradient>
            </Defs>
        </Svg>
    )

    return (
        <View style={styles.header}>
            <GradientBackground />
            <Image style={styles.logo} src={logo || {}} />
            <Text style={styles.headerText}>
                {(t('Third party due diligence report')).toUpperCase()}
            </Text>
        </View>
    );
};

export default withNamespaces()(FirstPageHeader);
