import { memo, useEffect, useState } from 'react';
import {Nav, NavItem, NavLink} from 'reactstrap';
import { useHistory, useLocation } from 'react-router-dom';


const Tabs = memo(({t}) => {
    const [activeTab, setActiveTab] = useState("");
    const history = useHistory();
    const location = useLocation();

    const isActiveTab = (currentTab, tab) => currentTab === tab;

    const tabsLinks = [
        {
            key     :   "basic-information",
            title   :   t("Basic Information")
        },
        {
            key     :   "internal-comments",
            title   :   t("Internal Comments")
        },
        {
            key     :   "processing-activity",
            title   :   t("Processing Activity")
        },
        {
            key     :   "dpia",
            title   :   t("DPIA")
        },  
        {
            key     :   "monitoring",
            title   :   t("Monitoring")
        },
        {
            key     :   "related-incident",
            title   :   t("Related Incidents")
        },
          
        {
            key     :   "risk-assessment",
            title   :   t("Risk Assessment")
        },
        {
            key     :   "task-management",
            title   :   t("Task Management")
        },
        {
            key     :   "dpa",
            title   :   t("Data Protection Authority")
        },
        {
            key     :   "history-log",
            title   :   t("History Log")
        },
        
    ];

    useEffect(() => {
        setActiveTab((new URLSearchParams(location.search)).get('tab'));
    }, [ location.search ]);

    useEffect(() => {
        if(!(new URLSearchParams(location.search)).get('tab')){
            // history.push({
            //     search : '?tab=overview'
            // })
            history.push('?tab=basic-information');
        }
    }, []);

    return (
        <div style={{
            backgroundColor: '#D1D2D6'
        }}>
            <Nav tabs className="custom-style-1">
                {
                    tabsLinks.map((link) => {
                        return (
                            <NavItem className={isActiveTab(activeTab, link.key) ? 'active': ''} key={link.key}>
                                <NavLink
                                    className={isActiveTab(activeTab, link.key) ? 'active': ''}
                                    // onClick={() => history.push({
                                    //     search : '?tab=' + link.key
                                    // })}
                                    onClick={() => history.push(`?tab=${link.key}`)}
                                    >

                                    {t(link.title)}
                                </NavLink>
                            </NavItem>
                        )
                    })
                }
            </Nav>
        </div>
	);
});

export default Tabs;
