import DOMPurify from 'dompurify';

export default class StringUtils {
    sortByAlphabet(firstString, secondString) {
        const firstLowercase = firstString.toLowerCase();
        const secondLowercase = secondString.toLowerCase();

        if (firstLowercase < secondLowercase) {
            return -1;
        }

        if (firstLowercase > secondLowercase) {
            return 1;
        }

        return 0;
    }
    
    getTextBetweenParentheses(value) {
        if (value && typeof value === 'string') {
            return value.substring(value.lastIndexOf('(') + 1, value.lastIndexOf(')'));
        }
        
        return '';
    }

    sanitizeHTML(contentHTML) {
        const allowTags = ['b', 'strong', 'p', 'ul', 'li', 'ol', 'em', 'u', 'span'];
        return DOMPurify.sanitize(contentHTML, {ALLOWED_TAGS: allowTags});
    }

    capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
      };
}
