import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import React, { useState, useEffect, memo } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import defaultImage from "src/modules/data-protection/assets/images/defaultNewsImage.svg";
import DataProtectionNewsService from "../../../../../../apis/NewsService";
import SingleNews from "../components/single-news";
import MonitoringDetails from "../components/details";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import { Col, Row, Spinner } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import overlayFactory from "react-bootstrap-table2-overlay";
import { EMPTY_LIST } from "src/common/constants";

const DataProtectionLatestNewsList = ({ t, assetId }) => {
  const [news, setNews] = useState([]);
  const [selectedNewsId, setSelectedNewsId] = useState(null);

  const [tableFilters, setTableFilters] = useState({
    pageIndex: 1,
    pageSize: 25,
    sortField: null,
    sortOrder: null,
  });

  const [tableResultTotalCount, setTableResultTotalCount] = useState(0);

  // Functions
  // List
  const listOfNews = useQuery({
    queryKey: ["latestNews", tableFilters],
    queryFn: async () => {
      const service = DataProtectionNewsService.getInstance();
      const response = await service.list(
        assetId,
        tableFilters.pageIndex,
        tableFilters.pageSize,
        {
          ...(tableFilters.sortField && { sortField: tableFilters.sortField }),
          ...(tableFilters.sortField && { sortOrder: tableFilters.sortOrder }),
        }
      );

      return response || [];
    },
    cacheTime: 0,
    refetchOnWindowFocus: true,
    onError(error) {
      if (process.env.NODE_ENV === "development") console.error(error);
      toast(t("error while fetchinig news"), {
        type: "error",
      });
    },
  });

  // Toggle Flag
  const onToggleNews = useMutation({
    mutationFn: async (payload) => {
      const service = DataProtectionNewsService.getInstance();

      return await service.toggleFlag(payload);
    },
    onSuccess: () => {
      listOfNews.refetch();
      toast(t("News toggled successfully."), {
        type: "success",
      });
    },
    onError: (error) => {
      if (process.env.NODE_ENV === "development") console.error(error);

      toast(t("An error occurred while updating your request."), {
        type: "error",
      });
    },
  });

  const handleTableChange = (type, data) => {
    switch (type) {
      case "pagination":
        const { page, sizePerPage } = data;
        setTableFilters({
          ...tableFilters,
          pageIndex: page,
          pageSize: sizePerPage,
        });
        break;

      case "sort": {
        const { sortField, sortOrder } = data;

        if (sortField === "operating_system_name") {
          setTableFilters({
            ...tableFilters,
            sortField: "os",
            sortOrder: sortOrder,
          });
        } else if (sortField === "analyst_assigned_name") {
          setTableFilters({
            ...tableFilters,
            sortField: "assignedAnalyst",
            sortOrder: sortOrder,
          });
        } else {
          setTableFilters({
            ...tableFilters,
            sortField: sortField,
            sortOrder: sortOrder,
          });
        }
        break;
      }
      default:
        return false;
    }
  };

  const NoDataIndication = () =>
    listOfNews.isFetched && !listOfNews.length ? (
      <div className="alert m-0" role="alert">
        <p
          style={{
            textAlign: "center",
            marginBottom: 0,
          }}
        >
          {t(EMPTY_LIST)}
        </p>
      </div>
    ) : (
      <></>
    );

  useEffect(() => {
    if (listOfNews.isLoading || onToggleNews.isLoading) return;
    if (listOfNews.data) {
      setTableResultTotalCount(Number(listOfNews.data.itemsCount) || 0);
    }
    setNews(listOfNews?.data?.news || []);
  }, [listOfNews?.data]);

  const tableColumns = [
    {
      dataField: "id",
      text: "",
      sort: false,
      formatter: (cellContent, row) => {
        return (
          <SingleNews
            t={t}
            image={defaultImage}
            selectedNews={row}
            onFlagNews={() => onToggleNews.mutate(row?.id)}
            isSelected={row?.id === selectedNewsId}
            onImageClick={() => setSelectedNewsId(row?.id)}
          />
        );
      },
    },
  ];

  return news?.length > 0 ? (
    selectedNewsId ? (
      <MonitoringDetails
        t={t}
        id={selectedNewsId}
        setSelectedNewsId={setSelectedNewsId}
        onFlagNews={(id) => onToggleNews.mutate(id)}
      />
    ) : (
      <Row className="p-4">
        <Col sm="12">
          <PaginationProvider
            pagination={paginationFactory({
              custom: true,
              page: tableFilters.pageIndex,
              sizePerPage: tableFilters.pageSize,
              totalSize: tableResultTotalCount,
              withFirstAndLast: false,
              alwaysShowAllBtns: true,
              prePageText: (
                <span>
                  <i className="ri-arrow-left-s-line"></i> {t("Back")}
                </span>
              ),
              nextPageText: (
                <span>
                  {t("Next")} <i className="ri-arrow-right-s-line"></i>
                </span>
              ),
              prePageTitle: t("Pre page"),
              firstPageTitle: t("Next page"),
              showTotal: true,
              hideSizePerPage: false,
              sizePerPageList: [
                {
                  text: "25",
                  value: 25,
                },
                {
                  text: "50",
                  value: 50,
                },
              ],
            })}
          >
            {({ paginationProps, paginationTableProps }) => (
              <React.Fragment>
                <Row>
                  <Col sm="12">
                    <BootstrapTable
                      remote={{
                        pagination: true,
                        filter: false,
                        sort: true,
                        cellEdit: false,
                        search: false,
                      }}
                      loading={listOfNews.isFetching || listOfNews.isLoading}
                      overlay={overlayFactory({
                        spinner: (
                          <Spinner
                            animation="border"
                            variant="primary"
                            size="md"
                          />
                        ),
                        text: "Loading...",
                      })}
                      onTableChange={handleTableChange}
                      defaultSorted={[]}
                      keyField={"id"}
                      responsive
                      bordered={false}
                      data={news || []}
                      striped={false}
                      columns={tableColumns}
                      wrapperClasses="table-responsive"
                      classes={"table tpdd-table"}
                      headerWrapperClasses={"thead-light"}
                      style={{
                        overflowX: "auto",
                      }}
                      noDataIndication={() => <NoDataIndication />}
                      {...paginationTableProps}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col sm="12" md="6">
                    <div className="tpdd-pagination-style-1">
                      <PaginationListStandalone {...paginationProps} />

                      <SizePerPageDropdownStandalone {...paginationProps} />
                    </div>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </PaginationProvider>
        </Col>
      </Row>
    )
  ) : (
    <p className="p-4">{t("The is no news at the moment")}</p>
  );
};

export default withNamespaces()(withRouter(memo(DataProtectionLatestNewsList)));
