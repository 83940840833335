import { withNamespaces } from "react-i18next";
import { useState, useEffect, memo } from "react";
import TopNewsMenu from "../../components/top-menu";
import DataProtectionLatestNewsList from "../../list";
import { Card } from "reactstrap";
import { withRouter } from "react-router-dom";

const DataProtectionLatestNews = ({ t }) => {

  const [breadcrumbs, _] = useState([
    { title: "SIP", link: "/" },
    { title: t("Data Protection"), link: "#" },
    { title: t("News Monitoring"), link: "#" },
  ]);

  return (
    <div className="">

        {/* <PageBody> */}
          <Card>
            {/* Top Menu */}
            <TopNewsMenu />

            {/* List */}
            <DataProtectionLatestNewsList />
          </Card>
        {/* </PageBody> */}
    </div>
  );
};

export default withNamespaces()(withRouter(memo(DataProtectionLatestNews)));
