import React, { memo, useEffect, useRef, useState } from "react";
import { withNamespaces } from "react-i18next";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import "./create-risk-modal.scss";
import CreateRiskModal from "src/modules/data-protection/pages/reporting/Components/RiskComponents/CreateRiskModal";
import { successNotification, errorNotification } from "src/store/actions.js";
import RiskAssessmentService from "src/modules/data-protection/apis/RiskAssessmentService";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useQuery } from "@tanstack/react-query";

const CreatingRiskModal = (props) => {
  const { t, modal, setModal, toggle, assetId } = props;
  // Create Risk Modal
  const [createRiskModalStatus, setCreateRiskModalStatus] = useState(false);

  const [currency, setCurrency] = useState(null);
  const [categories, setCategories] = useState([]);
  const [locations, setLocations] = useState([]);
  const [damages, setDamages] = useState(null);
  const [owners, setOwners] = useState(null);
  const [likelihoods, setLikelihoods] = useState(null);
  const [
    aiBasedRiskAssessmentModalStatus,
    setAiBasedRiskAssessmentModalStatus,
  ] = useState(false);

  // Functions
  const handleFetchRisksMatrix = useQuery({
    queryKey: ["data-protection-fetch-risks-matrix"],
    queryFn: async () => {
      const service = RiskAssessmentService.getInstance();

      return await service.fetchMatrix({ softwareId: assetId });
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      toast(t("An error occurred while fetching matrix."), {
        type: "error",
      });
    },
  });

  const handleFetchRisksCategories = useQuery({
    queryKey: ["data-protection-fetch-risks-categories"],
    queryFn: async () => {
      const service = RiskAssessmentService.getInstance();

      return await service.fetchCategories(assetId);
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      toast(t("An error occurred while fetching categories."), {
        type: "error",
      });
    },
  });

  const handleFetchRisksLocations = useQuery({
    queryKey: ["data-protection-fetch-risks-locations"],
    queryFn: async () => {
      const service = RiskAssessmentService.getInstance();

      return await service.fetchLocations(assetId);
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      toast(t("An error occurred while fetching locations."), {
        type: "error",
      });
    },
  });

  const handleFetchRisksOwners = useQuery({
    queryKey: ["data-protection-fetch-risks-owners"],
    queryFn: async () => {
      const service = RiskAssessmentService.getInstance();

      return await service.fetchOwners(assetId);
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      toast(t("An error occurred while fetching owners."), {
        type: "error",
      });
    },
  });

  useEffect(() => {
    if (handleFetchRisksMatrix.data && createRiskModalStatus) {
      setLikelihoods(handleFetchRisksMatrix.data.likelihoods);
      setCurrency(handleFetchRisksMatrix.data.currency);
      setDamages(
        handleFetchRisksMatrix.data.consequences.map((item) => {
          return {
            value: item.id,
            label:
              handleFetchRisksMatrix.data.currency +
              " " +
              Number(item.title).toLocaleString(
                localStorage.getItem("i18nextLng")
              ),
          };
        })
      );

      // calculating matrix colors for caption
      if (
        handleFetchRisksMatrix.data.matrix &&
        handleFetchRisksMatrix.data.ratings
      ) {
        const flattedMatrix = handleFetchRisksMatrix.data.matrix.flat();
        const items = [];

        for (const item of handleFetchRisksMatrix.data.ratings) {
          items.push({
            label: item.name,
            color: flattedMatrix.find((i) => i.name === item.name).color,
          });
        }
      }
    }
  }, [handleFetchRisksMatrix.data, createRiskModalStatus]);

  useEffect(() => {
    if (handleFetchRisksOwners.data && createRiskModalStatus) {
      setOwners(
        handleFetchRisksOwners.data.map((item) => {
          return {
            value: item.id,
            label: `${item.first_name} ${item.last_name}`,
          };
        })
      );
    }
  }, [handleFetchRisksOwners.data, createRiskModalStatus]);

  useEffect(() => {
    if (handleFetchRisksCategories.data && createRiskModalStatus) {
      setCategories(
        handleFetchRisksCategories.data.map((item) => {
          return {
            value: item.id,
            label: t(item.name),
            baseLabel: item.name,
            subCategories: item.sub_categories.map((sub) => {
              return {
                value: sub.id,
                baseLabel: sub.name,
                label: t(sub.name),
              };
            }),
          };
        })
      );
    }
  }, [handleFetchRisksCategories.data, createRiskModalStatus]);

  useEffect(() => {
    if (handleFetchRisksLocations.data && createRiskModalStatus) {
      setLocations(
        handleFetchRisksLocations.data.map((item) => {
          return {
            value: item.id,
            baseLabel: item.name,
            label: t(item.name),
          };
        })
      );
    }
  }, [handleFetchRisksLocations.data, createRiskModalStatus]);

  return (
    <>
      {/* Create Risk Modal */}
      <CreateRiskModal
        t={t}
        isOpen={createRiskModalStatus}
        close={() => setCreateRiskModalStatus(false)}
        successNotification={props.successNotification}
        errorNotification={props.errorNotification}
        authToken={props.token}
        reportId={assetId}
        reportIdKey={"software"}
        updateRisksList={() => {
          // handleFetchRisksQuery.refetch();
          return null;
        }}
        currency={currency}
        categories={categories}
        locations={locations}
        damages={damages}
        likelihoods={
          likelihoods
            ? likelihoods.map((l) => {
                return {
                  value: l.id,
                  label: l.title,
                };
              })
            : null
        }
        owners={owners}
        handleSetReportRiskField={() => console.log("ok")}
        module={"gdpr"}
        // aiBasedRiskAssessmentModalStatus={aiBasedRiskAssessmentModalStatus}
        // setAiBasedRiskAssessmentModalStatus={
        //   setAiBasedRiskAssessmentModalStatus
        // }
        reportLocation={null}
        reportCategories={[]}
        route={null}
        assetId={assetId}
      />

      <div>
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>
            <div style={{ display: "flex", alignItems: "center" }}>
              {t("Adding Risks")}
            </div>
          </ModalHeader>
          <ModalBody>
            <p>
              {t("Do you like to see AI based risk and task recommendations?")}
            </p>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              outline
              size="sm"
              style={{ minWidth: "115.5px", borderRadius: "6.86px" }}
              onClick={() => {
                setAiBasedRiskAssessmentModalStatus(false);
                toggle()
                setCreateRiskModalStatus(true);
              }}
            >
              {t("Add Manually")}
            </Button>
            <Button
              color="primary"
              size="sm"
              onClick={() => null}
              style={{ minWidth: "115.5px", borderRadius: "6.86px" }}
            >
              {t("Use AI")}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
};

const mapStatetoProps = (state) => {
  const { token } = state.Login;
  const { Organization } = state;
  return {
    user: state.Login.user,
    token,
    organization: Organization,
  };
};

export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
      successNotification,
      errorNotification,
    })(memo(CreatingRiskModal))
  )
);
