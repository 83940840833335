import {
    ACTION_CONFIRM_PROMPT_UPDATE_STATE,
    ACTION_CONFIRM_PROMPT_MAKE_READY_TO_SHOW,
    ACTION_CONFIRM_PROMPT_DISPLAY_PROMPT,
    ACTION_CONFIRM_PROMPT_HIDE_PROMPT,
    ACTION_CONFIRM_PROMPT_UPDATE_CONFIRM_STATUS,
    ACTION_CONFIRM_PROMPT_UPDATE_ALERT_PROPS
} from './actionTypes';

export const confirmPromptUpdateState = (status) => ({
    type        :   ACTION_CONFIRM_PROMPT_UPDATE_STATE,
    payload     :   status
});

export const confirmPromptMakeReadyToShow = (status) => ({
    type        :   ACTION_CONFIRM_PROMPT_MAKE_READY_TO_SHOW,
    payload     :   status
});

export const confirmPromptShow = (status) => ({
    type        :   ACTION_CONFIRM_PROMPT_DISPLAY_PROMPT,
    payload     :   status
});

export const confirmPromptHide = (status) => ({
    type        :   ACTION_CONFIRM_PROMPT_HIDE_PROMPT,
    payload     :   status
});

export const confirmPromptUpdateConfirmStatus = (status) => ({
    type        :   ACTION_CONFIRM_PROMPT_UPDATE_CONFIRM_STATUS,
    payload     :   status
});

export const confirmPromptUpdateAlertProps = (status) => ({
    type        :   ACTION_CONFIRM_PROMPT_UPDATE_ALERT_PROPS,
    payload     :   status
});