import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { Col, Container } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Navigator from "src/modules/data-protection/components/navigator";
import PageContent from "../../components/page-content";
import PageBody from "../../components/page-body";
import Tabs from "./tabs";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import TOMHome from "./tabs-content/home";
import OverviewHeader from "./components/overview-header";
import CommonService from "../../apis/CommonService";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import DataProtectionTomService from "../../apis/DataProtectionTomService";
import TaskManager from "./tabs-content/task-manager";
import TaskDetails from "src/modules/data-protection/pages/reporting/Components/TaskComponents/TaskDetails";

const TOM = (props) => {
  const { t } = props;
  const location = useLocation();

  const [selectedHeader, setSelectedHeader] = useState(null);

  const [detailPage, setDetailPage] = useState(null);
  const history = useHistory();

  // Analysts
  const [users, setUsers] = useState([]);
  const [selectedAssignedAnalyst, setSelectedAssignedAnalyst] = useState(null);
  const [updatingSelectedAssignedAnalyst, setUpdatingSelectedAssignedAnalyst] =
    useState(false);

  const [updatingSelectedStatus, setUpdatingSelectedStatus] = useState(false);

  const [updatingSelectedRivision, setUpdatingSelectedRivision] =
    useState(false);

  /********************************* NEXT REVISION *************************************** */
  const changeRevisionMutation = useMutation({
    mutationFn: async (revision) => {
      const service = DataProtectionTomService.getInstance();
      return await service.changeRevision({
        date: revision.date,
      });
    },
    onSuccess: () => {
      toast(t("Revision successfully changed."), {
        type: "success",
      });
    },
    onError: (error) => {
      toast(t("An error occurred while changing revision."), {
        type: "error",
      });
    },
    onMutate: () => {
      setUpdatingSelectedRivision(true);
    },
    onSettled: () => {
      setUpdatingSelectedRivision(false);
    },
  });

  const changeRevision = (newRevision) => {
    changeRevisionMutation.mutate(
      {
        date: newRevision,
      },
      {
        onSuccess: () => {
          handleFetchHeaderInformation.refetch();
        },
      }
    );
  };

  /********************************* STATUS *************************************** */
  const changeStatusMutation = useMutation({
    mutationFn: async ({ status }) => {
      const service = DataProtectionTomService.getInstance();
      return await service.changeStatus({
        status: status.value.toLowerCase(),
      });
    },
    onSuccess: () => {
      toast(t("Status successfully changed."), {
        type: "success",
      });
    },
    onError: (error) => {
      toast(t("An error occurred while changing status."), {
        type: "error",
      });
    },
    onMutate: () => {
      setUpdatingSelectedStatus(true);
    },
    onSettled: () => {
      setUpdatingSelectedStatus(false);
    },
  });

  const changeStatus = (newStatus) => {
    changeStatusMutation.mutate(
      {
        status: newStatus,
      },
      {
        onSuccess: () => {
          handleFetchHeaderInformation.refetch();
        },
      }
    );
  };

  /********************************* ASIGNED ANALYST *************************************** */
  const changeAssignedAnalystMutation = useMutation({
    mutationFn: async ({ analyst }) => {
      const service = DataProtectionTomService.getInstance();
      return await service.changeAssignedAnalyst({
        user: String(analyst.value),
      });
    },
    onSuccess: () => {
      handleFetchHeaderInformation.refetch();
      toast(t("Assigned analyst successfully changed."), {
        type: "success",
      });
    },
    onError: () => {
      toast(t("An error occurred while changing analyst."), {
        type: "error",
      });
    },
    onMutate: () => {
      setUpdatingSelectedAssignedAnalyst(true);
    },
    onSettled: () => {
      setUpdatingSelectedAssignedAnalyst(false);
    },
  });

  const changeAssignedAnalyst = (newAnalyst) => {
    changeAssignedAnalystMutation.mutate(
      {
        analyst: newAnalyst,
      },
      {
        onSuccess: () => {
          setSelectedAssignedAnalyst(newAnalyst);
        },
        onError: (e) => {
          console.log(e);
        },
      }
    );
  };

  /********************************* FETCH FUNCTIONS *************************************** */
  const handleFetchHeaderInformation = useQuery({
    queryKey: ["data-protection-tom-overview-header-contnet"],
    queryFn: async () => {
      const service = DataProtectionTomService.getInstance();
      return await service.fetchHeaderInfo();
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      toast(t("An error occurred while fetching overview content."), {
        type: "error",
      });
    },
  });

  const handleFetchUsersListQuery = useQuery({
    queryFn: async () => {
      const service = CommonService.getInstance();

      return await service.analystAdminsAndAnalysts();
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      if (process.env.NODE_ENV === "development") console.error(error);

      toast(t("An error occurred while fetching users."), {
        type: "error",
      });
    },
  });

  /************************************ USE EFFECTS ****************************************** */
  useEffect(() => {
    const { data } = handleFetchHeaderInformation;
    if (data) {
      const { analyst_assigned_object } = data;
      setSelectedHeader(data);
      if (analyst_assigned_object && !Array.isArray(analyst_assigned_object)) {
        setSelectedAssignedAnalyst({
          value: analyst_assigned_object.id,
          label: analyst_assigned_object.name,
        });
      }
    }
  }, [handleFetchHeaderInformation.data]);

  useEffect(() => {
    if (
      handleFetchUsersListQuery.data &&
      handleFetchUsersListQuery.data?.length
    ) {
      const users = handleFetchUsersListQuery.data.map((u) => {
        return {
          value: u.id,
          label: u.first_name + " " + u.last_name,
        };
      });
      setUsers(users);
    }
  }, [handleFetchUsersListQuery.data]);

  /********************************** RENDER TABS ****************************************** */
  const renderDetailsContent = () => {
    switch (detailPage?.type) {
      case "task":
        return (
          <div className="p-4">
            <TaskDetails
              {...detailPage?.data}
              back={() => {
                history.push(
                  `/admin/data-protection/tom/?tab=task-management`
                );
                setDetailPage(null);
              }}
            />
          </div>
        );

      default:
        return null;
    }
  };

  const renderContent = () => {
    const displayAnyItemDetails = renderDetailsContent();

    if (displayAnyItemDetails) {
      return displayAnyItemDetails;
    }

    const searchParams = new URLSearchParams(location.search);

    let result = null;

    switch (searchParams.get("tab")) {
      case "tom":
        result = (
          <TOMHome
            // Fetch Header
            handleFetchHeaderInformation={handleFetchHeaderInformation}
            // Analysts
            users={users}
            selectedAssignedAnalyst={selectedAssignedAnalyst}
            handleFetchUsersListQuery={handleFetchUsersListQuery}
            changeAssignedAnalyst={changeAssignedAnalyst}
            updatingSelectedAssignedAnalyst={updatingSelectedAssignedAnalyst}
            // Statuses
            changeStatus={changeStatus}
            updatingSelectedStatus={updatingSelectedStatus}
            // Revision
            changeRevision={changeRevision}
            updatingSelectedRivision={updatingSelectedRivision}
          />
        );
        break;
      case "task-management":
        result = (
          <div className="p-4 d-flex flex-column gap-4">
            <TaskManager
              // assetId={softwareId}
              onTaskSelected={(selectedTaskData) =>
                setDetailPage({
                  type: "task",
                  data: selectedTaskData,
                })
              }
            />
          </div>
        );
        break;
      default:
        break;
    }

    return (
      <React.Fragment>
        <Tabs t={t} />
        <div className="p-4 d-flex flex-column gap-4">
          <OverviewHeader
            selectedHeader={selectedHeader}
            handleFetchHeaderInformation={handleFetchHeaderInformation}
          />
        </div>
        {result}
      </React.Fragment>
    );
  };
  /********************************************* USE EFFECTS ******************************************** */
  useEffect(() => {
    if (!new URLSearchParams(location.search).get("page")) {
      setDetailPage(null);
    }
  }, [location.search]);

  return (
    <PageContent classNames="data-protection-software-details-page software-details-page">
      <Container fluid>
        <Col lg="12" className="mb-4">
          <Navigator
            backButtonOptions={() => {
              return {
                enable: false,
                // onClick: () => handleBack(),
                title: props.t("Back"),
                icon: <i className="ri-arrow-left-fill"></i>,
              };
            }}
            breadCrumbs={[
              { title: "SIP", link: "/" },
              {
                title: "TOM",
                link: `/admin/data-protection/tom?tab=tom`,
              },
            ]}
          />
        </Col>

        {/* page body */}
        <PageBody classNames="p-0">
          <div className="details-content-wrapper">{renderContent()}</div>
        </PageBody>
      </Container>
    </PageContent>
  );
};

const mapStatetoProps = (state) => {
  const { token } = state.Login;
  return {
    token,
  };
};
export default withNamespaces()(withRouter(connect(mapStatetoProps)(TOM)));
