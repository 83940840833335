import { withNamespaces } from "react-i18next";
import { memo, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
const Deletion = ({ t, modal, setModal, handleDelete, caseId }) => {
  const toggle = () => setModal(!modal);
 

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{t("Delete Activity")}</ModalHeader>
        <ModalBody>
          <p>{t("Are you sure?")}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>{t("Cancel")}</Button>{" "}
          <Button color="danger" onClick={() => handleDelete(caseId)}>
            {t("Delete")}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default withNamespaces()(withRouter(memo(Deletion)));
