import React from 'react'
import DefaultAvatar from "src/assets/images/users/def.png";

const Avatar = ({ src, alt }) => {
    return (
    <div className="avatar-xs ">
      <span
        className="avatar-title bg-soft-primary text-primary rounded-circle text-capitalize"
        style={{ overflow: "hidden" }}
      >
        <img
          alt={alt}
          src={src ?? DefaultAvatar}
          style={{
            width: "100%",
            height: "100%",
            display: "block",
            objectFit: "cover",
          }}
        />
      </span>
    </div>
  )}; 

  export default Avatar;