import { withNamespaces } from "react-i18next";
import DatePicker from "react-datepicker";
import * as datePickerLocales from "date-fns/locale";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "@tanstack/react-query";
import Select from 'react-select';

import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Spinner,
    Col,
    Row
} from "reactstrap";

import { Form } from "react-bootstrap";

import SupplierContactPersonService from "src/modules/3rd-party-management/apis/SupplierContactPersonService";
import SupplierService from "src/modules/3rd-party-management/apis/SupplierService";
import DateUtils from "src/services/utils/DateUtils";

const NewReportRequestModal = ({
    t,
    lng,
    supplierId,
    show,
    toggle,
    onSuccess,
    supplierType
}) => {
    const twoWeeksLaterDate = new Date();
    twoWeeksLaterDate.setDate(twoWeeksLaterDate.getDate() + 14);

    const [ formValidated, setFormValidated ] = useState(false);
    const [ people, setPeople ] = useState([]);
    const [ deadlineDate, setDeadlineDate ] = useState(twoWeeksLaterDate);
    const [ financialYears, setFinancialYears ] = useState({
        start: null,
        end: null
    })  
    const [ person, setPerson ] = useState(null);
    const [ creationFormInProcess, setCreationFormInProcess ] = useState(false);
    const [ faildValidations, setFaildValidations ] = useState([]); 

    const dateUtils = new DateUtils();

    const handleFetchPeopleList = useQuery({
		queryFn: async () => {
			const service = SupplierContactPersonService.getInstance();

            return await service.list(supplierId, {});
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			toast(t('An error occurred while fetching supplier contact list.'), {
				type: 'error',
			});
		},
	});

    const handleCreateNewReportRequestMutation = useMutation({
        mutationFn: async (payload) => {
            const service = SupplierService.getInstance();
            
            setCreationFormInProcess(true);

            return await service.createReportRequest(payload);
        },
        onSuccess: () => {
            onSuccess && onSuccess();

            toast(t("New report request created successfully."), {
                type: "success",
            });

            toggle();
        },
        onError: () => {
            toast(t("An error occurred while creating request."), {
                type: "error",
            });
        },
        onSettled: () => {
            setCreationFormInProcess(false);
        }
    });

    useEffect(() => {
        setPeople((handleFetchPeopleList?.data?.contactPersons || []).map((person) => {
            return {
                value   :   person.id,
                label   :   `${person.name} ${person.lastName}`
            }
        }));
    }, [ handleFetchPeopleList.data ]);

    useEffect(() => {
        if(!show){
            setPerson(null);
            setDeadlineDate(twoWeeksLaterDate);
            setFinancialYears({
                start: null,
                end: null
            });
            setFormValidated(false);
            setFaildValidations([]);
        }
    }, [show])

    const handleCreateRequest = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setFormValidated(true);

        const faildInputs = [];

        if(!person){
            faildInputs.push('person');
        }

        if(!deadlineDate){
            faildInputs.push('deadlineDate');
        }

        if(!financialYears.start){
            faildInputs.push('financialStartYear')
        }

        if(!financialYears.end){
            faildInputs.push('financialEndYear')
        }

        if(faildInputs.length > 0 || (
            dateUtils.getDiffInDays(financialYears.start, financialYears.end) < 365 ||
            dateUtils.getDiffInDays(financialYears.start, financialYears.end) > 366
        )
        ){
            setFaildValidations(faildInputs);
            return;
        }

        handleCreateNewReportRequestMutation.mutate({
            supplier            :   supplierId,
            responsiblePerson   :   person.value,
            deadlineDate        :   dateUtils.convertDateToDate(deadlineDate, 'YYYY-MM-DD'),
            financialStartYear  :   dateUtils.convertDateToDate(financialYears.start, 'YYYY-MM-DD'),
            financialEndYear    :   dateUtils.convertDateToDate(financialYears.end, 'YYYY-MM-DD'),
        });
    }

    const peopleListLoading = handleFetchPeopleList.isFetching || handleFetchPeopleList.isLoading;

    const isResponsiblePersonInputInValid = faildValidations.includes('person') || (formValidated && !person);
    const isDeadlineInputInValid = faildValidations.includes('deadline') || (formValidated && !deadlineDate);
    const startYearInvalid = faildValidations.includes('financialStartYear') || (formValidated && !financialYears.start);
    const endYearInvalid = faildValidations.includes('financialEndYear') || (formValidated && !financialYears.end);

    return (
        <Modal size="md" isOpen={ show }>
            <Form noValidate onSubmit={handleCreateRequest} autocomplete="off">
                <ModalHeader className="border-0" toggle={ toggle }>
                    {t("Request A New Report")}
                </ModalHeader>
                
                <ModalBody>
                    <Row className="mb-3">
                        <Col sm='12'>
                            <Form.Group controlId="responsiblePerson">
                                <Form.Label>
                                    { t('Responsible person') }
                                </Form.Label>

                                <Select
                                    placeholder={t('Select...')}
                                    name="responsiblePerson"
                                    isDisabled={ peopleListLoading }
                                    isLoading={ peopleListLoading }
                                    classNamePrefix='select2-selection'
                                    options={ people }
                                    value={ person }
                                    onChange={(e) => {
                                        setPerson(e);
                                        faildValidations.splice(faildValidations.findIndex((item) => item === 'person'), 1)
                                    }} 
                                />

                                <Form.Control.Feedback type="invalid" style={{ display: `${isResponsiblePersonInputInValid ? 'block' : 'none'}`}}> 
                                    { t('Responsible person not selected') }
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col sm='12'>
                            <Form.Group controlId="deadline">
                                <Form.Label>
                                    { t('Deadline') }
                                </Form.Label>

                                <DatePicker 
                                    locale={datePickerLocales[lng]}
                                    name="deadline"
                                    id="deadline"
                                    className="form-control"
                                    selected={ deadlineDate }
                                    showTimeSelect={ false }
                                    minDate={new Date()}
                                    onChange={(date) => {
                                        setDeadlineDate(date);
                                        faildValidations.splice(faildValidations.findIndex((item) => item === 'deadlineDate'), 1)
                                    }}
                                /> 

                                <Form.Control.Feedback type="invalid" style={{ display: `${isDeadlineInputInValid ? 'block' : 'none'}`}}>
                                    { t('Deadline is required') }
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col sm='6'>
                            <Form.Group controlId="financialStartYear">
                                <Form.Label>
                                    { t('Start of Financial Year') }
                                </Form.Label>
                                <DatePicker 
                                    placeholderText={t('Click to select a date')}
                                    locale={datePickerLocales[lng]}
                                    name="financialStartYear"
                                    id="financialStartYear"
                                    className="form-control"
                                    selected={ financialYears.start }
                                    showTimeSelect={ false }
                                    minDate={new Date('2022-01-01')}
                                    maxDate={new Date('2025-12-31')}
                                    onChange={(date) => {
                                        setFinancialYears((currentState) => {
                                            return {
                                                ...currentState,
                                                start: date
                                            }
                                        });
                                        faildValidations.splice(faildValidations.findIndex((item) => item === 'financialStartYear'), 1);
                                    }}
                                /> 
                                <Form.Control.Feedback type="invalid" style={{ display: `${startYearInvalid ? 'block' : 'none'}`}}>
                                    { t('The start year is required') }
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col sm='6'>
                            <Form.Group controlId="financialEndYear">
                                <Form.Label>
                                    { t('End of Financial Year') }
                                </Form.Label>
                                <DatePicker 
                                    placeholderText={t(!financialYears.start ? 'Select the start year first' : 'Click to select a date')}
                                    autoComplete="off"
                                    locale={datePickerLocales[lng]}
                                    name="financialEndYear"
                                    id="financialEndYear"
                                    className="form-control"
                                    selected={ financialYears.end }
                                    showTimeSelect={ false }
                                    minDate={financialYears.start || new Date('2022-01-01')}
                                    maxDate={new Date('2025-12-31')}
                                    disabled={!financialYears.start}
                                    onChange={(date) => {
                                        setFinancialYears((currentState) => {
                                            return {
                                                ...currentState,
                                                end: date
                                            }
                                        });

                                        faildValidations.splice(faildValidations.findIndex((item) => item === 'financialEndYear'), 1);
                                    }}
                                /> 
                                <Form.Control.Feedback type="invalid" style={{ display: `${endYearInvalid ? 'block' : 'none'}`}}>
                                    { t('The end year is required') }
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>

                        {financialYears.start && financialYears.end && (
                            <>
                                {(
                                    dateUtils.getDiffInDays(financialYears.start, financialYears.end) < 365 || 
                                    dateUtils.getDiffInDays(financialYears.start, financialYears.end) > 366
                                ) ? (
                                    <Col sm='12'>
                                        <Form.Control.Feedback type="invalid" className="d-block">
                                            { t('Please select a date range of exactly one year (365 days). The current selection doesn’t meet this requirement.') }
                                        </Form.Control.Feedback>
                                    </Col>
                                ) : (
                                    <Col sm='12'>
                                        <p className="text-info mb-0 mt-1">
                                            { t('The selected financial end year is') }: <b>{financialYears.end.getFullYear()}</b>
                                        </p>
                                    </Col>
                                )}
                            </>
                        )}
                    </Row>
                </ModalBody>

                <ModalFooter>
                    <Button color="danger" onClick={toggle} disabled={ creationFormInProcess }> 
                        { t('Cancel') }
                    </Button>

                    <Button type="submit" color="primary" disabled={ creationFormInProcess }>
                        {
                            creationFormInProcess && (
                                <Spinner animation="border" variant="primary" size="sm"/>
                            )
                        }
                        { t('Submit') }
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    )
};

export default withNamespaces()(NewReportRequestModal);