
import axios from 'axios';
import { API_BASE_URL } from '../constants';

export default class DataProtectionPorcessingDirectoryService {
	static instance;
	resource = `${API_BASE_URL}/processing_activities`;

	constructor() {}

	static getInstance() {
		if (!DataProtectionPorcessingDirectoryService.instance) {
			DataProtectionPorcessingDirectoryService.instance = new DataProtectionPorcessingDirectoryService();
		}

		return DataProtectionPorcessingDirectoryService.instance;
	}

	async list(params) {
		const response = await axios.get(`${this.resource}`, {
			params,
			headers: {
				Authorization: `Bearer ${localStorage.getItem('LOCAL_STORAGE_KEY_ACCESS_TOKEN')}`,
			},
		});

		return response.data;
	}
}