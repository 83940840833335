import { Component } from "react";
import { withNamespaces } from "react-i18next";
import {
    Button,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Spinner,
    UncontrolledDropdown,
} from "reactstrap";
import {
    API_BASE_URL
} from 'src/modules/3rd-party-management/constants';

import { AiFillCaretDown } from "react-icons/ai";

import { AvForm } from "availity-reactstrap-validation";

class DownloadAttachments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            spinner: {
                id: "",
                active: false,
            },
            dropdown: {
                id: "",
                active: false,
            },
            availableFiles: [],
            toggleDropDown: false,
        };
        this.attachFile();
    }

    handleClickDownloadAttch = (id, name) => {
        this.setState({
            spinner: {
                id: `download-file-${id}`,
                active: true,
            }
        });
        this.handleDownload(id, name);
    };

    handleDownload = (id, name) => {
        let url;
        if(this.props.type === 'task'){
            url = `${API_BASE_URL}/task/attachment/${id}/download_file`;
        } 
        else if(this.props.type === 'risk'){
            url = `${API_BASE_URL}/risk_rate/attachment/${id}/download_file`;
        }

        const token = `Bearer ${localStorage.getItem(
        "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
        )}`;
        
        const ext = name.split(".").pop();
        var xhr = new XMLHttpRequest();
        xhr.open("GET", url, true);
        xhr.setRequestHeader("Authorization", token);
        xhr.responseType = "arraybuffer";

        xhr.onload = function (e) {
            if (this.status == 200) {
                var blob = new Blob([this.response], { type: "*" });
                var link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = name;
                link.click();
            }
        };

        xhr.send();

        this.setState({
            spinner: {
                id: "",
                active: false,
            }
        });
    };

    renderDeletionModal = () => {
        const {
            t,
            deletionModalActivated,
            onDeletionModalClosed,
        } = this.props;

        return (
            <Modal size="lg"
                scrollable={true}
                isOpen={deletionModalActivated}
                backdrop="static">

                <ModalHeader toggle={onDeletionModalClosed}>
                    {t("Delete file")}
                </ModalHeader>

                <ModalBody>
                    <AvForm className="needs-validation"
                        onValidSubmit={this.deleteF}>
                        <Row>
                            <Label>
                                {t("Are you sure?")}
                            </Label>
                        </Row>

                        <ModalFooter>
                            <Button color="danger"
                                className="waves-effect waves-light"
                                type="submit">
                                {t("Delete").toUpperCase()}
                            </Button>
                            <Button
                                color="secondary"
                                className="waves-effect waves-light"
                                type="button"
                                onClick={onDeletionModalClosed}>
                                    {t("Cancel").toUpperCase()}
                            </Button>
                        </ModalFooter>
                    </AvForm>
                </ModalBody>
            </Modal>
        )
    }

    attachFile = () => {
        const { files } = this.props;
        const { availableFiles } = this.state;
        if (files) {
            files.map((file) => {
                availableFiles.push({
                value: file.id,
                title: file.origin_name,
                });
            });
        }
    };

    deleteF = () => {
        this.props.fetchTask();
        this.props.fetchComments();
        this.props.deleteFile();
    };

    toggle = () => this.setState({ toggleDropDown: this.state.toggleDropDown });

    render() {
        const {
            t,
            showAttachments,
            onUploadModalChange,
            onDeletionModalChange,
            comment,
            commentSection,
            files,
        } = this.props;

        return (
            <>
                <div hidden={showAttachments}
                    style={{ textAlign: commentSection ? "right" : "left" }}>
                    <div style={{
                            display: "inline-flex",
                            position: "relative",
                            textAlign: "left",
                        }}>
                        <UncontrolledDropdown>
                            <DropdownToggle className="bg-primary" caret>
                                {t("Download")} <AiFillCaretDown />
                            </DropdownToggle>

                            <DropdownMenu>
                                {files.map((item, index) => (
                                <DropdownItem text key={`download-file-${index}`}>
                                    <div
                                    className="d-flex"
                                    style={{
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                    >
                                    <span
                                        onClick={() =>
                                        this.handleClickDownloadAttch(
                                            item.id,
                                            item.origin_name
                                        )
                                        }
                                        style={{
                                            cursor: "pointer",
                                        }}>
                                        {this.props.t(item.origin_name)}
                                    </span>
                                    {this.state.spinner.id === `download-file-${index}` &&
                                        this.state.spinner.active && (
                                        <Spinner size="sm" color="primary" />
                                        )}
                                    </div>
                                </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                </div>

                <div hidden={!showAttachments} style={{ textAlign: "left" }}>
                    <div
                        style={{
                            position: "relative",
                            borderBottom: "1px solid #c9c9c9",
                        }}>
                        <ul>
                            {files.map((item, index) => {
                                return (
                                    <li>
                                        <Row
                                            style={{
                                            backgroundColor:
                                                index % 2 === 0 ? "#dee2e6" : "white",
                                            }}
                                        >
                                            <Col sm="12" md="7" lg="7">
                                            <span
                                                className="text-primary"
                                                onClick={() =>
                                                this.handleClickDownloadAttch(
                                                    item.id,
                                                    item.origin_name
                                                )
                                                }
                                                style={{
                                                cursor: "pointer",
                                                userSelect: "none",
                                                fontSize: 14,
                                                }}
                                            >
                                                {t(item.origin_name)}
                                            </span>
                                            </Col>
                                            <Col sm="12" md="5" lg="5">
                                            <span
                                                style={{ cursor: "pointer" }}
                                                className="text-primary"
                                                onClick={() => onUploadModalChange(item, comment)}
                                            >
                                                <a> {t("Update this version")}</a>
                                            </span>
                                            <span>{" | "}</span>
                                            <span
                                                style={{ cursor: "pointer" }}
                                                className="text-danger"
                                                onClick={() => onDeletionModalChange(item, comment)}
                                            >
                                                {t("Delete")}
                                            </span>
                                            </Col>
                                        </Row>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>

                { this.renderDeletionModal() }
            </>
        );
    }
}

export default withNamespaces()(
    DownloadAttachments
);
