import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import {
  Row,
  Col,
  Label,
  Input,
  FormGroup,
  Button,
  UncontrolledPopover,
  PopoverBody,
} from "reactstrap";
import Select from "react-select";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Accordion } from "react-bootstrap";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { TbLoader } from "react-icons/tb";
import CompanyInfoImage from "src/assets/images/company-information.svg";
import ComplaintCustomizationService from "src/apis/ComplaintCustomizationService";
import WhistleblowingCustomizationService from "src/apis/WhistleblowingCustomizationService";

const CompanyInformationCustomization = (props) => {
  const { t, fetchOrganizationDetails, locations, getCountries, modules } = props;
  const details = fetchOrganizationDetails.data;
  
  const [name, setName] = useState(details?.name);
  const [city, setCity] = useState(details?.city);
  const [country, setCountry] = useState({});
  const [abbreviation, setAbbreviation] = useState(details?.abbreviation);
  const [address, setAddress] = useState(details?.address);

  /*********************************** FUNCTIONS ***************************************** */
  // Submit Information
  const handleSubmitMutation = useMutation({
    mutationFn: async () => {
      const payload = {
        name: name,
        country: country.value,
        city: city,
        abbreviation: abbreviation,
        address: address,
      };

      const service = modules.active === "cm" ? ComplaintCustomizationService.getInstance() : WhistleblowingCustomizationService.getInstance();
      return await service.editInformation(payload);
    },
    onSuccess: () => {
      toast(t("Data saved successfully."), {
        type: "success",
      });
    },
    onError: () => {
      toast(t("Failed to save data."), {
        type: "error",
      });
    },
  });

  // Restore Information
  const restore = () => {
    const { name, city, abbreviation, address } = details;
    setName(name);
    setCity(city);
    setSelectedCountry();
    setAbbreviation(abbreviation);
    setAddress(address);
  };

  // Set Selected Country
  const setSelectedCountry = () => {
    const selectedCountry = locations.filter(
      (count) => count.value === details.country
    )[0];
    setCountry(selectedCountry);
  };

  /*************************************** USE EFFECTS ****************************************** */

  useEffect(() => {
    if (details?.country && locations && locations?.length) {
      setSelectedCountry();
    }
  }, [locations]);

  /*************************************** VIEW ****************************************** */
  return (
    <Row>
      <div className="page-customization">
        <Row>
          <Col className="d-flex align-items-start customization-image-col">
            <img src={CompanyInfoImage} alt="Company Information" />
          </Col>
          <Col className="customization-accordion-col">
            <Accordion defaultActiveKey="0">
              <Accordion.Item className="border-none cusom-accordion-item customization-accordion" eventKey="0">
                <Accordion.Header className="custom-accordion-header">
                  <div className="p-1 custom-div-header">
                    {`1. ${t("Company Information")}`}{" "}
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col lg="6" md="6" sm="12" className="mt-3">
                      <FormGroup>
                        <Label for="companyName">
                          {t("Company Name") + ":"}
                        </Label>
                        <Input
                          name="companyName"
                          type="text"
                          value={name}
                          disabled={
                            handleSubmitMutation.isLoading ||
                            fetchOrganizationDetails.isLoading ||
                            fetchOrganizationDetails.isFetching
                          }
                          onChange={(e) => setName(e.target.value)}
                        />
                      </FormGroup>
                    </Col>

                    <Col lg="6" md="6" sm="12" className="mt-3">
                      <FormGroup>
                        <Label for="abbreviation">
                          {t("Abbreviation") + ":"}
                          <>
                            <span
                              id={`section-info-button-abbreviation`}
                              className="section-info-button"
                            >
                              <i className="ri-information-line" />
                            </span>

                            <UncontrolledPopover
                              placement="bottom"
                              trigger="hover"
                              target={`section-info-button-abbreviation`}
                            >
                              <PopoverBody>
                                <span>
                                  {t(
                                    "This will be used in the default user and privacy statement"
                                  )}
                                </span>
                              </PopoverBody>
                            </UncontrolledPopover>
                          </>
                        </Label>
                        <Input
                          name="abbreviation"
                          type="text"
                          value={abbreviation}
                          disabled={
                            handleSubmitMutation.isLoading ||
                            fetchOrganizationDetails.isLoading ||
                            fetchOrganizationDetails.isFetching
                          }
                          onChange={(e) => setAbbreviation(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="6" md="6" sm="12" className="mt-3">
                      <FormGroup>
                        <Label for="fullAddress">
                          {t("Full Address") + ":"}
                        </Label>
                        <Input
                          name="fullAddress"
                          type="text"
                          value={address}
                          disabled={
                            handleSubmitMutation.isLoading ||
                            fetchOrganizationDetails.isLoading ||
                            fetchOrganizationDetails.isFetching
                          }
                          onChange={(e) => setAddress(e.target.value)}
                        />
                      </FormGroup>
                    </Col>

                    <Col lg="3" md="3" sm="12" className="mt-3">
                      <FormGroup>
                        <Label for="city">{t("City") + ":"}</Label>
                        <Input
                          name="city"
                          type="text"
                          value={city}
                          disabled={
                            handleSubmitMutation.isLoading ||
                            fetchOrganizationDetails.isLoading ||
                            fetchOrganizationDetails.isFetching
                          }
                          onChange={(e) => setCity(e.target.value)}
                        />
                      </FormGroup>
                    </Col>

                    <Col lg="3" md="3" sm="12" className="mt-3">
                      <FormGroup>
                        <Label for="city">{t("Country") + ":"}</Label>
                        <Select
                          name="status"
                          value={country}
                          classNamePrefix="select2-selection"
                          options={locations}
                          placeholder={t("Select")}
                          readOnly
                          isDisabled={
                            getCountries.isFetching ||
                            getCountries.isLoading ||
                            handleSubmitMutation.isLoading ||
                            fetchOrganizationDetails.isLoading ||
                            fetchOrganizationDetails.isFetching
                          }
                          menuPortalTarget={document.body}
                          onChange={(e) => setCountry(e)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Accordion.Body>
                <Row className="p-4">
                  <Col sm="12" className="d-flex justify-content-end">
                    <Button
                      onClick={restore}
                      color="primary"
                      type="button"
                      outline
                      className="me-2"
                      disabled={
                        handleSubmitMutation.isLoading ||
                        fetchOrganizationDetails.isLoading ||
                        fetchOrganizationDetails.isFetching
                      }
                    >
                      {t("Restore")}
                    </Button>

                    <Button
                      color="primary"
                      onClick={handleSubmitMutation.mutate}
                      type="submit"
                      disabled={
                        handleSubmitMutation.isLoading ||
                        fetchOrganizationDetails.isLoading ||
                        fetchOrganizationDetails.isFetching
                      }
                    >
                      {handleSubmitMutation.isLoading ? (
                        <TbLoader />
                      ) : (
                        t("Save")
                      )}
                    </Button>
                  </Col>
                </Row>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </div>
    </Row>
  );
};

const mapStatetoProps = (state) => {
  const { token } = state.Login;
  const organization = state.Organization;
  const App = state.App;
  const modules = state.Modules;
  return { organization, token, App, modules };
};

export default withNamespaces()(
  withRouter(connect(mapStatetoProps)(CompanyInformationCustomization))
);
