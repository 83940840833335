import { memo, useEffect, useState } from 'react';
import {Nav, NavItem, NavLink} from 'reactstrap';
import { useLocation, useHistory } from 'react-router-dom';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { getFeatureStatusByTitle } from "src/helpers/feature_helper";
const Tabs = memo(({t, Feature}) => {
    const [activeTab, setActiveTab] = useState("");
    const history = useHistory();
    const location = useLocation();

    const isActiveTab = (currentTab, tab) => currentTab === tab;

    useEffect(() => {
      setActiveTab((new URLSearchParams(location.search)).get('tab'));
    }, [ location.search ]);

    useEffect(() => {
      if(!(new URLSearchParams(location.search)).get('tab')){
        history.push({
          search : '?tab=qr'
        });
      }
    }, [location.search]);

    let tabsLinks = [
      {
          key: "qr",
          title: `${t("QR Code")}`
      },
      {
          key: "risk-customization",
          title: `${t("Risk Customization")}`
      },
      {
        key: "news-monitoring",
        title: `${t("News Monitoring")}`
    },
      {
        key: "routing",
        title: `${t("Routing")}`
      },
      {
        key: "email-settings",
        title: `${t("Email Settings")}`
      },
  ]

    return (
      <div style={{
        backgroundColor: '#D1D2D6',
      }}>
        <Nav tabs className="custom-style-1">
            {
              tabsLinks.map((link) => {
                return (
                  <NavItem className={isActiveTab(activeTab, link.key) ? 'active': ''} key={link.key}>
                      <NavLink
                        className={isActiveTab(activeTab, link.key) ? 'active': ''}
                        onClick={() => history.push({
                            search : '?tab=' + link.key
                        })}>
                          {t(link.title)}
                      </NavLink>
                  </NavItem>
                )
              })
            }
        </Nav>
      </div>
	  );
});

const mapStatetoProps = (state) => {
	const App = state.App;
	const { token } = state.Login;
	const { Feature, CMFeature } = state;

	const activeModule = localStorage.getItem('module');

	return {
		token,
		App,
		Feature: activeModule === 'wb' ? Feature : activeModule === 'cm' ? CMFeature : null,
	};
};


export default withNamespaces()(
  withRouter(connect(mapStatetoProps)(Tabs))
);
