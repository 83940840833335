import { withNamespaces } from "react-i18next";
import React, { memo, useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { useLocation, useParams, useHistory } from "react-router-dom";
import { withRouter } from "react-router-dom";
// styles
import "./style.scss";
// components
import PageBody from "../../../components/page-body";
import PageContent from "../../../components/page-content";
// components
import Tabs from "./tabs";
import Navigator from "../../../components/navigator";

// Contents
import DataProtectionSoftwareInformation from "./tabs-content/information/index";
import InternalCommunication from "./tabs-content/internal-communication";
import ProcessingActivity from "./processing-activity";
import DPA from "./tabs-content/dpa";
import DPIA from "./tabs-content/dpia/index";
import RiskAssessment from "./tabs-content/risk-assessment";
import OverviewHeader from "../overview-header";
import TaskManager from "./tabs-content/task-manager";
import RiskDetails from "src/modules/data-protection/pages/reporting/Components/RiskComponents/RiskDetails";
import TaskDetails from "src/modules/data-protection/pages/reporting/Components/TaskComponents/TaskDetails";
import RelatedIncident from "./tabs-content/related-incident";
import HistoryLog from "./tabs-content/history-log";
import LatestNews from "./tabs-content/latest-news";

const DataProtectionSoftwareDetails = (props) => {
  const [assetId, setAssetId] = useState(null);
  const { softwareId } = useParams();
  const history = useHistory();
  const location = useLocation();
  const [detailPage, setDetailPage] = useState(null);
  const [navigatorOptions, setNavigatorOptions] = useState({
    enable: false,
    backButtonOptions: {},
    breadCrumbs: [],
  });

  const { t } = props;

  const renderDetailsContent = () => {
    switch (detailPage?.type) {
      case "risk":
        return (
          <div className="p-4">
            <RiskDetails {...detailPage?.data} back={() => {
                  history.push(`/admin/data-protection/softwares/${softwareId}?tab=risk-assessment`)
                  setDetailPage(null)}} />
          </div>
        );

      case "task":
        return (
          <div className="p-4">
            <TaskDetails {...detailPage?.data} back={() => {
                  history.push(`/admin/data-protection/softwares/${softwareId}?tab=task-management`)
                  setDetailPage(null)}} />
          </div>
        );

      default:
        return null;
    }
  };

  const renderContent = () => {
    const displayAnyItemDetails = renderDetailsContent();

    if (displayAnyItemDetails) {
      return displayAnyItemDetails;
    }
    const searchParams = new URLSearchParams(location.search);

    let result = null;

    switch (searchParams.get("tab")) {
      case "basic-information":
        result = <DataProtectionSoftwareInformation 
          setAssetId={setAssetId} 
         />;
        break;
      case "internal-comments":
        result = <InternalCommunication assetId={softwareId}/>;
        break;
      case "processing-activity":
        result = <ProcessingActivity />;
        break;
      case "monitoring":
        result = (
          <div className="p-4 d-flex flex-column gap-4">
            <OverviewHeader assetId={softwareId} />
            <LatestNews assetId={softwareId}/>
          </div>
        );
        break
      case "related-incident":
        result = (
          <div className="p-4 d-flex flex-column gap-4">
            <OverviewHeader assetId={softwareId} />
            <RelatedIncident assetId={softwareId}/>
          </div>
        );
        break;
      case "dpa":
        result = <DPA assetId={softwareId} />;
        break;
      case "risk-assessment":
        result = (
          <div className="p-4 d-flex flex-column gap-4">
            <OverviewHeader assetId={softwareId}  />
            <RiskAssessment
              assetId={softwareId}
              onRiskSelected={(selectedRiskData) =>
                setDetailPage({
                  type: "risk",
                  data: selectedRiskData,
                })
              }
            />
          </div>
        );
        break;
      case "task-management":
        result = (
          <div className="p-4 d-flex flex-column gap-4">
            <OverviewHeader assetId={softwareId}/>
            <TaskManager
              assetId={softwareId}
              onTaskSelected={(selectedTaskData) =>
                setDetailPage({
                  type: "task",
                  data: selectedTaskData,
                })
              }
            />
          </div>
        );
        break;
      case "dpia":
        result = <DPIA assetId={softwareId} />;
        break;
      case "history-log":
        result = (
          <div className="p-4 d-flex flex-column gap-4">
            <OverviewHeader assetId={softwareId} />
            <HistoryLog assetId={softwareId} />
          </div>
        );
        break;
      default:
        break;
    }

    return (
      <React.Fragment>
        <Tabs t={t} />

        {result}
      </React.Fragment>
    );
  };

  
  useEffect(() => {
    if (!new URLSearchParams(location.search).get("page")) {
      setDetailPage(null);
    }
  }, [location.search]);

  const handleBack = () => {
    if(softwareId){
    history.push('/admin/data-protection/softwares?tab=all-software-assets')
      return;
    }
    setDetailPage(null);
   return;
  };

  return (
    <PageContent classNames="data-protection-software-details-page software-details-page">
      <Container fluid>
           <Col lg="12" className="mb-4">
           <Navigator
             backButtonOptions={() => {
               return {
                 enable: true,
                 onClick: () => handleBack(),
                 title: props.t("Back"),
                 icon: <i className="ri-arrow-left-fill"></i>,
               };
             }}
             breadCrumbs={[
              { title: "SIP", link: "/" },
              {
                title: "Software Assets",
                link: `/admin/data-protection/softwares?tab=all-software-assets`,
              },
             ]}
           />
         </Col>

        {/* page body */}
        <PageBody classNames="p-0">
          <div className="details-content-wrapper">{renderContent()}</div>
        </PageBody>
      </Container>
    </PageContent>
  );
};

export default withNamespaces()(
  withRouter(memo(DataProtectionSoftwareDetails))
);
