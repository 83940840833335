const createSVGFromText = (content, settings = {}) => {
    /**
     * The user can overwrite all the settings by sending the desired items
     */
    const defaultSettings = {
        svg     :   {
            'viewbox'   :   '0 0 30 30',
            'width'     :   '30',
            'height'    :   '30',
            'style'     :   'background-color: rgb(239, 242, 247);'
        },
        text    :   {
            'x'                 :   15,
            'y'                 :   15,
            'dominant-baseline' :   'middle',
            'text-anchor'       :   'middle',
            'style'             :   'font-familly: Inter, sans-serif;fill: rgb(80, 93, 105);font-weight:bold;font-size: 14px;'
        }
    };

    const SVG_NS = "http://www.w3.org/2000/svg";

    let svg = document.createElementNS(SVG_NS, 'svg');
    let text = document.createElementNS(SVG_NS, "text");

    const gotTextSettings = settings?.text || {};

    for(const attrKey in defaultSettings.text){
        text.setAttributeNS(null, attrKey, (gotTextSettings[attrKey] || defaultSettings.text[attrKey]));
    }

    const gotSVGSettings = settings?.svg || {};

    for(const attrKey in defaultSettings.svg){
        svg.setAttributeNS(null, attrKey, (gotSVGSettings[attrKey] || defaultSettings.svg[attrKey]));
    }
    
    text.textContent = content;

    svg.appendChild(text);

    return svg;
}

export {
    createSVGFromText
};