import axios from "axios";
import { logoutUser } from "./../store/auth/login/actions";
import store from "./../store";

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      console.log("axios.interceptors____logout.user");
      store.dispatch(logoutUser());
    }
    return Promise.reject(error);
  }
);