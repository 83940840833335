import { useMutation, useQuery } from "@tanstack/react-query";
import { memo, useImperativeHandle } from "react";
import { withNamespaces } from "react-i18next";
import { TbLoader } from "react-icons/tb";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import DataProtectionTomService from "src/modules/data-protection/apis/DataProtectionTomService";

const TOMFooter = (props) => {
  const {
    t,
    changeStatus,
    updatingSelectedStatus,
    handleFetchHeaderInformation,
    handleFetchDetails,
    actionsRef,
  } = props;

  //   const restore = useQuery({
  //     queryKey: ["data-protection-tom-restore"],
  //     queryFn: async () => {
  //       const service = DataProtectionTomService.getInstance();
  //       return await service.restore();
  //     },
  //     cacheTime: 0,
  //     refetchOnWindowFocus: false,
  //     enabled: false,
  //     onError: (error) => {
  //       toast(t("An error occurred while restoring content."), {
  //         type: "error",
  //       });
  //     },
  //     onSuccess: () => {
  //       toast(t("Content restored successfully."), {
  //         type: "success",
  //       });
  //       handleFetchHeaderInformation.refetch();
  //       handleFetchDetails.refetch();
  //     },
  //   });

  const restore = useMutation({
    mutationFn: async (revision) => {
      const service = DataProtectionTomService.getInstance();
      return await service.restore();
    },
    onSuccess: () => {
      toast(t("Content restored successfully."), {
        type: "success",
      });
      handleFetchHeaderInformation.refetch();
      handleFetchDetails.refetch();
    },
    onError: (error) => {
      toast(t("An error occurred while restoring content."), {
        type: "error",
      });
    },
  });

  useImperativeHandle(actionsRef, () => {
    return {
      reload: () => {
        restore.refetch();
      },
    };
  });

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        width: "Hug (109.31px)",
      }}
    >
      <Button
        color="primary me-4"
        outline
        onClick={() => restore.mutate()}
        disabled={restore.isLoading}
      >
        {restore.isLoading ? <TbLoader /> : `${t("Restore")}`}
      </Button>
      <Button
        color="primary"
        onClick={() => changeStatus({ label: "approved", value: "APPROVED" })}
        disabled={updatingSelectedStatus}
      >
        {updatingSelectedStatus ? <TbLoader /> : `${t("Approve")}`}
      </Button>
    </div>
  );
};

const mapStatetoProps = (state) => {
  const { Organization } = state;

  return {
    Organization,
  };
};

export default withNamespaces()(connect(mapStatetoProps)(memo(TOMFooter)));
