import { memo, useEffect, useState } from 'react';
import {Nav, NavItem, NavLink} from 'reactstrap';
import { useLocation, useHistory } from 'react-router-dom';
import './style.scss';

const QuestionaireTypesTabs = memo(({
  t, 
  questionaires, 
  onActiveQuestionaireChanged
}) => {
  const location = useLocation();
  
  const searchParams = new URLSearchParams(location.search);

  const [questionaireId, setQuestionaireId] = useState(null);

  const [activeTab, setActiveTab] = useState(null);

  const history = useHistory();

  const isActiveTab = (tab) => activeTab === tab;

  const handleTabChange = (tab) => {
    setActiveTab(Number.parseInt(tab));
    onActiveQuestionaireChanged && onActiveQuestionaireChanged(tab);
    history.push(`/admin/data-protection/administration/questionnaire?id=${tab}`);
  }

  useEffect(() => {
    if(questionaireId){
      handleTabChange(questionaireId);
    }
  }, [questionaireId]);

  useEffect(() => {
    if(questionaires && questionaires.length > 0){
      if(!searchParams.size || !searchParams.has('id')){
        setQuestionaireId(questionaires[0]?.id);
      }
      else{
        setQuestionaireId(searchParams.get('id'));
      }
    }
  }, [questionaires]);

  return (
    <Nav tabs>
      {
        questionaires.map((questionaire) => {
          return (
            <NavItem key={ questionaire.id }>
              <NavLink
                className={isActiveTab(questionaire.id) ? 'active': ''}
                onClick={() => handleTabChange(questionaire.id)}>
                {t(questionaire.title)}
              </NavLink>
            </NavItem>
          )
        })
      }
    </Nav>
	);
});

export default QuestionaireTypesTabs;
