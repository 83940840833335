import { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { 
    Button, 
    Card, 
    CardBody, 
    CardHeader, 
    CardText, 
    Col, 
    Collapse, 
    Modal, 
    ModalBody, 
    ModalFooter, 
    ModalHeader, 
    Row, 
    Spinner 
} from "reactstrap";
import { GoTag } from "react-icons/go";
import { CiFlag1 } from "react-icons/ci";
import { toast } from "react-toastify";
import {
  API_URL_GET_RISK_AI_CLASS
} from "../../../../../constants";
import axios from "axios";
import StrongImage from "src/assets/images/RiskAI/Strong.svg";
import WeakImage from "src/assets/images/RiskAI/Weak.svg";
import ModerateImage from "src/assets/images/RiskAI/Moderate.svg";

const AiRiskDetailsModal = ({
  t,
  token,

  handleModal,
  showModal,
  selectedRiskDetails,
  flaggedRisks,
  onFlagRisk,
  flaggedMitTasks,
  onFlagMitTask,
  rColor,
  subTColor,
}) => {
    const [classFetched, setClassFetched] = useState(false);
    const [collapse, setCollapse] = useState(0);
    const [selectedClass, setSelectedClass] = useState(null);
    const [modalHeight, setModalHeight] = useState(0);

    const processHtmlContent = (htmlContent) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');

        // Convert h1 to h2, h2 to h3, h3 to h4
        doc.querySelectorAll('h1, h2, h3').forEach(header => {
            switch (header.tagName) {
                case 'H1':
                header.outerHTML = `<h4>${header.innerHTML}</h4>`;
                break;
                case 'H2':
                header.outerHTML = `<h5>${header.innerHTML}</h5>`;
                break;
                case 'H3':
                header.outerHTML = `<h6>${header.innerHTML}</h6>`;
                break;
                default:
                break;
            }
        });

        return doc.body.innerHTML;
    };

    const processedExplanation = processHtmlContent(selectedRiskDetails?.details);

    const toggle = (id) => {
        setCollapse(id);
    };

    useEffect(() => {
        const updateModalHeight = () => {
            const modalElement = document.getElementById('modalId');
            if (modalElement) {
                setModalHeight(modalElement.offsetHeight);
            }
        };

        const { source } = selectedRiskDetails;
        let descriptions = source.map(s => `'${s.description}'`);
        let formattedDescriptions = descriptions.length > 1
            ? descriptions.slice(0, -1).join(", ") + ' and ' + descriptions[descriptions.length - 1]
            : descriptions.join("");

        axios.post(API_URL_GET_RISK_AI_CLASS,
            {
                promt: "Determine score between these risks: " + formattedDescriptions,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
        ).then(({
            data
        }) => {
            const res = data?.out;
            let parts = res.split(" ");
            let secondPart = parts[1];

            setSelectedClass(secondPart);
            setClassFetched(true);
        }).catch(() => {
            setClassFetched(false);
            toast(t('Failed to fetch class.'), {
                type: 'error',
            });
        });

        window.addEventListener('resize', updateModalHeight);
        updateModalHeight();

        // Cleanup
        return () => {
            window.removeEventListener('resize', updateModalHeight);
        };
    }, []);

    function getMainLink(url) {
        return url ? ((new URL(url))?.origin) : ''; 
    }

    const { riskId, mitigation_tasks, source, riskType } = selectedRiskDetails;
  
    let firstTwoWords = riskType

    const scrollHeight = modalHeight - 30;

    return (
        <div>
            <Modal isOpen={showModal} toggle={handleModal} id={"modalId"} size="xl">
                <ModalHeader toggle={handleModal}>
                    <Row className="w-100">
                        <Col className="d-flex justify-content-start">
                            <span className="category" style={{ backgroundColor: rColor }}>
                                <GoTag /> {t(firstTwoWords)}
                            </span>
                        </Col>
                        <Col className="d-flex justify-content-end">
                            <span
                                className={
                                flaggedRisks?.includes(riskId)
                                    ? "selectedFlag"
                                    : "flag"
                                }
                                onClick={() => onFlagRisk(selectedRiskDetails)}
                            >
                                <CiFlag1 />
                            </span>
                        </Col>
                    </Row>
                </ModalHeader>

                <ModalBody>
                    <Row>
                        <Col lg={8}>
                            <h3>{t("Description") + ":"}</h3>
                            <div dangerouslySetInnerHTML={{ __html: processedExplanation }}></div>
                            <h3>{t("Mitigation tasks") + ":"}</h3>
                            {mitigation_tasks?.map((mitTask, index) => {
                                const taskId = mitTask.id;
                                return (
                                    <Card style={{ marginBottom: "1rem" }}
                                        key={index}>
                                        <div>
                                            <CardHeader
                                                onClick={() => toggle(mitTask.id)}
                                                data-event={index}
                                                style={{
                                                cursor: "pointer",
                                                display: "flex",
                                                justifyContent: "space-between",
                                                backgroundColor: subTColor
                                                }}>
                                                <span>{t(mitTask.title)}</span>

                                                <span
                                                    className={
                                                        flaggedMitTasks.includes(taskId)
                                                        ? "selectedFlag"
                                                        : "mitFlag"
                                                    }
                                                    onClick={() =>
                                                        onFlagMitTask(mitTask, riskId)
                                                    }>
                                                    <CiFlag1 />
                                                </span>
                                            </CardHeader>
                                        </div>
                                        <Collapse isOpen={collapse === mitTask.id}>
                                            <CardBody>
                                                {t(mitTask.description)}
                                            </CardBody>
                                        </Collapse>
                                    </Card>
                                );
                            })}
                        </Col>

                        <Col lg={4}>
                            { classFetched ? 
                                (
                                    <Card className="my-2"
                                        outline
                                        style={{
                                            width: '18rem',
                                            border: "1px solid #5664D2",
                                            overflowY: "scroll",
                                            maxHeight: `${scrollHeight}px` // Using modalHeight here
                                        }}>
                                        <img
                                            alt="Risk Level"
                                            src={selectedClass === "A" ? StrongImage : selectedClass === "B" ? ModerateImage : WeakImage}
                                        />
                                        <CardBody>
                                            <CardText>
                                                <h3 style={{ color: "#1A2052", textAlign: "center", marginTop: 0, paddingTop: 0 }}>{t("Source")}</h3>
                                                    {
                                                        source.map(s => {
                                                            return (
                                                                <div className="mb-4">
                                                                    <h4 style={{ textAlign: "center" }}>{s.title}</h4>
                                                                    <p>{s.description}</p>
                                                                   <li style={{ listStyleType: 'none' }}><a  rel="noreferrer" href={s.source} target="_blank">{s.title}</a></li>   {/*getMainLink(s.link) */}
                                                                    <hr></hr>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                        {
                                                        mitigation_tasks.map(s => {
                                                            return (
                                                                <div className="mb-4">
                                                                    <h4 style={{ textAlign: "center" }}>{s.title}</h4>
                                                                    <p>{s.description}</p>
                                                                   <li style={{ listStyleType: 'none' }}><a  rel="noreferrer" href={s.source} target="_blank">{s.title}</a></li>   {/*getMainLink(s.link) */}
                                                                    <hr></hr>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                ):(
                                    <div className="d-flex justify-content-center align-items-center" style={{ height: "100px" }}>
                                        <Spinner type="grow" color="primary" />
                                    </div>
                                )
                            }
                        </Col>
                    </Row>
                </ModalBody>

                <ModalFooter>
                    <Button variant="secondary" onClick={handleModal}>
                        {t("Close")}
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

const mapStatetoProps = (state) => {
    const { token } = state.Login;

    return {
        user            :   state.Login.user,
        token       
    };
};

export default withNamespaces()(
    connect(
        mapStatetoProps,
        {}
    )(AiRiskDetailsModal)
);