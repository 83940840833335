import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import { withNamespaces } from "react-i18next";
import { Card, CardBody, CardTitle, Tooltip } from "reactstrap";

class RiskRatings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [],
      options: {
        labels: [],
        colors: [],

        plotOptions: {
          bar: {
            distributed: true,
          },
        },

        dataLabels: {
          enabled: true,
          position: "center",
          formatter: function (val) {
            return val.toFixed(0) + "%"; // SPIK-1042
          },
          style: {
            fontSize: "12px",
          },
        },

        legend: {
          show: true,
          offsetX: 0,
          offsetY: -10,
          floating: false,
          fontSize: "14px",
          position: "bottom",
          verticalAlign: "middle",
          horizontalAlign: "center",
        },

        responsive: [
          {
            breakpoint: 600,
            options: {
              chart: {
                height: 240,
              },
              legend: {
                show: false,
              },
            },
          },
        ],

        // xaxis: {
        // 	labels: {
        // 		formatter: function (val) {
        // 			return Math.floor(val);
        // 		},
        // 	},
        // },
        // yaxis: {
        // 	labels: {
        // 		formatter: function (val) {
        // 			return Math.floor(val);
        // 		},
        // 	},
        // },
      },
      riskRatingTooltip: false,
    };
  }

  // https://stackoverflow.com/questions/1026069/how-do-i-make-the-first-letter-of-a-string-uppercase-in-javascript
  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  render() {
    return (
      <>
        <Card>
          <CardBody>
            <div className="mb-3">
              <CardTitle>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>{this.props.t("Risk Ratings")}</div>
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    id="infoIconRisk"
                  />
                  <Tooltip
                    placement="top"
                    isOpen={this.state.riskRatingTooltip}
                    target="infoIconRisk"
                    toggle={() =>
                      this.setState({
                        riskRatingTooltip: !this.state.riskRatingTooltip,
                      })
                    }
                  >
                    {this.props.t(
                      "This chart marks the level of risk in all the cases between the time that you chose"
                    )}
                  </Tooltip>
                </div>
              </CardTitle>
            </div>
            <div className="dissco--donut-chart" style={{ paddingBottom: 10 }}>
              <ReactApexChart
                options={this.state.options}
                series={this.state.series}
                type="donut"
                height="320"
              />
            </div>
          </CardBody>
        </Card>
      </>
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data || prevProps.t !== this.props.t) {
      // const series = data.map((item) => item.percent);
      // const labels = data.map((item) => this.capitalizeFirstLetter(this.props.t(item.title)));
      const data = this.props.data;
      const series = [];
      const labels = [];
      const colors = [];

      data.map((item) => {
        colors.push(`#${item.color}`);
        series.push(item.percent);
        labels.push(this.capitalizeFirstLetter(this.props.t(item.title)));
      });

      // console.log("series ", series);

      // console.log("*SPIK-1042 risks data: ", series);

      this.setState({
        series: series,
        options: {
          ...this.state.options,
          labels: labels,
          colors,
        },
      });
    }
  }
}

export default withNamespaces()(RiskRatings);
