// @flow
import {
	CHANGE_WORKSPACE_ACTION
} from "./actionTypes";

const INIT_STATE = {
	name: "third_party_workspace"
};

const Workspace = (state = INIT_STATE, action) => {
	switch (action.type) {
		case CHANGE_WORKSPACE_ACTION:
			return {
				...state,
				name: action.payload
			};
		default:
			return state;
	}
};

export default Workspace;
