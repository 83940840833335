import { memo } from 'react';

const PageHeader = memo(({ classNames, title, children }) => {
	return (
		<div className={`page-header mb-3 mt-2 d-flex justify-content-between align-items-center ${classNames ? classNames : ''}`}>
			<p className='page-header-title'>{title}</p>
			<div className='d-flex gap-1'>{children}</div>
		</div>
	);
});
export default PageHeader;