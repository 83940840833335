import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Col, Row, Modal, ModalHeader, ModalBody } from "reactstrap";
import { toast } from "react-toastify";
import FindingsService from "src/modules/3rd-party-management/apis/FindingsService";
import SupplierService from "src/modules/3rd-party-management/apis/SupplierService";

import AIRiskAssessmentModal from "src/modules/3rd-party-management/components/RisksAndTasks/risks/ai/AssessmentModal";

import FindingDetailBody from './components/finding';
import SanctionDetailBody from './components/sanction';

import {
    CHAT_BOT_DOMAIN,
    API_URL_RISK_AI_GET_ADDITIONAL_DETAILS,
    API_BASE_URL
} from 'src/modules/3rd-party-management/constants';

const FindingDetails = function (props) {
    const {
        organization,
        t,
        supplierId,
        finding : {
            id
        }
    } = props;

    const history = useHistory();

    const [ aiBasedRiskAssessmentModalStatus, setAiBasedRiskAssessmentModalStatus ] = useState(false);

    const {
        data        :   finding,
        isLoading   :   findingDetailsIsLoading,
        isFetching  :   findingDetailsIsFetching
    } = useQuery({
		queryKey: [
            '3rd-party-management-fetch-finding-details',
            supplierId,
            id
        ],
		queryFn: async () => {
			const service = FindingsService.getInstance();

            return await service.fetchDetails(supplierId, id);
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			toast(t('An error occurred while fetching finding details.'), {
				type: 'error',
			});
		},
	});

    const {
        data        :   supplierInfos,
        isFetching  :   basicInfosIsFetching,
        isLoading   :   basicInfosIsLoading
    } = useQuery({
		queryKey: [
            '3rd-party-management-supplier-details-basic-infos',
            supplierId
        ],
		queryFn: async () => {
			const service = SupplierService.getInstance();

            return await service.fetchBasicInfos(supplierId, {});
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			toast(t('An error occurred while fetching supplier basic information.'), {
				type: 'error',
			});
		},
	});

    const handleToggleFlagStatusMutation = useMutation({
        mutationFn: async (payload) => {
            const service = FindingsService.getInstance();
    
            return await service.toggleFlag(payload);
        },
        onSuccess: () => {
            finding.flag = !finding.flag;

            toast(t("Flag status updated successfully."), {
                type: "success",
            });
        },
        onError: () => {
            toast(t("An error occurred while updating flag status."), {
                type: "error",
            });
        }
    });

    const renderLoadingPlaceholder = () => {
        return (
            <Row>
                <Col sm="12" md="9">
                    <div className="dt-field dt-skeleton mb-4" style={{width: '70%'}}></div>
                    <div className="dt-field dt-skeleton mb-5" style={{width: '50%', height: '220px'}}></div>

                    <div className="dt-field dt-skeleton mb-1" style={{width: '90%'}}></div>
                    <div className="dt-field dt-skeleton mb-1" style={{width: '85%'}}></div>
                    <div className="dt-field dt-skeleton mb-1" style={{width: '90%'}}></div>
                    <div className="dt-field dt-skeleton mb-4" style={{width: '50%'}}></div>

                    <div className="dt-field dt-skeleton mb-1" style={{width: '90%'}}></div>
                    <div className="dt-field dt-skeleton mb-1" style={{width: '85%'}}></div>
                    <div className="dt-field dt-skeleton mb-1" style={{width: '90%'}}></div>
                    <div className="dt-field dt-skeleton mb-4" style={{width: '50%'}}></div>

                    <div className="dt-field dt-skeleton mb-1" style={{width: '90%'}}></div>
                    <div className="dt-field dt-skeleton mb-1" style={{width: '85%'}}></div>
                    <div className="dt-field dt-skeleton mb-1" style={{width: '90%'}}></div>
                    <div className="dt-field dt-skeleton" style={{width: '50%'}}></div>
                </Col>

                <Col sm="12" md="3">
                    <div className="dt-field dt-skeleton w-100 mb-4" style={{height: '220px'}}></div>
                    <div className="dt-field dt-skeleton w-100 mb-4" style={{height: '220px'}}></div>
                    <div className="dt-field dt-skeleton w-100" style={{height: '220px'}}></div>
                </Col>
            </Row>
        );
    }

    const renderModals = () => {
        return (
            aiBasedRiskAssessmentModalStatus && (
                <AIRiskAssessmentModal
                    closeModal={ () => setAiBasedRiskAssessmentModalStatus(false) }
                    onRisksCreated={ () => history.push({ search : '?tab=risk-assessment'}) }
                    isOpen={ aiBasedRiskAssessmentModalStatus }
                    translateResult={ true }
                    aiRequestOptions={{
                        requestUrl      :   `${CHAT_BOT_DOMAIN}api/tpdd/risks-associated.ai`,
                        payload         :   {
                            findingId           :   id,
                            productsGroup       :   (supplierInfos?.product || []).map((product) => {
                                return product?.name.productGroup;
                            }),
                            organizationId      :   organization.id,
                            myLocation          :   organization?.countryId,
                            targetCountry       :   (supplierInfos?.locations || []).filter((location) => location.type === 'office')[0]?.country
                        }
                    }}
                    aiTranlateRequestOptions={{
                        requestUrl      :   `${CHAT_BOT_DOMAIN}api/tpdd/risks-associated-translate.ai`,
                        payload         :   {
                            findingId           :   id,
                            organizationId      :   organization.id,
                            module              :   "TPDD_NEWS"
                        }
                    }}
                    additionnalDetailsRequestOptions={{
                        requestUrl  :   API_URL_RISK_AI_GET_ADDITIONAL_DETAILS,
                        payload     :   {}
                    }}
                    createRisksRequestOptions={{
                        requestUrl      :   `${API_BASE_URL}/risk_rate/create_ai`,
                        payload         :   {
                            supplier    :   supplierId,
                            news        :   id
                        }
                    }}>
                    {(result) => {
                        return (
                            <Modal className="ai-risk-result-modal mw-100" style={{width: '90%'}} isOpen={aiBasedRiskAssessmentModalStatus} size="xl">
                                <ModalHeader toggle={() => setAiBasedRiskAssessmentModalStatus(!aiBasedRiskAssessmentModalStatus)}>
                                    {t('Risk Assesment Support by AI')}
                                </ModalHeader>
                                <ModalBody>
                                    <result.type {...result.props} />
                                </ModalBody>
                            </Modal>
                        )
                    }}
                </AIRiskAssessmentModal>
            )
        )
    }

    const renderFindingBody = () => {
        if(finding.findings_type === 'sanction'){
            return (
                <SanctionDetailBody 
                    setAiBasedRiskAssessmentModalStatus={setAiBasedRiskAssessmentModalStatus}
                    finding={finding} 
                />
            )
        }

        return (
            <FindingDetailBody 
                setAiBasedRiskAssessmentModalStatus={setAiBasedRiskAssessmentModalStatus} 
                finding={finding}
            />
        );
    }
    
    const isLoading = basicInfosIsFetching || basicInfosIsLoading || findingDetailsIsLoading || findingDetailsIsFetching;    
    
    return (
        <div className="finding-details-content p-2">
            {
                (isLoading) ? (
                    <>{ renderLoadingPlaceholder() }</>
                ) : (
                    <>
                        { renderFindingBody() }
                        { renderModals() }
                    </>
                )
            }
        </div>
    )
};

const mapStatetoProps = (state) => {
    const { Organization } = state;
    const { token } = state.Login;

    return {
        user            :   state.Login.user,
        organization    :   Organization,
        token
    };
};

export default withNamespaces()(
    connect(
        mapStatetoProps,
        {}
    )(FindingDetails)
);