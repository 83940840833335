import { withNamespaces } from "react-i18next";
import { memo, useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Col,
  Row,
  Button,
  FormGroup,
  Label,
  Form,
  Input,
  ModalFooter,
} from "reactstrap";
import DataProtectionTomService from "src/modules/data-protection/apis/DataProtectionTomService";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { TbLoader } from "react-icons/tb";

const SubContractorModal = (props) => {
  const { t, modal, setModal, type, handleFetchDetails, data } = props;
  
  const id = data?.id;
  const [formData, setFormData] = useState({
    area: "",
    name: "",
    address: "",
    website: "",
    GTC: "",
    privacyPolicy: "",
  });

  const [isProcessing, setIsProcessing] = useState(false);

  const service = DataProtectionTomService.getInstance();

  /********************************************** FIUNCTIONS ******************************************** */
  const toggle = () => setModal(!modal);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  /************************ Edit Subcontractor *************************** */
  const editSubcontractorMutation = useMutation({
    mutationFn: async (data) => {
      return await service.editSubcontractor(data, id);
    },
    onSuccess: () => {
      setIsProcessing(false);
      toast(t("Subcontractor edited successfully."), {
        type: "success",
      });
      handleFetchDetails.refetch();
      toggle();
    },
    onError: (error) => {
      setIsProcessing(false);
      toast(t("An error occurred while editing the subcontractor."), {
        type: "error",
      });
    },
  });

  const editSubcontractor = () => {
    setIsProcessing(true);
    editSubcontractorMutation.mutate(formData);
  };

  /************************ Create Subcontractor *************************** */
  const createSubcontractorMutation = useMutation({
    mutationFn: async (data) => {
      return await service.createSubcontractor(data);
    },
    onSuccess: () => {
      setIsProcessing(false);
      toast(t("New subcontractor created successfully."), {
        type: "success",
      });
      handleFetchDetails.refetch();
      toggle();
    },
    onError: (error) => {
      setIsProcessing(false);
      toast(t("An error occurred while creating the subcontractor."), {
        type: "error",
      });
    },
  });

  const createSubcontractor = () => {
    setIsProcessing(true);
    createSubcontractorMutation.mutate(formData);
  };

  /********************************************** USE EFFECTS*************************************** */
  useEffect(() => {
    if (type === "edit")
      setFormData({
        area: data?.area || "",
        name: data?.name || "",
        address: data?.address || "",
        website: data?.website || "",
        GTC: data?.g_t_c || "",
        privacyPolicy: data?.privacy_policy || "",
      });
  }, [data]);
  /*  */
  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} size="xl">
        <ModalHeader toggle={toggle}>
          {type === "create" ? t("Add Subcontractor") : t("Edit Subcontractor")}
        </ModalHeader>
        <ModalBody>
          <Form>
            <Row className="mb-4">
              <Col md="4" sm="12">
                <FormGroup>
                  <Label for="area">{t("Area")}</Label>
                  <Input
                    id="area"
                    name="area"
                    type="text"
                    value={formData.area}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
              <Col md="4" sm="12">
                <FormGroup>
                  <Label for="name">{t("Name")}</Label>
                  <Input
                    id="name"
                    name="name"
                    type="text"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
              <Col md="4" sm="12">
                <FormGroup>
                  <Label for="address">{t("Address")}</Label>
                  <Input
                    id="address"
                    name="address"
                    type="text"
                    value={formData.address}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md="4" sm="12">
                <FormGroup>
                  <Label for="website">{t("Website")}</Label>
                  <Input
                    id="website"
                    name="website"
                    type="text"
                    value={formData.website}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
              <Col md="4" sm="12">
                <FormGroup>
                  <Label for="GTC">{t("GTC")}</Label>
                  <Input
                    id="GTC"
                    name="GTC"
                    type="text"
                    value={formData.GTC}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
              <Col md="4" sm="12">
                <FormGroup>
                  <Label for="privacyPolicy">{t("Privacy Policy")}</Label>
                  <Input
                    id="privacyPolicy"
                    name="privacyPolicy"
                    type="text"
                    value={formData.privacyPolicy}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </ModalBody>

        <ModalFooter>
          <Button color="secondary" outline onClick={toggle}>
            {t("Cancel")}
          </Button>{" "}
          <Button
            color="primary"
            onClick={
              type === "create" ? createSubcontractor : editSubcontractor
            }
          >
            {isProcessing ? <TbLoader /> : t("Save")}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default withNamespaces()(memo(SubContractorModal));
