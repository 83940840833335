import axios from "axios";

import { API_BASE_URL } from "../constants";

export default class DataProtectionMailerService {
  static instance;
  resource = `${API_BASE_URL}/mailer_setting`;

  constructor() {}

  static getInstance() {
    if (!DataProtectionMailerService.instance) {
      DataProtectionMailerService.instance = new DataProtectionMailerService();
    }

    return DataProtectionMailerService.instance;
  }

  async fetchMailerSettings() {
    const response = await axios.get(`${this.resource}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
        )}`,
      },
    });
    return response.data.data;
  }


  async saveMailerSettings(settings) {
    const response = await axios.post(`${this.resource}/edit`, settings, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
        )}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  }
}
