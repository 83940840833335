import { withNamespaces } from 'react-i18next'
import { useEffect, useRef, useState } from 'react'
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner'
import InputGroup from 'react-bootstrap/InputGroup'
import { Alert } from 'reactstrap'

const CheckList = withNamespaces()(({
    t,
    name,
    data = [],
    isLoading,
    formatter,
    defaultValue,
    onFilterChange,
    searchInListOption,
    pagination
}) => {
    const [paginationOption, setPaginationOption] = useState({})
    const [value, setValue] = useState(defaultValue || [])
    const [items, setItems] = useState([])
    const [searchText, setSearchText] = useState(searchInListOption?.defaultValue)
    const itemsContainerRef = useRef()

    const onItemsContainerScrollEvent = () => {
        if (itemsContainerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = itemsContainerRef.current;
            if (scrollTop + clientHeight === scrollHeight) {
                if(!isLoading && (paginationOption.pageIndex * paginationOption.pageSize) < paginationOption.total){
                    pagination.handler({
                        pageIndex: parseInt(paginationOption.pageIndex) + 1,
                        pageSize: paginationOption.pageSize
                    })
                }
            }
        }
    }

    useEffect(() => {
        if(pagination?.enabled){
            setPaginationOption({
                total: parseInt(data[pagination.totalCountKey] || 0),
                pageSize: parseInt(data[pagination.pageSizeKey] || 0),
                pageIndex: parseInt(data[pagination.pageIndexKey] || 1)
            })

            if(pagination.dataKey in data && Array.isArray(data[pagination.dataKey])){
                setItems((currentState) => {
                    const result = parseInt(data[pagination.pageIndexKey]) > 1 ? [...currentState] : []

                    return [...result, ...(data[pagination.dataKey].map((item) => {
                        return {
                            id: item.id,
                            title: (formatter && formatter(item)) || ''
                        }
                    }))]
                })
            }
        }
        else{
            if(data && Array.isArray(data)){
                setItems(data.map((item) => {
                    return {
                        id: item.id,
                        title: (formatter && formatter(item)) || ''
                    }
                }))
            }
        }
    }, [data])

    useEffect(() => {
        if (value) {
            onFilterChange &&
                onFilterChange({
                    filterName: name,
                    value: value
                })
        }
    }, [value])

    useEffect(() => {
        if(searchInListOption?.defaultValue !== searchText){
            const id = setTimeout(() => {
                if(searchInListOption.remote){
                    setItems([]);
                    searchInListOption.handler(searchText || "");
                }
                else{
                    const normalizedSearchText = searchText.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")
                    
                    setItems((currentState) => {
                        return currentState.map((item) => {
                            return {
                                ...item,
                                hide: (t(item.title).toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")).indexOf(normalizedSearchText) < 0
                            }
                        })
                    })
                }
            }, 500);
    
            return () => clearTimeout(id);
        }
	}, [searchText]);

    const paginationEvents = {}
    if(pagination?.enabled && pagination.handler){
        paginationEvents.onScroll = onItemsContainerScrollEvent
    }

    return (
        <>
            {searchInListOption?.enabled && (
                <InputGroup>
                    <Form.Control
                        placeholder={t('Search')}
                        aria-describedby='search-result-input'
                        defaultValue={searchText}
                        style={{
                            borderRight: 'none',
                            fontWeight: 700,
                            color: '#4F4F4F'
                        }}
                        onChange={(e) => {
                            setSearchText(e.target.value)
                        }} 
                    />

                    <InputGroup.Text
                        className='bg-transparent border-left-0'
                        id='search-result-input'
                        style={{ borderLeft: 'none' }}
                        defaultValue={searchInListOption?.defaultValue}
                        placeholder={`${t('Start Typing')}...`}
                    >
                        <i className='ri-search-line' />
                    </InputGroup.Text>
                </InputGroup>
            )}

            <div className='filter-items-container' 
                ref={itemsContainerRef} 
                {...paginationEvents}>

                {items.length > 0 ? (
                    <>
                        {items.map((item, index) => {
                            return (
                                <div className={`filter-item ${item.hide ? 'd-none' : ''}`} key={index}>
                                    <Form.Check
                                        id={`${name}-item-${item.id}`}
                                        type='checkbox'
                                        checked={value.includes(item.id)}
                                        onChange={() => {
                                            setValue((currentState) => {
                                                const newState = [...currentState]
                                                if(newState.includes(item.id)){
                                                    return newState.filter((i) => i !== item.id)
                                                }
                                                else{
                                                    newState.push(item.id)
                                                }
                                                return newState
                                            })
                                        }}
                                        label={t(item.title)}
                                    />
                                </div>
                            )
                        })}
                    </>
                ) : (
                    <Alert color='warning' className='mt-2'>
                        {t('This list is empty')}
                    </Alert>
                )}

                {isLoading && (
                    <div className='text-center'>
                        <Spinner animation='border' variant='primary' size='md' />
                    </div>
                )}
            </div>
        </>
    )
})

export { CheckList }
