import React from "react";
import Avatar from "./AvatarCard";
import moment from "moment";
import AttachmentCardInChat from "./AttachmentCardInChat";

const ReceiveMessageWithDetails = ({ message, sameOwnerAsNext }) => {
  // Convert the backend timestamp to a Moment.js object
  const backendMoment = moment.unix(message.created_at);
  const currentDate = moment().format("YYYY-MM-DD");
  // Get the backend date
  const backendDate = backendMoment.format("YYYY-MM-DD");
  // Compare dates
  const isToday = currentDate === backendDate;
  
  return (
    <li className="message-show-fade-in">
      <div
        className="conversation-list"
        style={{ justifyContent: "flex-start" }}
      >
        <Avatar src={message.owner?.avatar && message.owner?.avatar !=="" ? message.owner?.avatar : null} alt={message.owner?.first_name} />
        <div className="ctext-wrap px-2">
          <div className="conversation-name" style={{ textAlign: "left" }}>
          {message.owner?.username ? `${message.owner.username}` : message.owner?.first_name + ' ' + message.owner?.last_name}
          </div>
            <div
              className="ctext-wrap-content"
              style={{ color: "#fff", background: "#5664d2" }}
            >
             <div className="comment-text" dangerouslySetInnerHTML={{__html: message?.content}}/>
              <AttachmentCardInChat
              message={message}
              isSender={!message.owner?.contactPerson}
            />         
            </div>
          {sameOwnerAsNext === true ? null : (
            <p className="chat-time mb-0 d-flex gap-1 justify-content-start">
              <i className="mdi mdi-clock-outline align-middle me-1"></i>{" "}
              {isToday ? (
                // If the message is from today, display only the time
                new Date(message.created_at * 1000).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })
              ) : (
                // If the message is not from today, display time, month, and day
                <>
                  {new Date(message.created_at * 1000).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                  <i className="mdi mdi-calendar-month-outline align-middle ms-1 mb-2"></i>{" "}
                  {/* Date icon */}
                  {new Date(message.created_at * 1000).toLocaleDateString([], {
                    month: "short",
                    day: "numeric",
                  })}
                </>
              )}
            </p>
          )}
        </div>
      </div>
    </li>
  );
};

export default ReceiveMessageWithDetails;
