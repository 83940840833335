import { withNamespaces } from 'react-i18next';
import { withRouter, useHistory } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useRef, useState } from 'react';
import QuestionnaireService from 'src/modules/3rd-party-management/apis/QuestionnaireService';
import { toast } from "react-toastify";
import Questionar from 'src/modules/3rd-party-management/components/questionar';
import { connect } from 'react-redux';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import {
    API_BASE_URL
} from 'src/modules/3rd-party-management/constants';

import {
	confirmPromptMakeReadyToShow,
	confirmPromptHide,
	confirmPromptUpdateAlertProps,

	moduleTPDDResetQuestionnaireState,
	moduleTPDDUpdateQuestionnaireLatestPayload,
	moduleTPDDUpdateQuestionnaireUnsavedStepsCount,
	moduleTPDDSaveQuestionnaireCurrentState,
	moduleTPDDUpdateQuestionnaireAllQuestionsAnsweredState,
	moduleTPDDUpdateQuestionnaireReportCaseId
} from 'src/store/actions';

const FillQuestionnaire = ({
    t,
    token, 
    user,
	Organization,
    onBackButtonClicked,
    onValidSubmit,
	moduleTPDDQuestionnaire	:	questionnaireLatestPayload,

	resetQuestionnaireState,
	updateQuestionnaireLatestPayload,
	setUnsavedStepsCount,
	saveQuestionnaireCurrentState,
	setAllQuestionsAnswered,
	setReportCaseId,

	exitConfirmPromptOptions, 
	makeConfirmPromptReadyToShow, 
	hideConfirmPrompt
}) => {
    const history = useHistory();
	const maxUnsavedSteps = 7;

	const {
		finalRequest,
		unSavedStepsCount,
		allQuestionsAnswered,
		reportCaseId,
		latestSaveRequestStatus
	} = questionnaireLatestPayload;

    const initStatus = useRef(false);

    const [ questionareSettings, setQuestionareSettings ] = useState({
		confirmFilledInputsForm	:	{
			enabled	:	false
		},
		buttons	:	{
			submit	:	{
				enabled	:	false,
				title	:	"Next"
			},
			back	:	{
				enabled	:	true,
				title	:	"Back",
				onClick	:	() => {
					onBackButtonClicked && onBackButtonClicked();
				}
			}
		}
	});

    const handleFetchPrevFilledAnswers = useQuery({
		queryKey: [
            '3rd-party-fetch-questionnaire-prev-answers-query',
            'tpdd',
            Organization.id
        ],
		queryFn: async () => {
			const service = QuestionnaireService.getInstance();
	
			return await service.fetchPrevFilledAnswers('tpdd', Organization.id, {});
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		enabled: true,
		retry: 0,
		onError: ({
			response
		}) => {
			if((response.data.error).toLowerCase() === 'there is not any report for this questionnaire'){
				axios.post(`${API_BASE_URL}/report.create`, {
					"questionnaireType"		:	'tpdd',
					"questionAnswers"	    :	[]
				}, {
					headers: {
						Authorization: `Bearer ${token}`,
					}
				})
				.then(({ data }) => {
					setReportCaseId(data.data.report_case_id);
				})
				.catch(({
                    response
                }) => {
                    hideConfirmPrompt();

                    if((response.data.error).toLowerCase() === 'there is not any report request'){
                        toast(t('It is not possible to send a report before the request is created by admin'), {
                            type: 'error',
                        });
                    }
                    else{
                        toast(t('An error occurred while starting!'), {
                            type: 'error',
                        });
                    }

                    history.push('/third-party/3rd-party/home');
				});
			}
		},
		onSuccess : (response) => {
			setReportCaseId(response.reportCaseId);
		}
	});

	useEffect(() => {
		resetQuestionnaireState();
	}, []);

	useEffect(() => {
        if(reportCaseId){
            handleFetchPrevFilledAnswers.refetch();
        }
	}, [reportCaseId]);

	useEffect(() => {
		if(allQuestionsAnswered){
			setQuestionareSettings({
				...questionareSettings,
				buttons : {
					...questionareSettings.buttons,
					submit: {
						...questionareSettings.buttons.submit,
						title	:	'Next',
						enabled : 	true
					}
				}
			});
		}
	}, [ allQuestionsAnswered ]);

	useEffect(() => {
		if(questionnaireLatestPayload.payload){
            setUnsavedStepsCount(unSavedStepsCount + 1);

			if(unSavedStepsCount >= maxUnsavedSteps || (finalRequest && unSavedStepsCount > 0)){
				saveQuestionnaireCurrentState();
			}
		}
	}, [ questionnaireLatestPayload.payload ]);

	useEffect(() => {
		if(unSavedStepsCount > 0){
			if(!exitConfirmPromptOptions.readyToShow){
				makeConfirmPromptReadyToShow({
					title       : (
						<div>
							<Spinner className="me-2" animation="border" variant="info" size="sm"/>
							{t('Saving the current state')}...
						</div>
					),
					message     : t("please don't close or refresh the page"),
					alertProps  : {
						...exitConfirmPromptOptions.alertProps,
						confirmBtn	:	{
							...exitConfirmPromptOptions.alertProps.confirmBtn,
							enabled	:	false
						},
						cancelBtn	:	{
							...exitConfirmPromptOptions.alertProps.cancelBtn,
							enabled	:	false
						}
					}
				});
			}
		}
	}, [ unSavedStepsCount ]);

	useEffect(() => {
		if(finalRequest){
            let currentTitle = 'Next'
            if(latestSaveRequestStatus === 'sending'){
                currentTitle = 'Please wait...';
            }

            setQuestionareSettings({
                ...questionareSettings,
                buttons : {
                    ...questionareSettings.buttons,
                    submit: {
                        ...questionareSettings.buttons.submit,
                        title	:	currentTitle
                    }
                }
            });
		}
	}, [ finalRequest, latestSaveRequestStatus ]);

	useEffect(() => {
		if(exitConfirmPromptOptions.show){
			saveQuestionnaireCurrentState();
		}
	}, [ exitConfirmPromptOptions.show ]);

	useEffect(() => {
		if(latestSaveRequestStatus !== 'unknown'){
			switch(latestSaveRequestStatus){
				case 'succeed':
						if(exitConfirmPromptOptions.show){
							exitConfirmPromptOptions.callbackFunc && exitConfirmPromptOptions.callbackFunc(true);
							hideConfirmPrompt();

							toast(t('Questionnaire saved successfully.'), {
								type: 'success',
							});
						}
					break;

				case 'failed':
						if(exitConfirmPromptOptions.show){
							hideConfirmPrompt();
						}

						toast(t('An error occurred while saving questionnaire!'), {
							type: 'error',
						});
					break;

				default:
			}
		}
	}, [ latestSaveRequestStatus ]);

    return (
        <Questionar 
            questionnaireId='tpdd'
            questionnaireType='tpdd'
            loading={ handleFetchPrevFilledAnswers.isFetching || handleFetchPrevFilledAnswers.isLoading }
            questions={ handleFetchPrevFilledAnswers?.data?.questions || [] }
            onValidSubmit={ (payload) => onValidSubmit && onValidSubmit({
				reportCaseId	:	reportCaseId,
                questions		: 	handleFetchPrevFilledAnswers.data.questions,
                answers   		:	payload
            })}
            onChange={ ({
				payload,
				isCompleted
			}) => {
                if(!initStatus.current){
                    initStatus.current = true

                    if(isCompleted){
                        setAllQuestionsAnswered(true)
                    }
                    return;
                }

				updateQuestionnaireLatestPayload({
					payload			:	payload,
					finalRequest	:	isCompleted,
				});
			}}
            user={{
                token: token,
                ...user
            }}
            settings={ questionareSettings }
            preFilledAnswers={ handleFetchPrevFilledAnswers?.data?.questions || [] }
        />
    )
};

const mapStatetoProps = (state) => {
	const { token, user } = state.Login;
	const { Organization, App, moduleTPDDQuestionnaire, ExitConfirmPrompt } = state;
	
	return {
		token,
		user,
		Organization,
		App,
		moduleTPDDQuestionnaire,
		exitConfirmPromptOptions	:	ExitConfirmPrompt
	};
};

export default withNamespaces()(
    withRouter(
        connect(mapStatetoProps, {
            updateQuestionnaireLatestPayload	:	(payload) => moduleTPDDUpdateQuestionnaireLatestPayload(payload),
            setUnsavedStepsCount				:	(payload) => moduleTPDDUpdateQuestionnaireUnsavedStepsCount({
                count	:	payload
            }),
            saveQuestionnaireCurrentState		:	(payload) => moduleTPDDSaveQuestionnaireCurrentState(payload),
            setAllQuestionsAnswered				:	(payload) => moduleTPDDUpdateQuestionnaireAllQuestionsAnsweredState({
                status	:	payload
            }),
            setReportCaseId						:	(payload) => moduleTPDDUpdateQuestionnaireReportCaseId({
                reportCaseId	:	payload
            }),
            resetQuestionnaireState				:	() => moduleTPDDResetQuestionnaireState(),
            makeConfirmPromptReadyToShow  		: 	(payload) => confirmPromptMakeReadyToShow(payload),
            hideConfirmPrompt             		: 	() => confirmPromptHide(),
            updateConfirmPromptAlertProps 		: 	(payload) => confirmPromptUpdateAlertProps(payload)
        })(
            FillQuestionnaire
        )
    )
);