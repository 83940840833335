import { uniqueKey } from './utils';

const CustomTableRow = ({ rowData, bold }) => {
	return (
		<div className='row-container'>
			{rowData.map((col, index) => (
				<div
					key={uniqueKey(`${col.value}-${index}`)}
					className='item-container'
					style={{
						flexGrow: 1,
						width: `${col.width}`,
					}}
				>
					{col.value}
				</div>
			))}
		</div>
	);
};

export default CustomTableRow;
