import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import { withNamespaces } from "react-i18next";
import { Card, CardBody, CardTitle } from "reactstrap";

class BarChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [],
      options: {},
      reload: false,
      processingTimeTooltip: false,
    };
  }

  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  render() {
    const {
        t,
        title
    } = this.props;
    return (
      <>
        <Card className="flex-grow-1 card_box_Shadow">
          <CardBody>
            <div className="mb-4">
              <CardTitle>
                { t(title) }
              </CardTitle>
            </div>
            <div className="mb-3">
              <ReactApexChart
                options={this.state.options}
                series={this.state.series}
                type="bar"
                height="200"
              />
            </div>
          </CardBody>
        </Card>
      </>
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data || prevProps.t !== this.props.t) {
      const label = Array.from(this.props.t("Day"))[0];

      const data = this.props.data;
      const items = [];
      const names = [];
      const colors = ["#00008B", "#DC143C", "#9400D3"];

      for (let i in data) {
        items.push({ x: this.props.t(i), y: parseInt(data[i]) });
        names.push(this.props.t(i));
      }

      this.setState({
        series: [
          {
            name: [],
            data: items,
          },
        ],
        options: {
          colors,
          legend: {
            show: true,
          },
          chart: {
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
              distributed: true,
            },
          },
          dataLabels: {
            enabled: true,
            position: "center",
            style: {
              fontSize: "12px",
            },

            formatter: function (val) {
              return val;
            },
          },

          tooltip: {
            theme: "light",
            enabled: false,
            followCursor: true,
          },
        },
      });
    }

    if (prevProps.t !== this.props.t) {
      this.setState({ reload: !this.state.reload });
    }
  }
}

export default withNamespaces()(BarChart);
