import { withNamespaces } from "react-i18next";
import { useEffect, useState } from "react";
import { withRouter, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { Col, Container, NavItem, NavLink, Row } from "reactstrap";
import { EditorState } from "draft-js";

import CompanyDetails from "./components/CompanyDetails";
import ContactPerson from "./components/ContactPerson";
import ReviewReport from "./components/ReviewReport";
import FactorySitesAndProducts from "./components/FactorySitesAndProducts";
import SuccessModal from "./components/SuccessModal";

import { convertToHTML, convertFromHTML } from "draft-convert";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import Spinner from 'react-bootstrap/Spinner';

import {
  confirmPromptMakeReadyToShow,
  confirmPromptHide,
  confirmPromptUpdateAlertProps
} from 'src/store/actions';

import ThirdpartyUsersService from "src/modules/3rd-party-management/apis/ThirdpartyUsersService";
import CommonService from "src/modules/3rd-party-management/apis/CommonService";
import PageHeader from "src/modules/3rd-party-management/components/page-header";

const thirdPartyService = new ThirdpartyUsersService();

const NewThirdParty = ({ 
    t, 
    user, 
    organization, 
    exitConfirmPromptOptions, 
    makeConfirmPromptReadyToShow, 
    hideConfirmPrompt, 
    updateConfirmPromptAlertProps 
}) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const supplierType = searchParams.get('type') === 'own' ? 'own' : 'creditor'

    const [countries, setCountries] = useState([]);
    const [openSuccessModal, setOpenSuccessModal] = useState(false);

    const [companyName, setCompanyName] = useState("");
    const [industries, setIndustries] = useState(null);
    const [companySize, setCompanySize] = useState(null);
    const [website, setWebsite] = useState("");
    const [description, setDescription] = useState(EditorState.createEmpty());
    const [legalEntities, setLegalEntities] = useState([]);
    const [vatNumber, setVatNumber] = useState("");
    const [selectedIndusries, setSelectedIndustries] = useState([]);
    const [selectedIndustryIds, setSelectedIndustryIds] = useState([]);

    const [officeAddressCount, setOfficeAddressCount] = useState(1);
    const [officeAddresses, setOfficeAddresses] = useState([]);

    const [factoryAddressCount, setFactoryAddressCount] = useState(1);
    const [factoryAddresses, setFactoryAddresses] = useState([]);

    const [addedProducts, setAddedProducts] = useState([]);
    const [addedProductsCount, setAddedProductsCount] = useState(0);

    const [contactCount, setContactCount] = useState(1);
    const [contacts, setContacts] = useState([]);

    const [ dataShouldBeSaved, setDataShouldBeSaved ] = useState(false);

    const [tabs, setTabs] = useState([
        {
            id: 1,
            name: "company-details",
            title: "Company Details",
            completed: false,
            isCurrent: true,
        },
        {
            id: 2,
            name: "factory-sites-and-products",
            title: "Factory Sites and Products",
            completed: false,
            isCurrent: false,
        },
        {
            id: 3,
            name: "contact-person",
            title: "Contact Person",
            completed: false,
            isCurrent: false,
        },
        {
            id: 4,
            name: "finalize",
            title: "Finalize",
            completed: false,
            isCurrent: false,
        },
    ]);

    const [activeTab, setActiveTab] = useState(tabs.find((t) => t.isCurrent));


    const renderStepsWizard = () => {
        const currentActiveTabIndex = tabs.findIndex((t) => t.isCurrent);

        return (
            <Row>
                <Col sm={12}>
                <div className="bg-white wizard-steps-container mb-4">
                    <div className="twitter-bs-wizard">
                    <ul className="twitter-bs-wizard-nav nav-justified nav nav-pills">
                        {tabs.map((tab, index) => {
                        return (
                            <NavItem
                            key={index}
                            className={tab.completed ? "completed" : ""}
                            >
                            <NavLink
                                className={
                                (index <= currentActiveTabIndex ? "active " : "") +
                                (tab.isCurrent ? "current" : "")
                                }
                            >
                                <span className="step-number">
                                {tab.completed ? (
                                    <i className="fa fa-check"></i>
                                ) : (
                                    index + 1
                                )}
                                </span>

                                <span className="step-title">{t(tab.title)}</span>
                            </NavLink>
                            </NavItem>
                        );
                        })}
                    </ul>
                    </div>
                </div>
                </Col>
            </Row>
        );
    };

    const setCurrentTabComplete = (name) => {
        const selectedTab = tabs.filter((t) => t.name === name)[0];
        const selectedTabId = selectedTab?.id;
        selectedTab.completed = true;
        selectedTab.isCurrent = false;
        goToNextTab(selectedTabId + 1);
    };

    const goToNextTab = (id) => {
        const selectedTab = tabs.filter((t) => t.id === id)[0];
        selectedTab.completed = false;
        selectedTab.isCurrent = true;
        setActiveTab(tabs.find((t) => t.isCurrent));
    };

    const goToPrevTab = (id) => {
        const selectedTab = tabs.filter((t) => t.id === id)[0];
        selectedTab.completed = false;
        selectedTab.isCurrent = true;
        setActiveTab(tabs.find((t) => t.isCurrent));
    };

    const goToSpecificTab = (id) => {
        const updatedTabs = tabs.map((tab) => {
            if (tab.id === id) {
                return { ...tab, isCurrent: true, completed: false }; 
            } else {
                return { ...tab, isCurrent: false }; 
            }
        });

        const newActiveTab = updatedTabs.find((tab) => tab.isCurrent);
        setActiveTab(newActiveTab); 
        setTabs(updatedTabs);
    };

    const fetchCountries = () => {
        return thirdPartyService.fetchCountries().then((res) => {
            const options = res.map((country) => {
                return {
                    value: country.id,
                    baseLabel: country.name,
                    label: t(country.name),
                    code: country.code,
                };
            });
            setCountries(options);
        });
    };

    const onFormSubmit = () => {
        const products = addedProducts.map((p) => {
            return {
                name: p.productName.value,
                description: p.description,
            };
        });

        const supplierLocations = [];

        officeAddresses.forEach((loc) => {
            supplierLocations.push({
                type: "office",
                country: loc?.country?.value,
                city: loc?.city,
                postalCode: loc?.postalCode, 
                address: loc?.extra,
            });
        });

        factoryAddresses.forEach((loc) => {
            if(loc?.country){
                supplierLocations.push({
                    type: "manufacture",
                    country: loc?.country?.value,
                    city: loc?.city,
                    postalCode: loc?.postalCode, 
                    address: loc?.extra
                });
            }
        });

        const languages = organization?.languages?.map(l => {
            return {
                name: l?.name,
                direction: l?.direction,
                language: l?.language?.id,
                isDefault: l?.is_default,
                module: l?.module?.id
            }
        });
        
        const data = {
            isOwnCompany: supplierType === 'own' ? true : undefined,
            token: localStorage.getItem("LOCAL_STORAGE_KEY_ACCESS_TOKEN"),
            name: companyName,
            languages: languages,
            products: products,
            supplierLocations: supplierLocations,
            industries: selectedIndusries.map(industry => String(industry?.value)),
            companySize: companySize?.value,
            vatNumber: vatNumber,
            websiteUrl: website,
            description: description.getCurrentContent().hasText()
                ? convertToHTML(description.getCurrentContent())
                : "",
            isPartner: 0,
            partnerAnalysts: [String(user?.id)],
            partner: Number(organization?.customerId),
            hasDashboard: 0,
            subscriptionType: ""
        };

        if(supplierType === 'own'){
            data.responsiblePersons = contacts.map((p) => {
                return p.id.toString()
            })

            data.legalEntities = [legalEntities.value.toString()]

            data.countryCode = (countries.find((country) => country.value === officeAddresses[0]?.country?.value))?.code
        }
        else{
            data.contactPersons = contacts.map((c) => {
                return {
                    title       : c?.title,
                    name        : c?.firstName,
                    lastName    : c?.lastName,
                    email       : c?.email,
                    phoneNumber : c?.phoneNumber,
                    position    : c?.position,
                    language    : c?.language?.value,
                };
            });

            data.legalEntities = legalEntities.map((legal) => {
                return legal.value.toString()
            })

            
            data.countryCode = officeAddresses[0]?.country?.code
        }

        handleCreateThirdPartyMutation.mutate(data)
    };

    const handleCreateThirdPartyMutation = useMutation({
        mutationFn: async (payload) => {
            const service = ThirdpartyUsersService.getInstance();

            return await service.create(payload);
        },
        onSuccess: () => {
            removeDataFromStorage();
            hideConfirmPrompt();
            setOpenSuccessModal(true);
        },
        onError: () => {
            toast(t("An error occurred while creating third party."), {
                type: "error",
            });
        },
    });

    const {
        data        :   selectedLegalData,
        refetch     :   fetchLegalEntityDetailsQuery
    } = useQuery({
        queryKey: ['3rd-party-management-fetch-legal-entity-details', legalEntities?.value],
        queryFn: async () => {
            const service = CommonService.getInstance();

            return await service.fetchLegalEntityDetails(legalEntities.value);
        },
        enabled: false,
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onError: () => {
            toast(t('An error occurred while fetching the details of entity.'), {
                type: 'error',
            });
        }
    });

    const saveDataOnTheStorage = () => {
        return new Promise((res, rej) => {
            updateConfirmPromptAlertProps({
                ...exitConfirmPromptOptions.alertProps,
                confirmBtn  : {
                    ...exitConfirmPromptOptions.alertProps.confirmBtn,
                    title   : (
                        <>
                        <Spinner className="me-2" animation="border" variant="white" size="sm"/>Saving...
                        </>
                    ),
                    loading : true
                }
            });

            try{
                const generalInfosData = {
                    companyName   :   companyName,
                    industries      :   industries,
                    companySize   :   companySize,
                    website       :   website,
                    legalEntities :   legalEntities,
                    vatNumber     :   vatNumber,
                    description   :   description.getCurrentContent().hasText() ? convertToHTML(description.getCurrentContent()) : "",
                };
        
                localStorage.setItem('MODULE_TPDD_NEW_THIRDPARTY_SAVED_DATA', JSON.stringify({
                    generalInfosData  : generalInfosData,
                    officeAddresses   : (officeAddresses && officeAddresses.length > 0) ? officeAddresses : undefined,
                    factoryAddresses  : (factoryAddresses && factoryAddresses.length > 0) ? factoryAddresses : undefined,
                    products          : (addedProducts && addedProducts.length > 0) ? addedProducts : undefined
                }));

                res();
            }
            catch(error){
                rej();
            }
        });
    }

    const removeDataFromStorage = () => {
        localStorage.removeItem('MODULE_TPDD_NEW_THIRDPARTY_SAVED_DATA');
    }

    useEffect(() => {
        if (!countries.length) {
            fetchCountries();
        }

        const prevData = localStorage.getItem('MODULE_TPDD_NEW_THIRDPARTY_SAVED_DATA');

        if(prevData){
            try{
                const parsedData = JSON.parse(prevData);

                if(parsedData?.generalInfosData){
                    setCompanyName(parsedData.generalInfosData?.companyName || '');
                    setIndustries(parsedData.generalInfosData?.industries || null);
                    setCompanySize(parsedData.generalInfosData?.companySize || null);
                    setLegalEntities(parsedData.generalInfosData?.legalEntities || []);
                    setVatNumber(parsedData.generalInfosData?.vatNumber || '');
                    setWebsite(parsedData.generalInfosData?.website || '');
                    setDescription(EditorState.createWithContent(convertFromHTML(parsedData.generalInfosData?.description || '')));
                }

                if(parsedData?.factoryAddresses && Array.isArray(parsedData.factoryAddresses)){
                    setFactoryAddresses(parsedData.factoryAddresses);
                    setFactoryAddressCount(parsedData.factoryAddresses.length);
                }

                if(parsedData?.officeAddresses && Array.isArray(parsedData.officeAddresses)){
                    setOfficeAddresses(parsedData.officeAddresses);
                    setOfficeAddressCount(parsedData.officeAddresses.length);
                }

                if(parsedData?.products && Array.isArray(parsedData.products)){
                    setAddedProducts(parsedData.products);
                    setAddedProductsCount(parsedData.products.length);
                }
            }
            catch(error){
                removeDataFromStorage();
            }
        }
    }, []);

    useEffect(() => {
        if(exitConfirmPromptOptions.show){
            const continueAction = () => {
                exitConfirmPromptOptions.callbackFunc && exitConfirmPromptOptions.callbackFunc(true);
                hideConfirmPrompt();
            }

            if(exitConfirmPromptOptions.confirmStatus){
                saveDataOnTheStorage()
                .then(() => continueAction())
                .catch(() => {
                    toast(t("An error occurred while saving data."), {
                        type: "error",
                    });

                    continueAction();
                });
            }
            else{
                removeDataFromStorage();

                setTimeout(() => {
                    continueAction();
                }, 250);
            }
        }
    }, [ exitConfirmPromptOptions.confirmStatus ]);

    useEffect(() => {
        setSelectedIndustryIds(selectedIndusries.map(industry => industry.value));
    }, [selectedIndusries]);

    useEffect(() => {
        if(dataShouldBeSaved && !exitConfirmPromptOptions.readyToShow){
            makeConfirmPromptReadyToShow({
                title       : t('As you are leaving the page'),
                message     : t('Do you want to save the current state to continue later?'),
                alertProps  : {
                ...exitConfirmPromptOptions.alertProps,
                    confirmBtn  : {
                        ...exitConfirmPromptOptions.alertProps.confirmBtn,
                        enabled : true,
                        title : t('Yes, save the current state')
                    },
                    cancelBtn  : {
                        ...exitConfirmPromptOptions.alertProps.cancelBtn,
                        enabled : true,
                        title : t('No')
                    }
                }
            });
        }
    }, [ dataShouldBeSaved ]);

    useEffect(() => {
        if(supplierType === 'own' && legalEntities?.value){
            fetchLegalEntityDetailsQuery()
        }
    }, [ legalEntities ]);

    useEffect(() => {
        const gotLocations = selectedLegalData?.legal_entity_locations;

        if(gotLocations){
            setOfficeAddresses(gotLocations.map((loc) => {
                return {
                    country: {
                        value: loc.country.id,
                        label: t(loc.country.name),
                        baseLabel: loc.country.name
                    },
                    city: loc.city,
                    id: loc.id,
                    postalCode: loc.postal_code,
                    extra: loc.address
                }
            }));

            setOfficeAddressCount(gotLocations.length);
        }
    }, [ selectedLegalData ]);

    useEffect(() => {
        setCountries((currentState) => {
            return currentState.map((country) => {
                return {
                    ...country,
                    label: t(country.baseLabel)
                }
            })
        })
    }, [ t ]);
    
    return (
        <div className="page-content new-thirdparty-page">
            {openSuccessModal && <SuccessModal supplierType={supplierType} setModal={setOpenSuccessModal} modal={openSuccessModal} />}
            <Container fluid>
                <PageHeader title={t('New Third Party')} />

                {renderStepsWizard()}

                {activeTab.name === "company-details" ? (
                    <CompanyDetails
                        setCompanyName={setCompanyName}
                        setIndustries={setIndustries}
                        setCompanySize={setCompanySize}
                        setDescription={setDescription}
                        setWebsite={setWebsite}
                        companyName={companyName}
                        website={website}
                        industries={industries}
                        companySize={companySize}
                        description={description}
                        legalEntities={legalEntities}
                        setLegalEntities={setLegalEntities}
                        vatNumber={vatNumber}
                        setVatNumber={setVatNumber}
                        selectedIndusries={selectedIndusries}
                        setSelectedIndustries={setSelectedIndustries}
                        setCompanyDetailsTabCompleted={(payload) => {
                            setCurrentTabComplete(payload);
                            setDataShouldBeSaved(true);
                        }}
                        supplierType={supplierType}
                    />
                ) : activeTab.name === "factory-sites-and-products" ? (
                    <FactorySitesAndProducts
                        selectedIndustryIds={ selectedIndustryIds }
                        countries={countries}
                        setCompanyDetailsTabCompleted={setCurrentTabComplete}
                        goToPrevTab={goToPrevTab}
                        officeAddressCount={officeAddressCount}
                        setOfficeAddressCount={setOfficeAddressCount}
                        officeAddresses={officeAddresses}
                        setOfficeAddresses={setOfficeAddresses}
                        factoryAddressCount={factoryAddressCount}
                        setFactoryAddressCount={setFactoryAddressCount}
                        factoryAddresses={factoryAddresses}
                        setFactoryAddresses={setFactoryAddresses}
                        addedProducts={addedProducts}
                        setAddedProducts={setAddedProducts}
                        addedProductsCount={addedProductsCount}
                        setAddedProductsCount={setAddedProductsCount}
                        supplierType={supplierType}
                    />
                ) : activeTab.name === "contact-person" ? (
                    <ContactPerson
                        setCompanyDetailsTabCompleted={setCurrentTabComplete}
                        goToPrevTab={goToPrevTab}
                        contacts={contacts}
                        setContacts={setContacts}
                        contactCount={contactCount}
                        setContactCount={setContactCount}
                        supplierType={supplierType}
                    />
                ) : activeTab.name === "finalize" ? (
                    <ReviewReport
                        goToSpecificTab={goToSpecificTab}
                        goToPrevTab={goToPrevTab}
                        onFormSubmit={onFormSubmit}

                        isLoading={handleCreateThirdPartyMutation.isLoading}
                        companyName={companyName}
                        industries={selectedIndusries}
                        companySize={companySize}
                        website={website}
                        description={description}

                        officeAddresses={officeAddresses}
                        factoryAddresses={factoryAddresses}
                        addedProducts={addedProducts}
                        legalEntities={legalEntities}
                        vatNumber={vatNumber}

                        contacts={contacts}
                        supplierType={supplierType}
                    />
                ) : null}
            </Container>
        </div>
    );
};

const mapStatetoProps = (state) => {
    const { token } = state.Login;
    const { Organization } = state;
    return {
        user: state.Login.user,
        token,
        organization: Organization,
        exitConfirmPromptOptions : state.ExitConfirmPrompt
    };
};

export default withNamespaces()(
    withRouter(connect(mapStatetoProps, {
        makeConfirmPromptReadyToShow  : (payload)  =>  confirmPromptMakeReadyToShow(payload),
        hideConfirmPrompt             : ()  =>  confirmPromptHide(),
        updateConfirmPromptAlertProps : (payload)  =>  confirmPromptUpdateAlertProps(payload)
    })(NewThirdParty))
);
