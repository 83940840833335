import axios from "axios";
import { API_BASE_URL } from "../constants";

export default class DataProtectionOnboardingService {
  static instance;
  resource = `${API_BASE_URL}/onboarding/`;

  constructor() {}

  static getInstance() {
    if (!DataProtectionOnboardingService.instance) {
      DataProtectionOnboardingService.instance =
        new DataProtectionOnboardingService();
    }

    return DataProtectionOnboardingService.instance;
  }

  // handle file upload
  async uploadOnboardingFile(file) {
    const formData = new FormData();
    formData.append("file", file);

    const response = await axios.post(`${this.resource}file/upload`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("LOCAL_STORAGE_KEY_ACCESS_TOKEN")}`,
      },
    });

    return response.data;
  }

   // Start processing
   async startProcessing() {
    const response = await axios.post(`${this.resource}processing/start`, null, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("LOCAL_STORAGE_KEY_ACCESS_TOKEN")}`,
      },
    });

    return response.data;
  }

  // Fetch the file processing status
  async getProcessingStatus() {
    const response = await axios.get(`${this.resource}processing/processing_status`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("LOCAL_STORAGE_KEY_ACCESS_TOKEN")}`,
      },
    });

    return response.data.data;
  }

  // Reset processing status
  async resetProcessing() {
    const response = await axios.post(`${this.resource}processing/restart`, {}, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("LOCAL_STORAGE_KEY_ACCESS_TOKEN")}`,
      },
    });

    return response.data;
  }
}
