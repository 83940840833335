import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import {
  AUTH_CURRENT_USER,
  CHECK_LOGIN,
  LOGOUT_USER,
  REGISTER_USER,
} from "./actionTypes";

import axios from "axios";

import i18n, {fetch, getCurrentLanguage} from "src/i18n";

import {
  apiError,
  loginUserSuccessful,
  logoutUserSuccess,
  changeWorkspace,
  appLoadStatusChanged,
  registerUserSuccessful,
  loginErrorFlag,
} from "../../actions";

import { LOCAL_STORAGE_KEY_ACCESS_TOKEN } from "../../../services/constants/LocalStorage";

import { post } from "../../../helpers/request_helper";

import {
  API_URL_SIGN_IN,
  API_URL_REGISTER,
  API_URL_GET_USER_DETAIL,
} from "../../../common/constants";

import store from "../../";
import UserUtils from "../../../services/utils/UserUtils";

const userUtils = new UserUtils();

const sendAuthUserRequest = () => {
  const { token } = store.getState().Login;
  const { id : orgId } = store.getState().Organization;

  return axios.post(
    API_URL_GET_USER_DETAIL,
    {
      organizationId: orgId,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  ).then((response) => {	
    const lng = response.data.data?.settings?.lang;
    const prevLng = getCurrentLanguage();
    if(lng && lng !== prevLng){
        localStorage.setItem('i18nextLng', lng);
        fetch(lng);
        i18n.changeLanguage(lng.replace('-', '_'));
    }

    if (response.data.data.settings?.direction) {
        localStorage.setItem('lang_dir', response.data.data.settings.direction);
    }
    return response.data.data;
  }).catch((err) => {
    throw err.response.data.error;
  });
};

// Is user register successfull.
function* registerUser({ payload: { user } }) {
  try {
    const response = yield call(
      post,
      API_URL_REGISTER,
      {
        email: user.email || "",
        username: user.username,
        plainPassword: user.password,
        organization: user.organization,
      },
      {}
    );
    yield put(apiError(null));
    yield put(
      registerUserSuccessful({
        ...response.data.data.profile,
        needExchange: !!response.data.data.needExchange,
        tempToken: response.data.data.token,
      })
    );
    yield put(appLoadStatusChanged(true));
  } catch (error) {
    console.log("Error(registerUser): ", error);
  }
}

//
export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser);
}

function* authCurrentUserOnRemote() {
  try {
    const response = yield call(sendAuthUserRequest);
    const workspace = userUtils.getWorkspace(response);
    yield put(apiError(null));
    yield put(
      loginUserSuccessful({
        ...response,
      })
    );
    yield put(changeWorkspace(workspace));
  } catch (error) {
    localStorage.removeItem(LOCAL_STORAGE_KEY_ACCESS_TOKEN);
    yield put(logoutUserSuccess());
    yield put(apiError(error));
  }
}

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(
      post,
      API_URL_SIGN_IN,
      {
        "2fa": true,
        username: user.username,
        password: user.password,
        organization: user.organization,
        isThirdPartyUser: user.isThirdPartyUser,
      },
      {}
    );
    yield put(apiError(null));
    yield put(
      loginUserSuccessful({
        ...response.data.data.profile,
        needExchange: !!response.data.data.needExchange,
        tempToken: response.data.data.token,
      })
    );
    yield put(appLoadStatusChanged(true));
  } catch (error) {
    yield put(loginErrorFlag(true));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem(LOCAL_STORAGE_KEY_ACCESS_TOKEN);
    yield put(logoutUserSuccess());

    let location = window.location.href.split("/");

    // Testing.
    if (location.includes("third-party")) {
      if (
        !location.includes("signin") &&
        !location.includes("register") &&
        !location.includes("home")
      ) {
        window.location.href = "home";
      }
    } else {
      if (!location.includes("signin") && !location.includes("register")) {
        window.location.href = "signin";
      }
    }
  } catch (error) {}
}

export function* watchUserLogin() {
  yield takeEvery(CHECK_LOGIN, loginUser);
}

export function* watchUserLogout() {
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export function* watchAuthCurrentUser() {
  yield takeEvery(AUTH_CURRENT_USER, authCurrentUserOnRemote);
}

function* loginSaga() {
  yield all([
    fork(watchUserRegister),
    fork(watchUserLogin),
    fork(watchUserLogout),
    fork(watchAuthCurrentUser),
  ]);
}

export default loginSaga;
