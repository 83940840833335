import { memo, useEffect, useState } from 'react';
import { Col, Row, Label, Tooltip} from 'reactstrap';
import { AvField } from 'availity-reactstrap-validation';
import { withNamespaces } from 'react-i18next';
import Switcher from '../../../../components/Switcher';

const Textbase = (props) => {
    const {
        questionIndex,
        id, 
        name,
        title, 
        type,
        isMandatory, 
        key,
        elRefIdPrefix,
        aiSuggestion,
        helpText,
        defaultValue,
        onChange,
        onTypingStoped
    } = props;

    const [isAISwitcherToggled, setAISwitcherToggled] = useState(false);
    const [inputValue, setInputValue] = useState(defaultValue || '');
    const [helpTooltipTextStatus, setHelpTooltipTextStatus] = useState(false);
    // const [typingStarted, setTypingStarted] = useState(false);

    useEffect(() => {
        if(isAISwitcherToggled && aiSuggestion){
            setInputValue(props.t(aiSuggestion));
        }
        else{
            setInputValue(defaultValue || '');
        }
    }, [isAISwitcherToggled]);

    // useEffect(() => {
    //     if(typingStarted && onTypingStoped){
    //         const id = setTimeout(() => {
    //             onTypingStoped();
    //         }, 1500);
    
    //         return () => clearTimeout(id);
    //     }
    // }, [inputValue])

    return (
        <Row key={key}>
             <Col sm="12">
                <div className="mb-3 position-relative">
                    <Label
                        className="form-label"
                        htmlFor={`${elRefIdPrefix}-question-${id}`}>
                            {questionIndex} {props.t(title)}

                        {
                            helpText && (
                                <span className="ms-2 tooltip-container">
                                    <i
                                        className="fa fa-info-circle help-text-icon"
                                        aria-hidden="true"
                                        id={`${elRefIdPrefix}-question-${id}-help-text`}
                                    />
                                    <Tooltip
                                        style={{
                                            backgroundColor: '#899ba1',
                                            fontWeight: 'bold'
                                        }}
                                        placement="top"
                                        isOpen={ helpTooltipTextStatus }
                                        target={`${elRefIdPrefix}-question-${id}-help-text`}
                                        toggle={() =>
                                            setHelpTooltipTextStatus(!helpTooltipTextStatus)
                                        }>
                                            {
                                                <p>
                                                    {props.t(helpText)}
                                                </p>
                                            }
                                    </Tooltip>
                                </span>
                            )
                        }
                    </Label>
                    <AvField
                        name={name ? name : ("question[" + id + "]")}
                        rows={5}
                        placeholder=""
                        type={type}
                        errorMessage={props.t(
                            "This field cannot be blank"
                        )}
                        className="form-control with-ai-suggestion-switcher"
                        validate={{
                            required: { value: isMandatory },
                        }}
                        value={inputValue}
                        onBlur={(e) => {
                            if(e.target.value !== inputValue){
                                setInputValue(e.target.value);
                                onChange && onChange(e);
                            }
                        }}
                        // onChange={(e) => {
                        //     setInputValue(e.target.value);
                        //     onChange && onChange(e);
                        //     setTypingStarted(true);
                        // }}
                        id={`${elRefIdPrefix}-question-${id}`} />
                        {
                            aiSuggestion ? 
                            <Switcher
                                className="ai-suggestion-switcher"
                                isToggled={isAISwitcherToggled}
                                onToggle={() => setAISwitcherToggled(!isAISwitcherToggled)} /> : null
                        }
                </div>
            </Col>
        </Row>
    );
}

export default withNamespaces()(memo(Textbase));