import userLightIcon from 'src/modules/data-protection/assets/images/filter_user_light.svg';
import userBlueIcon from 'src/modules/data-protection/assets/images/filter_user_blue.svg';

import userRoleLightIcon from 'src/modules/data-protection/assets/images/filter_user_role_light.svg';
import userRoleBlueIcon from 'src/modules/data-protection/assets/images/filter_user_role_blue.svg';

import calendarLightIcon from 'src/modules/data-protection/assets/images/filter_calendar_light.svg';
import calendarBlueIcon from 'src/modules/data-protection/assets/images/filter_calendar_blue.svg';

import activityLightIcon from 'src/modules/data-protection/assets/images/filter_activity_light.svg';
import activityBlueIcon from 'src/modules/data-protection/assets/images/filter_activity_blue.svg';

export const icons = {
    date    :   {
        default :   calendarLightIcon,
        active  :   calendarBlueIcon
    },
    user    :   {
        default :   userLightIcon,
        active  :   userBlueIcon
    },
    role    :   {
        default :   userRoleLightIcon,
        active  :   userRoleBlueIcon
    },
    activity    :   {
        default :   activityLightIcon,
        active  :   activityBlueIcon
    },
};