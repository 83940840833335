import { Fragment } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import UserUtils from 'src/services/utils/UserUtils';
import DateUtils from 'src/services/utils/DateUtils';

import {
    INTERNATIONAL_DATE_TIME_FORMAT
} from 'src/common/constants';

import { markNotificationAsRead, removeNotificationInList } from 'src/store/actions';
import { Spinner } from 'reactstrap';
import UrlUtils from 'src/services/utils/UrlUtils';

const Handler = ({
    notifications,
    workspace,
    user,
    t,
    removeNotification,
    markAsRead
}) => {
    const userUtils = new UserUtils();
    const urlUtils = new UrlUtils();
    const dateUtils = new DateUtils();

    const handleTranslateOnType = (notification) => {
		const notificationType = notification.message_type;
		const secondaryTaskId = notification.data?.secondaryTaskId;
		const secondaryCaseId = notification.data?.secondaryId;
		const secondaryRiskId = notification.data?.secondaryRiskId;
		const riskRate = notification.data?.riskRate;
		const isIncident =notification.data?.questionnaire === "data_breach_incident" ? true : null ;
		const isRequest =notification.data?.questionnaire === "data_subject_request" ? true : null ;
		const isSoftware =notification.data?.software ? true : null ;
        const SoftwareID =notification.data?.secondaryId;

		switch (notificationType) {
            case "new_chat": {
                return t("You have received a new live chat request");
            }
            case "new_comment": {
                return (
                    <Fragment>
                        <span style={{ display: "block" }}>
                            {isSoftware ? t("Your software has a new comment") :
                            isRequest  ? t("Your DSR has a new comment") :
                            isIncident ? t('Your incident has a new comment') :
                            t("Your report has a new comment")}
                        </span>

                        <span style={{ display: 'block' }}>
                            {isRequest ? t('Request ID') :
                            isIncident ? t('Incident ID') :
                            isSoftware ? t('Software ID') :
                            t('Report ID')
                            }: {isSoftware ? SoftwareID : secondaryCaseId }
                        </span>
                    </Fragment>
                );
            }
            case "new_internal_comment": {
                return (
                    <Fragment>
                        <span style={{ display: "block" }}>
                            {isSoftware ? t("Your software has a new internal comment") :
                            isRequest  ? t("Your DSR has a new internal comment") :
                            isIncident ? t('Your incident has a new internal comment') :
                            t("Your report has a new internal comment")}
                        </span>

                        <span style={{ display: 'block' }}>
                            {isRequest ? t('Request ID') :
                            isIncident ? t('Incident ID') :
                            isSoftware ? t('Software ID') :
                            t('Report ID')
                            }: {isSoftware ? SoftwareID : secondaryCaseId }
                        </span>
                    </Fragment>
                );
            }
            case "open": {
                return  <>{isSoftware ? t("Your software has been received and read") :
                isRequest  ? t("Your DSR has been received and read") :
                isIncident ? t("Your incident has been received and read") :
                t("Your report has been received and read")}</>
            }
            case "in_progress": {
                return (
                    <Fragment>
                        {isSoftware ? t("Your software status changed to In Progress") :
                        isRequest  ? t("Your DSR status changed to In Progress") :
                        isIncident ? t("Your incident status changed to In Progress") :
                        t("Your report status changed to In Progress")}
                    </Fragment>
                );
            }
            
            case "closed": {
                return (
                    <Fragment>
                        {isSoftware ? t("Your software status changed to Closed") :
                        isRequest  ? t("Your DSR status changed to Closed") :
                        isIncident ? t("Your incident status changed to Closed") :
                        t("Your report status changed to Closed")}
                    </Fragment>
                );
            }
            case "deleted": {
                return (
                    <Fragment>
                        {isSoftware ? t("Your software has been deleted") :
                        isRequest  ? t("Your DSR has been deleted") :
                        isIncident ? t("Your incident has been deleted") :
                        t("Your report has been deleted")}
                    </Fragment>
                );
            }
            case "pending_delete": {
                return (
                    <Fragment>
                        {isSoftware ? t("Your software has been deleted") :
                        isRequest  ? t("Your DSR has been deleted") :
                        isIncident ? t("Your incident has been deleted") :
                        t("Your report has been deleted")}
                    </Fragment>
                );
            }
            case "new": {
                return (
                    <Fragment>
                        <span style={{ display: "block" }}>
                        {isSoftware ? t("Your software has been submitted") :
                        isRequest  ? t("Your report has been submitted") :
                        isIncident ? t("Your incident has been submitted") :
                        t("Your request or report has been submitted.")}
                        </span>
                        {isSoftware ? SoftwareID : secondaryCaseId !==undefined 
                        ? "\n" + t("ID") + ": " + secondaryCaseId
                        : ""}
                    </Fragment>
                );
            }
            case "new_case_reported": {
                return (
                    <Fragment>
                        <span style={{ display: "block" }}>
                        {isSoftware ? t("You have received a new software") :
                        isRequest  ? t("You have received a new DSR") :
                        isIncident ? t("You have received a new incident") :
                        t("You have received a new report")}
                        </span>
                        <span style={{ display: 'block' }}>
                                {isRequest ? t('Request ID') :
                                isIncident ? t('Incident ID') :
                                isSoftware ? t('Software ID') :
                                t('Report ID')
                                }: {isSoftware ? SoftwareID : secondaryCaseId }
                            </span>
                    </Fragment>
                );
            }
            case "assigned": {
                return (
                    <Fragment>
                        <span style={{ display: "block" }}>
                        {isSoftware ? t("Your software has been assigned to an analyst") :
                        isRequest  ? t("Your DSR has been assigned to an analyst") :
                        isIncident ? t("Your incident has been assigned to an analyst") :
                        t("Your report has been assigned to an analyst")}
                        </span>
                    </Fragment>
                );
            }
            case "new_case_assigned": {
                return (
                    <Fragment>
                        <span style={{ display: "block" }}>
                        {isSoftware ? t("A software was assigned to you") :
                        isRequest  ? t("A DSR was assigned to you") :
                        isIncident ? t("An incident was assigned to you") :
                        t("A report was assigned to you")}
                        </span>
                        <span style={{ display: 'block' }}>
                                {isRequest ? t('Request ID') :
                                isIncident ? t('Incident ID') :
                                isSoftware ? t('Software ID') :
                                t('Report ID')
                                }: {isSoftware ? SoftwareID : secondaryCaseId }
                            </span>
                    </Fragment>
                );
            }
            case "new_attachment": {
                return (
                    <Fragment>
                        {isSoftware ? t("You have received an update on your software") :
                        isRequest  ? t("You have received an update on your DSR") :
                        isIncident ? t("You have received an update on your incident") :
                        t("You have received an update on your report")}
                    </Fragment>
                );
            }
            case "case_had_been_read": {
                return (
                    <Fragment>
                        {isSoftware ? t("Your software has been read") :
                        isRequest  ? t("Your DSR has been read") :
                        isIncident ? t("Your incident has been read") :
                        t("Your report has been read")}
                    </Fragment>
                );
            }
            case "case_in_progress": {
                return (
                    <Fragment>
                        {isSoftware ? t("An Analyst is working on your software") :
                        isRequest  ? t("An Analyst is working on your DSR") :
                        isIncident ? t("An Analyst is working on your incident") :
                        t("An Analyst is working on your report")}
                    </Fragment>
                );
            }
            case "case_had_been_closed": {
                return (
                    <Fragment>
                        {isSoftware ? t("Your software has been closed") :
                        isRequest  ? t("Your DSR has been closed") :
                        isIncident ? t("Your incident has been closed") :
                        t("Your report has been closed")}
                    </Fragment>
                );
            }
            case "sip_system_update": {
                return "Smart Integrity Platform " + notification?.data?.caseId
                ? t("Report update")
                : t("System update");
            }
            case "risk_assign": {
                return (
                    <Fragment>
                        <span style={{ display: "block" }}>
                            {t("A new risk has been assigned to you.")}
                        </span>
                        <span style={{ display: "block" }}>
                            {t("ID")}: {secondaryRiskId}
                        </span>
                    </Fragment>
                );
            }
            case "tom_before_revision": {
                return (
                    <Fragment>
                        <span style={{ display: "block" }}>
                            {t("The deadline for the tom revision is soon.")}
                        </span>
                    </Fragment>
                );
            }
            case "tom_assigned": {
                return (
                    <Fragment>
                        <span style={{ display: "block" }}>
                            {t("The TOM is assigned to you.")}
                        </span>
                    </Fragment>
                );
            }
            case "risk_comment": {
                return (
                    <Fragment>
                        <span style={{ display: "block" }}>
                            {t("A comment was added to your risk.")}
                        </span>
                        <span style={{ display: "block" }}>
                            {t("ID")}: {secondaryRiskId}
                        </span>
                    </Fragment>
                );
            }
            case "new_task_created": {
                return (
                    <Fragment>
                        <span style={{ display: "block" }}>
                            {t("A new task has been assigned to you.")}
                        </span>
                        <span style={{ display: "block" }}>
                            {t("ID")}: {secondaryTaskId}
                        </span>
                    </Fragment>
                );
            }
            case "task_status_new": {
                return (
                    <Fragment>
                        <span style={{ display: "block" }}>
                            {t("The status of your task has been changed.")}
                        </span>
                        <span style={{ display: "block" }}>
                            {t("ID")}: {secondaryTaskId}
                        </span>
                        <span style={{ display: "block" }}>
                            {t("New status")}: {t("New")}
                        </span>
                    </Fragment>
                );
            }
            case "task_status_in_progress": {
                return (
                    <Fragment>
                        <span style={{ display: "block" }}>
                            {t("The status of your task has been changed.")}
                        </span>
                        <span style={{ display: "block" }}>
                            {t("ID")}: {secondaryTaskId}
                        </span>
                        <span style={{ display: "block" }}>
                            {t("New status")}: {t("In Progress")}
                        </span>
                    </Fragment>
                );
            }
            case "task_status_review": {
                return (
                    <Fragment>
                        <span style={{ display: "block" }}>
                            {t("The status of your task has been changed.")}
                        </span>
                        <span style={{ display: "block" }}>
                            {t("ID")}: {secondaryTaskId}
                        </span>
                        <span style={{ display: "block" }}>
                            {t("New status")}: {t("Review")}
                        </span>
                    </Fragment>
                );
            }
            case "task_status_delete": {
                return (
                    <Fragment>
                        <span style={{ display: "block" }}>
                            {t("The status of your task has been changed.")}
                        </span>
                        <span style={{ display: "block" }}>
                            {t("ID")}: {secondaryTaskId}
                        </span>
                        <span style={{ display: "block" }}>
                            {t("New status")}: {t("Deleted")}
                        </span>
                    </Fragment>
                );
            }
            case "task_status_close": {
                return (
                    <Fragment>
                        <span style={{ display: "block" }}>
                            {t("The status of your task has been changed.")}
                        </span>
                        <span style={{ display: "block" }}>
                            {t("ID")}: {secondaryTaskId}
                        </span>
                        <span style={{ display: "block" }}>
                            {t("New status")}: {t("Closed")}
                        </span>
                    </Fragment>
                );
            }
            case "task_priority_low": {
                return (
                    <Fragment>
                        <span style={{ display: "block" }}>
                            {t("The priority of your task has been changed.")}
                        </span>
                        <span style={{ display: "block" }}>
                            {t("ID")}: {secondaryTaskId}
                        </span>
                        <span style={{ display: "block" }}>
                            {t("New priority")}: {t("Low")}
                        </span>
                    </Fragment>
                );
            }
            case "task_priority_medium":
                return (
                    <Fragment>
                        <span style={{ display: "block" }}>
                            {t("The priority of your task has been changed.")}
                        </span>
                        <span style={{ display: "block" }}>
                            {t("ID")}: {secondaryTaskId}
                        </span>
                        <span style={{ display: "block" }}>
                            {t("New priority")}: {t("Medium")}
                        </span>
                    </Fragment>
                );
            case "task_priority_high":
                return (
                    <Fragment>
                        <span style={{ display: "block" }}>
                            {t("The priority of your task has been changed.")}
                        </span>
                        <span style={{ display: "block" }}>
                            {t("ID")}: {secondaryTaskId}
                        </span>
                        <span style={{ display: "block" }}>
                            {t("New priority")}: {t("High")}
                        </span>
                    </Fragment>
                );
            case "task_comment":
                return (
                    <Fragment>
                        <span style={{ display: "block" }}>
                            {t("A comment was added to your task")}.
                        </span>
                        <span style={{ display: "block" }}>
                            {t("ID")}: {secondaryTaskId}
                        </span>
                    </Fragment>
                );
            case "task_before_overdue": {
                return (
                    <Fragment>
                        <span style={{ display: "block" }}>
                            {t("The deadline for the task is soon.")}
                        </span>
                        <span style={{ display: "block" }}>
                            {notification?.data?.secondaryTaskId
                                ? "\n" +
                                t("Task ID") +
                                ": " +
                                notification.data.secondaryTaskId
                                : notification?.data?.taskId
                                ? "\n" +
                                t("Task ID") +
                                ": " +
                                notification?.data?.taskId
                                : ""}
                        </span>
                    </Fragment>
                );
            }
            case "new_task": {
                return (
                    <span style={{ display: "block" }}>
                        {t("You created a new task")}.
                    </span>
                );
            }
            case "task_change_deadline": {
                return (
                    <Fragment>
                        <span style={{ display: "block" }}>
                            {t("Deadline of your task was changed")}.
                        </span>
                        <span style={{ display: "block" }}>
                            {t("ID")}: {secondaryTaskId}
                        </span>
                    </Fragment>
                );
            }
            case "task_change_start_date": {
                return (
                    <Fragment>
                        <span style={{ display: "block" }}>
                            {t("Start date of your task was changed")}.
                        </span>
                        <span style={{ display: "block" }}>
                            {t("ID")}: {secondaryTaskId}
                        </span>
                    </Fragment>
                );
            }
            case "task_change_description": {
                return (
                    <Fragment>
                        <span style={{ display: "block" }}>
                            {t("The description of your task has been changed.")}
                        </span>
                        <span style={{ display: "block" }}>
                            {t("ID")}: {secondaryTaskId}
                        </span>
                    </Fragment>
                );
            }
            case "task_overdue": {
                return (
                    <Fragment>
                        <span style={{ display: "block" }}>
                            {t("The deadline for your task is today.")}
                        </span>
                        <span style={{ display: "block" }}>
                        {notification?.data?.secondaryTaskId
                            ? "\n" +
                            t("Task ID") +
                            ": " +
                            notification.data.secondaryTaskId
                            : notification?.data?.taskId
                            ? "\n" +
                            t("Task ID") +
                            ": " +
                            notification?.data?.taskId
                            : ""}
                        </span>
                    </Fragment>
                );
            }
            case "risk_rating": {
                return (
                    <Fragment>
                        <span style={{ display: "block" }}>
                            {t("The rating of your risk has been changed.")}
                        </span>
                        <span style={{ display: "block" }}>
                            {t("New rating")}: {riskRate}
                        </span>
                    </Fragment>
                );
            }
            case "risk_change_description": {
                return (
                    <span style={{ display: "block" }}>
                        {t("Description of your risk was changed")}.
                    </span>
                );
            }
            case "status_update": {
                return t(
                    "The status of your case has been updated: {{status}}"
                );
            }
            case "readonly_access": {
                return (
                    <Fragment>
                        {isSoftware ? t("You have read-only access to a software.") :
                            isRequest  ? t("You have read-only access to a DSR.") :
                            isIncident ? t("You have read-only access to an incident.") :
                            t("You have read-only access to a case.")}
                            +
                            <span style={{ display: 'block' }}>
                            {
                            t('ID')
                            }: {isSoftware ? SoftwareID : secondaryCaseId }
                        </span>
                    </Fragment> 
                );
            }
            case "comment_mention": {
                return (
                    <Fragment>
                        <span style={{ display: "block" }}>
                        {t("You have been mentioned in a comment")}.
                        </span>
                        {isRequest ? t('Request ID') :
                    isIncident ? t('Incident ID') :
                    isSoftware ? t('Software ID') :
                        t('Report ID')
                    }: {isSoftware ? SoftwareID : secondaryCaseId }
                    </Fragment>
                );
            }
            case "task_owner_role_removed": {
                return (
                    <span style={{ display: "block" }}>
                        {t("Your task owner role has been removed by the admin")}
                        .
                    </span>
                );
            }
            case "task_owner_role_added": {
                return (
                    <span style={{ display: "block" }}>
                        {t("You have been assigned to the Task Owner role")}.
                    </span>
                );
            }
            case "high_dpia_risk_created": {
                return (
                    <>
                        <span style={{ display: "block" }}>
                            {t("The risk assessment indicates a high risk level. Please proceed to complete the Data Protection Impact Assessment")}.
                        </span>
                        {SoftwareID ? 
                            <span style={{ display: 'block' }}>
                                {t('Software ID') 
                                }: {SoftwareID}
                            </span>
                        : ''}
                    </>
                );
            }
            default: {
                return t(notificationType);
            }
        }
	}

	const handleRenderNotification = (notification, index) => {
		let link = '#';
		if (typeof notification.id !== "undefined") {
            if (workspace === "third_party_workspace") {
                switch (notification.message_type) {
                    case "new_chat": {
                            link = "livechat";
                        break;
                    }
                    case "new_task_created":
                    case "task_status_new":
                    case "task_status_in_progress":
                    case "task_status_review":
                    case "task_status_close":
                    case "task_status_delete":
                    case "task_priority_low":
                    case "task_priority_medium":
                    case "task_priority_high":
                    case "task_comment":
                    case "task_overdue":
                    case "task_change_deadline":
                    case "task_change_start_date":
                    case "task_change_description":
                    case "task_before_overdue": {
                        if (userUtils.isTaskOwner(user)) {
                            link = `tasks/${notification.data.taskId}`;
                        } else {
                            link = `report/task/${notification.data.taskId}`;
                        }
                        break;
                    }
                    case "risk_assign":
                    case "risk_comment":
                    case "risk_rating":
                    case "risk_change_description": {
                            link = `report/risk/${notification.data.riskId}`;
                        break;
                    }
                    case "tom_before_revision": {
                        link = `data-protection/tom?tab=tom`;
                        break;
                    }
                    case "tom_assigned": {
                        link = `data-protection/tom?tab=tom`;
                        break;
                    }
                    default: {
                            link = `report/detail`;
                        break;
                    }
                }
            } 
            else {
                switch (notification.message_type) {
                    case "new_chat": {
                            link = "livechat";
                        break;
                    }
                    case "task_owner_role_removed":
                    case "task_owner_role_added":
                    case "new_task_created":
                    case "task_status_new":
                    case "task_status_in_progress":
                    case "task_status_review":
                    case "task_status_close":
                    case "task_status_delete":
                    case "task_priority_low":
                    case "task_priority_medium":
                    case "task_priority_high":
                    case "task_comment":
                    case "task_overdue":
                    case "task_change_deadline":
                    case "task_change_start_date":
                    case "task_change_description":
                    case "task_before_overdue": {
                        if (userUtils.isTaskOwner(user)) {
                            link = `tasks/${notification.data.taskId}`;
                        }
                        if (notification.data?.questionnaire === "data_subject_request") {
                            if (notification.data?.taskId) {
                                link = `data-protection/data-subject-request/details/${notification.data.caseId}?tab=task-management&taskId=${notification.data?.taskId}`;
                            } else {
                                link = `data-protection/data-subject-request/details/${notification.data.caseId}?tab=task-management`;
                            }
                        }
                        else if (
                            notification.data?.questionnaire === "data_breach_incident"
                        ) {
                            if (notification.data?.taskId) {
                                link = `data-protection/incident-register/details/${notification.data.caseId}?tab=task-management&taskId=${notification.data?.taskId}`;
                            } else {
                                link = `data-protection/incident-register/details/${notification.data.caseId}?tab=task-management`;
                            }
                        }
                        else if (notification.data?.software) {
                            if (notification.data?.taskId) {
                                link = `data-protection/softwares/${notification.data.software}?tab=task-management&taskId=${notification.data?.taskId}`;
                            } else {
                                link = `data-protection/softwares/${notification.data.software}?tab=task-management`;
                            }
                        } else {
                            link = `report/task/${notification.data.taskId}`;
                        }
                        break;
                    }
                    case "risk_assign": {
                        if (notification.data?.questionnaire === "data_breach_incident") {
                            if (notification.data?.riskId) {
                                link = `data-protection/incident-register/details/${notification.data.caseId}?tab=risk-assessment&riskId=${notification.data?.riskId}`;
                            } else {
                                link = `data-protection/incident-register/details/${notification.data.caseId}?tab=risk-assessment`;
                            }
                        }
                        else if (notification.data?.software) {
                            if (notification.data?.riskId) {
                                link = `data-protection/softwares/${notification.data.software}?tab=risk-assessment&riskId=${notification.data?.riskId}`;
                            } 
                            else
                                link = `data-protection/softwares/${notification.data.software}?tab=risk-assessment`;
                            } 
                        else {
                            link = `report/task/${notification.data.riskId}`;
                        }
                        break;
                    }
                    case "tom_before_revision": {
                        link = `data-protection/tom?tab=tom`;
                        break;
                    }
                    case "tom_assigned": {
                        link = `data-protection/tom?tab=tom`;
                        break;
                    }

                    case "risk_comment":
                    case "risk_rating":
                    case "risk_change_description": {
                        if (notification.data?.questionnaire === "data_subject_request") {
                            link = `data-protection/data-subject-request/details/${notification.data.caseId}?tab=risk-assessment`;
                        }
                        else if (
                            notification.data?.questionnaire === "data_breach_incident"
                        ) {
                            link = `data-protection/incident-register/details/${notification.data.caseId}?tab=risk-assessment`;
                        }
                        else if (notification.data?.software) {
                            link = `data-protection/softwares/${notification.data.software}?tab=risk-assessment`;
                        } else {
                            link = `report/task/${notification.data.riskId}`;
                        }
                        break;
                    }
                    case "new_comment":
                    case "comment_mention":
                    case "new_internal_comment": {
                        if (notification.data?.questionnaire === "data_subject_request") {
                            link = `data-protection/data-subject-request/details/${notification.data.caseId}?tab=internal-comments`;
                        }
                        else if (
                            notification.data?.questionnaire === "data_breach_incident"
                        ) {
                            link = `data-protection/incident-register/details/${notification.data.caseId}?tab=internal-comments`;
                        }
                        else if (notification.data?.software) {
                            link = `data-protection/softwares/${notification.data.software}?tab=internal-comments`;
                        }
                        break;
                    }
                    case "high_dpia_risk_created": {
                        if (notification.data?.software) {
                            link = `data-protection/softwares/${notification.data.software}?tab=dpia`;
                        }
                        break;
                    }

                    case "new_attachment":
                    case "open":
                    case "in_progress":
                    case "case_had_been_closed":
                    case "closed":
                    case "deleted":
                    case "pending_delete":
                    case "case_had_been_deleted":
                    case "new":
                    case "new_case_reported":
                    case "assigned":
                        if (notification.data?.questionnaire === "data_subject_request") {
                            link = `data-protection/data-subject-request/details/${notification.data.caseId}`;
                        }
                        else if (
                            notification.data?.questionnaire === "data_breach_incident"
                        ) {
                            link = `data-protection/incident-register/details/${notification.data.caseId}`;
                        }
                        else if (notification.data?.software) {
                            link = `data-protection/incident-register/details/${notification.data.software}`;
                        }
                        break;  
                    case "new_case_assigned":
                    case "new_contact_request":
                    case "new_demo_request":
                    case "readonly_access": {
                        if (notification.data?.questionnaire === "data_subject_request") {
                            link = `data-protection/data-subject-request/details/${notification.data.caseId}`;
                        }
                        else if (
                            notification.data?.questionnaire === "data_breach_incident"
                        ) {
                            link = `data-protection/incident-register/details/${notification.data.caseId}`;
                        }
                        else if (notification.data?.software) {
                            link = `data-protection/softwares/${notification.data.software}`;
                        }
                        break;
                    }
                    default: {
                        link = `report/detail`;
                        break;
                    }
                }
            }
        }

		return (
            <div className='d-flex notification-item'>
                <Link
                    onClick={() => {
                        localStorage.setItem('taskId', notification.data.taskId ? notification.data.taskId : '');
                        localStorage.setItem('riskId', notification.data.riskId ? notification.data.riskId : '');
                        localStorage.setItem('caseId', notification.data.caseId);

                        if (notification.data.secondaryId) {
                            localStorage.setItem('secondaryCaseId', notification.data.secondaryId);
                        }
                    }}
                    to={`/${window.location.pathname.split('/', 4)[3]}/${link}`}
                    className='text-reset flex-grow-1'
                >
                    <div className='d-flex'>
                        <div className='avatar-xs me-3'>
                            <span className='avatar-title bg-info rounded-circle font-size-16'>
                                <i className='ri-notification-3-line'></i>
                            </span>
                        </div>
                        <div className='flex-1'>
                            <h6 className='mt-0 mb-1'>
                                Smart Integrity Platform {notification?.data?.caseId ? t('Report update') : t('System update')}:
                            </h6>
                            <div className='font-size-12 text-muted'>
                                <p className='mb-1'>
                                    {handleTranslateOnType(notification)}
                                </p>
                                <p className='mb-0'>
                                    <i className='mdi mdi-clock-outline'></i>
                                    {` ${dateUtils.convertTimeStampToDate(notification.created_at, INTERNATIONAL_DATE_TIME_FORMAT)}`}
                                </p>
                            </div>
                        </div>
                    </div>
                </Link>
                <div 
					className='pe-3 pt-2' 
					style={{
						cursor: "pointer"
					}} 
					onClick={(e) => {
						e.stopPropagation();
						removeNotification(notification?.id)
					}}>
						{notification.isDeleting ?
                            <div className='d-flex justify-content-center'>
                                <Spinner  size="sm" color="primary" />
                            </div>
					        :
                            <i className="mdi mdi-trash-can text-danger font-size-18"></i>
                        }
				</div>
            </div>
        );
	};

    return (
        <>
            {(notifications?.list || []).map((notification, index) => {
                return (
                    <div key={notification.id}
                        style={{
                            animationName: notification.hasMotion ? 'newNotificationAnimation' : '',
                            animationDuration: '0.6s',
                            animationIterationCount: 1,
                            overflow: 'hidden',
                            transition: 'opacity 0.3s ease 0s, max-height 0.3s ease 0.3s',
                            maxHeight: notification.isRead ? '0px' : '1000px',
							opacity: notification.isRead ? '0' : '1',
                        }}
                    >
                        {handleRenderNotification(notification, index)}
                    </div>
                );
            })}
        </>
    );
}

const mapStatetoProps = (state) => {
	return {
        organization    :   state.Organization,
        user            :   state.Login?.user,
        token           :   state.Login?.token,
		notifications   :   state.AppNotification,
        modules         :   state.Modules,
        workspace       :   state.Workspace
	};
};

const mapDispachToProps = (dispatch) => {
	return {
        markAsRead  :   (notificationId) => dispatch(markNotificationAsRead({
            notificationId
        })),
        removeNotification  :  (notificationId) => dispatch(removeNotificationInList({
            notificationId
        })),
    };
};

export default connect(
    mapStatetoProps, mapDispachToProps
)(withNamespaces()(
    Handler
));