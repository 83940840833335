import React from "react";
import BottyAI from "src/modules/data-protection/assets/images/ai-botty/loading.png";
export default function Loding(props) {
  return (
    <div style={{ textAlign: "center" }}>
      <h5 className="my-5">
        {props.t("Hi, I'm Botty. The AI Assistant from DISS-CO.")}
      </h5>
      <img
        className="mb-3 "
        src={BottyAI}
        width={"50%"}
        height={"50%"}
        alt="Botty"
      />
      <h6 className="my-5">
        {props.t(
          "The creation process may take a few minutes, so please wait..."
        )}
      </h6>
    </div>
  );
}
