import { withNamespaces } from "react-i18next";
import { memo, useEffect, useMemo, useState } from "react";
import {
    Col,
    Row,
    Input,
    Form,
    FormGroup,
    Label,
    Button
} from "reactstrap";

import Select from "react-select";

import {
    HiChevronDoubleUp,
    HiChevronDoubleDown,
    HiMenuAlt4,
} from "react-icons/hi";
import { connect } from "react-redux";
import Accordion from "react-bootstrap/Accordion";

import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

// Services
import CommonService from "../../../../apis/CommonService";
import DataProtectionHardwareService from "../../../../apis/DataProtectionHardwareService";

import DateUtils from "../../../../../../services/utils/DateUtils";

// styles
import "./style.scss";
import UserUtils from "../../../../../../services/utils/UserUtils";
import hardware from "../..";
import MultiSelect from "src/modules/data-protection/components/MultiSelect";

const DataProtectionHardwareInformation = (props) => {
    const dateUtils = new DateUtils();
    const userUtils = new UserUtils();
    const {t, setAssetId} = props;

    const { hardwareId } = useParams();

    const [id, setId] = useState("");

    const [selectedStatus, setSelectedStatus] = useState(null);
    const [updatingSelectedStatus, setUpdatingSelectedStatus] = useState(false);

    const [selectedAssignedAnalyst, setSelectedAssignedAnalyst] = useState(null);
    const [users, setUsers] = useState([]);
    const [updatingSelectedAssignedAnalyst, setUpdatingSelectedAssignedAnalyst] = useState(false);

    const [selectedPriority, setSelectedPriority] = useState(null);
    const [updatingSelectedPriority, setUpdatingSelectedPriority] = useState(false);

    const [manufacturers, setManufacturers] = useState([]);
    const [customManufacturer, setCustomManufacturer] = useState('');
    const [selectedManufacturer, setSelectedManufacturer] = useState(null);
    const [manufacturer_errors, setManufacturer_errors] = useState([]);

    const [selectedModel, setSelectedModel] = useState(null);
    const [customModel, setCustomModel] = useState('');
    const [models, setModels] = useState([]);
    const [model_errors, setModel_errors] = useState([]);

    const [selectedDeviceType, setSelectedDeviceType] = useState(null);
    const [deviceTypes, setDeviceTypes] = useState([]);
    const [deviceType_errors, setDeviceType_errors] = useState([]);

    const [selectedOperatingSystem, setSelectedOperatingSystem] = useState(null);
    const [operatingSystems, setOperatingSystems] = useState([]);
    const [operatingSystem_errors, setOperatingSystem_errors] = useState([]);

    const [serialNumber, setSerialNumber] = useState("");
    const [serialNo_errors, setSerialNo_errors] = useState([]);

    const [selectedTypeOfOwnerShip, setSelectedTypeOfOwnerShip] = useState(null);
    const [ownerShipsTypes, setOwnerShipsTypes] = useState([]);
    const [typeOfOwnerShip_errors, setTypeOfOwnerShip_errors] = useState([]);

    const [handoverInfos, setHandoverInfos] = useState({});

    const[updatingDetailsForm, setUpdatingDetailsForm] = useState(false);

    const [legalEntitiesOptions, setLegalEntitiesOptions] = useState([]);
    const [selectedLegalEntities, setSelectedLegalEntities] = useState([]);
    const [updatingSelectedEntity, setUpdatingSelectedEntity] = useState(false);

    const HardwareStatuses = useMemo(() => {
        return [
            {
                value       : 'NEW',
                label       : t('New'),
                baseLabel   : 'New'
            },
            {
                value       : 'IN_PROGRESS',
                label       : t('In Progress'),
                baseLabel   : 'In Progress'
            },
            {
                value       : 'OPEN',
                label       : t('Open'),
                baseLabel   : 'Open'
            },
            {
                value       : 'APPROVED',
                label       : t('Approved'),
                baseLabel   : 'Approved'
            },
            {
                value       : 'ASSIGNED',
                label       : t('Assigned'),
                baseLabel   : 'Assigned'
            },
            {
                value       : 'DELETED',
                label       : t('Deleted'),
                baseLabel   : 'Deleted'
            },
        ];
    }, [t]);

    const priorities = useMemo(() => {
        return [
            {
                value: "LOW",
                label: (
                    <span>
                    {<HiChevronDoubleDown className="text-info" />}
                    {" " + t("Low")}
                    </span>
                )
            },
            {
                value: "MEDIUM",
                label: (
                    <span>
                    {<HiMenuAlt4 className="text-success" />}
                    {" " + t("Medium")}
                    </span>
                )
            },
            {
                value: "HIGH",
                label: (
                    <span>
                    {<HiChevronDoubleUp className="text-danger" />}
                    {" " + t("High")}
                    </span>
                )
            }
        ]
    }, [t]);

    const handleFetchHardwareDetailsQuery = useQuery({
        queryKey: ["data-protection-hardware-details", hardwareId],
        queryFn: async () => {
            const service = DataProtectionHardwareService.getInstance();
            return await service.details(hardwareId);
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onError: (error) => {
            if (process.env.NODE_ENV === 'development') console.error(error);
            toast("An error occured while fetching hardware details.", {
            type: "error",
            });
        }
    });

    const handleFetchUsersListQuery = useQuery({
        queryFn: async () => {
            const service = CommonService.getInstance();

            return await service.analystAdminsAndAnalysts();
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onError: (error) => {
            if (process.env.NODE_ENV === 'development') console.error(error);

            toast(t("An error occurred while fetching users."), {
                type: "error",
            });
        }
    });

    const handleFetchManufacturersListQuery = useQuery({
		queryKey: ['data-protection-manufacturers-list'],
		queryFn: async () => {
			const service = DataProtectionHardwareService.getInstance();

			return await service.manufacturers();
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			if (process.env.NODE_ENV === 'development') console.error(error);

			toast(t('An error occurred while fetching hardwares manufacturers.'), {
				type: 'error',
			});
		},
	});

    const handleFetchModelsListQuery = useQuery({
		queryKey: ['data-protection-models-list'],
		queryFn: async () => {
			const service = DataProtectionHardwareService.getInstance();

			return await service.models();
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			if (process.env.NODE_ENV === 'development') console.error(error);

			toast(t('An error occurred while fetching hardwares models.'), {
				type: 'error',
			});
		},
	});

    const handleFetchDeviceTypesListQuery = useQuery({
		queryKey: ['data-protection-deviceTypes-list'],
		queryFn: async () => {
			const service = DataProtectionHardwareService.getInstance();

			return await service.deviceTypes();
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			if (process.env.NODE_ENV === 'development') console.error(error);

			toast(t('An error occurred while fetching hardwares device types.'), {
				type: 'error',
			});
		},
	});

    const handleFetchOperatingSystemsListQuery = useQuery({
		queryKey: ['data-protection-operating-systems-list'],
		queryFn: async () => {
			const service = DataProtectionHardwareService.getInstance();

			return await service.operatingSystems();
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			if (process.env.NODE_ENV === 'development') console.error(error);

			toast(t('An error occurred while fetching hardwares operating systems.'), {
				type: 'error',
			});
		},
	});

    const changeStatusMutation = useMutation({
        mutationFn: async ({ status }) => {
            const service = DataProtectionHardwareService.getInstance();
            return await service.changeStatus({
                hardwareId: id,
                status: status.value.toUpperCase(),
            });
        },
        onSuccess: () => {
            toast(t("Status successfully changed."), {
                type: "success",
            });
        },
        onError: () => {
            toast(t("An error occurred while updating status."), {
                type: "error",
            });
        },
        onMutate: () => {
            setUpdatingSelectedStatus(true);
        },
        onSettled: () => {
            setUpdatingSelectedStatus(false);
        }
    });

    const changeAssignedAnalystMutation = useMutation({
        mutationFn: async ({ analyst }) => {
            const service = DataProtectionHardwareService.getInstance();
            return await service.changeAssignedAnalyst({
                hardwareId  :   id,
                analystId   :   analyst.value.toString(),
            });
        },
        onSuccess: () => {
            toast(t("Assigned analyst successfully changed."), {
                type: "success",
            });
        },
        onError: () => {
            toast(t("An error occurred while updating analyst."), {
                type: "error",
            });
        },
        onMutate: () => {
            setUpdatingSelectedAssignedAnalyst(true);
        },
        onSettled: () => {
            setUpdatingSelectedAssignedAnalyst(false);
        }
    });

    const changePriorityMutation = useMutation({
        mutationFn: async ({ priority }) => {
            const service = DataProtectionHardwareService.getInstance();
            return await service.changePriority({
                hardwareId  :   id,
                priority    :   priority.value,
            });
        },
        onSuccess: () => {
            toast(t("Priority successfully changed."), {
                type: "success",
            });
        },
        onError: () => {
            toast(t("An error occurred while updating priority."), {
                type: "error",
            });
        },
        onMutate: () => {
            setUpdatingSelectedPriority(true);
        },
        onSettled: () => {
            setUpdatingSelectedPriority(false);
        }
    });

    const changeEntityMutation = useMutation({
        mutationFn: async ( entity ) => {
            const service = DataProtectionHardwareService.getInstance();
            return await service.changeEntity({
                hardware: hardwareId,
                legalEntity: entity.value
            });
        },
        onSuccess: () => {
            toast(t("Legal entity successfully changed."), {
                type: "success",
            });
        },
        onError: () => {
            toast(t("An error occurred while updating legal entity."), {
                type: "error",
            });
        },
        onMutate: () => {
            setUpdatingSelectedEntity(true);
        },
        onSettled: () => {
            setUpdatingSelectedEntity(false);
        }
    });

    const updateDetailsMutation = useMutation({
        mutationFn: async ({ payload }) => {
            const service = DataProtectionHardwareService.getInstance();
            return await service.updateDetails(hardwareId, payload);
        },
        onSuccess: () => {
            toast(t("Details updated successfully."), {
                type: "success",
            });
        },
        onError: () => {
            toast(t("An error occurred while updating details."), {
                type: "error",
            });
        },
        onMutate: () => {
            setUpdatingDetailsForm(true);
        },
        onSettled: () => {
            setUpdatingDetailsForm(false);
        }
    });

    const handleGetSelectedManufacturerName = (manufacturer) => {
		if (manufacturer.value === '-custom-') return customManufacturer;
		else return manufacturer.label;
	};

    const handleGetSelectedModelName = (model) => {
		if (model.value === '-custom-') return customModel;
		else return model.label;
	};

    const changeStatus = (newStatus) => {
        changeStatusMutation.mutate({
            status  :   newStatus
        }, {
            onSuccess: () => {
                setSelectedStatus(newStatus);
            }
        });
    };

    const changeAssignedAnalyst = (newAnalyst) => {
        changeAssignedAnalystMutation.mutate({
            analyst :   newAnalyst
        }, {
            onSuccess: () => {
                setSelectedAssignedAnalyst(newAnalyst);
            }
        });
    }

    const changeSelectedPriority = (newPriority) => {
        changePriorityMutation.mutate({
            priority    :   newPriority
        }, {
            onSuccess: () => {
                setSelectedPriority(newPriority);
            }
        });
    }

    const AddEntityMutation = useMutation({
        mutationFn: async ( entity ) => {
            const service = DataProtectionHardwareService.getInstance();
            return await service.AddEntity({
                hardware: Number(hardwareId),
                legalEntity: entity.id
            });
        },
        onSuccess: () => {
            toast(t("Legal entity successfully changed."), {
                type: "success",
            });
            handleFetchHardwareDetailsQuery.refetch();
        },
        onError: () => {
            toast(t("An error occurred while updating legal entity."), {
                type: "error",
            });
        },
    });
  
    const RemoveEntityMutation = useMutation({
      mutationFn: async ( entity ) => {
          const service = DataProtectionHardwareService.getInstance();
          return await service.RemoveEntity({
            hardware:  Number(hardwareId),
              legalEntity: entity.id
          });
      },
      onSuccess: () => {
          toast(t("Legal entity successfully changed."), {
              type: "success",
          });
          handleFetchHardwareDetailsQuery.refetch();
      },
      onError: () => {
          toast(t("An error occurred while updating legal entity."), {
              type: "error",
          });
      }
  });
  
    const changeSelectedEntity = (newEntity) => {
        // Helper function to check if two entities are equal
        const areEntitiesEqual = (entity1, entity2) => entity1.id === entity2.id && entity1.title === entity2.title;
  
       // Check for added entities
       const addedEntities = newEntity.filter(newEnt => !selectedLegalEntities.some(selEnt => areEntitiesEqual(newEnt, selEnt)));
  
       // Check for removed entities
       const removedEntities = selectedLegalEntities.filter(selEnt => !newEntity.some(newEnt => areEntitiesEqual(newEnt, selEnt)));
   
       if (addedEntities.length > 0) {
           // Call AddEntityMutation for the added entities
           addedEntities.forEach(entity => {
               AddEntityMutation.mutate(entity);
           });
  
       } else if (removedEntities.length > 0) {
           // Call RemoveEntityMutation for the removed entities
           removedEntities.forEach(entity => {
               RemoveEntityMutation.mutate(entity);
           });
       }
    }

    const handleFetchTypeOfOwnerShips = () => {
        setOwnerShipsTypes([
            {
                value       :   "PRIVATE",
                label       :   t("Private"),
                baseLabel   :   "Private"
            },
            {
                value       :   "COMPANY",
                label       :   t("Company"),
                baseLabel   :   "Company"
            }
        ]);
    };

    const submitEditDetailsForm = () => {
        if (handleValidateForm()){
            updateDetailsMutation.mutate({
                payload :   {
                    serialNo        :   serialNumber,
                    assetType       :   selectedDeviceType.value,
                    manufacturer    :   handleGetSelectedManufacturerName(selectedManufacturer),
                    model           :   handleGetSelectedModelName(selectedModel),
                    operatingSystem :   selectedOperatingSystem.value,
                    typeOfOwnerShip :   selectedTypeOfOwnerShip.value
                }
            });
        }
    }

    // Fetch Legal Enteties Options
	 const fetchLegalEntitiesOptions = useQuery({
		queryKey: ["data-protection-enteties-list"],
		queryFn: async () => {
		  const service = CommonService.getInstance();
	
		  return await service.legalEntities(props?.Organization?.id);
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
		  if (process.env.NODE_ENV === "development") console.error(error);
	
		  toast(props.t("An error occurred while fetching legal entities."), {
			type: "error",
		  });
		},
	  });

    const handleResetFormErrors = () => {
		setDeviceType_errors([]);
		setSerialNo_errors([]);
		setManufacturer_errors([]);
		setModel_errors([]);
		setTypeOfOwnerShip_errors([]);
		setOperatingSystem_errors([]);
	};

    const handleValidateForm = () => {
		let isValid = true;
		handleResetFormErrors();

		if (!selectedDeviceType) {
			setDeviceType_errors([props.t('Device type is required.')]);
			isValid = false;
		}

		if (!serialNumber) {
			setSerialNo_errors([props.t('Serial no is required.')]);
			isValid = false;
		}

		if (!selectedManufacturer) {
			setManufacturer_errors([props.t('Manufacturer is required.')]);
			isValid = false;
		} 
		else if (selectedManufacturer.value === '-custom-' && !customManufacturer) {
			setManufacturer_errors([props.t('Please write a custom manufacturer.')]);
			isValid = false;
		}

		if (!selectedModel) {
			setModel_errors([props.t('Model is required.')]);
			isValid = false;
		} else if (selectedModel.value === '-custom-' && !customModel) {
			setModel_errors([props.t('Please write a custom model.')]);
			isValid = false;
		}

		if (selectedTypeOfOwnerShip?.value !== 'PRIVATE' && selectedTypeOfOwnerShip?.value !== 'COMPANY') {
			setTypeOfOwnerShip_errors([props.t('Type of ownership is required.')]);
			isValid = false;
		}

		if (!selectedOperatingSystem ) {
			setOperatingSystem_errors([props.t('Operating System is required.')]);
			isValid = false;
		}

		return isValid;
	};

    useState(() => {
        handleFetchTypeOfOwnerShips();
    }, []);

    useEffect(() => {
        const { data } = handleFetchHardwareDetailsQuery;
        if (data) {
            setAssetId(data?.asset_id);

            setId(data?.id);

            if(data?.legal_entities) {
                setSelectedLegalEntities(data.legal_entities)
            }
            
            if(data.manufacture_name){
                setSelectedManufacturer({
                    label   :   data.manufacture_name,
                    value   :   data.manufacture_name.trim().toLowerCase()
                });
            }

            if(data.model_name){
                setSelectedModel({
                    label   :   data.model_name,
                    value   :   data.model_name.trim().toLowerCase()
                });
            }

            setSelectedDeviceType({
                label   :   t(data.asset_type.replaceAll('_', ' ').toLowerCase()),
                value   :   data.asset_type
            });

            setSelectedOperatingSystem({
                label   :   data.operating_system_name,
                value   :   data.operating_system_name.trim().toLowerCase()
            });

            if(data.analyst_assigned_object && !Array.isArray(data.analyst_assigned_object)){
                setSelectedAssignedAnalyst({
                    value   :   data.analyst_assigned_object.id,
                    label   :   data.analyst_assigned_object.name
                });
            }

            if (data?.status) {
                const status = HardwareStatuses.find((status) => {
                    return status.value.toLowerCase() === data.status.toLowerCase()
                });

                setSelectedStatus(status || null);
            }

            if (data?.priority) {
                const priority = priorities.find((priority) => {
                    return priority.value.toLowerCase() === data.priority.toLowerCase();
                });

                setSelectedPriority(priority || null);
            }

            if(data?.type_of_owner_ship){
                const typeOfOwnerShip = ownerShipsTypes.find((ownerShipType) => {
                    return ownerShipType.value.toLowerCase() === data.type_of_owner_ship.toLowerCase();
                });

                setSelectedTypeOfOwnerShip(typeOfOwnerShip || null);
            }

            setSerialNumber(data?.serial_no);

            const { handover } = data;

            if(handover && handover?.handover_at){
                setHandoverInfos({
                    id          :   handover.id,
                    handoverAt  :   handover.handover_at,
                    user        :   {
                        ...handover.user,
                        rolesNames  :   handover.user.roles.map((r) => {
                            return userUtils.gerUserRole(r);
                        })
                    }
                });
            }
        }
    }, [handleFetchHardwareDetailsQuery.data]);

    useEffect(() => {
        if (handleFetchUsersListQuery.data && handleFetchUsersListQuery.data?.length) {
            const users = handleFetchUsersListQuery.data.map((u) => {
                return {
                    value: u.id,
                    label: u.first_name + " " + u.last_name,
                };
            });
            setUsers(users);
        }
    }, [handleFetchUsersListQuery.data]);

    useEffect(() => {
        if(handleFetchManufacturersListQuery.data){
            const manufacturers = (handleFetchManufacturersListQuery?.data?.manufacturers || []).map((m) => {
                return {
                    label   :   m.name,
                    value   :   m.name.trim().toLowerCase()
                };
            });

            setManufacturers([
                {
                    label : props.t('Custom'),
                    value : '-custom-'
                },
                ...(manufacturers || [])
            ]);
        }
    }, [handleFetchManufacturersListQuery.data]);

    useEffect(() => {
        if(handleFetchModelsListQuery.data){
            const models = (handleFetchModelsListQuery?.data?.models || []).map((m) => {
                return {
                    label   :   m.name,
                    value   :   m.name.trim().toLowerCase()
                };
            });

            setModels([
                {
                    label : props.t('Custom'),
                    value : '-custom-'
                },
                ...(models || [])
            ]);
        }
    }, [handleFetchModelsListQuery.data]);

    useEffect(() => {
        if(handleFetchDeviceTypesListQuery.data){
            const types = handleFetchDeviceTypesListQuery.data;
            
            if(types && Array.isArray(types)){
                setDeviceTypes(types.map((deviceType) => {
                    return {
                        label   :   t(deviceType.replaceAll('_', ' ').toLowerCase()),
                        value   :   deviceType
                    };
                }));
            }
        }
    }, [handleFetchDeviceTypesListQuery.data, t]);

    useEffect(() => {
        if(handleFetchOperatingSystemsListQuery.data){
            const { operationSystems } = handleFetchOperatingSystemsListQuery.data;
            
            if(operationSystems && Array.isArray(operationSystems)){
                setOperatingSystems(operationSystems.map((os) => {
                    return {
                        label   :   os.name,
                        value   :   os.name.trim().toLowerCase()
                    };
                }));
            }
        }
    }, [handleFetchOperatingSystemsListQuery.data]);

    useEffect(() => {
        handleFetchTypeOfOwnerShips();
    }, [t]);

    useEffect(() => {
		const entities = fetchLegalEntitiesOptions?.data;
		if(entities){
		setLegalEntitiesOptions(entities);}
	  }, [fetchLegalEntitiesOptions?.data])

    return (
        <>
            <Row className="mb-4">
                <Col xl="2" lg="4" md="4" sm="12" className="mt-3">
                    <Form>
                        <FormGroup>
                            <Label for="assetId">{t("Asset ID") + ":"}</Label>
                            <Input id="assetId" className="disabled" value={id} readOnly />
                        </FormGroup>
                    </Form>
                </Col>
                <Col xl="2" lg="4" md="4" sm="12" className="mt-3">
                    <Form>
                        <FormGroup>
                            <Label for="statuses">{t("Status") + ":"}</Label>
                            <Select
                                className="mt-1 text-capitalize"
                                placeholder={t("Select") + "..."}
                                id="statuses"
                                name="statuses"
                                value={selectedStatus && {...selectedStatus, label: t(selectedStatus.baseLabel)}}
                                onChange={changeStatus}
                                options={HardwareStatuses}
                                menuPortalTarget={document.body}
                                isLoading={handleFetchHardwareDetailsQuery.isLoading || updatingSelectedStatus}
                                isDisabled={handleFetchHardwareDetailsQuery.isLoading || updatingSelectedStatus}
                            />
                        </FormGroup>
                    </Form>
                </Col>
                <Col xl="2" lg="4" md="4" sm="12" className="mt-3">
                    <Form>
                        <FormGroup>
                            <Label for="assignedAnalyst">{t("Assigned Analyst") + ":"}</Label>
                            <Select
                                className="mt-1 text-capitalize"
                                placeholder={t("Select") + "..."}
                                id="assignedAnalyst"
                                name="assignedAnalyst"
                                options={users}
                                isLoading={handleFetchUsersListQuery.isLoading || handleFetchUsersListQuery.isFetching || updatingSelectedAssignedAnalyst}
                                isError={handleFetchUsersListQuery.isError}
                                menuPortalTarget={document.body}
                                isDisabled={handleFetchUsersListQuery.isLoading || handleFetchUsersListQuery.isFetching || updatingSelectedAssignedAnalyst}
                                value={selectedAssignedAnalyst}
                                onChange={changeAssignedAnalyst}
                            />
                        </FormGroup>
                    </Form>
                </Col>
                <Col xl="2" lg="4" md="4" sm="12" className="mt-3">
                    <Form>
                        <FormGroup>
                            <Label for="priorities">{t("Priority") + ":"}</Label>
                            <Select
                                className="mt-1 text-capitalize"
                                placeholder={t("Select") + "..."}
                                id="priorities"
                                name="statprioritiesuses"
                                value={selectedPriority && priorities.find((p) => p.value === selectedPriority.value)}
                                options={priorities}
                                menuPortalTarget={document.body}
                                isLoading={handleFetchHardwareDetailsQuery.isLoading || handleFetchHardwareDetailsQuery.isFetching || updatingSelectedPriority}
                                isDisabled={handleFetchHardwareDetailsQuery.isLoading || handleFetchHardwareDetailsQuery.isFetching || updatingSelectedPriority}
                                onChange={changeSelectedPriority}
                            />
                        </FormGroup>
                    </Form>
                </Col>
                <Col xl="4" lg="8" md="8" sm="12" className="mt-3">
                    <Form>
                        <FormGroup>
                            <Label for="entities">{t("Legal Entities") + ":"}</Label>
                            <MultiSelect
                            placeholder={t("Select") + "..."}
                            selectedValues={selectedLegalEntities}
                            options={legalEntitiesOptions}
                            isLoading={handleFetchHardwareDetailsQuery.isLoading || handleFetchHardwareDetailsQuery.isFetching || updatingSelectedPriority || fetchLegalEntitiesOptions.isLoading || fetchLegalEntitiesOptions.isFetching}
                            onChange={changeSelectedEntity}
                                />
                        </FormGroup>
                    </Form>
                </Col>
            </Row>

            <Row>
                <Col sm="12">
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item className="border-none" eventKey="0">
                            <Accordion.Header>{t("Asset Details")}</Accordion.Header>

                            <Accordion.Body>
                                {/* FIRST ROW */}
                                <Row>
                                    {/* <Col xl="2" lg="4" md="4" sm="12" className="mt-3">
                                        <FormGroup>
                                            <Label for="hwNum">{t("Hardware Number") + ":"}</Label>
                                            <Input id="hwNum" className="mt-1" readOnly />
                                        </FormGroup>
                                    </Col> */}

                                    <Col xl="2" lg="4" md="4" sm="12" className="mt-3">
                                        <FormGroup>
                                            <Label for="type-of-ownership">
                                                {t("Type of Ownership") + ":"}
                                            </Label>
                                            <Select
                                                className="mt-1 text-capitalize"
                                                placeholder={t("Select") + "..."}
                                                name="type_of_owner_ship"
                                                value={selectedTypeOfOwnerShip && {...selectedTypeOfOwnerShip, label: t(selectedTypeOfOwnerShip.baseLabel)}}
                                                options={ownerShipsTypes}
                                                menuPortalTarget={document.body}
                                                isLoading={handleFetchHardwareDetailsQuery.isLoading || handleFetchHardwareDetailsQuery.isFetching}
                                                onChange={(newOwnerShip) => setSelectedTypeOfOwnerShip(newOwnerShip)}
                                                isDisabled={handleFetchHardwareDetailsQuery.isLoading || handleFetchHardwareDetailsQuery.isFetching}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col xl="2" lg="4" md="4" sm="12" className="mt-3">
                                        <FormGroup>
                                            <Label for="mnNum">{t("Manufacturer") + ":"}</Label>
                                            <Select
                                                className="mt-1"
                                                placeholder={t("Select") + "..."}
                                                name="manufacturer"
                                                value={selectedManufacturer}
                                                options={manufacturers}
                                                menuPortalTarget={document.body}
                                                isLoading={handleFetchManufacturersListQuery.isLoading || handleFetchManufacturersListQuery.isFetching}
                                                onChange={(newManufacturer) => {
                                                    setSelectedManufacturer(newManufacturer);
                                                    setManufacturer_errors([]);
                                                }}
                                                isDisabled={handleFetchManufacturersListQuery.isLoading || handleFetchManufacturersListQuery.isFetching}
                                            />
                                            {selectedManufacturer?.value === '-custom-' ? (
                                                <Input
                                                    className='mt-1'
                                                    id='manufacturer'
                                                    name='manufacturer'
                                                    type='text'
                                                    value={customManufacturer}
                                                    onChange={(e) => {
                                                        setCustomManufacturer(e.target.value);
                                                        setManufacturer_errors([]);
                                                    }}
                                                    invalid={manufacturer_errors.length ? true : false}
                                                    placeholder={props.t('Type a custom manufacturer')}
                                                />
                                            ) : null}
                                            {manufacturer_errors.length
                                                ? manufacturer_errors.map((e) => {
                                                        return (
                                                            <small key={e} className='text-danger'>
                                                                {e}
                                                            </small>
                                                        );
                                                })
                                                : null}
                                        </FormGroup>
                                    </Col>

                                    <Col xl="2" lg="4" md="4" sm="12" className="mt-3">
                                        <FormGroup>
                                            <Label for="model">{t("Model") + ":"}</Label>
                                            <Select
                                                className="mt-1"
                                                placeholder={t("Select") + "..."}
                                                name="model"
                                                value={selectedModel}
                                                options={models}
                                                menuPortalTarget={document.body}
                                                isLoading={handleFetchModelsListQuery.isLoading || handleFetchModelsListQuery.isFetching}
                                                onChange={(newModel) => {
                                                    setSelectedModel(newModel);
                                                    setModel_errors([]);
                                                }}
                                                isDisabled={handleFetchModelsListQuery.isLoading || handleFetchModelsListQuery.isFetching}
                                            />
                                            {selectedModel?.value === '-custom-' ? (
                                                <Input
                                                    className='mt-1 text-capitalize'
                                                    id='model'
                                                    name='model'
                                                    type='text'
                                                    value={customModel}
                                                    onChange={(e) => {
                                                        setCustomModel(e.target.value);
                                                        setModel_errors([]);
                                                    }}
                                                    invalid={model_errors.length ? true : false}
                                                />
                                            ) : null}
                                            {model_errors.length
                                                ? model_errors.map((e) => {
                                                        return (
                                                            <small key={e} className='text-danger'>
                                                                {e}
                                                            </small>
                                                        );
                                                })
                                                : null}
                                        </FormGroup>
                                    </Col>

                                    <Col xl="2" lg="4" md="4" sm="12" className="mt-3">
                                        <FormGroup>
                                            <Label for="type">{t("Type") + ":"}</Label>
                                            <Select
                                                className="mt-1"
                                                placeholder={t("Select") + "..."}
                                                name="device_type"
                                                value={selectedDeviceType}
                                                options={deviceTypes}
                                                menuPortalTarget={document.body}
                                                isLoading={handleFetchDeviceTypesListQuery.isLoading || handleFetchDeviceTypesListQuery.isFetching}
                                                isDisabled={handleFetchDeviceTypesListQuery.isLoading || handleFetchDeviceTypesListQuery.isFetching}
                                                onChange={(newType) => setSelectedDeviceType(newType)}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col xl="2" lg="4" md="4" sm="12" className="mt-3">
                                        <FormGroup>
                                            <Label for="serial">{t("Serial Number") + ":"}</Label>
                                            <Input id="serial" className="mt-1" value={serialNumber} onChange={(e) => {
                                                setSerialNumber(e.target.value)
                                                setSerialNo_errors([]);
                                                }} />
                                            {serialNo_errors.length
                                                ? serialNo_errors.map((e) => {
                                                        return (
                                                            <small key={e} className='text-danger'>
                                                                {e}
                                                            </small>
                                                        );
                                                })
                                                : null}
                                        </FormGroup>
                                    </Col>

                                    <Col xl="2" lg="4" md="4" sm="12" className="mt-3">
                                        <FormGroup>
                                            <Label for="os">{t("Operating System") + ":"}</Label>
                                            <Select
                                                className="mt-1"
                                                placeholder={t("Select") + "..."}
                                                name="operating_system"
                                                value={selectedOperatingSystem}
                                                options={operatingSystems}
                                                menuPortalTarget={document.body}
                                                isLoading={handleFetchOperatingSystemsListQuery.isLoading || handleFetchOperatingSystemsListQuery.isFetching}
                                                isDisabled={handleFetchOperatingSystemsListQuery.isLoading || handleFetchOperatingSystemsListQuery.isFetching}
                                                onChange={(newOs) => setSelectedOperatingSystem(newOs)}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                
                                <Row>
                                    <Col xl="2" lg="4" md="4" sm="12" className="mt-3">
                                        <FormGroup>
                                            <Label for="handover">{t("Handover on") + ":"}</Label>
                                            <Input id="handover" className="mt-1 disabled" value={handoverInfos.handoverAt ? dateUtils.convertTimestampToOnlyDate(handoverInfos.handoverAt) : ''} readOnly />
                                        </FormGroup>
                                    </Col>

                                    <Col xl="2" lg="4" md="4" sm="12" className="mt-3">
                                        <FormGroup>
                                            <Label for="position">{t("Position") + ":"}</Label>
                                            <Input id="position" className="disabled mt-1" value={handoverInfos?.user?.position} readOnly />
                                        </FormGroup>
                                    </Col>

                                    <Col xl="2" lg="4" md="4" sm="12" className="mt-3">
                                        <FormGroup>
                                            <Label for="role">{t("Role") + ":"}</Label>
                                            <Input id="role" className="disabled mt-1" value={handoverInfos?.user?.rolesNames.join(', ')} readOnly />
                                        </FormGroup>
                                    </Col>

                                    <Col xl="2" lg="4" md="4" sm="12" className="mt-3">
                                        <FormGroup>
                                            <Label for="date">{t("Date") + ":"}</Label>
                                            <Input id="date" className="disabled mt-1" value={handoverInfos.handoverAt ? dateUtils.convertTimestampToOnlyDate(handoverInfos.handoverAt) : ''} readOnly />
                                        </FormGroup>
                                    </Col>

                                    <Col xl="2" lg="4" md="4" sm="12" className="mt-3">
                                        <FormGroup>
                                            <Label for="ret">{t("Return on") + ":"}</Label>
                                            <Input id="ret" className="disabled mt-1" value={""} readOnly />
                                        </FormGroup>
                                    </Col>

                                    <Col xl="2" lg="4" md="4" sm="12" className="mt-3">
                                        <FormGroup>
                                            <Label for="delBy">
                                                {t("Deletion Routine by") + ":"}
                                            </Label>
                                            <Input id="delBy" className="disabled mt-1" value={""} readOnly />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row className="mt-4">
                                    <Col lg={12}>
                                        <Button
                                            disabled={updatingDetailsForm || handleFetchHardwareDetailsQuery.isFetching}
                                            onClick={submitEditDetailsForm}
                                            color="btn btn-primary"
                                            type="button">
                                            {props.t("Save")}
                                        </Button>
                                    </Col>
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Col>
            </Row>
        </>
    );
};

const mapStatetoProps = (state) => {

    const { Organization } = state;
  
    return {
      Organization
    };
  };
  
  export default withNamespaces()((connect(mapStatetoProps)(memo(DataProtectionHardwareInformation))));
  
