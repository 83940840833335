import { Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { connect } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import overlayFactory from "react-bootstrap-table2-overlay";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import axios from "axios";
import { INTERNATIONAL_DATE_FORMAT } from "../../../../../../common/constants";
import { EMPTY_LIST } from "../components/table-filter/constants";
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import TableFilter from "../components/table-filter";
import {  Row, Col } from "reactstrap";
import DateUtils from "../../../../../../services/utils/DateUtils";
import { withRouter } from "react-router-dom";
import { GDPR_REPORT_STATUS_LIST } from "src/modules/data-protection/constants/Common";
import ReportIncidenService from "../components/api/ReportIncidenService";
import highPriorityIcon from "src/modules/data-protection/assets/icons/priority-high.png";
import mediumPriorityIcon from "src/modules/data-protection/assets/icons/priority-medium.png";
import lowPriorityIcon from "src/modules/data-protection/assets/icons/priority-low.png";

import { isFeatureEnabledForActiveModule } from "src/helpers/module_helper";

const RequestTable = (props) => {
  const {t} = props;
  const [tableFilters, setTableFilters] = useState({
    pageIndex: 1,
    pageSize: 25,
    status: null,
    adminCategories: null,
    risk: null,
    analystAssigned: null,
    sortField :null,
    sortOrder: null
  });

  const [tableResultTotalCount, setTableResultTotalCount] = useState(0);
  const [reportList, setReportList] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedFiltersDisplay, setSelectedFiltersDisplay] = useState({});
  const dateUtils = new DateUtils();

  const handleFetchCategories = useQuery({
    queryKey: ["report-request-fetch-categories"],
    queryFn: async () => {
      const service = ReportIncidenService.getInstance();

      return await service.FetchCategoriesRequest();
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      toast(props.t("An error occurred while fetching categories."), {
        type: "error",
      });
    },
  });

  const renderCategories = handleFetchCategories.data;

  useEffect(() => {
    if (handleFetchCategories.data) {
      setCategories(handleFetchCategories.data);
    }
  }, [handleFetchCategories.data]);


  const handleRenderPriority = (priority) => {
    switch (priority) {
      case "HIGH": {
        return (
          <div className="priority d-flex gap-2 pt-2 text-capitalize">
            <span className="priority-icon d-flex align-items-center justify-content-center">
              <img
                src={highPriorityIcon}
                alt="Flag Icon"
                className="object-fit-contain d-inline-block mw-100"
              />
            </span>
            {props.t(priority.toLowerCase())}
          </div>
        );
      }
      case "MEDIUM": {
        return (
          <div className="priority d-flex gap-2 pt-2  text-capitalize">
            <span className="priority-icon d-flex align-items-center justify-content-center">
              <img
                src={mediumPriorityIcon}
                alt="Flag Icon"
                className="object-fit-contain d-inline-block mw-100"
              />
            </span>
            {props.t(priority.toLowerCase())}
          </div>
        );
      }
      case "LOW": {
        return (
          <div className="priority d-flex gap-2 pt-2  text-capitalize">
            <span className="priority-icon d-flex align-items-center justify-content-center">
              <img
                src={lowPriorityIcon}
                alt="Flag Icon"
                className="object-fit-contain d-inline-block mw-100"
              />
            </span>
            {props.t(priority.toLowerCase())}
          </div>
        );
      }
      default:{
        return (
          <div className="d-flex gap-2 priority pt-2   text-capitalize">
            <span className="priority-icon d-flex align-items-center justify-content-center">
              <img
                src={lowPriorityIcon}
                alt="Flag Icon"
                className="object-fit-contain d-inline-block mw-100"
              />
            </span>
            {props.t("Low")}
          </div>
        );
      }
    }
  };


  const handleRenderReportStatus = (statusId) => {
    const status = GDPR_REPORT_STATUS_LIST.find(
      (item) => item.id === parseInt(statusId)
    );
    return (  
    <div
        style={{
          fontSize: "10px",
          fontWeight: 700,
          padding: "3px 8px",
          background: status.backgroundColor,
          opacity: status?.opacity || 1,
          color:"#1D1D1D",
          borderRadius:'7px',
          whiteSpace:'nowrap'
        }}
        className={"dt-task-status "}
      >
        {props.t(status.titleKey)}
      </div>
    );
  };

  const handleRenderReportCategories = (reportCategories) => {
    const result =
      categories && categories?.length > 0
        ? categories
            .filter((i) => reportCategories?.includes(i.id))
            .map((i) => props.t(i.title))
        : null;
    return result ? result.join(", ") : null;
  };


  const tableColumns = [
    {
      key: 1,
      dataField: "case_id",
      text: props.t("Request ID"),
      sort: true,
      style: {
        display: "table-cell",
        maxWidth: "200px",
      },
      headerStyle: {
        maxWidth: "200px",
      },
      formatter: (cellContent, row) => {
        return (
          <Link
            className="text-dark"
            to={`/admin/data-protection/data-subject-request/details/${row.case_id}`}

          >
            {row.secondary_case_id}
          </Link>
        );
      },
    },
    {
      key: 2,
      dataField: "category",
      style: {
        display: "table-cell",
        maxWidth: "200px",
      },
      sort: true,
      headerStyle: {
        maxWidth: "200px",
      },
      text: props.t("Category"),
      formatter: (cellContent, row) => {
        return <div>{handleRenderReportCategories(row?.admin_category)}</div>;
      },
    },
    {
      key: 3,
      dataField: "submission_date",
      text: props.t("Submission date"),
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <div>
            {dateUtils.convertTimeStampToDate(
              cellContent,
              INTERNATIONAL_DATE_FORMAT
            )}
          </div>
        );
      },
    },
    {
      key: 4,
      dataField: "closing_date",
      text: props.t("Closing date"),
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <div>
            {dateUtils.convertTimeStampToDate(
              cellContent,
              INTERNATIONAL_DATE_FORMAT
            )}
          </div>
        );
      },
    },
    {
      key: 5,
      dataField: "entity",
      sort: true,
      text: props.t("Entity"),
    },
    {
      key: 6,
      dataField: "analyst_assigned",
      sort: true,
      text: props.t("Assigned analyst"),
      formatter: (cellContent, row) => {
        if (!cellContent || Array.isArray(cellContent)) return null;
        if (cellContent?.avatar && cellContent?.avatar !== "") {
          // avatar exists and is not an empty string
          return (
            <div className="d-flex gap-1 align-items-center">
            <div className="avatar-xs ">
              <span
                className="avatar-title bg-soft-primary text-primary rounded-circle text-capitalize"
                style={{ overflow: "hidden" }}
              >
                <img
                  alt={cellContent.first_name}
                  src={cellContent?.avatar}
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "block",
                    objectFit: "cover",
                  }}
                />
              </span>
              
            </div>
            {' '}{`${cellContent.first_name} ${cellContent.last_name}`}</div>
          );
      }
        return (
          <div><span className='dt-list-col-bullet'>{cellContent.first_name[0]}</span>{' '}{`${cellContent.first_name} ${cellContent.last_name}`}</div>
        );
      },
    },
    {
      dataField: "priority",
      sort: true,
      text: props.t("Priority"),
      key: 7,
      formatter: (cell, row) => {
        return < >{handleRenderPriority(row?.priority)}</ >;
      },
      style: {
        display: "table-cell",
        width: "100px",
      },
      headerStyle: {
        width: "100px",
      },
    },
    {
      key: 8,
      dataField: "status",
      sort: true,
      text: props.t("Status"),
      formatter: (cellContent, row) => {
        return (
          <div>
            {handleRenderReportStatus(row.status)}
          </div>
        );
      },
    },
  ];

  const NoDataIndication = () =>
    handleFetchReportListQuery.isFetched &&
    !handleFetchReportListQuery.length ? (
      <div className="alert m-0" role="alert">
        <p
          style={{
            textAlign: "center",
            marginBottom: 0,
          }}
        >
          {props.t(EMPTY_LIST)}
        </p>
      </div>
    ) : (
      <></>
    );

  const handleFetchReportListQuery = useQuery({
    queryKey: [
      "report-incident-fetch-list",
      props.url,
      props.assignedToMe,
      tableFilters,
    ],
    queryFn: async () => {
      const {
        status: tableStatus,
        adminCategories,
        risk,
        analystAssigned,
      } = tableFilters;
      const transformedAdminCategories =
      adminCategories?.length === 1 && adminCategories[0] === "all"
        ? []
        : adminCategories;
      const status =
        props.status !== null && props.status !== undefined
          ? props.status
          : tableStatus !== null
          ? tableStatus
          : undefined;
      const param = {
        assignedToMe: props.assignedToMe,
        ...(status !== undefined && { status }), // Add status if not null or undefined
        ...(transformedAdminCategories !== null && { adminCategories: transformedAdminCategories }),
        ...(risk !== null && { risk }), // Conditionally add risk if not null
        ...(analystAssigned !== null && { analystAssigned }), // Conditionally add analystAssigned if not null
        pageIndex: tableFilters.pageIndex,
        pageSize: tableFilters.pageSize,
        ...(tableFilters.sortField && { sortField: tableFilters.sortField }),
        ...(tableFilters.sortField && { sortOrder: tableFilters.sortOrder }),
        questionnaire:"data_subject_request",//used for the data request only
      };
      try {
        const response = await axios.post(`${props.url}`, param, {
          headers: {
            Authorization: `Bearer ${props.token}`,
          },
        });
        return response.data?.data || [];
      } catch (error) {
        throw new Error(
          "An error occurred while fetching report list."
        );
      }
    },
    config: {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      onError: (error) => {
        toast(
          props.t("An error occurred while fetching report list."),
          {
            type: "error",
          }
        );
      },
    },
  });

  useEffect(() => {
    if (handleFetchReportListQuery.data) {
      setReportList(handleFetchReportListQuery.data.reports || []);
      setTableResultTotalCount(
        handleFetchReportListQuery.data?.itemsCount || 0
      );
    }
  }, [handleFetchReportListQuery.data]);

  const onTableFilterOkButtonClicked = (filters) => {
    let result = {};
    let displayFilters = {};
    for (const tableFilterKey in tableFilters) {
      if (tableFilterKey in filters) {
        if (tableFilterKey === "dateFrom" || tableFilterKey === "dateTo") {
          result[tableFilterKey] =
            filters[tableFilterKey] !== null
              ? dateUtils.convertDateToDate(
                  new Date(filters[tableFilterKey]),
                  "YYYY-MM-DD"
                )
              : null;
              displayFilters[tableFilterKey] =
              filters[tableFilterKey] !== null
                ? dateUtils.convertDateToDate(new Date(filters[tableFilterKey]), "YYYY-MM-DD")
                : "";
        } else {
          result[[tableFilterKey]] = filters[tableFilterKey];
          displayFilters[tableFilterKey] = filters[tableFilterKey];
        }
      } else {
        result[[tableFilterKey]] = tableFilters[tableFilterKey];
        displayFilters[tableFilterKey] = tableFilters[tableFilterKey] ? tableFilters[tableFilterKey] : "";
      }
    }
    setTableFilters(result);
    setSelectedFiltersDisplay(displayFilters);
  };

  const handleFetchAnalystsAndAnalystAdmins = useQuery({
    queryKey: ["fetch-supplier-Analys-Admins"],
    queryFn: async () => {
      const service = ReportIncidenService.getInstance();

      return await service.FetchAnalystsAndAnalystAdmins();
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      toast(t("An error occurred while fetching owners."), {
        type: "error",
      });
    },
  });
 
  const renderAnalystAdminsFilterList = () => {
    if (handleFetchAnalystsAndAnalystAdmins.isFetching || handleFetchAnalystsAndAnalystAdmins.isLoading) {
      return [];
    }
    return handleFetchAnalystsAndAnalystAdmins.data;
  };

  const handleFetchRisks = useQuery({
    queryKey: ["fetch-risks"],
    queryFn: async () => {
      const service = ReportIncidenService.getInstance();

      return await service.FetchRiskRating();
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      toast(t("An error occurred while fetching managers."), {
        type: "error",
      });
    },
  });

  const renderRiskFilterList = () => {
    let riskRates = [];
    // Check if the "risk-assessment" feature is enabled
    if (isFeatureEnabledForActiveModule("risk-assessment")) {
      // Fetch data and handle loading state
     
      // Once data is fetched, map it to checkboxes
      const risks = (handleFetchRisks.data || []).map((item) => {
        return {
          value: item.id,
          baseLabel: item.name,
          label: t(item.name),
        };
      });
  
      riskRates =[
        {
          value: "",
          baseLabel: "All Risk",
          label: t("All Risk"),
        },
        ...risks,
      ];

    } else {
      // If the feature flag is off, use the hardcoded array
      riskRates = [
        {
          value: "",
          baseLabel: "All Risk",
          label: t("All Risk"),
        },
        {
          value: "high",
          baseLabel: "High",
          label: t("High"),
        },
        {
          value: "medium",
          baseLabel: "Medium",
          label: t("Medium"),
        },
        {
          value: "low",
          baseLabel: "Low",
          label: t("Low"),
        },
      ];
    }
  
    // Render checkboxes
    return riskRates;
  };

  const allRisks = renderRiskFilterList();    

  const handleTableChange = (type, data) => {

    switch (type) {
      case "pagination":
        const { page, sizePerPage } = data
        setTableFilters({
          ...tableFilters,
          pageIndex: page,
          pageSize: sizePerPage,
        });
        break;

        case 'sort': {
          const { sortField, sortOrder } = data
          if (sortField === 'priority') {

          const sortedList = [...reportList].sort((a, b) => {
           
              const priorityOrder = {
                'HIGH': 1,
                'MEDIUM': 2,
                'LOW': 3
              };
    
              const priorityA = priorityOrder[a[sortField]] || 4; // default to 4 if not found
              const priorityB = priorityOrder[b[sortField]] || 4; // default to 4 if not found
    
              if (priorityA < priorityB) return sortOrder === 'asc' ? -1 : 1;
              if (priorityA > priorityB) return sortOrder === 'asc' ? 1 : -1;
              return 0;
          });
          setReportList(sortedList);
        }
        else{
          setTableFilters({
            ...tableFilters,
            sortField: sortField,
            sortOrder: sortOrder,
          });
 
        }
          break;
      }
      default:
        return false;
    }
  };

  return (
    <div className="p-4" style={{ marginBottom: "300px" }}>
      <PaginationProvider
        pagination={paginationFactory({
          custom: true,
          page: tableFilters.pageIndex,
          sizePerPage: tableFilters.pageSize,
          totalSize: tableResultTotalCount,
          withFirstAndLast: false,
          alwaysShowAllBtns: true,
          prePageText: (
            <span>
              <i className="ri-arrow-left-s-line"></i> {props.t("Back")}
            </span>
          ),
          nextPageText: (
            <span>
              {props.t("Next")} <i className="ri-arrow-right-s-line"></i>
            </span>
          ),
          prePageTitle: props.t("Pre page"),
          firstPageTitle: props.t("Next page"),
          showTotal: true,
          hideSizePerPage: false,
          sizePerPageList: [
            {
              text: "25",
              value: 25,
            },
            {
              text: "50",
              value: 50,
            },
          ],
        })}
      >
        {({ paginationProps, paginationTableProps }) => {
          const { analystAssigned, risk, adminCategories} = selectedFiltersDisplay;
          
          const filteredUser = analystAssigned ? renderAnalystAdminsFilterList()?.filter(u => u?.id === analystAssigned)[0] : null;
          let filterRisks = null;
          
          if(allRisks.length > 1){
             filterRisks = allRisks.find(u => u?.value === risk);
          }

          let selectedCategories = null
          if(adminCategories){
             selectedCategories = renderCategories?.filter(category=> adminCategories.includes(category.id));
          }
          
          
          return(
          <React.Fragment>
            <Row className="mb-5 mt-3">
              <Col sm="12">
                <div className="d-flex gap-4 justify-content-end">
                  <TableFilter
                    defaultValues={{
                      ...tableFilters,
                    }}
                    categories={categories}
                    onOkButtonClicked={onTableFilterOkButtonClicked}
                    type={props.type}
                    handleFetchAnalystsAndAnalystAdmins={handleFetchAnalystsAndAnalystAdmins}
                  />
                </div>
                <div className="d-flex gap-4 justify-content-end mt-2" hidden={
                            !analystAssigned && !risk && !adminCategories
                          }>
                            <ul style={{
                              maxWidth:"312px"
                            }}>
                            <li hidden={!filteredUser}>{t("Analyst")}: {`${filteredUser?.first_name} ${filteredUser?.last_name}`}</li>
                            <li hidden={!filterRisks}>{t("Risk")}: {`${filterRisks?.label}`}</li>
                            <li  hidden={!selectedCategories?.length}>{t("Categories")}: {`${selectedCategories?.map((category)=> category.title).join(", ")}`}</li>
                            </ul>
                          </div> 
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <BootstrapTable
                  remote={{
                    pagination: true,
                    filter: false,
                    sort: true,
                    cellEdit: false,
                    search: false,
                  }}
                  loading={
                    handleFetchReportListQuery.isFetching ||
                    handleFetchReportListQuery.isLoading ||
                    handleFetchCategories.isLoading ||
                    handleFetchCategories.isFetching
                  }
                  overlay={overlayFactory({
                    spinner: (
                      <Spinner animation="border" variant="primary" size="md" />
                    ),
                    text: "Loading...",
                  })}
                  onTableChange={handleTableChange}
                  defaultSorted={[]}
                  keyField={"id"}
                  responsive
                  bordered={false}
                  data={reportList}
                  striped={true}
                  columns={tableColumns}
                  wrapperClasses="table-responsive"
                  classes={"table tpdd-table incident-table"}
                  headerWrapperClasses={"thead-light"}
                  style={{
                    overflowX: "auto",
                  }}
                  noDataIndication={() => <NoDataIndication />}
                  {...paginationTableProps}
                />
              </Col>
            </Row>

            <Row>
              <Col sm="12" md="6">
                <div className="tpdd-pagination-style-1">
                  <PaginationListStandalone {...paginationProps} />

                  <SizePerPageDropdownStandalone {...paginationProps} />
                </div>
              </Col>
            </Row>
          </React.Fragment>
        )}}
      </PaginationProvider>
    </div>
  );
};

const mapStatetoProps = (state) => {
  const { token } = state.Login;
  return {
    token,
  };
};

export default withNamespaces()(
  withRouter(connect(mapStatetoProps)(RequestTable))
);
