import { useEffect, useMemo, useRef, useState } from "react";
import { withNamespaces } from "react-i18next";
import TableFilter from "src/modules/3rd-party-management/components/table-filter";

const FilteringComponent = ({
    t,
    onChange
}) => {
    const [ tableFilters, setTableFilters ] = useState({
        date        :   null,
        flag        :   null,
        text        :   undefined
    });

    const isFirstRender = useRef(true);

    const tableFilterTabs = useMemo(() => {
        return [
            {
                type: 'radio_list',
                name: 'date',
                title: 'Date',
                icon: <i className="ri-calendar-event-line" />,
                isLoading: false,
                defaultValue: tableFilters?.date,
                data: [
                    {
                        id      :   'lastWeek',
                        title   :   'Last Week'
                    },
                    {
                        id      :   'lastMonth',
                        title   :   'Last Month'
                    },
                    {
                        id      :   'lastThreeMonth',
                        title   :   'Last Three Month'
                    },
                    {
                        id      :   'lastYear',
                        title   :   'Last Year'
                    }
                ],
                formatter: (item) => {
                    return item.title
                }
            },
            {
                type: 'radio_list',
                name: 'flag',
                title: 'Flag Status',
                icon: <i className="ri-flag-line" />,
                isLoading: false,
                defaultValue: tableFilters?.flag,
                data: [
                    {
                        id      :   1,
                        title   :   'Flagged'
                    },
                    {
                        id      :   0,
                        title   :   'Unflagged'
                    }
                ],
                formatter: (item) => {
                    return item.title
                }
            },
            {
                type: 'search_textbox',
                name: 'text',
                title: 'Search Text',
                icon: <i className="ri-search-2-line" />,
                defaultValue: tableFilters?.text,
                options: {
                    placeholder: 'Search in title and body'
                },
                formatter: (item) => {
                    return item.title
                }
            }
        ]
    }, [
        tableFilters,
        t
    ]);

    const onTableFilterOkButtonClicked = (filters) => {
        if (filters) {
            const result = {}

            if ('flag' in filters) {
                result.flag = filters.flag !== 'all' ? filters.flag : undefined
            }

            if ('date' in filters) {
                result.date = filters.date !== 'all' ? filters.date : undefined
            }

            if('text' in filters && filters.text !== ''){
                result.text = filters.text;
            }
            else{
                result.text = undefined;
            }
            
            setTableFilters({
                ...tableFilters,
                ...result
            })
        } else {
            setTableFilters({
                flag        :   null,
                date        :   null,
                text        :   undefined
            })
        }
    }

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }

        onChange && onChange(tableFilters)
    }, [ tableFilters ]);

    return (
        <TableFilter
            className="me-2"
            defaultActiveTab='date'
            tabs={tableFilterTabs}
            onOkButtonClicked={
                onTableFilterOkButtonClicked
            }
        />
    )
}

export default withNamespaces()(FilteringComponent);