import { withNamespaces } from "react-i18next";
import { useState, useEffect, memo } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import defaultImage from "src/modules/data-protection/assets/images/defaultNewsImage.svg";
import DataProtectionNewsService from "src/modules/data-protection/apis/NewsService";
import SingleNews from "../components/single-news";
import { withRouter } from "react-router-dom";

const DataProtectionNewsList = ({ t, assetId }) => {
  const [news, setNews] = useState([]);
  const [selectedNewsId, setSelectedNewsId] = useState(null); // State to track selection
  // Functions
  // List
  const listOfNews = useQuery({
    queryKey: ["latestNews"],
    queryFn: async () => {
      const service = DataProtectionNewsService.getInstance();
      const response = await service.latestNewsList(assetId);
      return response.news || [];
    },
    cacheTime: 0,
    refetchOnWindowFocus: true,
    onError(error) {
      if (process.env.NODE_ENV === 'development') console.error(error);
      toast(t("error while fetchinig news"), {
        type: "error",
      });
    },
  });

  // Toggle Flag
  const onToggleNews = useMutation({
    mutationFn: async (payload) => {
      const service = DataProtectionNewsService.getInstance();

      return await service.toggleFlag(payload);
    },
    onSuccess: () => {
      listOfNews.refetch();
      toast(t("News toggled successfully."), {
        type: "success",
      });
    },
    onError: (error) => {
      if (process.env.NODE_ENV === 'development') console.error(error);

      toast(t("An error occurred while updating your request."), {
        type: "error",
      });
    },
  });

  useEffect(() => {
    if (listOfNews.isLoading || onToggleNews.isLoading) return;
    setNews(listOfNews?.data || []);
  }, [listOfNews?.data]);

  return (
    news.length ? (
      <div className="p-4">
        {news?.map((n) => {
          return (
            <SingleNews
              t={t}
              image={defaultImage}
              selectedNews={n}
              onFlagNews={() => onToggleNews.mutate(n?.id)}
              isSelected={n.id === selectedNewsId} // Pass selection state
              onClick={() => setSelectedNewsId(n.id)} // Set selection on click
            />
          );
        })}
      </div>
    ) : <p className="p-4">{t("The is no news at the moment")}</p>
  );
};

export default withNamespaces()(withRouter(memo(DataProtectionNewsList)));
