import React from "react";
import { View } from "@react-pdf/renderer";
import { withNamespaces } from "react-i18next";
import Locations from "./Locations";
import Products from "./Products";
import ContactPeople from "./ContactPeople";
import ManagementTeam from "./ManagementTeam";
import Shareholders from "./Shareholders";
import Findings from "./Findings";
import Risks from "./Risks"
import styles from "../Styles";
import Tasks from "./Tasks";
import Reports from "./Reports";

const Sections = (props) => {
    const { supplierData, noteBackgroundColor } = props;
    
    styles.sectionSeparator.backgroundColor = noteBackgroundColor;

    return (
        <View>
            <Locations locations={ supplierData?.supplierDetail?.locations } />

            <Products products={ supplierData?.products || [] } />

            <ContactPeople people={ supplierData?.contactPersons || [] } />

            <ManagementTeam people={ supplierData?.teamManagement || [] } />

            <Shareholders holders={ supplierData?.shareHolders || {} } />

            <Findings news={ supplierData?.news || [] } />

            <Risks risks={ supplierData?.risks || [] } />

            <Tasks tasks={ supplierData?.tasks || [] } /> 

            <Reports reports={ supplierData?.reports || [] } />
        </View>
    );
};

export default withNamespaces()(Sections);