import { memo } from "react";
import { Button } from "reactstrap";
import { FaChevronRight } from "react-icons/fa";
import { TbLoader } from "react-icons/tb";

const ButtonsGroup = function ({ t, onSubmitClick, onRegenerateClick, onProgress }) {
  return (
    <>
      <div
        className="mt-3"
        style={{display: "flex"}}
      >
        <Button color="primary" onClick={onRegenerateClick} disabled={onProgress} className="me-2" outline>
          {t("Regenerate")}
        </Button>

        {
          <Button color="primary" onClick={onSubmitClick} style={{display: "flex"}} disabled={onProgress}>
            {onProgress ? 
            <TbLoader /> :
            <>{t("Next")} <FaChevronRight className="ps-2 pt-2" /></>
            }
        </Button>}
      </div>
    </>
  );
};

export default memo(ButtonsGroup);
