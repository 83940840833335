import { useRef } from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import { isFeatureEnabledForActiveModule } from 'src/helpers/module_helper';
import PageHeader from 'src/modules/3rd-party-management/components/page-header';
import UserUtils from 'src/services/utils/UserUtils';
import { withRouter } from "react-router-dom";
import CreateDocument from './create';
import ListDocuments from './list';

const Documents = ({
    t,
    user
}) => {
    const listDocumentsActionsRef = useRef();
    const userUtils = new UserUtils();

    return (
        <div className='page-content'>
            <Container fluid>
                <PageHeader 
                    title={t('Documents')} 
                />

                <Row className='mb-3'>
                    <Col sm='12'>
                        {!isFeatureEnabledForActiveModule('Documents') ? (
                            <>
                                {t("Please upgrade your plan to access this feature")}.
                            </>
                        ) : (
                            <>
                                {userUtils.isAnalystAdmin(user) && (
                                    <CreateDocument onCreateSuccessfull={() => {
                                        if(listDocumentsActionsRef?.current){
                                            listDocumentsActionsRef.current.reload()
                                        }
                                    }} />
                                )}

                                <ListDocuments actionsRef={ listDocumentsActionsRef }/>
                            </>
                        )}
                    </Col>
                </Row>
            </Container>
        </div>
    )
};

const mapStatetoProps = (state) => {
	const { token, user } = state.Login;
	const { Organization } = state;
	
	return {
		token,
		user,
		organization: Organization
	};
};

export default withNamespaces()(
    withRouter(
        connect(mapStatetoProps, {
        })(
            Documents
        )
    )
);