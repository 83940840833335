import { memo, useEffect, useState } from 'react';
import { AvRadio, AvField } from 'availity-reactstrap-validation';
import DateInputBefor from 'src/components/Fields/DateInputBefor';
import { withNamespaces } from 'react-i18next';
import DateUtils from 'src/services/utils/DateUtils';

const Option = (props) => {
    const {
        id, 
        title, 
        type,
        isSelected,
        groupId,
        elRefIdPrefix,
        defaultValue,
        extendedTitle,
        onChange
    } = props;

    const dateUtils = new DateUtils();

    const [selectedValue, setSelectedValue] = useState('');

    const renderOptionByType = () => {
        if(type === 'date'){
            return (
                <>
                    <AvRadio
                        id={`${elRefIdPrefix}-question-${groupId}-option-${id}`}
                        label={props.t(title)}
                        datatitle={title}
                        datatype={type}
                        datarelinputref={`${elRefIdPrefix}-question-${groupId}-option-${id}-rel-input`}
                        value={id}
                        onChange={(e) => onChange && onChange(e)}
                    />
                    {
                        isSelected ? (
                            <DateInputBefor
                                startFrom={new Date()}
                                name="date"
                                id={`${elRefIdPrefix}-question-${groupId}-option-${id}-rel-input`}
                                value={selectedValue}
                                onValueChanged={(value) => {
                                    setSelectedValue(value);
                                }}
                            />
                        ) : null
                    }
                </>
            );
        }
        else if(type === 'text'){
            return (
                <>
                    <AvRadio
                        id={`${elRefIdPrefix}-question-${groupId}-option-${id}`}
                        label={props.t(title)}
                        datatitle={title}
                        datatype={type}
                        datarelinputref={`${elRefIdPrefix}-question-${groupId}-option-${id}-rel-input`}
                        value={id}
                        onChange={(e) => onChange && onChange(e)}
                    />
                    {
                        isSelected ? (
                            <AvField
                                type="text"
                                id={`${elRefIdPrefix}-question-${groupId}-option-${id}-rel-input`}
                                name="text"
                                value={selectedValue}
                                placeholder={props.t(extendedTitle || '')}
                                validate={{
                                    required: { value: false },
                                }}
                                onChange={(e) => setSelectedValue(e.target.value)}
                            />
                        ) : null
                    }
                </>
            );
        }
        else{
            return (
                <AvRadio
                    id={`${elRefIdPrefix}-question-${groupId}-option-${id}`}
                    label={props.t(title)}
                    datatitle={title}
                    datatype={type}
                    value={id}
                    onChange={(e) => onChange && onChange(e)}
                />
            );
        }
    }

    useEffect(() => {
        if(type === 'date'){
            if(defaultValue){
                setSelectedValue(dateUtils.parseDate(defaultValue, 'dd/mm/yyyy'));
            }
            else{
                setSelectedValue(new Date());
            }
        }
        else if(type === 'text'){
            setSelectedValue(defaultValue || '');
        }
    }, [defaultValue]);

    return (
        <>
            { renderOptionByType() }
        </>
    );
}

export default withNamespaces()(memo(Option));