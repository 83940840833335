export const ROUTE_PATH_ROOT = "/:locale/:organization";
export const ROUTE_PATH_SIGN_IN = "/signin";
export const ROUTE_PATH_SET_PASSWORD = "/set-password/:token";
export const ROUTE_PATH_RESET_PASSWORD = "/reset-password";
export const ROUTE_PATH_RESET_PASSWORD_WITH_TOKEN = "/reset-password/:token";
export const ROUTE_PATH_EXPIRED = "/expired";
export const ROUTE_PATH_OTHERS = "*";

const ROUTE_PATH_EMPLOYEE_ROOT = "/employee";
export const ROUTE_PATH_EMPLOYEE_PROFILE =
  ROUTE_PATH_EMPLOYEE_ROOT + "/profile";
export const ROUTE_PATH_EMPLOYEE_HOME = ROUTE_PATH_EMPLOYEE_ROOT + "/home";
export const ROUTE_PATH_EMPLOYEE_FAQ = ROUTE_PATH_EMPLOYEE_ROOT + "/FAQ";
export const ROUTE_PATH_EMPLOYEE_FEEDBACK =
  ROUTE_PATH_EMPLOYEE_ROOT + "/feedback";
export const ROUTE_PATH_EMPLOYEE_TECHNICAL_SUPPORT =
  ROUTE_PATH_EMPLOYEE_ROOT + "/technical-support";
export const ROUTE_PATH_PROFILE = ROUTE_PATH_EMPLOYEE_ROOT + "/profile";
export const ROUTE_PATH_EMPLOYEE_E_LEARNING =
  ROUTE_PATH_EMPLOYEE_ROOT + "/e-learning";
export const ROUTE_PATH_EMPLOYEE_E_LEARNING_DETAIL =
  ROUTE_PATH_EMPLOYEE_ROOT + "/e-learning/:id";
export const ROUTE_PATH_EMPLOYEE_TUTORIAL =
  ROUTE_PATH_EMPLOYEE_ROOT + "/tutorial";
export const ROUTE_PATH_EMPLOYEE_TUTORIAL_DETAIL =
  ROUTE_PATH_EMPLOYEE_ROOT + "/tutorial/:id";

export const ROUTE_PATH_REPORTING_CHANNEL =
  ROUTE_PATH_EMPLOYEE_ROOT + "/reporting-channel";
export const ROUTE_PATH_REPORT_ANONYMOUSLY =
  ROUTE_PATH_EMPLOYEE_ROOT + "/report-anonymously";
export const ROUTE_PATH_REPORT_CONFIDENTLY =
  ROUTE_PATH_EMPLOYEE_ROOT + "/report-confidentially";
export const ROUTE_PATH_ARRANGE_MEETING =
  ROUTE_PATH_EMPLOYEE_ROOT + "/arrange-meeting";
export const ROUTE_PATH_VOICE_REPORT =
  ROUTE_PATH_EMPLOYEE_ROOT + "/voice-report";
export const ROUTE_PATH_REPORT_SUBMITTED =
  ROUTE_PATH_EMPLOYEE_ROOT + "/report-submitted";
export const ROUTE_PATH_MEETING_SUBMITTED =
  ROUTE_PATH_EMPLOYEE_ROOT + "/meeting-submitted";
export const ROUTE_PATH_EMPLOYEE_DOCUMENTS =
  ROUTE_PATH_EMPLOYEE_ROOT + "/documents";
export const ROUTE_PATH_EMPLOYEE_PLATFORM_UPDATE = ROUTE_PATH_EMPLOYEE_ROOT + "/platform-updates";

  export const ROUTE_PATH_REPORT_LIST = ROUTE_PATH_EMPLOYEE_ROOT + "/reports";
export const ROUTE_PATH_REPORT_DETAIL =
  ROUTE_PATH_EMPLOYEE_ROOT + "/report/detail";
export const ROUTE_PATH_EMPLOYEE_TASKS_LIST = ROUTE_PATH_EMPLOYEE_ROOT + "/tasks";

const ROUTE_PATH_THIRD_PARTY_ROOT = "/third-party";
export const ROUTE_PATH_THIRD_PARTY_PROFILE =
  ROUTE_PATH_THIRD_PARTY_ROOT + "/profile";
export const ROUTE_PATH_THIRD_PARTY_E_LEARNING =
  ROUTE_PATH_THIRD_PARTY_ROOT + "/e-learning";
export const ROUTE_PATH_THIRD_PARTY_E_LEARNING_DETAIL =
  ROUTE_PATH_THIRD_PARTY_ROOT + "/e-learning/:id";
export const ROUTE_PATH_THIRD_PARTY_SIGN_IN =
  ROUTE_PATH_THIRD_PARTY_ROOT + "/signin";
export const ROUTE_PATH_THIRD_PARTY_HOME =
  ROUTE_PATH_THIRD_PARTY_ROOT + "/home";
export const ROUTE_PATH_THIRD_PARTY_REPORTING_CHANNEL =
  ROUTE_PATH_THIRD_PARTY_ROOT + "/reporting-channel";
export const ROUTE_PATH_THIRD_PARTY_REPORT_ANONYMOUSLY =
  ROUTE_PATH_THIRD_PARTY_ROOT + "/report-anonymously";
export const ROUTE_PATH_THIRD_PARTY_REPORT_CONFIDENTLY =
  ROUTE_PATH_THIRD_PARTY_ROOT + "/report-confidentially";
export const ROUTE_PATH_THIRD_PARTY_VOICE_REPORT =
  ROUTE_PATH_THIRD_PARTY_ROOT + "/voice-report";
export const ROUTE_PATH_THIRD_PARTY_ARRANGE_MEETING =
  ROUTE_PATH_THIRD_PARTY_ROOT + "/arrange-meeting";
export const ROUTE_PATH_THIRD_PARTY_REPORT_SUBMITTED =
  ROUTE_PATH_THIRD_PARTY_ROOT + "/report-submitted";
export const ROUTE_PATH_THIRD_PARTY_MEETING_SUBMITTED =
  ROUTE_PATH_THIRD_PARTY_ROOT + "/meeting-submitted";
export const ROUTE_PATH_THIRD_PARTY_REPORT_LIST =
  ROUTE_PATH_THIRD_PARTY_ROOT + "/reports";
export const ROUTE_PATH_THIRD_PARTY_REPORT_DETAIL =
  ROUTE_PATH_THIRD_PARTY_ROOT + "/report/detail";
export const ROUTE_PATH_THIRD_PARTY_DOCUMENTS =
  ROUTE_PATH_THIRD_PARTY_ROOT + "/documents";
export const ROUTE_PATH_THIRD_PARTY_TUTORIAL =
  ROUTE_PATH_THIRD_PARTY_ROOT + "/tutorial";
export const ROUTE_PATH_THIRD_PARTY_TUTORIAL_DETAIL =
  ROUTE_PATH_THIRD_PARTY_ROOT + "/tutorial/:id";
export const ROUTE_PATH_THIRD_PARTY_PLATFORM_UPDATE = ROUTE_PATH_THIRD_PARTY_ROOT + "/platform-updates";

export const ROUTE_PATH_ADMIN_ROOT = "/admin";
export const ROUTE_PATH_ADMIN_HOME = ROUTE_PATH_ADMIN_ROOT + "/home";
export const ROUTE_PATH_ADMIN_PROFILE = ROUTE_PATH_ADMIN_ROOT + "/profile";
export const ROUTE_PATH_ADMIN_DOCUMENTS = ROUTE_PATH_ADMIN_ROOT + "/documents";
export const ROUTE_PATH_ADMIN_KANBAN_BOARD =
  ROUTE_PATH_ADMIN_ROOT + "/kanban-board";
export const ROUTE_PATH_ADMIN_RISK_MANAGMENT =   ROUTE_PATH_ADMIN_ROOT + "/risk-management";
export const ROUTE_PATH_ADMIN_ENTITY_MANAGMENT =   ROUTE_PATH_ADMIN_ROOT + "/entity-management/entities";
export const ROUTE_PATH_ADMIN_ADMINISTRATION =
  ROUTE_PATH_ADMIN_ROOT + "/administration";
export const ROUTE_PATH_ADMIN_MY_TASK = ROUTE_PATH_ADMIN_ROOT + "/my-task";
export const ROUTE_PATH_ADMIN_E_LEARNING =
  ROUTE_PATH_ADMIN_ROOT + "/e-learning";
export const ROUTE_PATH_ADMIN_E_LEARNING_DETAIL =
  ROUTE_PATH_ADMIN_ROOT + "/e-learning/:id";
export const ROUTE_PATH_ADMIN_TUTORIAL = ROUTE_PATH_ADMIN_ROOT + "/tutorial";
export const ROUTE_PATH_ADMIN_USER_LIST =
  ROUTE_PATH_ADMIN_ADMINISTRATION + "/users";
export const ROUTE_PATH_ADMIN_USER_ADD =
  ROUTE_PATH_ADMIN_ADMINISTRATION + "/user/add";
export const ROUTE_PATH_ADMIN_CUSTOMIZATION =
  ROUTE_PATH_ADMIN_ADMINISTRATION + "/customization";
export const ROUTE_PATH_ADMIN_HISTORY_LOG =
  ROUTE_PATH_ADMIN_ADMINISTRATION + "/history-logs";

export const ROUTE_PATH_ADMIN_QUESTION_GENERATOR =
  ROUTE_PATH_ADMIN_ADMINISTRATION + "/questionnaire-designer";
export const ROUTE_PATH_PARTNER_CLIENT_MANAGEMENT =
ROUTE_PATH_ADMIN_ADMINISTRATION + "/clients";
export const ROUTE_PATH_ADMIN_SETTINGS =
  ROUTE_PATH_ADMIN_ADMINISTRATION + "/settings";
export const ROUTE_PATH_ADMIN_PRIVACY_POLICY =
  ROUTE_PATH_ADMIN_ADMINISTRATION + "/privacy-policy";
export const ROUTE_PATH_ADMIN_PAYMENT =
  ROUTE_PATH_ADMIN_ADMINISTRATION + "/payment";
export const ROUTE_PATH_ADMIN_REPORT_LIST = ROUTE_PATH_ADMIN_ROOT + "/reports";
export const ROUTE_PATH_ADMIN_REPORT_DETAIL =
  ROUTE_PATH_ADMIN_ROOT + "/report/detail";
export const ROUTE_PATH_ADMIN_REPORTING_CHANNEL =
  ROUTE_PATH_ADMIN_ROOT + "/reporting-channel";
export const ROUTE_PATH_ADMIN_REPORT_ANONYMOUSLY =
  ROUTE_PATH_ADMIN_ROOT + "/report-anonymously";
export const ROUTE_PATH_ADMIN_REPORT_CONFIDENTLY =
  ROUTE_PATH_ADMIN_ROOT + "/report-confidentially";
export const ROUTE_PATH_ADMIN_REPORT_SUBMITTED =
  ROUTE_PATH_ADMIN_ROOT + "/report-submitted";
// export const ROUTE_PATH_ADMIN_CHAT = ROUTE_PATH_ADMIN_ROOT + "/livechat";
export const ROUTE_PATH_ADMIN_DASHBOARD = ROUTE_PATH_ADMIN_ROOT + "/dashboard";
export const ROUTE_PATH_ADMIN_PLATFORM_UPDATE = ROUTE_PATH_ADMIN_ROOT + "/platform-updates";
