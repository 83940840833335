import React, { memo, useMemo } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { Row, Col, Card, CardBody, Container } from "reactstrap";
import RiskCustomazation from "./components/riskCutomization";
import Routing from "./components/routing";
import Tabs from "./components/tabs";
import { useLocation } from "react-router-dom";
import Navigator from "src/modules/3rd-party-management/components/navigator";
import { isFeatureEnabledForActiveModule } from "src/helpers/module_helper";

const Settings = (props) => {
    const { t } = props;

    const location = useLocation();

    const renderSelectedTabContent = () => {
        const searchParams = new URLSearchParams(location.search);

        switch (searchParams.get("tab")) {
            case "risk-customization":
                if(!isFeatureEnabledForActiveModule("risk-assessment")){
                    return (
                        <div>
                            {t("Please upgrade your plan to access this feature")}.
                        </div>
                    );
                }
                return <RiskCustomazation />;
            default:
                return <Routing />;
        }
    };

    const navigatorOptions = useMemo(() => {
        return {
            backButtonOptions   :   ()  => {
                return {
                    enable  :   false,
                    title   :   t("Back"),
                    icon    :   <i className="ri-arrow-left-fill"></i>
                }
            },
            breadCrumbs         :   [
                {
                    title   :   t("SIP"), 
                    link    :   "/admin/3rd-party/dashboard"
                },
                {
                    title   :   t("Administration"), 
                    link    :   "/admin/3rd-party/administration/settings"
                },
                {
                    title   :   t("Settings"), 
                    link    :   "/admin/3rd-party/administration/settings"
                }
            ],
            pageTitle           :   t('Settings')
        }
    }, [ t, location.search ]);

    return (
        <div className="page-content ">
            <Container fluid>
                <Row>
                    <Col lg="12" className="mb-4">
                        <Navigator {...navigatorOptions}/>
                    </Col>

                    <Col lg="12" className="mb-4">
                        <div className="details-content-wrapper">
                            <>
                                <Tabs t={t} />
                                <Row>
                                    <Col xl="12">
                                        <Card style={{marginBottom:'0px'}}>
                                            <CardBody className="tabOverview">
                                                <div className="d-flex flex-column gap-4 mb-4">
                                                    {renderSelectedTabContent()}
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStatetoProps = (state) => {
    const { token } = state.Login;
    const { Organization, Modules } = state;
    return {
        token,
        user            :   state.Login.user,
        organization    :   Organization,
        modules         :   Modules,
    };
};

export default withNamespaces()(connect(mapStatetoProps)(memo(Settings)));

