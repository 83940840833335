import { withNamespaces } from "react-i18next";
import { Text, View } from "@react-pdf/renderer";
import styles from "../../Styles";
import ReportContent from "./report";

const Reports = ({
    t,
    reports
}) => {
    const groupQuestionsBySection = (questions) => {
        const groupedQuestionsBySection = [];

        const findSection = (sectionName) => {
            return groupedQuestionsBySection.find((section) => {
                return section.name === sectionName;
            });
        }

        for(let i = 0; i < questions.length; i++){
            const question = questions[i];
            let section = findSection(question.section || '');

            if(!section){
                section = {
                    name : question.section || '',
                    items : []
                }
                groupedQuestionsBySection.push(section);
            }

            section.items.push(question);
        }

        return groupedQuestionsBySection;
    }

    return (
        <View break>
            <View style={styles.sectionSeparator}>
                <Text>{t('REPORTS')}</Text>
            </View>
        
            <View style={styles.section}>
                {reports.map((report, index) => {
                    return (
                        <ReportContent
                            key={index}
                            sections={groupQuestionsBySection(report)}
                        />
                    )
                })}
            </View>
        </View>
    )
};

export default withNamespaces()(Reports);