import { Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

const VerticalMenuContent = (props) => {
	const {
		menu,
		t
	} = props;

    const location = useLocation();
    const [ activeMenuIndex, setActiveMenuIndex ] = useState(-1);

    const activateParentDropdown = () => {
        var matchingMenuItem = null;
        var ul = document.getElementById("side-menu");
        var items = ul.getElementsByTagName("a");
        const currentPath = location.pathname;

        for (var i = 0; i < items.length; ++i) {
            if (items[i].pathname.includes(currentPath)) {
                matchingMenuItem = items[i];
                break;
            }
        }

        if (matchingMenuItem) {
            matchingMenuItem.classList.add("active");

            const parent = matchingMenuItem.closest('.afc-dropdown');

            if (parent) {
                parent.classList.add("active")
                return false;
            }
        }
    };

    useEffect(() => {
        activateParentDropdown()
    }, [menu]);

	return (
		<ul className="metismenu list-unstyled" id="side-menu">
            {
				menu.map((menuItem, menuItemIndex) => {
					const subMenuItems = menuItem.items || [];
					const hasSubMenu = subMenuItems.length > 0;

					return (
						<li key={menuItemIndex} className={`afc-dropdown ${activeMenuIndex === menuItemIndex ? 'active' : ''}`} onClick={() => setActiveMenuIndex(menuItemIndex)}>
							{
								menuItem.linkType === 'react-router' ? (
									<>
									{menuItem?.path ? 
                                        <Link to={menuItem.path}
                                            className={`${hasSubMenu ? "has-arrow" : null} waves-effect`}>
                                                
                                            <i className={menuItem.icon}></i>
                                            
                                            <span className="ms-1">
                                                {t(menuItem.titleKey)}
                                            </span>
                                        </Link>
									// eslint-disable-next-line jsx-a11y/anchor-is-valid
									: <a className={`${hasSubMenu && "has-arrow" } waves-effect`}>
                                            
                                        <i className={menuItem.icon}></i>
                                        
                                        <span className="ms-1">
                                            {t(menuItem.titleKey)}
                                        </span>
                                    </a>}
									</>
								) : (
									<a href={menuItem.path}
										className="waves-effect" target='_new'>
											
										<i className={menuItem.icon}></i>
										
										<span className="ms-1">
											{t(menuItem.titleKey)}
										</span>
									</a>
								)
							}
							

							{
								hasSubMenu ? (
									<ul className="sub-menu">
										{subMenuItems.map((subMenuItem, subMenuItemIndex) => {
											return (
												<li key={subMenuItemIndex}>
													{
														subMenuItem.linkType === 'react-router' ? (
															<Link to={subMenuItem.path}>
																{t(subMenuItem.titleKey)}
															</Link>
														) : (
															<a href={subMenuItem.path} target="_new">
																{t(subMenuItem.titleKey)}
															</a>
														)
													}
												</li>
											);
										})}
									</ul>
								) : null
							}
						</li>
					);
				})
			}
        </ul>
	);
}

export default withNamespaces()(VerticalMenuContent);