import { takeEvery, put, call } from "redux-saga/effects"

import {
  GET_CHATS,
  START_CHAT,
  GET_CONTACTS,
  GET_GROUPS,
  GET_MESSAGES,
  POST_ADD_MESSAGE,
  SEND_MESSAGE,
  FINISH_CHAT,
  DELETE_CHAT
} from "./actionTypes"
import {
  getChatsSuccess,
  getChatsFail,
  getGroupsSuccess,
  getGroupsFail,
  getContactsSuccess,
  getContactsFail,
  getMessagesSuccess,
  getMessagesFail,
  addMessageFail,
  startChatSuccess,
  startChatFail,
  finishChatSuccess,
  finishChatFail,
  deleteChatSuccess,
  changeChatLoading,
  changeMessagesLoading
} from "./actions"

import {
  getChats,
  deleteChat,
  getGroups,
  getContacts,
  getMessages,
  addMessage,
  startChating,
  sendMessage,
  finishChating
} from "../../helpers/chat_helper"

function* onGetChats() {
  try {
    const response = yield call(getChats)
    yield put(getChatsSuccess(Array.isArray(response) ? response : []))
  } catch (error) {
    yield put(getChatsFail(error))
  }
}

function* onGetGroups() {
  try {
    const response = yield call(getGroups)
    yield put(getGroupsSuccess(response))
  } catch (error) {
    yield put(getGroupsFail(error))
  }
}

function* onGetContacts() {
  try {
    const response = yield call(getContacts)
    yield put(getContactsSuccess(response))
  } catch (error) {
    yield put(getContactsFail(error))
  }
}

function* onGetMessages({ chatId }) {
  try {
    yield put(changeMessagesLoading(true))
    const response = yield call(getMessages, chatId)
    yield put(getMessagesSuccess(Array.isArray(response) ? response : []))
  } catch (error) {
    yield put(getMessagesFail(error))
  }
}

function* onAddMessage({ message }) {
  try {
    yield call(addMessage, message)
  } catch (error) {
    yield put(addMessageFail(error))
  }
}

function* onSendMessage({ message }) {
  try {
    yield call(sendMessage, message)
  } catch (error) {
    yield put(addMessageFail(error))
  }
}

function* onStartChat(infos){
  try {
    const response = yield call(startChating, infos);
    yield put(startChatSuccess(response.chatId))
  } catch (error) {
    yield put(startChatFail(error))
  }
}

function* onFinishChat({payload}){
  try {
    yield call(finishChating, payload);
    yield put(finishChatSuccess());
  } catch (error) {
    yield put(finishChatFail(error))
  }
}

function* onDeleteChat({payload: chatId}){
  try {
    yield put(changeChatLoading(true))
    yield call(deleteChat, chatId);
    yield put(deleteChatSuccess());
  } catch (error) {}
}

function* chatSaga() {
  yield takeEvery(GET_CHATS, onGetChats)
  yield takeEvery(GET_GROUPS, onGetGroups)
  yield takeEvery(GET_CONTACTS, onGetContacts)
  yield takeEvery(GET_MESSAGES, onGetMessages)
  yield takeEvery(POST_ADD_MESSAGE, onAddMessage)
  yield takeEvery(START_CHAT, onStartChat)
  yield takeEvery(SEND_MESSAGE, onSendMessage)
  yield takeEvery(FINISH_CHAT, onFinishChat)
  yield takeEvery(DELETE_CHAT, onDeleteChat)
}

export default chatSaga
