import axios from 'axios';

import {
    API_BASE_URL
} from '../constants/index';

export default class SupplierTaskAssessmentService {
	static instance;

	static getInstance() {
		if (!SupplierTaskAssessmentService.instance) {
			SupplierTaskAssessmentService.instance = new SupplierTaskAssessmentService();
		}

		return SupplierTaskAssessmentService.instance;
	}

    async fetchTasks(supplierId, params = {}){
        const response = await axios.get(`${API_BASE_URL}/supplier/${supplierId}/tasks`, {
            params  :   params,
            headers :   {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`
            },
        });
        return response.data.data
    }

    async fetchOwners(supplierId, payload = {}){
        const response = await axios.post(`${API_BASE_URL}/analystAndAdminAnalystAndTaskOwners.list`, payload, {
            params: {},
            headers: {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`,
            },
        });
  
        return response.data.data;
    }

    async fetchCategories(supplierId, params = {}){
        const response = await axios.get(`${API_BASE_URL}/task/categories`, {
            params  :   params,
            headers :   {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`
            },
        });

        return response.data?.data || [];
    }

    async fetchMitigationCategories(supplierId, params = {}){
        const response = await axios.get(`${API_BASE_URL}/mitigation-task/categories`, {
            params  :   params,
            headers :   {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`
            },
        });

        return response.data?.data || [];
    }

    async deleteTask(supplierId, taskId){
        const response = await axios.delete(`${API_BASE_URL}/task/${taskId}/delete`,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                    )}`,
                }
            }
        );
  
        return response.data.data;
    }    
}
