import { AvField } from "availity-reactstrap-validation";
import react, { useState } from "react";
import "./style.css";
export default function PhoneNumber(props) {
  const { name, id, className, validate, placeholder } = props;
  const [inputValue, setInputValue] = useState("");

  const handleInput = (e) => {
    setInputValue(formatPhoneNumber(e.target.value));
  };

  return (
    <AvField
      name={name}
      placeholder={placeholder}
      type="number"
      className={className}
      validate={validate}
      id={id}
      onChange={(e) => handleInput(e)}
      value={inputValue}
    />
  );
}

function formatPhoneNumber(value) {
  if (!value) return value;
  return value.replace(/\D/g, "");
}
