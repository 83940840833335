import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Container } from "reactstrap";
import PageHeader from "src/modules/3rd-party-management/components/page-header";
import ListDocuments from "../administration/documents/list";
import { useMemo } from "react";

const ViewDocuments = ({
    t
}) => {

    const documentsSettings = useMemo(() => {
        return {
            actions: {
                delete: false
            }
        }
    }, []);

    return (
        <div className='page-content'>
            <Container fluid>
                <PageHeader title={t('Documents')} />

                <ListDocuments settings={documentsSettings}/>   
            </Container>
        </div>
    )
};

const mapStatetoProps = (state) => {
    const { token, user } = state.Login;
    const organization = state.Organization;
    const App = state.App;
    return { organization, token, App, user };
};
  
export default withNamespaces()(
    withRouter(connect(mapStatetoProps, null)(ViewDocuments))
);