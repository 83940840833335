import axios from 'axios';

import {
    API_URL_FETCH_COMPANY_COUNTRIES,
    API_BASE_URL
} from '../constants/index';

export default class SupplierFilterService {
	static instance;

	static getInstance() {
        if (!SupplierFilterService.instance) {
            SupplierFilterService.instance = new SupplierFilterService();
        }

        return SupplierFilterService.instance;
	}

    async fetchCountries(params = {}) {
        const response = await axios.get(API_URL_FETCH_COMPANY_COUNTRIES, {
            params: params,
            headers: {
                Authorization: `Bearer ${localStorage.getItem(
                "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`
            }
        });

        return response.data.data
    }

    async fetchIndustries(params = {}) {
        const response = await axios.get(`${API_BASE_URL}/supplier/industries`,
            {
            params: params,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                    )}`
                }
            }
        );

        return response.data.data;
    }

    async fetchProducts(params = {}) {
        const response = await axios.get(`${API_BASE_URL}/supplier/products`,
            {
                params: {
                    ...params
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                    )}`
                }
            }
        );

        return response.data?.data || null;
    }
}
