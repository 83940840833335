import React from 'react'
import HistoryLog from '../../components/history-log' 

const index = ({
    suppliersType
}) => {
    const url = suppliersType === 'own' ? '/logs?ownCompanies=true' : '/logs';

    return (
      <HistoryLog historyUrl={url} isDownloadButton={true}/>
    )
};

export default index;