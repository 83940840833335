import axios from "axios";

import { API_BASE_URL } from "../constants";

export default class DataProtectionHistoryLogeService {
  static instance;
  resource = `${API_BASE_URL}/logs/`;

  constructor() {}

  static getInstance() {
    if (!DataProtectionHistoryLogeService.instance) {
      DataProtectionHistoryLogeService.instance =
        new DataProtectionHistoryLogeService();
    }

    return DataProtectionHistoryLogeService.instance;
  }

  // History Logs
  async fetchLogs(params = {}) {
    const response = await axios.get(
      `${API_BASE_URL}/logs`,
      {
        params: params,
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
          )}`,
        },
      }
    );
    return response.data.data;
  }

  async fetchLogDetails(logId, params = {}) {
    const response = await axios.get(
      `${API_BASE_URL}/log/${logId}/show`,
      {
        params: params,
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
          )}`,
        },
      }
    );
    return response.data.data;
  }

}
