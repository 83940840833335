import React, { useState } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { INTERNATIONAL_DATE_FORMAT } from "../constants";

const DateInput = (props) => {
    const {
        name,
        id,
        placeholder,
        errorMessage,
        validate,
        value,
        onValueChanged,
        startFrom
    } = props;
    return (
        <DatePicker 
            dateFormat={INTERNATIONAL_DATE_FORMAT}
            name={name}
            id={id}
            placeholder={placeholder}
            minDate={startFrom}
            selected={value} 
            onChange={(date) => onValueChanged(date)}
            className="form-control"  
        />
    );
};

export default DateInput;