import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, Label } from 'reactstrap';
import Select from 'react-select';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { API_URL_MY_TASKS, EMPTY_LIST, INTERNATIONAL_DATE_FORMAT } from '../../../common/constants';
import DateUtils from '../../../services/utils/DateUtils';
import { HiChevronDoubleDown, HiChevronDoubleUp, HiMenuAlt4 } from 'react-icons/hi';
import axios from 'axios';
import { getCurrentLanguage } from '../../../i18n';

class EmployeeMyTasks extends Component {
	constructor(props) {
		super(props);
		this.state = {
			breadcrumbItems: [
				{ title: 'SIP', link: '/' },
				{ title: 'My Tasks', link: '#' },
			],
			selectedLanguage: getCurrentLanguage(),
			loadingTasks: true,
			selectedStatusFilter: null,
			selectedPriorityFilter: null,
			tasks: [],
			filter: {
				status: null,
				priority: null,
			},
		};
		this.statuses = [
			{
				value: 'NEW',
				baseLabel: 'New',
				label: this.props.t('New'),
			},
			{
				value: 'IN_PROGRESS',
				baseLabel: 'In Progress',
				label: this.props.t('In Progress'),
			},
			{
				value: 'REVIEW',
				baseLabel: 'Review',
				label: this.props.t('Review'),
			},
			{
				value: 'CLOSED',
				baseLabel: 'Closed',
				label: this.props.t('Closed'),
			},
			{
				value: 'DELETED',
				baseLabel: 'Deleted',
				label: this.props.t('Deleted'),
			},
		];
		this.priorities = [
			{
				value: 'low',
				baseLabel: 'Low',
				label: (
					<>
						<HiChevronDoubleDown className='text-info' />
						<span>{this.props.t('Low')}</span>
					</>
				),
			},
			{
				value: 'medium',
				baseLabel: 'Medium',
				label: (
					<>
						<HiMenuAlt4 className='text-success' />
						<span>{this.props.t('Medium')}</span>
					</>
				),
			},
			{
				value: 'high',
				baseLabel: 'High',
				label: (
					<>
						<HiChevronDoubleUp className='text-danger' />
						<span>{this.props.t('High')}</span>
					</>
				),
			},
		];
		this.sort = [
			{
				value: 'priority',
				baseLabel: 'Priority',
				label: this.props.t('Priority'),
			},
			{
				value: 'deadline',
				baseLabel: 'Deadline',
				label: this.props.t('Deadline'),
			},
		];
		this.dateUtils = new DateUtils();
	}

	handleFilterAndSortTasks(status, priority, sort, token) {
		console.log(status,priority);
		this.setState({
			filter: {
				status,
				priority,
			},
			sort: sort,
		});

		this.handleFetchTasks(
			{
				status: status?.value,
				priority: priority?.value,
			},
			sort?.value,
			token,
		);
	}

	handleFetchTasks = async (filter, sort, token) => {
		this.setState({
			tasks: [],
			loadingTasks: true,
		});

		const data = {};

		if (filter) {
			if (filter.status) data['status'] = filter.status;
			if (filter.priority) data['priority'] = filter.priority;
		}

		if (sort) data['sortBy'] = sort;

		try {
			const response = await axios.get(API_URL_MY_TASKS, {
				params: data,
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			if (response.status === 200) {
				const tasks = response.data.data.data;

				this.setState({ tasks: tasks });
			}
		} catch (error) {
			console.log(error);
		}

		this.setState({
			loadingTasks: false,
		});
	};

	componentDidMount() {
		this.handleFetchTasks(null, null, this.props.token);
	}

	render() {
		return (
			<React.Fragment>
				<div className='page-content'>
					<Container fluid>
						<Breadcrumbs title={this.props.t('My Tasks')} breadcrumbItems={this.state.breadcrumbItems} />
						<div style={{ width: '100%' }}>
							<Row className='d-flex'>
								<Col sm='12' md='2' lg='2' className='mb-2 '>
									<Label>{this.props.t('Status')}</Label>
									{!this.state.loadingTasks ? (
										<Select
											classNamePrefix='select2-selection'
											options={this.statuses.map((i) => {
												return {
													value: i.value,
													baseLabel: i.baseLabel,
													label: this.props.t(i.baseLabel),
												};
											})}
											value={this.state.filter.status}
											onChange={(e) => {
												this.handleFilterAndSortTasks(e, this.state.filter.priority, this.state.filter.sort, this.props.token);
											}}
											placeholder={this.props.t('Select status')}
											isClearable={true}
											/>
									) : (
										<div className='dt-field dt-skeleton dt-select-list' style={{ marginBottom: 16 }}></div>
									)}
								</Col>

								<Col sm='12' md='2' lg='2' className='mb-2 '>
									<Label>{this.props.t('Priority')}</Label>
									{!this.state.loadingTasks ? (
										<Select
											classNamePrefix='select2-selection'
											options={this.priorities.map((i) => {
												return {
													value: i.value,
													baseLabel: i.baseLabel,
													label: this.props.t(i.baseLabel),
												};
											})}
											value={this.state.filter.priority}
											onChange={(e) => {
												this.handleFilterAndSortTasks(this.state.filter.status, e, this.state.filter.sort, this.props.token);
											}}
											placeholder={this.props.t('Select priority')}
											isClearable={true}
											/>
									) : (
										<div className='dt-field dt-skeleton dt-select-list' style={{ marginBottom: 16 }}></div>
									)}
								</Col>

								<Col sm='12' md='2' lg='2' className='mb-2' style={{ marginLeft: 'auto', marginRight: '0' }}>
									<Label>{this.props.t('Sort')}</Label>
									{!this.state.loadingTasks ? (
										<Select
											classNamePrefix='select2-selection'
											options={this.sort.map((i) => {
												return {
													value: i.value,
													baseLabel: i.baseLabel,
													label: this.props.t(i.baseLabel),
												};
											})}
											isClearable={true}
											value={this.state.sort}
											onChange={(e) => {
												this.handleFilterAndSortTasks(this.state.filter.status, this.state.filter.priority, e, this.props.token);
											}}
											placeholder={this.props.t('Select an item')}
										/>
									) : (
										<div className='dt-field dt-skeleton dt-select-list' style={{ marginBottom: 16 }}></div>
									)}
								</Col>
							</Row>
						</div>
						<Card>
							<CardBody>
								{/* tasks list */}
								<table className='dt-list dt-tasks-list'>
									<thead>
										<tr>
											{/* task id */}
											<td style={{ width: '16.66%' }}>{this.props.t('ID')}</td>

											{/* title */}
											<td style={{ width: '16.66%' }}>{this.props.t('Title')}</td>

											{/* creation date */}
											<td style={{ width: '16.66%' }}>{this.props.t('Creation Date')}</td>

											{/* deadline */}
											<td style={{ width: '16.66%' }}>{this.props.t('Deadline')}</td>

											{/* priority */}
											<td style={{ width: '16.66%' }}>{this.props.t('Priority')}</td>

											{/* status */}
											<td style={{ width: '16.66%' }}>{this.props.t('Status')}</td>
										</tr>
									</thead>

									<tbody>
										{!this.state.loadingTasks ? (
											this.state.tasks && this.state.tasks.length > 0 ? (
												this.state.tasks.map((task) => {
													return (
														<tr key={task.id}>
															{/* task id */}
															<td style={{ width: '16.66%' }}>
																{/* <div
																	className='dt-task-id'
																	style={{
																		cursor: !task.deleted_at ? 'pointer' : 'default',
																	}}
																>
																	{task.report_case.secondary_case_id + '-T' + task.task_secondary_id}
																</div> */}
																<Link
																	to={`/employee/tasks/${task.id}`}
																	className={`dt-task-title ${task.deleted_at ? '' : 'hoverable'}`}
																	style={{
																		cursor: !task.deleted_at ? 'pointer' : 'default',
																	}}
																>
																	{task.report_case.secondary_case_id + '/T' + task.task_secondary_id}
																</Link>
															</td>

															{/* title */}
															<td style={{ width: '16.66%' }}>
																<Link
																	to={`/employee/tasks/${task.id}`}
																	className={`dt-task-title ${task.deleted_at ? '' : 'hoverable'}`}
																	style={{
																		cursor: !task.deleted_at ? 'pointer' : 'default',
																	}}
																>
																	{task.name}
																</Link>
															</td>

															{/* creation date */}
															<td style={{ width: '16.66%' }}>
																<div className='dt-task-creation-date'>{this.dateUtils.convertTimeStampToDate(task.created_at, INTERNATIONAL_DATE_FORMAT)}</div>
															</td>

															{/* deadline */}
															<td style={{ width: '16.66%' }}>
																<div className='dt-task-deadline-date'>{this.dateUtils.convertTimeStampToDate(task.ended_at, INTERNATIONAL_DATE_FORMAT)}</div>
															</td>

															{/* priority */}
															<td style={{ width: '16.66%' }}>
																<div className='dt-task-priority'>
																	{task.priority === 'high'.toLowerCase() ? (
																		<HiChevronDoubleUp className='text-danger' />
																	) : task.priority.toLowerCase() === 'medium' ? (
																		<HiMenuAlt4 className='text-success' />
																	) : (
																		<HiChevronDoubleDown className='text-info' />
																	)}
																	<span style={{ marginLeft: '4px' }}>{this.props.t(task.priority)}</span>
																</div>
															</td>

															{/* status */}
															<td style={{ width: '16.66%' }}>
																<div
																	className={
																		'dt-task-status ' +
																		(task.status.toLowerCase() === 'review'
																			? 'bg-info'
																			: task.status.toLowerCase() === 'new'
																			? 'bg-success'
																			: task.status.toLowerCase() === 'in_progress'
																			? 'bg-primary'
																			: task.status.toLowerCase() === 'deleted'
																			? 'bg-danger'
																			: 'bg-secondary')
																	}
																>
																	{this.props.t(task.status.replace('_', ' '))}
																</div>
															</td>
														</tr>
													);
												})
											) : (
												<tr>
													<td colSpan={6}>
														<div className='alert alert-warning' role='alert'>
															<p
																style={{
																	textAlign: 'center',
																	marginBottom: 0,
																	width: '100%',
																}}
															>
																{this.props.t(EMPTY_LIST)}
															</p>
														</div>
													</td>
												</tr>
											)
										) : (
											<>
												<tr>
													{/* task id */}
													<td style={{ width: '16.66%' }}>
														<div>
															<span className='dt-task-id dt-skeleton'></span>
														</div>
													</td>

													{/* title */}
													<td style={{ width: '16.66%' }}>
														<div>
															<span className='dt-task-title dt-skeleton'></span>
														</div>
													</td>

													{/* creation date */}
													<td style={{ width: '16.66%' }}>
														<div>
															<span className='dt-task-creation-date dt-skeleton'></span>
														</div>
													</td>

													{/* deadline */}
													<td style={{ width: '16.66%' }}>
														<div>
															<span className='dt-task-deadline-date dt-skeleton'></span>
														</div>
													</td>

													{/* priority */}
													<td style={{ width: '16.66%' }}>
														<div>
															<span className='dt-task-priority dt-skeleton'></span>
														</div>
													</td>

													{/* status */}
													<td style={{ width: '16.66%' }}>
														<div>
															<span className='dt-task-status dt-skeleton'></span>
														</div>
													</td>
												</tr>
												<tr>
													{/* task id */}
													<td style={{ width: '16.66%' }}>
														<div>
															<span className='dt-task-id dt-skeleton'></span>
														</div>
													</td>

													{/* title */}
													<td style={{ width: '16.66%' }}>
														<div>
															<span className='dt-task-title dt-skeleton'></span>
														</div>
													</td>

													{/* creation date */}
													<td style={{ width: '16.66%' }}>
														<div>
															<span className='dt-task-creation-date dt-skeleton'></span>
														</div>
													</td>

													{/* deadline */}
													<td style={{ width: '16.66%' }}>
														<div>
															<span className='dt-task-deadline-date dt-skeleton'></span>
														</div>
													</td>

													{/* priority */}
													<td style={{ width: '16.66%' }}>
														<div>
															<span className='dt-task-priority dt-skeleton'></span>
														</div>
													</td>

													{/* status */}
													<td style={{ width: '16.66%' }}>
														<div>
															<span className='dt-task-status dt-skeleton'></span>
														</div>
													</td>
												</tr>
												<tr>
													{/* task id */}
													<td style={{ width: '16.66%' }}>
														<div>
															<span className='dt-task-id dt-skeleton'></span>
														</div>
													</td>

													{/* title */}
													<td style={{ width: '16.66%' }}>
														<div>
															<span className='dt-task-title dt-skeleton'></span>
														</div>
													</td>

													{/* creation date */}
													<td style={{ width: '16.66%' }}>
														<div>
															<span className='dt-task-creation-date dt-skeleton'></span>
														</div>
													</td>

													{/* deadline */}
													<td style={{ width: '16.66%' }}>
														<div>
															<span className='dt-task-deadline-date dt-skeleton'></span>
														</div>
													</td>

													{/* priority */}
													<td style={{ width: '16.66%' }}>
														<div>
															<span className='dt-task-priority dt-skeleton'></span>
														</div>
													</td>

													{/* status */}
													<td style={{ width: '16.66%' }}>
														<div>
															<span className='dt-task-status dt-skeleton'></span>
														</div>
													</td>
												</tr>
												<tr>
													{/* task id */}
													<td style={{ width: '16.66%' }}>
														<div>
															<span className='dt-task-id dt-skeleton'></span>
														</div>
													</td>

													{/* title */}
													<td style={{ width: '16.66%' }}>
														<div>
															<span className='dt-task-title dt-skeleton'></span>
														</div>
													</td>

													{/* creation date */}
													<td style={{ width: '16.66%' }}>
														<div>
															<span className='dt-task-creation-date dt-skeleton'></span>
														</div>
													</td>

													{/* deadline */}
													<td style={{ width: '16.66%' }}>
														<div>
															<span className='dt-task-deadline-date dt-skeleton'></span>
														</div>
													</td>

													{/* priority */}
													<td style={{ width: '16.66%' }}>
														<div>
															<span className='dt-task-priority dt-skeleton'></span>
														</div>
													</td>

													{/* status */}
													<td style={{ width: '16.66%' }}>
														<div>
															<span className='dt-task-status dt-skeleton'></span>
														</div>
													</td>
												</tr>
												<tr>
													{/* task id */}
													<td style={{ width: '16.66%' }}>
														<div>
															<span className='dt-task-id dt-skeleton'></span>
														</div>
													</td>

													{/* title */}
													<td style={{ width: '16.66%' }}>
														<div>
															<span className='dt-task-title dt-skeleton'></span>
														</div>
													</td>

													{/* creation date */}
													<td style={{ width: '16.66%' }}>
														<div>
															<span className='dt-task-creation-date dt-skeleton'></span>
														</div>
													</td>

													{/* deadline */}
													<td style={{ width: '16.66%' }}>
														<div>
															<span className='dt-task-deadline-date dt-skeleton'></span>
														</div>
													</td>

													{/* priority */}
													<td style={{ width: '16.66%' }}>
														<div>
															<span className='dt-task-priority dt-skeleton'></span>
														</div>
													</td>

													{/* status */}
													<td style={{ width: '16.66%' }}>
														<div>
															<span className='dt-task-status dt-skeleton'></span>
														</div>
													</td>
												</tr>
											</>
										)}
									</tbody>
								</table>
							</CardBody>
						</Card>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}

const mapStatetoProps = (state) => {
	const { user, token } = state.Login;
	const { App, Organization } = state;
	return { App, user, token, Organization };
};

export default withNamespaces()(withRouter(connect(mapStatetoProps, null)(EmployeeMyTasks)));
