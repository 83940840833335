import React from "react";
import BottyAI from "src/modules/data-protection/assets/images/ai-botty/failed.png";
export default function Failed(props) {
  return (
    <div style={{ textAlign: "center" }}>

      <img
        className="mt-5 mb-3 "
        src={BottyAI}
        width={"50%"}
        height={"50%"}
        alt="Botty"
      />
      <h6 className="my-5">
        {props.t(
          "Our AI cannot receive a response from the source. Please try again."
        )}
      </h6>
    </div>
  );
}
