import { withNamespaces } from "react-i18next";
import { Fragment, memo, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './style.scss';

const Pagination = memo(({ t, totalCount, pageIndex, pageSize, link }) => {
	const [totalPagesCount, setTotalPagesCounts] = useState(0);

	const handleCalculateTotalPages = (pageSize, totalCount) => {
		setTotalPagesCounts(Math.ceil(totalCount / pageSize));
	};

	const handleRenderPrevButton = (pageIndex) => {
		if (pageIndex <= 1) {
			return <div className='custom-pagination-items-button custom-pagination-items-button-disabled'>{t('Prev')}</div>;
		}

		return (
			<Link
				to={{
					pathname: link,
					search: `pageIndex=${pageIndex <= 1 ? pageIndex : pageIndex - 1}&pageSize=${pageSize}`,
				}}
				className='custom-pagination-items-button'
			>
				{t('Prev')}
			</Link>
		);
	};

	const handleRenderNextButton = (pageIndex) => {
		if (pageIndex === totalPagesCount) {
			return <div className='custom-pagination-items-button custom-pagination-items-button-disabled'>{t('Next')}</div>;
		} else {
			return (
				<Link
					to={{
						pathname: link,
						search: `pageIndex=${pageIndex + 1}&pageSize=${pageSize}`,
					}}
					className='custom-pagination-items-button '
				>
					{t('Next')}
				</Link>
			);
		}
	};

	const handleRenderFirstButton = (pageIndex) => {
		if (pageIndex === 1) {
			return <div className='custom-pagination-items-button custom-pagination-items-button-disabled'>{t('First')}</div>;
		} else {
			return (
				<Link
					to={{
						pathname: link,
						search: `pageIndex=${1}&pageSize=${pageSize}`,
					}}
					className='custom-pagination-items-button '
				>
					{t('First')}
				</Link>
			);
		}
	};

	const handleRenderLastButton = (pageIndex) => {
		if (pageIndex === totalPagesCount) {
			return <div className='custom-pagination-items-button custom-pagination-items-button-disabled'>{t('Last')}</div>;
		} else {
			return (
				<Link
					to={{
						pathname: link,
						search: `pageIndex=${totalPagesCount}&pageSize=${pageSize}`,
					}}
					className='custom-pagination-items-button'
				>
					{t('Last')}
				</Link>
			);
		}
	};

	const handleRenderPageButtons = (totalPagesCount, pageIndex) => {
		// if total pages was equal to 6
		if (totalPagesCount <= 6) {
			return Array(totalPagesCount)
				.fill(0, 0)
				.map((_, index) => {
					if (index === pageIndex - 1) {
						return (
							<div className='custom-pagination-items-number-button custom-pagination-items-number-button-active' key={index + 1}>
								{index + 1}
							</div>
						);
					} else {
						return (
							<Link
								to={{
									pathname: link,
									search: `pageIndex=${index + 1}&pageSize=${pageSize}`,
								}}
								className='custom-pagination-items-number-button'
								key={index + 1}
							>
								{index + 1}
							</Link>
						);
					}
				});
		} else {
			// if current page was one of three last pages
			if (pageIndex >= totalPagesCount - 2) {
				return (
					<Fragment>
						<div className='custom-pagination-items-number-button custom-pagination-items-number-button-disabled'>...</div>
						{[totalPagesCount - 2, totalPagesCount - 1, totalPagesCount].map((pageNumber) => {
							if (pageIndex === pageNumber) {
								return (
									<div className='custom-pagination-items-number-button custom-pagination-items-number-button-active' key={pageNumber}>
										{pageNumber}
									</div>
								);
							} else {
								return (
									<Link
										to={{
											pathname: link,
											search: `pageIndex=${pageNumber}&pageSize=${pageSize}`,
										}}
										className='custom-pagination-items-number-button'
										key={pageNumber}
									>
										{pageNumber}
									</Link>
								);
							}
						})}
					</Fragment>
				);
			}
			// other pages
			else {
				return (
					<Fragment>
						{Array(3)
							.fill(0, 0)
							.map((_, index) => {
								const pageNumber = pageIndex + index;
								if (pageNumber <= totalPagesCount) {
									if (pageIndex === pageNumber) {
										return (
											<div className='custom-pagination-items-number-button custom-pagination-items-number-button-active' key={pageNumber}>
												{pageNumber}
											</div>
										);
									} else {
										return (
											<Link
												to={{
													pathname: link,
													search: `pageIndex=${pageNumber}&pageSize=${pageSize}`,
												}}
												className='custom-pagination-items-number-button'
												key={pageNumber}
											>
												{pageNumber}
											</Link>
										);
									}
								}
							})}

						<div className='custom-pagination-items-number-button custom-pagination-items-number-button-disabled'>...</div>
					</Fragment>
				);
			}
		}
	};

	useEffect(() => {
		handleCalculateTotalPages(pageSize, totalCount);
	}, [totalCount, pageSize]);

	return (
		<div className='custom-pagination'>
			<div className='custom-pagination-per-page'>
				<p className='custom-pagination-per-page-text'>{t('Items per page')}: {pageSize}</p>
			</div>
			<div className='custom-pagination-items'>
				{handleRenderFirstButton(pageIndex)}
				{handleRenderPrevButton(pageIndex)}
				{handleRenderPageButtons(totalPagesCount, pageIndex)}
				{handleRenderNextButton(pageIndex)}
				{handleRenderLastButton(pageIndex)}
				{!totalPagesCount ? null : <p className='custom-pagination-items-total-page'>{t('Total pages')}: {totalPagesCount}</p>}
			</div>
		</div>
	);
});

export default withNamespaces()(Pagination);
