import { withRouter, useHistory, Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import React, { useState, useEffect, memo } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Select from "react-select";
import BootstrapTable from "react-bootstrap-table-next";
import overlayFactory from 'react-bootstrap-table2-overlay';
import DateUtils from "src/services/utils/DateUtils";

import { 
    Button, 
    Card, 
    CardBody, 
    CardTitle, 
    Col, 
    Label, 
    Modal, 
    ModalBody, 
    ModalFooter, 
    ModalHeader, 
    Row, 
    Spinner 
} from "reactstrap";

import RiskMatrix from "src/modules/3rd-party-management/components/RisksAndTasks/risks/matrix";

import CreateRiskModal from "src/modules/3rd-party-management/components/RisksAndTasks/risks/new";

import SupplierRiskAssessmentService from "src/modules/3rd-party-management/apis/SupplierRiskAssessmentService";

import OverviewHeader from "../../../../components/overview-header";

import {
    EMPTY_LIST,
    INTERNATIONAL_DATE_FORMAT
} from "src/common/constants";
import { AvForm } from "availity-reactstrap-validation";

const RiskAssessment = (props) => {
    const {
        t,
        supplierId
    } = props;

    const history = useHistory();

    const [ pageIndex, setPageIndex ] = useState(1);
    const [ pageSize, setPageSize ] = useState(12);
    const [ risks, setRisks ] = useState([]);
    const [ matrix, setMatrix ] = useState([]);
    const [ consequences, setConsequences ] = useState(null);
    const [ ratings, setRatings ] = useState([]);
    const [ likelihoods, setLikelihoods ] = useState(null);
    const [ currency, setCurrency ] = useState(null);
    const [ damages, setDamages ] = useState(null);
    const [ owners, setOwners ] = useState(null);
    const [ managers, setManagers ] = useState(null);
    const [ tableColors, setTableColors ] = useState(null);
    const [ categories, setCategories]= useState([]);
    const [ locations, setLocations ] = useState([]);
    const [ deleteRiskId, setDeleteRiskId ] = useState(false);

    const [ createRiskModalStatus, setCreateRiskModalStatus ] = useState(false);
    const [ deleteRiskModalStatus, setDeleteRiskModalStatus ] = useState(false);

    const [ searchFilters, setSearchFilters ] = useState({
        rating  :   null,
        owner   :   null,
        manager :   null
    });

    const dateUtils = new DateUtils();

    const RisksTableColumns = [
        {
            dataField :   "rating",
            text      :   t("Rating"),
            sort      :   true,
            key       :   1,
            style     :   {
                width : '80px'
            },
            formatter : (_cellContent, {
                result_color,
                is_deleted,
                id
            }) => {
                return (
                    <div>
                        <span className="dt-risk-rating-bullet"
                            style={{
                                backgroundColor: `#${result_color}`,
                                opacity: is_deleted ? 0.15 : 1,
                                cursor: !is_deleted
                                    ? "pointer"
                                    : "default",
                            }}
                            onClick={() => !is_deleted && riskSelectedToShow(id) } 
                        />
                    </div>
                )
            }
        },
        {
            dataField :   "id",
            text      :   t("Risk ID"),
            sort      :   true,
            key       :   2,
            style     :   {
                width : '80px'
            },
            formatter : (_cellContent, {
                is_deleted,
                secondary_id,
                id
            }) => {
                return (
                    <div className="dt-list-col-result-id"
                        style={{
                            cursor: !is_deleted
                            ? "pointer"
                            : "default",
                        }}
                        onClick={() => !is_deleted && riskSelectedToShow(id) }
                    >
                        {"R" + secondary_id}
                    </div>
                )
            }
        },
        {
            dataField :   "category",
            text      :   t("Category"),
            sort      :   true,
            key       :   3,
            style     :   {
                width : '120px'
            },
            formatter : (cellContent) => {
                if(cellContent && cellContent.parent) {
                    return (
                        <div>
                            <span className="dt-list-col-result-description">
                                {cellContent.parent.name}
                            </span>
                        </div>
                    )
                }

                return null
            }
        },
        {
            dataField :   "sub_category",
            text      :   t("Sub Category"),
            sort      :   false,
            key       :   4,
            style     :   {
                width : '120px'
            },
            formatter : (_cellContent, {
                category
            }) => {
                if(category){
                    return (
                        <div>
                            <span className="dt-list-col-result-description">
                                {category.name}
                            </span>
                        </div>
                    )
                }

                return null
            }
        },
        {
            dataField :   "description",
            text      :   t("Description"),
            sort      :   false,
            key       :   5,
            style     :   {
                width : '180px'
            },
            formatter : (cellContent, {
                id,
                is_deleted
            }) => {
                return (
                    <div className="dt-list-col-result-description"
                            style={{
                                cursor: !is_deleted
                                ? "pointer"
                                : "default",
                            }}
                            onClick={() => !is_deleted && riskSelectedToShow(id) }
                        >
                        { decodeHTMLEntities(cellContent).replace(/<[^>]+>/g, "") }
                    </div>
                );
            }
        },
        {
            dataField :   "owner_name",
            text      :   t("Risk owner"),
            sort      :   true,
            key       :   6,
            style     :   {
                width : '180px'
            },
            formatter : (cellContent) => {
                if(cellContent){
                    return (
                        <div>
                            <span className='dt-list-col-bullet'>{cellContent[0]}</span>{' '}
                            <span className='dt-list-col-bullet-text'>{cellContent}</span>
                        </div>
                    )
                }

                return null
            }
        },
        {
            dataField :   "manager",
            text      :   t("Risk Manager"),
            sort      :   true,
            key       :   7,
            style     :   {
                width : '180px'
            },
            formatter : (cellContent) => {
                return (
                    <>
                        <span className="dt-list-col-bullet">
                            {cellContent[0]}
                        </span>{" "}
                        <span className="dt-list-col-bullet-text">
                            {cellContent}
                        </span>
                    </>
                )
            }
        },
        {
            dataField :   "tasks",
            text      :   t("Mitigation"),
            sort      :   false,
            key       :   8,
            style     :   {
                width : '100px'
            },
            formatter : (cellContent) => {
                if(cellContent && cellContent.length > 0){
                    return cellContent.map((task, i) => {
                        return (
                            <Link to={`/admin/3rd-party/suppliers/${supplierId}/tasks/${task.id}/details`}>
                                {`T${task.secondary_id}`}
                                <span hidden={i === cellContent.length - 1}>{', '}</span>
                            </Link>
                        );
                    })
                }

                return null
            }
        },
        {
            dataField :   "created_at",
            text      :   t("Creation Date"),
            sort      :   false,
            key       :   9,
            style     :   {
                width : '120px'
            },
            formatter : (cellContent) => {
                return (
                    <div>
                        {cellContent ? dateUtils.convertTimeStampToDate(cellContent, INTERNATIONAL_DATE_FORMAT) : null}
                    </div>
                )
            }
        },
        {
            dataField :   "actions",
            text      :   t("Action"),
            sort      :   false,
            key       :   10,
            style     :   {
                width : '120px'
            },
            formatter : (_cellContent, row) => {
                if(!row.deleted_at){
                    return (
                        <div className="actions">
                            <button onClick={() => {
                                setDeleteRiskId(row.id);
                                setDeleteRiskModalStatus(true);
                            }} className="btn btn-small text-danger">
                               <i className='ri-delete-bin-line text-danger'></i>
                            </button>
                        </div>
                    )
                }
                else{
                    return (
                        <div className="text-danger">
                            {t('Deleted')}
                        </div>
                    )
                }
            }
        }
    ];

    const handleFetchSupplierRisksQuery = useQuery({
		queryKey: ['3rd-party-management-fetch-supplier-risks', pageIndex, pageSize],
		queryFn: async () => {
			const service = SupplierRiskAssessmentService.getInstance();

            let filtersToSend = {}

            for(const filterKey in searchFilters){
                if(searchFilters[filterKey]){
                    filtersToSend[filterKey] = searchFilters[filterKey]?.value;
                }
            }

            return await service.fetchRisks(supplierId, {
                ...filtersToSend,
                pageIndex   :   pageIndex,
                pageSize    :   pageSize
            });
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			toast(t('An error occurred while fetching supplier risks.'), {
				type: 'error',
			});
		},
	});

    const handleFetchSupplierRisksMatrix = useQuery({
		queryKey: ['3rd-party-management-fetch-supplier-risks-matrix', supplierId],
		queryFn: async () => {
			const service = SupplierRiskAssessmentService.getInstance();

            return await service.fetchMatrix(supplierId);
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			toast(t('An error occurred while fetching matrix.'), {
				type: 'error',
			});
		},
	});

    const handleFetchSupplierRisksOwners = useQuery({
		queryKey: ['3rd-party-management-fetch-supplier-risks-owners'],
		queryFn: async () => {
			const service = SupplierRiskAssessmentService.getInstance();

            return await service.fetchOwners(supplierId);
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			toast(t('An error occurred while fetching owners.'), {
				type: 'error',
			});
		},
	});

    const handleFetchSupplierRisksManagers = useQuery({
		queryKey: ['3rd-party-management-fetch-supplier-risks-managers'],
		queryFn: async () => {
			const service = SupplierRiskAssessmentService.getInstance();

            return await service.fetchManagers(supplierId);
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			toast(t('An error occurred while fetching managers.'), {
				type: 'error',
			});
		},
	});

    const handleFetchSupplierRisksCategories = useQuery({
		queryKey: ['3rd-party-management-fetch-supplier-risks-categories'],
		queryFn: async () => {
			const service = SupplierRiskAssessmentService.getInstance();

            return await service.fetchCategories(supplierId);
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			toast(t('An error occurred while fetching categories.'), {
				type: 'error',
			});
		},
	});

    const handleFetchSupplierRisksLocations = useQuery({
		queryKey: ['3rd-party-management-fetch-supplier-risks-locations'],
		queryFn: async () => {
			const service = SupplierRiskAssessmentService.getInstance();

            return await service.fetchLocations(supplierId);
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			toast(t('An error occurred while fetching locations.'), {
				type: 'error',
			});
		},
	});

    const handleDeleteSupplierRiskMutation = useMutation({
        mutationFn: async (riskId) => {
            const service = SupplierRiskAssessmentService.getInstance();
    
            return await service.deleteRisk(supplierId, riskId);
        },
        onSuccess: () => {
            setDeleteRiskModalStatus(false);
            handleFetchSupplierRisksQuery.refetch();
            handleFetchSupplierRisksMatrix.refetch();

            toast(t("Risk deleted successfully."), {
                type: "success",
            });
        },
        onError: () => {
            toast(t("Failed to delete risk."), {
                type: "error",
            });
        }
    });

    const NoDataIndication = () => (
        (handleFetchSupplierRisksQuery.isFetched && !risks.length) ? 
            (
                <div className="alert m-0" role="alert">
                    <p style={{
                            textAlign: "center",
                            marginBottom: 0
                        }}>
                        {props.t(EMPTY_LIST)}
                    </p>
                </div> 
            ) : 
            <></>
    );

    useEffect(() => {
        if(handleFetchSupplierRisksQuery.data){
            setRisks(handleFetchSupplierRisksQuery.data.risks)
        }
    }, [handleFetchSupplierRisksQuery.data]);

    useEffect(() => {
        if(handleFetchSupplierRisksMatrix.data){
            setMatrix(handleFetchSupplierRisksMatrix.data.matrix);
            setConsequences(handleFetchSupplierRisksMatrix.data.consequences);
            setRatings(handleFetchSupplierRisksMatrix.data.ratings);
            setLikelihoods(handleFetchSupplierRisksMatrix.data.likelihoods);
            setCurrency(handleFetchSupplierRisksMatrix.data.currency);
            setDamages(
                handleFetchSupplierRisksMatrix.data.consequences.map((item) => {
                    return {
                        value: item.id,
                        label:
                        handleFetchSupplierRisksMatrix.data.currency +
                        " " +
                        Number(item.title).toLocaleString(
                            localStorage.getItem("i18nextLng")
                        ),
                    };
                })
            );

            // calculating matrix colors for caption
            if (handleFetchSupplierRisksMatrix.data.matrix && handleFetchSupplierRisksMatrix.data.ratings) {
                const flattedMatrix = handleFetchSupplierRisksMatrix.data.matrix.flat();
                const items = [];

                for (const item of handleFetchSupplierRisksMatrix.data.ratings) {
                    items.push({
                        label: item.name,
                        color: flattedMatrix.find((i) => i.name === item.name).color,
                    });
                }

                setTableColors(items);
            }
        }
    }, [handleFetchSupplierRisksMatrix.data]);

    useEffect(() => {
        if(handleFetchSupplierRisksOwners.data){
            setOwners(handleFetchSupplierRisksOwners.data.map((item) => {
                return {
                    value: item.id,
                    label: `${item.first_name} ${item.last_name}`,
                };
            }));
        }
    }, [handleFetchSupplierRisksOwners.data]);

    useEffect(() => {
        if(handleFetchSupplierRisksOwners.data){
            setOwners(handleFetchSupplierRisksOwners.data.map((item) => {
                return {
                    value: item.id,
                    label: `${item.first_name} ${item.last_name}`,
                };
            }));
        }
    }, [handleFetchSupplierRisksOwners.data]);

    useEffect(() => {
        if(handleFetchSupplierRisksManagers.data){
            setManagers(handleFetchSupplierRisksManagers.data.map((item) => {
                return {
                    value: item.id,
                    label: item.full_name,
                };
            }));
        }
    }, [handleFetchSupplierRisksManagers.data]);

    useEffect(() => {
        if(handleFetchSupplierRisksCategories.data){
            setCategories(handleFetchSupplierRisksCategories.data.map((item) => {
                return {
                    value: item.id,
                    label: t(item.name),
                    baseLabel: item.name,
                    subCategories: item.sub_categories.map((sub) => {
                        return {
                            value: sub.id,
                            baseLabel: sub.name,
                            label: t(sub.name),
                        };
                    }),
                };
            }));
        }
    }, [handleFetchSupplierRisksCategories.data]);

    useEffect(() => {
        if(handleFetchSupplierRisksLocations.data){
            setLocations(handleFetchSupplierRisksLocations.data.map((item) => {
                return {
                    value: item.id,
                    baseLabel: item.name,
                    label: t(item.name),
                };
            }));
        }
    }, [handleFetchSupplierRisksLocations.data]);

    useEffect(() => {
        handleFetchSupplierRisksQuery.refetch();
    }, [searchFilters]);

    const isLoading = ((handleFetchSupplierRisksMatrix.isFetching || handleFetchSupplierRisksMatrix.isLoading) ||
            (handleFetchSupplierRisksOwners.isFetching || handleFetchSupplierRisksOwners.isLoading) ||
            (handleFetchSupplierRisksManagers.isFetching || handleFetchSupplierRisksManagers.isLoading) || 
            (handleFetchSupplierRisksQuery.isFetching || handleFetchSupplierRisksQuery.isLoading) || !props.user);

    const riskSelectedToShow = (id) => {
        history.push(`/admin/3rd-party/suppliers/${supplierId}/risks/${id}/details`);
    }

    const decodeHTMLEntities = (text) => {
        const parser = new DOMParser();
        const decodedText = parser.parseFromString(
            `<!doctype html><body>${text}`,
            "text/html"
        ).body.textContent;
        return decodedText;
    };

    const renderRiskMatrix = () => {
        return (
            <Card className="mb-4">
                <CardBody>
                    <CardTitle className="mb-4">
                        {t('Risk Matrix')}
                    </CardTitle>

                    <RiskMatrix
                        matrix={matrix}
                        consequences={consequences}
                        currency={currency}
                        likelihoods={likelihoods}
                        risks={null}
                        ratings={ratings}
                        showSupplierId={false}
                        isLoading={ handleFetchSupplierRisksMatrix.isFetching || handleFetchSupplierRisksMatrix.isLoading }
                    />
                </CardBody>
            </Card>
        );
    }

    const renderModals = () => {
        const closeDeleteModal = () => {
            setDeleteRiskId(null);
            setDeleteRiskModalStatus(false);
        }

        return (
            <React.Fragment>
                <CreateRiskModal
                    isOpen          =   { createRiskModalStatus }
                    close           =   { () => setCreateRiskModalStatus(false) }
                    currency        =   { currency }
                    categories      =   { categories }
                    locations       =   { locations }
                    damages         =   { damages }
                    likelihoods     =   {
                        likelihoods ? likelihoods.map((l) => {
                            return {
                                value: l.id,
                                label: l.title,
                            };
                        }) : null
                    }
                    owners          =   { owners }
                    creationRequestPayload = {{
                        supplier    :   supplierId
                    }}
                    onRiskCreationSuccess   =   {() => {
                        handleFetchSupplierRisksQuery.refetch();
                        handleFetchSupplierRisksMatrix.refetch();
                    }}
                />
            
                <Modal size='lg' scrollable={true} isOpen={deleteRiskModalStatus} backdrop='static'>

                    <ModalHeader toggle={closeDeleteModal}>
                        {t('Delete Risk')}
                    </ModalHeader>

                    <ModalBody>
                        <AvForm className='needs-validation'>
                            <Row>
                                <Label>
                                    {t('Are you sure?')}
                                </Label>
                            </Row>

                            <ModalFooter>
                                <Button
                                    color='danger'
                                    className='waves-effect waves-light'
                                    type='submit'
                                    onClick={() => {
                                        handleDeleteSupplierRiskMutation.mutate(deleteRiskId);
                                    }}>
                                        {t('Delete')}
                                </Button>

                                <Button color='secondary'
                                    className='waves-effect waves-light'
                                    type='button'
                                    onClick={closeDeleteModal}>
                                        {t('Cancel')}
                                </Button>
                            </ModalFooter>
                        </AvForm>
                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }

    const renderFilters = () => {
        return (
            <Row className="d-flex">
                {/* ratings */}
                <Col sm="12" md="3" lg="2" className="mb-2">
                    {!isLoading ? (
                        <Select
                            classNamePrefix="select2-selection"
                            options={ratings.map((r) => {
                                return {
                                    value: r.name,
                                    label: t(r.name),
                                };
                            })}
                            value={searchFilters.rating}
                            isClearable={true}
                            onChange={(e) => {
                                setSearchFilters((filters) => {
                                    return {
                                        ...filters,
                                        rating  :   e
                                    }
                                })
                            }}
                            placeholder={t("Risk rating")}
                        />
                    ) : (
                        <div className="dt-field dt-skeleton dt-select-list" style={{ marginBottom: 16 }}></div>
                    )}
                </Col>

                {/* owners */}
                <Col sm="12" md="3" lg="3" className="mb-2 ">
                    {!isLoading ? (
                        <Select
                            classNamePrefix="select2-selection"
                            options={owners}
                            value={searchFilters.owner}
                            isClearable={true}
                            onChange={(e) => {
                                setSearchFilters((filters) => {
                                    return {
                                        ...filters,
                                        owner  :   e
                                    }
                                })
                            }}
                            placeholder={t("Risk owner")}
                        />
                    ) : (
                        <div className="dt-field dt-skeleton dt-select-list" style={{ marginBottom: 16 }}></div>
                    )}
                </Col>

                {/* managers */}
                <Col sm="12" md="3" lg="3" className="mb-2">
                    {!isLoading ? (
                        <Select
                            classNamePrefix="select2-selection"
                            options={managers}
                            value={searchFilters.manager}
                            isClearable={true}
                            onChange={(e) => {
                                setSearchFilters((filters) => {
                                    return {
                                        ...filters,
                                        manager  :   e
                                    }
                                })
                            }}
                            placeholder={t("Risk Manager")}
                        />
                ) : (
                    <div className="dt-field dt-skeleton dt-select-list" style={{ marginBottom: 16 }}></div>
                )}
                </Col>

                <Col sm="12" md="6" lg="2">
                    <Button color="primary"
                        className="mb-2"
                        style={{
                            marginLeft: "auto",
                            marginRight: 0,
                            display: "block",
                            width: "100%",
                        }}
                        onClick={() => {
                            setCreateRiskModalStatus(true);
                        }}
                        outline
                        >
                        {t("Create Risk")}
                    </Button>
                </Col>
            </Row>
        );
    }

    const renderRisksTable = () => {
        return (
            <Row>
                <Col sm='12'>
                    <BootstrapTable
                        loading={ isLoading }
                        overlay={ 
                            overlayFactory({ 
                                spinner: <Spinner animation="border" variant="primary" size="md"/>, 
                                text : `${props.t("Loading")}...`
                            }) 
                        }
                        keyField={"id"}
                        responsive
                        bordered={ false }
                        data={ risks }
                        striped={true}
                        columns={ RisksTableColumns }
                        wrapperClasses="table-responsive"
                        classes={"table tpdd-table"}
                        headerWrapperClasses={"thead-light"}
                        style={{
                            overflowX: "auto",
                        }}
                        noDataIndication={ () => <NoDataIndication /> }
                    />
                </Col>
            </Row>
        );
    }

    return (
        <div className="p-4 d-flex flex-column gap-4">
            { renderModals() }
            <Row>
                <Col sm="12">
                    <OverviewHeader supplierId={supplierId} />
                </Col>

                <Col sm="12">
                    { renderRiskMatrix() }
                </Col>

                <Col sm="12" className="mt-4 mb-4">
                    { renderFilters() }

                    { renderRisksTable() }              
                </Col>
            </Row>  
        </div> 
    );
};

const mapStatetoProps = (state) => {
    const { token } = state.Login;
    const { Organization } = state;
    return {
        user : state.Login.user,
        token,
        organization : Organization
    };
};

export default withNamespaces()(
    withRouter(connect(
        mapStatetoProps,
        {}
    )(memo(RiskAssessment)))
);