import React from 'react';
import { Row, Col, Form } from "react-bootstrap";
import Select from 'react-select';
import Accordion from 'react-bootstrap/Accordion';

const DataSubjectRequests = ({ t, routerSettings, selectedEntity,analystAdminsList, loading, isAdmin }) => {

  const handleSelectChange = (field) => (selectedOption) => {
    selectedEntity(prevAssignments => ({
      ...prevAssignments,
      [field]: selectedOption
    }));
  };

  return (
    <Accordion defaultActiveKey="" className="accordionBody">
      <Accordion.Item eventKey="4">
        <Accordion.Header>{t("Data Subject Requests")}</Accordion.Header>
        <Accordion.Body>
        {loading ?    <div
            className="dt-field dt-skeleton"
            style={{ width: "100%", height: "100px" }}
          ></div>
          :
          <Row className="mb-3">
            <Col sm="6">
              <Form.Label>{t("Assign assets to")}</Form.Label>
              <Select
                options={analystAdminsList}
                value={routerSettings?.defaultDsrOwner}
                onChange={handleSelectChange('defaultDsrOwner')}
                placeholder={t("Select...")}
                isDisabled={!isAdmin}
              />
            </Col>
            <Col sm="6">
              <Form.Label>{t("Assign tasks to")}</Form.Label>
              <Select
                options={analystAdminsList}
                value={routerSettings.defaultDsrTaskOwner}
                onChange={handleSelectChange('defaultDsrTaskOwner')}
                placeholder={t("Select...")}
                isDisabled={!isAdmin}
              />
            </Col>
          </Row>}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default DataSubjectRequests;
