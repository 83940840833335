import React, { memo, useEffect, useState } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { Button, Spinner, Label, Input, Row, Col } from "reactstrap";
import NotificationSettingsService from "./service";
import { NotificationSections } from "./data";
import { withNamespaces } from "react-i18next";

const NotificationSettings = (props) => {
  const { module, t } = props;
  const [notificationsOptions, setNotificationsOptions] = useState({});
  const [enabledKeys, setEnabledKeys] = useState([]);

  const {
    data: fetchedNotifications = {},
    isFetching: isLoading,
    refetch: refetchNotifications,
  } = useQuery({
    queryKey: [`${module}-fetch-notification-settings`],
    queryFn: async () => {
      const service = NotificationSettingsService.getInstance();
      return await service.notificationSettings(module);
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: () => {
      toast(t("An error occurred while fetching notification settings."), {
        type: "error",
      });
    },
  });

  const { mutate: updateNotificationSettings, isLoading: isUpdating } =
    useMutation({
      mutationFn: async (payload) => {
        const service = NotificationSettingsService.getInstance();
        return await service.toggleNotif(module, payload);
      },
      onSuccess: () => {
        toast(t("Notification settings updated successfully."), {
          type: "success",
        });
      },
      onError: () => {
        toast(t("An error occurred while updating notification settings."), {
          type: "error",
        });
      },
    });

  const restoreNotificationMutation = useMutation({
    mutationFn: async (payload) => {
      const service = NotificationSettingsService.getInstance();
      return await service.restoreNotif(payload);
    },
    onSuccess: () => {
      toast(t("Settings successfully updated."), {
        type: "success",
      });
      refetchNotifications();
    },
    onError: () => {
      toast(t("An error occurred while restoring settings."), {
        type: "error",
      });
    },
  });

  const restoreNotifications = () => {
    restoreNotificationMutation.mutate(module);
  };

  useEffect(() => {
    if (fetchedNotifications) {
      setNotificationsOptions(fetchedNotifications);
      const initialEnabledKeys = [];

      Object.keys(fetchedNotifications).forEach((category) => {
        fetchedNotifications[category].forEach((option) => {
          if (option.active) initialEnabledKeys.push(option.value);
        });
      });

      setEnabledKeys(initialEnabledKeys);
    }
  }, [fetchedNotifications]);

  const handleToggleNotification = (category, key, index) => {
    setEnabledKeys((prevKeys) =>
      prevKeys.includes(key)
        ? prevKeys.filter((item) => item !== key)
        : [...prevKeys, key]
    );

    setNotificationsOptions((prevOptions) => {
      const newOptions = { ...prevOptions };
      const categoryOptions = [...newOptions[category]];

      categoryOptions[index] = {
        ...categoryOptions[index],
        active: !categoryOptions[index].active,
      };

      newOptions[category] = categoryOptions;

      return newOptions;
    });
  };

  const handleSave = () => {
    updateNotificationSettings({ enableNotifications: enabledKeys });
  };

  return (
    <div className="notifications-settings">
      <h4 className="settings-title mb-3">{t("Notifications Setting")}</h4>
      {isLoading ? (
        <Spinner animation="border" />
      ) : (
        Object.keys(notificationsOptions).map((category) => {
          return (
            <div className="notif-card" key={category}>
              <h6>{t(NotificationSections(module)[category])}</h6>
              {notificationsOptions[category].map((option, index) => {
                const { value, active } = option;
                return (
                  <div key={value} className="form-check form-switch mb-3">
                    <Input
                      type="checkbox"
                      checked={active}
                      onChange={() =>
                        handleToggleNotification(category, value, index)
                      }
                    />
                    <Label>
                      {t(notificationsOptions[category][index].label)}
                    </Label>
                  </div>
                );
              })}
            </div>
          );
        })
      )}

      <Row>
        <Col sm="12" className="d-flex justify-content-end">
          <Button
            onClick={restoreNotifications}
            color="primary"
            type="button"
            outline
            className="me-2"
            disabled={isUpdating || restoreNotificationMutation.isLoading}
          >
            {t("Restore")}
          </Button>

          <Button color="primary" onClick={handleSave} disabled={isUpdating}>
            {isUpdating ? (
              <Spinner
                animation="border"
                variant="danger"
                size="sm"
                className="me-1"
              />
            ) : (
              t("Save")
            )}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default withNamespaces()(memo(NotificationSettings));
