import { withNamespaces } from "react-i18next"

const OverviewHeader = ({
    t,
    items
}) => {
    return (
        <div className="report-details-box">
            {
                items.map((cols, index) => {
                    return (
                        <div className="report-details-row" key={ index }>
                            {
                                cols.map((col, colIndex) => {
                                    return (
                                        <div className="report-details-col" key={colIndex}>
                                            <span className="detail-title">
                                                { t(col.title) }
                                            </span>
                                            <span className="detail-value">
                                                { col.value }
                                            </span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    )
                })
            }
        </div>
    );
};

export default withNamespaces()(OverviewHeader);