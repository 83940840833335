import React, { Component } from "react";
import { Container, Row, Col, Card, Label, Button, CardBody } from "reactstrap";
import Dropzone from "react-dropzone";

import { connect } from "react-redux";
import { withRouter,  } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { AvForm,  } from "availity-reactstrap-validation";


import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { onEditInfoOrganizationSuccess } from "../../../store/organization/actions";

import { successNotification } from "../../../store/actions";


class PrivacyPolicy extends Component {
	constructor(props) {
		super(props);
		this.state = {
			phone: null,
			breadcrumbItems: [
				{ title: "SIP", link: "/" },
				{ title: this.props.t("Privacy Policy"), link: "#" },
			],
			editorState: null,
			selectedFiles: [],
			uploadedFiles: [],
		};

		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this);
		this.onEditorStateChange = this.onEditorStateChange.bind(this);
		this.handleEditInfoOrganizationSuccess = this.props.handleEditInfoOrganizationSuccess.bind(this);
		this.successNotification = this.props.successNotification.bind(this);
	}

	handleAcceptedFiles = (files) => {
		files.map((file) => {
			Object.assign(file, {
				preview: URL.createObjectURL(file),
				formattedSize: this.formatBytes(file.size),
			});
		});
		this.setState({ selectedFiles: files });
	};

	onEditorStateChange(editorState) {
		this.setState({
			editorState,
		});
	}

	formatBytes = (bytes, decimals = 2) => {
		if (bytes === 0) return "0 Bytes";
		const k = 1024;
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

		const i = Math.floor(Math.log(bytes) / Math.log(k));
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
	};

	handleSubmit(e, values) {
	}

	render() {
		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						<Breadcrumbs title={this.props.t("Privacy Policy")} breadcrumbItems={this.state.breadcrumbItems} />
						<Row>
							<Col xl="12">
								<AvForm className="needs-validation" onValidSubmit={this.handleSubmit}>
									<Card>
										<CardBody>
											<div className="mb-3">
												<Label className="form-label">{`${this.props.t("Attachments")} ${this.props.t("(Optional)")}`}</Label>
												<Dropzone onDrop={(acceptedFiles) => this.handleAcceptedFiles(acceptedFiles)}>
													{({ getRootProps, getInputProps }) => (
														<div className="dropzone">
															<div className="dz-message needsclick" {...getRootProps()}>
																<input {...getInputProps()} />
																<div className="mb-3">
																	<i className="display-4 text-muted ri-upload-cloud-2-line"></i>
																</div>
																<h4>{this.props.t("Drop files here or click to upload")}</h4>
															</div>
														</div>
													)}
												</Dropzone>
											</div>
                                            <div>
												<Button color="primary" type="submit">
													{this.props.t("Upload")}
												</Button>
											</div>
										</CardBody>
									</Card>
								</AvForm>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}

	componentDidUpdate(prevProps) {}

	componentDidMount() {}
}

const mapStatetoProps = (state) => {
	const { token } = state.Login;
	const organization = state.Organization;
	const App = state.App;
	return { organization, token, App };
};

const mapDispachToProps = (dispach) => {
	return {
		successNotification: (message) => dispach(successNotification(message)),
		handleEditInfoOrganizationSuccess: (val) => dispach(onEditInfoOrganizationSuccess(val)),
	};
};

export default withNamespaces()(withRouter(connect(mapStatetoProps, mapDispachToProps)(PrivacyPolicy)));
