import { memo } from 'react';
import CustomTableHeader from './CustomTableHeader';
import CustomTableRow from './CustomTableRow';
import { uniqueKey } from './utils';
import './style.scss';

const CustomTable = (props) => {
	const { headerData = [], data = [] } = props;

	return (
		<div className='custom-table'>
			{/* table header */}
			{headerData.length > 0
				? headerData.map((row, index) => {
						return <CustomTableHeader key={uniqueKey(row[index].value)} rowData={row} />;
				  })
				: null}

			{/* table body */}
			{data.map((row, index) => {
				return <CustomTableRow key={index} rowData={row} />;
			})}
		</div>
	);
};

export default memo(CustomTable);
