import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import { withNamespaces } from "react-i18next";
import { Card, CardBody, CardTitle } from "reactstrap";

class Channels extends Component {
	constructor(props) {
		super(props);

		this.state = {
			series: [],
			options: {},
		};
	}

	render() {
		return (
			<>
				<Card>
					<CardBody>
						<div className="mb-3">
							<CardTitle>{this.props.t("Channels")}</CardTitle>
						</div>
						<div className="dissco--custom-radial-chart">
							<ReactApexChart options={this.state.options} series={this.state.series} type="radialBar" height="320" />
						</div>
					</CardBody>
				</Card>
			</>
		);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.data !== this.props.data || prevProps.t !== this.props.t) {
			const data = this.props.data;
			const series = data.map((item) => {
				return parseInt(item.percent);
			});
			const labels = data.map((item) => this.props.t(item.title));
			// console.log(labels);
			this.setState({
				series: series,
				options: {
					...this.state.options,
					labels: [],
					colors: ["#5664d2", "#9400D3", "#00008B", "#f1b44c", "#1ab7ea", "#0084ff", "#39539E", "#0077B5"],

					dataLabels: {
						enabled: false,
						position: "center",
						formatter: function (val) {
							return val + "%";
						},
						style: {
							fontSize: "12px",
						},
					},

					plotOptions: {
						radialBar: {
							offsetY: 0,
							startAngle: 0,
							endAngle: 270,
							hollow: {
								margin: 5,
								size: "30%",
								background: "transparent",
								image: undefined,
							},
							dataLabels: {
								name: {
									show: true,
								},
								value: {
									show: true,
								},
							},
						},
					},

					legend: {
						show: true,
						floating: true,
						fontSize: "16px",
						position: "left",
						offsetX: 160,
						offsetY: 15,
						labels: {
							useSeriesColors: true,
						},
						markers: {
							size: 0,
						},
						formatter: function (seriesName, opts) {
							return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex];
						},
						itemMargin: {
							vertical: 3,
						},
					},
					responsive: [
						{
							breakpoint: 480,
							options: {
								legend: {
									show: false,
								},
							},
						},
					],
					labels: labels,
				},
			});
		}
	}
}

// https://apexcharts.com/javascript-chart-demos/radialbar-charts/custom-angle-circle/

export default withNamespaces()(Channels);
