import React, { Component } from "react";
import { Card, Row, Col, CardBody, Container, Button } from "reactstrap";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import axios from "axios";
import {
  API_URL_GET_E_LEARNING_VIDEOS,
  API_URL_GET_E_LEARNING_LESSONS,
} from "../../common/constants";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { Link } from "react-router-dom";

import UserUtils from "../../services/utils/UserUtils";
import { getCurrentLanguage } from "../../i18n";

class ElearningHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "SIP", link: "#" },
        { title: this.props.t("E-Learning"), link: "#" },
      ],
      list: [],
      userRole: "",
      lang: getCurrentLanguage(),
    };

    this.fetchLessons = this.fetchLessons.bind(this);
    this.videoPlayEndPoint = process.env.REACT_APP_CORE_API_ENDPOINT;
  }

  defaultSorted = [];

  seeDetails = (cell, row) => {
    let id = row.lesson_id;
    let name = row.lesson_name;
    const { userRole } = this.state;
    // const userRole = "admin"; // It is temprory, when the bug fixex, it should be changed.(componentDidMount)

    return (
      <Link
        to={{
          pathname: `/${userRole}/e-learning/${id}/detail`,
          lesson_id: id,
          lesson_name: name,
        }}
        onClick={(e) => {
          console.log(e);
        }}
      >
        <Button
          type="button"
          color="btn btn-primary"
          className="btn-rounded mb-2 me-2"
        >
          {this.props.t("Play")}
        </Button>
      </Link>
    );
  };

  render() {
    const pageOptions = {
      sizePerPage: 25,
      totalSize: this.state.list.length,
      custom: true,
    };
    const columns = [
      {
        dataField: "lesson_name",
        text: this.props.t("Lesson name"),
        sort: true,
      },
      {
        dataField: "language",
        text: this.props.t("Language"),
        sort: true,
      },
      {
        dataField: "see_details",
        text: this.props.t("See details"),
        formatter: this.seeDetails,
        sort: false,
      },
    ];
    const { defaultSorted } = this;
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.props.t("E-Learning")}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="lesson_id"
                      columns={columns}
                      data={this.state.list}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="lesson_id"
                          columns={columns}
                          data={this.state.list}
                          search
                        >
                          {(toolkitProps) => (
                            <React.Fragment>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"lesson_id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />
                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }

  fetchVideos() {
    axios
      .get(API_URL_GET_E_LEARNING_VIDEOS, {
        headers: {
          Authorization: `Bearer ${this.props.token}`,
        },
      })
      .then((res) => {
        const list = res.data.data;
        if (Array.isArray(res.data.data)) {
          this.setState({
            list: list,
          });
        }
      });
  }

  fetchLessons() {
    if (this.props.token) {
      axios
        .post(
          API_URL_GET_E_LEARNING_LESSONS,
          { module: localStorage.getItem("module") },
          {
            headers: {
              Authorization: `Bearer ${this.props.token}`,
            },
          }
        )
        .then((res) => {
          const list = res.data.message;

          if (list.length) {
            this.setState({
              list,
            });
          }
        })
        .catch((err) => {
          console.log("fetchLessons error: ", err);
        });
    } else {
      console.log("No token avalibe!");
    }
  }

  componentDidUpdate(prevProps) {
    if (getCurrentLanguage() !== this.state.lang) {
      const userUtils = new UserUtils();
      const { user } = this.props;
      // console.log(this.props.user);

      if (userUtils.isAnalystAdmin(user)) {
        this.setState({ userRole: "admin" });
      } else if (userUtils.isEmployee(user)) {
        this.setState({ userRole: "employee" });
      } else if (userUtils.isThirdParty(user)) {
        this.setState({ userRole: "third-party" });
      }

      // this.fetchLessons();
      this.setState({ lang: getCurrentLanguage() });
    }

    if (prevProps.t !== this.props.t) {
      this.fetchLessons();
    }
  }

  componentDidMount() {
    const { user } = this.props;
    const userUtils = new UserUtils();
    // console.log(this.props.user);

    if (userUtils.isAnalystAdmin(user)) {
      this.setState({ userRole: "admin" });
    } else if (userUtils.isEmployee(user)) {
      this.setState({ userRole: "employee" });
    } else if (userUtils.isThirdParty(user)) {
      this.setState({ userRole: "third-party" });
    }

    this.fetchLessons();
  }
}

const mapStatetoProps = (state) => {
  const { user, token } = state.Login;
  const { App } = state;
  return { token, user, App };
};

export default withNamespaces()(connect(mapStatetoProps, null)(ElearningHome));
