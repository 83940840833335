import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { Row, Col, Label, FormGroup, Button, UncontrolledPopover, PopoverBody, Alert, Input } from "reactstrap";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Accordion } from "react-bootstrap";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { TbLoader } from "react-icons/tb";
import CustomizationService from "src/modules/3rd-party-management/apis/CustomizationService";
import LogoImage from "src/assets/images/logo-icon.svg";
import Dropzone from "react-dropzone";
import { reloadOrganizationActiveModuleSettingsFromServer } from "src/store/actions";

const TPDDCompanyLogoCustomization = (props) => {
  const { t, fetchOrganizationDetails, reloadActiveModuleSettings } = props;
  const details = fetchOrganizationDetails.data;
  const [coloredLogo, setColoredLogo] = useState(details?.primary_logo || []);
  const [whiteLogo, setWhiteLogo] = useState(details?.secondary_logo || []);
  const [ primaryLogoSize, setPrimaryLogoSize ] = useState(details.primary_logo_size);
  const [ secondaryLogoSize, setSecondaryLogoSize ] = useState(details.secondary_logo_size);
  const [reducedMenuIcon, setReducedMenuIcon] = useState(
    details?.small_logo || []
  );

  const dropzoneStyle = {
    background: "#fff",
    border: "none",
    fontSize: "20px",
    paddingRight: 0,
    fontWeight: "400",
  };

  const typesRatios = {
    colored: {
        width: 4,
        height: 1
    },
    white: {
        width: 4,
        height: 1
    },
    reduced: {
        width: 1,
        height: 1
    }
  }

  /*********************************** FUNCTIONS ***************************************** */

  // Submit Information
  const handleSubmitMutation = useMutation({
    mutationFn: async () => {
      const formData = new FormData();
      // Append logos to formData

      if (coloredLogo.length > 0 && typeof coloredLogo[0] === "object") {
        formData.append("primaryLogo", coloredLogo[0]);
      }
      if (whiteLogo.length > 0 && typeof whiteLogo[0] === "object") {
        formData.append("secondaryLogo", whiteLogo[0]);
      }

    //   formData.append('primaryLogoSize', primaryLogoSize || '');
	//   formData.append('secondaryLogoSize', secondaryLogoSize || '');

      if (
        reducedMenuIcon.length > 0 &&
        typeof reducedMenuIcon[0] === "object"
      ) {
        formData.append("smallLogo", reducedMenuIcon[0]);
      }

      const service = CustomizationService.getInstance();
      return await service.editLogos(formData);
    },
    onSuccess: () => {
      reloadActiveModuleSettings();
      fetchOrganizationDetails.refetch();
      toast(t("Data saved successfully."), {
        type: "success",
      });
    },
    onError: () => {
      toast(t("Failed to save data."), {
        type: "error",
      });
    },
  });

  // Restore Information
  const restore = () => {
    const { primary_logo, secondary_logo, small_logo } = details;
    setColoredLogo(primary_logo || []);
    setWhiteLogo(secondary_logo || []);
    setReducedMenuIcon(small_logo || []);
  };

    const checkAspectRatio = (width, height, targetWidth, targetHeight) => {
        return true;
        const actualRatio = width / height;

        const targetRatio = targetWidth / targetHeight;

        const tolerance = 1;

        if (Math.abs(actualRatio - targetRatio) <= tolerance) {
            return true; 
        } else {
            return false;
        }
    }

  // Submiting files
  const handleAcceptedFiles = (type, acceptedFiles) => {
    if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0];

        const validateImageRatio = new Promise((res, rej) => {
            const image = new Image();
            image.addEventListener('load', () => {
                if(checkAspectRatio(image.width, image.height, (typesRatios[type].width * 100), (typesRatios[type].height * 100))){
                    res()
                }
                else{
                    rej()
                }
            })
            image.src = URL.createObjectURL(file)
        })

        validateImageRatio.then(() => {
            switch(type){
                case 'colored':
                        setColoredLogo([file])
                    break;

                case 'white':
                        setWhiteLogo([file])
                    break;

                case 'reduced': 
                        setReducedMenuIcon([file])
                    break;

                default:
            }
        }).catch(() => {
            toast(t('The aspect ratio of the uploaded file is not valid'), {
                type: "error",
            });
        })
    }
  };

  const getImageUrl = (file) => {
    if(typeof file === 'string'){
        return file
    }

    if(Array.isArray(file) && file.length > 0){
        return URL.createObjectURL(file[0])
    }

    return null
  }

  /*************************************** VIEW ****************************************** */
  return (
    <Row>
      <div className="page-tpdd-customization">
        <Row>
          <Col className="d-flex align-items-start customization-image-col">
            <img src={LogoImage} alt="Logo" />
          </Col>
          <Col className="customization-accordion-col">
            <Accordion defaultActiveKey="0">
              <Accordion.Item
                className="border-none cusom-accordion-item"
                eventKey="0"
              >
                <Accordion.Header className="custom-accordion-header">
                  <div className="p-1 custom-div-header">
                    {`4. ${t("Logo")}`}{" "}
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <Row className="mb-4">
                    {/* Colored Logo */}
                    <Col lg="6" md="6" sm="12" className="mt-3">
                        <div>
                            <FormGroup className="mb-3">
                                <Label for="fontFamily">
                                    {t("Colored Logo") + ":"}

                                    <span id="colored-logo-info-btn" className="section-info-button">
                                        <i className="ri-information-line" />
                                    </span>
                                    <UncontrolledPopover
                                        placement="bottom"
                                        trigger="hover"
                                        target="colored-logo-info-btn"
                                    >
                                        <PopoverBody>
                                            <span>
                                                {t("This image will be displayed in the sign in, register, set password and reset password pages")}
                                            </span>
                                        </PopoverBody>
                                    </UncontrolledPopover>
                                </Label>
                                <Dropzone
                                onDrop={(acceptedFiles) =>
                                    handleAcceptedFiles("colored", acceptedFiles)
                                }
                                maxFiles={1}
                                multiple={false}
                                >
                                {({ getRootProps, getInputProps }) => (
                                    <div className="dropzone tpdd p-3">
                                        <div
                                            className="dz-message needsclick"
                                            {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <button type="button" className="btn select-files-btn">
                                                {props.t('Select file...')}
                                            </button>
                                            <h4>
                                                {props.t(
                                                    "Drop file here or click to upload"
                                                )}
                                            </h4>
                                        </div>
                                    </div>
                                )}
                                </Dropzone>

                                {/* <Alert color="info" className="alert-dismissible fade show mb-4 mt-1" role="alert">
                                    <i className="mdi mdi-alert-circle-outline me-2"></i>
                                    <b>{t('Please Note')}</b>
                                    <p className="m-0 mt-1">
                                        {t('The acceptable aspect ratio is')}: {typesRatios.colored.width}:{typesRatios.colored.height}
                                        <br/>
                                        {t('Best size in pixels for this logo is')}: 100px * 25px
                                    </p>
                                </Alert> */}

                                <div className="dropzone-previews tpdd mt-3">
                                    <img 
                                        src={getImageUrl(coloredLogo)} 
                                        alt="" 
                                        style={{
                                            width: '100px',
                                            height: '25px'
                                        }} 
                                    />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Label>
                                    {`${t('Colored Logo Size')} (${t('Pixel')})`}
                                </Label>
                                <Input 
                                    type="number"  
                                    name="primaryLogoSize"
                                    value={primaryLogoSize}
                                    onChange={(e) => setPrimaryLogoSize(e.target.value)}
                                />
                            </FormGroup>
                        </div>
                    </Col>

                    {/* White Transparent Logo */}
                    <Col lg="6" md="6" sm="12" className="mt-3">
                        <div>
                            <FormGroup className="mb-3">
                                <Label for="fontFamily">
                                    {t("White Transparent Logo") + ":"}
                                    <span id="white-transparent-logo-info-btn" className="section-info-button">
                                        <i className="ri-information-line" />
                                    </span>

                                    <UncontrolledPopover
                                        placement="bottom"
                                        trigger="hover"
                                        target="white-transparent-logo-info-btn"
                                    >
                                        <PopoverBody>
                                            <span>
                                                {t("This image will be displayed in the left side of your panel as the main logo.")}
                                            </span>
                                        </PopoverBody>
                                    </UncontrolledPopover>
                                </Label>
                                <Dropzone
                                    onDrop={(acceptedFiles) =>
                                        handleAcceptedFiles("white", acceptedFiles)
                                    }
                                    maxFiles={1}
                                    multiple={false}
                                    >
                                    {({ getRootProps, getInputProps }) => (
                                        <div className="dropzone tpdd p-3">
                                            <div
                                                className="dz-message needsclick"
                                                {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                <button type="button" className="btn select-files-btn">
                                                    {props.t('Select file...')}
                                                </button>
                                                <h4>
                                                    {props.t(
                                                        "Drop file here or click to upload"
                                                    )}
                                                </h4>
                                            </div>
                                        </div>
                                    )}
                                </Dropzone>

                                {/* <Alert color="info" className="alert-dismissible fade show mb-4 mt-1" role="alert">
                                    <i className="mdi mdi-alert-circle-outline me-2"></i>
                                    <b>{t('Please Note')}</b>
                                    <p className="m-0 mt-1">
                                        {t('The acceptable aspect ratio is')}: {typesRatios.white.width}:{typesRatios.white.height}
                                        <br/>
                                        {t('Best size in pixels for this logo is')}: 100px * 25px
                                    </p>
                                </Alert> */}

                                <div className="dropzone-previews primary-background tpdd mt-3">
                                    <img 
                                        src={getImageUrl(whiteLogo)} 
                                        alt="" 
                                        style={{
                                            width: '100px',
                                            height: '25px'
                                        }} 
                                    />
                                </div>
                            </FormGroup>

                            <FormGroup>
                                <Label>
                                    {`${t('White Transparent Logo')} (${t('Pixel')})`}
                                </Label>
                                <Input 
                                    type="number"  
                                    name="secondaryLogoSize"
                                    value={secondaryLogoSize}
                                    onChange={(e) => setSecondaryLogoSize(e.target.value)}
                                />
                            </FormGroup>
                        </div>
                    </Col>
                  </Row>

                  <Row>
                    {/* Reduced Menu Icon */}
                    <Col lg="6" md="6" sm="12" className="mt-3">
                        <div>
                            <FormGroup>
                                <Label for="fontFamily">
                                    {t("Reduced Menu Icon") + ":"}

                                    <span id="reduced-logo-info-btn" className="section-info-button">
                                        <i className="ri-information-line" />
                                    </span>

                                    <UncontrolledPopover
                                        placement="bottom"
                                        trigger="hover"
                                        target="reduced-logo-info-btn"
                                    >
                                        <PopoverBody>
                                            <span>
                                                {t("This image will be displayed in the left side of your panel as the main logo when the menu is collapsed.")}
                                            </span>
                                        </PopoverBody>
                                    </UncontrolledPopover>
                                </Label>
                                <Dropzone
                                    onDrop={(acceptedFiles) =>
                                        handleAcceptedFiles("reduced", acceptedFiles)
                                    }
                                    maxFiles={1}
                                    multiple={false}
                                    >
                                    {({ getRootProps, getInputProps }) => (
                                        <div className="dropzone tpdd p-3">
                                            <div
                                                className="dz-message needsclick"
                                                {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                <button type="button" className="btn select-files-btn">
                                                    {props.t('Select file...')}
                                                </button>
                                                <h4>
                                                    {props.t(
                                                        "Drop file here or click to upload"
                                                    )}
                                                </h4>
                                            </div>
                                        </div>
                                    )}
                                </Dropzone>

                                {/* <Alert color="info" className="alert-dismissible fade show mb-4 mt-1" role="alert">
                                    <i className="mdi mdi-alert-circle-outline me-2"></i>
                                    <b>{t('Please Note')}</b>
                                    <p className="m-0 mt-1">
                                        {t('The acceptable aspect ratio is')}: {typesRatios.reduced.width}:{typesRatios.reduced.height} <br/>
                                        {t('Best size in pixels for this logo is')}: 40px * 40px
                                    </p>
                                </Alert> */}

                                <div className="dropzone-previews primary-background tpdd mt-3">
                                    <img 
                                        src={getImageUrl(reducedMenuIcon)} 
                                        alt="" 
                                        style={{
                                            width: '40px',
                                            height: '40px'
                                        }} 
                                    />
                                </div>
                            </FormGroup>
                        </div>
                    </Col>
                  </Row>
                </Accordion.Body>
                <Row className="p-4">
                  <Col sm="12" className="d-flex justify-content-end">
                    <Button
                      onClick={restore}
                      color="primary"
                      type="button"
                      outline
                      className="me-2"
                      disabled={
                        handleSubmitMutation.isLoading ||
                        fetchOrganizationDetails.isLoading ||
                        fetchOrganizationDetails.isFetching
                      }
                    >
                      {t("Restore")}
                    </Button>

                    <Button
                      color="primary"
                      onClick={handleSubmitMutation.mutate}
                      type="submit"
                      disabled={
                        handleSubmitMutation.isLoading ||
                        fetchOrganizationDetails.isLoading ||
                        fetchOrganizationDetails.isFetching
                      }
                    >
                      {handleSubmitMutation.isLoading ? <TbLoader /> : t("Save")}
                    </Button>
                  </Col>
                </Row>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </div>
    </Row>
  );
};

const mapStatetoProps = (state) => {
  const { token } = state.Login;
  const organization = state.Organization;
  const App = state.App;
  const modules = state.Modules;
  return { organization, token, App, modules };
};

const mapDispachToProps = (dispach) => {
  return {
    reloadActiveModuleSettings: () =>
      dispach(reloadOrganizationActiveModuleSettingsFromServer()),
  };
};

export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, mapDispachToProps)(TPDDCompanyLogoCustomization)
  )
);
