import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { Tooltip, Label } from "reactstrap";

import UserUtils from "src/services/utils/UserUtils";
import ReportIncidenService from "../../../../components/api/ReportIncidenService";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { AvField, AvForm } from "availity-reactstrap-validation";
import debounce from "lodash.debounce";

const Index = (props) => {
  const { t, report, caseId, currentUser, reportLoading, token } = props;
  const [adminDescription, setAdminDescription] = useState("");
  const [descriptionTooltip, setDescriptionTooltip] = useState(false);
  const userUtils = new UserUtils();

  const isAnalystOrAnalystAdmin = () => {
    return userUtils.isAnalystOrAnalystAdmin(currentUser);
  };

  const isAnalystAdmin = () => {
    return userUtils.isAnalystAdmin(currentUser);
  };

  const handleUpdateAdminDescriptionMutation = useMutation({
    mutationFn: async (payload) => {
      const service = ReportIncidenService.getInstance();

      return await service.UpdateAdminDescription(payload);
    },
  });

  const handleChangeUpdateAdminDescription = useCallback(
    debounce((value) => {
      handleUpdateAdminDescriptionMutation.mutate(
        {
          caseId: caseId,
          description: value,
        },
        {
          onSuccess: () => {
            toast(t("Admin description updated successfully."), {
              type: "success",
            });
            //props.reportRefetch();
          },
          onError: (error) => {
            toast(t("Failed to update admin description."), {
              type: "error",
            });
            // Refetch description if mutation fails
            setAdminDescription(report?.admin_description);
          },
        }
      );
    }, 600),
    []
  );


  // Set admin description when report loading changes
  useEffect(() => {
    if (!reportLoading) {
      setAdminDescription(report?.admin_description);
    }
  }, [reportLoading, report?.admin_description]);

  const handleDescriptionChange = (e) => {
    const value = e.target.value;
    setAdminDescription(value);
    if (report?.admin_description?.trim() !== value.trim()) {
      handleChangeUpdateAdminDescription(value);
    }
  };


  return (
    <div  >
      {/* Description by the admin*/}
      <Label style={{ display: "flex", justifyContent: "space-between" }}>
        <span>
          {t("Description by the admin")}:
          {!report?.is_deleted_content &&
          (isAnalystAdmin ||
            report?.assignedAnalyst?.value === currentUser?.id) ? (
            <span className="ms-2">
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                id="descriptionTooltip"
              />
              <Tooltip
                placement="top"
                isOpen={descriptionTooltip}
                target="descriptionTooltip"
                toggle={() => setDescriptionTooltip(!descriptionTooltip)}
              >
                {t(
                  "Please do not use clear names in the description. The description will remain in the deletion protocol"
                )}
                .
              </Tooltip>
            </span>
          ) : null}
        </span>
        {/* {!report?.is_deleted_content &&
        (isAnalystAdmin ||
          report?.assignedAnalyst?.value === currentUser?.id) ? (
          <span
            onClick={() =>
              report?.admin_description?.trim() === adminDescription?.trim() 
                ? null
                : handleChangeUpdateAdminDescription()
            }
            style={{
              display: "inline-block",
              marginLeft: "auto",
              marginRight: "0",
              borderRadius: "0.25rem",
              fontSize: "11px",
              lineHeight: "20px",
              padding: "0 10px",
              cursor:

              report?.admin_description?.trim() === adminDescription?.trim() ? "not-allowed"
              : "pointer",
              opacity:
              report?.admin_description?.trim() === adminDescription?.trim()
                  ? "0.5"
                  : "1",
              transition: "all ease 0.3s",
            }}
            className="btn-primary"
          >
            {t("Save")}
          </span>
        ) : null} */}
      </Label>
      {reportLoading ? (
        <p
          className={`dt-field dt-skeleton ${
            isAnalystOrAnalystAdmin ? "dt-select-list" : ""
          }`}
        ></p>
      ) : (
        <AvForm>
          {!report?.is_deleted_content &&
          (isAnalystAdmin ||
            (report?.assignedAnalyst &&
              report?.assignedAnalyst.value === currentUser?.id)) ? (
            <AvField
              type="textarea"
              name="description"
              placeholder={t("Write a summary")}
              value={report?.admin_description}
              onChange={handleDescriptionChange}
              style={{
                maxHeight: "239px",
                transition: "all ease 0.3s",
                height: "114px",
                width:'100%',
              }}
            />
          ) : (
            <p className="dt-field">{report?.admin_description}</p>
          )}
        </AvForm>
      )}
    </div>
  );
};

const mapStatetoProps = (state) => {
  const { token, currentUser } = state.Login;
  return {
    currentUser,
    token,
  };
};

export default withNamespaces()(connect(mapStatetoProps)(Index));
