import { withNamespaces } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { Button, Breadcrumb, BreadcrumbItem } from "reactstrap";

const Navigator = (props) => {
    const { t, backButtonOptions, breadCrumbs = [], pageTitle } = props;
    const history = useHistory();

    const backButtonDefaultOptions = {
        enable          :   false,
        title           :   props.t('Back'),
        icon            :   <i className="ri-arrow-left-fill"></i>,
        onClick         :   () => history.goBack() 
    };

    const backButtonResultOptions = backButtonOptions ? backButtonOptions(backButtonDefaultOptions) : backButtonDefaultOptions;

    return (
        <div className="custom-navigator">

            <div className="d-flex justify-content-center align-items-baseline" style={{
                flexFlow: 'row-reverse'
            }}>
                {
                    pageTitle && (
                        <div className='page-header' style={{
                            marginLeft: '8px'
                        }}>
                            <p className='page-header-title'>
                                {pageTitle}
                            </p>
                        </div>
                    )
                }
                <div className="navigator-actions">
                    {
                        backButtonResultOptions?.enable && (
                            <Button className="d-flex gap-1" onClick={(e) => {
                                backButtonResultOptions?.onClick && backButtonResultOptions.onClick(e)
                            }} outline color="secondary" size="sm">
                                {
                                    backButtonResultOptions?.icon && (
                                        <>{ backButtonResultOptions.icon }</>
                                    )
                                }
                                { t(backButtonResultOptions?.title) }
                            </Button>
                        )
                    }
                </div>
            </div>

            <div className="navigator-breadcrumbs">
                <Breadcrumb>
                    {
                        (breadCrumbs || []).map((breadItem) => {
                            return (
                                <BreadcrumbItem>
                                    <Link to={breadItem.link}>
                                        { t(breadItem.title) }
                                    </Link>
                                </BreadcrumbItem>
                            )
                        })
                    }
                </Breadcrumb>
            </div>
        </div>
    );
}
export default withNamespaces()(Navigator);