import { withNamespaces } from "react-i18next";
import { useEffect } from "react";
import { withRouter } from "react-router-dom";
// styles
import "./style.scss";

import QuestionarService from "../../../../../components/Questionar/service";

import { useQuery } from "@tanstack/react-query";

import QuestionnaireDesigner from "../../../../../components/Questionar/Designer";

import { toast } from 'react-toastify';

const DataProtectionEditQuestionnaire = (props) => {
  const { t, selectedQuestionaire } = props;

  const handleFetchQuestionaireDetails = useQuery({
    queryKey: ["data-protection-admin-questionaire-details"],
    queryFn: async () => {
      const service = QuestionarService.getInstance();

      return await service.questionnaireDetail(selectedQuestionaire.id);
    },
    cacheTime: 0,
    enabled: false,
    onError: (error) => {
      if (process.env.NODE_ENV === 'development') console.error(error);

      toast(t("An error occurred while fetching questionnaire detail."), {
        type: "error",
      });
    }
  });

  useEffect(() => {
    if(selectedQuestionaire){
      handleFetchQuestionaireDetails.refetch();
    }
  }, [selectedQuestionaire]);

  return (
    <QuestionnaireDesigner 
      isLoading={ handleFetchQuestionaireDetails.isLoading || handleFetchQuestionaireDetails.isFetching } 
      questions={ handleFetchQuestionaireDetails?.data }
      questionnaire={ selectedQuestionaire }
      onQuestionnaireUpdated={() => handleFetchQuestionaireDetails.refetch()}
    />
  );
};

export default withNamespaces()(
  withRouter(DataProtectionEditQuestionnaire)
);
