export const IMAGE_ROOT_FOLDER = process.env.PUBLIC_URL + '/assets/images/';
export const IMAGE_REPORTS_FOLDER = IMAGE_ROOT_FOLDER + 'reports/';

export const IMAGE_DEFAULT_LOGO = IMAGE_ROOT_FOLDER + 'logo.svg';

export const IMAGE_HEADER = IMAGE_ROOT_FOLDER + 'header/';
export const IMAGE_HEADER_ARROW_DOWN = IMAGE_HEADER + 'arrow_down.svg';
export const IMAGE_HEADER_QUESTION = IMAGE_HEADER + 'question.svg';
export const IMAGE_HEADER_NOTIFICATION = IMAGE_HEADER + 'notification.svg';

export const IMAGE_REPORT_ANONYMOUSLY = IMAGE_REPORTS_FOLDER + 'report_anonymously.svg';
export const IMAGE_ARRANGE_MEETING = IMAGE_REPORTS_FOLDER + 'arrange_meeting.svg';
export const IMAGE_REPORT_CONFIDENTLY = IMAGE_REPORTS_FOLDER + 'report_confidently.svg';
export const IMAGE_E_LEARNING = IMAGE_REPORTS_FOLDER + 'e_learning.svg';
export const IMAGE_REPORT_NEW_CASE = IMAGE_REPORTS_FOLDER + 'report_new_case.svg';

export const IMAGE_ADMIN_FOLDER = IMAGE_ROOT_FOLDER + 'admin/';
export const IMAGE_ADMIN_USER_MANAGEMENT = IMAGE_ADMIN_FOLDER + 'user_management.svg';
export const IMAGE_ADMIN_CUSTOMIZATION = IMAGE_ADMIN_FOLDER + 'customization.svg';
export const IMAGE_ADMIN_PAYMENT = IMAGE_ADMIN_FOLDER + 'payment.svg';
export const IMAGE_ADMIN_ADD_USER = IMAGE_ADMIN_FOLDER + 'add_user.svg';

export const IMAGE_CHATBOX_FOLDER = IMAGE_ROOT_FOLDER + 'chatbox/';
export const IMAGE_CHATBOX_MESSAGE = IMAGE_CHATBOX_FOLDER + 'message.svg';
export const IMAGE_CHATBOX_CLOSE = IMAGE_CHATBOX_FOLDER + 'close.svg';
export const IMAGE_CHATBOX_SEND = IMAGE_CHATBOX_FOLDER + 'send.svg';
export const IMAGE_CHATBOX_BACK = IMAGE_CHATBOX_FOLDER + 'back.svg';
export const IMAGE_CHATBOX_COPY = IMAGE_CHATBOX_FOLDER + 'copy.svg';

export const IMAGE_USER_FOLDER = IMAGE_ROOT_FOLDER + 'users/';
export const IMAGE_USER_CIRCLE = IMAGE_USER_FOLDER + 'user_circle.png';
export const IMAGE_USER_CIRCLE_SVG = IMAGE_USER_FOLDER + 'user_circle.svg';
export const IMAGE_USER_UPLOAD_FILE = IMAGE_USER_FOLDER + 'upload.svg';
