import { Component, Fragment } from "react";
import { Container } from "reactstrap";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";

import MenuGenerator from "../MenuGenerator";

class Navbar extends Component {
  render() {
    return (
      <Fragment>
        <div className='topnav'>
          <Container fluid>
            <nav className='navbar navbar-light navbar-expand-lg topnav-menu' id='navigation'>
              <MenuGenerator 
                layout="horizontal"
                menuOpen={this.props.menuOpen}
              />
            </nav>
          </Container>
        </div>
      </Fragment>
    );
  }
}


export default withRouter(
    withNamespaces()(Navbar)
);
