import { SET_CASE_ID } from './actionTypes';

const initialState = {
	caseId: '',
};

export const CaseId = (state = initialState, action = {}) => {
	switch (action.type) {
		case SET_CASE_ID:
			return Object.assign({}, state, { caseId: action.payload });
		default:
			return state;
	}
};
