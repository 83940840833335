import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

//Import images
import errorImg from "../assets/images/error-img.png";
import { withNamespaces } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import UserUtils from "../services/utils/UserUtils";

class Error404 extends Component {
  userUtils = new UserUtils();
  getUserHome() {
    if (window.location.href.includes("third-party")) {
      return "/third-party/reports";
    } else if (window.location.href.includes("employee")) {
      return "/employee/reports";
    } else {
      return "/admin";
    }
    // return this.userUtils.getUserHomeRoute(this.props.user);
  }
  render() {
    return (
			<React.Fragment>
				<div className='my-5 pt-5'>
					<Container>
						<Row>
							<Col lg={12}>
								<div className='text-center my-5'>
									<h1 className='fw-bold text-error'>
										4{' '}
										<span className='error-text'>
											0<img src={errorImg} alt='' className='error-img' />
										</span>{' '}
										4
									</h1>
									<h3 className='text-uppercase'>Sorry, page not found</h3>
									{this.props.from !== 'mainRouter' ? (
										<div className='mt-5 text-center'>
											<Link to={this.getUserHome()} className='btn btn-primary waves-effect waves-light'>
												{this.props.t('Back to Home')}
											</Link>
										</div>
									) : null}
								</div>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
  }
}

const mapStatetoProps = (state) => {
  const { token, user: currentUser } = state.Login;
  const { App, CaseId, Feature, Organization } = state;
  return { token, Organization, currentUser, App, CaseId, Feature };
};

export default withNamespaces()(
  withRouter(connect(mapStatetoProps, {})(Error404))
);
