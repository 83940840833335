import { memo, useEffect, useState } from 'react';
import {Nav, NavItem, NavLink} from 'reactstrap';
import { useHistory, useLocation } from 'react-router-dom';


const Tabs = memo(({t, suppliersType}) => {
    const [activeTab, setActiveTab] = useState("");
    const history = useHistory();
    const location = useLocation();

    const isActiveTab = (currentTab, tab) => currentTab === tab;

    const tabsLinks = [
      {
        key     :   "suppliers",
        title   :   suppliersType === 'own' ? 'Own Companies' : "Third Party"
      },
      {
        key     :   "history-log",
        title   :   "History Log"
      }
    ];

    useEffect(() => {
      const tab = (new URLSearchParams(location.search)).get('tab');
      setActiveTab(tab || 'suppliers');
    }, [ location.search ]);

    return (
      <div style={{
        backgroundColor: '#D1D2D6'
      }}>
        <Nav tabs className="custom-style-1">
            {
              tabsLinks.map((link) => {
                return (
                  <NavItem className={isActiveTab(activeTab, link.key) ? 'active': ''} key={link.key}>
                      <NavLink
                        className={isActiveTab(activeTab, link.key) ? 'active': ''}
                        onClick={() => history.push({
                            search : '?tab=' + link.key
                        })}>
                          {t(link.title)}
                      </NavLink>
                  </NavItem>
                )
              })
            }
        </Nav>
      </div>
	  );
});

export default Tabs;
