import React, { useEffect, useRef, useState } from "react";
import { withNamespaces } from "react-i18next";
import { Button, Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import Spinner from 'react-bootstrap/Spinner';

import { useQuery } from "@tanstack/react-query";

import DataProtectionSoftwareService from "src/modules/data-protection/apis/DataProtectionSoftwareService";

import { toast } from "react-toastify";

import {
    HistoryLogActions,
    HistoryLogSections,
    HistoryLogRoles
} from "src/modules/data-protection/constants";

import {
    INTERNATIONAL_DATE_TIME_FORMAT
} from "src/common/constants";

import DateUtils from "src/services/utils/DateUtils";
import CommonService from "src/modules/data-protection/apis/CommonService";

const DetailsModal = ({
    t,
    logId,
    onClose
}) => {
    const [ logData, setLogData ] = useState(null);

    const [ showModal, setShowModal ] = useState(true);

    const dateUtils = new DateUtils();

    const handleFetchSoftwareHistoryLogDetailsQuery = useQuery({
		queryKey: ['data-protection-history-log-details'],
		queryFn: async () => {
			const service = DataProtectionSoftwareService.getInstance();

            return await service.fetchLogDetails(logId);
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			toast(t('An error occurred while fetching history log details.'), {
				type: 'error',
			});
		},
	});

    const fetchUsersQuery = useQuery({
        queryKey: ["users"],
        queryFn: async () => {
          const service = CommonService.getInstance();
    
          return await service.analystAdminsAndAnalysts();
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onSuccess(data) {
        },
        onError: (error) => {
          if (process.env.NODE_ENV === 'development') console.error(error);
    
          toast(t("An error occured while fetching users."), {
            type: "error",
          });
        },
      });

    const toggle = () => {
        let runCloseEvent = showModal;

        setShowModal(!showModal);

        runCloseEvent && onClose && onClose();
    };

    
    const renderDetailsBody = () => {
        return (
            <div className="p-5 pt-1 pb-1">
                <Row className="mb-3">
                    <Col sm="6" md="4">
                        <span className="detail-label">
                            {t('Activites')}
                        </span>
                    </Col>
                    <Col sm="6" md="8">
                        <span className="detail-value bold">
                            { t(HistoryLogSections[logData?.section]) }
                        </span>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col sm="6" md="4">
                        <span className="detail-label">
                            {t('Event ID')}
                        </span>
                    </Col>
                    <Col sm="6" md="8">
                        <span className="detail-value">
                            { logData?.secondary_id }
                        </span>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col sm="6" md="4">
                        <span className="detail-label">
                            {t('User')}
                        </span>
                    </Col>
                    <Col sm="6" md="8">
                        <span className="detail-value">
                            {logData?.user?.full_name || logData?.full_name}
                        </span>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col sm="6" md="4">
                        <span className="detail-label">
                            {t('User Role')}
                        </span>
                    </Col>
                    <Col sm="6" md="8">
                        <span className="detail-value">
                            {HistoryLogRoles[logData?.user_role]}
                        </span>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col sm="6" md="4">
                        <span className="detail-label">
                            {t('Date')}
                        </span>
                    </Col>
                    <Col sm="6" md="8">
                        <span className="detail-value light">
                            <i className="ri-time-line"></i>&nbsp;
                            { dateUtils.convertDateToDate(logData?.created_at, INTERNATIONAL_DATE_TIME_FORMAT) }
                        </span>
                    </Col>
                </Row>

                {logData?.platform && logData?.browser && <Row className="mb-3">
                    <Col sm="6" md="4">
                        <span className="detail-label">
                            {t('Browser/Location')}
                        </span>
                    </Col>
                    <Col sm="6" md="8">
                        <span className="detail-value">
                            {`${logData?.platform} - ${logData?.browser}`}
                        </span>
                    </Col>
                </Row>}

                {
                   logData?.metadata && logData?.metadata?.module_name && <Row className="mb-3">
                        <Col sm="6" md="4">
                            <span className="detail-label">
                                {t('Module')}
                            </span>
                        </Col>
                        <Col sm="6" md="8">
                            <span className="detail-value">
                                {
                                    logData?.metadata?.module_name
                                }
                            </span>
                        </Col>
                    </Row>
                }

{
                   logData?.metadata && logData?.metadata?.measure_title && <Row className="mb-3">
                        <Col sm="6" md="4">
                            <span className="detail-label">
                                {t('Measure Title')}
                            </span>
                        </Col>
                        <Col sm="6" md="8">
                            <span className="detail-value">
                                {
                                    logData?.metadata?.measure_title
                                }
                            </span>
                        </Col>
                    </Row>
                }


                <Row>
                    <Col sm="6" md="4">
                        <span className="detail-label">
                            {t('Description')}
                        </span>
                    </Col>
                    <Col sm="6" md="8">
                        <span className="detail-value">
                            {
                                HistoryLogActions[logData?.section] && HistoryLogActions[logData?.section][logData?.action] && (
                                    <>{t(HistoryLogActions[logData?.section][logData?.action])}</>
                                )
                            }
                        </span>
                    </Col>
                </Row>
            </div>
        )
    }

    useEffect(() => {
        if(handleFetchSoftwareHistoryLogDetailsQuery.data){
            const { data } = handleFetchSoftwareHistoryLogDetailsQuery;
            if(handleFetchSoftwareHistoryLogDetailsQuery.data?.user_id) {
                const selectedUser = fetchUsersQuery?.data?.filter(u => u.id === data?.user_id)[0];
                const fullName = `${selectedUser?.first_name} ${selectedUser?.last_name}`;
                const updatedData = { ...data, full_name: fullName }
                return setLogData(updatedData);
            }   
            setLogData(handleFetchSoftwareHistoryLogDetailsQuery.data);
            
        }
    }, [handleFetchSoftwareHistoryLogDetailsQuery.data]);


    return (
        <Modal className="history-log-details-modal" isOpen={showModal} toggle={ toggle } size="lg">
            <ModalHeader>
                { t('History Log') } { logData?.secondary_id }
            </ModalHeader>

            <ModalBody>
                {
                    (handleFetchSoftwareHistoryLogDetailsQuery.isFetching || handleFetchSoftwareHistoryLogDetailsQuery.isLoading) ? (
                        <div className="d-flex justify-content-center align-items-center">
                            <Spinner animation="border" variant="primary" size="md"/>
                        </div>
                    ) : (
                        <>
                            { renderDetailsBody() }
                        </>
                    )
                }
            </ModalBody>

            <ModalFooter>
                <Button onClick={ toggle } size="sm" color="primary">
                    { t('OK') }
                </Button>
            </ModalFooter>
        </Modal>
    )
};

export default withNamespaces()(DetailsModal);