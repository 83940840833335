import { withNamespaces } from "react-i18next";
import { memo, useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Row,
  Col,
  FormGroup,
  Input,
} from "reactstrap";
import CustomizationService from "src/modules/3rd-party-management/apis/CustomizationService";
import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { TbLoader } from "react-icons/tb";
import draftToHtml from "draftjs-to-html";

const EmailIntroModal = ({
  t,
  modal,
  translation,
  fetchOrganizationDetails,
  closeModal,
}) => {
  const toggle = () => closeModal();
  const [mainTranslation, setMainTranslation] = useState(null);

  /*********************************** FUNCTIONS ***************************************** */
  // Submit Information
  const handleSubmitMutation = useMutation({
    mutationFn: async () => {
      if (!mainTranslation || !mainTranslation.editorState) {
        throw new Error("EditorState is not defined");
      }

      const rawContentState = convertToRaw(
        mainTranslation.editorState.getCurrentContent()
      );
      const introHtml = draftToHtml(rawContentState);
      const payload = {
        text: introHtml,
        language: translation.lang,
      };

      const service = CustomizationService.getInstance();
      return await service.editEmailTranslation(payload);
    },
    onSuccess: () => {
      fetchOrganizationDetails.refetch();
      closeModal();
      toast(t("Data saved successfully."), {
        type: "success",
      });
    },
    onError: () => {
      toast(t("Failed to save data."), {
        type: "error",
      });
    },
  });

  // Restore Information
  const restore = () => {
    resetTranslation();
  };

  // On Editor State Change
  const handleEditorStateChange = (editorState) => {
    setMainTranslation((currentTrans) => ({
      ...currentTrans,
      editorState,
    }));
  };

  //   Reset Translation
  const resetTranslation = () => {
    let contentState;
    const { text } = translation;
    if (text) {
      const blocksFromHTML = convertFromHTML(text);
      contentState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
    } else {
      contentState = ContentState.createFromText("");
    }

    setMainTranslation({
      ...mainTranslation,
      editorState: EditorState.createWithContent(contentState),
    });
  };

  /****************************************** USE EFFECTS *************************************** */
  useEffect(() => {
    if (translation) {
      resetTranslation();
    }
  }, [translation]);

  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        <ModalHeader className="border-0" toggle={toggle}>
          {t("Translations")}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col className="customization-accordion-col">
              <Row className="mt-5">
                <h4>{t("Translation") + ": " + t(translation?.language_name)}</h4>
              </Row>
              <Row>
                <Col>
                  <Label for="introText">{t("Intro Text") + ":"}</Label>
                  {mainTranslation && (
                    <Editor
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      editorState={mainTranslation.editorState}
                      onEditorStateChange={handleEditorStateChange}
                    />
                  )}
                </Col>
              </Row>

              <Row className="p-4">
                <Col sm="12" className="d-flex justify-content-end">
                  <Button
                    onClick={restore}
                    color="primary"
                    type="button"
                    outline
                    className="me-2"
                    disabled={
                      handleSubmitMutation.isLoading ||
                      fetchOrganizationDetails.isLoading ||
                      fetchOrganizationDetails.isFetching
                    }
                  >
                    {t("Restore")}
                  </Button>

                  <Button
                    color="primary"
                    onClick={handleSubmitMutation.mutate}
                    type="submit"
                    disabled={
                      handleSubmitMutation.isLoading ||
                      fetchOrganizationDetails.isLoading ||
                      fetchOrganizationDetails.isFetching
                    }
                  >
                    {handleSubmitMutation.isLoading ? <TbLoader /> : t("Save")}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </div>
  );
};

const mapStatetoProps = (state) => {
  const { token } = state.Login;
  const { Organization } = state;
  return {
    user: state.Login.user,
    token,
    organization: Organization,
  };
};

export default withNamespaces()(
  withRouter(connect(mapStatetoProps, {})(memo(EmailIntroModal)))
);
