import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { toast } from "react-toastify";
import { useQuery, useMutation } from "@tanstack/react-query";
import Select from 'react-select';
import EntityRoutingService from "src/modules/3rd-party-management/apis/EntityRoutingService";
import CommonService from "src/modules/3rd-party-management/apis/CommonService";
import {  Row, Col, Label, Button } from "reactstrap";
import Supplier from './components/Supplier';
import UserUtils from "src/services/utils/UserUtils";

// Mapping object to convert specific keys
const keyMapping = {
    "default_supplier_owner"        :   "defaultSupplierOwner",
    "default_supplier_risk_owner"   :   "defaultSupplierRiskOwner",
    "default_supplier_task_owner"   :   "defaultSupplierTaskOwner",
};

const RouterSetting = (props) => {
    const { t, currentUser } = props;
    const [entityLevel, setEntityLevel] = useState();
    const [analystAdminsList, setAnalystAdminsList] = useState([]);
    const [routerSettings, setRouterSettings] = useState({
        defaultSupplierOwner: null,
        defaultSupplierRiskOwner: null,
        defaultSupplierTaskOwner: null
    });

    const [selectedEntity, setSelectedEntity] = useState(null);
    const [loading, setLoading] = useState(false);

    const userUtils = new UserUtils();
    const isAdmin =userUtils.isAnalystAdmin(currentUser);

    const entityService = EntityRoutingService.getInstance();
    const commonService = CommonService.getInstance();

    const handleFetchEntitiesList = useQuery({
        queryKey: ["fetch-entity-list"],
        queryFn: async () => {
            return await commonService.fetchLegalEntities();
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onError: (error) => {
            toast(props.t("An error occurred while fetching entities."), {
                type: "error",
            });
        },
    });

    const handleFetchAnalystAndAdminAnalystList = useQuery({
        queryKey: ["fetch-analyst-and-admin-analyst-list"],
        queryFn: async () => {
            return await commonService.fetchAnalystAndAdminAnalystList();
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onError: (error) => {
            toast(t("An error occurred while fetching analyst admins list."), {
                type: "error"
            });
        },
    });

    useEffect(() => {
        if (handleFetchEntitiesList.data) {
            const options = (handleFetchEntitiesList?.data?.legalEntities || []).map(entity => ({
                value   :   entity.id,
                label   :   entity.title
            }));

            setEntityLevel(options);
        }
    }, [handleFetchEntitiesList.data]);

    useEffect(() => {
        if ( handleFetchAnalystAndAdminAnalystList.data && Array.isArray(handleFetchAnalystAndAdminAnalystList.data) ) {
            const newList = handleFetchAnalystAndAdminAnalystList.data.map(
                (analyst) => {
                    return {
                        value: analyst.id,
                        label: `${analyst.first_name} ${analyst.last_name}`,
                    };
                }
            );

            setAnalystAdminsList(newList);
        }
    }, [handleFetchAnalystAndAdminAnalystList.data]);

    const transformSettings = (settings) => {
        return Object.keys(settings).reduce((acc, key) => {
            acc[key] = settings[key]?.value || null;
            return acc;
        }, {});
    };

    const saveSettingsMutation = useMutation({
        mutationFn: async (id) => {
            const transformedSettings = transformSettings(routerSettings);
            return await entityService.saveRouterSettings(id, transformedSettings);
        },
        onError: () => {
            toast(t("An error occurred while saving settings."), {
                type: "error",
            });
        },
        onSuccess: () => {
            toast(t("Settings saved successfully."), {
                type: "success",
            });
        },
        onSettled: () => {
            setLoading(false); // Set loading to false on completion
        }
    });

    const restoreSettingsMutation = useMutation({
        mutationFn: async (id) => {
            return await entityService.restoreRouterSettings(id);
        },
        onError: () => {
            toast(t("An error occurred while restoring settings."), {
                type: "error",
            });
        },
        onSuccess: (data) => {
            setRouterSettings({
                defaulSupplierOwner: null,
                defaultSupplierRiskOwner: null,
                defaultSupplierTaskOwner: null
            });
            toast(t("Settings restored successfully."), {
                type: "success",
            });
        },
        onSettled: () => {
            setLoading(false); // Set loading to false on completion
        }
    });

    const transformFetchedData = (data) => {
        const transformedData = {};
    
        // Initialize all keys with null values based on keyMapping
        Object.keys(keyMapping).forEach(mappedKey => {
            transformedData[keyMapping[mappedKey]] = null;
        });
    
        // Transform data from response
        for (const key in data) {
            const newKey = keyMapping[key] || key; // Use the mapped key or the original key if not in the mapping
            if (data[key] && typeof data[key] === 'object') {
                transformedData[newKey] = {
                    value: data[key].id,
                    label: data[key].full_name,
                };
            } else {
                transformedData[newKey] = data[key];
            }
        }
    
        return transformedData;
    };

    const fetchRoutingDetailsMutation = useMutation({
        mutationFn: async (id) => {
            return await entityService.fetchRoutingDetails(id);
        },
        onError: () => {
            toast(t("An error occurred while fetching routing details."), {
                type: "error",
            });
        },
        onSuccess: (data) => {
            if (Array.isArray(data.data)) {
                    setRouterSettings({
                        defaultSupplierOwner: null,
                        defaultSupplierRiskOwner: null,
                        defaultSupplierTaskOwner: null
                    });
                return;
            }
            if(data?.data && typeof data.data === 'object'){
                const transformedData = transformFetchedData(data.data);
                setRouterSettings(transformedData);
            }
        },
        onSettled: () => {
            setLoading(false); // Set loading to false on completion
        }
    });

    const handleSave = () => {
        if(!selectedEntity){
            toast(t("Please select an entity"), {
                type: "error",
            });
            return;
        }
        setLoading(true);
        saveSettingsMutation.mutate(selectedEntity?.value);
    };

    const handleRestore = () => {
        if(!selectedEntity){
            toast(t("Please select an entity"), {
                type: "error",
            });
            return;
        }

        setLoading(true);
        restoreSettingsMutation.mutate(selectedEntity?.value);
    };

    const handleEntityChange = (selectedOption) => {
        setLoading(true);
        setSelectedEntity(selectedOption);
        fetchRoutingDetailsMutation.mutate(selectedOption.value);
    };

    return (
        <div className="settings">
            <Row className="w-100 mb-3">
                <Col sm="12" md="6" lg="6" className="p-2">
                    <Label>{t("Entity")}</Label>
                    <Select
                        options={entityLevel}
                        value={selectedEntity}
                        onChange={handleEntityChange}
                        placeholder={t("Select Entity")}
                        isDisabled={!isAdmin}
                    />
                </Col>
            </Row>

            <Supplier 
                t={t}
                routerSettings={routerSettings}
                selectedEntity={setRouterSettings}
                analystAdminsList={analystAdminsList}
                loading={loading}
                isAdmin={isAdmin}
            />

            <Row className="w-100">
                <div className="d-flex gap-3 mt-4 justify-content-end">
                    <Button onClick={handleRestore} outline disabled={loading || !isAdmin} type="button" size="sm" color="primary">
                        {t("Restore")}
                    </Button>

                    <Button onClick={handleSave} disabled={loading || !isAdmin} type="button" size="sm" color="primary">
                        {t("Save")}
                    </Button>
                </div>
            </Row>
        </div>
    );
};

const mapStatetoProps = (state) => {
    const { token, user: currentUser } = state.Login;
    const { Organization } = state;
    return {
        currentUser,
        token,
        Organization
    };
};

export default withNamespaces()(connect(mapStatetoProps)(RouterSetting));
