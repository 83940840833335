import { withNamespaces } from "react-i18next";

import DateUtils from "src/services/utils/DateUtils";

import { GDPR_REPORT_STATUS_LIST } from "src/modules/data-protection/constants/Common";

import ReactApexChart from "react-apexcharts";

import Select from "react-select";

import { useMutation } from "@tanstack/react-query";

import { toast } from "react-toastify";

import ReportIncidenService from "../api/ReportIncidenService";
import React, { useEffect, useState } from "react";

import { Col, Row, Tooltip } from "reactstrap";

import OverviewHeaderContent from "src/modules/data-protection/components/overview-header";
import { INTERNATIONAL_DATE_FORMAT } from "src/common/constants/Dates";

import { createSVGFromText } from "src/modules/data-protection/helpers/common";
import {
  AvatarGroup,
  AvatarItem,
} from "src/modules/data-protection/components/avatar-group";

const OverviewHeader = ({
  t,
  caseId,
  categories,
  report,
  loading,
  analystAdminsList,
  adminsListIsLoading,
  reportRefetch,
}) => {
  const [asset, setAsset] = useState(null);
  const [analystAdmin, setAnalystAdmin] = useState(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const dateUtils = new DateUtils();

  const handleUpdateAssignedAnalystMutation = useMutation({
    mutationFn: async (payload) => {
      const service = ReportIncidenService.getInstance();

      return await service.updateAssignedAnalyst(payload);
    },
    onError: () => {
      toast(t("An error occurred while updating assigned analyst."), {
        type: "error",
      });
    },
  });

  const handleSupplierAssignedAnalystChange = (newAnalyst) => {
    handleUpdateAssignedAnalystMutation.mutate(
      {
        report_case_id: caseId,
        analyst_id: parseInt(newAnalyst.value),
      },
      {
        onSuccess: () => {
          setAnalystAdmin(newAnalyst);
          reportRefetch();

          toast(t("Assigned analyst updated successfully."), {
            type: "success",
          });
        },
      }
    );
  };
  const renderLoadingSection = () => {
    return (
      <Row>
        <Col sm="12">
          <div className="loading-section p-5">
            <Row className="mb-5">
              <Col sm="12" md="3">
                <div
                  className="dt-field dt-skeleton mb-1"
                  style={{ width: "40%" }}
                ></div>
                <div
                  className="dt-field dt-skeleton"
                  style={{ width: "70%" }}
                ></div>
              </Col>
              <Col sm="12" md="3">
                <div
                  className="dt-field dt-skeleton mb-1"
                  style={{ width: "40%" }}
                ></div>
                <div
                  className="dt-field dt-skeleton"
                  style={{ width: "70%" }}
                ></div>
              </Col>
              <Col sm="12" md="3">
                <div
                  className="dt-field dt-skeleton mb-1"
                  style={{ width: "40%" }}
                ></div>
                <div
                  className="dt-field dt-skeleton"
                  style={{ width: "70%" }}
                ></div>
              </Col>
              <Col sm="12" md="3">
                <div
                  className="dt-field dt-skeleton mb-1"
                  style={{ width: "40%" }}
                ></div>
                <div
                  className="dt-field dt-skeleton"
                  style={{ width: "70%" }}
                ></div>
              </Col>
            </Row>
            <Row>
              <Col sm="12" md="4">
                <div
                  className="dt-field dt-skeleton mb-1"
                  style={{ width: "100%" }}
                ></div>
              </Col>
              <Col sm="12" md="4">
                <div
                  className="dt-field dt-skeleton mb-1"
                  style={{ width: "100%" }}
                ></div>
              </Col>
              <Col sm="12" md="4">
                <div
                  className="dt-field dt-skeleton mb-1"
                  style={{ width: "100%" }}
                ></div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    );
  };

  const renderAssignedAnalysts = () => {
    // const result = [];
    // const adminUpdateInProgress =
    //   handleUpdateAssignedAnalystMutation.isFetching ||
    //   handleUpdateAssignedAnalystMutation.isLoading;
    // result.push(
    //   <Select
    //     className="mb-2"
    //     isDisabled={adminsListIsLoading || adminUpdateInProgress || loading}
    //     isLoading={adminsListIsLoading || adminUpdateInProgress || loading}
    //     placeholder={t("Select") + "..."}
    //     classNamePrefix="select2-selection"
    //     options={analystAdminsList}
    //     menuPortalTarget={document.body}
    //     onChange={handleSupplierAssignedAnalystChange}
    //     styles={{
    //       control: (baseStyles) => ({
    //         ...baseStyles,
    //         width: "220px",
    //         backgroundColor: "transparent",
    //         padding: "4px",
    //         borderColor: "#21252980",
    //       }),
    //     }}
    //     value={analystAdmin}
    //   />
    // );

    const avatarSrc = report?.analyst_assigned?.avatar;
  const Avatars = [];

  if (!avatarSrc || avatarSrc === "") {
    const initials = report?.analyst_assigned?.first_name
      ?.split(" ")
      ?.map((part) => part.charAt(0).toUpperCase())
      ?.join("");
    
    const xml = new XMLSerializer().serializeToString(createSVGFromText(initials));
    
    Avatars.push(
      <AvatarItem
        key="avatar"
        id="overview-analyst-avatar"
        title={report?.analyst_assigned?.first_name}
        src={`data:image/svg+xml;charset=utf-8,${xml}`}
        alt=""
      />
    );
  }

  return (
    <div className="d-flex justify-content-center align-items-center flex-column">
      {/* {result} */}
      {!analystAdmin ? null : <>
      {avatarSrc && avatarSrc !== "" ? (
           <>
        <div className="avatar-xs">
          <span className="avatar-title bg-soft-primary text-primary rounded-circle text-capitalize" style={{ overflow: "hidden" }}>
            <img
              alt={report?.analyst_assigned?.first_name}
              src={avatarSrc}
              style={{
                width: "40px",
                height: "40px",
                display: "block",
                objectFit: "cover",
              }}
            />
          </span>
        </div>
           <p className="fs-6">{ report?.analyst_assigned?.first_name} {report?.analyst_assigned?.last_name}</p>
           </>
      ) : (
        <AvatarGroup max={4}>{Avatars}</AvatarGroup>
      )}
      </>}
    </div>
  );
};

  const handleRenderReportStatus = (statusId) => {
    const status = GDPR_REPORT_STATUS_LIST.find(
      (item) => item.id === parseInt(statusId)
    );
    return (
      <div
        style={{
          minWidth: "82px",
          fontSize: "10px",
          fontWeight: 700,
          padding: "3px 8px",
          background: status.backgroundColor,
          opacity: status?.opacity || 1,
          color:"#1D1D1D",
          borderRadius:'7px',
          whiteSpace:'nowrap',
          textAlign:'center'
        }}
        className={"dt-task-status "}
      >
        {t(status?.titleKey)}
      </div>
    );
  };

  const handleRenderReportCategories = (reportCategories) => {
    if (!reportCategories || reportCategories.length === 0) return null;

    const categoryObjects =
      categories && categories?.length > 0
        ? categories.filter((category) =>
            reportCategories.includes(category.id)
          )
        : [];

    if (categoryObjects?.length === 0) return null;

    const firstCategory = categoryObjects[0];
    const remainingCategories = categoryObjects.slice(1);

    const tooltipContent = remainingCategories
      .map((category) => t(category.title))
      .join(", ");

    return (
      <div
        className="d-flex justify-content-center align-items-center  flex-column mb-4"
        style={{ width: "220px", textWrap: "wrap", paddingLeft:'16px' }}
      >
        <div>
          {t(firstCategory.title)}
          {remainingCategories?.length > 0 ? (
            <span
              className="ps-1"
              style={{ color: "#899BA1" }}
              id="tooltip__controller"
            >
              <i aria-hidden="true" className="fa fa-info-circle" />
            </span>
          ) : null}
        </div>
        {remainingCategories?.length > 0 ? (
          <Tooltip
            target="tooltip__controller"
            autohide
            style={{ padding: "8px" }}
            isOpen={tooltipOpen}
            placeholder="top"
            toggle={toggle}
          >
            <p className="">{tooltipContent}</p>
          </Tooltip>
        ) : null}
      </div>
    );
  };

  useEffect(() => {
    if (report && !loading) {
      setAsset(report);
      if (report?.analyst_assigned?.id) {
        const analystName = {
          value: report?.analyst_assigned?.id,
          label: `${report?.analyst_assigned?.first_name} ${report?.analyst_assigned?.last_name}`,
        };
        setAnalystAdmin(analystName);
      }
      else setAnalystAdmin(null);
    }
  }, [report, loading]);

  return (
    <React.Fragment>
      {loading || !asset ? (
        <>{renderLoadingSection()}</>
      ) : (
        <OverviewHeaderContent
          items={[
            [
              {
                title: t("Incident ID"),
                value: asset?.secondary_case_id,
              },
              {
                title: t("Type"),
                value: (
                  <div
                    className="d-flex justify-content-center align-items-center  flex-column"
                    style={{ maxWidth: "200px"}}
                  >
                    {t(asset?.questionnaire)}
                  </div>
                ),
              },
              {
                title: t("Category"),
                value: (
                  <div
                    className="d-flex justify-content-center align-items-center flex-column mb-4"
                    style={{ width: "220px", textWrap: "wrap" }}
                  >
                    {handleRenderReportCategories(asset?.admin_category || [])}
                  </div>
                ),
              },
              {
                title: t("Created on"),
                value: (
                  <div className="d-flex justify-content-center align-items-center flex-column">
                    {dateUtils.convertTimeStampToDate(
                      asset.created_at,
                      INTERNATIONAL_DATE_FORMAT
                    )}
                    <small className="text-muted">
                      {t("Updated")}:{" "}
                      {dateUtils.convertTimeStampToDate(
                        asset.status_updating_date,
                        INTERNATIONAL_DATE_FORMAT
                      )}
                    </small>
                  </div>
                ),
              },
              {
                title: t("Closing date"),
                value: (
                  <div>
                    {dateUtils.convertTimeStampToDate(
                      asset.closing_date,
                      INTERNATIONAL_DATE_FORMAT
                    )}
                  </div>
                ),
              },
              {
                title: t("Status"),
                value: handleRenderReportStatus(asset.status),
              },
            ],
            [
              {
                title: "Assigned Analyst",
                value: renderAssignedAnalysts(),
              },
              {
                title: "Mitigated Risks",
                value: (
                  <>
                    <ReactApexChart
                      options={{
                        chart: {
                          sparkline: {
                            enabled: true,
                          },
                        },
                        dataLabels: {
                          enabled: false,
                        },
                        colors: ["#FF4949"],
                        stroke: {
                          lineCap: "round",
                        },
                        plotOptions: {
                          radialBar: {
                            hollow: {
                              margin: 0,
                              size: "70%",
                            },
                            track: {
                              margin: 0,
                            },

                            dataLabels: {
                              show: false,
                            },
                          },
                        },
                      }}
                      series={[asset?.completedMitigatedTasks || 0]}
                      type="radialBar"
                      height="60"
                    />
                    <p className="text-center">
                      {asset?.completedMitigatedTasks || 0}%
                    </p>
                  </>
                ),
              },
              {
                title: "Completed Tasks",
                value: (
                  <>
                    <ReactApexChart
                      options={{
                        chart: {
                          sparkline: {
                            enabled: true,
                          },
                        },
                        dataLabels: {
                          enabled: false,
                        },
                        colors: ["#195A4D"],
                        stroke: {
                          lineCap: "round",
                        },
                        plotOptions: {
                          radialBar: {
                            hollow: {
                              margin: 0,
                              size: "70%",
                            },
                            track: {
                              margin: 0,
                            },

                            dataLabels: {
                              show: false,
                            },
                          },
                        },
                      }}
                      series={[asset?.completedTasks || 0]}
                      type="radialBar"
                      height="60"
                    />
                    <p className="text-center">{asset?.completedTasks || 0}%</p>
                  </>
                ),
              },
            ],
          ]}
        />
      )}
    </React.Fragment>
  );
};

export default withNamespaces()(OverviewHeader);
