import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';
import aiFlag  from '../../../../modules/data-protection/assets/images/ai-flag.svg'; 
import { withNamespaces } from 'react-i18next';

const AIFlagWithTooltip = (props) => {
  const {t, isAIFilled} = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <div hidden={!isAIFilled}>
      <img
        src={aiFlag}
        alt={t("Generated by AI")}
        id="TooltipImage"
        style={{width: "30px", height: "30px"}}
        className="input-icon"
      />
      <Tooltip
        placement="top" // You can change this to "bottom", "left", "right"
        isOpen={tooltipOpen}
        target="TooltipImage"
        toggle={toggle}
      >
        {props.t("Generated by AI")}
      </Tooltip>
    </div>
  );
};

export default withNamespaces()(AIFlagWithTooltip);
