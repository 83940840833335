import AdminMenuItems from './AdminMenuItems';
import AnalystMenuItems from './AnalystMenuItems';
import EmployeeMenuItems from './EmployeeMenuItems';
import ThirdPartyMenuItems from './ThirdPartyMenuItems';

const WorkspacesMenuItems = {
    admin_workspace         :   AdminMenuItems,
    analyst_workspace       :   AnalystMenuItems,
    employee_workspace      :   EmployeeMenuItems,
    third_party_workspace   :   ThirdPartyMenuItems
};

export {
    WorkspacesMenuItems
};