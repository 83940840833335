import { Collapse } from "reactstrap";
import { Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import classname from "classnames";
import { useState } from "react";

const HorizontalMenuContent = (props) => {
	const {
		menu,
		t,
        menuOpen
	} = props;

    const [activeMenuIndex, setActiveMenuIndex] = useState(-1)

	return (
		<Collapse isOpen={ menuOpen } className='navbar-collapse' id='topnav-menu-content'>
			<ul className='navbar-nav'>
				{
					menu.map((menuItem, index) => {
						const subMenuItems = menuItem.items || [];
						const hasSubMenu = subMenuItems.length > 0;
						
						return (
                            <li
                                key={index}
                                className={"nav-item" + (hasSubMenu > 0 ? " dropdown" : "")}
                                onClick={() => setActiveMenuIndex(index === activeMenuIndex ? -1 : index)}
                            >
                                {menuItem.linkType === "react-router" ? (
                                menuItem?.path ? (
                                    <Link
                                        className={
                                            "nav-link" +
                                            (hasSubMenu > 0 ? " dropdown-toggle arrow-none" : "")
                                        }
                                        to={menuItem.path}
                                    >
                                        <i className={menuItem?.icon + " me-2"}></i>
                                        {t(menuItem.titleKey)}
                                    </Link>
                                ) : (
                                    <span
                                        className={
                                            "nav-link" +
                                            (hasSubMenu > 0 ? " dropdown-toggle arrow-none" : "")
                                        }
                                    >
                                        <i className={menuItem?.icon + " me-2"}></i>
                                        {t(menuItem.titleKey)}
                                    </span>
                                )
                                ) : menuItem?.path ? (
                                    <a className="nav-link" href={menuItem.path} target="_new">
                                        <i className={menuItem?.icon + " me-2"}></i>
                                        <span className="ms-1">{t(menuItem.titleKey)}</span>
                                    </a>
                                ) : (
                                    <span className="nav-link">
                                        <i className={menuItem?.icon + " me-2"}></i>
                                        <span className="ms-1">{t(menuItem.titleKey)}</span>
                                    </span>
                                )}

                                {hasSubMenu ? (
                                    <div
                                        className={classname("dropdown-menu dropdown-menu-end", {
                                            show: activeMenuIndex === index,
                                        })}
                                        aria-labelledby="topnav-apps"
                                    >
                                        {subMenuItems.map((subMenuItem, subMenuItemIndex) => {
                                            return subMenuItem.linkType === "react-router" ? (
                                                <Link
                                                    key={subMenuItemIndex}
                                                    to={subMenuItem?.path}
                                                    className="dropdown-item"
                                                >
                                                    {t(subMenuItem.titleKey)}
                                                </Link>
                                            ) : (
                                                <a
                                                    className="dropdown-item"
                                                    href={subMenuItem?.path}
                                                    target="_new"
                                                >
                                                    {t(subMenuItem.titleKey)}
                                                </a>
                                            );
                                        })}
                                    </div>
                                ) : null}
                            </li>
                        )
					})
				}
			</ul>
		</Collapse>
	);
}


export default withNamespaces()(HorizontalMenuContent);