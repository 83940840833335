import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import { Row, Col, Card, CardBody, CardTitle, Tooltip } from "reactstrap";
import { Link } from "react-router-dom";

//Import Vector Map Component
import Vector from "./Vectormap";

class RevenueByLocations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      lenght: 4,
      countries: [],
      heatmapTooltip: false,
    };
  }

  lengthCountries() {
    let lenght = Object.keys(this.props.topCountries).length;
    if (lenght > 4) {
      lenght = 4;
    }
    this.setState({
      lenght: lenght,
    });
  }

  render() {
    return (
      <React.Fragment>
        <Col lg={12}>
          <Card>
            <CardBody>
              {/* <Dropdown className="float-end" isOpen={this.state.menu} toggle={() => this.setState({ menu: !this.state.menu })}>
                                <DropdownToggle tag="i" className="darrow-none card-drop" aria-expanded="false">
                                    <i className="mdi mdi-dots-vertical"></i>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">

                                    <DropdownItem href="">Sales Report</DropdownItem>

                                    <DropdownItem href="">Export Report</DropdownItem>

                                    <DropdownItem href="">Profit</DropdownItem>

                                    <DropdownItem href="">Action</DropdownItem>
                                </DropdownMenu>
                            </Dropdown> */}

              <CardTitle>
                {
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>{this.props.t("Heatmap")}</div>
                    <i
                      className="fa fa-info-circle"
                      aria-hidden="true"
                      id="infoIconHeatmap"
                    />
                    <Tooltip
                      placement="top"
                      isOpen={this.state.heatmapTooltip}
                      target="infoIconHeatmap"
                      toggle={() =>
                        this.setState({
                          heatmapTooltip: !this.state.heatmapTooltip,
                        })
                      }
                    >
                      {this.props.t(
                        "On this map, you can check in an easy way where have been more reports in the latest time"
                      )}
                    </Tooltip>
                  </div>
                }
              </CardTitle>

              <div id="usa-vectormap">
                <Vector
                  value="world_mill"
                  width="200"
                  color="#e8ecf4"
                  countries={this.state.countries}
                />
              </div>

              {this.props.topCountries ? (
                <Row className="justify-content-center">
                  <Col xl={12}>
                    <div className="legend-title">
                      {this.props.t("Top 4 countries")}
                    </div>
                    <div
                      className="legend"
                      style={{
                        gridTemplateColumns:
                          "repeat(" + this.state.lenght + ", 1fr)",
                        maxWidth: "50%",
                      }}
                    >
                      {Object.keys(this.props.topCountries).map(
                        (topCountry, index) => {
                          return (
                            <div
                              className={`level-${
                                Object.keys(this.props.topCountries).length -
                                index
                              }`}
                            ></div>
                          );
                        }
                      )}
                      {Object.keys(this.props.topCountries).map(
                        (topCountry) => {
                          return (
                            <div className="scale">
                              {this.props.t(topCountry)}(
                              {this.props.topCountries[topCountry]})
                            </div>
                          );
                        }
                      )}
                    </div>
                  </Col>
                </Row>
              ) : null}
            </CardBody>
          </Card>
        </Col>
      </React.Fragment>
    );
  }

  // componentDidUpdate() {
  //    this.lengthCountries();
  // }

  componentDidUpdate(prevProps) {
    if (prevProps.countries !== this.props.countries) {
      const countries = this.props.countries;

      // console.log(countries);

      this.setState({
        countries,
      });
    }
  }
}

export default withNamespaces()(RevenueByLocations);
