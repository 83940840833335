import axios from 'axios';

import {
    API_BASE_URL
} from '../constants/index';

export default class SupplierRiskAssessmentService {
	static instance;

	static getInstance() {
		if (!SupplierRiskAssessmentService.instance) {
			SupplierRiskAssessmentService.instance = new SupplierRiskAssessmentService();
		}

		return SupplierRiskAssessmentService.instance;
	}

    async fetchRisks(supplierId, params = {}){
        const response = await axios.get(`${API_BASE_URL}/supplier/${supplierId}/risks`, {
            params  :   params,
            headers :   {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`
            },
        });
        return response.data.data
    }

    async fetchMatrix(supplierId, params = {}){
        const response = await axios.get(`${API_BASE_URL}/supplier/${supplierId}/risk_rate/matrix`, {
            params  :   params,
            headers :   {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`
            },
        });
        return response.data.data
    }

    async fetchOwners(supplierId, payload = {}){
        // ${API_BASE_URL}/supplier/${supplierId}/analystAndAdminAnalyst.list
        const response = await axios.post(`${API_BASE_URL}/analystAndAdminAnalyst.list`, payload, {
            params: {},
            headers: {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`,
            },
        });
  
        return response.data.data;
    }

    async fetchManagers(supplierId, params = {}){
        const response = await axios.get(`${API_BASE_URL}/manager.list`, {
            params  :   params,
            headers :   {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`
            },
        });

        return response.data.data
    }

    async fetchCategories(supplierId, params = {}){
        const response = await axios.get(`${API_BASE_URL}/risk_rate_category/index`, {
            params  :   params,
            headers :   {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`
            },
        });

        return response.data.data
    }

    async fetchLocations(supplierId, payload = {}){
        const response = await axios.post(`${API_BASE_URL}/report.country.list`, payload, {
            params: {},
            headers: {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`,
            },
        });
  
        return response.data.data;
    }

    async deleteRisk(supplierId, riskId){
        const response = await axios.delete(`${API_BASE_URL}/risk_rate/${riskId}/delete`,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                    )}`,
                }
            }
        );
  
        return response.data.data;
    }    
}
