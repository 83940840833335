import { withNamespaces } from "react-i18next";
import { connect } from 'react-redux';
import { 
    Modal, 
    ModalBody, 
    ModalFooter,
    ModalHeader,
    Row,
    Col,
    Label,
    Button,
    ButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Spinner
} from "reactstrap";
import { AvForm, AvInput, AvField, AvGroup, AvFeedback } from "availity-reactstrap-validation";
import Select from "react-select";
import { useEffect, useMemo, useRef, useState } from "react";
import SupplierContactPersonService from "src/modules/3rd-party-management/apis/SupplierContactPersonService";
import CommonService from "src/modules/3rd-party-management/apis/CommonService";
import LocalizedPhoneInput from "src/modules/3rd-party-management/components/LocalizedPhoneInput";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";

const CreditorContactModal = ({
    t,
    Organization,
    displayModal,
    toggleModal,
    handleSubmit,
    creationFormInProcess
}) => {
    const [ responsiblePersonTitleDrop, setResponsiblePersonTitleDrop ] = useState(false);
    const [ responsiblePerson, setResponsiblePerson ] = useState({});
    const [ emailIsValid, setEmailIsValid ] = useState(true);

    const avFormRef = useRef();

    const languages = (Organization?.languages || []).map((lng) => {
        return {
            value   :   lng?.language?.id,
            label   :   t(lng.name),
            code    :   lng.language_code,
        }
    });

    const {
        mutate      :   checkUserExistenceMutation,
        isLoading   :   checkUserExistenceMutationInProcess   
    } = useMutation({
        mutationFn: async (payload) => {
          const service = CommonService.getInstance();
    
          return await service.checkUserExistence(payload);
        },
        onError: () => {
            setEmailIsValid(false)
        },
        onSuccess: () => {
            setEmailIsValid(true);
        }
    });

    useEffect(() => {
        if(!displayModal){
            setResponsiblePerson({
                ...responsiblePerson,
                title   :   'Mr.'
            });
        }
    }, [ displayModal ]);

    return (
        <Modal 
            onClosed={() => {
                setEmailIsValid(true)
            }} 
            isOpen={ displayModal } 
            backdrop="static" 
            keyboard={ false } 
            size='xl'
            className="contact-person-creation-modal">
            
            <AvForm className="needs-validation m-2"
            onValidSubmit={ (e, values) => {
                if(!emailIsValid || checkUserExistenceMutationInProcess) return;
                handleSubmit('creditor', e, values)
            } } ref={ avFormRef }>
                <ModalHeader toggle={ () => toggleModal(false) }>
                    {t("Add A New Contact Person")}
                </ModalHeader>

                <ModalBody>
                    <Row className="mb-3">
                        <Col md="4" sm="12">
                            <Label for="responsible-person-firstname">
                                {t("First Name")}
                            </Label>

                            <div className="d-flex flex-wrap position-relative">
                                <ButtonDropdown className="position-absolute" 
                                    isOpen={ responsiblePersonTitleDrop } 
                                    toggle={() => {
                                        setResponsiblePersonTitleDrop(!responsiblePersonTitleDrop)
                                    }} 
                                    style={{
                                        left: "0px",
                                        top: "0px",
                                        width: "74px",
                                        height: "33px"
                                    }}>
                                        <DropdownToggle outline split style={{ border: 'none', borderRight: '1px solid rgb(206, 212, 218)' }}>
                                            <span style={{marginRight: '8px'}}>
                                                { t(responsiblePerson.title || 'Mr.') }
                                            </span>
                                        </DropdownToggle>

                                        <DropdownMenu>
                                            <DropdownItem onClick={() => { 
                                                setResponsiblePerson({
                                                    ...responsiblePerson,
                                                    title   :   'Mr.'
                                                });
                                            }}>
                                                { t('Mr.') }
                                            </DropdownItem>

                                            <DropdownItem onClick={() => { 
                                                setResponsiblePerson({
                                                    ...responsiblePerson,
                                                    title   :   'Mrs.'
                                                });
                                            }}>
                                                { t('Mrs.') }
                                            </DropdownItem>

                                            <DropdownItem onClick={() => { 
                                                setResponsiblePerson({
                                                    ...responsiblePerson,
                                                    title   :   'Mx.'
                                                });
                                            }}>
                                                { t('Mx.') }
                                            </DropdownItem>
                                        </DropdownMenu>
                                </ButtonDropdown>

                                <AvInput 
                                    id={`responsible-person-title`}
                                    name={`responsible_person[title]`}
                                    type="hidden"
                                    value={ responsiblePerson.title || 'Mr.'}
                                />

                                <AvField
                                    id={`responsible-person-firstname`}
                                    name={`responsible_person[name]`}
                                    type="text"
                                    errorMessage={t("This field cannot be blank")}
                                    className="form-control"
                                    validate={{
                                        required: { value: true },
                                    }}
                                    style={{
                                        paddingLeft: '85px'
                                    }}
                                    groupAttrs={{
                                        className: 'w-100'
                                    }}
                                />
                            </div>
                        </Col>

                        <Col md="4" sm="12">
                            <Label for="responsible-person-lastname">
                                {t("Last Name")}
                            </Label>
                            <AvField
                                name={`responsible_person[lastName]`}
                                type="text"
                                errorMessage={t("This field cannot be blank")}
                                className="form-control"
                                validate={{
                                    required: { value: true },
                                }}
                                id='responsible-person-lastname'
                            />
                        </Col>

                        <Col md="4" sm="12">
                            <Label for="responsible-person-position">
                                {t("Position")}
                            </Label>
                            <AvField
                                name={`responsible_person[position]`}
                                type="text"
                                errorMessage={t("This field cannot be blank")}
                                className="form-control"
                                validate={{
                                    required: { value: true },
                                }}
                                id='responsible-person-position'
                            />
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col md="4" sm="12">
                            <Label for="responsible-person-language">
                                {t("Language")}
                            </Label>
                            <Select
                                placeholder={t("Select") + "..."}
                                classNamePrefix="select2-selection"
                                id='responsible-person-language'
                                options={ languages }
                                menuPortalTarget={document.body}
                                onChange={(e) => {
                                    setResponsiblePerson({
                                        ...responsiblePerson,
                                        language    :   e
                                    });
                                }}
                                value={ responsiblePerson?.language }
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            />
                            <AvField
                                name={`responsible_person[language]`}
                                type="hidden"
                                errorMessage={t("This field cannot be blank")}
                                validate={{
                                    required: { value: true }
                                }}
                                value={ responsiblePerson?.language?.value }
                            />
                        </Col>

                        <Col md="4" sm="12">
                            <Label for="responsible-person-email">
                                {t("Email")}
                            </Label>
                            <AvGroup>
                                <AvField
                                    name={`responsible_person[email]`}
                                    type="email"
                                    className="form-control"
                                    validate={{
                                        required: {
                                            value: true,
                                            errorMessage: t("This field cannot be blank"),
                                        },
                                        email: { errorMessage: t("Your email is invalid") },
                                    }}
                                    onBlur={(e) => {
                                        if(!e.target.value){
                                            return setEmailIsValid(true);
                                        }

                                        checkUserExistenceMutation({
                                            email : e.target.value
                                        });
                                    }}
                                    disabled={checkUserExistenceMutationInProcess}
                                    id={`responsible-person-email`}
                                />
                                {!checkUserExistenceMutationInProcess && !emailIsValid && (
                                    <AvFeedback valid={false} className='d-block'>
                                        {t('Email already exists')}
                                    </AvFeedback>
                                )}
                                {checkUserExistenceMutationInProcess && (
                                    <AvFeedback className='d-block text-info'>
                                        <Spinner className="me-1" animation="border" variant="info" size="sm"/>
                                        {t('Validating email address.please wait...')}
                                    </AvFeedback>
                                )}
                            </AvGroup>
                        </Col>

                        <Col md="4" sm="12">
                            <Label for="responsible-person-phone">
                                {t("Phone Number")}
                            </Label>

                            <LocalizedPhoneInput
                                inputClass="w-100"
                                country={ "us" }
                                inputStyle={{
                                    height  :   '38px'
                                }}
                                disableInitialCountryGuess={ false }
                                disableCountryGuess={ false }
                                onChange={ (e) => {
                                    const input = avFormRef.current?._inputs['responsible_person[phoneNumber]'];
                                    if(input){
                                        input.value = e;
                                        input.validate();
                                    }
                                }}
                            />

                            <AvField
                                name={`responsible_person[phoneNumber]`}
                                type="hidden"
                                errorMessage={t("This field cannot be blank")}
                                validate={{
                                    required: { value: true },
                                }}
                            />
                        </Col>
                    </Row>
                </ModalBody>

                <ModalFooter>
                    <Button onClick={ () => toggleModal(false) } color="primary" type="button" outline>
                        { t('Cancel') }
                    </Button>

                    <Button disabled={ creationFormInProcess } color="primary" type="submit" size="md">
                        {
                            creationFormInProcess ? (
                                <Spinner className="me-2" animation="border" variant="white" size="sm"/>
                            ) : (
                                <i className="ri-add-line align-middle me-1"></i>
                            )
                            
                        }
                        { t('Add') }
                    </Button>
                </ModalFooter>
            </AvForm>
        </Modal>
    )
}

const OwnCompanyContactModal = ({
    t,
    displayModal,
    toggleModal,
    handleSubmit,
    creationFormInProcess
}) => {
    const [ selectedAnalyst, setSelectedAnalyst ] = useState(); 

    const {
        data: analystList,
        isFetching: fetchingAnalysts,
        isLoading: loadingAnalysts
    } = useQuery({
		queryKey: ['3rd-party-management-fetch-supplier-analyst-list'],
		queryFn: async () => {
			const service = CommonService.getInstance();

            return await service.fetchAnalystAndAdminAnalystList();
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: () => {
			toast(t('An error occurred while fetching analysts list.'), {
				type: 'error',
			});
		},
	});

    const memorizedAnalystList = useMemo(() => {
        return (analystList || []).map((analyst) => {
            return {
                value: analyst.id,
                label: `${analyst.first_name} ${analyst.last_name}`
            }
        });
    }, [ analystList ])

    return (
        <Modal 
            isOpen={ displayModal } 
            backdrop="static" 
            keyboard={ false } 
            size='md'
            className="contact-person-creation-modal">
            
            <AvForm className="needs-validation m-2"
            onValidSubmit={ (e, values) => {
                handleSubmit('own', e, values)
            } }>
                <ModalHeader toggle={ () => toggleModal(false) }>
                    {t("Add A New Responsible Person")}
                </ModalHeader>

                <ModalBody>
                    <Row>
                        <Col sm="12">
                            <Label for="responsible-person-language">
                                {t("Select user")}
                            </Label>
                            <Select
                                placeholder={t("Select") + "..."}
                                isLoading={fetchingAnalysts || loadingAnalysts}
                                classNamePrefix="select2-selection"
                                id='responsible-person-language'
                                options={ memorizedAnalystList }
                                onChange={(e) => setSelectedAnalyst(e)}
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                
                            />
                            <AvField
                                name={`responsible_person[user]`}
                                type="hidden"
                                errorMessage={t("This field cannot be blank")}
                                validate={{
                                    required: { value: true },
                                }}
                                value={ selectedAnalyst?.value }
                            />
                        </Col>
                    </Row>
                </ModalBody>

                <ModalFooter>
                    <Button onClick={ () => toggleModal(false) } color="primary" type="button" outline>
                        { t('Cancel') }
                    </Button>

                    <Button disabled={ creationFormInProcess } color="primary" type="submit" size="md">
                        {
                            creationFormInProcess ? (
                                <Spinner className="me-2" animation="border" variant="white" size="sm"/>
                            ) : (
                                <i className="ri-add-line align-middle me-1"></i>
                            )
                            
                        }
                        { t('Add') }
                    </Button>
                </ModalFooter>
            </AvForm>
        </Modal>
    )
}

const CreationModal = ({
    t,
    supplierType,
    supplierId,
    onCeationSucceed,
    Organization,
    displayModal,
    toggleModal
}) => {
    const {
        mutate: createSupplierMutation,
        isLoading: creationFormInProcess
    } = useMutation({
        mutationFn: async (payload, type) => {
            const service = SupplierContactPersonService.getInstance();

            if(supplierType === 'own')
                return await service.createInternal(payload);

            return await service.create(payload); 
        },
        onSuccess: () => {
            onCeationSucceed && onCeationSucceed();
            toggleModal(false);

            toast(t("New contact person created successfully."), {
                type: "success",
            });
        },
        onError: () => {
            toast(t("An error occurred while creating contact person."), {
                type: "error",
            });
        }
    });

    const handleSubmit = (type, e, values) => {
        createSupplierMutation({
            ...values['responsible_person'],
            'supplier'      :   supplierId
        });
    }

    if(supplierType === 'own'){
        return (
            <OwnCompanyContactModal 
                t={t}
                displayModal={displayModal}
                toggleModal={toggleModal}
                handleSubmit={handleSubmit}
                creationFormInProcess={creationFormInProcess}
            />
        )
    }
        
    return (
        <CreditorContactModal 
            t={t} 
            Organization={Organization} 
            displayModal={displayModal}
            toggleModal={toggleModal}
            handleSubmit={handleSubmit}
            creationFormInProcess={creationFormInProcess}
        />
    );
}

const mapStatetoProps = (state) => {
	const { Organization } = state;

	return {
		Organization
	};
};

export default withNamespaces()(
    connect(mapStatetoProps, null)(
        CreationModal
    )
);