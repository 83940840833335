import React from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { withNamespaces } from "react-i18next";

const FirstFooter = ({ backgroundColor, logo, t }) => {
  const styles = StyleSheet.create({
    footer: {
      backgroundColor,
      position: 'absolute',
      height: 27,
      width: '100%',
      bottom: 0,
      left: 0,
      paddingLeft: 58,
      display: 'flex',
      justifyContent: 'center'
    },
    pageNumber : {
        fontSize: 9,
        color: '#000'
    }
  });

  return (
    <View style={styles.footer} fixed>
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => `${t('Page')} ${pageNumber}/${totalPages}`} />
    </View>
  );
};

export default withNamespaces()(FirstFooter);