// Http status error code
export const STATUS_CODE_UNAUTHORIZED = 401;
export const STATUS_CODE_NOT_FOUND = 404;

// App error code
export const ERROR_CODE_NO_ACCESS_TOKEN = 'ERROR_CODE_NO_ACCESS_TOKEN';
export const ERROR_CODE_FATAL_ERROR = 'fatal_error';
export const ERROR_CODE_UNKNOWN_ERROR = 'unknown_error';
export const ERROR_CODE_INVALID_ARGUMENTS = 'invalid_arguments';
export const ERROR_CODE_INVALID_ARG_NAME = 'invalid_arg_name';
export const ERROR_CODE_INVALID_USER_AUTHORIZE = 'invalid_user_authorize';
export const ERROR_CODE_INVALID_AUTHENTICATION = 'invalid_authentication';
export const ERROR_CODE_AUTHENTICATION_ATTEMPTS = 'authentication_attempts';
export const ERROR_CODE_ORGANIZATION_INACTIVE = 'demo_organization_inactive';
export const ERROR_CODE_JOIN_CHAT = 'chat_joined';
export const ERROR_CODE_LIST_CHAT = 'get_list_chat_error';
export const ERROR_CODE_THIS_FIELD_CANNOT_BE_BLANK = 'this_field_cannot_be_blank';
