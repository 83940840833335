import axios from 'axios';
import { API_BASE_URL } from '../constants';

export default class DashboardService {
	static instance;
	resource = `${API_BASE_URL}/`;
	constructor() {}

	static getInstance() {
		if (!DashboardService.instance) {
			DashboardService.instance = new DashboardService();
		}

		return DashboardService.instance;
	}

    async list(params = {}){
        const response = await axios.get(`${this.resource}dashboard`, {
            params: params,
            headers: {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`,
            },
        });
        return response.data.data
    }

}
