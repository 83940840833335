import { withNamespaces } from "react-i18next";
import { memo, useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Row,
  Col,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import ThirdpartyUsersService from "../../../../../apis/ThirdpartyUsersService";
import Select from "react-select";
import BottyImage from "../../../../../assets/images/bottyAi.png";

import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const CreateModal = ({
  t,
  modal,
  setModal,
  onComplete,
  token,
  organization,
  user,
}) => {
  const toggle = () => setModal(!modal);

  const [selectedProducts, setSelectedProducts] = useState([]);

  // Handle Delete
  const handleCreateUserMutation = useMutation({
    mutationFn: async (payload) => {
      const service = ThirdpartyUsersService.getInstance();

      return await service.create(payload);
    },
    onSuccess: () => {
      onComplete(true);
      toast(t("Third party successfully loaded."), {
        type: "success",
      });
    },
    onError: () => {
      toast(t("An error occurred while creating third party."), {
        type: "error",
      });
    },
  });

  const handleFetchCountriesListQuery = useQuery({
    queryKey: ["3rd-party-management-list-countries"],
    queryFn: async () => {
      const service = ThirdpartyUsersService.getInstance();
      return await service.fetchCountries();
    },
    enabled: false,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      toast(t("An error occurred while fetching softwares."), {
        type: "error",
      });
    },
  });

  const handleSubmit = (e, values) => {
    values.token = token;

    values.languages = organization.languages.map((lang) => {
      return {
        ...lang,
        language: lang.language.id,
        module: lang.module.id,
        id: undefined,
        language_code: undefined,
        is_default: undefined,
        isDefault: lang.is_default,
      };
    });

    values.isPartner = 0;

    values.partnerAnalysts = [user.id.toString()];

    values.partner = Number.parseInt(organization.id);

    values.hasDashboard = 0;

    values.subscriptionType = "test";

    values.domainUrl = values.name.toString().replace(/ /g, "-");

    values.product = selectedProducts?.value;

    handleCreateUserMutation.mutate(values);
  };

  useEffect(() => {
    if (modal) {
      handleFetchCountriesListQuery.refetch();
      setSelectedProducts([]);
    }
  }, [modal]);

  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <ModalHeader className="border-0" toggle={toggle}></ModalHeader>
        <ModalBody>
          <div
            style={{
              display: "flex",
              flexFlow: "column wrap",
              justifyContent: "center",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                src={BottyImage}
                style={{ maxWidth: "400px", maxHeight: "400px" }}
                alt="Botty"
              />
            </div>

            <div className="mt-3" style={{ width: "80%", margin: "auto" }}>
              <h5>{t("Hi, I am Botty") + "!"}</h5>
              <p>
                {t(
                  "Before you start you need to know that I can help you with completing this process faster!"
                )}
                <br></br>
                {t("All I need is your company's name and website")}
                <br></br>
                {t("May I help you now?")}
              </p>

              <AvForm
                className="needs-validation"
                onValidSubmit={handleSubmit}
                id="company_details"
              >
                <Row>
                  <Col sm={12} md={6} className="mb-3">
                    <Label className="form-label" htmlFor="company-name">
                      {t("Company Name")}
                    </Label>
                    <AvField
                      name={"name"}
                      placeholder=""
                      type="text"
                      errorMessage={t("This field cannot be blank")}
                      className="form-control"
                      validate={{
                        required: { value: true },
                      }}
                      id="company-name"
                    />
                  </Col>

                  <Col sm={12} md={6} className="mb-3">
                    <Label className="form-label" htmlFor="company-name">
                      {t("Website")}
                    </Label>
                    <AvField
                      name={"website"}
                      placeholder=""
                      type="text"
                      errorMessage={t("This field cannot be blank")}
                      className="form-control"
                      validate={{
                        required: { value: true },
                      }}
                      id="website"
                    />
                  </Col>
                </Row>

                <Row
                  style={{
                    display: "flex",
                    flexFlow: "row wrap",
                    justifyContent: "flex-end",
                  }}
                  className="mt-2"
                >
                  <Col md="6"></Col>
                  <Col
                    md="6"
                    style={{
                      display: "flex",
                      flexFlow: "row wrap",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Link to={"/admin/3rd-party/suppliers/create"}>
                      <Button color="primary" className="me-1" outline>
                        {t("Not Now")}
                      </Button>
                    </Link>

                    <Button color="primary">{t("Yes, Help Me")}</Button>
                  </Col>
                </Row>
              </AvForm>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

const mapStatetoProps = (state) => {
  const { token } = state.Login;
  const { Organization } = state;
  return {
    user: state.Login.user,
    token,
    organization: Organization,
  };
};

export default withNamespaces()(
  withRouter(connect(mapStatetoProps, {})(memo(CreateModal)))
);
