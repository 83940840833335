import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import Checkbox from "./checkbox";
import { withNamespaces } from "react-i18next";

const styles = StyleSheet.create({
  section: {
    margin: 10,
    padding: 50,
    flexGrow: 1,
    position: "relative",
    width: "100%",
    height: "100%",
  },
  sectionTitle: {
    fontWeight: 600,
    marginBottom: 10,
    fontSize: 13,
  },
  subsectionTitle: {
    fontWeight: "bold",
    fontSize: 11,
    left: 10,
    marginTop: 10,
    marginBottom: 8,
  },
  table: {
    display: "table",
    width: "auto",
    marginTop: 10,
    marginBottom: 10,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
  },
  tableRow: {
    flexDirection: "row",
    borderBottom: "1px solid #000"
  },
  tableLastRow : {
    borderBottom: 'none'
  },
  tableColHeader: {
    width: "50%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundColor: "#fff",
    borderRight: '1px solid #000',
  },
  fontBold: {
    fontWeight: "600"
  },
  tableCol: {
    width: "50%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    borderRight: '1px solid #000',
    paddingTop: 2,
    paddingBottom: 2
  },
  borderRightNone : {
    borderRight: 'none'
  },    
  tableCell: {
    margin: 5,
    fontSize: 8,
    flexDirection: "row",
    alignItems: "center",
    flexGrow: 1,
    flexShrink: 1, 
    flexBasis: 0, 
    maxWidth: "100%", 
    wordWrap: "break-word",
    whiteSpace: "normal",
    overflow: "hidden"
  },
  text: {
    fontSize: 10,
    marginBottom: 10,
  },
});

const PdfTable = ({ technicalMeasures, organizationalMeasures, t }) => {
    const longestListCount = technicalMeasures?.length > organizationalMeasures?.length ? technicalMeasures?.length : organizationalMeasures?.length;

    const measures = [];

    for(let i = 0; i < longestListCount; i++){
        const rowStyle = {
            ...styles.tableRow,
            ...(((i + 1) >= longestListCount) && {...styles.tableLastRow})
        }

        const techMeasure = technicalMeasures[i];
        const orgMeasure = organizationalMeasures[i];

        measures.push(
            <View style={rowStyle} key={i}> 
                <View style={styles.tableCol}>
                    { techMeasure && (
                        <>
                            <Checkbox isChecked={techMeasure?.is_checked} />
                            <Text style={styles.tableCell}>{t(techMeasure.title)}</Text>
                        </>
                    ) }
                </View>
                <View style={{
                    ...styles.tableCol,
                    ...styles.borderRightNone
                }}>
                    {orgMeasure && (
                        <>
                            <Checkbox isChecked={orgMeasure?.is_checked} />
                            <Text style={styles.tableCell}>
                                {t(orgMeasure?.title) || ""}
                            </Text>
                        </>
                    )}
                </View>
            </View>
        )
    }

    return (
        <View style={styles.table} break>
            <View style={styles.tableRow}>
                <View style={styles.tableColHeader}>
                    <Text style={{
                        ...styles.tableCell,
                        ...styles.fontBold
                    }}>{t("Technical measures")}</Text>
                </View>
                <View style={{
                    ...styles.tableColHeader,
                    ...styles.borderRightNone
                }}>
                    <Text style={{
                        ...styles.tableCell,
                        ...styles.fontBold
                    }}>{t("Organizational measures")}</Text>
                </View>
            </View>
            
            { measures }
        </View>
    )
};

const PageContent = (props) => {
    const { sections, sub_contractors, t } = props;
    
    return (
        <View style={styles.section}>
            {sections.map((section, index) => {
                const subsections = section.sub_sections.filter((s) => s.is_show_on_pdf);
                
                return (
                    <View key={index}>
                        <Text style={styles.sectionTitle}>
                            {`${index + 1}. ${ t(section.title)}`}
                        </Text>

                        {subsections.map((subSection, subIndex) => {
                            const technicalMeasures = subSection.measures.filter(
                                (measure) => measure.type === "technical"
                            );

                            const organizationalMeasures = subSection.measures.filter(
                                (measure) => measure.type === "organizational"
                            );

                            return (
                                <View key={subIndex}>
                                    <Text style={styles.subsectionTitle}>
                                        {`${index + 1}.${ subIndex + 1 } ${t(subSection.title)}`}
                                    </Text>

                                    <Text style={styles.text}>{t(subSection.information)}</Text>

                                    <PdfTable
                                        technicalMeasures={technicalMeasures}
                                        organizationalMeasures={organizationalMeasures}
                                        t={t}
                                    />

                                    <Text style={styles.text}>{t(subSection.description)}</Text>
                                </View>
                            );
                        })}
                    </View>
                )
            })}

            <View>
                <Text style={styles.sectionTitle}>{`${
                    sections?.length + 1
                }. List of subcontractors
                    `}</Text>

                <View style={styles.table} break>
                    <View style={styles.tableRow}>
                    <View style={styles.tableColHeader}>
                        <Text style={{
                            ...styles.tableCell,
                            ...styles.fontBold
                        }}>{"#"}</Text>
                    </View>
                    <View style={styles.tableColHeader}>
                        <Text style={{
                            ...styles.tableCell,
                            ...styles.fontBold
                        }}>{t("Area")}</Text>
                    </View>
                    <View style={styles.tableColHeader}>
                        <Text style={{
                            ...styles.tableCell,
                            ...styles.fontBold
                        }}>{t("Name")}</Text>
                    </View>
                    <View style={styles.tableColHeader}>
                        <Text style={{
                            ...styles.tableCell,
                            ...styles.fontBold
                        }}>{t("Website")}</Text>
                    </View>
                    <View style={styles.tableColHeader}>
                        <Text style={{
                            ...styles.tableCell,
                            ...styles.fontBold
                        }}>{t("GTC")}</Text>
                    </View>
                    <View style={styles.tableColHeader}>
                        <Text style={{
                            ...styles.tableCell,
                            ...styles.fontBold
                        }}>{t("Privacy Policy")}</Text>
                    </View>
                    <View style={{
                        ...styles.tableColHeader,
                        ...styles.fontBold,
                        ...styles.borderRightNone
                    }}>
                        <Text style={styles.tableCell}>{t("Address")}</Text>
                    </View>
                    </View>
                    {sub_contractors.map((contractor, index) => {
                    const rowStyle = {
                        ...styles.tableRow,
                        ...(((index + 1) >= sub_contractors?.length) && {...styles.tableLastRow})
                    }

                    return (
                        <View style={rowStyle} key={index}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{index + 1}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{t(contractor.area)}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{t(contractor.name)}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{contractor.website}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{t(contractor.g_t_c)}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>
                            {t(contractor.privacy_policy)}
                            </Text>
                        </View>
                        <View style={{
                            ...styles.tableCol,
                            ...styles.borderRightNone
                        }}>
                            <Text style={styles.tableCell}>{contractor.address}</Text>
                        </View>
                        </View>
                    );
                    })}
                </View>
            </View>
        </View>
    );
};

export default withNamespaces()(PageContent);
