import { withNamespaces } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';
import QuestionnaireDesignerService from 'src/modules/3rd-party-management/apis/QuestionnaireDesignerService';
import { toast } from "react-toastify";
import { connect } from 'react-redux';

import { QuestionnaireDesigner } from '@smartintegrity/questionnaire';
import { UncontrolledAlert, Col, Container, Row, Tooltip } from 'reactstrap';
import PageHeader from 'src/modules/3rd-party-management/components/page-header';
import '@smartintegrity/questionnaire/dist/index.jsx.css';

const TPDDQuestionnaireDesigner = ({
    t,
    lng,
	Organization
}) => {
    const questionnaireID = 'tpdd';

    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

    const {
        isFetching: fetchingQuestionnaireDetial,
        isLoading: loadingQuestionnaireDetail,
        data: questionnaireDetail
    } = useQuery({
        queryKey: ["3rd-party-admin-questionaire-details", questionnaireID],
        queryFn: async () => {
          const service = QuestionnaireDesignerService.getInstance();
    
          return await service.fetchQuestionnaireDetail(questionnaireID);
        },
        cacheTime: 0,
        enabled: true,
        refetchOnWindowFocus: false,
        onError: (error) => {
          if (process.env.NODE_ENV === 'development') console.error(error);
    
          toast(t("An error occurred while fetching questionnaire detail."), {
            type: "error",
          });
        }
    });

    const {
        mutate      :   updateQuestionnaire,
        isLoading   :   updatingQuestionnaire   
    } = useMutation({
        mutationFn: async (payload) => {
          const service = QuestionnaireDesignerService.getInstance();
    
          return await service.updateQuestionnaire(Organization.id, questionnaireID, payload);
        },
        onSuccess: () => {
            toast(t("Questionnaire saved successfully."), {
                type: "success",
            });
        },
        onError: () => {
            toast(t("An error occurred while saving the questionnaire."), {
                type: "error",
            });
        }
    });

    const handleSaveQuestionnaire = (elements) => {
        const result = {
            questions: [],
            language: lng
        }

        let questionSort = 0;

        elements.forEach((page) => {
            const {
                image: pageImage,
                title: pageTitle,
                elements: pageElements
            } = page;

            (pageElements || []).forEach((element) => {
                const questions = element.elementType === 'question' ? [element] : (element.elements || [])
                const subSection = element.elementType === 'panel' ? element.title : undefined
            
                questions.forEach((question) => {
                    const {
                        options
                    } = question

                    result.questions.push({
                        title: question.title,
                        sortIndex: questionSort++,
                        isMandatory: !!question.isMandatory,
                        type: question.questionType,
                        section: pageTitle,
                        subSection: subSection,
                        icon: pageImage,
                        options: !options?.length ? undefined : options.map((option) => {
                            const  optionsChildren =  (option?.children || []).map((optionChild) => {
                                return {
                                    title: optionChild.title,
                                    type: optionChild.questionType,
                                    children: (optionChild?.options || []).map((childOption) => {
                                        return {
                                            title: childOption.title,
                                            type: optionChild.questionType
                                        }
                                    })
                                    // isMandatory: optionChild.isMandatory
                                }
                            })

                            return {
                                type: question.questionType,
                                title: option.title,
                                children: !optionsChildren.length ? undefined : optionsChildren
                            }
                        })
                    })
                })
            })
        }) 

        updateQuestionnaire(result)
    }

    const questionnaireDefaultValue = useMemo(() => {
        if(!questionnaireDetail) return [];

        const groupedQuestionsBySection = [];

        const findSection = (sectionName) => {
            return groupedQuestionsBySection.find((section) => {
                return section.baseTitle === sectionName;
            });
        }

        for(let i = 0; i < questionnaireDetail.length; i++){
            const question = questionnaireDetail[i];
            let section = findSection(question.section || '');
            const q = {
                elementType :   'question',
                baseTitle   :   question.title,
                title       :   t(question.title),
                questionType:   question.type,
                placeholder :   question.title,
                isMandatory :   !!question.is_mandatory,
                isDeletable :   !!question.is_deletable,
                options     :   (question?.options || []).map((option) => {
                    return {
                        baseTitle   :   option.title,
                        title       :   t(option.title),
                        children    :   (option?.children || []).map((child) => {
                            return {
                                baseTitle   :   child.title,
                                title       :   t(child.title),
                                questionType:   child.type,
                                isMandatory :   false,
                                isDeletable :   true,
                                options     :   (child?.children || []).map((childOption) => {
                                    return {
                                        baseTitle   :   childOption.title,
                                        title       :   t(childOption.title)
                                    }
                                })
                            }
                        })   
                    }
                }) 
            }

            if(!section){
                section = {
                    elementType :   'page',
                    baseTitle   :   question.section || '',
                    title       :   t(question.section || ''),
                    image       :   question?.icon,
                    elements    :   []
                }
                groupedQuestionsBySection.push(section);
            }

            if(question.sub_section){
                let subSection = section.elements.find((el) => {
                    return el.baseTitle === question.sub_section
                })

                if(!subSection){
                    subSection = {
                        elementType: 'panel',
                        baseTitle: question.sub_section,
                        title: t(question.sub_section),
                        elements: []
                    }

                    section.elements.push(subSection)
                }

                subSection.elements.push(q)
            }
            else{
                section.elements.push(q);
            }
        }

        return groupedQuestionsBySection;
    }, [ questionnaireDetail ])

    return (
        <div className='page-content'>
            <Container fluid>
                <PageHeader 
                    title={
                        <>
                            {t('Questionnaire') + " "}
                            <i className="fa fa-info-circle ml-2"
                                aria-hidden="true"
                                id="tooltipTarget"
                                style={{ cursor: 'pointer' }}
                            />
                            {/* Tooltip for English-only message */}
                            <Tooltip
                                placement="right"
                                isOpen={tooltipOpen}
                                target="tooltipTarget"
                                toggle={toggleTooltip}
                            >
                                {t("The questionnaire designer is only editable in English.")}
                            </Tooltip>
                        </>
                    } />

                {lng !== 'en' && (
                    <Row>
                        <Col sm='12'>
                            <UncontrolledAlert dis color="warning" className="d-flex justify-content-start">
                                <div className="d-flex align-items-center me-2">
                                    <i className="ri-alert-line" style={{fontSize: '32px'}}/>
                                </div>
                                <div className="d-flex align-items-start justify-content-center flex-column">
                                    <strong>{t('Language Restriction Notice!')}</strong>
                                    {t('You can only make changes to the questionnaire designer in English. In non-English languages, modifications are restricted to editing titles and sorting elements.')}
                                </div>
                            </UncontrolledAlert>
                        </Col>
                    </Row>
                )}

                <Row className='mb-3'>
                    <Col sm='12'>
                        <QuestionnaireDesigner 
                            savingInprocess={ updatingQuestionnaire }
                            isLoading={ fetchingQuestionnaireDetial || loadingQuestionnaireDetail }
                            onSaveButtonClicked={ handleSaveQuestionnaire } 
                            defaultModel={ questionnaireDefaultValue }
                        />
                    </Col>
                </Row>
            </Container>
        </div>
    )
};

const mapStatetoProps = (state) => {
	const { token, user } = state.Login;
	const { Organization, App } = state;
	
	return {
		token,
		user,
		Organization,
		App
	};
};

export default withNamespaces()(
	connect(mapStatetoProps, {
	})(
        TPDDQuestionnaireDesigner
    )
);