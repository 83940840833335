import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from "react-toastify";
import Questionar from 'src/modules/3rd-party-management/components/questionar';
import { connect } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';

import {
	confirmPromptMakeReadyToShow,
	confirmPromptHide,
	confirmPromptUpdateAlertProps,

	moduleTPDDUpdateQuestionnaireLatestPayload,
	moduleTPDDUpdateQuestionnaireUnsavedStepsCount,
	moduleTPDDSaveQuestionnaireCurrentState,
	moduleTPDDUpdateQuestionnaireAllQuestionsAnsweredState,
	moduleTPDDUpdateQuestionnaireReportCaseId
} from 'src/store/actions';

const FillQuestionnaire = ({
    t,
    token, 
    user,
    onValidSubmit,
    questions,
	moduleTPDDQuestionnaire	:	questionnaireLatestPayload,

	updateQuestionnaireLatestPayload,
	setUnsavedStepsCount,
	saveQuestionnaireCurrentState,
    setAllQuestionsAnswered,

	exitConfirmPromptOptions, 
	makeConfirmPromptReadyToShow, 
	hideConfirmPrompt
}) => {
	const maxUnsavedSteps = 7;

    const initStatus = useRef(false);

	const {
		finalRequest,
		unSavedStepsCount,
		allQuestionsAnswered,
		reportCaseId,
		latestSaveRequestStatus
	} = questionnaireLatestPayload;

    const [ questionareSettings, setQuestionareSettings ] = useState({
		confirmFilledInputsForm	:	{
			enabled	:	false
		},
		buttons	:	{
			submit	:	{
				enabled	:	false,
				title	:	"Next"
			},
			back	:	{
				enabled	:	false,
				title	:	"Back"
			}
		}
	});

	useEffect(() => {
		if(allQuestionsAnswered){
			setQuestionareSettings({
				...questionareSettings,
				buttons : {
					...questionareSettings.buttons,
					submit: {
						...questionareSettings.buttons.submit,
						title	:	'Next',
						enabled : 	true
					}
				}
			});
		}
	}, [ allQuestionsAnswered ]);

	useEffect(() => {
		if(questionnaireLatestPayload.payload){
            setUnsavedStepsCount(unSavedStepsCount + 1);

			if(unSavedStepsCount >= maxUnsavedSteps || (finalRequest && unSavedStepsCount > 0)){
				saveQuestionnaireCurrentState();
			}
		}
	}, [ questionnaireLatestPayload.payload ]);

	useEffect(() => {
		if(unSavedStepsCount > 0){
			if(!exitConfirmPromptOptions.readyToShow){
				makeConfirmPromptReadyToShow({
					title       : (
						<div>
							<Spinner className="me-2" animation="border" variant="info" size="sm"/>
							{t('Saving the current state')}...
						</div>
					),
					message     : t("please don't close or refresh the page"),
					alertProps  : {
						...exitConfirmPromptOptions.alertProps,
						confirmBtn	:	{
							...exitConfirmPromptOptions.alertProps.confirmBtn,
							enabled	:	false
						},
						cancelBtn	:	{
							...exitConfirmPromptOptions.alertProps.cancelBtn,
							enabled	:	false
						}
					}
				});
			}
		}
	}, [ unSavedStepsCount ]);

	useEffect(() => {
		if(finalRequest){
            let currentTitle = 'Next'
            if(latestSaveRequestStatus === 'sending'){
                currentTitle = 'Please wait...';
            }

            setQuestionareSettings({
                ...questionareSettings,
                buttons : {
                    ...questionareSettings.buttons,
                    submit: {
                        ...questionareSettings.buttons.submit,
                        title	:	currentTitle
                    }
                }
            });
		}
	}, [ finalRequest, latestSaveRequestStatus ]);

	useEffect(() => {
		if(exitConfirmPromptOptions.show){
			saveQuestionnaireCurrentState();
		}
	}, [ exitConfirmPromptOptions.show ]);

	useEffect(() => {
		if(latestSaveRequestStatus !== 'unknown'){
			switch(latestSaveRequestStatus){
				case 'succeed':
						if(exitConfirmPromptOptions.show){
							exitConfirmPromptOptions.callbackFunc && exitConfirmPromptOptions.callbackFunc(true);
							hideConfirmPrompt();

							toast(t('Questionnaire saved successfully.'), {
								type: 'success',
							});
						}
					break;

				case 'failed':
						if(exitConfirmPromptOptions.show){
							hideConfirmPrompt();
						}

						toast(t('An error occurred while saving questionnaire!'), {
							type: 'error',
						});
					break;

				default:
			}
		}
	}, [ latestSaveRequestStatus ]);

    return (
        <Questionar 
            questionnaireId='tpdd'
            questionnaireType='short_tpdd_bafa'
            loading={ false }
            questions={ questions }
            onValidSubmit={ (payload) => onValidSubmit && onValidSubmit({
				reportCaseId	:	reportCaseId,
                questions		: 	questions,
                answers   		:	payload
            })}
            onChange={ ({
				payload,
				isCompleted
			}) => {
                if(!initStatus.current){
                    initStatus.current = true

                    if(isCompleted){
                        setAllQuestionsAnswered(true)
                    }
                    return;
                }

				updateQuestionnaireLatestPayload({
					payload			:	payload,
					finalRequest	:	isCompleted,
				});
			}}
            user={{
                token: token,
                ...user
            }}
            settings={ questionareSettings }
            preFilledAnswers={ questions }
        />
    )
};

const mapStatetoProps = (state) => {
	const { token, user } = state.Login;
	const { Organization, App, moduleTPDDQuestionnaire, ExitConfirmPrompt } = state;
	
	return {
		token,
		user,
		Organization,
		App,
		moduleTPDDQuestionnaire,
		exitConfirmPromptOptions	:	ExitConfirmPrompt
	};
};

export default withNamespaces()(
    withRouter(
        connect(mapStatetoProps, {
            updateQuestionnaireLatestPayload	:	(payload) => moduleTPDDUpdateQuestionnaireLatestPayload(payload),
            setUnsavedStepsCount				:	(payload) => moduleTPDDUpdateQuestionnaireUnsavedStepsCount({
                count	:	payload
            }),
            saveQuestionnaireCurrentState		:	(payload) => moduleTPDDSaveQuestionnaireCurrentState(payload),
            setAllQuestionsAnswered				:	(payload) => moduleTPDDUpdateQuestionnaireAllQuestionsAnsweredState({
                status	:	payload
            }),
            setReportCaseId						:	(payload) => moduleTPDDUpdateQuestionnaireReportCaseId({
                reportCaseId	:	payload
            }),
            makeConfirmPromptReadyToShow  		: 	(payload) => confirmPromptMakeReadyToShow(payload),
            hideConfirmPrompt             		: 	() => confirmPromptHide(),
            updateConfirmPromptAlertProps 		: 	(payload) => confirmPromptUpdateAlertProps(payload)
        })(
            FillQuestionnaire
        )
    )
);