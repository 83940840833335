import axios from "axios";
import { API_BASE_URL, API_URL_GET_RISK_AI_CLASS } from "../constants";

export default class DataProtectionNewsService {
  static instance;
  resource = `${API_BASE_URL}/news`;

  constructor() {}

  static getInstance() {
    if (!DataProtectionNewsService.instance) {
      DataProtectionNewsService.instance = new DataProtectionNewsService();
    }

    return DataProtectionNewsService.instance;
  }

  async getClass(payload) {
    try {
      const response = await axios.post(
        API_URL_GET_RISK_AI_CLASS,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
            )}`,
          },
        }
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }  

  async latestNewsList(params) {
    const response = await axios.get(`${this.resource}/list`, {
      // params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
        )}`,
      },
    });

    return response.data.data;
  }
  


  async list(softwareId, pageIndex, pageSize, filters) {
    const response = await axios.get(`${this.resource}/list?softwareId=${softwareId}`, {
      params: {
        pageSize: pageSize,
        pageIndex: pageIndex,
        ...filters,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
        )}`,
      },
    });

    return response.data.data;
  }

  async details(id) {
    const response = await axios.get(`${this.resource}/${id}/detail`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
        )}`,
      },
    });
    
    return response.data.data;
  }

  async toggleFlag(newsId) {
    try {
      const response = await axios.put(
        `${this.resource}/${newsId}/toggle_flag`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
            )}`,
          },
        }
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async initial(params) {
    const response = await axios.get(`${this.resource}/tags/initial`, {
      // params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
        )}`,
      },
    });

    return response.data.data;
  }

  async listOfTags(params) {
    const response = await axios.get(`${this.resource}/tags/list`, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
        )}`,
      },
    });

    return response.data.data;
  }

  async updateTags(payload) {
    try {
      const response = await axios.post(
        `${this.resource}/edit_tags`,
        { tags: payload.tags },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
            )}`,
          },
        }
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }
}
