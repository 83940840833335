import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { withNamespaces } from "react-i18next";
import { toast } from "react-toastify";
import TableFilter from "src/modules/3rd-party-management/components/table-filter";
import DateUtils from "src/services/utils/DateUtils";
import SupplierHistoryLogService from "src/modules/3rd-party-management/apis/SupplierHistoryLogService";
import {
    HistoryLogSections,
    HistoryLogActions
} from "src/modules/3rd-party-management/constants";

const FilteringComponent = ({
    t,
    supplierId,
    onChange
}) => {
    const [ tableFilters, setTableFilters ] = useState({
        fromDate    :   null,
        toDate      :   null,
        user        :   null,
        sections    :   [],
        actions     :   []
    });

    const [ avActions, setAvActions ] = useState([]);

    const dateUtils = new DateUtils();

    const supplierUsersQuery = useQuery({
		queryKey: ['3rd-party-management-supplier-users-list'],
		queryFn: async () => {
			const service = SupplierHistoryLogService.getInstance();

            return await service.fetchUsers(supplierId, {});
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: () => {
			toast(t('An error occurred while fetching supplier users list.'), {
				type: 'error',
			});
		},
	});

    const tableFilterTabs = useMemo(() => {
        const sectionsList = (Object.keys(HistoryLogSections).map((section) => {
            return {
                id: section,
                title: t(HistoryLogSections[section])
            }
        }));

        return [
            {
                type: 'date',
                name: 'filter-date',
                title: 'Date',
                icon: <i className="ri-calendar-event-line" />,
                defaultValue: {
                    from: tableFilters['createdAt[from]'],
                    to: tableFilters['createdAt[to]']
                }
            },
            {
                type: 'radio_list',
                name: 'user',
                title: 'User',
                icon: <i className="ri-user-line" />,
                isLoading: supplierUsersQuery.isFetching || supplierUsersQuery.isLoading,
                defaultValue: tableFilters?.user,
                data: supplierUsersQuery.data || [],
                searchInListOption: {
                    enabled: true,
                    remote: false
                },
                formatter: (item) => {
                    return `${item.first_name} ${item.last_name}` 
                }
            },
            {
                type: 'check_list',
                name: 'sections',
                title: 'Sections',
                icon: <i className="ri-function-line" />,
                defaultValue: tableFilters?.sections,
                data: sectionsList,
                searchInListOption: {
                    enabled: true,
                    remote: false
                },
                onFilterChange: (currentSections) => {
                    const actionsList = [];

                    if(Array.isArray(currentSections) && currentSections.length > 0){
                        currentSections.forEach((section) => {
                            const sectionActions = HistoryLogActions[section];

                            Object.keys(sectionActions).forEach((actionKey) => {
                                if(actionsList.findIndex((aItem) => aItem.id === actionKey) < 0){
                                    actionsList.push({
                                        id: actionKey,
                                        title: sectionActions[actionKey]
                                    })
                                }
                            });
                        });
                    }

                    setAvActions(actionsList);
                },
                formatter: (item) => {
                    return item.title
                }
            },
            {
                type: 'check_list',
                name: 'actions',
                title: 'Actions',
                icon: <i className="ri-run-line" />,
                defaultValue: tableFilters?.actions,
                data: avActions,
                searchInListOption: {
                    enabled: true,
                    remote: false
                },
                formatter: (item) => {
                    return item.title
                }
            }
        ]
    }, [
        tableFilters,
        supplierUsersQuery,
        avActions,
        t
    ]);

    const onTableFilterOkButtonClicked = (filters) => {
        if (filters) {
            const result = {}

            if ('filter-date' in filters) {
                result['fromDate'] = dateUtils.convertDateToDate(
                    new Date(filters['filter-date']?.from),
                    'YYYY-MM-DD 00:00:00'
                )

                result['toDate'] = dateUtils.convertDateToDate(
                    new Date(filters['filter-date']?.to),
                    'YYYY-MM-DD 23:59:59'
                )
            }

            if ('user' in filters) {
                result.user = filters.user !== 'all' ? filters.user : undefined
            }

            result.sections = filters.sections.length > 0 ? filters.sections : undefined

            result.actions = filters.actions.length > 0 ? filters.actions : undefined
            
            setTableFilters({
                ...tableFilters,
                ...result
            })
        } else {
            setTableFilters({
                fromDate    :   null,
                toDate      :   null,
                user        :   null,
                sections    :   [],
                actions     :   []
            })
        }
    }

    useEffect(() => {
        onChange && onChange(tableFilters)
    }, [ tableFilters ]);

    return (
        <TableFilter
            className="me-2"
            defaultActiveTab='filter-date'
            tabs={tableFilterTabs}
            onOkButtonClicked={
                onTableFilterOkButtonClicked
            }
        />
    )
}

export default withNamespaces()(FilteringComponent);