import React, { useEffect, useRef, useState } from "react";
import { withNamespaces } from "react-i18next";
import { Button, Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import Spinner from 'react-bootstrap/Spinner';

import { useQuery } from "@tanstack/react-query";

import SupplierHistoryLogService from "src/modules/3rd-party-management/apis/SupplierHistoryLogService";

import { toast } from "react-toastify";

import {
    HistoryLogActions,
    HistoryLogSections,
    HistoryLogRoles
} from "../../../../../../constants/Common";

import {
    INTERNATIONAL_DATE_TIME_FORMAT
} from "src/common/constants";

import DateUtils from "src/services/utils/DateUtils";

const DetailsModal = ({
    t,
    supplierId,
    logId,
    onClose
}) => {
    const [ logData, setLogData ] = useState(null);

    const [ showModal, setShowModal ] = useState(true);

    const dateUtils = new DateUtils();

    const handleFetchSupplierHistoryLogDetailsQuery = useQuery({
		queryKey: ['3rd-party-management-supplier-history-log-details', supplierId, logId],
		queryFn: async () => {
			const service = SupplierHistoryLogService.getInstance();

            return await service.fetchDetails(supplierId, logId);
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			toast(t('An error occurred while fetching history log details.'), {
				type: 'error',
			});
		},
	});

    const toggle = () => {
        let runCloseEvent = showModal;

        setShowModal(!showModal);

        runCloseEvent && onClose && onClose();
    };


    const renderDetailsBody = () => {
        return (
            <div className="p-5 pt-1 pb-1">
                <Row className="mb-3">
                    <Col sm="6" md="4">
                        <span className="detail-label">
                            {t('Activites')}
                        </span>
                    </Col>
                    <Col sm="6" md="8">
                        <span className="detail-value bold">
                            { t(HistoryLogSections[logData?.section]) }
                        </span>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col sm="6" md="4">
                        <span className="detail-label">
                            {t('Supplier')}
                        </span>
                    </Col>
                    <Col sm="6" md="8">
                        <span className="detail-value">
                            {logData?.supplier?.name}
                        </span>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col sm="6" md="4">
                        <span className="detail-label">
                            {t('Event ID')}
                        </span>
                    </Col>
                    <Col sm="6" md="8">
                        <span className="detail-value">
                            { logData?.secondary_id }
                        </span>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col sm="6" md="4">
                        <span className="detail-label">
                            {t('User')}
                        </span>
                    </Col>
                    <Col sm="6" md="8">
                        <span className="detail-value">
                            {logData?.user?.full_name}
                        </span>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col sm="6" md="4">
                        <span className="detail-label">
                            {t('User Role')}
                        </span>
                    </Col>
                    <Col sm="6" md="8">
                        <span className="detail-value">
                            {HistoryLogRoles[logData?.user_role]}
                        </span>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col sm="6" md="4">
                        <span className="detail-label">
                            {t('Date')}
                        </span>
                    </Col>
                    <Col sm="6" md="8">
                        <span className="detail-value light">
                            <i className="ri-time-line"></i>&nbsp;
                            { dateUtils.convertDateToDate(logData?.created_at, INTERNATIONAL_DATE_TIME_FORMAT) }
                        </span>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col sm="6" md="4">
                        <span className="detail-label">
                            {t('Browser/Location')}
                        </span>
                    </Col>
                    <Col sm="6" md="8">
                        <span className="detail-value">
                            {`${logData?.platform} - ${logData?.browser}`}
                        </span>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col sm="6" md="4">
                        <span className="detail-label">
                            {t('Description')}
                        </span>
                    </Col>
                    <Col sm="6" md="8">
                        <span className="detail-value">
                            {
                                HistoryLogActions[logData?.section] && HistoryLogActions[logData?.section][logData?.action] && (
                                    <>{t(HistoryLogActions[logData?.section][logData?.action])}</>
                                )
                            }
                        </span>
                    </Col>
                </Row>

                {
                logData?.metadata?.added_tags?.length  ?
                    <Row className="mb-3">
                        <Col sm="6" md="4">
                            <span className="detail-label">
                                {t('Added Tags')}
                            </span>
                        </Col>
                        <Col sm="6" md="8">
                            <span className="detail-value">
                                {logData?.metadata?.added_tags?.join(", ")}
                            </span>
                        </Col>
                    </Row> :
                    null
                }

                {
                    logData?.metadata?.removed_tags?.length  ?
                        <Row className="mb-3">
                            <Col sm="6" md="4">
                                <span className="detail-label">
                                    {t('Removed Tags')}
                                </span>
                            </Col>
                            <Col sm="6" md="8">
                                <span className="detail-value">
                                    {logData?.metadata?.removed_tags?.join(", ")}
                                </span>
                            </Col>
                        </Row> :
                        null
                }

            </div>
        )
    }

    useEffect(() => {
        if(handleFetchSupplierHistoryLogDetailsQuery.data){
            setLogData(handleFetchSupplierHistoryLogDetailsQuery.data);
        }
    }, [handleFetchSupplierHistoryLogDetailsQuery.data]);

    return (
        <Modal className="history-log-details-modal" isOpen={showModal} toggle={ toggle } size="lg">
            <ModalHeader>
                { t('History Log') } { logData?.secondary_id }
            </ModalHeader>

            <ModalBody>
                {
                    (handleFetchSupplierHistoryLogDetailsQuery.isFetching || handleFetchSupplierHistoryLogDetailsQuery.isLoading) ? (
                        <div className="d-flex justify-content-center align-items-center">
                            <Spinner animation="border" variant="primary" size="md"/>
                        </div>
                    ) : (
                        <>
                            { renderDetailsBody() }
                        </>
                    )
                }
            </ModalBody>

            <ModalFooter>
                <Button onClick={ toggle } size="sm" color="primary">
                    { t('Close') }
                </Button>
            </ModalFooter>
        </Modal>
    )
};

export default withNamespaces()(DetailsModal);