import { withNamespaces } from "react-i18next";
import { Text, View } from "@react-pdf/renderer";
import styles from "../../Styles";

const Products = ({
    t,
    products
}) => {
    return (
        <View style={styles.section}>
            <Text style={styles.alphListItem}>
                {`${t('b')}) ${t('List Of Products')}`}
            </Text>

            <View style={styles.table} break>
                <View style={styles.tableRow}>
                    <View style={styles.tableColHeader}>
                        <Text style={{
                            ...styles.tableCell,
                            ...styles.fontBold
                        }}>#</Text>
                    </View>
                    <View style={{
                        ...styles.tableColHeader,
                        ...styles.borderRightNone
                    }}>
                        <Text style={{
                            ...styles.tableCell,
                            ...styles.fontBold
                        }}>{t('Name')}</Text>
                    </View>
                    <View style={{
                        ...styles.tableColHeader,
                        ...styles.borderRightNone
                    }}>
                        <Text style={{
                            ...styles.tableCell,
                            ...styles.fontBold
                        }}>{t('Description')}</Text>
                    </View>
                    <View style={{
                        ...styles.tableColHeader,
                        ...styles.borderRightNone
                    }}>
                        <Text style={{
                            ...styles.tableCell,
                            ...styles.fontBold
                        }}>{t('Nace Code')}</Text>
                    </View>
                    <View style={{
                        ...styles.tableColHeader,
                        ...styles.borderRightNone
                    }}>
                        <Text style={{
                            ...styles.tableCell,
                            ...styles.fontBold
                        }}>{t('ERP ID')}</Text>
                    </View>
                </View>

                {products.map((product, i) => {
                    const rowStyle = {
                        ...styles.tableRow,
                        ...(((i + 1) >= products.length) && {...styles.tableLastRow})
                    }

                    return (
                        <View style={rowStyle} key={i}> 
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {i + 1}
                                </Text>
                            </View>

                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {product.name?.name}
                                </Text>
                            </View>

                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {product?.description}
                                </Text>
                            </View>

                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {product?.name?.naceCode}
                                </Text>
                            </View>

                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                --- 
                                </Text>
                            </View>
                        </View>
                    )
                })}
            </View>
        </View>
    )
};

export default withNamespaces()(Products);

