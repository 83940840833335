import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import { Card, CardBody, CardTitle, Tooltip } from "reactstrap";
import { withNamespaces } from "react-i18next";
class ColumnWithDataLabels extends Component {
  constructor(props) {
    super(props);
    this.state = {
      series: [],
      options: {},
      caseTypeTooltip: false,
    };
  }
  render() {
    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <div className="mb-4">
              <CardTitle>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>{this.props.t("Case type")}</div>
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    id="infoIconCaseType"
                  />
                  <Tooltip
                    placement="top"
                    isOpen={this.state.caseTypeTooltip}
                    target="infoIconCaseType"
                    toggle={() =>
                      this.setState({
                        caseTypeTooltip: !this.state.caseTypeTooltip,
                      })
                    }
                  >
                    {this.props.t(
                      "This chart observes the number of reports that have been in meetings, anonymous and confidential"
                    )}
                  </Tooltip>
                </div>
              </CardTitle>
            </div>
            <div className="mb-3">
              <ReactApexChart
                options={this.state.options}
                series={this.state.series}
                type="bar"
                height={350}
              />
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
  componentDidUpdate(prevProps) {
    if (prevProps.options !== this.props.options || prevProps.t !== this.props.t ) {
      const titles = [];
      const series = [];

      // console.log(this.props.options);

      this.props.options &&
        this.props.options.titles?.length &&
        this.props.options.titles.map((item) =>
          titles.push(this.props.t(item))
        );
      this.props.options &&
        this.props.options.percents?.length &&
        this.props.options.percents.map((item) => series.push(parseInt(item)));

      // console.log("series: ", series);

      this.setState({
        series: [
          {
            data: series,
          },
        ],
        options: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              distributed: true,
            },
          },
          dataLabels: {
            enabled: true,
            position: "center",
            formatter: function (val) {
              return val + "%";
            },
            style: {
              fontSize: "12px",
            },
          },

          colors: ["#DC143C", "#00008B", "#9400D3"],
          grid: {
            borderColor: "#f1f1f1",
          },
          xaxis: {
            position: "top",
            categories: titles,

            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          tooltip: {
            enabled: false,
            theme: "light",
            followCursor: true,
          },
        },
      });
    }
  }
}

export default withNamespaces()(ColumnWithDataLabels);
