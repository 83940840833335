import ReportApiProvider from "../../../apis/ReportApiProvider";

import UrlUtils from "../../utils/UrlUtils";

export default class InternalCommentsManager {
  urlUtils = new UrlUtils();

  constructor(module) {
    this.reportApi = new ReportApiProvider(module);
  }

  async getInternalCommentsSearchAnalyst(data) {
    const accessToken = localStorage.getItem("LOCAL_STORAGE_KEY_ACCESS_TOKEN");
    // let result = [];
    const result = await this.reportApi.getInternalCommentsSearchAnalyst(
      accessToken,
      data
    );
    console.log(result, "step: 2");
    return result;
  }

  getMentionsId(commentContent) {
    let myMention = commentContent.entityMap;

    let element = [];

    for (let i = 0; i < Object.keys(myMention).length; i++) {
      element.push(myMention[i].data.url);
    }
    return element;
  }
}
