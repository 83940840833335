
import AllSuppliersList from './All';
import OwnSuppliersList from './Own';

const List = ({
    suppliersType
}) => {
    if(suppliersType === 'own'){
        return <OwnSuppliersList />;
    }

    return <AllSuppliersList />;
};

export default List;