import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { Col, Container, Row } from "reactstrap";
import Breadcrumbs from "src/components/Common/Breadcrumb";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import TPDDCompanyInformationCustomization from "./components/company-information";
import TPDDEmailIntroCustomization from "./components/email-intro.jsx";
import TPDDCompanyFontsAndDisplayCustomization from "./components/fonts-and-display";
import TPDDDPOCustomization from "./components/dpo";
import TPDDCompanyLogoCustomization from "./components/logo";
import TPDDCompanyBackgroundCustomization from "./components/background";
import TPDDIntroCustomization from "./components/intro";
import CustomizationService from "src/modules/3rd-party-management/apis/CustomizationService";
import { getCurrentLanguage } from "src/i18n";
import axios from "axios";
import { API_URL_FETCH_COUNTRIES } from "src/modules/3rd-party-management/constants";

const TPDDAdministrationCustomization = (props) => {
  const { t, token } = props;
  const [locations, setLocations] = useState([]);

  /*********************************** FUNCTIONS ***************************************** */
  // Get Countries
  const getCountries = useQuery({
    queryKey: ["fetch-locations-query"],
    queryFn: async () => {
      const {
        data: { data },
      } = await axios.post(API_URL_FETCH_COUNTRIES, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const result = data?.map((country) => ({
        label: country.name,
        value: country.id,
        baseLabel: country.name
      }));
      return result || [];
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      toast(t("An error occurred while locations list."), {
        type: "error",
      });
    },
  });

  // Get Organization Details
  const fetchOrganizationDetails = useQuery({
    queryKey: ["3rd-party-fetch-organization-deatils-query"],
    queryFn: async () => {
      const service = CustomizationService.getInstance();
      const data = service.fetch();
      return data;
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    enabled: false,
    onError: (error) => {
      toast(t("An error occurred while fetching organization details."), {
        type: "error",
      });
    },
  });

  // Sort Countries By Alphabet
  const sortLocationsByLanguage = (language, locations) => {
    if (locations && Array.isArray(locations)) {
      const collator = new Intl.Collator(language, {
        sensitivity: "base",
      });
      return locations.map((location) => {
        return {
            ...location,
            label: t(location.baseLabel)
        }
      }).sort((a, b) => collator.compare(a.label, b.label));
    } else {
      return [];
    }
  };

  /*************************************** USE EFFECTS ****************************************** */
  useEffect(() => {
    setLocations(sortLocationsByLanguage(getCurrentLanguage(), locations));
  }, [t]);

  useEffect(() => {
    fetchOrganizationDetails.refetch();
  }, []);

  useEffect(() => {
    if (getCountries.data) {
      setLocations(
        sortLocationsByLanguage(getCurrentLanguage(), getCountries.data)
      );
    }
  }, [getCountries.data]);

  /*************************************** VIEW ****************************************** */
  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs
          title={t("Customisation")}
          breadcrumbItems={[
            { title: "SIP", link: "/" },
            { title: t("Customisation"), link: "#" },
          ]}
        />

        {!fetchOrganizationDetails.isFetching &&
        !fetchOrganizationDetails.isLoading ? (
          <Row>
            <div className="page-tpdd-customization">
              {/* ****************************** HEADER *************************** */}
              <div className="header-section">
                <h6>{t("Customize Your Platform")}</h6>
              </div>
              {/* ******************************** Company Information ********************** */}
              <TPDDCompanyInformationCustomization
                fetchOrganizationDetails={fetchOrganizationDetails}
                locations={locations}
                getCountries={getCountries}
              />

              {/* ******************************** DPO ********************** */}
              <TPDDDPOCustomization
                fetchOrganizationDetails={fetchOrganizationDetails}
                locations={locations}
                getCountries={getCountries}
              />

              {/* ******************************** FONT AND DISPLAY ********************** */}
              <TPDDCompanyFontsAndDisplayCustomization
                fetchOrganizationDetails={fetchOrganizationDetails}
              />

              {/* ******************************** LOGO ********************** */}
              <TPDDCompanyLogoCustomization
                fetchOrganizationDetails={fetchOrganizationDetails}
              />

              {/* ******************************** BACKGROUND ********************** */}
              <TPDDCompanyBackgroundCustomization
                fetchOrganizationDetails={fetchOrganizationDetails}
              />

              {/* ******************************** INTRO ********************** */}
              <TPDDIntroCustomization
                fetchOrganizationDetails={fetchOrganizationDetails}
              />

              {/* ******************************** EMAIL INTRO ********************** */}
              <TPDDEmailIntroCustomization
                fetchOrganizationDetails={fetchOrganizationDetails}
              />
            </div>
          </Row>
        ) : (
          <Row>
            <Col lg="12">
              <p
                style={{ width: "100%" }}
                className={`dt-field dt-skeleton`}
              ></p>
            </Col>
            <Col lg="12">
              <p
                style={{ width: "100%" }}
                className={`dt-field dt-skeleton`}
              ></p>
            </Col>
            <Col lg="12">
              <p
                style={{ width: "100%" }}
                className={`dt-field dt-skeleton`}
              ></p>
            </Col>
            <Col lg="12">
              <p
                style={{ width: "100%" }}
                className={`dt-field dt-skeleton`}
              ></p>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};

const mapStatetoProps = (state) => {
  const { token } = state.Login;
  const organization = state.Organization;
  const App = state.App;
  const modules = state.Modules;
  return { organization, token, App, modules };
};

export default withNamespaces()(
  withRouter(connect(mapStatetoProps)(TPDDAdministrationCustomization))
);
