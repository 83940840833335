import React from "react";
import axios from "axios";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { withRouter } from "react-router-dom";

import UrlUtils from "../../services/utils/UrlUtils";
import UserUtils from "../../services/utils/UserUtils";
import {
  API_URL_AZURE_CALLBACK,
} from "../../common/constants";
import {
  LOCAL_STORAGE_KEY_ACCESS_TOKEN,
} from "../../services/constants/LocalStorage";
import {
  checkLogin,
  setUserAccessToken,
  changeWorkspace,
  errorNotification,
  successNotification,
  appLoadStatusChanged,
  loginUserSuccessful,
} from "../../store/actions";
import ConsentModal from "./ConsentModal";

class AzureCallback extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      state: null,
      accessToken: null,
      sessionState: null,
      showConsentModal: false,
      message: null,
      consetnt: false,
      profile: null,
      logindata: null,
      token: null,
    };

    this.urlUtils = new UrlUtils();
    this.userUtils = new UserUtils();
    this.changeWorkspace = this.props.changeWorkspace.bind(this);
    this.appLoadStatusChanged = this.props.appLoadStatusChanged.bind(this);
  }
  render() {
    const { showConsentModal, consent, profile, loginData} = this.state;
    return (
      <div>
        <h1 className="mb-5">
          {this.state.message
            ? this.props.t(this.state.message)
            : this.props.t("Please wait")}
        </h1>
        <ConsentModal
            modal={showConsentModal}
            isTP={window.location.href.includes("third-party")}
            toggle={this.toggleConsentModal}
            consent={consent}
            setConsent={() => this.setState({ consent: !consent })}
            loginData={loginData}
            doLogin={this.doLogin}
          />
      </div>
    );
  }

  toggleConsentModal = () =>
    this.setState({ showConsentModal: !this.state.showConsentModal }, () => this.props.history.push("/login"));

  getAfterLoginRedirectRoute(profile) {
    let redirect = new URLSearchParams(this.props.location.search).get(
      "redirect"
    );

    switch (redirect) {
      // case "livechat":
      //   return this.userUtils.getUserTypeBaseRoute(profile) + "/livechat";
      default:
        return this.userUtils.getUserHomeRoute(profile);
    }
  }

  azureCallback = (accessToken = null, state = null, sessionState = null) => {
    if (!accessToken) {
      return;
    }

    this.setState({
      state,
      accessToken,
      sessionState,
    });

    document.cookie = `PHPSESSID=${state}`;

    axios
      .post(
        API_URL_AZURE_CALLBACK,
        {
            accessToken,
            state,
            code : accessToken,
            organizationId : Number.parseInt(this.urlUtils.getOrganizationId())
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((res) => {
        this.setState({
          message: "redirecting",
        });

        let token = res.data.data.token;
        let profile = res.data.data.profile;
        this.setState({
          loginData: res.data.data,
          profile: profile,
          token: token
        }, () => {
          // console.log( profile?.consentStatus)
          if(profile?.consentStatus=== false ) {
            return this.setState({showConsentModal: true});
          } else {
            return this.doLogin();
          }
        })

        // console.log("res.data.data: ", res.data.data);
        // console.log("azure callback token: ", token);
        // console.log("azure callback profile: ", profile);
        
      })
      .catch((err) => {
        console.log("err: ", err);

        if (err.response) {
          const data = err.response.data;

          this.setState({
            message:
              typeof data.message === "object"
                ? JSON.stringify(data.message)
                : data.message || data.title || data.error,
          });

          this.props.errorNotification({
            code: data.error || "unknown_error",
            message: this.props.t(
              typeof data.message === "object"
                ? JSON.stringify(data.message)
                : data.message || data.title || data.error
            ),
          });
        }
      });
  };

  doLogin = () => {
      const {profile, token} = this.state;
      localStorage.setItem(LOCAL_STORAGE_KEY_ACCESS_TOKEN, token);
      localStorage.setItem("org", this.urlUtils.getOrganizationName());
      console.log("passed ___ 1. local storage");

      this.props.setUserAccessToken(token);
      // console.log("passed ___ 2. setUserAccessToken");

      this.props.loginUserSuccessful({
        ...profile,
        needExchange: false,
        tempToken: token,
      });
      console.log("passed ___ 3. loginUserSuccessful ");

      this.setWorkspace(profile);
      console.log("passed ___ 4. setWorkspace");

      const redirectRoute = this.getAfterLoginRedirectRoute(profile);
      console.log(
        "passed ____ 5. getAfterLoginRedirectRoute: ",
        redirectRoute
      );

      if (profile.roles.includes("ROLE_EMPLOYEE")) {

        if (parseInt(profile.reportsCount) <= 0) {
          return "/employee/home";
        } else {
          return "/employee/reports";
        }
      } else {
        this.props.history.push(redirectRoute);
      }

      console.log(
        "passed ___ THE END! this.props.history.push(redirectRoute)"
      );

      localStorage.setItem("azure", true);
  }

  setWorkspace(profile) {
    // console.log("setWorkspace this.props.user: ", profile);

    const workspace = this.userUtils.getWorkspace(profile);
    this.props.changeWorkspace(workspace);
  }

  componentDidMount() {
    const hashParams = new URLSearchParams((new URL(window.location.href)).search);

    // console.log("hashParams: ", window.location.hash.slice(1))

    const state = hashParams.get("state");
    const accessToken = hashParams.get("code");
    const sessionState = hashParams.get("session_state");

    // console.log("state: ", state);
    // console.log("accessToken: ", accessToken);
    // console.log("sessionState: ", sessionState);

    if (accessToken) {
      this.azureCallback(accessToken, state, sessionState);
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.user && this.props.user) {
      console.log("user changed");
      // this.startCountDown();
    }

    if (!prevProps.token && this.props.token) {
      console.log("token changed");
    }

    if (
      this.props.user &&
      this.props.user.tempToken &&
      !this.props.user.needExchange
    ) {
      this.props.setUserAccessToken(this.props.token);
      console.log("componentDidUpdate__setUserAccessToken");

      localStorage.setItem(LOCAL_STORAGE_KEY_ACCESS_TOKEN, this.props.token);
      this.setWorkspace(this.props.user);
      console.log("componentDidUpdate__setWorkspace");

      this.props.history.push(this.userUtils.getUserHomeRoute(this.props.user));
      console.log("componentDidUpdate__this.props.history.push");
      // this.checkUrlParamThirdParty();

      console.log("test passed");
    }
  }
}

const mapStatetoProps = (state) => {
  const { Feature } = state;

  return {
    Feature,
    token: state.Login.token,
    user: state.Login.user,
  };
};

export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
      checkLogin,
      loginUserSuccessful,
      setUserAccessToken,
      changeWorkspace,
      errorNotification,
      successNotification,
      appLoadStatusChanged,
    })(AzureCallback)
  )
);
