import React, { Component } from "react";
import {
  Col,
  Label,
  Button,
  Popover,
  PopoverHeader,
  PopoverBody,
  Progress,
  Card,
  Row,
} from "reactstrap";
import { Link } from "react-router-dom";
import Dropzone from "react-dropzone";
import {
  API_URL_REPORT_UPLOAD_FILE,
  AUTH_API_DOMAIN_URL,
} from "../../../common/constants";
import axios from "axios";

class AttachmentsDropzone extends Component {
  state = {
    popover: {
      id: "dropzone-popover",
      active: false,
    },
    showProg: false,
    uploadedFiles: [],
  };

  dropzoneAcceptedFiles = {
    accepted:
      "text/plain, application/pdf, application/msword, application/vnd.ms-excel, application/vnd, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.presentationml.presentation, image/*, audio/*, video/mp4, video/x-mpegURL, video/MP2T, video/3gpp, video/quicktime, video/x-msvideo, video/x-ms-wmv",
    description: [
      `${this.props.t("")}. ${this.props.t(
        "For more information"
      )}, ${this.props.t("visit")}`,
      <a
        href="https://diss-co.tech/faq-diss-co/"
        target="_blank"
      >{` ${this.props.t("FAQ")} `}</a>,
      this.props.t("page"),
    ],
  };

  handleAcceptedFiles = (files, id) => {
    const headers =
      this.props.user && this.props.token
        ? {
            headers: {
              Authorization: `Bearer ${this.props.token}`,
            },
          }
        : {};
    this.props.setGivenState("isUploading", true);
    let count = 0;
    files.map((file) => {
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: file.size,
      });
      const formData = new FormData();

      formData.append("report_type", 1);
      formData.append("file", file);
      this.setState({ showProg: true });
      axios
        .post(API_URL_REPORT_UPLOAD_FILE, formData, headers)
        .then((response) => {
          count++;
          if (response.status === 200) {
            const fileData = response.data.data;
            this.setState({ showProg: false });
            this.props.updateFileState(fileData.id);
            this.setState({
              uploadedFiles: [
                ...this.state.uploadedFiles,
                {
                  questionId: id,
                  id: fileData.id,
                  size: fileData.size,
                  file: fileData,
                  name: file.name,
                  preview: file.preview,
                  formattedSize: file.formattedSize,
                },
              ],
            });
            if (count === files.length) {
              this.props.setGivenState("isUploading", false);
            }
          } else {
            this.props.setGivenState("isUploading", false);
            this.props.errorNotification({
              code: response.data.error,
              message: this.props.t(
                "Your file could not be uploaded. The file is corrupted or the size is bigger than 1 GB. Please check your file or contact the support."
              ),
            });
          }
        })
        .catch((error) => {
          this.setState({ showProg: false });
          this.props.setGivenState("isUploading", false);
          this.props.errorNotification({
            code: error.response.data.error,
            message: this.props.t(error.response.data.message),
          });
        });
    });

    this.setState({ selectedFiles: files });
  };

  formatFileSize = (size) => {
    if (size === 0) {
      return "0 Bytes";
    }
    const units = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(size) / Math.log(1024));
    return `${parseFloat((size / Math.pow(1024, i)).toFixed(2))} ${units[i]}`;
  };

  handleClickDeleteFiles = (file) => {
    const { files } = this.props;
    const url = AUTH_API_DOMAIN_URL + `attachment/${file.id}/delete`;
    const headers =
      this.props.user && this.props.token
        ? {
            headers: {
              Authorization: `Bearer ${this.props.token}`,
            },
          }
        : {};

    let newSelectedFiles = this.state.selectedFiles.filter(function (obj) {
      return obj.name !== file.name;
    });
    let newUploadedFiles = this.state.uploadedFiles.filter(function (obj) {
      return obj.name !== file.name;
    });

    const deletedFile = {
      file: file.name,
      report_case_id: file.id,
    };

    axios
      .post(url, deletedFile, headers)
      .then((data) => {
        let index = files.indexOf(file.id);
        if (index !== -1) {
          files.splice(index, 1);
        }
        this.setState({
          selectedFiles: newSelectedFiles,
          uploadedFiles: newUploadedFiles,
        });

        this.props.successNotification({
          code: this.props.t("Data was deleted successfully"),
          message: this.props.t("Data was deleted successfully"),
        });
      })
      .catch((error) => {
        this.props.errorNotification({
          code: error.response.data.error,
          message: this.props.t(error.response.data.message),
        });
      });
  };

  togglePopover = () => {
    this.setState((prevState) => ({
      popover: {
        ...prevState.popover,
        active: !prevState.popover.active,
      },
    }));
  };

  render() {
    const { popover, showProg, uploadedFiles } = this.state;
    const { t, id, key } = this.props;

    return (
      <Row key={key} className="mt-4">
        <Col sm="12">
          <div className="mb-3">
            <Label className="form-label" htmlFor={`question-${id}`}>
              {`${t("Attachments")} ${t("(Optional)")}`}
            </Label>
            <div style={{ position: "relative" }}>
              <Dropzone
                onDrop={(acceptedFiles) =>
                  this.handleAcceptedFiles(acceptedFiles, id)
                }
                accept={this.dropzoneAcceptedFiles.accepted}
              >
                {({ getRootProps, getInputProps }) => (
                  <div className="dropzone">
                    <div className="dz-message needsclick" {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div className="mb-3">
                        <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                      </div>
                      <h4>{t("Drop files here or click to upload")}</h4>
                    </div>
                  </div>
                )}
              </Dropzone>
              <Button
                id="dropzone-popover"
                className="button"
                type="button"
                color="link"
                style={{
                  position: "absolute",
                  right: 8,
                  bottom: 6,
                  cursor: "pointer",
                }}
                onClick={this.togglePopover}
              >
                <i className="ri-information-line" style={{ fontSize: 18 }}></i>
              </Button>
              <Popover
                placement="bottom"
                target="dropzone-popover"
                isOpen={popover.id === "dropzone-popover" && popover.active}
                toggle={this.togglePopover}
              >
                <PopoverHeader>{t("Allowed Formats")}</PopoverHeader>
                <PopoverBody>
                  <span>
                    {this.props.t(
                      "You are allowed to upload limited file types. for more information, visit"
                    )}{" "}
                  </span>
                  <a href="https://diss-co.tech/faq-diss-co/" target="_blank">
                    {this.props.t("FAQ page")}
                  </a>
                </PopoverBody>
              </Popover>
            </div>
            <br />
            <Progress
              color="info"
              striped
              animated
              value={100}
              hidden={!showProg}
            >
              {t("Please wait while the file is uploaded")}
            </Progress>
            <div className="dropzone-previews mt-3" id="file-previews">
              {uploadedFiles.map((f, i) => (
                <Card
                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                  key={i + "-file"}
                >
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col>
                        <Link to="#" className="text-muted fw-bold">
                          {f.name}
                        </Link>
                        <p className="mb-0">
                          <strong>{f.formattedSize}</strong>
                        </p>
                      </Col>
                      <Col xs={2} style={{ textAlign: "right" }}>
                        <Button
                          color="danger"
                          size="sm"
                          onClick={() => this.handleClickDeleteFiles(f)}
                        >
                          X
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Card>
              ))}
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}

export default AttachmentsDropzone;
