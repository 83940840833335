import { useState } from "react";
import BottyAI from "src/modules/data-protection/assets/images/ai-botty/bottyAi.png";
import { Col, FormGroup, Input, Label, Form } from "reactstrap";
import DataProtectionSoftwareService from "../../../../apis/DataProtectionSoftwareService";
import { useQuery, useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
// import DataProtectionSoftwareService from "../../../../apis/DataProtectionSoftwareService";

export default function GeneratedByAI(props) {
  const [providerUrl, setProviderUrl] = useState(props.defaultValues?.providerUrl || '');
  const [softwareName, setSoftwareName] = useState(props.defaultValues?.softwareName || '');
  const [inputErrors, setInputErrors] = useState([]);

  const handleValidateForm = () => {
    let isValid = true;
    const errors = {};

    // Check if softwareName is empty
    if (!softwareName.trim()) {
      errors.softwareName = props.t("Software name is required.");
      isValid = false;
    }

    // Check if providerUrl is a valid URL providerUrl.trim() && 
    if (!isValidURL(providerUrl)) {
      errors.providerUrl = props.t("Valid URL is required for provider.");
      isValid = false;
    }

    setInputErrors(errors);
    return isValid;
  };

  const addSoftwareMutation = useMutation({
    mutationFn: async (software) => {
      props.changeCurrentState("loading")
      const service = DataProtectionSoftwareService.getInstance();
      return await service.generateByAI(software);
    },
    onSuccess: (data) => {
      props.generate({softwareName, providerUrl,...data})
      props.changeCurrentState("manuallyEditted");
    },
    onError: () => {
      props.changeCurrentState("failed");
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (handleValidateForm()) {
      addSoftwareMutation.mutate({
        software_name: softwareName,
        url: providerUrl,
      });}
  };

// Function to check if a URL is valid
const isValidURL = (url) => {
  const expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
  return expression.test(url);
};

  return (
    <div style={{ textAlign: "center" }}>
      <img
        className="my-3 "
        src={BottyAI}
        width={"50%"}
        height={"50%"}
        alt="Botty"
        style={{ marginInline: "auto" }}
      />
      <h6 className="mt-4 mb-3 text-dark" style={{ fontSize: "24px" }}>
        {props.t("Adding new asset? Let me help you with that!")}
      </h6>
      <Form onSubmit={handleSubmit}>
        <div
          className="mb-4 d-flex flex-column gap-2 justify-content-center align-items-center"
          style={{ textAlign: "left" }}
        >
          <Col sm="12" md="9" lg="9">
            <FormGroup>
              <Label
                className={
                  inputErrors.softwareName
                    ? "text-danger mb-0"
                    : "mb-0 text-dark"
                }
              >
                {props.t("Name of Software:")}
              </Label>
              <Input
                type="text"
                value={softwareName}
                onChange={(e) => {
                  setSoftwareName(e.target.value);
                  setInputErrors((prevErrors) => ({
                    ...prevErrors,
                    softwareName: "",
                  }));
                }}
                invalid={!!inputErrors.softwareName}
              />
              {inputErrors.softwareName && (
                <small className="text-danger">
                  {inputErrors.softwareName}
                </small>
              )}
            </FormGroup>
          </Col>
          <Col sm="12" md="9" lg="9">
            <FormGroup>
              <Label
                className={
                  inputErrors.providerUrl
                    ? "text-danger mb-0"
                    : "mb-0 text-dark"
                }
              >
                {props.t("Page of The Software Provider (URL):")}
              </Label>
              <Input
                type="text"
                value={providerUrl}
                onChange={(e) => {
                  setProviderUrl(e.target.value);
                  setInputErrors((prevErrors) => ({
                    ...prevErrors,
                    providerUrl: "",
                  }));
                }}
                invalid={!!inputErrors.providerUrl}
              />
              {inputErrors.providerUrl && (
                <small className="text-danger">{inputErrors.providerUrl}</small>
              )}
            </FormGroup>
          </Col>
        </div>
        <div className="d-flex p-4 justify-content-end gap-2 me-5">
          <button
            className="cancel-button"
            type="button"
            onClick={() => props.toggleModal()}
          >
            {props.t("Cancel")}
          </button>
          <button className="done-button" type="submit">
            {props.t("Done")}
          </button>
        </div>
      </Form>
    </div>
  );
}
