import React, { memo, useRef } from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { StyleSheet, PDFDownloadLink } from "@react-pdf/renderer";
import PdfDocument from "../../components/PDF";
import logo from "src/modules/data-protection/assets/images/bottyAi-2.png";
import { INTERNATIONAL_DATE_FORMAT } from "src/components/constants";
import DateUtils from "src/services/utils/DateUtils";

const TopButtons = (props) => {
  const dateUtils = new DateUtils();
  const { t, handleFetchDetails, Organization, handleFetchHeaderInformation } =
    props;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        width: "Hug (109.31px)",
      }}
      className="mb-4"
    >
      {(!handleFetchDetails.isLoading || !handleFetchDetails.isFetching) &&
        handleFetchDetails?.data && (
          <Button color="primary">
            <PDFDownloadLink
              document={
                <PdfDocument
                  data={handleFetchDetails.data}
                  organization={Organization}
                  created={dateUtils.convertTimestampToOnlyDate(
                    handleFetchHeaderInformation.data?.created_at,
                    INTERNATIONAL_DATE_FORMAT
                  )}
                  updated={dateUtils.convertTimestampToOnlyDate(
                    handleFetchHeaderInformation.data?.updated_at,
                    INTERNATIONAL_DATE_FORMAT
                  )}
                />
              }
              fileName="TOM_Document.pdf"
            >
              {({ blob, url, loading, error }) =>
                loading ? t("Loading document...") : `${t("Export PDF")}`
              }
            </PDFDownloadLink>
          </Button>
        )}
    </div>
  );
};

const mapStatetoProps = (state) => {
  const { Organization } = state;

  return {
    Organization,
  };
};

export default withNamespaces()(connect(mapStatetoProps)(memo(TopButtons)));
