import { useState } from "react";
import { Tooltip } from "reactstrap";

const AvatarGroup = ({
    className,
    max,
    children
}) => {
    return (
        <div className={'avatar-group' + (className ? ` ${className}` : '')}>
            {
                children.map((child) => {
                    return <child.type {...child.props} />
                })
            }
        </div>
    );
};

const AvatarItem = ({
    id,
    src,
    alt,
    title,
    className
}) => {

    const [ showTooltip, setShowTooltip ] = useState(false);

    return (
        <div>
            {
                title && (
                    <Tooltip 
                        placement="top" 
                        isOpen={ showTooltip } 
                        target={ id } 
                        toggle={ () => setShowTooltip(!showTooltip) }>
                        { title } 
                    </Tooltip>
                )
            }
            
            <img id={ id } 
                className={'avatar-group-item' + (className ? ` ${className}` : '')} 
                src={ src } alt={ alt } 
            />
        </div>
    );
};

export {
    AvatarGroup,
    AvatarItem
};