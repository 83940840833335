import { memo, useEffect, useState } from "react";
import { Button, Card, CardBody, CardHeader, CardText, Col, Collapse, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";
import { GoTag } from "react-icons/go";
import { CiFlag1 } from "react-icons/ci";
import { API_URL_GET_RISK_AI_CLASS } from "../../../../common/constants";
import axios from "axios";
import StrongImage from "../../../../assets/images/RiskAI/Strong.svg";
import WeakImage from "../../../../assets/images/RiskAI/Weak.svg";
import ModerateImage from "../../../../assets/images/RiskAI/Moderate.svg";
import "./AIRiskDetailsModal.scss";

const AiRiskDetailsModal = ({
                              t, 
                              handleModal, 
                              showModal, 
                              // explanation, 
                              selectedRiskDetails, 
                              errorNotification, 
                              flaggedRisks, 
                              onFlagRisk, 
                              flaggedMitTasks,
                              onFlagMitTask,
                              rColor,
                              subTColor,
                            }) => {
  const token = localStorage.getItem("LOCAL_STORAGE_KEY_ACCESS_TOKEN");
  const [classFetched, setClassFetched] = useState(false);
  const [collapse, setCollapse] = useState(0);
  const [selectedClass, setSelectedClass] = useState(null);
  const [modalHeight, setModalHeight] = useState(0);                            
  // console.log("SRD", selectedRiskDetails)
  const processHtmlContent = (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');

    // Convert h1 to h2, h2 to h3, h3 to h4
    doc.querySelectorAll('h1, h2, h3').forEach(header => {
      switch (header.tagName) {
        case 'H1':
          header.outerHTML = `<h4>${header.innerHTML}</h4>`;
          break;
        case 'H2':
          header.outerHTML = `<h5>${header.innerHTML}</h5>`;
          break;
        case 'H3':
          header.outerHTML = `<h6>${header.innerHTML}</h6>`;
          break;
        default:
          break;
      }
    });

    return doc.body.innerHTML;
  };
  
  const processedExplanation = processHtmlContent(selectedRiskDetails?.description);
  const processedDetails = processHtmlContent(selectedRiskDetails?.details);

  const toggle = (id) => {
    setCollapse(id);
  };

  useEffect(async () => {
    const url = API_URL_GET_RISK_AI_CLASS;
    const {source} = selectedRiskDetails;
    try {
      let descriptions = source.map(s => `'${s.description}'`);
    let formattedDescriptions = descriptions.length > 1
      ? descriptions.slice(0, -1).join(", ") + ' and ' + descriptions[descriptions.length - 1]
      : descriptions.join("");
      const response = await axios.post(
        url,
        {
          promt: "Determine score between these risks: " + formattedDescriptions,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
  
      if(response.status === 200) {
        const res = response?.data.out;
        let parts = res.split(" "); // Splits the string by space
        let secondPart = parts[1]; 

        setSelectedClass(secondPart);
        setClassFetched(true);
      }
    } catch(err) {
      console.log(err)
      setClassFetched(false);
      return errorNotification({
        message: t("Failed to fetch class."),
      })
    }
    
  },[])
 

  useEffect(() => {
    const updateModalHeight = () => {
      const modalElement = document.getElementById('modalId'); // Replace with your modal's ID
      if (modalElement) {
        setModalHeight(modalElement.offsetHeight);
      }
    };

    window.addEventListener('resize', updateModalHeight);

    // Set initial height
    updateModalHeight();

    // Cleanup
    return () => {
      window.removeEventListener('resize', updateModalHeight);
    };
  }, []);

  function getMainLink(url) {
      if(!url){
        return;
      }
      var parsedUrl = new URL(url);
      return parsedUrl.origin; // This will return the protocol + hostname (base URL)
  }

  const {title, riskId, mitigation_tasks, source,riskType} = selectedRiskDetails;
  // let firstTwoWords = selectedRiskDetails?.title?.split(' ')?.slice(0, 2)?.join(' ');
  let firstTwoWords = riskType
  
  const scrollHeight = modalHeight - 30;
  return (
    <>
      <div>
        <Modal isOpen={showModal} toggle={handleModal} id={"modalId"} size="xl">
        <ModalHeader toggle={handleModal}>
          <Row className="w-100">
            <Col className="d-flex justify-content-start">
              <span className="category" style={{backgroundColor: rColor}}>
                <GoTag /> {t(firstTwoWords)}
              </span>
            </Col>
            <Col className="d-flex justify-content-end">
              <span 
                className={
                  flaggedRisks?.includes(riskId)
                    ? "selectedFlag"
                    : "flag"
                }
                onClick={() => onFlagRisk(selectedRiskDetails)}
              >
                <CiFlag1 />
              </span>
            </Col>
          </Row>
        </ModalHeader>

          <ModalBody>
            <Row>
              <Col lg={8}>
                <h3>{t("Description") + ":"}</h3>
                <div dangerouslySetInnerHTML={{ __html: processedDetails }}></div>
                <h3>{t("Mitigation tasks") + ":"}</h3>
                {mitigation_tasks?.map((mitTask, index) => {
                                  const taskId = mitTask.id;
                                  return (
                                    <Card
                                      style={{ marginBottom: "1rem" }}
                                      key={index}
                                    >
                                      <div>
                                        <CardHeader
                                          onClick={() => toggle(mitTask.id)}
                                          data-event={index}
                                          style={{
                                            cursor: "pointer",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            backgroundColor: subTColor
                                          }}
                                        >
                                          <span>{t(mitTask.title)}</span>

                                          <span
                                            className={
                                              flaggedMitTasks.includes(taskId)
                                                ? "selectedFlag"
                                                : "mitFlag"
                                            }
                                            onClick={() =>
                                              onFlagMitTask(mitTask, riskId)
                                            }
                                          >
                                            <CiFlag1 />
                                          </span>
                                        </CardHeader>
                                      </div>
                                      <Collapse
                                        isOpen={collapse === mitTask.id}
                                      >
                                        <CardBody>
                                          {t(mitTask.description)}
                                        </CardBody>
                                      </Collapse>
                                    </Card>
                                  );
                                })}
              </Col>
              <Col lg={4}>
              {classFetched ? 
              <Card
                className="my-2"
                outline
                style={{
                  width: '18rem',
                  border: "1px solid #5664D2",
                  overflowY: "scroll",
                  maxHeight: `${scrollHeight}px` // Using modalHeight here
                }}
                >
                  <img
                    alt="Risk Level"
                    src={selectedClass === "A" ? StrongImage : selectedClass === "B" ? ModerateImage : WeakImage}
                  />
                  <CardBody>
                    <CardText>
                      <h3 style={{color: "#1A2052", textAlign: "center", marginTop: 0, paddingTop: 0}}>{t("Source")}</h3>
                      {
                        source.map(s => {
                          return(
                            <div className="mb-4">
                              <h4 style={{textAlign: "center"}}>{s.title}</h4>
                              <p>{s.description}</p>
                              <li><a href={getMainLink(s.source)} target="_blank">{getMainLink(s.source)}</a></li>
                              <hr></hr>
                            </div>
                          )
                        })
                      }
                    </CardText>
                  </CardBody>
                </Card> : 
                  <div className="d-flex justify-content-center align-items-center" style={{ height: "100px" }}>
                    <Spinner type="grow" color="primary" />
                 </div>
                }
              </Col>
            </Row>
            
          </ModalBody>
          <ModalFooter>
            <Button variant="secondary" onClick={handleModal}>
              {t("Close")}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
};

export default memo(AiRiskDetailsModal);
