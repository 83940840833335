import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { errorNotification, successNotification } from "src/store/actions";
import { Fragment, memo, useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import CommonService from "src/modules/data-protection/apis/CommonService";
import ReportsService from "src/modules/data-protection/apis/ReportsService";
// styles
import "./style.scss";
// components
import CustomTable from "src/components/CustomTable";
import Pagination from "src/modules/data-protection/components/pagination";
import PageBody from "src/modules/data-protection/components/page-body";

import Deletion from "src/modules/data-protection/pages/software/modal/deletion";

import { withRouter } from "react-router-dom";
import DateUtils from "src/services/utils/DateUtils";
import { REPORT_STATUS_LIST } from "src/components/constants";
import TableFilter from "./table-filter";
import ReportIncidenService from "src/modules/data-protection/pages/admin/data-subject-request/components/api/ReportIncidenService";

const DataProtectionSoftwareRelatedIncidents = (props) => {
  const location = useLocation();

  const dateUtils = new DateUtils();
  const { t, assetId } = props;

  const [softwares, setSoftwares] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const [showDeletionModal, setDeletionModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [deletionComplete, setDeletionComplete] = useState(false);

  const [tableFilters, setTableFilters] = useState({
    pageIndex: 1,
    pageSize: 25,
    adminCategories: [],
    status: null,
    risk: null,
    analystAssigned: null,
    sortField :null,
    sortOrder: null
  });
  const [selectedFiltersDisplay, setSelectedFiltersDisplay] = useState({});
  const [categories, setCategories] = useState([]);

  const [users, setUsers] = useState([]);

  const tableHeader = [
    [
      {
        value: (
          <p className="text-truncate text-capitalize text-left">
            {props.t("Risk Rating")}
          </p>
        ),
        width: "5%",
      },
      {
        value: (
          <p className="text-truncate text-capitalize text-left">
            {props.t("Incident ID")}
          </p>
        ),
        width: "10%",
      },
      {
        value: (
          <p className="text-truncate text-capitalize text-center">
            {props.t("Description")}
          </p>
        ),
        width: "10%",
      },
      {
        value: (
          <p className="text-truncate text-capitalize text-center">
            {props.t("Submission Date")}
          </p>
        ),
        width: "10%",
      },
      {
        value: (
          <p className="text-truncate text-capitalize text-center">
            {props.t("Closing Date")}
          </p>
        ),
        width: "10%",
      },
      {
        value: (
          <p className="text-truncate text-capitalize text-center">
            {props.t("Assign Analyst")}
          </p>
        ),
        width: "10%",
      },
      {
        value: (
          <p className="text-truncate text-capitalize text-center">
            {props.t("Status")}
          </p>
        ),
        width: "10%",
      },
    ],
  ];

  const handleFetchCategories = useQuery({
    queryKey: ["report-incident-fetch-categories"],
    queryFn: async () => {
      const service = ReportIncidenService.getInstance();

      return await service.FetchCategories();
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      toast(props.t("An error occurred while fetching categories."), {
        type: "error",
      });
    },
  });

  const handleRenderReportCategories = (reportCategories) => {
    const result =
      categories && categories?.length > 0
        ? categories
            .filter((i) => reportCategories?.includes(i.id))
            .map((i) => props.t(i.title))
        : null;
    return result ? result.join(", ") : null;
  };

  useEffect(() => {
    if (handleFetchCategories.data) {
      setCategories(handleFetchCategories.data);
    }
  }, [handleFetchCategories.data]);

  const handleFetchIncidentListQuery = useQuery({
    queryKey: ["data-protection-incidents-list", tableFilters],
    queryFn: async () => {
      const {
        status: tableStatus,
        adminCategories,
        risk,
        analystAssigned,
      } = tableFilters;
      const transformedAdminCategories =
      (adminCategories?.length === 1 && adminCategories[0] === "all") || adminCategories?.length === 0
        ? null
        : adminCategories;
      const status =
        props.status !== null && props.status !== undefined
          ? props.status
          : tableStatus !== null
          ? tableStatus
          : undefined;
      const param = {
        ...(status !== undefined && { status }), // Add status if not null or undefined
        ...(transformedAdminCategories !== null && { adminCategories: transformedAdminCategories }),
        ...(risk !== null && { risk }), // Conditionally add risk if not null
        ...(analystAssigned !== null && { analystAssigned }), // Conditionally add analystAssigned if not null
        pageIndex: tableFilters.pageIndex,
        pageSize: tableFilters.pageSize,
        software: Number(assetId),
        ...(tableFilters.sortField && { sortField: tableFilters.sortField }),
        ...(tableFilters.sortField && { sortOrder: tableFilters.sortOrder }),
      };
      const service = ReportsService.getInstance();
      return await service.list(tableFilters.pageIndex, tableFilters.pageSize, {
        ...param
      });
    },
    cacheTime: 0,
    refetchOnWindowFocus: true,
    onError: (error) => {
      if (process.env.NODE_ENV === "development") console.error(error);

      toast(t("An error occurred while fetching incidents."), {
        type: "error",
      });
    },
  });

  const handleFetchAnalystsAndAnalystAdmins = useQuery({
    queryKey: ["fetch-supplier-Analys-Admins"],
    queryFn: async () => {
      const service = ReportIncidenService.getInstance();

      return await service.FetchAnalystsAndAnalystAdmins();
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      toast(t("An error occurred while fetching owners."), {
        type: "error",
      });
    },
  });

  const handleFetchRisks = useQuery({
    queryKey: ["fetch-risks"],
    queryFn: async () => {
      const service = ReportIncidenService.getInstance();

      return await service.FetchRiskRating();
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      toast(t("An error occurred while fetching risks."), {
        type: "error",
      });
    },
  });

  const fetchUsers = useQuery({
    queryFn: async () => {
      const service = CommonService.getInstance();

      return await service.analystAdminsAndAnalysts();
    },
    cacheTime: 0,
    refetchOnWindowFocus: true,
    onError: (error) => {
      if (process.env.NODE_ENV === "development") console.error(error);

      toast(t("An error occurred while fetching users."), {
        type: "error",
      });
    },
  });

  const handleRenderReportStatus = (statusId) => {
    const status = REPORT_STATUS_LIST.find(
      (item) => item.id === parseInt(statusId)
    );
    return (
      <div className={`${status.className} p-2`} style={{ minWidth: "78px" }}>
        {t(status.titleKey)}
      </div>
    );
  };

  const handleProvideTableData = (softwares) => {
    return softwares.map((software) => {
      return [
        {
          value: (
            <p className="text-truncate text-capitalize">
              <span
                style={{ backgroundColor: `#${software.risk}` }}
                className="risk-rate ms-5"
              ></span>
            </p>
          ),
          width: "5%",
          isRecentlyAdded: software?.isRecentlyAdded,
        },
        {
          value: (
            <Link
              to={`/admin/data-protection/incident-register/details/${software?.case_id}`}
              onClick={() => localStorage.setItem("caseId", software?.case_id)}
            >
              <p className="text-truncate text-capitalize text-left">
                {software?.secondary_case_id || ""}
              </p>
            </Link>
          ),
          width: "10%",
          isRecentlyAdded: software?.isRecentlyAdded,
        },
        {
          value: (
            <p className="text-truncate text-capitalize text-center">
              {/* {software?.admin_description  || "" } */}
              {"admin_description" in software &&
              software?.admin_description.trim()
                ? software?.admin_description.length > 70
                  ? `${software?.admin_description?.substring(0, 70)}...`
                  : software?.admin_description
                : software?.description
                ? software?.description.length > 70
                  ? `${software?.description.substring(0, 70)}...`
                  : software?.description
                : "---"}
            </p>
          ),
          width: "10%",
          isRecentlyAdded: software?.isRecentlyAdded,
        },
        {
          value: (
            <p className="text-truncate text-capitalize text-center d-flex justify-content-center">
              {dateUtils.convertTimestampToOnlyDate(
                software?.submission_date
              ) || ""}
            </p>
          ),
          width: "10%",
          isRecentlyAdded: software?.isRecentlyAdded,
        },
        {
          value: (
            <p className="text-truncate text-capitalize text-center d-flex justify-content-center">
              {software?.closing_date
                ? dateUtils.convertTimestampToOnlyDate(software?.closing_date)
                : ""}
            </p>
          ),
          width: "10%",
          isRecentlyAdded: software?.isRecentlyAdded,
        },
        {
          value: (
            <p className="text-truncate text-capitalize text-center">
              {software?.analyst_assigned.id
                ? `${software?.analyst_assigned.first_name} ${software?.analyst_assigned.last_name}`
                : ""}
            </p>
          ),
          width: "10%",
          isRecentlyAdded: software?.isRecentlyAdded,
        },
        {
          value: (
            <p className="text-truncate text-capitalize text-center">
              <div>{handleRenderReportStatus(software?.status)}</div>
            </p>
          ),
          width: "10%",
          isRecentlyAdded: software?.isRecentlyAdded,
        },
      ];
    });
  };

  const onTableFilterOkButtonClicked = (filters) => {
    let result = {};
    for (const tableFilterKey in tableFilters) {
      if (tableFilterKey in filters) {
        if (tableFilterKey === "dateFrom" || tableFilterKey === "dateTo") {
          result[tableFilterKey] =
            filters[tableFilterKey] !== null
              ? dateUtils.convertDateToDate(
                  new Date(filters[tableFilterKey]),
                  "YYYY-MM-DD"
                )
              : null;
        } else {
          result[[tableFilterKey]] = filters[tableFilterKey];
        }
      } else {
        result[[tableFilterKey]] = tableFilters[tableFilterKey];
      }
    }
    
    setTableFilters(result);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const pageIndex = searchParams.get("pageIndex");
    if (pageIndex !== null && !Number.isNaN(Number(pageIndex)))
      setTableFilters((prevFilters) => ({
        ...prevFilters,
        pageIndex: Number(pageIndex)
      }))

    const pageSize = searchParams.get("pageSize");
    if (pageSize !== null && !Number.isNaN(Number(pageSize)))
      setTableFilters((prevFilters) => ({
        ...prevFilters,
        pageSize: Number(pageSize)
      }))
  }, [location.search]);

  useEffect(() => {
    if (handleFetchIncidentListQuery.data) {
      setTotalCount(Number(handleFetchIncidentListQuery.data.itemsCount));
    }

    if (deletionComplete) {
      handleFetchIncidentListQuery.refetch();
      setDeletionComplete(false);
    }
  }, [deletionComplete, handleFetchIncidentListQuery.data]);

  useEffect(() => {
    if (fetchUsers.data && fetchUsers.data?.length) {
      const users = fetchUsers.data.map((u) => {
        return {
          value: u.id,
          label: u.first_name + " " + u.last_name,
        };
      });

      setUsers(users);
    }
  }, [fetchUsers.data]);

  useEffect(() => {
    if (handleFetchIncidentListQuery.data) {
      setSoftwares(handleFetchIncidentListQuery.data);
    }
  }, [handleFetchIncidentListQuery.data]);

  useEffect(() => {
    handleFetchIncidentListQuery.refetch();
  }, [tableFilters])

  const { analystAssigned, risk, adminCategories } = tableFilters;

  const filteredAnalystAssigned = handleFetchAnalystsAndAnalystAdmins?.data?.filter(analyst => analyst?.id === analystAssigned)[0];
  const fileteredRisk = handleFetchRisks?.data?.filter(r => r.id === risk)[0]?.name;
  let selectedCategories = null
  if(adminCategories){
      selectedCategories = categories?.filter(category=> adminCategories.includes(category.id));
  }

  const filteredStatus = REPORT_STATUS_LIST.find(
    (item) => item.id === tableFilters.status
  );

  return (
    <>
      <Container fluid className="mt-2">
            <div className="d-flex mb-4 justify-content-end w-full px-5 flex-wrap gap-3">
                <div className="d-flex flex-column">
                    <div className="d-flex align-items-end"> 
                        <TableFilter
                        defaultValues={{
                            ...tableFilters,
                        }}
                        categories={categories}
                        onOkButtonClicked={onTableFilterOkButtonClicked}
                        type={props.type}
                        handleFetchAnalystsAndAnalystAdmins={handleFetchAnalystsAndAnalystAdmins}
                        handleFetchRisks={handleFetchRisks}
                        />
                    </div>
                    <div className="d-flex justify-content-end mt-2" hidden={!filteredAnalystAssigned && !fileteredRisk && !selectedCategories?.length}>
                        <ul style={{
                                maxWidth:"312px"
                            }}>
                            <li hidden={!filteredAnalystAssigned}>{t("Analyst")}: {`${filteredAnalystAssigned?.first_name} ${filteredAnalystAssigned?.last_name}`}</li>
                            <li hidden={!fileteredRisk}>{t("Risk")}: {t(fileteredRisk)}</li>
                            <li hidden={!selectedCategories?.length}>{t("Categories")}: {`${selectedCategories?.map((category)=> category.title).join(", ")}`}</li>
                            <li hidden={!filteredStatus}>{t("Status")}: {`${filteredStatus?.titleKey}`}</li>
                        </ul>
                    </div>
                </div>
                <div>
                    <Link
                        className="btn btn-primary"
                        to={`/admin/data-protection/incident-register/create?softwareId=${assetId}`}
                        >
                        {props.t("Add A New Incident")}
                    </Link>
                </div> 
            </div>

        {/* page body */}
        <PageBody classNames="box-shadow">
          {!handleFetchIncidentListQuery.isLoading ||
          !handleFetchIncidentListQuery.isFetching ? (
            !handleFetchIncidentListQuery.isError ? (
              softwares?.reports && softwares?.reports?.length ? (
                // data
                <Fragment>
                  <Row>
                    <Col sm="12" md="12" lg="12">
                      <CustomTable
                        headerData={tableHeader}
                        data={handleProvideTableData(softwares.reports)}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12" md="12" lg="12">
                      <Pagination
                        pageIndex={tableFilters.pageIndex}
                        pageSize={tableFilters.pageSize}
                        totalCount={totalCount}
                        link={location.pathname}
                      />
                    </Col>
                  </Row>
                </Fragment>
              ) : (
                // empty
                <Row>
                  <Col sm="12" md="12" lg="12">
                    <Fragment>
                      <CustomTable headerData={tableHeader} data={[]} />
                      <div className="p-2">
                        <div className="alert alert-secondary text-center">
                          {props.t("This List Is Empty.")}
                        </div>
                      </div>
                    </Fragment>
                  </Col>
                </Row>
              )
            ) : (
              // error
              <Row>
                <Col sm="12" md="12" lg="12">
                  <Fragment>
                    <CustomTable headerData={tableHeader} data={[]} />
                    <div className="p-2">
                      <div className="alert alert-danger text-center">
                        {props.t("Failed To Fetch Softwares.")}
                      </div>
                    </div>
                  </Fragment>
                </Col>
              </Row>
            )
          ) : (
            // loading
            <Row>
              <Col sm="12" md="12" lg="12">
                <Fragment>
                  <CustomTable headerData={tableHeader} data={[]} />
                  <div className="p-2">
                    <div className="alert alert-info text-center">
                      {props.t("Loading")} ...
                    </div>
                  </div>
                </Fragment>
              </Col>
            </Row>
          )}
        </PageBody>
      </Container>

      {/* Deletion Modal */}
      {users?.length && (
        <Deletion
          t={t}
          modal={showDeletionModal}
          setModal={setDeletionModal}
          users={users}
          id={selectedId}
          setDeletionComplete={setDeletionComplete}
        />
      )}
      {/* </PageContent> */}
    </>
  );
};

export default withNamespaces()(
  withRouter(
    connect(null, {
      errorNotification,
      successNotification,
    })(memo(DataProtectionSoftwareRelatedIncidents))
  )
);
