import { ERROR_NOTIFICATION, SUCCESS_NOTIFICATION } from "./actionTypes"

export const errorNotification = (payload) => {
    return {
        type: ERROR_NOTIFICATION,
        payload: payload
    }
}

export const successNotification = (payload) => {
    return {
        type: SUCCESS_NOTIFICATION,
        payload: payload
    }
}