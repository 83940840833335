import { withNamespaces } from "react-i18next";
import { useState } from "react";
import { withRouter } from "react-router-dom";
import PageContent from "../../../components/page-content";
import { Container, Row, Col, Nav, NavItem, NavLink } from "reactstrap";
import PageHeader from "../../../components/page-header";

import TabIncidentContent from "./tabs-contents/incidents";
import TabDataSubjectContent from "./tabs-contents/data-subjects";
import TabProcessingActivityContent from "./tabs-contents/processing-activities";

const Dashboard = (props) => {
	const {
		t
	} = props;

	const [activeTab, setActiveTab] = useState('incidents');

	const renderActiveTabContent = () => {
		switch(activeTab){
			case 'incidents':
				return <TabIncidentContent/>;
			case 'data-subjects':
				return <TabDataSubjectContent />;
			case 'processing-activities':
				return <TabProcessingActivityContent type={"processing_activity"} />
			default:
				return null;
		}
	}

	return (
		<PageContent classNames="data-protection-hardware-list-page">
			<Container fluid>
				<PageHeader title={t("Dashboard")}></PageHeader>

				<Row>
					<Col lg={12}>
					<div style={{
							backgroundColor: '#D1D2D6',
						}}>
						<Nav tabs className="custom-style-1">
							<NavItem>
								<NavLink className={activeTab === 'incidents' ? 'active': ''}
									onClick={() => setActiveTab('incidents')}>
									{t("Incidents")}
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink className={activeTab === 'data-subjects' ? 'active': ''}
									onClick={() => setActiveTab('data-subjects')}>
									{t("Data Subjects Request")}
								</NavLink>
							</NavItem>

							<NavItem>
								<NavLink className={activeTab === 'processing-activities' ? 'active': ''}
									onClick={() => setActiveTab('processing-activities')}>
									{t("Processing Activites")}
								</NavLink>
							</NavItem>
						</Nav>
						</div>
					</Col>
				</Row>

				<Row className="mt-3">
					<Col lg={12}>
						{ renderActiveTabContent() }
					</Col>
				</Row>
			</Container>
		</PageContent>
	);
}	

export default withNamespaces()(
	withRouter(
		Dashboard
	)
);