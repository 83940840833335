import { Component } from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";

class SeeDetailsButton extends Component {
  render() {
    const { cell, row, userRole, translatedPlay } = this.props;
    const { tutorial_id: id, tutorial_name: name } = row;
    return (
      <Link
        to={{
          pathname: `/${userRole}/tutorial/${id}/detail`,
          tutorial_id: id,
          tutorial_name: name,
        }}
        onClick={() => console.log("id", id)}
      >
        <Button
          type="button"
          color="btn btn-primary"
          className="btn-rounded mb-2 me-2"
        >
          {this.props.t("Play")}
        </Button>
      </Link>
    );
  }
}

const mapStatetoProps = (state) => {
  const { user, token } = state.Login;
  const { App } = state;
  return { token, user, App };
};

export default withNamespaces()(
  connect(mapStatetoProps, null)(SeeDetailsButton)
);
