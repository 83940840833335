import React, { Component, Fragment } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { Button, Dropdown, DropdownToggle, DropdownMenu, Row, Col, Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';
import LanguageDropdown from '../CommonForBoth/TopbarDropdown/LanguageDropdown';
import NotificationDropdown from '../CommonForBoth/TopbarDropdown/NotificationDropdown/NotificationDropdown';
import ProfileMenu from '../CommonForBoth/TopbarDropdown/ProfileMenu';
import { withNamespaces } from 'react-i18next';
import { 
	saveOrganizationActiveModuleOnRemote as saveOrgModuleOnRemote, 
	toggleRightSidebar 
} from '../../store/actions';
import defaultLogo from '../../assets/images/logo.svg';
import UserUtils from '../../services/utils/UserUtils';
import UrlUtils from '../../services/utils/UrlUtils';
import FaqDropDown from '../CommonForBoth/TopbarDropdown/FaqDropDown';

const modulesData = {
    wb : {
        subTitle    :   'WB',
        title       :   'Whistleblowing',
        headerTitle :   'Whistleblowing'
    },
    cm : {
        subTitle    :   'CM',
        title       :   'Complaint',
        headerTitle :   'Complaint Management'
    },
    gd : {
        subTitle    :   'GD',
        title       :   'GDPR',
        headerTitle :   'GDPR'
    },
    tp : {
        subTitle    :   'TP',
        title       :   'TPDD',
        headerTitle :   'TPDD'
    }
}

class Header extends Component {
	constructor(props) {
		super(props);
		this.urlUtils = new UrlUtils();
		this.userUtils = new UserUtils();

		this.state = {
			isSearch: false,
			isSocialPf: false,

			disscoSupportEmail: 'support@diss-co.tech',
            clickedModule: null
		};
		this.toggleMenu = this.toggleMenu.bind(this);
		this.toggleRightbar = this.toggleRightbar.bind(this);
		this.toggleFullscreen = this.toggleFullscreen.bind(this);

		this.handleRenderModuleBox = this.handleRenderModuleBox.bind(this);
	}

	handleRenderModuleBox = (module) => {
        const isModuleClickable = this.props.modules.active !== module.domain && !this.props.modules.isLoading;

        return (
            <span style={{
                cursor: isModuleClickable ? 'pointer' : 'not-allowed'
            }} className='text-reset notification-item' onClick={() => {
                if(isModuleClickable){
                    this.setState({
                        clickedModule: module.domain
                    });

                    this.props.saveSelectedModuleOnRemote(module.domain);
                }
            }} key={module.domain}>
                <div className='d-flex align-items-start'>
                    <div className='avatar-xs me-3'>
                        <div style={{ backgroundColor: 'var(--ds-background-brand-bold, #0052CC)' }} className='css-4uzw5i'>
                            <span style={{ fontWeight: 'bold', color: '#ffffff' }}>{modulesData[module.domain]?.subTitle}</span>
                        </div>
                    </div>
                    <div className='flex-1'>
                        <h6 className='mt-2 mb-0'>{this.props.t(modulesData[module.domain]?.title)}</h6>
                    </div>
                    {this.props.modules.isLoading && this.state.clickedModule === module.domain && (
                        <Spinner animation="border" variant="white" size="sm"/>
                    )}
                </div>
            </span>
        )
	};

	/**
	 * Toggle sidebar
	 */
	toggleMenu() {
		this.props.toggleMenuCallback();
	}

	/**
	 * Toggles the sidebar
	 */
	toggleRightbar() {
		this.props.toggleRightSidebar();
	}

	toggleFullscreen() {
		if (!document.fullscreenElement && /* alternative standard method */ !document.mozFullScreenElement && !document.webkitFullscreenElement) {
			// current working methods
			if (document.documentElement.requestFullscreen) {
				document.documentElement.requestFullscreen();
			} else if (document.documentElement.mozRequestFullScreen) {
				document.documentElement.mozRequestFullScreen();
			} else if (document.documentElement.webkitRequestFullscreen) {
				document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
			}
		} else {
			if (document.cancelFullScreen) {
				document.cancelFullScreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.webkitCancelFullScreen) {
				document.webkitCancelFullScreen();
			}
		}
	}

	componentDidUpdate(prevProps){
		if((!prevProps.clickedModule && this.state.clickedModule) && (prevProps.modules.active !== this.props.modules.active)){
            let route = '';
            if (window.location.pathname.split('/').includes('third-party')) {
                route = '/third-party/home';
            } else {
                route = this.userUtils.getUserHomeRoute(this.props.user, this.props.modules.active);
            }
            this.props.history.push(route);
		}
	}

	render() {
		const organization = this.props.organization;
		const logo = organization.secondaryLogo ? organization.secondaryLogo : defaultLogo;
        const smallSizeLogo = organization.smallSizeLogo ? organization.smallSizeLogo : logo;

		return (
			<Fragment>
				<header id='page-topbar'>
					<div className='navbar-header'>
						<div className='d-flex'>
							<div className='navbar-brand-box'>
								<div to='#' className='logo logo-dark'>
									<span className='logo-sm'>
										<img src={smallSizeLogo} alt='' height='22' />
									</span>
									<span className='logo-lg'>
										<img src={logo} alt='' height='20' />
									</span>
								</div>

								<div to='#' className='logo logo-light'>
									<span className='logo-sm'>
										<img src={smallSizeLogo} alt='' height='22' />
									</span>
									<span className='logo-lg'>
										<img src={logo} alt='' height='20' />
									</span>
								</div>
							</div>
							
							<Button
								size='sm'
								color='none'
								type='button'
								onClick={this.toggleMenu}
								className='px-3 font-size-24 header-item waves-effect'
								id='vertical-menu-btn'
							>
								<i className='ri-menu-2-line align-middle'></i>
							</Button>

							{this.props.modules.active ? (
								<div className='managment-title' style={{ display: 'inline-flex', alignItems: 'center', cursor: 'default', marginLeft: '0.7rem' }}>
									<div className='px-1 font-size-20 text-primary waves-effect' style={{ cursor: 'default' }}>
                                        {this.props.t(modulesData[this.props.modules.active]?.headerTitle)}
									</div>
								</div>
							) : null}
						</div>

						<div className='d-flex'>
							<LanguageDropdown />
							<NotificationDropdown />
							<div className='dropdown d-none d-lg-inline-block ms-1'>
								<Button color='none' type='button' className='header-item noti-icon waves-effect' onClick={this.toggleFullscreen}>
									<i className='ri-fullscreen-line'></i>
								</Button>
							</div>
							{/* <div className='dropdown d-none d-lg-inline-block ms-1'>
								<Button id='TooltipSupportButton' color='none' type='button' className='btn header-item noti-icon waves-effect'>
									<a href={`mailto:${this.state.disscoSupportEmail}`} title={`${this.props.t('Need Support')}?`}>
										<i className='ri-question-line' style={{ fontSize: 26 }}></i>
									</a>
								</Button>
							</div> */}
							<FaqDropDown />
							{this.props.modules?.list?.length ? (
								<Dropdown
									isOpen={this.state.isSocialPf}
									toggle={() => this.setState({ isSocialPf: !this.state.isSocialPf })}
									className='d-none d-lg-inline-block ms-1'
								>
									<DropdownToggle tag='button' className='btn header-item noti-icon waves-effect'>
										<i className='ri-apps-2-line'></i>
									</DropdownToggle>
									
									<DropdownMenu className='dropdown-menu-lg dropdown-menu-end'>
										<div className='px-lg-2'>
											<div className='p-3'>
												<Row className='align-items-center'>
													<Col>
														<div className='switch-to-modules'>
															<div className='switch-to-modules-item-left'>
																<b>Switch to</b>
															</div>
															<div className='switch-to-modules-item-right'>
																<Link to='/admin/start'>
																	<b>
																		SIP Start <i className=' ri-external-link-line'></i>
																	</b>
																</Link>
															</div>
														</div>
													</Col>
												</Row>
											</div>
											
											{
												this.props.modules.list.map((m) => {
													return this.handleRenderModuleBox(m);
												})
											}
										</div>
									</DropdownMenu>
								</Dropdown>
							) : null}

							<ProfileMenu />
						</div>
					</div>
				</header>
			</Fragment>
		);
	}
}

const mapDispachToProps = (dispatch) => {
	return {
		saveSelectedModuleOnRemote	:	(module) => dispatch(saveOrgModuleOnRemote(module)),
		toggleRightSidebar
	};
};

const mapStatetoProps = (state) => {
	return {
		token			: 	state.Login.token,
		layoutType		: 	state.Layout.layoutType,
		organization	: 	state.Organization,
		user			: 	state.Login.user,
		modules			:	state.Modules
	};
};

export default withRouter(connect(mapStatetoProps, mapDispachToProps)(withNamespaces()(Header)));
