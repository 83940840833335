export const REPORT_TYPE_ANONYMOUS = 1;
export const REPORT_TYPE_CONFIDENT = 2;
export const REPORT_TYPE_MEETING = 3;

export const REPORT_STATUS_NEW = 0;
export const REPORT_STATUS_OPEN = 1;
export const REPORT_STATUS_ASSIGNED = 2;
export const REPORT_STATUS_IN_PROGRESS = 3;
export const REPORT_STATUS_CLOSED = 4;
export const REPORT_STATUS_DELETED = 5;
export const REPORT_STATUS_PENDING_DELETION = 6;

export const CASE_GREATER_THAN_5_YEARS_CLOSED = 'CASE_GREATER_THAN_5_YEARS_CLOSED';
export const CASE_GREATER_THAN_10_YEARS_CLOSED = 'CASE_GREATER_THAN_10_YEARS_CLOSED';
export const OTHER_REASON = 'OTHER_REASON';

export const ANALYST_REPORT_STATUS_LIST = [
  {
    id: REPORT_STATUS_NEW,
    titleKey: "New",
    className: "bg-danger me-1 badge badge-secondary",
  },
  {
    id: REPORT_STATUS_OPEN,
    titleKey: "Open",
    className: "bg-warning me-1 badge badge-secondary",
  },
  {
    id: REPORT_STATUS_ASSIGNED,
    titleKey: "Assigned",
    className: "bg-info me-1 badge badge-secondary",
  },
  {
    id: REPORT_STATUS_IN_PROGRESS,
    titleKey: "In Progress",
    className: "bg-success me-1 badge badge-secondary",
  },
  {
    id: REPORT_STATUS_CLOSED,
    titleKey: "Closed",
    className: "badge-soft-dark me-1 badge badge-secondary",
  },
  {
    id: REPORT_STATUS_PENDING_DELETION,
    titleKey: "Pending Deletion",
    className: "bg-dark me-1 badge badge-secondary",
  },
];

export const REPORT_STATUS_LIST = [
  {
    id: REPORT_STATUS_NEW,
    titleKey: "New",
    className: "bg-danger me-1 badge badge-secondary",
  },
  {
    id: REPORT_STATUS_OPEN,
    titleKey: "Open",
    className: "bg-warning me-1 badge badge-secondary",
  },
  {
    id: REPORT_STATUS_ASSIGNED,
    titleKey: "Assigned",
    className: "bg-info me-1 badge badge-secondary",
  },
  {
    id: REPORT_STATUS_IN_PROGRESS,
    titleKey: "In Progress",
    className: "bg-success me-1 badge badge-secondary",
  },
  {
    id: REPORT_STATUS_CLOSED,
    titleKey: "Closed",
    className: "badge-soft-dark me-1 badge badge-secondary",
  },
  {
    id: REPORT_STATUS_PENDING_DELETION,
    titleKey: "Pending Deletion",
    className: "bg-dark me-1 badge badge-secondary",
  },
  {
    id: REPORT_STATUS_DELETED,
    titleKey: "Deleted",
    className: "bg-dark me-1 badge badge-secondary",
  },
];

export const REPORT_RISK_RATE = [
  {
    value: 'low',
    label : 'Low'
  },
  {
    value: 'medium',
    label : 'Medium'
  },
  {
    value: 'high',
    label : 'High'
  }
];

export const REPORT_STATUS_LIST_USER = [
  {
    id: REPORT_STATUS_NEW,
    titleKey: "Submitted",
    className: "badge badge-pink",
  },
  {
    id: REPORT_STATUS_OPEN,
    titleKey: "Read",
    className: "badge badge-yellow",
  },
  {
    id: REPORT_STATUS_ASSIGNED,
    titleKey: "Assigned",
    className: "badge badge-blue-3",
  },
  {
    id: REPORT_STATUS_IN_PROGRESS,
    titleKey: "In Progress",
    className: "badge badge-green-3",
  },
  {
    id: REPORT_STATUS_CLOSED,
    titleKey: "Closed",
    className: "badge badge-gray-4",
  },
  {
    id: REPORT_STATUS_DELETED,
    titleKey: "Deleted",
    className: "badge badge-gray-1",
  },
];

export const REPORT_PDF_DOWNLOAD_OPTIONS = [
  {
    id: 1,
    title: "Internal investigation report",
  },
  {
    id: 2,
    title: "Case",
  },
];

export const REASON_OPTIONS = [
  CASE_GREATER_THAN_5_YEARS_CLOSED,
  CASE_GREATER_THAN_10_YEARS_CLOSED,
  OTHER_REASON
]
