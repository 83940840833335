import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { isTag, isText } from "domhandler";
import { parseDocument } from "htmlparser2";

const styles = StyleSheet.create({});

const getTagClassName = (className) => {
    switch (className) {
        case "ql-indent-1":
            return { paddingLeft: 15 };
        case "ql-indent-2":
            return { paddingLeft: 30 };
        case "ql-indent-3":
            return { paddingLeft: 45 };
        case "ql-indent-4":
            return { paddingLeft: 60 };
        case "ql-indent-5":
            return { paddingLeft: 75 };
        case "ql-indent-6":
            return { paddingLeft: 90 };
        case "ql-indent-7":
            return { paddingLeft: 105 };
        case "ql-indent-8":
            return { paddingLeft: 120 };
        case "ql-indent-9":
            return { paddingLeft: 135 };
        case "ql-indent-10":
            return { paddingLeft: 150 };
        default:
            return {};
    }
};

const renderNodeData = (node, keys) => {
    const data = node.data;

    return <Text key={keys.toString()}>{data}</Text>;
};

const renderListTag = (nodes, keys, isOrderedNumber) => {
    const renderListPrefix = (index) => {
        if (isOrderedNumber) {
            return <Text>{index + 1 + ". "}</Text>;
        }

        return <Text style={styles.dotPrefix}>{"· "}</Text>;
    };

    return nodes.map((node, index) => {
        if (isTag(node)) {
            keys.push(index);
            const children = node.children;
            const className = getTagClassName(node.attribs.class);

            return (
                <View style={[styles.listItem, className]} key={keys.toString()}>
                    {renderListPrefix(index)}

                    <View>{renderNodes(children, keys)}</View>
                </View>
            );
        }

        return null;
    });
};

const renderTag = (tag, keys) => {
    const name = tag.name;
    const children = tag.children;
    const className = getTagClassName(tag.attribs.className);

    switch (name) {
        case "br":
            return (
                <View key={keys.toString()} style={className}>
                    {renderNodes(children, keys)}
                </View>
            );
        case "p":
            if (Array.isArray(children) && children.length > 0) {
                const firstNode = children[0];

                if (isText(firstNode)) {
                    return (
                        <View key={keys.toString()} style={className}>
                            <Text>{renderNodes(children, keys)}</Text>
                        </View>
                    );
                }
            }

            return (
                <View key={keys.toString()} style={className}>
                    {renderNodes(children, keys)}
                </View>
            );
        case "b":
        case "strong":
            return (
                <Text key={keys.toString()} style={{ fontWeight: 600 }}>
                    {renderNodes(children, keys)}
                </Text>
            );

        case "em":
            return (
                <Text key={keys.toString()} style={{ fontStyle: "italic" }}>
                    {renderNodes(children, keys)}
                </Text>
            );
        case "u":
            return (
                <Text key={keys.toString()} style={{ textDecoration: "underline" }}>
                    {renderNodes(children, keys)}
                </Text>
            );
        case "ul":
            return (
                <View style={[styles.list, className]} key={keys.toString()}>
                    {renderListTag(children, keys, false)}
                </View>
            );
        case "ol":
            return (
                <View style={[styles.list, className]} key={keys.toString()}>
                    {renderListTag(children, keys, true)}
                </View>
            );
        default:
            return null;
    }
};

const renderNodes = (nodes, keys) => {
    if (!nodes || nodes.length === 0) {
        return null;
    }

    const newKeys = keys ? keys : [];

    return (
        <View>
            {nodes.map((node, index) => {
                newKeys.push(index);

                if (isTag(node)) {
                    return renderTag(node, newKeys);
                }

                if (isText(node)) {
                    return renderNodeData(node, newKeys);
                }

                return null;
            })}
        </View>
    );
};

const HTMLContent = ({
    content
}) => {
    const domContent = parseDocument(content);
    const children = domContent.children;

    return renderNodes(children);
};

export default HTMLContent;