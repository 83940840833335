import { memo } from 'react';
import { Modal, ModalBody, ModalHeader, Row, Col } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import UploadedFiles from '../../CommonForTaskAndRisk/uploadFiles';

const ReplaceFileModal = function (props) {
	ReplaceFileModal.displayName = 'Memorized Component - Replace File Modal';
	if (process.env.NODE_ENV === 'development') console.log('---- rendering memorized component >>> ReplaceFileModal');

	const { t, submit, close, progress, isOpen } = props;

	return (
		<>
			<Modal size='lg' scrollable={true} isOpen={isOpen} backdrop='static'>
				{/* header */}
				<ModalHeader toggle={() => close()}>{t('Upload a file')}</ModalHeader>

				{/* body */}
				<ModalBody>
					<AvForm className='needs-validation'>
						<Row>
							<Col sm='12' md='12' lg='12'>
								<UploadedFiles
									t={t}
									handleAcceptedFiles={(e) => (e.target?.files[0] ? submit(e.target.files[0]) : null)}
									uploadedFiles={[]}
									showProg={progress}
								/>
							</Col>
						</Row>
					</AvForm>
				</ModalBody>
			</Modal>
		</>
	);
};

export default memo(ReplaceFileModal);
