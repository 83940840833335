import { Component, Fragment } from "react";
import { logoutUser, setCaseId } from "../../../store/actions";
import { withRouter, Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import {
  API_URL_FETCH_RISK_RATINGS,
  CM_API_URL_FETCH_RISK_RATINGS,
  API_URL_GET_ANALYST_REPORT_LIST,
  CM_API_URL_GET_ANALYST_REPORT_LIST,
  API_URL_GET_ANALYST_REPORT_LISTS_COUNT,
  CM_API_URL_GET_ANALYST_REPORT_LISTS_COUNT,
  API_URL_GET_REPORT_LIST,
  CM_API_URL_GET_REPORT_LIST,
  API_URL_REPORT_CATEGORY_LIST_CASE,
  CM_API_URL_REPORT_CATEGORY_LIST_CASE,
  INTERNATIONAL_DATE_FORMAT,
  EMPTY_LIST,
} from "../../../common/constants";
import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import axios from "axios";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { isFeatureEnabledForActiveModule } from 'src/helpers/module_helper';
import TableWithPagination from "../../../components/Pagination";
import anonyReportImage from "../../../assets/images/report_anonymously.svg";
import confidentlyReportImage from "../../../assets/images/report_confidently.svg";
import arrangeMeetingImage from "../../../assets/images/arrange_meeting.svg";
import voice_report from "../../../assets/images/microphone.svg";
import DateUtils from "../../../services/utils/DateUtils";
import {
  REPORT_STATUS_DELETED,
  REPORT_STATUS_LIST,
} from "../../../components/constants";
import classnames from "classnames";
import Select from "react-select";
import { TbLoader } from "react-icons/tb";
import _debounce from "lodash/debounce";

// const debounce = (func, wait) => {
//   let timeout;

//   return function executedFunction(...args) {
//     const later = () => {
//       clearTimeout(timeout);
//       func(...args);
//     };

//     clearTimeout(timeout);
//     timeout = setTimeout(later, wait);
//   };
// };

class ReportsList extends Component {

  constructor(props) {
    super(props);

		document.title = 'Reports List | SIP';
    this.handleProvideTableColumns = this.handleProvideTableColumns.bind(this);
    this.handleRenderReportType = this.handleRenderReportType.bind(this);
    this.handleRenderReportStatus = this.handleRenderReportStatus.bind(this);
    this.handlePrepareReportsList = this.handlePrepareReportsList.bind(this);
    this.handleFetchReports = this.handleFetchReports.bind(this);
    this.handleFetchCategories = this.handleFetchCategories.bind(this);
    this.handleToggleTab = this.handleToggleTab.bind(this);
    this.handleInitiateFilterStatuses =
      this.handleInitiateFilterStatuses.bind(this);
    this.handleFetchReportCounts = this.handleFetchReportCounts.bind(this);
    this.onTableChange = this.onTableChange.bind(this);
    // Debounce the fetchUsers function with a 1000ms delay
   this.debouncedFetchCases = _debounce(this.handleFilterReports, 1000);

    this.dateUtils = new DateUtils();

    this.state = {
      breadcrumbItems: [
        { title: "SIP", link: "/" },
        { title: this.props.t("Case Management"), link: "#" },
      ],
      activeTab: "all_reports",
      reports: null,
      reportsTotalCount: 0,
      filter: {
        status: null,
        category: null,
        risk: null,
        caseId: null,
        search: null,
        assignedToMe: false,
        reportedByMe: false,
        excludeTestCases: false,
        excludeDeleted: false,
      },
      sort: {
        field: null,
        order: null,
      },
      pagination: {
        pageIndex: 1,
        pageSize: 25,
      },
      categories: null,
      riskRates: null,
      filterStatuses: [],
      counts: null,
      counts_loading: true,
      isLoading: false,
    };
  }

  /**
   * Handles the toggle of a tab.
   *
   * @param {string} tabId The ID of the tab to toggle.
   * @returns {void}
   */
  handleToggleTab(tabId) {
    if (tabId !== this.state.activeTab) {
      // Cancel any ongoing debounced calls
      this.debouncedFetchCases.cancel();
      this.setState(
        {
          activeTab: tabId,
          filter: {
            risk: null,
            status:
              tabId === "deleted_reports"
                ? this.state.filterStatuses.find(
                    (i) => i.value === REPORT_STATUS_DELETED
                  )
                : null,
            category: null,
            caseId: null,
            assignedToMe: tabId === "assigned_to_me" ? true : false,
            reportedByMe: tabId === "reported_by_me" ? true : false,
          },
          reportsTotalCount: null,
        },
        () => {
          this.handleFilterReports(
            this.state.filter.status,
            this.state.filter.category,
            this.state.filter.risk,
            this.state.filter.caseId,
            this.state.filter.search,
            this.state.filter.assignedToMe,
            this.state.filter.reportedByMe,
            1,
            this.state.pagination.pageSize,
            this.props.token,
            null
          );
        }
      );
    }
  }

  /**
   * Initiates the filter statuses dropdown items.
   *
   * @returns {void}
   */
  handleInitiateFilterStatuses() {
    const filterStatusDropDownItems = [
      {
        value: "",
        baseLabel: "Select status",
        label: this.props.t("Select status"),
      },
    ];
    REPORT_STATUS_LIST.forEach((item) => {
      filterStatusDropDownItems.push({
        value: item.id,
        baseLabel: item.titleKey,
        label: this.props.t(item.titleKey),
      });
    });

    this.setState({
      filterStatuses: filterStatusDropDownItems,
    });
  }

  /**
   * Provides the columns for the reports table.
   *
   * @returns {Array<{dataField: string, text: string, sort?: boolean}>} The columns list.
   */
  handleProvideTableColumns() {
    const columns = [
      {
        dataField: "risk_type",
        text: this.props.t("Risk"),
        sort: false,
      },
      {
        dataField: "type",
        text: this.props.t("Type"),
        sort: false,
      },
      {
        dataField: "case_id",
        text: this.props.t("Case ID"),
        sort: false,
        style: {
          display: "table-cell",
          maxWidth: "200px",
        },
        headerStyle: {
          minWidth: "200px",
          maxWidth: "200px",
        },
      },
      {
        dataField: "description",
        text: this.props.t("Description"),
        sort: false,
      },
      {
        dataField: "category",
        text: this.props.t("Category"),
        style: { display: "table-cell", maxWidth: "260px" },
        headerStyle: { maxWidth: "260px" },
      },
      {
        dataField: "source",
        text: this.props.t("Channel"),
        sort: true
      },
      {
        dataField: "submission_date",
        text: this.props.t("Submission date"),
        sort: true
      },
      {
        dataField: "closing_date",
        text: this.props.t("Closing date"),
        sort: true
      },
      {
        dataField: "analyst_assigned",
        text: this.props.t("Assigned analyst"),
        sort: true
      },
      {
        dataField: "status",
        text: this.props.t("Status"),
        sort: true
      },
    ];

    return columns;
  }

  /**
   * Renders the report type icon.
   *
   * @param {number} type The type of report.
   * @returns {JSX.Element} The icon element.
   */
  handleRenderReportType(type) {
    // eslint-disable-next-line default-case
    switch (type) {
      case 1:
        return (
          <img
            alt="Anonymously"
            className="rounded-circle"
            style={{ width: "36px" }}
            src={anonyReportImage}
          />
        );
      case 2:
        return (
          <img
            alt="Confidential"
            className="rounded-circle"
            style={{ width: "36px" }}
            src={confidentlyReportImage}
          />
        );
      case 3:
        return (
          <img
            alt="Meeting"
            className="rounded-circle"
            style={{ width: "36px" }}
            src={arrangeMeetingImage}
          />
        );
      case 4:
        return (
          <img
            alt="Voice"
            className="rounded-circle"
            style={{ width: "36px" }}
            src={voice_report}
          />
        );
    }
  }

  /**
   * Renders the report status.
   *
   * @param {number} statusId The ID of the report status.
   * @returns {JSX.Element} The rendered report status.
   */
  handleRenderReportStatus(statusId) {
    const status = REPORT_STATUS_LIST.find(
      (item) => item.id === parseInt(statusId)
    );
    return (
      <div className={status.className}>{this.props.t(status.titleKey)}</div>
    );
  }

  /**
   * Renders the report categories.
   *
   * @param {Array<number>} reportCategories The IDs of the report categories.
   * @param {Array<Category>} categories The list of categories.
   * @returns {string} The rendered report categories.
   */
  handleRenderReportCategories(reportCategories, categories) {
    const result =
      categories && categories?.length > 0
        ? categories
            .filter((i) => reportCategories?.includes(i.id))
            .map((i) => this.props.t(i.title))
        : null;
    return result.join(", ");
  }

  /**
   * Prepares the reports list for render.
   *
   * @param {Array<Report>} list The list of reports.
   * @returns {Array<Report>} Prepared list.
   */
  handlePrepareReportsList(list) {
    return list.map((item) => {
      const caseId = (
        <Link
          className="text-dark"
          to={`/admin/report/detail`}
          onClick={() => {
            localStorage.setItem("caseId", item.case_id);
            localStorage.setItem("secondaryCaseId", item.secondary_case_id);
            this.props.onSetCaseId(item.case_id);
          }}
        >
          {item.secondary_case_id}
        </Link>
      );

      return {
        risk_type: (
          <span
            style={{ backgroundColor: `#${item.risk}` }}
            className="risk-rate"
          ></span>
        ),
        id: item.case_id,
        case_id: caseId,
        type: this.handleRenderReportType(item.type),
        // description: 'admin_description' in item ? item.admin_description : item.description,
        description:
          "admin_description" in item && item.admin_description.trim()
            ? item.admin_description.length > 70 ? `${item.admin_description.substring(0, 70)}...` : item.admin_description
            : item.description ? (item.description.length > 70 ? `${item.description.substring(0, 70)}...` : item.description) : '',
        // category: this.state.categories
        // 	? this.handleRenderReportCategories('admin_category' in item ? item.admin_category : item.category, this.state.categories)
        // 	: [],
        category: this.state.categories
          ? this.handleRenderReportCategories(
              "admin_category" in item && item.admin_category.length > 0
                ? item.admin_category
                : item.category,
              this.state.categories
            )
          : [],
        source: (
          <div
            className="badge text-bg-primary"
            style={{ textTransform: "capitalize" }}
          >
            {this.props.t(item.source)}
          </div>
        ),
        submission_date: this.dateUtils.convertTimeStampToDate(
          item.submission_date,
          INTERNATIONAL_DATE_FORMAT
        ),
        closing_date: this.dateUtils.convertTimeStampToDate(
          item.closing_date,
          INTERNATIONAL_DATE_FORMAT
        ),
        analyst_assigned: item.analyst_assigned.id
          ? `${item.analyst_assigned.first_name} ${item.analyst_assigned.last_name}`
          : "",
        status: (
          <div>
            {this.handleRenderReportStatus(item.status)}
            <div>
              <small className="text-muted">
                {this.props.t("Updated")}:{" "}
                {this.dateUtils.convertTimeStampToDate(
                  item.status_updating_date,
                  INTERNATIONAL_DATE_FORMAT
                )}
              </small>
            </div>
          </div>
        ),
        // status: 'status',
      };
    });
  }

  /**
   * Prepare filter query and fetches the reports based on prepared query.
   *
   * @param {Object} status The status of the reports to filter.
   * @param {Array<Object>} category The categories of the reports to filter.
   * @param {Object} risk The risk levels of the reports to filter.
   * @param {boolean} assignedToMe Whether to filter reports that are assigned to the current user.
   * @param {number} pageIndex The index of the target page.
   * @param {number} pageSize The number of reports per page.
   * @param {string} token The authentication token.
   */
  handleFilterReports(
    status,
    category,
    risk,
    caseId,
    search,
    assignedToMe,
    reportedByMe,
    pageIndex,
    pageSize,
    token,
    sort
  ) {
    this.setState({
      filter: {
        ...this.state.filter,
        status,
        category,
        risk,
        caseId,
        search,
        assignedToMe,
        reportedByMe,
      },
      sort: {
        field: sort ? sort.field : null,
        order: sort ? sort.order : null,
      },
      pagination: {
        pageIndex: pageIndex,
        pageSize: pageSize,
      },
    });

    this.handleFetchReports(
      {
        status: status?.value,
        risk: risk?.value,
        caseId: caseId,
        search: search,
        category: category
          ? category?.map((i) => {
              return { value: i.value };
            })
          : [],
        assignedToMe: assignedToMe,
        reportedByMe: reportedByMe,
      },
      {
        pageIndex: pageIndex,
        pageSize: this.state.pagination.pageSize,
      },
      token,
      sort
    );
  }

  /**
   * Fetches the reports.
   *
   * @param {object} filter The filter object that specifies the criteria for the reports to fetch.
   * @param {object} pagination The pagination object that specifies the page index and page size.
   * @param {string} token The authentication token.
   */
  async handleFetchReports(filter, pagination, token, sort) {
    try {
      this.setState({
        reports: [],
        reportsTotalCount: null,
        isLoading: true,
      });

      const data = {
        pageSize: pagination.pageSize,
        pageIndex: pagination.pageIndex,
      };

      if (filter) {
        if (filter.risk) data["risk"] = filter.risk;
        if (filter.status || filter.status === 0)
          data["status"] = filter.status;
        if (filter.category) data["categories"] = filter.category;
        if(filter.caseId) data["caseId"] = filter.caseId;
        if(filter.search) data["search"] = filter.search;
        if (filter.assignedToMe) {
          data["assignedToMe"] = true;
        } else {
          data["assignedToMe"] = false;
        }
        data["excludeDeleted"] = this.state.filter.excludeDeleted;
        data["excludeTestCases"] = this.state.filter.excludeTestCases;
      }

      if (sort) {
        if (sort.field) data["sortField"] = sort.field;
        if (sort.order) data["sortOrder"] = sort.order;
      }

      const url = filter.reportedByMe
        ? this.props.modules.active === "wb"
          ? API_URL_GET_REPORT_LIST
          : CM_API_URL_GET_REPORT_LIST
        : this.props.modules.active === "wb"
        ? API_URL_GET_ANALYST_REPORT_LIST
        : CM_API_URL_GET_ANALYST_REPORT_LIST;

      const response = await axios.post(url, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        const counts = Object.create(this.state.counts);

        // eslint-disable-next-line default-case
        switch (this.state.activeTab) {
          case "all_reports":
            counts["all"] = Number(response.data.data.itemsCount);
            break;
          case "assigned_to_me":
            counts["assigned_to_me"] = Number(response.data.data.itemsCount);
            break;
          case "deleted_reports":
            counts["deleted"] = Number(response.data.data.itemsCount);
            break;
          case "reported_by_me":
            counts["my_cases"] = Number(response.data.data.itemsCount);
            break;
        }

        this.setState({
          reports: response.data.data.reports,
          reportsTotalCount: Number(response.data.data.itemsCount),
          counts: counts,
        });
      }
    } catch (error) {
      if (process?.env?.NODE_ENV === "development") {
        console.error(
          "this error only appears in the development environment:\nerror while fetching reports:",
          error
        );
      }
    } finally {
      this.setState({isLoading: false})
    }
  }

  /**
   * Fetches the categories.
   *
   * @param {string} token The authentication token.
   */
  async handleFetchCategories(token) {
    try {
      const response = await axios.post(
        this.props.modules.active === "wb"
          ? API_URL_REPORT_CATEGORY_LIST_CASE
          : CM_API_URL_REPORT_CATEGORY_LIST_CASE,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        this.setState({
          categories: response.data.data,
        });
      }
    } catch (error) {
      if (process?.env?.NODE_ENV === "development") {
        console.error(
          "this error only appears in the development environment:\nerror while fetching categories:",
          error
        );
      }

      // this.props.errorNotification({
      // 	message: this.props.t(FETCH_CASES_FAILED),
      // });
    }
  }

  /**
   * fires when table content changes.
   *
   * @param {string} token The authentication token.
   */
  onTableChange(type, newState){
    if(type === 'sort' && (newState.sortField !== this.state.sort.field || newState.sortOrder !== this.state.sort.order)){
      this.handleFilterReports(
        this.state.filter.status,
        this.state.filter.category,
        this.state.filter.risk,
        this.state.filter.caseId,
        this.state.filter.search,
        this.state.filter.assignedToMe,
        this.state.filter.reportedByMe,
        1,
        this.state.pagination.pageSize,
        this.props.token,
        {
          field: newState.sortField,
          order: newState.sortOrder,
        }
      );
    }
  }

  /**
   * Fetches the risk rates.
   *
   * @param {string} token The authentication token.
   */
  async handleFetchRiskRates(token) {
    try {
      const response = await axios.get(
        this.props.modules.active === "wb"
          ? API_URL_FETCH_RISK_RATINGS
          : CM_API_URL_FETCH_RISK_RATINGS,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const riskRates = response.data.data.map((i) => {
          return {
            value: i.id,
            baseLabel: i.name,
            label: this.props.t(i.name),
          };
        });
        this.setState({
          riskRates: [
            {
              value: "",
              baseLabel: "All Risk",
              label: this.props.t("All Risk"),
            },
            ...riskRates,
          ],
        });
      }
    } catch (error) {}
  }

  async handleFetchReportCounts(token) {
    this.setState({
      counts_loading: true,
    });

    try {
      const response = await axios.get(
        this.props.modules.active === "wb"
          ? API_URL_GET_ANALYST_REPORT_LISTS_COUNT
          : CM_API_URL_GET_ANALYST_REPORT_LISTS_COUNT,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        this.setState({
          counts: response.data.data,
        });
      }
    } catch (error) {}

    this.setState({
      counts_loading: false,
    });
  }

  mountActions() {
    this.handleInitiateFilterStatuses();

    this.handleFetchReports(
      {},
      {
        pageIndex: this.state.pagination.pageIndex,
        pageSize: this.state.pagination.pageSize,
      },
      this.props.token
    );
    this.handleFetchReportCounts(this.props.token);
    this.handleFetchCategories(this.props.token);

    // risk rates
    if (isFeatureEnabledForActiveModule("risk-assessment")) {
      this.handleFetchRiskRates(this.props.token);
    } else {
      this.setState({
        riskRates: [
          {
            value: "",
            baseLabel:"All Risk",
            label: this.props.t("All Risk"),
          },
          {
            value: "high",
            baseLabel:"High",
            label: this.props.t("High"),
          },
          {
            value: "medium",
            baseLabel:"Medium",
            label: this.props.t("Medium"),
          },
          {
            value: "low",
            baseLabel:"Low",
            label: this.props.t("Low"),
          },
        ],
      });
    }
  }

  componentDidMount() {
    this.mountActions();
  }

  componentDidUpdate(prevProps) {
    if (this.props.t !== prevProps.t) {
      const filterState = Object.create(this.state.filter);
      if (this.state.filter.status) {
        filterState.status = {
          ...filterState.status,
          label: this.props.t(filterState.status.baseLabel),
        };
      }
      if (this.state.filter.risk) {
        filterState.risk = {
          ...filterState.risk,
          label: this.props.t(filterState.risk.baseLabel),
        };
      }
      if (this.state.filter.category) {
        filterState.category = filterState.category.map((c) => {
          return {
            ...c,
            label: this.props.t(c.baseLabel),
          };
        });
      }
      this.setState({
        filter: filterState,
      });
    }

    if(this.props.modules?.active !== prevProps.modules?.active){
        if(['wb', 'cm'].includes(this.props.modules?.active)){
            this.mountActions();
        }
    }
  }

  onCaseIdFilterChange = (event) => {
    this.setState({
      filter: {
        ...this.state.filter,
        caseId: event.target.value
      }
    })
  }

  render() {
    const { t } = this.props;
    return (
      <Fragment>
        <div className="page-content">
          <Container fluid style={{ minHeight: "400px" }}>
            <Breadcrumbs
              title={this.props.t("Case Management")}
              breadcrumbItems={this.state.breadcrumbItems}
            />

            {isFeatureEnabledForActiveModule("list-cases") ? (
              <div>
                {/* filters */}
                <Row style={{ position: "relative", zIndex: 10 }}>
                    <Row>
                      <Col className="col-12 col-lg-8">
                        <Card>
                          <Nav tabs className="nav-tabs-custom nav-justified">
                            <NavItem>
                              <NavLink
                                style={{
                                  cursor: "pointer",
                                  whiteSpace: "nowrap"
                                }}
                                className={classnames({
                                  active: this.state.activeTab === "all_reports",
                                })}
                                onClick={() => {
                                  this.handleToggleTab("all_reports");
                                }}
                              >
                                <span className="d-block">
                                  {this.props.t("All reports")} (
                                  {!this.state.counts_loading ? (
                                    this.state.activeTab === "all_reports" ? (
                                      this.state.reportsTotalCount !== undefined &&
                                      this.state.reportsTotalCount !== null ? (
                                        this.state.reportsTotalCount
                                      ) : (
                                        <TbLoader />
                                      )
                                    ) : (
                                      this.state.counts["all"]
                                    )
                                  ) : (
                                    <TbLoader />
                                  )}
                                  )
                                </span>
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                style={{
                                  cursor: "pointer",
                                  whiteSpace: "nowrap"
                                }}
                                className={classnames({
                                  active: this.state.activeTab === "assigned_to_me",
                                })}
                                onClick={() => {
                                  this.handleToggleTab("assigned_to_me");
                                }}
                              >
                                <span className="d-block">
                                  {this.props.t("Assigned to Me")} (
                                  {!this.state.counts_loading ? (
                                    this.state.activeTab === "assigned_to_me" ? (
                                      this.state.reportsTotalCount !== undefined &&
                                      this.state.reportsTotalCount !== null ? (
                                        this.state.reportsTotalCount
                                      ) : (
                                        <TbLoader />
                                      )
                                    ) : (
                                      this.state.counts["assigned_to_me"]
                                    )
                                  ) : (
                                    <TbLoader />
                                  )}
                                  )
                                </span>
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                style={{
                                  cursor: "pointer",
                                  whiteSpace: "nowrap"
                                }}
                                className={classnames({
                                  active:
                                    this.state.activeTab === "deleted_reports",
                                })}
                                onClick={() => {
                                  this.handleToggleTab("deleted_reports");
                                }}
                              >
                                <span className="d-block">
                                  {this.props.t("Deleted Cases")} (
                                  {!this.state.counts_loading ? (
                                    this.state.activeTab === "deleted_reports" ? (
                                      this.state.reportsTotalCount !== undefined &&
                                      this.state.reportsTotalCount !== null ? (
                                        this.state.reportsTotalCount
                                      ) : (
                                        <TbLoader />
                                      )
                                    ) : (
                                      this.state.counts["deleted"]
                                    )
                                  ) : (
                                    <TbLoader />
                                  )}
                                  )
                                </span>
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                style={{
                                  cursor: "pointer",
                                  whiteSpace: "nowrap"
                                }}
                                className={classnames({
                                  active: this.state.activeTab === "reported_by_me",
                                })}
                                onClick={() => {
                                  this.handleToggleTab("reported_by_me");
                                }}
                              >
                                <span className="d-block">
                                  {this.props.t("Reported By Me")} (
                                  {!this.state.counts_loading ? (
                                    this.state.activeTab === "reported_by_me" ? (
                                      this.state.reportsTotalCount !== undefined &&
                                      this.state.reportsTotalCount !== null ? (
                                        this.state.reportsTotalCount
                                      ) : (
                                        <TbLoader />
                                      )
                                    ) : (
                                      this.state.counts["my_cases"]
                                    )
                                  ) : (
                                    <TbLoader />
                                  )}
                                  )
                                </span>
                              </NavLink>
                            </NavItem>
                          </Nav>
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="col-12 col-lg-12">
                        <Row>
                          {/* status */}
                          {isFeatureEnabledForActiveModule(
                            "fliter-status"
                          ) &&
                            this.state.activeTab !== "deleted_reports" && (
                              <Col sm="12" md="2" className="mb-2">
                                <Select
                                  classNamePrefix="select2-selection"
                                  options={this.state.filterStatuses.map((i) => {
                                    return {
                                      value: i.value,
                                      baseLabel: i.baseLabel,
                                      label: this.props.t(i.baseLabel),
                                    };
                                  })}
                                  value={this.state.filter.status}
                                  onChange={(e) => {
                                    this.handleFilterReports(
                                      e,
                                      this.state.filter.category,
                                      this.state.filter.risk,
                                      this.state.filter.caseId,
                                      this.state.filter.search,
                                      this.state.filter.assignedToMe,
                                      this.state.filter.reportedByMe,
                                      1,
                                      this.state.pagination.pageSize,
                                      this.props.token,
                                      this.state.sort
                                    );
                                  }}
                                  placeholder={this.props.t("Select status")}
                                />
                              </Col>
                            )}

                          {/* category */}
                          {isFeatureEnabledForActiveModule(
                            "filter-category"
                          ) && (
                            <Col sm="12" md="2" className="mb-2">
                              <Select
                                className={"select--filter-categories"}
                                classNamePrefix="select2-selection"
                                options={
                                  this.state.categories
                                    ? this.state.categories
                                        .filter((i) => i.flag)
                                        .map((i) => {
                                          return {
                                            value: i.id,
                                            baseLabel: i.title,
                                            label: this.props.t(i.title)
                                              ? this.props.t(i.title)
                                              : i.title,
                                          };
                                        })
                                    : []
                                }
                                value={this.state.filter.category}
                                onChange={(e) => {
                                  this.handleFilterReports(
                                    this.state.filter.status,
                                    e,
                                    this.state.filter.risk,
                                    this.state.filter.caseId,
                                    this.state.filter.search,
                                    this.state.filter.assignedToMe,
                                    this.state.filter.reportedByMe,
                                    1,
                                    this.state.pagination.pageSize,
                                    this.props.token,
                                    this.state.sort
                                  );
                                }}
                                isMulti
                                style={{
                                  height: 30,
                                  overflowY: "auto",
                                }}
                                placeholder={this.props.t("Select categories")}
                              />
                            </Col>
                          )}

                          {/* risk */}
                          {isFeatureEnabledForActiveModule(
                            "filter-risk"
                          ) && (
                            <Col sm="12" md="2" className="mb-2">
                              <Select
                                classNamePrefix="select2-selection"
                                options={
                                  this.state.riskRates
                                    ? this.state.riskRates.map((i) => {
                                        return {
                                          value: i.value,
                                          baseLabel: i.baseLabel,
                                          label: this.props.t(i.baseLabel),
                                        };
                                      })
                                    : []
                                }
                                value={this.state.filter.risk}
                                isClearable={true}
                                onChange={(e) => {
                                  this.handleFilterReports(
                                    this.state.filter.status,
                                    this.state.filter.category,
                                    e,
                                    this.state.filter.caseId,
                                    this.state.filter.search,
                                    this.state.filter.assignedToMe,
                                    this.state.filter.reportedByMe,
                                    1,
                                    this.state.pagination.pageSize,
                                    this.props.token,
                                    this.state.sort
                                  );
                                }}
                                placeholder={this.props.t("Select risk")}
                              />
                            </Col>
                          )}


                          {/* Case ID */}
                          <Col sm="12" md="2" className="mb-2">
                              <Input placeholder={this.props.t("Case ID")}
                              value={this.state.filter.caseId || ''} // Ensure it's controlled correctly
                              onChange={(e) => {
                                  // this.handleFilterReports(
                                  //   this.state.filter.status,
                                  //   this.state.filter.category,
                                  //   this.state.filter.risk,
                                  //   e.target.value,
                                  //   this.state.filter.assignedToMe,
                                  //   this.state.filter.reportedByMe,
                                  //   1,
                                  //   this.state.pagination.pageSize,
                                  //   this.props.token,
                                  //   this.state.sort
                                  // );
                                  // this.setState(
                                    this.setState({
                                      filter: {
                                        ...this.state.filter,
                                        caseId: e.target.value,
                                      }
                                    })
                                    this.debouncedFetchCases
                                      (this.state.filter.status,
                                        this.state.filter.category,
                                        this.state.filter.risk,
                                        e.target.value,
                                        this.state.filter.search,
                                        this.state.filter.assignedToMe,
                                        this.state.filter.reportedByMe,
                                        1,
                                        this.state.pagination.pageSize,
                                        this.props.token,
                                        this.state.sort
                                      )
                                  // )
                      
                                }}
                                />
                          </Col>

                          {/* Description */}
                          {/* <Col sm="12" md="4" className="mb-2">
                              <Input placeholder={this.props.t("Description")} onChange={(e) => {
                                    this.debouncedFetchCases
                                      (this.state.filter.status,
                                        this.state.filter.category,
                                        this.state.filter.risk,
                                        this.state.filter.caseId,
                                        e.target.value,
                                        this.state.filter.assignedToMe,
                                        this.state.filter.reportedByMe,
                                        1,
                                        this.state.pagination.pageSize,
                                        this.props.token,
                                        this.state.sort
                                      )
                      
                                }}
                                />
                          </Col> */}

                          {/* Exclude Items */}
                          <Col sm="12" md="4" className="mb-2">
                              <Form >
                                <fieldset>
                                <h5 style={{display: "inline-block"}} className="me-2 mt-1">{t("Exclude") + " :"}</h5> 
                                  <FormGroup
                                    check
                                    inline
                                  >
                                    <Input 
                                      type="checkbox" 
                                      onChange={() => this.setState({
                                        ...this.state,
                                        filter: {
                                        ...this.state.filter,
                                        excludeDeleted: !this.state.filter.excludeDeleted,
                                        // excludeTestCases: this.state.filter.excludeTestCases
                                      }
                                    }, () => {
                                        this.handleFilterReports(
                                          this.state.filter.status,
                                          this.state.filter.category,
                                          this.state.filter.risk,
                                          this.state.filter.caseId,
                                          this.state.filter.search,
                                          this.state.filter.assignedToMe,
                                          this.state.filter.reportedByMe,
                                          1,
                                          this.state.pagination.pageSize,
                                          this.props.token,
                                          this.state.sort
                                        );
                                      })}
                                      />
                                    <Label check>
                                      {t("Deleted cases")}
                                    </Label>
                                  </FormGroup>
                                  <FormGroup
                                    check
                                    inline
                                  >
                                    <Input 
                                      type="checkbox" 
                                      onChange={() => this.setState(
                                        {
                                          ...this.state,
                                          filter: {
                                        ...this.state.filter,
                                        // excludeDeleted: this.state.filter.excludeDeleted,
                                        excludeTestCases: !this.state.filter.excludeTestCases,
                                      }
                                    }, () => {
                                        this.handleFilterReports(
                                          this.state.filter.status,
                                          this.state.filter.category,
                                          this.state.filter.risk,
                                          this.state.filter.caseId,
                                          this.state.filter.search,
                                          this.state.filter.assignedToMe,
                                          this.state.filter.reportedByMe,
                                          1,
                                          this.state.pagination.pageSize,
                                          this.props.token,
                                          this.state.sort
                                        );
                                      })}
                                    />
                                    <Label check>
                                     {t("Test Cases")}
                                    </Label>
                                  </FormGroup>
                                </fieldset>
                              </Form>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                </Row>

                {/* table */}
                <Row>
                  <Col className="col-12">
                    <Card>
                      <CardBody className="pt-0">
                        <TabContent activeTab={this.state.activeTab}>
                          {/* all_reports */}
                          <TabPane tabId="all_reports" className="p-3">
                            {this.state.reports?.length > 0 ? (
                              <TableWithPagination
                                remote={{
                                  pagination: false,
                                  filter: false, 
                                  sort: true,
                                  cellEdit: false,
                                  search: false
                                }}
                                loading={this.state.isLoading}
                                onTableChange={this.onTableChange}
                                defaultSorted={[{
                                  dataField: this.state.sort.field,
                                  order: this.state.sort.order
                                }]}
                                columns={this.handleProvideTableColumns()}
                                pagination={{
                                  sizePerPage: 25,
                                  totalSize: this.state.reports
                                    ? this.state.reports.length
                                    : 0,
                                  custom: true,
                                }}
                                pageIndex={this.state.pagination.pageIndex}
                                totalSize={this.state.reportsTotalCount}
                                sizePerPage={this.state.pagination.pageSize}
                                data={
                                  this.state.reports
                                    ? this.handlePrepareReportsList(
                                        this.state.reports
                                      )
                                    : []
                                }
                                fetchData={(e) => {
                                  this.handleFilterReports(
                                    this.state.filter.status,
                                    this.state.filter.category,
                                    this.state.filter.risk,
                                    this.state.filter.caseId,
                                    this.state.filter.search,
                                    this.state.filter.assignedToMe,
                                    this.state.filter.reportedByMe,
                                    e.pageIndex,
                                    this.state.pagination.pageSize,
                                    this.props.token,
                                    this.state.sort
                                  );
                                }}
                              />
                            ) : this.state.isLoading ?
                              <div className="alert alert-info" role="alert">
                                <p
                                  style={{
                                    textAlign: "center",
                                    marginBottom: 0,
                                    width: "100%",
                                  }}
                                >
                                  {this.props.t("Loading") + "..."}
                                </p>
                              </div>
                            : (
                              <div className="alert alert-warning" role="alert">
                                <p
                                  style={{
                                    textAlign: "center",
                                    marginBottom: 0,
                                    width: "100%",
                                  }}
                                >
                                  {this.props.t(EMPTY_LIST)}
                                </p>
                              </div>
                            )}
                          </TabPane>

                          {/* assigned_to_me */}
                          <TabPane tabId="assigned_to_me" className="p-3">
                            {this.state.reports?.length > 0 ? (
                              <TableWithPagination
                                remote={{
                                  pagination: false,
                                  filter: false, 
                                  sort: true,
                                  cellEdit: false,
                                  search: false
                                }}
                                onTableChange={this.onTableChange}
                                defaultSorted={[{
                                  dataField: this.state.sort.field,
                                  order: this.state.sort.order
                                }]}
                                columns={this.handleProvideTableColumns()}
                                pagination={{
                                  sizePerPage: 25,
                                  totalSize: this.state.reports
                                    ? this.state.reports.length
                                    : 0,
                                  custom: true,
                                }}
                                pageIndex={this.state.pagination.pageIndex}
                                totalSize={this.state.reportsTotalCount}
                                sizePerPage={this.state.pagination.pageSize}
                                data={
                                  this.state.reports
                                    ? this.handlePrepareReportsList(
                                        this.state.reports
                                      )
                                    : []
                                }
                                fetchData={(e) => {
                                  this.handleFilterReports(
                                    this.state.filter.status,
                                    this.state.filter.category,
                                    this.state.filter.risk,
                                    this.state.filter.caseId,
                                    this.state.filter.search,
                                    this.state.filter.assignedToMe,
                                    this.state.filter.reportedByMe,
                                    e.pageIndex,
                                    this.state.pagination.pageSize,
                                    this.props.token,
                                    this.state.sort
                                  );
                                }}
                              />
                            ) : (
                              <div className="alert alert-warning" role="alert">
                                <p
                                  style={{
                                    textAlign: "center",
                                    marginBottom: 0,
                                    width: "100%",
                                  }}
                                >
                                  {this.props.t(EMPTY_LIST)}
                                </p>
                              </div>
                            )}
                          </TabPane>

                          {/* deleted_reports */}
                          <TabPane tabId="deleted_reports" className="p-3">
                            {this.state.reports?.length > 0 ? (
                              <TableWithPagination
                                remote={{
                                  pagination: false,
                                  filter: false, 
                                  sort: true,
                                  cellEdit: false,
                                  search: false
                                }}
                                onTableChange={this.onTableChange}
                                defaultSorted={[{
                                  dataField: this.state.sort.field,
                                  order: this.state.sort.order
                                }]}
                                columns={this.handleProvideTableColumns()}
                                pagination={{
                                  sizePerPage: 25,
                                  totalSize: this.state.reports
                                    ? this.state.reports.length
                                    : 0,
                                  custom: true,
                                }}
                                pageIndex={this.state.pagination.pageIndex}
                                totalSize={this.state.reportsTotalCount}
                                sizePerPage={this.state.pagination.pageSize}
                                data={
                                  this.state.reports
                                    ? this.handlePrepareReportsList(
                                        this.state.reports
                                      )
                                    : []
                                }
                                fetchData={(e) => {
                                  this.handleFilterReports(
                                    this.state.filter.status,
                                    this.state.filter.category,
                                    this.state.filter.risk,
                                    this.state.filter.caseId,
                                    this.state.filter.search,
                                    this.state.filter.assignedToMe,
                                    this.state.filter.reportedByMe,
                                    e.pageIndex,
                                    this.state.pagination.pageSize,
                                    this.props.token,
                                    this.state.sort
                                  );
                                }}
                              />
                            ) : (
                              <div className="alert alert-warning" role="alert">
                                <p
                                  style={{
                                    textAlign: "center",
                                    marginBottom: 0,
                                    width: "100%",
                                  }}
                                >
                                  {this.props.t(EMPTY_LIST)}
                                </p>
                              </div>
                            )}
                          </TabPane>

                          {/* reported_by_me */}
                          <TabPane tabId="reported_by_me" className="p-3">
                            {this.state.reports?.length > 0 ? (
                              <TableWithPagination
                                remote={{
                                  pagination: false,
                                  filter: false, 
                                  sort: true,
                                  cellEdit: false,
                                  search: false
                                }}
                                onTableChange={this.onTableChange}
                                defaultSorted={[{
                                  dataField: this.state.sort.field,
                                  order: this.state.sort.order
                                }]}
                                columns={this.handleProvideTableColumns()}
                                pagination={{
                                  sizePerPage: 25,
                                  totalSize: this.state.reports
                                    ? this.state.reports.length
                                    : 0,
                                  custom: true,
                                }}
                                pageIndex={this.state.pagination.pageIndex}
                                totalSize={this.state.reportsTotalCount}
                                sizePerPage={this.state.pagination.pageSize}
                                data={
                                  this.state.reports
                                    ? this.handlePrepareReportsList(
                                        this.state.reports
                                      )
                                    : []
                                }
                                fetchData={(e) => {
                                  this.handleFilterReports(
                                    this.state.filter.status,
                                    this.state.filter.category,
                                    this.state.filter.risk,
                                    this.state.filter.caseId,
                                    this.state.filter.search,
                                    this.state.filter.assignedToMe,
                                    this.state.filter.reportedByMe,
                                    e.pageIndex,
                                    this.state.pagination.pageSize,
                                    this.props.token,
                                    this.state.sort
                                  );
                                }}
                              />
                            ) : (
                              <div className="alert alert-warning" role="alert">
                                <p
                                  style={{
                                    textAlign: "center",
                                    marginBottom: 0,
                                    width: "100%",
                                  }}
                                >
                                  {this.props.t(EMPTY_LIST)}
                                </p>
                              </div>
                            )}
                          </TabPane>
                        </TabContent>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            ) : (
              <Alert color="warning">
                {this.props.t(
                  "You do not have permission to access these pages"
                )}
              </Alert>
            )}
          </Container>
        </div>
      </Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { user, token } = state.Login;
  const { App } = state;
  const { 
        Modules: modules 
  } = state;

  return {
    token,
    user,
    App,
    modules
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetCaseId: (caseId) => dispatch(setCaseId(caseId)),
    logoutUser: (history) => dispatch(logoutUser(history)),
  };
};

export default withNamespaces()(
  withRouter(connect(mapStatetoProps, mapDispatchToProps)(ReportsList))
);
