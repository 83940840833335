import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import React, { useState, useEffect, memo } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { connect } from "react-redux";
import Spinner from 'react-bootstrap/Spinner';
import { toast } from "react-toastify";
import BootstrapTable from "react-bootstrap-table-next";
import overlayFactory from 'react-bootstrap-table2-overlay';
import ReactApexChart from "react-apexcharts";
import NewReportRequestModal from "../new-report-request-modal";

import {
    HiChevronDoubleDown,
    HiChevronDoubleUp,
    HiMenuAlt4,
} from "react-icons/hi";

import paperImage from "src/modules/3rd-party-management/assets/images/paper.svg";

import flagOnIcon from "src/assets/icons/flag-icon.png";
import flagOffIcon from "src/assets/icons/flag-icon-off.png";

import { EMPTY_LIST } from "src/common/constants";

import {
    Button, Col, Row,Card, CardBody
  } from "reactstrap";

import DateUtils from "src/services/utils/DateUtils";

import {
    INTERNATIONAL_DATE_FORMAT
} from 'src/common/constants';

import SupplierReportsService from "src/modules/3rd-party-management/apis/SupplierReportsService";

const SupplierReportsList = ({
    t, 
    supplierId,
    onReportSelectedToView,
    className,
    supplierType
}) => {
    const [ pageIndex, setPageIndex ] = useState(1);
    const [ pageSize, setPageSize ] = useState(12);
    const [ reportsList, setReportsList ] = useState([]);
    const [ reportsDetails, setReportsDetails ] = useState(null);
    const [ showNewReportRequestModal, setShowNewReportRequestModal ] = useState(false);

    const dateUtils = new DateUtils();

    const tableColumns = [
        {
            dataField :   "request_date",
            text      :   t("Request Date"),
            sort      :   false,
            key       :   1,
            style     :   {
                width: '120px'
            },
            formatter :  (cellContent, row) => {
                return dateUtils.convertTimestampToOnlyDate(row?.created_at, INTERNATIONAL_DATE_FORMAT)
            }
        },
        {
            dataField :   "financial_start_year",
            text      :   t('Start Year (Financial)'),
            sort      :   false,
            key       :   2,
            style     :   {
                width: '120px'
            },
            formatter : (cellContent) => {
                if(cellContent)
                    return dateUtils.convertTimestampToOnlyDate(cellContent, INTERNATIONAL_DATE_FORMAT)
                return ''
            }
        },
        {
            dataField :   "financial_end_year",
            text      :   t('End Year (Financial)'),
            sort      :   false,
            key       :   3,
            style     :   {
                width: '120px'
            },
            formatter : (cellContent) => {
                if(cellContent)
                    return dateUtils.convertTimestampToOnlyDate(cellContent, INTERNATIONAL_DATE_FORMAT)
                return ''
            }
        },
        {
            dataField :   "deadline_date",
            text      :   t("Deadline"),
            sort      :   false,
            key       :   4,
            style     :   {
                width: '120px'
            },
            formatter :  (cellContent, row) => {
                return dateUtils.convertTimestampToOnlyDate(row?.deadline_date, INTERNATIONAL_DATE_FORMAT)
            }
        },
        {
            dataField :   "contact_person",
            text      :   t("Contact Person"),
            sort      :   false,
            key       :   5,
            style     :   {
                width: '160px'
            },
            formatter : (cellContent, row) => {
                return row.responsible_person?.user?.full_name;
            }
        },
        {
            dataField :   "report_case",
            text      :   t("Report ID"),
            sort      :   false,
            key       :   6,
            style     :   {
                width: '120px'
            },
            formatter : (cellContent, row) => {
                return row?.report_case?.caseIdV2;
            }
        },
        {
            dataField :   "submit_date",
            text      :   t("Submission Date"),
            sort      :   false,
            key       :   7,
            style     :   {
                width: '160px'
            },
            formatter :  (cellContent, row) => {
                if(!row.report_case){
                    return null;
                }

                return (
                    <div>
                        <span>
                            { dateUtils.convertTimestampToOnlyDate(row.report_case.submission_date, INTERNATIONAL_DATE_FORMAT) }
                        </span><br/>
                        <span className="text-muted">
                            { t('Updated on') }: { dateUtils.convertTimestampToOnlyDate(row.report_case.updated_at || row.report_case.submission_date, INTERNATIONAL_DATE_FORMAT) }
                        </span>
                    </div>
                )
            }
        },
        {
            dataField :   "flag",
            text      :   t('Flag'),
            sort      :   false,
            key       :   8,
            style     :   {
                width: '80px'
            },
            formatter : (cellContent, row) => {
                if(!row.report_case) return null;

                return (
                    <Button onClick={() => toggleFlag(row)} color="transparent" outline className="border-0 p-0">
                        {
                            row?.state?.updatingFlagInProgress ? (
                                <Spinner animation="border" variant="primary" size="sm"/>
                            ) : (
                                row.report_case?.flag ? (
                                    <img style={{width: '24px'}} src={flagOnIcon} alt="" />
                                ) : (
                                    <img style={{width: '24px'}} src={flagOffIcon} alt="" />
                                )
                            )
                        }
                    </Button>
                );
            }
        },
        {
            dataField :   "actions",
            text      :   t("Action"),
            sort      :   false,
            key       :   9,
            style     :   {
                width: '160px'
            },
            formatter : (cellContent, row) => {
                return (
                    <div className="actions">
                        <Button color="danger" outline className="border-0">
                            <i className="ri-delete-bin-line"></i>
                        </Button>

                        {row.report_case && (
                            <Button color="primary" onClick={() => onReportSelectedToView && onReportSelectedToView(row)} outline className="border-0">
                                <i className="ri-eye-line"></i>
                            </Button>
                        )}
                    </div>
                );
            }
        }
    ];

    const NoDataIndication = () => (
        (handleFetchSupplierReportsQuery.isFetched && !reportsList.length) ? <div className="alert m-0" role="alert">
            <p style={{
                    textAlign: "center",
                    marginBottom: 0
                }}>
                {t(EMPTY_LIST)}
            </p>
        </div> : <></>
    );

    const handleFetchSupplierReportsQuery = useQuery({
		queryKey: ['3rd-party-management-fetch-supplier-reports', supplierId, pageIndex, pageSize],
		queryFn: async () => {
			const service = SupplierReportsService.getInstance();

            return await service.list(supplierId, pageIndex, pageSize, {});
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			toast(t('An error occurred while fetching supplier reports.'), {
				type: 'error',
			});
		},
	});

    const handleToggleSupplierReportFlagStatusMutation = useMutation({
        mutationFn: async (reportId) => {
            const service = SupplierReportsService.getInstance();
    
            return await service.toggleFlag(reportId);
        },
        onSuccess: () => {
            toast(t("Flag status updated successfully."), {
                type: "success",
            });
        },
        onError: () => {
            toast(t("An error occurred while updating flag status."), {
                type: "error",
            });
        }
    });

    useEffect(() => {
        if(handleFetchSupplierReportsQuery.data){
            const {
                requests,
                reportsDetails
            } = handleFetchSupplierReportsQuery.data;

            setReportsList(requests || []);
            setReportsDetails(reportsDetails);
        }
    }, [handleFetchSupplierReportsQuery.data]);

    const renderReportPriority = (priority) => {
        if(priority === 'medium'){
            return (
                <>
                    <HiMenuAlt4 className="text-success" />
                    <span>{ t('Medium') }</span>
                </>
            );
        }
        else if(priority === 'high'){
            return (
                <>
                    <HiChevronDoubleUp className="text-danger" />
                    <span>{ t('High') }</span>
                </>
            );
        }
        else{
            return (
                <>
                    <HiChevronDoubleDown className="text-info" />
                    <span>{ t('Low') }</span>
                </>
            );
        }
    }

    const renderReportsStatistics = () => {
        const totalRequestsCount = parseInt(reportsDetails?.totalReportRequest || 0);
        const pendingRequestsCount = parseInt(reportsDetails?.totalPendingRequests || 0);
        const completedRequestsCount = parseInt(reportsDetails?.totalReports || 0);


        const pendingRequestsPercent = pendingRequestsCount && (pendingRequestsCount / totalRequestsCount * 100).toFixed(1);
        const completedRequestsPercent = completedRequestsCount && (completedRequestsCount / totalRequestsCount * 100).toFixed(1);

        return (
            <Row>

                <Col sm="12" md="4">
                    <Card className="report-statistics-card">
                        <CardBody>
                            <Row>
                                <Col sm="6" md="8">
                                    <div className="overflow-hidden">
                                        <p className="text-truncate">
                                            {t('Open requests')}
                                        </p>
                                        <h4 className="mb-0">{ pendingRequestsCount }</h4>
                                    </div>
                                </Col>

                                <Col sm="6" md="4">
                                    <ReactApexChart
                                        options={{
                                            chart: {
                                                sparkline: {
                                                    enabled: true
                                                }
                                            },
                                            dataLabels: {
                                                enabled: true
                                            },
                                            colors: ['#FF4949'],
                                            stroke: {
                                                lineCap: 'round'
                                            },
                                            plotOptions: {
                                                radialBar: {
                                                    hollow: {
                                                        margin: 0,
                                                        size: '70%'
                                                    },
                                                    track: {
                                                        margin: 0,
                                                    },
                                                    dataLabels: {
                                                        show: true,
                                                        name : {
                                                            show : false
                                                        },
                                                        value : {
                                                            show : true,
                                                            offsetY: 6
                                                        }
                                                    }
                                                }
                                            }
                                        }} 
                                        series={[pendingRequestsPercent]} 
                                        type="radialBar" 
                                        height="60" 
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>

                <Col sm="12" md="4">
                    <Card className="report-statistics-card">
                        <CardBody>
                            <Row>
                                <Col sm="6" md="8">
                                    <div className="overflow-hidden">
                                        <p className="text-truncate">
                                            {t('Reports submitted')}
                                        </p>
                                        <h4 className="mb-0">{ completedRequestsCount }</h4>
                                    </div>
                                </Col>

                                <Col sm="6" md="4">
                                    <ReactApexChart
                                        options={{
                                            chart: {
                                                sparkline: {
                                                    enabled: true
                                                }
                                            },
                                            dataLabels: {
                                                enabled: true
                                            },
                                            colors: ['#FF4949'],
                                            stroke: {
                                                lineCap: 'round'
                                            },
                                            plotOptions: {
                                                radialBar: {
                                                    hollow: {
                                                        margin: 0,
                                                        size: '70%'
                                                    },
                                                    track: {
                                                        margin: 0,
                                                    },
                                                    dataLabels: {
                                                        show: true,
                                                        name : {
                                                            show : false
                                                        },
                                                        value : {
                                                            show : true,
                                                            offsetY: 6
                                                        }
                                                    }
                                                }
                                            }
                                        }} 
                                        series={[completedRequestsPercent]} 
                                        type="radialBar" 
                                        height="60" 
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>

                <Col sm="12" md="4">
                    <Card className="report-statistics-card">
                        <CardBody>
                            <Row>
                                <Col sm="6" md="8">
                                    <div className="overflow-hidden">
                                        <p className="text-truncate">
                                            {t('Total number of report')}
                                        </p>
                                        <h4 className="mb-0">{ totalRequestsCount }</h4>
                                    </div>
                                </Col>

                                <Col sm="6" md="4" className="d-flex justify-content-end">
                                    <img src={paperImage} alt="" />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        );
    }

    const toggleFlag = (row) => {
        row.state = {
            updatingFlagInProgress  :   true
        };

        handleToggleSupplierReportFlagStatusMutation.mutate(row.report_case.case_id, {
            onSuccess   :   () =>   row.report_case.flag = !row.report_case.flag,
            onSettled   :   () =>   row.state.updatingFlagInProgress = false
        });
    }

    return (
        <Row className={`${className ? className : ''}`}>
            <Col sm="12" className="d-flex justify-content-end mb-4">   
                <Button color="primary" onClick={() => {
                    if(parseInt(reportsDetails?.totalPendingRequests) > 0){
                        toast(t('Due to the existence of open requests, it is not possible to request a new report.'), {
                            type: 'error'
                        });
                    }
                    else{
                        setShowNewReportRequestModal(true)
                    }
                }}>
                    {t(supplierType === 'own' ? "Add A New Report" : "Request A New Report")}
                </Button>
            </Col>
            
            <Col sm="12" className="mb-4">
                { renderReportsStatistics() }  
            </Col>
            
            <Col sm="12">
                <BootstrapTable
                    remote={{
                        pagination: false,
                        filter: false, 
                        sort: true,
                        cellEdit: false,
                        search: false
                    }}
                    loading={handleFetchSupplierReportsQuery.isFetching || handleFetchSupplierReportsQuery.isLoading}
                    overlay={ overlayFactory({ spinner: <Spinner animation="border" variant="primary" size="md"/>, text : `${t("Loading")}...` }) }
                    defaultSorted={[]}
                    keyField={"id"}
                    responsive
                    bordered={false}
                    data={ reportsList }
                    striped={false}
                    columns={tableColumns}
                    wrapperClasses="table-responsive"
                    classes={"table tpdd-table"}
                    headerWrapperClasses={"thead-light"}
                    style={{
                        overflowX: "auto",
                    }}
                    noDataIndication={ () => <NoDataIndication /> }
                />
            </Col>

            <NewReportRequestModal 
                toggle={ () => setShowNewReportRequestModal(!showNewReportRequestModal) } 
                show={ showNewReportRequestModal } 
                supplierId={ supplierId }
                onSuccess={ () => handleFetchSupplierReportsQuery.refetch() }
                supplierType={supplierType}
            />
        </Row>
    );
};

const mapStatetoProps = (state) => {
    const { token } = state.Login;
    const { Organization } = state;
    return {
      user : state.Login.user,
      token,
      organization : Organization
    };
};

export default withNamespaces()(
    withRouter(connect(
        mapStatetoProps,
        {}
    )(memo(SupplierReportsList)))
);