import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import { Row, Col, Card, CardBody } from "reactstrap";

import Knob from "./Charts/Knob";

class MiniWidgets extends Component {
  render() {
    return (
      <Row>
        {this.props.reports.map((report, key) => (
          <Col key={Math.random()} md={4}>
            <Card>
              <CardBody>
                <div className="d-flex">
                  <div className="overflow-hidden" style={{ flex: "0 0 70%" }}>
                    <p
                      className="text-truncate font-size-14 mb-2"
                      style={{ textTransform: "capitalize" }}
                    >
                      {this.props.t(report.title)}
                    </p>
                    <h4 className="mb-0">{report.amount}</h4>
                  </div>
                  {report.icon && (
                    <div
                      className="text-center"
                      dir="ltr"
                      style={{ height: 66 }}
                    >
                      <i
                        className={report.icon + " font-size-24"}
                        style={{ position: "relative", top: 12, right: 6 }}
                      ></i>
                    </div>
                  )}
                  {report.displayChart && (
                    <div className="text-primary">
                      <Knob
                        value={report.percent}
                        lineCap="round"
                        height={60}
                        width={60}
                        fgColor="#545B9A"
                        thickness={0.12}
                        readOnly={true}
                        onChange={(e) => {}}
                      />
                    </div>
                  )}
                </div>
              </CardBody>
              {report.desc && (
                <CardBody className="border-top py-3">
                  <div className="text-truncate">
                    <span className="badge badge-soft-success font-size-11 me-1">
                      <i className="mdi mdi-menu-up"> </i> {report.rate}
                    </span>
                    <span className="text-muted ms-2">{report.desc}</span>
                  </div>
                </CardBody>
              )}
            </Card>
          </Col>
        ))}
      </Row>
    );
  }
}

export default withNamespaces()(MiniWidgets);
