import { withNamespaces } from "react-i18next";
import { useState, useMemo, useEffect } from "react";
import { Alert, Button, Col, Label, Row, Table } from "reactstrap";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import CommonService from "src/modules/3rd-party-management/apis/CommonService";
import SupplierService from "src/modules/3rd-party-management/apis/SupplierService";
import DateUtils from "src/services/utils/DateUtils";
import { STANDARD_DATE_FORMAT } from "src/components/constants";
import Select from "react-select";
import Spinner from 'react-bootstrap/Spinner';

const LegalEntites = ({
    t,
    supplierId,
    defaultValues = [],
    purchasingVolumes = []
}) => {
    const [selectedLegalEntities, setSelectedLegalEntities] = useState([]);
    const [selectedEntityToAdd, setSelectedEntityToAdd] = useState(null);
    
    const dateUtils = new DateUtils();

    const {
        dataUpdatedAt   :   legalEntitiesListUpdatedAt,
        data            :   legalEntitiesData,
        isFetching      :   legalEntitiesListIsLoading
    } = useQuery({
        queryKey: ['3rd-party-management-fetch-legal-entities-list'],
        queryFn: async () => {
            const service = CommonService.getInstance();

            return await service.fetchLegalEntities();
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onError: (error) => {
            toast(t('An error occurred while fetching entities.'), {
            type: 'error',
            });
        }
    });

    const {
        mutate: updateSupplierLegalEntities,
        isLoading:  supplierLegalEntitiesMutationInProcess
    } = useMutation({
        mutationFn: async (payload) => {
            const service = SupplierService.getInstance();

            return await service.updateLegals(payload);
        },
        onError: () => {
            toast(t("An error occurred while updating legal entities."), {
                type: "error",
            });
        }
    });

    const entitiesOptions = useMemo(() => {
        return (legalEntitiesData?.legalEntities || []).map((legal) => {
            return {
                value   :   legal.id,
                label   :   legal.title
            }
        }).filter((l) => !selectedLegalEntities.find((s) => s.value === l.value))
    }, [ legalEntitiesListUpdatedAt, selectedLegalEntities ]);

    useEffect(() => {
        setSelectedLegalEntities(
            (legalEntitiesData?.legalEntities || [])
                .filter((l) => defaultValues.includes(l.id))
                .map((l) => {
                    return {
                        value   :   l.id,
                        label   :   l.title
                    }
                })
        );
    }, [ legalEntitiesListUpdatedAt ]);

    const addNewLegalId = 'add-new-legal'

    return (
        <Row>
            <Col sm='12' className="mb-4">
                <div className="table-responsive products-list">
                    <Table borderless>
                        <thead>
                            <tr>
                                <th>
                                    #
                                </th>
                                <th>
                                    { t('Title') }
                                </th>
                                <th>
                                    { t('Added On') }
                                </th>
                                <th>
                                    { t('Action') }
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {!selectedLegalEntities.length && (
                                <tr>
                                    <td colSpan='4'>
                                        <Alert className='text-center' color='warning'>
                                            { t('This list is empty') }
                                        </Alert>
                                    </td>
                                </tr>
                            )}
                            
                            {
                                selectedLegalEntities.map((legal, index) => {
                                    return (
                                        <tr key={ index }>
                                            <td>
                                                { index + 1 }
                                            </td>

                                            <td>
                                                { legal.label }
                                            </td>
                                            
                                            <td>
                                                {
                                                    dateUtils.convertDateToDate(
                                                        legal.addedOn, 
                                                        STANDARD_DATE_FORMAT
                                                    )
                                                }
                                            </td>

                                            <td>
                                                <div className="d-flex justify-content-start">
                                                    {
                                                        <Button 
                                                            onClick={() => {
                                                                const newList = [...selectedLegalEntities.filter((l) => l.value !== legal.value)];

                                                                updateSupplierLegalEntities({
                                                                    supplier        :   supplierId,
                                                                    legalEntities   :   newList.map((legal) => legal.value)
                                                                }, {
                                                                    onSuccess   :   () => {
                                                                        setSelectedLegalEntities(newList)
                                                        
                                                                        toast(t("Legal entity successfully deleted from the list."), {
                                                                            type: "success",
                                                                        });
                                                                    }
                                                                });
                                                            }}
                                                            outline 
                                                            color="danger" 
                                                            size="md" 
                                                            className="border-0">
                                                                {supplierLegalEntitiesMutationInProcess ? (
                                                                    <Spinner animation="border" variant="danger" size="sm"/>
                                                                ) : (
                                                                    <i className="ri-delete-bin-line font-size-20"></i>
                                                                )}
                                                        </Button>
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            <tr key={ addNewLegalId }>
                                <td>
                                    {selectedLegalEntities.length + 1}
                                </td>

                                <td colSpan={2}>
                                    <Select
                                        value={selectedEntityToAdd}
                                        isDisabled={ legalEntitiesListIsLoading }
                                        isLoading={ legalEntitiesListIsLoading }
                                        placeholder={t("Select a company...")}
                                        classNamePrefix="select2-selection"
                                        options={ entitiesOptions }
                                        menuPortalTarget={ document.body }
                                        onChange={(e) => setSelectedEntityToAdd(e)}
                                    />
                                </td>

                                <td>
                                    <div className="d-flex justify-content-start">
                                        <Button 
                                            onClick={ () => {
                                                const newList = [...selectedLegalEntities, selectedEntityToAdd];

                                                updateSupplierLegalEntities({
                                                    supplier        :   supplierId,
                                                    legalEntities   :   newList.map((legal) => legal.value)
                                                }, {
                                                    onSuccess   :   () => {
                                                        setSelectedLegalEntities(newList)
                                                        setSelectedEntityToAdd(null)
                                        
                                                        toast(t("Legal entity successfully added to the list."), {
                                                            type: "success",
                                                        });
                                                    }
                                                });
                                            } } 
                                            disabled={!selectedEntityToAdd || supplierLegalEntitiesMutationInProcess}
                                            color="primary" 
                                            outline>
                                                {supplierLegalEntitiesMutationInProcess && (
                                                    <Spinner animation="border" variant="secondary" size="sm"/>
                                                )}

                                                {t('Add Entity')}
                                        </Button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </Col>

            <Col sm='12'>
                <Label>
                    {t('Purchasing Volumes')}
                </Label>
                
                <div className="table-responsive products-list">
                    <Table borderless>
                        <thead>
                            <tr>
                                <th>
                                    #
                                </th>
                                <th>
                                    {t('Legal Name')}
                                </th>
                                <th>
                                    { t('Mandate ID') }
                                </th>
                                <th>
                                    { t('Creditor Number') }
                                </th>
                                <th>
                                    { t('Year') }
                                </th>
                                <th>
                                    { t('Purchasing Volume') }
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {purchasingVolumes.length > 0 ? (
                                <>
                                    {purchasingVolumes.map((volume, index) => {
                                        return (
                                            <tr key={ index }>
                                                <td>
                                                    { index + 1 }
                                                </td>

                                                <td>
                                                    { volume.legal }
                                                </td>

                                                <td>
                                                    { volume.mandateId }
                                                </td>

                                                <td>
                                                    { volume.creditorNumber }
                                                </td>

                                                <td>
                                                    { volume.year }
                                                </td>
                                                
                                                <th>
                                                    {new Intl.NumberFormat('de-DE', {
                                                        style: 'currency',
                                                        currency: 'EUR'
                                                    }).format(volume.purchasingVolume)}
                                                </th>
                                            </tr>
                                        )
                                    })}
                                </>
                            ) : (
                                <tr>
                                    <td colSpan='6'>
                                        <Alert className='text-center' color='warning'>
                                            { t('This list is empty') }
                                        </Alert>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div>
            </Col>
        </Row>
    );
};

export default withNamespaces()(LegalEntites);