import React, { memo, useState } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { Row, Col, Card, CardBody, Container } from "reactstrap";
import QRCodePage from "./components/qr";
import RiskCustomazation from "./components/riskCutomization";
import NewsMonitoring from "./components/newsMonitoring";
import Routing from "./components/routing";
import Tabs from "./components/tabs";
import { useQuery } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import Navigator from "src/modules/data-protection/components/navigator";
import { withRouter } from "react-router-dom";
import { getFeatureStatusByTitle } from "src/helpers/feature_helper";
import "./components/assets/index.scss"
import EmailSettings from "./components/emailSettings";

const Settings = (props) => {
  const { t, Feature } = props;

  const location = useLocation();

  const renderSelectedTabContent = () => {
    const searchParams = new URLSearchParams(location.search);


    switch (searchParams.get("tab")) {
      case "qr":
        if(!getFeatureStatusByTitle(Feature, "qr-code")){
          return (
            <div>
              {t("Please upgrade your plan to access this feature")}.
            </div>
          );
        }
        return <QRCodePage />;
      case "risk-customization":
        if(!getFeatureStatusByTitle(Feature, "risk-assessment")){
          return (
            <div>
              {t("Please upgrade your plan to access this feature")}.
            </div>
          );
        }
        return <RiskCustomazation />;
      case "news-monitoring":
        return <NewsMonitoring />;
      case "routing":
        return <Routing />;
      case "email-settings": 
        return <EmailSettings />
      default:
        return <QRCodePage />;
    }
  };

  return (
    <div className="page-content ">
      <Container fluid>
        <Row>
          <Col lg="12" className="mb-4">
            <Navigator
              backButtonOptions={() => {
                return {
                  enable: false,
                  // onClick: () =>
                  //   history.push("/admin/data-protection/data-subject-request"),
                  title: props.t("Back"),
                  icon: <i className="ri-arrow-left-fill"></i>,
                };
              }}
              breadCrumbs={[
                { title: "SIP", link: "/admin/data-protection" },
                {
                  title: "Settings",
                  link: `/admin/data-protection/administration/settings`,
                },
              ]}
            />
          </Col>

          <Col lg="12" className="mb-4">
            <div className="details-content-wrapper">
              <>
                <Tabs t={t} />
                <React.Fragment>
                  <Row>
                    <Col xl="12">
                      <Card style={{marginBottom:'0px'}}>
                        <CardBody className="tabOverview mt-2">
                          <div className="d-flex flex-column gap-4 mb-4 mt-1">
                            {renderSelectedTabContent()}
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </React.Fragment>
              </>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStatetoProps = (state) => {
  const { token } = state.Login;
  const { Organization } = state;
  const { Modules } = state;
  const feature= Modules.list.find((item) => item?.name === Modules.active)?.features;
  return {
    user: state.Login.user,
    token,
    organization: Organization,
    Feature: feature,
  };
};

export default withNamespaces()(connect(mapStatetoProps)(memo(Settings)));

