import {
  FIELD_REPORT_INCIDENT_DESCRIPTION,
  FIELD_REPORT_CATEGORIES,
  FIELD_REPORT_LEGAL_ENTITY,
  FIELD_REPORT_AFFECTED_LOCATION,
  FIELD_REPORT_WHEN_HAPPENED,
  FIELD_REPORT_WHO_INVOLVED,
  FIELD_REPORT_WHAT_HAPPENED,
  FIELD_REPORT_WHOM_REPORTED_TO,
  FIELD_REPORT_HOW_AWARE_FACTS,
  FIELD_REPORT_HOW_PROVE_FACTS,
  FIELD_REPORT_ATTACHMENTS,
  FIELD_OPTIONAL_PHONE_NUMBER,
  FIELD_PHONE_NUMBER,
  FIELD_EMAIL,
  FIELD_REPORT_USER_FIRST_NAME,
  FIELD_REPORT_USER_RELATION,
  FIELD_REPORT_USER_LAST_NAME,
  FIELD_REPORT_USER_POSITION,
  FIELD_REPORT_USER_TITLE,
  FIELD_REPORT_USER_ORGANIZATION,
  FIELD_REPORT_AVAILABLE_TIME,
  FIELD_MEETING_AVAILABLE_DATE_FROM,
  FIELD_MEETING_AVAILABLE_DATE_TO,
  FIELD_MEETING_AVAILABLE_TIME,
  FIELD_TYPE_MULTI_CHECKBOX,
  FIELD_TYPE_MULTI_RADIO,
  FIELD_TYPE_SELECT,
  FIELD_TYPE_REPORT_UPLOAD_FILE,
  REPORT_FIELDS,
  REPORT_STATUS_LIST,
  REPORT_TYPE_ANONYMOUS,
  REPORT_TYPE_CONFIDENT,
  REPORT_TYPE_MEETING,
  IMAGE_REPORT_ANONYMOUSLY,
  IMAGE_REPORT_CONFIDENTLY,
  IMAGE_ARRANGE_MEETING,
  REPORT_STATUS_LIST_USER,
  REPORT_STATUS_PENDING_DELETION,
  REPORT_STATUS_DELETED,
} from "../../components/constants/index";

import _ from "lodash";

import UserUtils from "./UserUtils";

import moment from "moment";

export default class ReportUtils {
  constructor() {
    this.userUtils = new UserUtils();
  }

  getEmptyCreateReportParams(type) {
    return {
      type: type,
      description: "",
      category: [],
      email: "",
      question_answer: null,
      relation_company: "",
      salutation: "",
      first_name: "",
      last_name: "",
      organization: "",
      position: "",
      phone: "",
      phone_available_time: null,
    };
  }

  getValueByType(type, value) {
    const reportOptions = [];

    switch (type) {
      case FIELD_TYPE_SELECT:
        return value ? value.value : "";
      case FIELD_TYPE_MULTI_RADIO:
        if (value) {
          const reportOption = {
            optionId: value.value,
            value: value.childValue || "",
          };

          reportOptions.push(reportOption);
        }

        return reportOptions;
      case FIELD_TYPE_MULTI_CHECKBOX:
        if (Array.isArray(value) && value.length > 0) {
          for (let i = 0; i < value.length; i++) {
            const reportOption = {
              optionId: value[i].value,
              value: value[i].childValue || "",
            };

            reportOptions.push(reportOption);
          }
        }

        return reportOptions;
      case FIELD_TYPE_REPORT_UPLOAD_FILE:
        const ids = [];

        if (Array.isArray(value) && value.length > 0) {
          for (let i = 0; i < value.length; i++) {
            const id = value[i].id;

            ids.push(id);
          }
        }

        return ids;
      default:
        return value || "";
    }
  }

  mapFieldToReport(report, field) {
    const fieldName = field && field.name;

    if (fieldName) {
      const questionId = field.questionId;
      const value = field.value;
      const type = field.type;
      report.question_answer = report.question_answer || [];

      switch (fieldName) {
        case FIELD_REPORT_LEGAL_ENTITY.name:
        case FIELD_REPORT_AFFECTED_LOCATION.name:
        case FIELD_REPORT_WHO_INVOLVED.name:
        case FIELD_REPORT_WHAT_HAPPENED.name:
        case FIELD_REPORT_WHOM_REPORTED_TO.name:
        case FIELD_REPORT_HOW_AWARE_FACTS.name:
        case FIELD_REPORT_HOW_PROVE_FACTS.name:
        case FIELD_REPORT_WHEN_HAPPENED.name:
          if (questionId && value) {
            const question = {
              id: questionId,
              answer: this.getValueByType(type, value),
            };

            report.question_answer.push(question);
          }

          break;
        case FIELD_REPORT_CATEGORIES.name:
          const ids = [];

          if (Array.isArray(value) && value.length > 0) {
            for (let i = 0; i < value.length; i++) {
              ids.push(value[i].value);
            }
          }

          report[fieldName] = ids;

          break;
        case FIELD_REPORT_INCIDENT_DESCRIPTION.name:
        case FIELD_OPTIONAL_PHONE_NUMBER.name:
        case FIELD_PHONE_NUMBER.name:
        case FIELD_EMAIL.name:
        case FIELD_REPORT_USER_ORGANIZATION.name:
        case FIELD_REPORT_USER_TITLE.name:
        case FIELD_REPORT_USER_FIRST_NAME.name:
        case FIELD_REPORT_USER_LAST_NAME.name:
        case FIELD_REPORT_USER_RELATION.name:
        case FIELD_REPORT_USER_POSITION.name:
        case FIELD_REPORT_AVAILABLE_TIME.name:
        case FIELD_REPORT_ATTACHMENTS.name:
        case FIELD_MEETING_AVAILABLE_DATE_FROM.name:
        case FIELD_MEETING_AVAILABLE_DATE_TO.name:
        case FIELD_MEETING_AVAILABLE_TIME.name:
          report[fieldName] = this.getValueByType(type, value);

          break;
        default:
          break;
      }
    }

    return report;
  }

  mapUserToReport(report, user) {
    if (user && this.userUtils.isEmployee(user)) {
	  if((report.email === "" && report.type === REPORT_TYPE_MEETING) || report.type !== REPORT_TYPE_MEETING)
	  {
          report.email = user.email;
      }
      
      report.position = user.position;
      report.organization = user.organization ? user.organization.name : "";
      report.first_name = user.first_name;
      report.last_name = user.last_name;
      report.salutation = user.title || "";
      report.relation_company = "";

      const options = FIELD_REPORT_USER_RELATION.options;

      if (options) {
        const relation = options[0].value;

        if (typeof relation === "string") {
          report.relation_company = relation;
        }
      }
    }

    return report;
  }

  getCreateReportData(
    fields,
    type,
    user
  ) {
    let report = this.getEmptyCreateReportParams(type);

    if (fields && fields.length > 0) {
      for (let i = 0; i < fields.length; i++) {
        const field = fields[i];
        report = this.mapFieldToReport(report, field);
      }
    }

    report = this.mapUserToReport(report, user);

    return report;
  }

  mapReportToField(report, field) {
    const fieldName = field && field.name;

    if (fieldName && report) {
      const questionId = field.questionId;
      const options = field.options || [];
      const fieldName = field.name;
      const questions = report.question_answer || [];

      switch (fieldName) {
        case FIELD_REPORT_LEGAL_ENTITY.name:
        case FIELD_REPORT_AFFECTED_LOCATION.name:
        case FIELD_REPORT_WHO_INVOLVED.name:
        case FIELD_REPORT_WHAT_HAPPENED.name:
        case FIELD_REPORT_WHOM_REPORTED_TO.name:
        case FIELD_REPORT_HOW_AWARE_FACTS.name:
        case FIELD_REPORT_HOW_PROVE_FACTS.name:
          if (questionId) {
            const foundQuestion = questions.find(
              (question) => question.id === questionId
            );

            if (foundQuestion) {
              field = this.mapReportValueToField(foundQuestion.answer, field);
            }
          }

          break;
        case FIELD_REPORT_WHEN_HAPPENED.name:
          if (questionId) {
            const foundQuestion = questions.find(
              (question) => question.id === questionId
            );

            if (foundQuestion) {
              field = this.mapReportValueToField(foundQuestion.answer, field);
            }
          }

          break;
        case FIELD_REPORT_CATEGORIES.name:
          const ids = report[fieldName] || [];

          if (Array.isArray(ids) && ids.length > 0) {
            const selectedOptions = [];

            for (let i = 0; i < ids.length; i++) {
              const foundOption = options.find(
                (option) => option.value === ids[i]
              );

              if (foundOption) {
                selectedOptions.push(foundOption);
              }
            }

            field.value = selectedOptions;
          }

          break;
        case FIELD_REPORT_INCIDENT_DESCRIPTION.name:
        case FIELD_OPTIONAL_PHONE_NUMBER.name:
        case FIELD_PHONE_NUMBER.name:
        case FIELD_EMAIL.name:
        case FIELD_REPORT_USER_ORGANIZATION.name:
        case FIELD_REPORT_USER_TITLE.name:
        case FIELD_REPORT_USER_FIRST_NAME.name:
        case FIELD_REPORT_USER_LAST_NAME.name:
        case FIELD_REPORT_USER_RELATION.name:
        case FIELD_REPORT_USER_POSITION.name:
        case FIELD_REPORT_AVAILABLE_TIME.name:
        case FIELD_MEETING_AVAILABLE_DATE_FROM.name:
        case FIELD_MEETING_AVAILABLE_DATE_TO.name:
        case FIELD_MEETING_AVAILABLE_TIME.name:
          field = this.mapReportValueToField(report[fieldName], field);

          break;
        case FIELD_REPORT_ATTACHMENTS.name:
          field = this.mapReportValueToField(report.attachments, field);

          break;
        default:
          break;
      }
    }

    return field;
  }

  mapReportValueToField(value, field) {
    const type = field && field.type;

    if (type) {
      const options = field.options || [];

      switch (type) {
        case FIELD_TYPE_SELECT:
          if (typeof value === "string") {
            const foundOption = options.find(
              (option) => option.value === value
            );

            if (foundOption) {
              field.value = foundOption;
            }
          }

          break;
        case FIELD_TYPE_MULTI_RADIO:
          if (Array.isArray(value) && value.length > 0) {
            const selectedOption = value[0];
            const foundOption = options.find(
              (option) => option.value === selectedOption.optionId
            );

            if (foundOption) {
              foundOption.childValue = selectedOption.value;
              field.value = foundOption;
            }
          }

          break;
        case FIELD_TYPE_MULTI_CHECKBOX:
          if (Array.isArray(value) && value.length > 0) {
            const fieldValue = [];

            for (let i = 0; i < value.length; i++) {
              const selectOption = value[i];
              const foundOptionIndex = options.findIndex(
                (option) => option.value === value[i].optionId
              );

              if (foundOptionIndex > -1) {
                options[i].childValue = selectOption.value;
                fieldValue.push(options[i]);
              }
            }

            field.options = options;
            field.value = fieldValue;
          }

          break;
        case FIELD_TYPE_REPORT_UPLOAD_FILE:
          const files = [];

          if (Array.isArray(value) && value.length > 0) {
            for (let i = 0; i < value.length; i++) {
              files.push(value[i]);
            }
          }

          field.value = files;

          break;
        default:
          field.value = value;

          break;
      }
    }

    return field;
  }

  getFieldsFromReport(report) {
    if (typeof report !== "object") {
      return null;
    }

    const fields = [];

    for (let i = 0; i < REPORT_FIELDS.length; i++) {
      let newField = _.cloneDeep(REPORT_FIELDS[i]);
      newField = this.mapReportToField(report, newField);

      fields.push(newField);
    }

    return fields;
  }

  getCategoryKeys(ids, categories) {
    if (!categories || !Array.isArray(categories)) {
      // Handle the case where categories is null, undefined, or not an array
      return [];
    }
  
    const list = ids?.map((categoryId) => {
      const categoryIndex = categories.findIndex((item) => item && item.id === categoryId);
      if (categoryIndex !== -1) {
        const category = categories[categoryIndex];
        return category.title;
      }
    });
  
    return list?.filter(Boolean);
  }
  

  getReportStatus(user, status) {
    if (this.userUtils.isAnalyst(user) || this.userUtils.isAnalystAdmin(user)) {
      return REPORT_STATUS_LIST.find((item) => item.id === status);
    }

    return REPORT_STATUS_LIST_USER.find((item) => item.id === status);
  }

  getReportStatusOptions() {
    const options = [];

    for (let i = 0; i < REPORT_STATUS_LIST.length; i++) {
      const statusItem = REPORT_STATUS_LIST[i];
      const option = {
        value: statusItem.id.toString(),
        titleKey: statusItem.titleKey,
      };

      options.push(option);
    }

    return options;
  }

  getSelectStatusOptions(status) {
    const options = [];

    for (let i = 0; i < REPORT_STATUS_LIST.length; i++) {
      const statusItem = REPORT_STATUS_LIST[i];
      const option = {
        value: statusItem.id.toString(),
        titleKey: statusItem.titleKey,
      };

      if(statusItem.id === REPORT_STATUS_DELETED && status !== REPORT_STATUS_PENDING_DELETION) {
        continue;
      }
      
      options.push(option);
    }

    return options;
  }

  getAssignedAnalyst(report) {
    const analyst = report.analyst_assigned;

    if (!_.isEmpty(analyst)) {
      return this.userUtils.getName(analyst);
    }

    return "";
  }

  sortCommentsByCreatedAt(comments) {
    if (Array.isArray(comments) && comments.length > 0) {
      return comments.sort((a, b) => {
        const firstCreatedAt = a.created_at;
        const secondCreatedAt = b.created_at;

        return firstCreatedAt < secondCreatedAt ? 1 : -1;
      });
    }

    return comments;
  }

  getReportTypeIcon(report) {
    const type = report.type;

    switch (type) {
      case REPORT_TYPE_ANONYMOUS:
        return IMAGE_REPORT_ANONYMOUSLY;
      case REPORT_TYPE_CONFIDENT:
        return IMAGE_REPORT_CONFIDENTLY;
      case REPORT_TYPE_MEETING:
        return IMAGE_ARRANGE_MEETING;
      default:
        return null;
    }
  }

  getReportTypeKey(report) {
    const type = report.type;

    switch (type) {
      case REPORT_TYPE_ANONYMOUS:
        return "Anonymous";
      case REPORT_TYPE_CONFIDENT:
        return "Confidential";
      case REPORT_TYPE_MEETING:
        return "Meeting";
      default:
        return "";
    }
  }

  isAssignedToAnalyst(report, analystId) {
    const analyst = report.analyst_assigned;

    if (!_.isEmpty(analyst) && analyst.id === analystId) {
      return true;
    }

    return false;
  }


  isDeletetedToAnalyst(report) {
    if (report.status === 5 ) {
      return true;
    }
    return false;
  }




  getCurrentDateTime() {
    return moment().format("DD/MM/YYYY HH:mm Z");
  }

  getPhoneAvailabaleKeys(report) {
    const keys = [];

    if (!report) {
      return keys;
    }
    const options = FIELD_REPORT_AVAILABLE_TIME.options;
    const availableTimes = report.phone_available_time;

    if (availableTimes && availableTimes.length > 0 && options) {
      for (let i = 0; i < availableTimes.length; i++) {
        const optionId = availableTimes[i].optionId;
        const foundItem = options.find((option) => option.value === optionId);

        if (foundItem) {
          if (foundItem.value === options[5].value) {
            keys.push(availableTimes[i].value);
          } else {
            keys.push(foundItem.titleKey);
          }
        }
      }
    }
    return keys;
  }
}
