import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, Button, Alert } from "reactstrap";
import { Input } from "reactstrap";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import SweetAlert from "react-bootstrap-sweetalert";

import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import i18next from "i18next";

import ToolkitProvider from "react-bootstrap-table2-toolkit";

import axios from "axios";
import {
  API_URL_CUSTOMER_API,
  API_URL_ADMIN_GET_USER_LOGS,
  API_URL_ADMIN_GET_USER_MANAGEMENT_LIST,
  API_URL_ADMIN_DELETE_USER_MANAGEMENT,
  API_URL_ADMIN_UPDATE_USER_MANAGEMENT,
  API_URL_PARTNER_GET_CLIENT_MANAGEMENT_LIST,
  EMPTY_LIST,
} from "../../../common/constants";

import Breadcrumbs from "../../../components/Common/Breadcrumb";


import { successNotification, errorNotification } from "../../../store/actions";

import { post } from "../../../helpers/request_helper";
import { isFeatureEnabledForActiveModule } from 'src/helpers/module_helper';

import UserUtils from "../../../services/utils/UserUtils";
import DateUtils from "../../../services/utils/DateUtils";
import viewIcon from "../../../assets/icons/viewIcon.png";

class ClientManagemnet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "SIP", link: "/" },
        { title: this.props.t("Client Management"), link: "#" },
      ],
      page: 1,
      users: [],
      clients: [],
      usersFetched: false,
      sizePerPage: 10,
      userIdLogs: null,
      userIdGetLogs: {},
      userIdToDelete: null,
      deletionReason: null,
    };

    this.userUtils = new UserUtils();

    // this.deleteUser = this.deleteUser.bind(this);
  }


  dateUtils = new DateUtils();
  render() {
    const columns = [
      {
        dataField: "id",
        text: this.props.t("ID"),
        sort: true,
      },
      {
        dataField: "name",
        text: this.props.t("Name"),
        sort: true,
        
      },
      {
        dataField: "createdAt",
        text: this.props.t("Created At"),
        sort: true,
        formatter: (cell, row) => {
          return this.dateUtils.convertTimestampToOnlyDate(cell)
        } 
      },
      {
        dataField: "",
        text: this.props.t(""),
        sort: false,
        formatter: (cell, row) => {
          return <Link style={{display: "flex", cursor: "pointer"}} to={{
            pathname: `/admin/administration/client/${row.id}`,
            state: { 
              id: row.id, 
              name: row.name,
              createdAt: row.createdAt,
              partnerAnalysts: row.partnerAnalysts
            }
          }} >
            <img src={viewIcon} width={20} height={20} alt="Details Icon" />
            <p style={{ margin: "0 5px" }}>{this.props.t("View")}</p>
          </Link>
        }
      },
      // {
      //   dataField: "position",
      //   text: this.props.t("Position"),
      //   sort: true,
      // },
      // {
      //   dataField: "roles",
      //   text: this.props.t("Assigned Role"),
      //   sort: false,
      // },
      // {
      //   dataField: "organization_name",
      //   text: `${this.props.t("Company")} ${this.props.t("name")}`,
      //   sort: false,
      // },
      // {
      //   dataField: "status",
      //   text: this.props.t("User status"),
      //   sort: false,
      // },
      // {
      //   dataField: "edit",
      //   text: this.props.t("Edit"),
      //   sort: false,
      // },
      // {
      // 	dataField: "logs",
      // 	text: this.props.t("Logs"),
      // 	sort: false,
      // },
    ];

    const defaultSorted = [];

    const pageOptions = {
      sizePerPage: 25,
      totalSize: this.state.users.length,
      custom: true,
    };

    let data = [];

    for (const key in this.state.userIdGetLogs) {
      if (this.state.userIdGetLogs[key].length) {
        data.push(<li>{`${key}: ${this.state.userIdGetLogs[key]}`}</li>);
      }
    }

    // let logs = Object.keys(this.state.userIdGetLogs);

    // {console.log(logs)}
    // {console.log(logs)}

    return (
      <React.Fragment>
        {/* {this.state.userIdLogs !== null ? (
          <SweetAlert
            title={this.props.t("Logs")}
            onCancel={() =>
              this.setState({
                userIdLogs: null,
              })
            }
            onConfirm={() =>
              this.setState({
                userIdLogs: null,
              })
            }
          >
            <ul style={{ textAlign: "left" }}>{data}</ul>
          </SweetAlert>
        ) : null}
        {this.state.userIdToDelete !== null ? (
          <SweetAlert
            title={this.props.t("Are you sure")}
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            cancelBtnText={this.props.t("Cancel")}
            confirmBtnText={this.props.t("OK")}
            onCancel={() =>
              this.setState({
                userIdToDelete: null,
              })
            }
            onConfirm={this.deleteUser}
          >
            {this.props.t("You wont be able to revert this")}
          </SweetAlert>
        ) : null} */}

        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.props.t("Client Management")}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <div
                      className="flex mb-3"
                      style={{ display: "flex", flexDirection: "row-reverse" }}
                    >
                      {/* {isFeatureEnabledForActiveModule(
                        "ad-settings"
                      ) && (
                        <div className="flex-inline text-sm-end">
                          <Link to="/admin/administration/users/ad-settings">
                            <Button
                              type="button"
                              color="primary"
                              className="btn-rounded mb-2 me-2"
                            >
                              {this.props.t("AD settings")}
                            </Button>
                          </Link>
                        </div>
                      )}
                      {isFeatureEnabledForActiveModule(
                        "new-user"
                      ) && (
                        <div className="flex-inline text-sm-end">
                          <Link to="/admin/administration/users/add">
                            <Button
                              type="button"
                              color="success"
                              className="btn-rounded mb-2 me-2"
                            >
                              <i className="mdi mdi-plus me-1" />{" "}
                              {this.props.t("New user")}
                            </Button>
                          </Link>
                        </div>
                      )} */}
                    </div>
                    {/* {isFeatureEnabledForActiveModule(
                      "list-users"
                    ) ? ( */}
                    {
                      this.state.clients.length > 0 ?
                      <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField="id"
                        columns={columns}
                        data={this.state.clients}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="id"
                            columns={columns}
                            data={this.state.clients}
                            search
                          >
                            {(toolkitProps) => (
                              <React.Fragment>
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        keyField={"id"}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row className="align-items-md-center mt-30">
                                  <Col className="inner-custom-pagination d-flex">
                                    <div className="d-inline">
                                      <SizePerPageDropdownStandalone
                                        {...paginationProps}
                                      />
                                    </div>
                                    <div className="text-md-right ms-auto">
                                      <PaginationListStandalone
                                        {...paginationProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider> :
                      <div className="alert alert-warning" role="alert">
                          <p
                              style={{
                              textAlign: "center",
                              marginBottom: 0,
                              width: "100%",
                              }}
                          >
                              {this.props.t(EMPTY_LIST)}
                          </p>
                      </div> }
                    {/* ) : (
                      <Alert color="warning">
                        {this.props.t(
                          "You do not have permission to access these pages"
                        )}
                      </Alert>
                    )} */}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }

  // updateUserStatus(id, status) {
  //   if (!id || !status) {
  //     return;
  //   }

  //   console.log(status);

  //   const url = `${API_URL_CUSTOMER_API}user/${id}/change_status`;
  //   const data = {
  //     status: status,
  //   };
  //   const header = {
  //     headers: {
  //       Authorization: `Bearer ${this.props.token}`,
  //     },
  //   };

  //   new Promise(async (res, rej) => {
  //     await axios
  //       .post(url, data, header)
  //       .then(() => {
  //         this.props.successNotification({
  //           code: "saved",
  //           message: this.props.t("User status updated successfully"),
  //         });
  //         let newUsers = this.state.users.map((user) => {
  //           if (user.id === id) {
  //             let newStatus =
  //               user.status === "ACTIVE" ? (
  //                 <Button
  //                   color="success"
  //                   size="sm"
  //                   onClick={() => this.updateUserStatus(user.id, "INACTIVE")}
  //                 >
  //                   {i18next.t("ACTIVE")}
  //                 </Button>
  //               ) : (
  //                 <Button
  //                   color="danger"
  //                   size="sm"
  //                   onClick={() => this.updateUserStatus(user.id, "ACTIVE")}
  //                 >
  //                   {i18next.t("InActive")}
  //                 </Button>
  //               );

  //             return { ...user, status: newStatus };
  //           }

  //           return user;
  //         });

  //         this.setState({
  //           users: newUsers,
  //         });

  //         // this.renderUsersList(newUsers);
  //         this.fetchUsers();

  //         this.props.history.push("/admin/administration/users");
  //       })
  //       .catch((err) => {
  //         if (err.response) {
  //           const data = err.response.data;

  //           this.props.errorNotification({
  //             code: data.error,
  //             message: this.props.t(data.message),
  //           });
  //           console.log(data);

  //           rej(data.error);
  //         }
  //         rej("fatal_error");
  //       });
  //   });

  //   // post(API_URL_ADMIN_UPDATE_USER_MANAGEMENT, {
  //   //     user_id: this.state.user.id
  //   // }, {
  //   //     headers: {
  //   //         Authorization: `Bearer ${this.props.token}`
  //   //     }
  //   // })
  //   // .then(() => {
  //   //     this.props.successNotification({
  //   //         code: 'saved',
  //   //         message: this.props.t("Data was saved successfully")
  //   //     });
  //   // 	this.setState({

  //   // 	})
  //   // });
  // }

  renderUsersList(list) {
    return list.map((item) => {

      return {
        id: item.id,
        name: item.name,
        partnerAnalysts: item.partner_analysts,
        createdAt: item.created_at,
      };
    });
  }

  // getUserLogs(id) {
  //   const url = API_URL_ADMIN_GET_USER_LOGS;
  //   const data = {
  //     user_id: id,
  //   };
  //   const header = {
  //     headers: {
  //       Authorization: `Bearer ${this.props.token}`,
  //     },
  //   };

  //   new Promise(async (res, rej) => {
  //     await axios
  //       .post(url, data, header)
  //       .then((data) => {
  //         const response = data.data.data;

  //         console.log(response);

  //         typeof response !== "undefined"
  //           ? this.setState({
  //               userIdLogs: id,
  //               userIdGetLogs: response,
  //             })
  //           : this.props.errorNotification({
  //               code: "",
  //               message: this.props.t("No logs have been recorded"),
  //             });

  //         this.props.history.push("/admin/administration/users");
  //       })
  //       .catch((err) => {
  //         if (err.response) {
  //           const data = err.response.data;

  //           console.log(data);

  //           this.props.errorNotification({
  //             code: data.error,
  //             message: this.props.t(data.message),
  //           });

  //           rej(data.error);
  //         }
  //         rej("fatal_error");
  //       });
  //   });
  // }

  // deleteUser() {
  //   const url = API_URL_ADMIN_DELETE_USER_MANAGEMENT;
  //   const data = {
  //     user_id: this.state.userIdToDelete,
  //   };
  //   const header = {
  //     headers: {
  //       Authorization: `Bearer ${this.props.token}`,
  //     },
  //   };

  //   new Promise(async (res, rej) => {
  //     await axios
  //       .post(url, data, header)
  //       .then(() => {
  //         this.props.successNotification({
  //           code: "saved",
  //           message: this.props.t("User deleted successfully"),
  //         });
  //         this.setState({
  //           users: this.state.users.filter((item) => {
  //             return item.id !== this.state.userIdToDelete;
  //           }),
  //           userIdToDelete: null,
  //         });
  //         this.props.history.push("/admin/administration/users");
  //       })
  //       .catch((err) => {
  //         if (err.response) {
  //           const data = err.response.data;

  //           // store.dispatch(errorNotification({
  //           // 	code: data.error,
  //           // 	message: translateError(data)
  //           // }));

  //           console.log(data);

  //           if (
  //             data.error === "unknown_error" &&
  //             data.message === "Access Denied."
  //           ) {
  //             this.props.errorNotification({
  //               code: "saved",
  //               message: this.props.t("User can not be deleted"),
  //             });
  //           } else {
  //             this.props.errorNotification({
  //               code: data.error,
  //               message: this.props.t(data.message),
  //             });
  //           }

  //           rej(data.error);
  //         }
  //         rej("fatal_error");
  //       });
  //   });
  //   // post(
  //   // 	API_URL_ADMIN_DELETE_USER_MANAGEMENT,
  //   // 	{
  //   // 		user_id: this.state.userIdToDelete,
  //   // 	},
  //   // 	{
  //   // 		headers: {
  //   // 			Authorization: `Bearer ${this.props.token}`,
  //   // 		},
  //   // 	}
  //   // ).then(() => {
  //   // 	this.props.successNotification({
  //   // 		code: "saved",
  //   // 		message: this.props.t("User deleted successfully"),
  //   // 	});
  //   // 	this.setState({
  //   // 		users: this.state.users.filter((item) => {
  //   // 			return item.id !== this.state.userIdToDelete;
  //   // 		}),
  //   // 		userIdToDelete: null,
  //   // 	});
  //   // 	this.props.history.push("/admin/administration/users");
  //   // });
  // }

  fetchUsers() {
    axios
      .get(
        API_URL_PARTNER_GET_CLIENT_MANAGEMENT_LIST,
        
        {
          headers: {
            Authorization: `Bearer ${this.props.token}`,
          },
        },
        {
          pageIndex: 1,
          pageSize: 10,
          // sortField: id,
          // sortOrder: 'desc',

        },
      )
      .then((response) => {
        // console.log(response);
        this.setState({
          clients: this.renderUsersList(response.data.data.clients),
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentDidUpdate() {
    if (!this.state.usersFetched) {
      this.setState({ usersFetched: true });
      this.fetchUsers();
    }
  }

  componentDidMount() {
    if (!this.state.usersFetched) {
      this.setState({ usersFetched: true });
      this.fetchUsers();
    }
  }
}

const mapStatetoProps = (state) => {
  const { token } = state.Login;
  const { App } = state;
  return { token, App };
};

export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
      successNotification,
      errorNotification,
    })(ClientManagemnet)
  )
);
