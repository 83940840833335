
import React, { Component } from "react";

import { Row, Col, Button, Container, Label } from "reactstrap";

import { connect } from "react-redux";

import { withRouter, Link } from "react-router-dom";

import { AvForm, AvField } from "availity-reactstrap-validation";

import InputMask from "react-input-mask";

import MaterialInput from "@material-ui/core/Input";

import darkLogo from "../../../assets/images/dark-logo.svg";

import { withNamespaces } from "react-i18next";

import { post } from "../../../helpers/request_helper";

import { 
    API_URL_REQUEST_RESET_PASSWORD,
    API_URL_EXCHANGE_RESET_PASSWORD_TOKEN
} from '../../../common/constants';

import { successNotification } from "../../../store/actions";
import OrganizationHeader from "../components/OrganizationHeader";

class GetEmail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: null,
            otp: {
                error: false,
                message : '',
                code: ""
            },
        };
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
        this.onOTPChange = this.onOTPChange.bind(this);
        this.verifyOTPCode = this.verifyOTPCode.bind(this);
        this.successNotification = this.props.successNotification.bind(this);
    }

    onOTPChange(e){
        this.setState({
            otp: {
                ...this.state.otp,
                code : e.target.value
            }
        });
    }

    verifyOTPCode(){
        const otpCode = this.state.otp.code.replaceAll('-','');

        post(API_URL_EXCHANGE_RESET_PASSWORD_TOKEN, {
            otp: otpCode,
            token : this.state.token
        },{})
        .then(() => {
            this.successNotification({
                code: 'password-reset-ok',
                message: this.props.t("Your request was sent successfully. A reset password email will be sent to your inbox.")
            });
            this.props.history.push('/signin')
        });
    }

    renderOTPForm(){
        return (
            <div>
                <div className="p-2 mt-5">
                    <AvForm className="form-horizontal" onValidSubmit={this.verifyOTPCode} >

                        <div className="mb-3">
                            <Label className="form-label">{ this.props.t("Enter code") }</Label>
                            <InputMask autoFocus mask="9-9-9-9-9-9" className="form-control input-color" value={this.state.otpCode} onChange={this.onOTPChange}>
                                {
                                    (inputProps) => <MaterialInput {...inputProps} disableUnderline />
                                }
                            </InputMask>
                        </div>

                        <div className="mt-4 text-center">
                            <Button color="primary" className="w-md waves-effect waves-light" type="submit">{ this.props.t("Submit") }</Button>
                        </div>
                    </AvForm>
                </div>
            </div>
        )
    }

    handleValidSubmit(event, values) {
        post(API_URL_REQUEST_RESET_PASSWORD, {
            email: values.useremail,
            organization_id: this.props.Organization.id
        },{})
        .then((response) => {
            this.setState({
                token: response.data.data.token
            })
        });
    }

    render() {
        return (
            <React.Fragment>
                <div>
                    <Container fluid className="p-0">
                        <Row className="g-0">
                            <Col lg={4}>
                                <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                                    <div className="w-100">
                                        <Row className="justify-content-center">
                                            <Col lg={9}>
                                                <div>
                                                <OrganizationHeader />

                                                    {
                                                        this.state.token ? 
                                                            this.renderOTPForm() : (
                                                                <div className="p-2 mt-5">
                                                                    <AvForm className="form-horizontal" onValidSubmit={this.handleValidSubmit}>

                                                                        <div className="auth-form-group-custom mb-4">
                                                                            <i className="ri-mail-line auti-custom-input-icon"></i>
                                                                            <Label htmlFor="useremail">
                                                                                {this.props.t('Email address')}
                                                                            </Label>
                                                                            <AvField 
                                                                                name="useremail" 
                                                                                value={this.state.username} 
                                                                                type="email" 
                                                                                validate={{ email: true, required: true }} 
                                                                                errorMessage={this.props.t("This field is invalid")}
                                                                                className="form-control bootstrap-default" 
                                                                                id="useremail" 
                                                                                placeholder="" />
                                                                        </div>

                                                                        <div className="mt-4 text-center">
                                                                            <Button color="primary" className="w-md waves-effect waves-light" type="submit">
                                                                                {
                                                                                    this.props.loading ? 
                                                                                        "Loading..." : 
                                                                                        this.props.t("Reset password")
                                                                                }
                                                                            </Button>
                                                                        </div>
                                                                    </AvForm>
                                                                </div>
                                                            )
                                                    }

                                                    <div className="mt-5 text-center">
                                                        <p>
                                                            {this.props.t("© 2024 SMART INTEGRITY PLATFORM. All rights reserved.")}
                                                        </p>
                                                    </div>
                                                </div>

                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={8}>
                                <div className="authentication-bg">
                                    <div className="bg-overlay"></div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { Organization } = state;
    return { Organization };
}

const mapDispachToProps = (dispach) => {
    return {
        successNotification: (message) => dispach(successNotification(message))
    }
}

export default withNamespaces()(withRouter(
    connect(mapStatetoProps, mapDispachToProps)(GetEmail)
));
