import WBHandler from './wb';
import GDHandler from './gd';
import TPHandler from './tp';

export const handlers = {
    wb  :   (props) => {
        return <WBHandler {...props} />
    },
    cm  :   (props) => {
        // is same with wb
        return <WBHandler {...props} />
    },
    gd  :   (props) => {
        return <GDHandler {...props} />
    },
    tp  :   (props) => {
        return <TPHandler {...props} />
    }
};