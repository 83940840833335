import {TRANSLATION_KEY_COMMENT_INFO as KEY_COMMENT_INFO} from '../../common/constants/TranslationKeys';

export const COMMENT_TABS = [
    {
        id: 0,
        titleKey: KEY_COMMENT_INFO + 'MESSAGES'
    },
    {
        id: 1,
        titleKey: KEY_COMMENT_INFO + 'INTERNAL_COMMENTS'
    }
];
