import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useRef, useState } from "react";
import { withNamespaces } from "react-i18next";
import { toast } from "react-toastify";
import TableFilter from "src/modules/3rd-party-management/components/table-filter";
import CommonService from "src/modules/3rd-party-management/apis/CommonService";
import DateUtils from "src/services/utils/DateUtils";

const FilteringComponent = ({
    t,
    onChange
}) => {
    const [ tableFilters, setTableFilters ] = useState({
        fromDate    :   null,
        toDate      :   null,
        owner       :   null,
        manager     :   null,
        sortBy      :   null
    });

    const isFirstRender = useRef(true);

    const sortOptions = [
        {
            id      :   'priority',
            title   :   'Priority',
        },
        {
            id      :   'deadline',
            title   :   'Deadline'
        }
    ];

    const dateUtils = new DateUtils();

    const {
        isLoading: usersListIsLoading,
        isFetching: usersListIsFetching,
        data: usersList
    } = useQuery({
        queryKey                :   [
            "3rd-party-management-fetch-analysts-and-admins-list-query"
        ],
        queryFn                 :   async () => {
            const service = CommonService.getInstance();

            return await service.fetchAnalystAndAdminAnalystList({});
        },
        cacheTime               :   0,
        refetchOnWindowFocus    :   false,
        onError                 :   (error) => {
            if (process.env.NODE_ENV === 'development') console.error(error);

            toast(t("An error occurred while fetching analyst admins list."), {
                type: "error",
            });
        }
    });

    const {
        isLoading: ownersAreLoading,
        isFetching: ownersAreFetching,
        data: ownersList
    } = useQuery({
        queryKey                :   [
            "3rd-party-management-fetch-analysts-and-owners-list-query"
        ],
        queryFn                 :   async () => {
            const service = CommonService.getInstance();

            return await service.fetchTaskOwners({});
        },
        cacheTime               :   0,
        refetchOnWindowFocus    :   false,
        onError                 :   (error) => {
            if (process.env.NODE_ENV === 'development') console.error(error);

            toast(t("An error occurred while fetching owners list."), {
                type: "error",
            });
        },
    });

    const tableFilterTabs = useMemo(() => {
        return [
            {
                type: 'date',
                name: 'deadline-date',
                title: 'Deadline',
                icon: <i className="ri-calendar-event-line" />,
                defaultValue: {
                    from: tableFilters['fromDate'],
                    to: tableFilters['toDate']
                }
            },
            {
                type: 'radio_list',
                name: 'sortBy',
                title: 'Sort By',
                icon: <i className="ri-sort-asc" />,
                defaultValue: tableFilters?.sortBy,
                data: sortOptions,
                options: {
                    disableAll: true
                },
                formatter: (item) => {
                    return t(item.title)
                }
            },
            {
                type: 'radio_list',
                name: 'owner',
                title: 'Task Owner',
                icon: <i className="ri-user-line" />,
                isLoading: ownersAreFetching || ownersAreLoading,
                defaultValue: tableFilters?.owner,
                data: ownersList || [],
                searchInListOption: {
                    enabled: true,
                    remote: false
                },
                formatter: (item) => {
                    return `${item.first_name} ${item.last_name}`
                }
            },
            {
                type: 'radio_list',
                name: 'manager',
                title: 'Task Manager',
                icon: <i className="ri-user-settings-fill" />,
                isLoading: usersListIsFetching || usersListIsLoading,
                defaultValue: tableFilters?.owner,
                data: usersList || [],
                searchInListOption: {
                    enabled: true,
                    remote: false
                },
                formatter: (item) => {
                    return `${item.first_name} ${item.last_name}`
                }
            }
        ]
    }, [
        tableFilters,
        usersListIsLoading,
        usersListIsFetching,
        ownersAreLoading,
        ownersAreFetching,
        t
    ]);

    const onTableFilterOkButtonClicked = (filters) => {
        if (filters) {
            const result = {}

            if ('deadline-date' in filters) {
                result['fromDate'] = dateUtils.convertDateToDate(
                    new Date(filters['deadline-date']?.from),
                    'YYYY-MM-DD'
                )

                result['toDate'] = dateUtils.convertDateToDate(
                    new Date(filters['deadline-date']?.to),
                    'YYYY-MM-DD'
                )
            }

            if ('sortBy' in filters) {
                result.sortBy = filters.sortBy || undefined
            }

            if ('owner' in filters) {
                result.owner = filters.owner !== 'all' ? filters.owner : undefined
            }

            if ('manager' in filters) {
                result.manager = filters.manager !== 'all' ? filters.manager : undefined
            }
            
            setTableFilters({
                ...tableFilters,
                ...result
            })
        } else {
            setTableFilters({
                fromDate    :   null,
                toDate      :   null,
                owner       :   null,
                manager     :   null,
                sortBy      :   null
            })
        }
    }

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }

        onChange && onChange(tableFilters)
    }, [ tableFilters ]);

    return (
        <TableFilter
            className="me-2"
            defaultActiveTab='deadline-date'
            tabs={tableFilterTabs}
            onOkButtonClicked={
                onTableFilterOkButtonClicked
            }
        />
    )
}

export default withNamespaces()(FilteringComponent);