import { memo, useEffect, useState } from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import { useHistory, useLocation } from "react-router-dom";

const Tabs = memo(({ t }) => {
  const [activeTab, setActiveTab] = useState("");
  const history = useHistory();
  const location = useLocation();

  const isActiveTab = (currentTab, tab) => currentTab === tab;

  const tabsLinks = [
    {
      key: "tom",
      title: t("TOM"),
    },
    {
      key: "task-management",
      title: t("Task Management"),
    },
  ];

  useEffect(() => {
    setActiveTab(new URLSearchParams(location.search).get("tab"));
  }, [location.search]);

  useEffect(() => {
    if (!new URLSearchParams(location.search).get("tab")) {
      history.push("?tab=tom");
    }
  }, []);

  return (
    <div
      style={{
        backgroundColor: "#D1D2D6",
      }}
    >
      <Nav tabs className="custom-style-1">
        {tabsLinks.map((link) => {
          return (
            <NavItem
              className={isActiveTab(activeTab, link.key) ? "active" : ""}
              key={link.key}
            >
              <NavLink
                className={isActiveTab(activeTab, link.key) ? "active" : ""}
                onClick={() => history.push(`?tab=${link.key}`)}
              >
                {t(link.title)}
              </NavLink>
            </NavItem>
          );
        })}
      </Nav>
    </div>
  );
});

export default Tabs;
