import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import { withNamespaces } from "react-i18next";
import { Card, CardBody, CardTitle, Tooltip } from "reactstrap";

class BarChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [],
      options: {},
      reload: false,
      processingTimeTooltip: false,
    };
  }

  // https://stackoverflow.com/questions/1026069/how-do-i-make-the-first-letter-of-a-string-uppercase-in-javascript
  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  render() {
    return (
      <>
        <Card>
          <CardBody>
            <div className="mb-4">
              <CardTitle>
                {this.props.title === "Processing time in days" ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>{this.props.t(this.props.title)}</div>
                    <i
                      className="fa fa-info-circle"
                      aria-hidden="true"
                      id="infoIconProcessingTime"
                    />
                    <Tooltip
                      placement="top"
                      isOpen={this.state.processingTimeTooltip}
                      target="infoIconProcessingTime"
                      toggle={() =>
                        this.setState({
                          processingTimeTooltip:
                            !this.state.processingTimeTooltip,
                        })
                      }
                    >
                      {this.props.t(
                        "This graph follows the time that the analyst takes from the moment they open a case until they finish it"
                      )}
                    </Tooltip>
                  </div>
                ) : (
                  this.props.t(this.props.title)
                )}
              </CardTitle>
            </div>
            <div className="mb-3">
              <ReactApexChart
                options={this.state.options}
                series={this.state.series}
                type="bar"
                height="200"
              />
            </div>
          </CardBody>
        </Card>
      </>
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data || prevProps.t !== this.props.t) {
      const label = Array.from(this.props.t("Day"))[0];

      const data = this.props.data;
      const items = [];
      const names = [];
      const colors = ["#00008B", "#DC143C", "#9400D3"];

      for (let i in data) {
        items.push({ x: this.props.t(i), y: parseInt(data[i]) });
        names.push(this.props.t(i));
      }

      this.setState({
        series: [
          {
            name: [],
            data: items,
          },
        ],
        options: {
          colors,
          legend: {
            show: true,
          },
          chart: {
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
              distributed: true,
            },
          },
          dataLabels: {
            enabled: true,
            position: "center",
            style: {
              fontSize: "12px",
            },

            // To show the unit, that is d as the day here
            formatter: function (val) {
              return val;
            },
          },

          tooltip: {
            theme: "light",
            enabled: false,
            followCursor: true,
          },
        },
      });
    }

    if (prevProps.t !== this.props.t) {
      this.setState({ reload: !this.state.reload });
    }
  }
}

export default withNamespaces()(BarChart);
