import { memo, useEffect, useState } from "react";
import { AvRadio, AvField } from "availity-reactstrap-validation";
import DateInputBefor from "src/components/Fields/DateInputBefor";
import { withNamespaces } from "react-i18next";
import DateUtils from "src/services/utils/DateUtils";
import CustomSelect from "src/modules/data-protection/components/questionar/Questions/CustomSelect";
import { useQuery } from "@tanstack/react-query";
import CommonService from "src/modules/data-protection/apis/CommonService";
import CountryList from "../CountryList";
import { toast } from "react-toastify";

const Option = (props) => {
  const {
    id,
    title,
    type,
    isSelected,
    groupId,
    elRefIdPrefix,
    defaultValue,
    extendedTitle,
    onChange,
    is_mandatory,
    options,
    isChildren,
    info,
  } = props;

  const dateUtils = new DateUtils();

  const [selectedValue, setSelectedValue] = useState("");
  const [countriesList, setCountriesList] = useState([]);

  const [changedOptionFlag, setChangedOptionFlag] = useState(false)

  const handleFetchCountriesListQuery = useQuery({
    queryKey: ["data-protection-countries-list"],
    queryFn: async () => {
      const service = CommonService.getInstance();

      return await service.countries();
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      if (process.env.NODE_ENV === "development") console.error(error);

      toast(props.t("An error occurred while fetching countries."), {
        type: "error",
      });
    },
  });

  useEffect(() => {
    const countries = handleFetchCountriesListQuery?.data;

    setCountriesList(countries || []);
  }, [handleFetchCountriesListQuery.data]);

  const renderOptionByType = () => {
    if (type === "date") {
      return (
        <>
          <AvRadio
            id={`${elRefIdPrefix}-question-${groupId}-option-${id}`}
            label={props.t(title)}
            datatitle={title}
            datatype={type}
            datarelinputref={`${elRefIdPrefix}-question-${groupId}-option-${id}-rel-input`}
            value={id}
          />
          {isSelected ? (
             <div style={{ width: '20vw', marginTop:'24px'}}>
            <DateInputBefor
              startFrom={new Date()}
              name="date"
              id={`${elRefIdPrefix}-question-${groupId}-option-${id}-rel-input`}
              value={selectedValue}
              onValueChanged={(value) => {
                setSelectedValue(value);
                onChange(value);
              }}
            />
            </div>
          ) : null}
        </>
      );
    } else if (type === "text") {
      return (
        <>
          <AvRadio
            id={`${elRefIdPrefix}-question-${groupId}-option-${id}`}
            label={props.t(title)}
            datatitle={title}
            datatype={type}
            datarelinputref={`${elRefIdPrefix}-question-${groupId}-option-${id}-rel-input`}
            value={id}
          />
          {isSelected ? (
              <div style={{ width: '20vw', marginTop:'24px'}}>
            <AvField
              type="text"
              id={`${elRefIdPrefix}-question-${groupId}-option-${id}-rel-input`}
              name="text"
              // value={selectedValue}
              defaultValue={ changedOptionFlag ? selectedValue : defaultValue }
              placeholder={props.t(extendedTitle || "")}
              validate={{
                required: { value: false },
              }}
              onChange={(e) => {
                setSelectedValue(e.target.value);
                onChange(e.target.value)
              }}
            />
            </div>
          ) : null}
        </>
      );
    }else if (type === "textarea") {
      return (
        <>
          <AvRadio
            id={`${elRefIdPrefix}-question-${groupId}-option-${id}`}
            label={props.t(title)}
            datatitle={title}
            datatype={type}
            datarelinputref={`${elRefIdPrefix}-question-${groupId}-option-${id}-rel-input`}
            value={id}
          />
          {isSelected ? (
            <div style={{ width: '20vw', marginTop:'24px'}}>
              <AvField
                type="textarea"
                id={`${elRefIdPrefix}-question-${groupId}-option-${id}-rel-input`}
                name="text"
                defaultValue={ changedOptionFlag ? selectedValue : defaultValue }
                placeholder={props.t(extendedTitle || "")}
                validate={{
                  required: { value: false },
                }}
                onChange={(e) => {
                  setSelectedValue(e.target.value);
                  onChange(e.target.value)
                }}
              />
            </div>
          ) : null}
        </>
      );
    } else if (type === "multi_select") {
      const newOptions = options?.map(o =>  {
        return {
          id: o?.id,
          title: o?.title,
          type: "multi_select",
          isChildren: isChildren
        }
      });

      return (
        <>
          <AvRadio
            id={`${elRefIdPrefix}-question-${groupId}-option-${id}`}
            label={props.t(title)}
            datatitle={title}
            datatype={type}
            datarelinputref={`${elRefIdPrefix}-question-${groupId}-option-${id}-rel-input`}
            value={id}
          />
          {isSelected ? (
                <div style={{ width: '20vw', marginTop:'24px'}}>
            <CustomSelect
              id={id}
              elRefIdPrefix={elRefIdPrefix}
              title={props.t(extendedTitle || "")}
              key={id}
              isMandatory={is_mandatory}
              options={newOptions }
              defaultValue={defaultValue}
              helpText={info}
              isChildren={isChildren}
              onChange={(e) => {
                onChange(e);
              }}
              isMulti={true}
            />
            </div>
          ) : null}
        </>
      );
    } 
    else if (type === "country_list") {
      return (
        <>
          <AvRadio
            id={`${elRefIdPrefix}-question-${groupId}-option-${id}`}
            label={props.t(title)}
            datatitle={title}
            datatype={type}
            datarelinputref={`${elRefIdPrefix}-question-${groupId}-option-${id}-rel-input`}
            value={id}
          />
          {isSelected ? (
              <div style={{ width: '20vw', marginTop:'24px'}}>
            <CountryList
              id={id}
              elRefIdPrefix={elRefIdPrefix}
              title={ extendedTitle || title}
              key={id}
              isMandatory={is_mandatory}
              defaultValue={defaultValue}
              onChange={(e) => onChange(e)}
              options={countriesList}
              helpText={info}
              isLoading={
                handleFetchCountriesListQuery.isLoading ||
                handleFetchCountriesListQuery.isFetching
              }
              isDisabled={
                handleFetchCountriesListQuery.isLoading ||
                handleFetchCountriesListQuery.isFetching
              }
            />
            </div>
          ) : null}
        </>
      );
    }  else if (type === "country_multi_select") {
      return (
        <>
          <AvRadio
            id={`${elRefIdPrefix}-question-${groupId}-option-${id}`}
            label={props.t(title)}
            datatitle={title}
            datatype={type}
            datarelinputref={`${elRefIdPrefix}-question-${groupId}-option-${id}-rel-input`}
            value={id}
            
          />
          {isSelected ? (
            <div style={{ width: '20vw', marginTop:'24px'}}>
            <CountryList
              id={id}
              elRefIdPrefix={elRefIdPrefix}
              title={ extendedTitle || title}
              key={id}
              isMandatory={is_mandatory}
              defaultValue={defaultValue}
              onChange={(e) => onChange(e)}
              options={countriesList}
              helpText={info}
              isLoading={
                handleFetchCountriesListQuery.isLoading ||
                handleFetchCountriesListQuery.isFetching
              }
              isDisabled={
                handleFetchCountriesListQuery.isLoading ||
                handleFetchCountriesListQuery.isFetching
              }
              isMulti={true}
              
            />
            </div>
          ) : null}
        </>
      );
    } 
    else {
      return (
        <>
        <AvRadio
          id={`${elRefIdPrefix}-question-${groupId}-option-${id}`}
          label={props.t(title)}
          datatitle={title}
          datatype={type}
          value={id}
          onChange={(e) => {
            return onChange(e.target.value)
          }}
        />
        </>
      );
    }
  };

  useEffect(() => {
    if (type === "date") {
      if (defaultValue) {
        setSelectedValue(dateUtils.parseDate(defaultValue, "dd/mm/yyyy"));
      } else {
        if(!changedOptionFlag) {
          setSelectedValue(new Date());
          setChangedOptionFlag(true);
        }
        
      }
    } else if (type === "text" || type === "textarea") {
      if(!changedOptionFlag){
        setChangedOptionFlag(true);
      }
      setSelectedValue(selectedValue || "");
    } else {
      if(!changedOptionFlag){
        setChangedOptionFlag(true);
      }
      setSelectedValue(id);
    }
  }, [selectedValue]);

  return <>{renderOptionByType()}</>;
};

export default withNamespaces()(memo(Option));
