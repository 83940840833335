import React, { useState } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import UrlUtils from "../../../services/utils/UrlUtils";
import { authCurrentUser } from "../../../store/auth/login/actions";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import UserUtils from "src/services/utils/UserUtils";

const avaialableList = ["wb", "cm", "gd"];

const FaqDropDown = (props) => {
  const userUtils = new UserUtils();
  const disscoSupportEmail = "support@diss-co.tech";
  const [menu, setMenu] = useState();
  const activeModule = props.modules?.active;

  const toggle = () => {
    setMenu(!menu);
  };

  const getRoute = (module) => {
    let result = "";
    switch (module) {
      case "wb":
      case "cm":
        result = userUtils.isAnalystOrAnalystAdmin(props.user)
          ? `/admin/user_manual`
          : userUtils.isEmployee(props.user)
          ? `/employee/user_manual`
          : `/third-party/user_manual`;
        break;
      case "gd":
        result = userUtils.isAnalystOrAnalystAdmin(props.user)
          ? `/admin/data-protection/user_manual`
          : userUtils.isEmployee(props.user)
          ? `/employee/data-protection/user_manual`
          : `/third-party/data-protection/user_manual`;
        break;
    }
    return result;
  };

  const route = getRoute(activeModule);
  
  return (
    <React.Fragment>
      <Dropdown isOpen={menu} toggle={toggle} className="d-sm-inline-block">
        <DropdownToggle
          tag="button"
          className="btn header-item noti-icon waves-effect"
        >
          <i className="ri-question-line" style={{ fontSize: 26 }}></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {activeModule && avaialableList.includes(activeModule) && (
            <>
              <Link
                to={route}
                className="text-reset notification-item"
              >
                <DropdownItem className="notify-item">
                  <a>{props.t("User Manual")}</a>
                </DropdownItem>
              </Link>

              <Link
                to={
                  userUtils.isAnalystOrAnalystAdmin(props.user)
                    ? "/admin/faq"
                    : userUtils.isEmployee(props.user)
                    ? "/employee/faq"
                    : "/third-party/faq"
                }
                className="text-reset notification-item"
              >
                <DropdownItem className="notify-item">
                  <a>{props.t("FAQ")}</a>
                </DropdownItem>
              </Link>

              <DropdownItem className="notify-item">
                <a
                  href={
                    "https://diss-co.tech/faq-whistleblower-protection-act-and-whistleblowing-system/"
                  }
                  target="_blank"
                >
                  {props.t("Legal Whistleblower Protection Act FAQ")}
                </a>
              </DropdownItem>
            </>
          )}

          <DropdownItem className="notify-item">
            <a
              href={`mailto:${disscoSupportEmail}`}
              title={`${props.t("Need Support")}?`}
            >
              {props.t("Need Support")}
            </a>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { user, token } = state.Login;
  const { App } = state;
  const organization = state.Organization;
  return { user, token, App, organization, modules: state.Modules };
};

export default connect(mapStatetoProps, {
  authCurrentUser,
})(withNamespaces()(FaqDropDown));
