import { withNamespaces } from "react-i18next";
import { withRouter } from "react-router-dom";
import { memo, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PageHeader from "../../../../../../components/page-header";
import PageContent from "../../../../../../components/page-content";
import PageBody from "../../../../../../components/page-body";
import Breadcrumbs from "../../../../../../../../components/Common/Breadcrumb";
import { Button, Card, CardBody, CardText, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import DataProtectionNewsService from "../../../../../../apis/NewsService";
import { useMutation, useQuery } from "@tanstack/react-query";
import defaultImage from "src/modules/data-protection/assets/images/defaultNewsImage.svg";
import "../components/style.css";
import { toast } from "react-toastify";
import Search from "../components/top-menu/search";
import StrongImage from "src/assets/images/RiskAI/Strong.svg";
import ModerateImage from "src/assets/images/RiskAI/Moderate.svg";
import WeakImage from "src/assets/images/RiskAI/Weak.svg";
import Botty from "src/modules/data-protection/assets/images/bottyAi-2.png";
import Navigator from "src/components/navigator";
import { API_BASE_URL, API_URL_GET_RISK_AI_CLASS, API_URL_RISK_AI_GET_ADDITIONAL_DETAILS, CHAT_BOT_DOMAIN } from "src/modules/data-protection/constants";
import AIRiskAssessmentModal from "./modals/AssessmentModal";
import { connect } from "react-redux";

const MonitoringDetails = ({ t, id, setSelectedNewsId, onFlagNews, organization }) => {
  const { softwareId } = useParams();
  const [details, setDetails] = useState(null);
  const [selectedClass, setSelectedClass] = useState("");
  const [
    aiBasedRiskAssessmentModalStatus,
    setAiBasedRiskAssessmentModalStatus,
  ] = useState(false);

  const {
    body = "",
    created_at = "",
    flag = false,
    lead_text = "",
    news_agency = "",
    release_date = "",
    source_link = "",
    tags = [],
    title = "",
  } = details || {};

  
  // Functions
  // Fetch
  const getDetails = useQuery({
    queryKey: ["latestNews", id],
    queryFn: async () => {
      const service = DataProtectionNewsService.getInstance();
      const response = await service.details(id);
      return response || [];
    },
    cacheTime: 0,
    refetchOnWindowFocus: true,
    onError(error) {
      if (process.env.NODE_ENV === 'development') console.error(error);
      toast(t("error while fetchinig news"), {
        type: "error",
      });
    },
  });

  // Fetch Main Url
  const getMainLink = (url) => {
    if (!url) {
      return;
    }
    var parsedUrl = new URL(url);
    return parsedUrl.origin; // This will return the protocol + hostname (base URL)
  };

  // Fetch Source Class
  const getClass = useMutation({
    mutationFn: async (payload) => {
      const service = DataProtectionNewsService.getInstance();
      return await service.getClass(payload);
    },
    onError: (error) => {
      if (process.env.NODE_ENV === 'development') console.error(error);

      toast(t("An error occurred while fetching the class."), {
        type: "error",
      });
    },
    onSuccess: (data) => setSelectedClass(data.out.charAt(data.out.length - 1))
  });

  useEffect(() => {
    if (getDetails.isLoading) return;

    setDetails(getDetails?.data || []);
  }, [getDetails?.data]);
  
  useEffect(() => {
    if(details) {
      getClass.mutate({promt: news_agency + ", " + lead_text});
    }
  }, [details])

  return (
    <div className="p-4">
        <Navigator
                backButtonOptions={() => {
                  return {
                    enable: true,
                    onClick: () => setSelectedNewsId(null),
                    title: t("Back"),
                    icon: <i className="ri-arrow-left-fill"></i>,
                  };
                }}
                breadCrumbs={[
                  { title: "SIP", link: "/" },
                  {
                    title: "Softwares",
                    link: `/admin/data-protection/softwares`,
                  },
                ]}
            />

        {
          aiBasedRiskAssessmentModalStatus 
          && 
          (
              <AIRiskAssessmentModal
                  closeModal={ () => setAiBasedRiskAssessmentModalStatus(false) }
                  onRisksCreated={ () => null
                  //   {
                  //     handleFetchRisksQuery.refetch();
                  //     handleFetchRisksMatrix.refetch();
                  // } 
                }
                  isOpen={ aiBasedRiskAssessmentModalStatus }
                  translateResult={ true }
                  aiRequestOptions={{
                      requestUrl      :   `${CHAT_BOT_DOMAIN}api/gdpr/news-software-asset-risks.get`,
                      payload    :   {
                          module          :   'GDPR_NEWS_SOFTWARE_ASSET',
                          organizationId  :   organization.id,
                          newsMonitoringAssetId           :   id,
                      }
                  }}
                  aiTranlateRequestOptions={{
                      requestUrl      :   `${CHAT_BOT_DOMAIN}api/gdpr/risks-associated-news-asset.get`,
                      payload         :   {
                          module          : "GDPR_NEWS_SOFTWARE_ASSET",
                          organizationId  : organization.id,
                          findingId    : id
                      }
                  }}
                  additionnalDetailsRequestOptions={{
                      requestUrl  :   API_URL_RISK_AI_GET_ADDITIONAL_DETAILS,
                      payload     :   {}
                  }}
                  createRisksRequestOptions={{
                      requestUrl      :   `${API_BASE_URL}/risk_rate/create_ai`,
                      payload         :   {software        :    softwareId}
                  }}
                  >
                  {(result) => {
                      return (
                          <Modal className="ai-risk-result-modal mw-100" style={{width: '90%'}} isOpen={aiBasedRiskAssessmentModalStatus} size="xl">
                              <ModalHeader toggle={() => setAiBasedRiskAssessmentModalStatus(!aiBasedRiskAssessmentModalStatus)}>
                                  {t('Risk Assesment Support by AI')}
                              </ModalHeader>

                              <ModalBody>
                                  <result.type {...result.props} />
                              </ModalBody>
                          </Modal>
                      )
                  }}
              </AIRiskAssessmentModal>
          )
        }

      <PageContent>
        <PageBody>
          {details && (
            <Card>
              <Row className={`p-5`}>
                <Col md="8" sm="12">
                  {/* Title */}
                  <div>
                    <div className="redCircle"></div>
                    <h4>{title}</h4>
                  </div>

                  {/* Translate to English */}
                  <Row hidden>
                    <Col
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Button
                        color="primary"
                        outline
                        className="br-4 mt-2 mb-2"
                        size="md"
                      >
                        {t("Translate To English")}
                      </Button>
                    </Col>
                  </Row>

                  {/* Image */}
                  <Row
                    className="mt-2"
                    style={{ marginLeft: "0px", paddingTop: "0px" }}
                  >
                    <Col>
                      <img
                        src={defaultImage}
                        style={{
                          maxWidth: "581px",
                          maxHeight: "290px",
                          marginLeft: "0px",
                        }}
                        alt={t("News Monitoring")}
                      />
                    </Col>
                  </Row>

                  {/* Body */}
                  <Row className="mt-4">
                    <p>{body}</p>
                  </Row>

                  {/* Link */}
                  <Row>
                    <a
                      className="text-secondary"
                      href={source_link}
                      target="_blank"
                    >
                      {source_link}
                    </a>
                  </Row>

                  {/* Flag + Search */}
                  <div
                    className="mt-4"
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    {/* <Search t={t} /> */}

                    <Button
                      className={`${
                        flag ? "bg-primary" : "bg-secondary"
                      } br2 ms-3`}
                      size="sm"
                      style={{width: "10rem"}}
                      onClick={() => onFlagNews(id)}
                    >
                      {t("Flag")}
                    </Button>
                  </div>
                </Col>
                <Col md="4" sm="12">
                  <Card
                    className="my-2"
                    outline
                    style={{
                      width: "26rem",
                      border: "1px solid #5664D2",
                      overflowY: "scroll",
                      // maxHeight: `80%`, // Using modalHeight here
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexFlow: "column wrap",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      <Row>
                      <h4 className="p-2 mt-3" style={{ fontWeight: "bold" }}>
                        {t("Risk Assessment Support by AI")}
                      </h4>
                      </Row>
                      <Row className="mb-2">
                        <img
                          style={{
                            maxWidth: "120px",
                            maxHeight: "120px",
                            display: "block",
                          }}
                          src={Botty}
                        />
                      </Row>
                      <Row className="mb-3">
                        <Button 
                          color="primary"
                          onClick={ () => setAiBasedRiskAssessmentModalStatus(true) }
                        >{t("Get Help From AI")}</Button>
                      </Row>
                    </div>
                  </Card>

                  { !getClass?.isLoading && !getClass?.isFetching ? selectedClass && 
                  <Card
                    className="my-2"
                    outline
                    style={{
                      width: "26rem",
                      border: "1px solid #5664D2",
                      overflowY: "scroll",
                      maxHeight: `70%`, // Using modalHeight here
                    }}
                  >
                    <img
                    className="p-5"
                      alt="Risk Level"
                      src={selectedClass === "A" ? WeakImage : selectedClass === "B" ? ModerateImage : StrongImage}
                      // src={StrongImage}
                    />
                    {/* <CardBody>
                      <CardText>
                        <h3
                          style={{
                            color: "#1A2052",
                            textAlign: "center",
                            marginTop: 0,
                            paddingTop: 0,
                          }}
                        >
                          {t("Source")}
                        </h3>
                        {source.map((s) => {
                          return (
                            <div className="mb-4">
                              <h4 style={{ textAlign: "center" }}>{s.title}</h4>
                              <p>{s.description}</p>
                              <li>
                                <a href={getMainLink(s.source)} target="_blank">
                                  {getMainLink(s.source)}
                                </a>
                              </li>
                              <hr></hr>
                            </div>
                          );
                        })}
                      </CardText>
                    </CardBody> */}
                  </Card>
                  :
                  <div 
                    className='dt-field dt-skeleton dt-select-list' 
                    style={{
                      width: "26rem",
                      // overflowY: "scroll",
                      maxHeight: `70%`, // Using modalHeight here
                    }} 
                  />
                  }
                </Col>
              </Row>
            </Card>
          )}
        </PageBody>
      </PageContent>
    </div>
  );
};

const mapStatetoProps = (state) => {
  const { token } = state.Login;
  const { Organization } = state;
  return {
    user: state.Login.user,
    token,
    organization: Organization,
  };
};

export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps)(memo(MonitoringDetails))
  )
);

