import React, { useEffect, useRef, useState } from "react";
import { withNamespaces } from "react-i18next";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Tooltip,
} from "reactstrap";
import DataProtectionNewsService from "../../../../../../../../apis/NewsService";
import { useMutation, useQuery } from "@tanstack/react-query";
import { FaInfoCircle } from "react-icons/fa";
import "./ManageKeywordsModal.scss";
import { toast } from "react-toastify";

const ManageKeywordsModal = ({ t, modal, setModal, toggle }) => {
  const [tags, setTags] = useState([]);
  const [newTagInput, setNewTagInput] = useState("");
  const inputRef = useRef(null);

  // Tooltip
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  //   Functions
   // Initiate default tags
   const initialDefaultTags = useQuery({
    queryKey: ["tags-init"],
    queryFn: async () => {
      const service = DataProtectionNewsService.getInstance();
      const response = await service.initial();
      return response || [];
    },
    cacheTime: 0,
    refetchOnWindowFocus: true,
    onError(error) {
      if (process.env.NODE_ENV === 'development') console.error(error);
      toast(t("error while fetchinig tags"), {
        type: "error",
      });
    },
  });

  //   Fetch tags
  const listOfTags = useQuery({
    queryKey: ["tags"],
    queryFn: async () => {
      const service = DataProtectionNewsService.getInstance();
      const response = await service.listOfTags();
      return response || [];
    },
    cacheTime: 0,
    refetchOnWindowFocus: true,
    onError(error) {
      console.log("err")
      if (process.env.NODE_ENV === 'development') console.error(error);
      toast(t("error while fetchinig tags"), {
        type: "error",
      });
    },
  });

  //   Edit Tags
  const editTags = useMutation({
    mutationFn: async (payload) => {
      const service = DataProtectionNewsService.getInstance();
      const tagsToSend = payload.map(p => p?.title )
      return await service.updateTags({ tags: tagsToSend });
    },
    onSuccess: () => {
      toast(t("Tags list updated successfully."), {
        type: "success",
      });
      toggle();
    },
    onError: (error) => {
      if (process.env.NODE_ENV === 'development') console.error(error);

      toast(t("An error occurred while updating the tags list."), {
        type: "error",
      });
    },
  });

  // Remove a tag
  const removeTag = (index) => {
    const updatedTags = [...tags];
    updatedTags.splice(index, 1);
    setTags(updatedTags);
  };

  // Add a new tag
  const addNewTag = () => {
    const newTag = newTagInput.trim();
    console.log(newTag)
    if (
      newTag &&
      !tags.find((tag) => tag?.title?.toLowerCase() === newTag.toLowerCase())
    ) {
      
      setTags([...tags, {title: newTag, isDefault: false}]);
      setNewTagInput(""); // Clear the input field
      inputRef.current.focus(); // Set focus back on the input
    }
  };

  // Handle Enter key press
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      addNewTag();
    }
  };


  useEffect(() => {
    if (listOfTags.isLoading) return;
    const tags = listOfTags?.data?.map(({ id, ...rest }) => {
      return {title: rest.title, isDefault: rest?.is_default}
    }) ?? [];
    setTags(tags);
  }, [listOfTags?.data]);

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <div style={{ display: "flex", alignItems: "center" }}>
            {t("Manage your keywords")}
            <Button
              color="link"
              id="infoButton"
              style={{ border: "none", padding: 0, marginLeft: "10px" }}
            >
              <FaInfoCircle />
            </Button>
          </div>
        </ModalHeader>
        <Tooltip
          placement="right"
          isOpen={tooltipOpen}
          target="infoButton"
          toggle={toggleTooltip}
        >
          {t("Default keywords are pre-set and cannot be edited or removed. Each additional custom keyword you add will deduct from your available search volume. Duplicate keywords are automatically removed to conserve search volume.")}
        </Tooltip>
        <ModalBody>
          <div>
            {/* Add New Tag */}
            <Input
              type="text"
              value={newTagInput}
              onChange={(e) => setNewTagInput(e.target.value)}
              onKeyPress={handleKeyPress}
              placeholder={t("Enter new tag")}
              ref={inputRef} // Attach ref to the Input
            />

            {/* Text Area */}
            <div
              contentEditable="true"
              className="keywords-container"
              id="keywords"
              name="keywords"
            >
              {tags.length
                ? tags.map((tag, index) => (
                    <div className="tag" key={index}>
                      {t(tag?.title)}
                      <span
                        className="tag-close"
                        hidden={tag?.isDefault}
                        onClick={() => {
                          removeTag(index);
                        }}
                      >
                        &times;
                      </span>
                    </div>
                  ))
                : null}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            size="sm"
            onClick={() => editTags.mutate(tags)}
          >
            {t("Save")}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default withNamespaces()(ManageKeywordsModal);
