import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  Label,
  Input
} from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";

import {
  API_URL_ACTIVE_DIRECTORY,
  API_URL_ACTIVE_DIRECTORY_FETCH,
  API_URL_ADMIN_CREATE_USER_MANAGEMENT,
} from "../../../common/constants";

import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { successNotification, errorNotification } from "../../../store/actions";
import {
  DATA_SAVED_SUCCESSFULLY,
  DATA_NOT_SAVED_SUCCESSFULLY,
} from "../../../common/constants/Messages";
import axios from "axios";

class ADSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      first: "",
      breadcrumbItems: [
        { title: "SIP", link: "/" },
        { title: this.props.t("AD settings"), link: "#" },
      ],
      header: {
        headers: {
          Authorization: `Bearer ${this.props.token}`,
        },
      },
      azure: {
        clientId: null,
        tenantId: null,
        clientSecret: null,
        enable: false
      },
      ldap: {
        serverAddress: null,
        portNumber: null,
        baseDomainName: null,
        security: null,
        enable: false
      }
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  fetchData = () => {
    const url = API_URL_ACTIVE_DIRECTORY_FETCH;
    axios
      .get(url, this.state.header)
      .then((res) => {
        const data = res.data.data;
        const { azure, ldap } = data;

        this.setState({
          azure: {
            clientId      : azure.client_id,
            tenantId      : azure.tenant_id,
            clientSecret  : azure.client_secret,
            enable        : azure.enable
          },
          ldap: {
            serverAddress   : ldap.server_address,
            portNumber      : ldap.port_number,
            baseDomainName  : ldap.base_domain_name,
            security        : ldap.security,
            enable          : ldap.enable
          }
        });

        if(azure.client_id || azure.tenant_id || azure.client_secret){
          this.setState({
            first: "Azure AD"
          });
        }
        else if(ldap.base_domain_name || ldap.port_number || ldap.server_address || ldap.security){
          this.setState({ 
            first: "LDAP" 
          });
        }
      })
      .catch((err) => console.log(err));
  };

  handleSubmit(e) {
    e.preventDefault();

    const url = API_URL_ACTIVE_DIRECTORY;

    const data = this.state.first === 'Azure AD' ? (
      {
        azure: this.state.azure,
        ldap: {}
      }
    ) : ({
      ldap: this.state.ldap,
      azure: {}
    });

    axios
      .post(url, data, {
        headers: {
          Authorization: `Bearer ${this.props.token}`,
        },
      })
      .then((response) => {
        this.props.successNotification({
          code: "saved",
          message: this.props.t(DATA_SAVED_SUCCESSFULLY),
        });
      })
      .catch((error) =>
        this.props.errorNotification({
          DATA_NOT_SAVED_SUCCESSFULLY,
        })
      );
  }

  render() {
    const { azure, ldap } = this.state;
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.props.t("AD settings")}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <Form onSubmit={this.handleSubmit}>
                      <div className="p-4">
                        <div className="mb-2">
                          <Input
                            style={{ marginRight: 6 }}
                            id="field-1"
                            name="first"
                            type="radio"
                            onChange={() => this.setState({
                              first: 'Azure AD'
                            })}
                            checked={this.state.first === 'Azure AD'}
                          />
                          <Label htmlFor="field-1" check>
                            {this.props.t("Azure AD")}
                          </Label>
                        </div>
                        <div className="mb-2">
                          <Input
                            style={{ marginRight: 6 }}
                            id="field-2"
                            name="first"
                            type="radio"
                            onChange={() => this.setState({
                              first: 'LDAP'
                            })}
                            checked={this.state.first === 'LDAP'}
                          />
                          <Label htmlFor="field-2" check>
                            {this.props.t("LDAP")}
                          </Label>
                        </div>
                      </div>
                      <div className="p-4">
                        {this.state.first === "Azure AD" && (
                          <div>
                            <hr />
                            <div className="mb-3">
                              <label htmlFor="azure-1">
                                {this.props.t("Client ID")}
                              </label>
                              <Input
                                type="text"
                                onChange={(e) =>
                                  this.setState({
                                    azure: {
                                      ...this.state.azure,
                                      clientId: e.target.value
                                    }
                                  })
                                }
                                defaultValue={azure.clientId}
                                id="azure-1"
                                name="azure-1"
                              />
                            </div>
                            <div className="mb-3">
                              <label htmlFor="azure-2">
                                {this.props.t("Tenant ID")}
                              </label>
                              <Input
                                type="text"
                                onChange={(e) =>
                                  this.setState({
                                    azure: {
                                      ...this.state.azure,
                                      tenantId: e.target.value
                                    }
                                  })
                                }
                                defaultValue={azure.tenantId}
                                id="azure-2"
                                name="azure-2"
                              />
                            </div>
                            <div className="mb-3">
                              <label htmlFor="azure-3">
                                {this.props.t("Client Secret")}
                              </label>
                              <Input
                                type="text"
                                id="azure-3"
                                onChange={(e) =>
                                  this.setState({
                                    azure: {
                                      ...this.state.azure,
                                      clientSecret: e.target.value
                                    }
                                  })
                                }
                                defaultValue={azure.clientSecret}
                                name="azure-3"
                              />
                            </div>
                            <div className="mb-3">
                              <label htmlFor="azure-3">
                                {" " + this.props.t("Enable") + " "}
                              </label>
                              <Input
                                type="checkbox"
                                id="azure-3"
                                onChange={(e) =>
                                  this.setState({
                                    azure: {
                                      ...this.state.azure,
                                      enable: e.target.checked
                                    }
                                  })
                                }
                                defaultChecked={azure.enable}
                                name="azure-3"
                              />
                            </div>
                          </div>
                        )}
                        {this.state.first === "LDAP" && (
                          <div>
                            <hr />
                            <div className="mb-3">
                              <label htmlFor="ldap-1">
                                {this.props.t("Server")}
                              </label>
                              <Input
                                type="text"
                                onChange={(e) =>
                                  this.setState({
                                    ldap: {
                                      ...this.state.ldap,
                                      serverAddress: e.target.value
                                    }
                                  })
                                }
                                id="ldap-1"
                                name="ldap-1"
                                defaultValue={ldap.serverAddress}
                              />
                            </div>
                            <div className="mb-3">
                              <label htmlFor="ldap-2">
                                {this.props.t("Port")}
                              </label>
                              <Input
                                type="text"
                                id="ldap-2"
                                name="ldap-2"
                                onChange={(e) =>
                                  this.setState({
                                    ldap: {
                                      ...this.state.ldap,
                                      portNumber: e.target.value
                                    }
                                  })
                                }
                                defaultValue={ldap.portNumber}
                              />
                            </div>
                            <div className="mb-3">
                              <label htmlFor="ldap-3">
                                {this.props.t("Base DN")}
                              </label>
                              <Input
                                type="text"
                                id="ldap-3"
                                name="ldap-3"
                                defaultValue={ldap.baseDomainName}
                                onChange={(e) =>
                                  this.setState({
                                    ldap: {
                                      ...this.state.ldap,
                                      baseDomainName: e.target.value
                                    }
                                  })
                                }
                              />
                            </div>
                            <div className="mb-3">
                              <label htmlFor="ldap-4">
                                {this.props.t("Security")}
                              </label>
                              <Input
                                type="select"
                                id="ldap-4"
                                name="ldap-4"
                                onChange={(e) =>
                                  this.setState({
                                    ldap: {
                                      ...this.state.ldap,
                                      security: e.target.value
                                    }
                                  })
                                }
                                defaultValue={ldap.security}
                              >
                                <option value="NONE">
                                  {this.props.t("NONE")}
                                </option>
                                <option value="TLS">
                                  {this.props.t("TLS")}
                                </option>
                                <option value="STARTTLS">
                                  {this.props.t("STARTTLS")}
                                </option>
                                <option value="INSECURETLS">
                                  {this.props.t("INSECURETLS")}
                                </option>
                                <option value="INSECURESTARTTLS">
                                  {this.props.t("INSECURESTARTTLS")}
                                </option>
                              </Input>
                            </div>
                            <div className="mb-3">
                              <label htmlFor="ldap-3">
                                {" " + this.props.t("Enable") + " "}
                              </label>
                              <Input
                                type="checkbox"
                                id="ldap-3"
                                onChange={(e) =>
                                  this.setState({
                                    ldap: {
                                      ...this.state.ldap,
                                      enable: e.target.checked
                                    }
                                  })
                                }
                                defaultChecked={ldap.enable}
                                name="ldap-3"
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      <Button color="primary" type="submit">
                        {this.props.t("Save")}
                      </Button>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }

  componentDidMount() {
    this.fetchData();
  }
}

const mapStatetoProps = (state) => {
  const { token } = state.Login;
  const { Feature } = state;
  return { token, state, Feature };
};

export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
      successNotification,
      errorNotification,
    })(ADSettings)
  )
);
