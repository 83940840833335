import { combineReducers } from 'redux';

import Layout from './layout/reducer';

import Workspace from './workspace/reducer';

import Login from './auth/login/reducer';

import chat from "./chat/reducer"

import { Organization } from "./organization/reducer"

import { AppNotification } from "./notification/reducer"

import { SystemNotification } from "./systemNotification/reducer"

import { App } from "./app/reducer"

import { CaseId } from './cases/reducer';

import { ExitConfirmPrompt } from './confirmPrompt/reducer';

import { moduleTPDDQuestionnaire } from 'src/modules/3rd-party-management/store/reducer';

import {
    Modules
} from './modules/reducer';

const rootReducer = combineReducers({
    Layout,
    Workspace,
    Organization,
    Login,
    chat,
    AppNotification,
    SystemNotification,
    CaseId,
    App,
    ExitConfirmPrompt,
    moduleTPDDQuestionnaire,
    Modules
});

export default rootReducer;