import { withNamespaces } from "react-i18next";
import { Card, CardBody } from "reactstrap";
import Knob from "../Knob";

const Widget = ({
    title,
    amount,
    icon,
    chart,
    description,
    rate,
    color,
    t
}) => {
    return (
        <Card>
            <CardBody style={{
                backgroundColor :   color || '#fff'
            }}>
                <div className="d-flex">
                    <div className="overflow-hidden" style={{ flex: "0 0 70%" }}>
                        <p
                            className="text-truncate font-size-14 mb-2"
                            style={{ textTransform: "capitalize" }}>
                            {t(title)}
                        </p>
                        <h4 className="mb-0">{amount}</h4>
                    </div>

                    {icon && (
                        <div
                            className="text-center"
                            dir="ltr"
                            style={{ height: 66 }}>
                            <i
                            className={icon + " font-size-24"}
                            style={{ position: "relative", top: 12, right: 6 }}
                            ></i>
                        </div>
                    )}

                    {chart && chart.show && (
                        <div className="text-primary">
                            <Knob
                                value={chart.percent}
                                lineCap="round"
                                height={60}
                                width={60}
                                fgColor="#545B9A"
                                thickness={0.12}
                                readOnly={true}
                            />
                        </div>
                    )}
                </div>
            </CardBody>

                {description && (
                    <CardBody className="border-top py-3">
                        <div className="text-truncate">
                            <span className="badge badge-soft-success font-size-11 me-1">
                                <i className="mdi mdi-menu-up"> </i> {rate}
                            </span>
                            <span className="text-muted ms-2">{t(description)}</span>
                        </div>
                    </CardBody>
                )}
        </Card>
    );
}

export default withNamespaces()(Widget);