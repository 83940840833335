import axios from "axios";
import store from "../store";
import i18next from "i18next";
import { errorNotification } from "../store/actions";
import { API_URL_ANALYST_GET_LIST_CHAT, API_URL_ANALYST_GET_CONVERSATION_CHAT, API_URL_ANALYST_REPLY_CHAT, API_URL_GET_STARTED_CHAT, API_URL_SEND_MESSAGE_CHAT, API_URL_ANALYST_DELETE_CONVERSATION_CHAT, API_URL_END_CHAT } from "../common/constants";

export const getChats = () => {
	const state = store.getState();
	const { token } = state.Login;
	const { id } = state.Organization;
	return axios
		.post(
			API_URL_ANALYST_GET_LIST_CHAT,
			{
				organization_id: id,
			},
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		)
		.then((response) => {
			return response.data.data;
		})
		.catch((err) => {
			throw err.response.data.error;
		});
};

export const deleteChat = (chatId) => {
	const state = store.getState();
	const { token } = state.Login;
	const { id } = state.Organization;
	return axios
		.post(
			API_URL_ANALYST_DELETE_CONVERSATION_CHAT,
			{
				chat_id: chatId,
				organization_id: id,
			},
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		)
		.then((response) => {
			return response.data.data;
		})
		.catch((err) => {
			throw err.response.data.error;
		});
};

export const getMessages = async (chatId) => {
	const { Login, Organization } = store.getState();
	const { token } = Login;
	const { id } = Organization;

	try {
		const response = await axios.post(
			API_URL_ANALYST_GET_CONVERSATION_CHAT,
			{
				organization_id: id,
				chat_id: chatId,
			},
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);

		return response.data.data;
	} catch (error) {
		const errorMessage = error.response?.data;

		store.dispatch(
			errorNotification({
				code: errorMessage?.error,
				message: i18next.t(errorMessage?.message),
			})
		);

		throw errorMessage?.error;
	}
};

export const addMessage = ({ chatId, message }) => {
	const state = store.getState();
	const { token } = state.Login;
	const { id } = state.Organization;
	return axios
		.post(
			API_URL_ANALYST_REPLY_CHAT,
			{
				organization_id: id,
				chat_id: chatId,
				message: message,
			},
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		)
		.then((response) => {
			return response.data.data;
		})
		.catch((err) => {
			throw err.response.data.error;
		});
};

export const sendMessage = ({ chatId, message }) => {
	const state = store.getState();
	const { token } = state.Login;
	const { id } = state.Organization;
	return axios
		.post(
			API_URL_SEND_MESSAGE_CHAT,
			{
				organization_id: id,
				chat_id: chatId,
				message: message,
			},
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		)
		.then((response) => {
			return response.data.data;
		})
		.catch((err) => {
			throw err.response.data.error;
		});
};

export const startChating = (infos) => {
	const state = store.getState();
	const { token } = state.Login;
	return axios
		.post(API_URL_GET_STARTED_CHAT, infos.payload, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
		.then((response) => {
			return response.data.data;
		})
		.catch((err) => {
			throw err.response.data.error;
		});
};

export const finishChating = (id) => {
	const state = store.getState();
	const { token } = state.Login;
	const { id: orgId } = state.Organization;
	return axios
		.post(
			API_URL_END_CHAT,
			{
				chat_id: id,
				organization_id: orgId,
			},
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		)
		.then((response) => {
			console.log(response);
			return response.data.data;
		})
		.catch((err) => {
			throw err.response.data.error;
		});
};

export const getGroups = () => {};

export const getContacts = () => {};
