import axios from "axios";

import { API_BASE_URL } from "../constants/index";

export default class CustomizationService {
  static instance;

  static getInstance() {
    if (!CustomizationService.instance) {
      CustomizationService.instance = new CustomizationService();
    }

    return CustomizationService.instance;
  }

  async fetch() {
    const response = await axios.get(`${API_BASE_URL}/organization/detail`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
        )}`,
      },
    });
    return response.data.data;
  }

  async editInformation(payload) {
    const response = await axios.post(
      `${API_BASE_URL}/organization/edit_information`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
          )}`,
        },
      }
    );
    return response.data.data;
  }

  async editFonts(payload) {
    const response = await axios.post(
      `${API_BASE_URL}/organization/edit_fonts`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
          )}`,
        },
      }
    );
    return response.data.data;
  }

  async editDpo(payload) {
    const response = await axios.post(
      `${API_BASE_URL}/organization/edit_dpo`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
          )}`,
        },
      }
    );
    return response.data.data;
  }

  async editLogos(payload) {
    const response = await axios.post(
      `${API_BASE_URL}/organization/edit_logos`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
          )}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data.data;
  }

  async editBackgrounds(payload) {
    const response = await axios.post(
      `${API_BASE_URL}/organization/edit_backgrounds`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
          )}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data.data;
  }

  async editTranslation(payload) {
    const response = await axios.post(
      `${API_BASE_URL}/organization/edit_translation`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
          )}`,
        },
      }
    );
    return response.data.data;
  }

  async editEmailTranslation(payload) {
    const response = await axios.post(
      `${API_BASE_URL}/organization/edit_mail_intro`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
          )}`,
        },
      }
    );
    return response.data.data;
  }
}
