import {
    ROLE_ANALYST
} from "src/components/constants";

import {
    ROUTE_PATH_ADMIN_DASHBOARD,
    ROUTE_PATH_ADMIN_KANBAN_BOARD,
    ROUTE_PATH_ADMIN_HOME,
    ROUTE_PATH_ADMIN_OWN_COMPANY_THIRD_PARTIES,
    ROUTE_PATH_ADMIN_VIEW_DOCUMENTS,
} from "src/modules/3rd-party-management/constants"

import {
    ROUTE_PATH_ADMIN_RISK_MANAGMENT
} from "src/common/constants";

const AnalystMenuItems = [
    {
        titleKey    :   "Dashboard",
        path        :   ROUTE_PATH_ADMIN_DASHBOARD,
        icon        :   "ri-dashboard-line",
        linkType    :   "react-router",
        authProtected   :   true,
        settingKey      :   null,
        moduleFeatures  :   [],
        roles           :   [
            ROLE_ANALYST
        ],
        customValidator :   null
    },
    {
        titleKey    :   "Kanban Board",
        path        :   ROUTE_PATH_ADMIN_KANBAN_BOARD,
        icon        :   "ri-bar-chart-2-line",
        linkType    :   "react-router",
        authProtected   :   true,
        settingKey      :   null,
        moduleFeatures  :   [],
        roles           :   [
            ROLE_ANALYST
        ],
        customValidator :   null
    },
    {
        titleKey    :   "Third Party Management",
        path        :   ROUTE_PATH_ADMIN_HOME,
        icon        :   "ri-team-line",
        linkType    :   "react-router",
        authProtected   :   true,
        settingKey      :   null,
        moduleFeatures  :   [],
        roles           :   [
            ROLE_ANALYST
        ],
        customValidator :   null
    },
    {
        titleKey    :   "Own Company",
        path        :   ROUTE_PATH_ADMIN_OWN_COMPANY_THIRD_PARTIES,
        icon        :   "ri-building-4-line",
        linkType    :   "react-router",
        authProtected   :   true,
        settingKey      :   null,
        moduleFeatures  :   [],
        roles           :   [
            ROLE_ANALYST
        ],
        customValidator :   null
    },
    {
        titleKey    :   "Risk Management",
        path        :   ROUTE_PATH_ADMIN_RISK_MANAGMENT,
        icon        :   "ri-alert-line",
        linkType    :   "react-router",
        authProtected   :   true,
        settingKey      :   null,
        moduleFeatures  :   [],
        roles           :   [
            ROLE_ANALYST
        ],
        customValidator :   null
    },
    {
        titleKey    :   "Documents",
        path        :   ROUTE_PATH_ADMIN_VIEW_DOCUMENTS,
        icon        :   "ri-book-open-line",
        linkType    :   "react-router",
        moduleFeatures  :   [],
        authProtected   :   true,
        settingKey      :   null,
        roles           :   [
            ROLE_ANALYST
        ],
        customValidator :   null
    },
    // {
    //     titleKey    :   "E-Learning",
    //     path        :   ROUTE_PATH_ADMIN_E_LEARNING,
    //     icon        :   "ri-video-line",
    //     linkType    :   "react-router",
    //     authProtected   :   true,
    //     settingKey      :   "isElearning",
    //     moduleFeatures  :   [],
    //     roles           :   [],
    //     customValidator :   null
    // },
    // {
    //     titleKey    :   "Tutorial",
    //     path        :   ROUTE_PATH_ADMIN_TUTORIAL,
    //     icon        :   "ri-video-line",
    //     linkType    :   "react-router",
    //     authProtected   :   true,
    //     settingKey      :   null,
    //     moduleFeatures  :   [],
    //     roles           :   [],
    //     customValidator :   null
    // }
];

export default AnalystMenuItems;