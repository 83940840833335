import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, Button, Alert, CardTitle, CardSubtitle } from "reactstrap";
import { Input } from "reactstrap";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import SweetAlert from "react-bootstrap-sweetalert";

import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import i18next from "i18next";

import ToolkitProvider from "react-bootstrap-table2-toolkit";

import axios from "axios";
import {
  API_URL_CUSTOMER_API,
  API_URL_ADMIN_GET_USER_LOGS,
  API_URL_ADMIN_GET_USER_MANAGEMENT_LIST,
  API_URL_ADMIN_DELETE_USER_MANAGEMENT,
  API_URL_ADMIN_UPDATE_USER_MANAGEMENT,
  API_URL_PARTNER_GET_CLIENT_MANAGEMENT_LIST,
  EMPTY_LIST,
} from "../../../common/constants";

import Breadcrumbs from "../../../components/Common/Breadcrumb";


import { successNotification, errorNotification } from "../../../store/actions";

import { post } from "../../../helpers/request_helper";
import { isFeatureEnabledForActiveModule } from 'src/helpers/module_helper';

import UserUtils from "../../../services/utils/UserUtils";
import DateUtils from "../../../services/utils/DateUtils";
import viewIcon from "../../../assets/icons/viewIcon.png";

class ClientDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "SIP", link: "/" },
        { title: this.props.t("Client Details"), link: "#" },
      ],
      page: 1,
      users: [],
      clients: [],
      usersFetched: false,
      sizePerPage: 10,
      userIdLogs: null,
      userIdGetLogs: {},
      userIdToDelete: null,
      deletionReason: null,
    };

    this.userUtils = new UserUtils();
  }


  dateUtils = new DateUtils();

  
  render() {
    const {id, name, createdAt, partnerAnalysts} = this.props.location.state;
    const columns = [
      {
        dataField: "id",
        text: this.props.t("ID"),
        sort: true,
      },
      {
        dataField: "first_name",
        text: this.props.t("First Name"),
        sort: true,
        
      },
      {
        dataField: "last_name",
        text: this.props.t("Last Name"),
        sort: true,
        
      },
      {
        dataField: "email",
        text: this.props.t("Email"),
        sort: true
      },
    ];

    const defaultSorted = [];

    const pageOptions = {
      sizePerPage: 25,
      totalSize: this.state.users.length,
      custom: true,
    };

    let data = [];

    for (const key in this.state.userIdGetLogs) {
      if (this.state.userIdGetLogs[key].length) {
        data.push(<li>{`${key}: ${this.state.userIdGetLogs[key]}`}</li>);
      }
    }

    return (
      <React.Fragment>
        

        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.props.t("Client Management")}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>

                    <CardTitle style={{display: "flex", gap:"20rem"}}>
                        <div>{this.props.t("ID") + ":   " + id}</div> 
                        <div>{this.props.t("Client") + ":   " + name}</div> 
                        <div>{this.props.t("Created At") + ":   " + this.dateUtils.convertTimestampToOnlyDate(createdAt)}</div>
                    </CardTitle>

                    <div
                      className="flex mb-3"
                      style={{ display: "flex", flexDirection: "row-reverse" }}
                    >
                    </div>
                    {/* {isFeatureEnabledForActiveModule(
                      "list-users"
                    ) ? ( */}
                     {partnerAnalysts.length> 0 ? <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField="id"
                        columns={columns}
                        data={this.state.clients}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="id"
                            columns={columns}
                            data={partnerAnalysts}
                            search
                          >
                            {(toolkitProps) => (
                              <React.Fragment>
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        keyField={"id"}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row className="align-items-md-center mt-30">
                                  <Col className="inner-custom-pagination d-flex">
                                    <div className="d-inline">
                                      <SizePerPageDropdownStandalone
                                        {...paginationProps}
                                      />
                                    </div>
                                    <div className="text-md-right ms-auto">
                                      <PaginationListStandalone
                                        {...paginationProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider> :
                      <div className="alert alert-warning" role="alert">
                        <p
                            style={{
                            textAlign: "center",
                            marginBottom: 0,
                            width: "100%",
                            }}
                        >
                            {this.props.t(EMPTY_LIST)}
                        </p>
                    </div>
                    }
                    {/* ) : (
                      <Alert color="warning">
                        {this.props.t(
                          "You do not have permission to access these pages"
                        )}
                      </Alert>
                    )} */}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }

  renderUsersList(list) {
    return list.map((item) => {
      console.log(item, " : item");

      return {
        id: item.id,
        name: item.name,
        partnerAnalysts: item.partner_analysts,
        createdAt: item.created_at,
      };
    });
  }

  fetchUsers() {
    axios
      .get(
        API_URL_PARTNER_GET_CLIENT_MANAGEMENT_LIST,
        
        {
          headers: {
            Authorization: `Bearer ${this.props.token}`,
          },
        },
        {
          pageIndex: 1,
          pageSize: 10,
          // sortField: id,
          // sortOrder: 'desc',

        },
      )
      .then((response) => {
        // console.log(response);
        this.setState({
          clients: this.renderUsersList(response.data.data.clients),
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentDidUpdate() {
    if (!this.state.usersFetched) {
      this.setState({ usersFetched: true });
      this.fetchUsers();
    }
  }

  componentDidMount() {
    if (!this.state.usersFetched) {
      this.setState({ usersFetched: true });
      this.fetchUsers();
    }
  }
}

const mapStatetoProps = (state) => {
  const { token } = state.Login;
  const { App } = state;
  return { token, App };
};

export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
      successNotification,
      errorNotification,
    })(ClientDetails)
  )
);
