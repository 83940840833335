import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { memo } from "react";
import "./style.css";
import { CiFlag1 } from "react-icons/ci";

const SingleNews = ({
  t,
  image,
  selectedNews,
  onFlagNews,
  isSelected,
  onClick,
  onImageClick
}) => {
  const {
    news_agency,
    title,
    source_link,
    lead_text,
    created_at,
    tags,
    flag,
  } = selectedNews;

  const truncateHtmlText = (html, maxChars = 250) => {
    return html?.length > maxChars ? html.slice(0, maxChars) + "..." : html;
  };

  const truncatedLeadText = truncateHtmlText(lead_text);

  const timeAgo = (timestamp) => {
    const secondsPerMinute = 60;
    const secondsPerHour = 3600;
    const secondsPerDay = 86400;
    const secondsPerMonth = 2592000; // Roughly 30 days
    const secondsPerYear = 31536000; // 365 days

    const date = new Date(timestamp * 1000);
    const now = new Date();
    const elapsed = (now - date) / 1000; // convert to seconds

    if (elapsed < secondsPerMinute) {
      return Math.round(elapsed) + " seconds ago";
    } else if (elapsed < secondsPerHour) {
      return Math.round(elapsed / secondsPerMinute) + ` ${t("minutes ago")}`;
    } else if (elapsed < secondsPerDay) {
      return Math.round(elapsed / secondsPerHour) + ` ${t("hours ago")}`;
    } else if (elapsed < secondsPerMonth) {
      return Math.round(elapsed / secondsPerDay) + ` ${t("days ago")}`;
    } else if (elapsed < secondsPerYear) {
      return Math.round(elapsed / secondsPerMonth) + ` ${t("months ago")}`;
    } else {
      return Math.round(elapsed / secondsPerYear) + ` ${t("years ago")}`;
    }
  };

  return (
    <div
      className={`news-container p-5 ${isSelected ? "selected-news" : ""}`}
      onClick={onClick}
    >
      <div className="news-image">
        <img src={image} alt={t("News Monitoring")} style={{ cursor: "pointer", width: "100%" }} onClick={onImageClick} />
      </div>
      <div className="news-text">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span className="text-secondary">{news_agency}</span>
          <span className={flag ? "selectedFlag" : "flag"} onClick={onFlagNews}>
            <CiFlag1 />
          </span>
        </div>

        <div>
          <div className="redCircle"></div>
          <h6>{title}</h6>
        </div>
        <a className="text-secondary" href={source_link} target="_blank" rel="noopener noreferrer">
          {source_link}
        </a>
        <p style={{ margin: "2px 0px" }}>{truncatedLeadText}</p>
        <span className="text-secondary">{timeAgo(created_at)}</span>
        <br />
        <div className="mt-3">
          {tags.length
            ? tags.map((tag) => <span className="tag me-2 " key={tag.id}>{t(tag.title)}</span>)
            : null}
        </div>
      </div>
    </div>
  );
};

export default withNamespaces()(withRouter(memo(SingleNews)));
