import React from 'react'
import { withNamespaces } from 'react-i18next'

import { Card, CardBody, CardFooter, CardImg } from 'reactstrap'
import ClockIcon from '../../assets/icons/clockIcon.png'

const PlatformUpdateCard = (props) => {
    const {t, update, setSelectedUpdate } = props;

    return (
        <Card key={update.link}>
            <CardImg
              src={update.image}
              className="card-img object-fit-cover"
              alt={update.title}
            />
          <CardBody className="p-0">            
                <p className="px-3 pt-2">
                    <strong>{update.title}</strong>
                </p>
            <CardFooter className="bg-transparent d-flex flex-row justify-content-between align-items-center border-top">
            <div className="text-muted">
                <span className="pe-2"><img src={ClockIcon} alt="icon" width={24} height={24} /></span>
                <span>{update.createdAt}</span>
            </div>
              <button onClick={() => setSelectedUpdate(update)} style={{backgroundColor: '#F5E1AE', color:'#C68D49'}} className="btn btn-sm border-0 btn-warning">
                {t("Read more")}
              </button>
            </CardFooter>
          </CardBody>
        </Card>
    )
}

export default withNamespaces()(PlatformUpdateCard)