import axios from 'axios';
import { API_BASE_URL, API_URL_ADMIN_GET_ASSIGN_ANALYST_LIST , API_URL_ADMIN_AND_TASK_OWNERS_LIST} from '../constants';

export default class CommonService {
	static instance;
	resource = `${process.env.REACT_APP_CUSTOMER_API_ENDPOINT}`;
  gdpr_resource = API_BASE_URL
	constructor() {}

	static getInstance() {
		if (!CommonService.instance) {
			CommonService.instance = new CommonService();
		}

		return CommonService.instance;
	}

	async countries() {
		const response = await axios.post(`${this.gdpr_resource}/report.country.list`, null, {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('LOCAL_STORAGE_KEY_ACCESS_TOKEN')}`,
			},
		});

		return response.data.data;
	}

	async analystAdminsAndAnalysts () {

		const response = await axios.post(`${this.gdpr_resource}/analystAndAdminAnalyst.list`, null, {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('LOCAL_STORAGE_KEY_ACCESS_TOKEN')}`,
			},
		});

		return response.data.data;
	}

	async fetchAnalystAndAdminAnalystList(payload = {}){
		const response = await axios.post(API_URL_ADMIN_GET_ASSIGN_ANALYST_LIST, payload,
			{
			headers: {
				Authorization: `Bearer ${localStorage.getItem(
					"LOCAL_STORAGE_KEY_ACCESS_TOKEN"
				)}`,
			},
			}
		);

		return response.data?.data;
	}

	async fetchTaskOwners(payload = {}){
		const response = await axios.post(API_URL_ADMIN_AND_TASK_OWNERS_LIST, payload,
			{
			headers: {
				Authorization: `Bearer ${localStorage.getItem(
					"LOCAL_STORAGE_KEY_ACCESS_TOKEN"
				)}`,
			},
			}
		);

		return response.data?.data;
	}

	async legalEntities(id) {
		const response = await axios.get(`${this.gdpr_resource}/organization/${id}/legal_entities`, {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('LOCAL_STORAGE_KEY_ACCESS_TOKEN')}`,
			},
		});

		if (response.data && response?.data?.data) {
            return response?.data?.data;
        } else {
            return []; // Return an empty array or handle accordingly
        }
	}

}
