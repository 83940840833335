import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { Label } from "reactstrap";
import MultiSelect from "../../../../../../../components/MultiSelect";
import UserUtils from "src/services/utils/UserUtils";
import ReportIncidenService from "../../../../components/api/ReportIncidenService";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";

const Index = (props) => {
  const { t, report, caseId, currentUser, reportLoading } = props;
  const [linkedCase, setLinkedCase] = useState([]);
  const [linkedIncidentsOptions, setLinkedIncidentsOptions] = useState([]);
  const userUtils = new UserUtils();

  const isAnalystOrAnalystAdmin = () => {
    return userUtils.isAnalystOrAnalystAdmin(currentUser);
  };

  const handleRemoveLinkedIncidentsMutation = useMutation({
    mutationFn: async (payload) => {
      const service = ReportIncidenService.getInstance();

      return await service.RemoveLinkedIncident(payload);
    },
  });

  const handleAddLinkedIncidentsMutation = useMutation({
    mutationFn: async (payload) => {
      const service = ReportIncidenService.getInstance();

      return await service.AddLinkedIncident(payload);
    },
  });

  const handleLinkedIncidents = useQuery({
    queryKey: ["data-protection-Linked-Incidents"],
    queryFn: async () => {
      const service = ReportIncidenService.getInstance();
      return await service.FetchCasess(caseId);
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      toast(t("An error occurred while fetching linked incidents."), {
        type: "error",
      });
    },
  });

  const handleRemoveLinkedIncidentsChange = (item) => {
    handleRemoveLinkedIncidentsMutation.mutate(
      {
        caseId: caseId,
        linkCaseId: item[0].value,
      },
      {
        onSuccess: () => {
          toast(t("Link case updated successfully."), {
            type: "success",
          });
        },
        onError: (error) => {
          toast(t("Failed to update link case."), {
            type: "error",
          });
          // Refetch link if mutation fails
          setLinkedCase([...linkedCase]);
        },
      }
    );
  };

  const handleAddLinkedIncidentsChange = (item) => {
    handleAddLinkedIncidentsMutation.mutate(
      {
        caseId: caseId,
        linkCaseId: item[0].value,
      },
      {
        onSuccess: () => {
          toast(t("Link case updated successfully."), {
            type: "success",
          });
        },
        onError: (error) => {
          toast(t("Failed to update link case."), {
            type: "error",
          });
          // Refetch link if mutation fails
          setLinkedCase([...linkedCase]);
        },
      }
    );
  };

  const linkedIncidentsLoading =
    handleLinkedIncidents.isFetching || handleLinkedIncidents.isLoading;

  //its just check the array lenght for determine the item add or removed
  const determineChangesAndSendToBackend = (
    currentValues,
    previousValues,
    removeCallback,
    addCallback
  ) => {
 
    const removedItems = previousValues.filter(
      (item) => !currentValues.find((val) => val.value === item.value)
    );
    const addedItems = currentValues.filter(
      (val) => !previousValues.find((item) => item.value === val.value)
    );

    if (removedItems.length > 0 && addedItems.length === 0) {
      removeCallback(removedItems);
    } else if (addedItems.length > 0 && removedItems.length === 0) {
      addCallback(addedItems);
    } else {
      return;
    }
  };
  //checking for the add item or remove item then call the query based on them
  const handleMultiSelectChangeLinkCase = (selectedValues) => {
    determineChangesAndSendToBackend(
      selectedValues,
      linkedCase,
      (removedItems) => {
        handleRemoveLinkedIncidentsChange(removedItems);
      },
      (addedItems) => {
        handleAddLinkedIncidentsChange(addedItems);
      }
    );
    setLinkedCase(selectedValues);
  };

  useEffect(() => {
    if (
      handleLinkedIncidents.data &&
      Array.isArray(handleLinkedIncidents.data) &&
      report &&
      !reportLoading
    ) {
      const newList = handleLinkedIncidents.data.map((item) => {
        const desc =
          item.description.length > 70
            ? item.description.substring(0, 70) + "..."
            : item.description;

        return {
          value: item.case_id,
          title: desc
            ? `${item.secondary_case_id + " - " + desc}`
            : item.secondary_case_id,
        };
      });

      setLinkedIncidentsOptions(newList);
      if (newList && newList?.length > 0) {
        const linkedIncidentsObjects = newList.filter((item) =>
          report?.linked_cases.includes(item.value)
        );
        setLinkedCase(linkedIncidentsObjects);
      }
    }
  }, [handleLinkedIncidents.data, report, reportLoading]);

  return (
    <div style={{ maxWidth: "396px" }}>
      {report?.is_deleted_content ? null : (
        <div>
          <Label>{t("Linked Incidents")}</Label>
          {!linkedIncidentsLoading && !reportLoading ? (
            <MultiSelect
              options={linkedIncidentsOptions}
              selectedValues={linkedCase}
              onChange={handleMultiSelectChangeLinkCase}
              placeholder={t("Select...")}
              isDisabled={
                report?.is_read_only_analyst ||
                report?.deleted_by_name ||
                report?.is_deleted_content
              }
            />
          ) : (
            // skeleton
            <>
              <p
                className={`dt-field dt-skeleton ${
                  isAnalystOrAnalystAdmin ? "dt-select-list" : ""
                }`}
              ></p>
              <p className="dt-field dt-skeleton dt-update-date"></p>
            </>
          )}
        </div>
      )}
    </div>
  );
};

const mapStatetoProps = (state) => {
  const { token, currentUser } = state.Login;
  return {
    currentUser,
    token,
  };
};

export default withNamespaces()(connect(mapStatetoProps)(Index));
