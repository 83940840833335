import React, { useEffect, useRef, useState } from "react";
import { withNamespaces } from "react-i18next";
import { Dropdown, DropdownToggle, DropdownMenu, Button } from "reactstrap";

import Form from "react-bootstrap/Form";
import { Nav, NavItem, NavLink } from "reactstrap";

import { icons} from "./constants";

import Spinner from "react-bootstrap/Spinner";

import { connect } from "react-redux";
import { isFeatureEnabledForActiveModule } from 'src/helpers/module_helper';
import {
  REPORT_STATUS_LIST,
} from "src/components/constants";

const TableFilter = ({
  t,
  onOkButtonClicked,
  defaultValues,
  type,
  categories,
  handleFetchAnalystsAndAnalystAdmins,
  handleFetchRisks
}) => {
  const [filters, setFilters] = useState({
    status: defaultValues.status || null,
    adminCategories: defaultValues.adminCategories || null,
    risk: defaultValues.risk || null,
    analystAssigned: defaultValues.analystAssigned || null,
  });

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("Analyst");
  const dateInputRef = useRef();

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const renderRiskFilterList = () => {
    let riskRates = [];
    // Check if the "risk-assessment" feature is enabled
    if (isFeatureEnabledForActiveModule("risk-assessment")) {
      // Fetch data and handle loading state
      if (handleFetchRisks.isFetching || handleFetchRisks.isLoading) {
        return (
          <div className="text-center">
            <Spinner animation="border" variant="primary" size="md" />
          </div>
        );
      }
  
      // Once data is fetched, map it to checkboxes
      const risks = (handleFetchRisks.data || []).map((item) => {
        return {
          value: item.id,
          baseLabel: item.name,
          label: t(item.name),
        };
      });
  
      riskRates =[
        {
          value: "",
          baseLabel: "All Risk",
          label: t("All Risk"),
        },
        ...risks,
      ];

    } else {
      // If the feature flag is off, use the hardcoded array
      riskRates = [
        {
          value: "",
          baseLabel: "All Risk",
          label: t("All Risk"),
        },
        {
          value: "high",
          baseLabel: "High",
          label: t("High"),
        },
        {
          value: "medium",
          baseLabel: "Medium",
          label: t("Medium"),
        },
        {
          value: "low",
          baseLabel: "Low",
          label: t("Low"),
        },
      ];
    }
  
    // Render checkboxes
    return riskRates.map((item) => {
      return (
        <div className="filter-item" key={item.value}>
          <Form.Check
            type="radio"
            id={`risk-${item.value}-filter`}
            checked={filters.risk === item.value}
            onChange={() => {
              setFilters((prevFilters) => ({
                ...prevFilters,
                risk: item.value,
              }));
            }}
            label={item.label}
          />
        </div>
      );
    });
  };
  

  const renderAnalystAdminsFilterList = () => {
    if (handleFetchAnalystsAndAnalystAdmins.isFetching || handleFetchAnalystsAndAnalystAdmins.isLoading) {
      return (
        <div className="text-center">
          <Spinner animation="border" variant="primary" size="md" />
        </div>
      );
    }

    return (handleFetchAnalystsAndAnalystAdmins.data || []).map((item) => {
      return (
        <div className="filter-item" key={item.id}>
          <Form.Check
            type="radio"
            id={`analysts-${item.id}-filter`}
            checked={filters.analystAssigned === item.id}
            onChange={() => {
              setFilters((prevFilters) => ({
                ...prevFilters,
                analystAssigned: item.id,
              }));
            }}
            label={`${item.first_name + " " + item.last_name}`}
          />
        </div>
      );
    });
  };


  const renderCategoryFilterList = () => {
    const result = [];
    const newCategories = [];
    newCategories.push({ title: t("All"), flag: true, id: "all"});
    const next = categories.forEach(cat => newCategories.push(cat));
    for (const category of newCategories) {
      const isAllSelected = filters.adminCategories?.includes("all");
      result.push(
        <div className="filter-item" key={category.id}>
          <Form.Check
            type="checkbox"
            id={`category-${category.id}-filter`}
            checked={filters.adminCategories?.includes(category.id)}
            disabled={category.id !== "all" && isAllSelected}
            onChange={() => {
              setFilters((prevFilters) => {
                const isSelected = prevFilters?.adminCategories?.includes(category.id);
                if(category.id === "all"){
                  return {
                    ...prevFilters,
                    adminCategories: isSelected ? [] : ["all"],
                  };
                }else {
                  return {
                    ...prevFilters,
                    adminCategories: isSelected
                      ? prevFilters?.adminCategories?.filter((id) => id !== category.id) 
                      : [...prevFilters.adminCategories, category.id], 
                  };
                }
              });
            }}
            label={t(category.title)}
          />
        </div>
      );
    }
  
    return result;
  };
  
  const renderStatusFilterList = () => {
    const result = [];
    
    const newStatuses = [];
    newStatuses.push({ titleKey: t("All")});
    const next = REPORT_STATUS_LIST.forEach(cat => newStatuses.push(cat));

    for (const status of newStatuses) {
      result.push(
        <div className="filter-item" key={status.id}>
          <Form.Check
            type="radio"
            id={`status-${status.id}-filter`}
            checked={filters.status === status.id}
            onChange={() => {
              setFilters((prevFilters) => ({
                ...prevFilters,
                status: status.id,
              }));
            }}
            label={t(status.titleKey)}
          />
        </div>
      );
    }
  
    return result;
  };
  

  const renderNavContent = () => {
    switch (activeTab) {
      case "Analyst":
        return renderAnalystAdminsFilterList();

      case "Risk":
        return renderRiskFilterList();

      case "Category":
        return renderCategoryFilterList();

      case "Status":
        return renderStatusFilterList();

      default:
        return null;
    }
  };

  useEffect(() => {
    if (activeTab === "Analyst" && dateInputRef?.current) {
      dateInputRef.current.focus();
    }
  }, [activeTab]);

  return (
    <div className="custom-table-filter">
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle caret color="secondary" outline>
          <i className="ri-filter-3-fill"></i>
          <span>{t("Filter")}</span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-end">
          <div className="filters-container">

            <div className="navs-container d-flex justify-content-center">
              <Nav tabs>
                <NavItem
                  onClick={() => {
                    setActiveTab("Analyst");
                    // handleFetchRisksManagers.refetch();
                  }}
                  className={`${activeTab === "Analyst" && "active"}`}
                >
                  <NavLink>
                    <img
                      src={
                        activeTab === "Analyst"
                          ? icons.Analyst.active
                          : icons.Analyst.default
                      }
                      alt="analyst-filter"
                    />
                    <span>{t("Analyst")}</span>
                  </NavLink>
                </NavItem>

                <NavItem
                  onClick={() => {
                    setActiveTab("Risk");
                    
                  }}
                  className={`${activeTab === "Risk" && "active"}`}
                >
                  <NavLink>
                    <img
                      src={
                        activeTab === "Risk"
                          ? icons.Risk.active
                          : icons.Risk.default
                      }
                      alt="risk-filter"
                    />
                    <span>{t("Risk")}</span>
                  </NavLink>
                </NavItem>


                <NavItem
                  onClick={() => {
                    setActiveTab("Category");
                  }}
                  className={`${activeTab === "Category" && "active"}`}
                >
                  <NavLink>
                    <img
                      src={
                        activeTab === "Category"
                          ? icons.Category.active
                          : icons.Category.default
                      }
                      alt="category-filter"
                    />
                    <span className="mt-1">{t("Category")}</span>
                  </NavLink>
                </NavItem>
                  {type !== "deleted-reports" && 
                <NavItem
                  onClick={() => setActiveTab("Status")}
                  className={`${activeTab === "Status" && "active"}`}
                >
                  <NavLink>
                    <img
                      src={
                        activeTab === "Status"
                          ? icons.Status.active
                          : icons.Status.default
                      }
                      alt="status-filter"
                    />
                    <span>{t("Status")}</span>
                  </NavLink>
                </NavItem>
                }

              

              </Nav>
            </div>

            <div className="navs-content-container">{renderNavContent()}</div>

            <div className="actions-container d-flex align-items-center justify-content-end">
            <Button
                color="secondry"
                size="sm"
                onClick={() => {
                 setFilters(  {  status: null,
                  category: null,
                  risk: null,
                  analystAssigned: null
                })}}
              >
                {t("Reset")}
              </Button>

              <Button
                color="primary"
                size="sm"
                onClick={() => {
                  onOkButtonClicked && onOkButtonClicked(filters);
                  toggle();
                }}
              >
                {t("OK")}
              </Button>

            </div>
          </div>
        </DropdownMenu>
      </Dropdown>

    </div>
  );
};

const mapStatetoProps = (state) => {
  const { token } = state.Login;

  return {
    token
  };
};


export default withNamespaces()(
    connect(mapStatetoProps)(TableFilter)
);
