import { withNamespaces } from 'react-i18next';

import { memo, useEffect, useState } from 'react';

import { withRouter, useHistory } from "react-router-dom";

import { Container, Col, Row, Card, CardBody, Alert, Label} from 'reactstrap';

import { connect } from 'react-redux';

import axios from 'axios';

import { useQuery } from '@tanstack/react-query';

import { toast } from 'react-toastify';

import { errorNotification, successNotification } from '../../../../../store/actions.js';

import { API_BASE_URL } from '../../../constants';

import Questionar from '../../../components/Questionar';
import QuestionarConfirmForm from '../../../components/Questionar/ConfirmForm/index.jsx';
import QuestionarService from '../../../components/Questionar/service.js';

import { TwitterWizard, TwitterWizardNavItem } from 'src/modules/data-protection/components/TwitterWizard/index.jsx';

import { AvField, AvForm, AvRadio, AvRadioGroup } from 'availity-reactstrap-validation';
import { useLocation } from 'react-router-dom';
import Navigator from 'src/modules/data-protection/components/navigator/index.jsx';
import UserUtils from 'src/services/utils/UserUtils.js';
import { Link } from "react-router-dom";

const DataBreachIncidentRegister = (props) => {
	const location = useLocation();
    const history = useHistory();

	const { from} = location.state || {};
    const searchParams = new URLSearchParams(location.search);

	const userUtils = new UserUtils();

	const softwareId = searchParams.get('softwareId');
	

	const [ questionareFilledData, setQuestionareFilledData ] = useState(null);

	const [ questions, setQuestions ] = useState([]);

	const [ isCreateNewFormSubmiting, setIsCreateNewFormSubmiting ] = useState(false);

	const [ activeTab, setActiveTab ] = useState("fill-questions-step");

	const [ selectedReportingType, setSelectedReportingType ] = useState('myself');

	const [ reportTypeFilledData, setReportTypeFilledData ] = useState(null);

	const [ submitResult, setSubmitResult ] = useState(null);

	let forOtherReportTypeRef = null;

	const questionareSettings = {
		confirmFilledInputsForm	:	{
			enabled	:	false
		},
		buttons	:	{
			submit	:	{
				enabled	:	true,
				title	:	"Next"
			}
		}
	};

	const otherOneReportTypeQuestions = [
		{
			id				:  	'title',
			is_mandatory	:  	true,
			sort_index 		:  	-10,
			title 			:  	"Title (Optional)",
			type 			:	"radiobox",
			options			:	[
				{
					id		:	'Mr.',
					title	:	'Mr.',
					type 	:	'radiobox'
				},
				{
					id		:	'Mrs.',
					title	:	'Mrs.',
					type 	:	'radiobox'
				}
			]
		},
		{
			id				:  	'relation_company',
			is_mandatory	:  	true,
			sort_index 		:  	-9,
			title 			:  	"What is your relation to the company?",
			type 			:	"radiobox",
			options			:	[
				{
					id		:	'Employee',
					title	:	'Employee',
					type 	:	'radiobox'
				},
				{
					id		:	'External',
					title	:	'External',
					type 	:	'radiobox'
				}
			]
		},
		{
			id				:  	'first_name',
			is_mandatory	:  	true,
			sort_index 		:  	-8,
			title 			:  	"First name",
			type 			:	"text"
		},
		{
			id				:  	'last_name',
			is_mandatory	:  	true,
			sort_index 		:  	-7,
			title 			:  	"Last name",
			type 			:	"text"
		},
		{
			id				:  	'organization',
			is_mandatory	:  	true,
			sort_index 		:  	-6,
			title 			:  	"Organization",
			type 			:	"text"
		},
		{
			id				:  	'position',
			is_mandatory	:  	true,
			sort_index 		:  	-5,
			title 			:  	"Position",
			type 			:	"text"
		}
	]; 

	const handleFetchQuestionsQuery = useQuery({
		queryKey: ['data-protection-thirdparty-data-breach-incident-request'],
		queryFn: async () => {
			const service = QuestionarService.getInstance();
	
			return await service.questions('data_breach_incident', props.Organization.id, {});
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			if (process.env.NODE_ENV === 'development') console.error(error);
	
			toast(props.t('An error occurred while fetching questions.'), {
				type: 'error',
			});
		},
	});

	const handleSubmit = () => {
		setIsCreateNewFormSubmiting(true);
		
		let sourceValue = null;

		const filteredQuestions = questionareFilledData.questionAnswers.filter((answerObj) => {
			if (answerObj.question === 'source') {
				sourceValue = answerObj.answer[0].value; 
				return false; 
			}
			return true;
		});

		const payload = {
			...questionareFilledData,
			questionAnswers: filteredQuestions, 
			source: sourceValue ? sourceValue.toLowerCase() : null 
		};

		if(softwareId) {
			Object.assign(payload, {software: softwareId});
		}

		axios.post(`${API_BASE_URL}/report.create`, payload , {
			headers: {
				Authorization: `Bearer ${props.token}`,
			}
		})
		.then(() => {
			setSubmitResult('succeed');
			
			props.successNotification({
				code: props.t("Request Submitted"),
				message: props.t("Request Submitted"),
			});
		})
		.catch(() => {
			setSubmitResult('failed');

			props.errorNotification({
				code: 'validation_error',
				message: props.t('Error!'),
			});
		})
		.finally(() => {
			setIsCreateNewFormSubmiting(false);
		});
	}

	const renderReportTypeForm = () => {
		return (
			<div className={`${activeTab !== 'fill-questions-step' && 'd-none'}`}>

				<AvForm className={`needs-validation ${(selectedReportingType === 'myself') && 'd-none'}`} onValidSubmit={(e, values) => { setReportTypeFilledData(values) }} ref={(ref) => forOtherReportTypeRef = ref}>
					<Row className='mb-3'>
						<Col lg={12}>
							<Label className="form-label" >
								{props.t('Title (Optional)')}
							</Label>
							<AvRadioGroup name={"question[title]"}
								validate={{
									required: { value: true },
								}}
								errorMessage={props.t(
									"This field cannot be blank"
								)}>
									<Row>
										<Col lg={6}>
											<AvRadio label="Mr." value="Mr." />
										</Col>
										<Col lg={6}>
											<AvRadio label="Mrs." value="Mrs." />
										</Col>
									</Row>
							</AvRadioGroup>
						</Col>
					</Row>

					<Row className='mb-3'>
						<Col lg={12}>
							<Label className="form-label" >
								{props.t('What is your relation to the company?')}
							</Label>
							<AvRadioGroup name={"question[relationCompany]"}
								validate={{
									required: { value: true },
								}}
								errorMessage={props.t(
									"This field cannot be blank"
								)}>
									<Row>
										<Col lg={6}>
											<AvRadio label="Employee" value="Employee" />
										</Col>
										<Col lg={6}>
											<AvRadio label="External" value="External" />
										</Col>
									</Row>
							</AvRadioGroup>
						</Col>
					</Row>

					<Row className='mb-3'>
						<Col lg={4}>
							<Label className="form-label" >
								{props.t('First name')}
							</Label>
							<AvField 
								type="text"
								name="question[firstName]"
								validate={{
									required: { value: true },
								}}
								errorMessage={props.t(
									"This field cannot be blank"
								)}
							/>
						</Col>
						<Col lg={4}>
							<Label className="form-label" >
								{props.t('Last name')}
							</Label>
							<AvField 
								type="text"
								name="question[lastName]"
								validate={{
									required: { value: true },
								}}
								errorMessage={props.t(
									"This field cannot be blank"
								)}
							/>
						</Col>
						<Col lg={4}>
							<Label className="form-label" >
								{props.t('Organization')}
							</Label>
							<AvField 
								type="text"
								name="question[otherOrganization]"
								validate={{
									required: { value: true },
								}}
								errorMessage={props.t(
									"This field cannot be blank"
								)}
							/>
						</Col>
					</Row>

					<Row className='mb-3'>
						<Col lg={12}>
							<Label className="form-label" >
								{props.t('Position')}
							</Label>
							<AvField 
								type="text"
								name="question[position]"
								validate={{
									required: { value: true },
								}}
								errorMessage={props.t(
									"This field cannot be blank"
								)}
							/>
						</Col>
					</Row>
				</AvForm>
			</div>
		);
	}

	const readyQuestionsForConfirmForm = () => {
		if(selectedReportingType === 'other_one'){
			return [
				...otherOneReportTypeQuestions,
				...(handleFetchQuestionsQuery.data || [])
			];
		}
		
		return handleFetchQuestionsQuery.data || [];
	}

	const readyAnswersForConfirmForm = () => {
		if(selectedReportingType === 'other_one'){
			return {
				...questionareFilledData,
				questionAnswers : [
					...questionareFilledData.questionAnswers,
					...(otherOneReportTypeQuestions.map((oQ) => {
						return {
							question	:	oQ.id,
							answer 		:	[
								{
									title	:	reportTypeFilledData.question[oQ.id],
									value 	:	reportTypeFilledData.question[oQ.id]
								}
							]
						}
					}))
				]
			};
		}

		return questionareFilledData;
	}

	useEffect(() => {
		if(questionareFilledData && selectedReportingType === 'myself'){
			setActiveTab('review-filled-answers');
		}
	}, [questionareFilledData]);

	useEffect(() => {
		if(reportTypeFilledData && questionareFilledData){
			setActiveTab('review-filled-answers');
		}
	}, [reportTypeFilledData]);

	useEffect(() => {
		if(activeTab === 'finalize-report'){
			handleSubmit();
		}
		else if(activeTab === 'fill-questions-step'){
			setQuestionareFilledData(null);
			setReportTypeFilledData(null);
		}
	}, [activeTab]);

	useEffect(() => {
		if(handleFetchQuestionsQuery.data && Array.isArray(handleFetchQuestionsQuery.data)){
			const isAnalystOrAnalystAdmin = userUtils.isAnalystOrAnalystAdmin(props.user);
			
			const newQuestion = isAnalystOrAnalystAdmin ? {
				id: "source", 
				title: "Choose the channel of information",
				type: "select",
				sort_index: 0,
				is_mandatory: true,
				options: [
					{ title: "Web", id: "Web" },
					{ title: "Email", id: "Email" },
					{ title: "Letter", id: "Letter" },
					{ title: "Phone", id: "Phone" },
					{ title: "Meeting", id: "Meeting" },
					{ title: "Live Chat", id: "Live Chat" },
					{ title: "Other", id: "Other" }
				]
			} : {};
	
			const updatedQuestions = [newQuestion, ...handleFetchQuestionsQuery.data];
			
			setQuestions(updatedQuestions);
		}
	}, [handleFetchQuestionsQuery.data]);


	return (
		<div className='page-content'>
			<Container fluid>
                <Row className="mb-4">
                    <Col sm="12">
                        <Navigator
                            backButtonOptions={() => {
                                return {
                                    enable: true,
                                    onClick: () => history.goBack(),
                                    title: props.t("Back"),
                                    icon: <i className="ri-arrow-left-fill"></i>,
                                };
                            }}
                            breadCrumbs={[
                                { title: "SIP", link: "/" },
                                {
                                    title: props.t("Incident Register"),
                                    link: `/admin/data-protection/incident-register`,
                                },
                            ]}
                        />
                    </Col>
                </Row>

				<Row>
					<Col sm={12}>
						<Card>
							<CardBody>
								<TwitterWizard activeTab={ activeTab} >
									<TwitterWizardNavItem uniqueKey={'fill-questions-step'}>
										<span className="step-number">01</span>
										<span className="step-title">
											{props.t("Report")}
										</span>
									</TwitterWizardNavItem>

									<TwitterWizardNavItem uniqueKey={'review-filled-answers'}>
										<span className="step-number">02</span>
										<span className="step-title">
											{props.t("Review your report")}
										</span>
									</TwitterWizardNavItem>

									<TwitterWizardNavItem uniqueKey={'finalize-report'}>
										<span className="step-number">03</span>
										<span className="step-title">
											{props.t("Finalize")}
										</span>
									</TwitterWizardNavItem>
								</TwitterWizard>
							</CardBody>
						</Card>
					</Col>
				</Row>

				<Row>
					<Col sm={12}>
						<Card>
							<CardBody>

								{ renderReportTypeForm() }

								<Questionar 
									questionnaireId={`data_breach_incident`}
									questionnaireType={'data_breach_incident'}
									loading={handleFetchQuestionsQuery.isLoading || handleFetchQuestionsQuery.isFetching}
									questions={questions}
									onValidSubmit={ (payload) => setQuestionareFilledData(payload) }
									onSubmit={() => {
										forOtherReportTypeRef.submit();
									}}
									isFormSubmiting={isCreateNewFormSubmiting}
									user={{
										token: props.token,
										...props.user
									}}
									settings={ questionareSettings }
									className={`${(activeTab !== 'fill-questions-step') && 'd-none'}`}
								/>

								{
									activeTab === 'review-filled-answers' && (
										<QuestionarConfirmForm 
											questions={ readyQuestionsForConfirmForm() }
											answers={ readyAnswersForConfirmForm() }
											onConfirmButtonClicked={() => {
												setActiveTab('finalize-report');
											}}
											onBackButtonClicked={() => {
												setActiveTab('fill-questions-step');
											}}
											settings={{
												buttons : {
													confirm	:	{
														title	:	"Next"
													},
													back	:	{
														title	:	"Back"
													}
												}
											}}
										/>
									)
								}

								{
									activeTab === 'finalize-report' && isCreateNewFormSubmiting && (
										<p>
											{props.t(
												"Thank you for your patience while your request is being processed."
											)}
										</p>
									)
								}

								{
									activeTab === 'finalize-report' && !isCreateNewFormSubmiting && (	
										submitResult === 'succeed' ? (
											<>
												<Alert color="success"
													className="alert-dismissible fade show"
													role="alert">
														<i className="mdi mdi-check-all me-2"></i>
														<b>
															{props.t("Report Submitted")}
														</b>
														<p>
															{props.t(
																"If you have any questions, please contact the technical support by sending an email to support@diss-co.tech."
															)}
														</p>
														<p>
															{props.t(
																"If you receive error messages or have a technical questions, please make a screenshot of the error message or your view including the URL and send your request by email to support@diss-co.tech"
															)}
														</p>
												</Alert>
												<Link 
													hidden={!softwareId} 
													className='btn btn-primary' 
													to={`/admin/data-protection/softwares/${softwareId}?tab=related-incident`}
												>
													{props.t("Back to related incidents page")}
												</Link>
											</>
										) : (
											<Alert color="danger"
												className="alert-dismissible fade show"
												role="alert">
													<i className="mdi mdi-check-all me-2"></i>
													<b>
														{props.t("Error!")}
													</b>
													<p>
														{props.t(
															"An error occurred while submitting the form"
														)}
													</p>
											</Alert>
										)
									)
								}
								
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

const mapStatetoProps = (state) => {
	const { token, user } = state.Login;
	const { Organization } = state;
	
	return {
		token,
		user,
		Organization
	};
};

export default withNamespaces()(
	connect(mapStatetoProps, {
		errorNotification,
		successNotification
	})(
		withRouter(memo(DataBreachIncidentRegister))
	)
);
