import {
    WorkspacesMenuItems as gd
} from './gd';

import {
    WorkspacesMenuItems as tp
} from './tp';

import {
    WorkspacesMenuItems as wb
} from './wb';

import {
    WorkspacesMenuItems as cm
} from './cm';

export const modulesMenuItems = {
    wb,
    cm,
    gd,
    tp
};