import React, { useState } from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from "reactstrap";
import {
  checkLogin,
  setUserAccessToken,
  changeWorkspace,
  errorNotification,
  successNotification,
  loginErrorFlag,
} from "../../store/actions";
import axios from "axios";
import { API_URL_CONSENT_APPROVEMENT } from "../../common/constants";

function ConsentModal(props) {
  const { modal, toggle, consent, setConsent, loginData, doLogin } = props;
  const { t } = props;

  const toggleConsent = () => setConsent();
  
const handleSubmit = async () => {
    const url = API_URL_CONSENT_APPROVEMENT;
    const data = {
        privacyPolicyAccepted: consent,
        userPolicyAccepted: consent
    }
    
    try {
        const response = await axios.post(url, data, {
            headers: {
              Authorization: `Bearer ${ props.token || props.loginData.token || props.loginData?.data?.data?.token }`,
            },
          });
    
          if (response.status === 200) {
            doLogin(loginData);
          } else {
            props.errorNotification({message: "Failed to submit data."})
          }
    } catch {
        props.errorNotification({message: "Failed to submit data."})
    }
    
}

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          {props.t("Your Privacy and Consent")}
        </ModalHeader>
        <ModalBody>
            <Input type="checkbox" checked={consent} onChange={toggleConsent} className="me-2" />
            {/* {
              props.t(`I agree to ${<a href={encodeURIComponent(`user_policy`)} target="_blank">{props.t("User policy")}</a>}`)
            } */}

            {/* {props.t("consentText", {
                userPolicy: <a href="user_policy" target="_blank">{props.t("User policy")}</a>,
                dataPrivacy: <a href="data_privacy" target="_blank">{props.t("Data privacy")}</a>
              })} */}
            { localStorage.getItem("i18nextLng") === "de" ? 
              <>
                {"Ich stimme den "}
                <a href={`user_policy`} target="_blank">{"Nutzungsbedingungen"}</a> 
                {" " + "und der" + " "}
                <a href={`data_privacy`} target="_blank">{"Datenschutzerklärung"}</a>
                {" zu."}
              </>
             : 
             <>
              {props.t("I agree to")} {" "}
              <a href={`user_policy`} target="_blank">{props.t("User policy")}</a> 
              {" " + props.t("and") + " "}
              <a href={`data_privacy`} target="_blank">{props.t("Data privacy")}</a>
             </>
             
            }
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmit} disabled={!consent}>
            {t("Submit")}
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            {t("Cancel")}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

const mapStatetoProps = (state) => {
  const { Feature } = state;
  return {
    Feature,
    user: state.Login.user,
    token: state.Login.token,
    organization: state.Organization,
    loginErrFlag: state.Login.loginErrFlag,
  };
};

export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
      checkLogin,
      setUserAccessToken,
      loginErrorFlag,
      changeWorkspace,
      errorNotification,
      successNotification,
    })(ConsentModal)
  )
);
