import {
    ROUTE_PATH_THIRD_PARTY_REPORT_LIST,
    ROUTE_PATH_THIRDPARTY_HOME,
    ROUTE_PATH_THIRDPARTY_FILL_QUESTIONNAIRE,
    ROUTE_PATH_THIRD_PARTY_REPLACE_CONTACT_PERSON
} from "src/modules/3rd-party-management/constants";

const ThirdPartyMenuItems = [
    {
        titleKey    :   "Home",
        linkType    :   "react-router",
        path        :   ROUTE_PATH_THIRDPARTY_HOME,
        icon        :   "ri-home-4-line",
        authProtected   :   false,
        settingKey      :   null,
        moduleFeatures  :   [],
        roles           :   [],
        customValidator :   null
    },
    {
        titleKey    :   "My reports",
        linkType    :   "react-router",
        path        :   ROUTE_PATH_THIRD_PARTY_REPORT_LIST,
        icon        :   "ri-mail-line",
        authProtected   :   true,
        settingKey      :   null,
        moduleFeatures  :   [],
        roles           :   [],
        customValidator :   null
    },
    {
        titleKey    :   "Replace contact person",
        linkType    :   "react-router",
        path        :   ROUTE_PATH_THIRD_PARTY_REPLACE_CONTACT_PERSON,
        icon        :   "ri-contacts-book-2-fill",
        authProtected   :   true,
        settingKey      :   null,
        moduleFeatures  :   [],
        roles           :   [],
        customValidator :   null
    }
];

export default ThirdPartyMenuItems;