import React from "react";
import { Card } from "reactstrap";
import { Link } from "react-router-dom";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { withNamespaces } from "react-i18next";

const EmailSidebar = (props) => {
  const { route, t } = props;

  return (
    <React.Fragment>
      {/* left sidebar start */}
      <Card className="email-leftbar">
        <div className="mail-list">
          <Link to="#" className={route === "inbox" ? "active" : ""}>
            <i className="mdi mdi-email-outline me-2"></i> {t("Inbox")}
            <span className="ms-1 float-end">(18)</span>
          </Link>
          <Link to="#" className={route === "sent_mail" ? "active" : ""}>
            <i className="mdi mdi-email-check-outline me-2"></i>
            {t("Sent Mail")}
          </Link>
        </div>
      </Card>
    </React.Fragment>
  );
};

export default withNamespaces()(EmailSidebar);
