// import Request from '../services/Request';
// import Config from '../services/Config';
import axios from "axios";

import { API_URL_REPORT_INTRNAL_COMMENTS_SEARCH,CM_API_URL_REPORT_INTRNAL_COMMENTS_SEARCH } from "../common/constants/";

export default class ReportApiProvider {
  constructor(module){
    this.module = module
  }
  // constructor() {
  //     this.request = new Request();
  //     this.config = new Config();
  // }

  // getHeader(){

  // }

  async getInternalCommentsSearchAnalyst(accessToken, data) {
    console.log("step 1 is called");
    return axios
      .get(this.module === 'cm' ?CM_API_URL_REPORT_INTRNAL_COMMENTS_SEARCH:API_URL_REPORT_INTRNAL_COMMENTS_SEARCH, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        console.log("step response", response);
        let result = [];

        if (response.status === 200) {
          const { data } = response.data;
          if (data) {
            data.map((item) => {
              result.push({
                text: `${item.full_name}`,
                url: item.id,
                value: `${item.full_name}`,
              });
            });
          }
        }

        console.log(result, "step: 1");
        return result;
      })
      .catch((err) => {
        console.log(err);
        return;
      });
  }
}
