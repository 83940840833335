import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";

class AreaChart extends Component {
  constructor(props) {
    super(props);

    const predefinedColors = [
      "#3c4ccf",
      "#02a499",
      "#ff5733",
      "#8a2be2",
      "#00bfff",
      "#ff1493",
    ];

    this.state = {
      options: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 0.5,
          curve: "straight",
        },
        grid: {
          borderColor: "#f8f8fa",
          row: {
            colors: ["transparent", "transparent"],
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: this.props.categories,
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        legend: {
          show: true,
          markers: {
            fillColors: predefinedColors,
          },
        },
        colors: predefinedColors,
      },
      series: this.props.series,
    };
  }

  componentDidMount() {
    console.log("Updated");
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.series !== this.props.series ||
      prevProps.categories !== this.props.categories
    ) {
      this.setState({
        series: this.props.series,
        options: {
          ...this.state.options,
          xaxis: {
            ...this.state.options.xaxis,
            categories: this.props.categories,
          },
        },
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="area"
          height="290"
        />
      </React.Fragment>
    );
  }
}

export default AreaChart;
