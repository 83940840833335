import { useCallback, memo, useState } from "react";
import { withNamespaces } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";
import "./style.scss";
import { Nav, NavItem, NavLink } from "reactstrap";

const HardwareDetailsTabs = memo((props) => {
  const {t} = props
  const { hardwareId } = useParams();
  const [activeTab, setActiveTab] = useState("0");
  const history = useHistory();
  const isActiveTab = (currentTab, tab) => currentTab === tab;
  const tabsLinks = {
    0: `/admin/data-protection/hardwares/${hardwareId}/`,
    1: `/admin/data-protection/hardwares/${hardwareId}/owners`,
  };
  const handleTabChange = useCallback(
    (tab) => {
      setActiveTab(tab);
      history.push(tabsLinks[tab]);
    },
    [hardwareId, tabsLinks]
  );

  return (
    <Nav tabs>
      <NavItem>
        <NavLink
          className={isActiveTab(activeTab, "0") ? "active" : ""}
          onClick={() => handleTabChange("0")}
        >
          {t("Hardware Asset Details")}
        </NavLink>
      </NavItem>
    </Nav>
  );
});

export default withNamespaces()(memo(HardwareDetailsTabs));
