import { withNamespaces } from "react-i18next";
import { Col, Container, Row } from "reactstrap";
import Breadcrumbs from "src/components/Common/Breadcrumb";
import EmailSidebar from "./email-sidebar";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import EmailInbox from "./email-inbox";
import React, { useEffect, useState } from "react";

const Mailer = (props) => {
  const { t } = props;
  const location = useLocation();
  const [route, setRoute] = useState("inbox");

  /*************************************************** USE EFFECTS ****************************************************** */
  useEffect(() => {
    const pathSegments = location.pathname.split("/");
    const lastSegment = pathSegments[pathSegments.length - 1];

    switch (lastSegment) {
      case "inbox":
        setRoute("inbox");
        break;
      default:
        setRoute("inbox");
        break;
    }
  }, [location.pathname]);

  /*************************************************** RENDER MAIN CONTENT ****************************************************** */

  const renderContent = () => {
    let result = null;

    switch (route) {
      case "inbox":
        result = <EmailInbox style={{ width: "100%" }} />;
        break;

      //   case "sent_mail":
      //     result = <SentMail />;
      //     break;

      default:
        result = <div>{t("Content not found")}</div>;
        break;
    }

    return <React.Fragment>{result}</React.Fragment>;
  };

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs
          title={t("Inbox")}
          breadcrumbItems={[
            { title: "SIP", link: "/" },
            { title: route === "inbox" ? t("Inbox") : "", link: "#" },
          ]}
        />

        <Row className="mailer-container">
          <Col md="2" sm="12">
            <EmailSidebar route={route} style />
          </Col>
          <Col md="10" sm="12">
            {renderContent()}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withNamespaces()(Mailer);
