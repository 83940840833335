import React, { useEffect,useMemo, useRef, useState } from "react";
import { withNamespaces } from "react-i18next";
import { 
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    Button
} from "reactstrap";

import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import {Nav, NavItem, NavLink } from 'reactstrap';

import {
    icons
} from './constants';


import Spinner from 'react-bootstrap/Spinner';

import { useFilterQuery } from "src/modules/data-protection/hooks/useFilters";
import { DateRangePicker, defaultInputRanges, defaultStaticRanges } from 'react-date-range';
import * as locales from 'react-date-range/dist/locale';

const TableFilter = ({
    t,
    supplierId,
    onFilterChanged,
    onOkButtonClicked,
    isSearchAvailable,
    defaultValues,
    userFilterQuery,
    HistoryLogActions,
    ...rest
}) => {
    const [ filters, setFilters ] = useState({
        fromDate    :   defaultValues.fromDate || null,
        toDate      :   defaultValues.toDate || null,
        user        :   defaultValues.user || null,
        section    :   defaultValues.section || null,
        action       :   defaultValues.action ||null,
    });
    const HistoryLogSections =[
      { label: t("Hardware"), value: "hardware" },
      { label: t("Software"), value: "software" },
    ];
    
    
    const [ dropdownOpen, setDropdownOpen ] = useState(false);
    const [ activeTab, setActiveTab ] = useState('date');
    const dateInputRef = useRef();

    const staticRangesLabels = {
      Today: t("Today"),
      Yesterday: t("Yesterday"),
      "This Week": t("This Week"),
      "Last Week": t("Last Week"),
      "This Month": t("This Month"),
      "Last Month": t("Last Month")
    };
    
    const inputRangesLabels = {
      "days up to today": t("days up to today"),
      "days starting today": t("days starting today")
    };
    function translateRange(dictionary) {
      return (item) =>
        dictionary[item.label] ? { ...item, label: dictionary[item.label] } : item;
    }
    
    const staticRanges = defaultStaticRanges.map(
      translateRange(staticRangesLabels)
    );
    const inputRanges = defaultInputRanges.map(translateRange(inputRangesLabels));

    
  

    const toggle = () => setDropdownOpen((prevState) => !prevState);


    const renderSectionsFilterList = () => {
      return (HistoryLogSections|| []).map((section) => {
        const isChecked = filters.section === section.value; // Check if current user is selected
        return (
          <div className="filter-item" key={section.value}>
            <Form.Check
              checked={isChecked}
              onChange={() => {
                if (!isChecked) {
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    section: section.value,
                  }));
                  onFilterChanged && onFilterChanged('section', section.value,);
                }
              }}
              type="radio"
              id={`section-${section.value}-filter`}
              label={`${section.label}`}
            />
          </div>
        );
      });
    };
    

    const renderActivitiesFilterList = () => {
      return (HistoryLogActions|| []).map((action) => {
        const isChecked = filters.action === action.value; // Check if current user is selected
        return (
          <div className="filter-item" key={action.value}>
            <Form.Check
              checked={isChecked}
              onChange={() => {
                if (!isChecked) {
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    action: action.value,
                  }));
                  onFilterChanged && onFilterChanged('action', action.value,);
                }
              }}
              type="radio"
              id={`section-${action.value}-filter`}
              label={`${action.label}`}
            />
          </div>
        );
      });
    };
    

    const renderUsersFilterList = () => {
        if (userFilterQuery.isFetching || userFilterQuery.isLoading) {
          return (
            <div className="text-center">
              <Spinner animation="border" variant="primary" size="md" />
            </div>
          );
        }
      
        return (userFilterQuery.data|| []).map((user) => {
          const isChecked = filters.user === user.id; // Check if current user is selected
          return (
            <div className="filter-item" key={user.id}>
              <Form.Check
                checked={isChecked}
                onChange={() => {
                  if (!isChecked) {
                    setFilters((prevFilters) => ({
                      ...prevFilters,
                      user: user.id,
                    }));
                    onFilterChanged && onFilterChanged('user', user.id);
                  }
                }}
                type="radio"
                id={`user-${user.id}-filter`}
                label={`${user.first_name} ${user.last_name}`}
              />
            </div>
          );
        });
      };
      
    const renderDateFilter = () => {
        return (
          <DateRangePicker
            locale={ locales[rest?.lng] || locales["enUS"]}
            scroll={{ enabled: true }}
            ranges={[
                {
                    startDate: filters.fromDate,
                    endDate: filters.toDate,
                    key: "selection",
                  }
                ]}
            onChange={(range) => {
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  fromDate: range.selection.startDate,
                  toDate: range.selection.endDate,
                }));
              }}
            staticRanges={staticRanges}
            inputRanges={inputRanges}
            startDatePlaceholder={t("Early")}
            endDatePlaceholder={t("Continuous")}
            rangeColors={["#5664d2"]}
          />
        );
    }

    const renderNavContent = () => {
        switch(activeTab){
            case 'date':
                return renderDateFilter();

            case 'user':
                return renderUsersFilterList();

            case 'sections':
                return renderSectionsFilterList();

            case 'actions':
                return renderActivitiesFilterList();
            
            default:
                return null;
        }
    }

    useEffect(() => {
        if(activeTab === 'date' && dateInputRef?.current){
            dateInputRef.current.focus();
        }
    }, [ activeTab ]);

 
    return (
      <div className="custom-table-filter">
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle caret color="secondary" outline>
            <i className="ri-filter-3-fill"></i>
            <span>{t("Filter")}</span>
          </DropdownToggle>

          <DropdownMenu>
            <div className="filters-container">
              {isSearchAvailable &&
                <div className="search-input">
                  <InputGroup>
                    <Form.Control placeholder="Search" aria-describedby="search-result-input" style={{borderRight: 'none'}} />
                    <InputGroup.Text className="bg-transparent border-left-0" id="search-result-input" style={{borderLeft: 'none'}}>
                      <i className="ri-search-line"></i>
                    </InputGroup.Text>
                  </InputGroup>
                </div>}

              <div className="navs-container d-flex justify-content-center">
                <Nav tabs>
                  <NavItem onClick={() => setActiveTab('date')} className={`${activeTab === 'date' && 'active'}`}>
                    <NavLink>
                      <img src={activeTab === 'date' ? icons.date.active : icons.date.default} alt="" />
                      <span>{t('Date')}</span>
                    </NavLink>
                  </NavItem>

                  <NavItem onClick={() => {
                      setActiveTab('user');
                      userFilterQuery.refetch();
                    }} className={`${activeTab === 'user' && 'active'}`}>
                    <NavLink>
                      <img src={activeTab === 'user' ? icons.user.active : icons.user.default} alt="" />
                      <span>{t('User')}</span>
                    </NavLink>
                  </NavItem>

                  
                                <NavItem onClick={() => setActiveTab('sections')} className={`${activeTab === 'sections' && 'active'}`}>
                    <NavLink>
                      <img src={activeTab === 'sections' ? icons.section.active : icons.section.default} alt="" />
                      <span>{t('Sections')}</span>
                    </NavLink>
                  </NavItem>

                  
                                <NavItem onClick={() => setActiveTab('actions')} className={`${activeTab === 'actions' && 'active'}`}>
                    <NavLink>
                      <img src={activeTab === 'actions' ? icons.actions.active : icons.actions.default} alt="" />
                      <span>{t('Actions')}</span>
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>

              <div className="navs-content-container">
                            { renderNavContent() }
                        </div>

              <div className="actions-container gap-3 d-flex align-items-center justify-content-end">
                <Button
                  color="primary"
                  size="sm"
                  onClick={() => {
                    onOkButtonClicked && onOkButtonClicked(filters);
                    toggle();
                  }}
                >
                  {t("OK")}
                </Button>
                <Button
                  color="secondry"
                  size="sm"
                  onClick={() => {
                    setFilters({
                      fromDate: null,
                      toDate: null,
                      user: null,
                      section: null,
                      action: null,
                    });
                  }}
                >
                  {t("Reset")}
                </Button>
              </div>
            </div>
          </DropdownMenu>
        </Dropdown>
      </div>
    );
};


export default withNamespaces()(TableFilter); 