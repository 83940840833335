import {
	ROLE_ANALYST_ADMIN
} from 'src/components/constants';

import VerticalLayout from '../../../components/VerticalLayout';
import Manager from '../pages/admin/manager';
import SupplierDetails from '../pages/admin/suppliers/details'
import dashboard from '../pages/admin/dashboard';
import NewThirdParty from "../pages/admin/suppliers/new";
import KanbanBoard from '../pages/admin/kanban-board';
import SupplierTaskDetailsPage from '../pages/admin/suppliers/tasks/details';
import SupplierRiskDetailsPage from '../pages/admin/suppliers/risks/details';
import Settings from '../pages/admin/administration/settings';
import Customization from '../pages/admin/administration/customization/Customization';
import ListUsers from '../pages/admin/administration/userMAnagment/ListUsers';
import AddUser from '../pages/admin/administration/AddUser';

import QuestionnaireDesigner from '../pages/admin/administration/questionnaire';

import Documents from '../pages/admin/administration/documents';
import ViewDocuments from '../pages/admin/documents';

const adminRoutes = [
	{
		path		: 	'/admin/3rd-party/dashboard',
		component	: 	dashboard,
		layout		: 	VerticalLayout,
		type		: 	'admin',
		modules		: 	['tp']
	},
	{
		path		: 	'/admin/3rd-party/suppliers/:id/details',
		component	: 	SupplierDetails,
		layout		: 	VerticalLayout,
		type		: 	'admin',
		modules		: 	['tp']
	},
	{
		path		: 	'/admin/3rd-party/suppliers/create',
		component	: 	NewThirdParty,
		layout		: 	VerticalLayout,
		type		: 	'admin',
		modules		: 	['tp']
	},
	{
		path		: 	'/admin/3rd-party/suppliers/:supplierId/tasks/:taskId/details',
		component	: 	SupplierTaskDetailsPage,
		layout		: 	VerticalLayout,
		type		: 	'admin',
		modules		: 	['tp']
	},
	{
		path		: 	'/admin/3rd-party/suppliers/:supplierId/risks/:riskId/details',
		component	: 	SupplierRiskDetailsPage,
		layout		: 	VerticalLayout,
		type		: 	'admin',
		modules		: 	['tp']
	},
    {
		path		: 	'/admin/3rd-party/manager/own',
		component	: 	() => <Manager suppliersType={'own'} />,
		layout		: 	VerticalLayout,
		type		: 	'admin',
		modules		: 	['tp']
	},
    {
		path		: 	'/admin/3rd-party/manager',
		component	: 	() => <Manager suppliersType={'all'} />,
		layout		: 	VerticalLayout,
		type		: 	'admin',
		modules		: 	['tp']
	},
	{
		path		: 	'/admin/3rd-party/kanban-board',
		component	: 	KanbanBoard,
		layout		: 	VerticalLayout,
		type		: 	'admin',
		modules		: 	['tp']
	},
    {
        path        :   '/admin/3rd-party/documents',
        component   :   ViewDocuments,
        layout      :   VerticalLayout,
        type        :   'admin',
        modules     :   ['tp']
    },
    {
		path        :   '/admin/3rd-party/administration/settings',
		component   :   Settings,
		layout      :   VerticalLayout,
		type        :   'admin',
        modules		: 	['tp']
	},
	{
        path        :   '/admin/3rd-party/administration/users/add',
        component   :   AddUser,
        layout      :   VerticalLayout,
        type        :   "admin",
        roles       :   [ ROLE_ANALYST_ADMIN ],
        modules     :   ["tp"]
    },
    {
        path        :   '/admin/3rd-party/administration/users',
        component   :   ListUsers,
        layout      :   VerticalLayout,
        type        :   "admin",
        roles       :   [ ROLE_ANALYST_ADMIN ],
        modules     :   ["tp"]
    },
    {
		path        :   '/admin/3rd-party/administration/customization',
		component   :   Customization,
		layout      :   VerticalLayout,
		type        :   'admin',
        modules		: 	['tp']
	},
    {
		path		: 	'/admin/3rd-party/administration/questionnaire',
		component	: 	QuestionnaireDesigner,
		layout		: 	VerticalLayout,
		type		: 	'admin',
		modules		: 	['tp']
	},
    {
        path        :   '/admin/3rd-party/administration/documents',
        component   :   Documents,
        layout      :   VerticalLayout,
        type        :   'admin',
        modules     :   ['tp']
    }
];

export default adminRoutes;

export {
	adminRoutes
};