import { memo, useEffect, useState } from "react";
import { Col, Row, Label, Tooltip } from "reactstrap";
import { AvRadioGroup } from "availity-reactstrap-validation";
import Option from "./Option";
import { withNamespaces } from "react-i18next";
import "./style.scss";

const Radiobox = (props) => {
  const {
    id,
    title,
    isMandatory,
    key,
    options,
    elRefIdPrefix,
    defaultValue,
    helpText,
    onChange,
  } = props;

  const [selectedOptionId, setSelectedOptionId] = useState(
    defaultValue?.value || ""
  );

  const [helpTooltipTextStatus, setHelpTooltipTextStatus] = useState(false);

  const [optionChildren, setOptionChildren] = useState([]);

  useEffect(() => {
    if (selectedOptionId) {
      const selectedOption = options.find(
        (option) => selectedOptionId.toString() === option.id.toString()
      );

      if (
        selectedOption &&
        selectedOption.children &&
        selectedOption.children.length > 0
      ) {
        setOptionChildren(selectedOption.children);
      } else {
        setOptionChildren([]);
      }
    }
  }, [selectedOptionId]);

  return (
    <Row key={key}>
      <Col sm="12">
        <div className="mb-3">
          <Label
            className="form-label"
            htmlFor={`${elRefIdPrefix}-question-${id}`}
          >
            {props.t(title)}

            {helpText && (
              <span className="ms-2 tooltip-container">
                <i
                  className="fa fa-info-circle help-text-icon"
                  aria-hidden="true"
                  id={`${elRefIdPrefix}-question-${id}-help-text`}
                />
                <Tooltip
                  style={{
                    backgroundColor: "#899ba1",
                    fontWeight: "bold",
                  }}
                  placement="top"
                  isOpen={helpTooltipTextStatus}
                  target={`${elRefIdPrefix}-question-${id}-help-text`}
                  toggle={() =>
                    setHelpTooltipTextStatus(!helpTooltipTextStatus)
                  }
                >
                  {<p>{props.t(helpText)}</p>}
                </Tooltip>
              </span>
            )}
          </Label>
          <AvRadioGroup
            name={"question[" + id + "]"}
            onChange={(e) => {
              e.target.type === "radio" && setSelectedOptionId(e.target.value);
            }}
            validate={{
              required: { value: isMandatory },
            }}
            errorMessage={props.t("This field cannot be blank")}
          >
             <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                flexWrap:'wrap',
                gap: "64px",
                marginLeft:"18px"
                
              }}>
              {(options || []).map((option) => {
                return (
                  <div
                  key={`${id}-${option.id}`}
                  >
                    <Option
                      type={
                        option?.title.toLowerCase() === "yes" &&
                        (option?.type === "country")
                          ? "country_list"
                          : option.type
                      }
                      title={option.title}
                      id={option.id}
                      groupId={id}
                      onChange={(e) =>
                        {
                          return(onChange(
                          e,
                          option?.children?.length > 0 ||
                            (option?.extended_title &&
                              (option?.type === "country" || option?.type === "country_multi_select")),
                          option?.extended_title && (option?.type !== "country" || option?.type !== "country_multi_select")
                            ? true
                            : false,
                          option?.id
                        ))
                      }
                      }
                      elRefIdPrefix={elRefIdPrefix}
                      options={
                        option?.title.toLowerCase() === "yes" &&
                        (option?.type === "multi_select" ||
                          option?.type === "country" || option?.type === "country_multi_select")
                          ? option?.children
                          : null
                      }
                      isSelected={
                        (selectedOptionId.toString() === option.id.toString()) 
                        // || (option?.title === defaultValue?.title) 
                      }
                      isChildren={option?.children?.length > 0}
                      defaultValue={
                        defaultValue && defaultValue?.children && defaultValue?.children.length
                          ? defaultValue?.children
                          : defaultValue && defaultValue?.title
                          ? defaultValue.title
                          : ""
                      }
                      extendedTitle={option.extended_title}
                    />
                  </div>
                );
              })}
            </div>
          </AvRadioGroup>
        </div>
      </Col>
    </Row>
  );
};

export default withNamespaces()(memo(Radiobox));
