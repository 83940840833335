import React, { useState } from "react";
// import MemorizedCreateRiskModal from "../RiskComponents/CreateRiskModal";
import BottyAI from "../../../../assets/images/bottyAi.png";
import "./Popup.css";
import { Button } from "reactstrap";

const BottyPopup = ({ t, showBottyPopup, onRiskAiBottySubmit }) => {
  const [isOpen, setIsOpen] = useState(showBottyPopup);

  // Function to toggle the visibility of the popup
  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      {/* Popup content */}
      {isOpen && (
        <div className="popup">
          <div className="popup-inner">
            <button className="close-button" onClick={togglePopup}>
              &times;
            </button>
            <h5>{t("Hi, I'm Botty. The AI Assistant from DISS-CO.")}</h5>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "10px",
              }}
            >
              <img src={BottyAI} width={"15%"} height={"15%"} alt="Botty" />
            </div>
            <p>
              {t(
                "I noticed that you haven't generated any risks and tasks yet. Would you like to see my suggestions?"
              )}
            </p>
            <div>
              <Button
                color="primary"
                outline
                className="me-3"
                onClick={onRiskAiBottySubmit}
              >
                {t("Yes")}
              </Button>
              <Button color="danger" outline onClick={togglePopup}>
                {t("No")}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BottyPopup;
