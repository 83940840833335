import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import Select from "react-select";
import "./ReportVoiceTranslator.css";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Collapse,
  Label,
  Input,
  Form,
  FormGroup
} from "reactstrap";
import axios from "axios";
import { API_URL_REPORT_TRANSLATOR_CONTECNT, API_URL_VOICE_CHANGE_MANUALTRANSCRIPT, CM_API_URL_REPORT_TRANSLATOR_CONTECNT } from "../../common/constants";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { connect } from "react-redux";
import { successNotification, errorNotification } from "../../store/actions";

class ReportVoiceTranslator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      translationMode: null, // 'ai' for AI-based, 'manual' for Manual
      selectedLanguage: null, // Selected language
      aiTranslation: "", // Store AI-based translation
      manualTranslation: "", // Store Manual translation
      isOpen: true,
      translations: null,
      manualTranslationFlag: false,
      isSaving: false,
    };
  }

  componentDidMount = () => {
    const { report } = this.props;
    // console.log(report.voices)
    const manualTranscripts = report.voices.filter(
      (r) => r.manual_transcript && (r.manual_transcript !== "")
    );
    if (manualTranscripts.length > 0) {
      // console.log("DID MOUNT MAKES IT TRUE", manualTranscripts);
      this.setState({
        manualTranslationFlag: true,
      });
    }
  };

  componentDidUpdate = () => {
    if(!this.state.manualTranslationFlag && this.props.manualText) {
      this.setState({
        manualTranslationFlag: true,
      });
    }
  }

  handleTranslationModeChange = (mode) => {
    this.setState({ translationMode: mode, selectedLanguage: "" });
  };

  handleLanguageChange = (selectedLanguage) => {
    this.setState({ selectedLanguage, aiTranslation: "", manualTranslation: "" });
    // Call the function to fetch translation when a language is selected
    if (selectedLanguage) {
      this.fetchTranslation(selectedLanguage);
    }
  };

  fetchTranslation = async (selectedLanguage) => {
    try {
      const result = await axios.post(
        this.props.module === 'wb'?API_URL_REPORT_TRANSLATOR_CONTECNT:CM_API_URL_REPORT_TRANSLATOR_CONTECNT,
        {
          codeLang: selectedLanguage,
          report_case_id: this.props.id,
        },
        {
          headers: {
            Authorization: `Bearer ${this.props.authToken}`,
          },
        }
      );
      if (result.status === 200) {
        const { data } = result.data;
        if (data.category[0] === "Translation failed") {
          return this.props.errorNotification({
            message: this.props.t(data.category[0]),
          });
        } else {
          // if (
          //   !this.state.manualTranslationFlag &&
          //   data.voices[0].manualTranscript &&
          //   data.voices[0].manualTranscript !== ""
          // ) {
          //   this.setState({ manualTranslationFlag: true });
          // }
          // console.log("Data", data.voices)
          if(data.voices?.at(0)?.aiTranscript === "Translation failed") {
            this.setState({
              translations: [],
              aiTranscript: [],
              manualTranslation: []
            })

            return this.props.errorNotification({
              message: `${this.props.t(data.voices.at(0).aiTranscript)}. ${this.props.t("Please select another language or try again")}`
            });
          }

          this.setState({
            translations: data.voices,
            aiTranslation: data.voices,
            manualTranslation: result.data.data.voices
          });
        }
      }
    } catch (error) {}
  };

  handleAITranslationChange = (event, ind) => {
    const { value } = event.target;
    const { aiTranslation } = this.state;
    aiTranslation.at(ind).aiTranscript = value;
    this.setState({ aiTranslation });

  };

  handleManualTranslationChange = (event, ind) => {
    const { value } = event.target;
    const { manualTranslation } = this.state;
    manualTranslation.at(ind).manualTranscript = value;
    this.setState({ manualTranslation });
  };

  toggleCollapse = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  handleSaveManualTranslation = async (id, index) => {
    this.setState({ isSaving: true })
    const url = API_URL_VOICE_CHANGE_MANUALTRANSCRIPT(id)
    const result = await axios.post(url, {
      manual_transcript: this.state.manualTranslation?.at(index)?.manualTranscript,
      lang: this.state.selectedLanguage
    },
        {
          headers: {
            Authorization: `Bearer ${this.props.authToken}`,
          },
        }
    )
    if(result.status === 200 && result.data.ok) {
      this.props.successNotification({
        message: this.props.t("Manual transcription updated successfully")
      })
      this.fetchTranslation(this.state.selectedLanguage)  
    } else {
      this.props.errorNotification({
        message: this.props.t("Failed to update manual transcription")
      })
    }
    this.setState({isSaving: false})
  }
  renderLanguageSelect = () => {
    const { t, organizationLanguages, isDeleted } = this.props;
    const { selectedLanguage } = this.state;

    return (
      <div className="mt-2 mb-3">
        <Label className="form-label text-dark">
          {t("Language translator")}:
        </Label>
        <Input
          type="select"
          id="lang-select"
          className="select2-selection"
          onChange={(e) => this.handleLanguageChange(e.target.value)}
          disabled={isDeleted}
          value={selectedLanguage}
        >
          <option value="">{t("Select")}</option>
          {organizationLanguages.map((language) => (
            <option key={language.value} value={language.value}>
              {language.label}
            </option>
          ))}
        </Input>
      </div>
    );
  };

  renderSelectedTranslationComponent = (translation, ind) => {
    const { translationMode, manualTranslationFlag } = this.state;
    const { aiTranscript: aiTranslation, manualTranscript: manualTranslation } =
      translation;
      // console.log("Translation", translation)
    const { authToken, t } = this.props;
    // const id = `file_${translation.id}-v${ind}`;
    const id = `file_${localStorage.getItem("secondaryCaseId")}-v${ind+1}`;
    if (!this.state.selectedLanguage) {
      return null; // Hide translation components until a language is selected
    }

    if (translationMode === "manual" && !manualTranslationFlag) {
      if (manualTranslation) {
        // console.log("Func MAKES IT TRUE");
        this.setState({ manualTranslationFlag: true});
      }
    }
    console.log(this.state.manualTranslation, "MANUAL TRANSCRIPT")

    return (
      <div>
        {translationMode === "ai" && (
          <div>
            <Label className="form-label text-dark">{id}:</Label>
            <Form>
              <FormGroup>
                <Input
                  type="textarea"
                  value={this.state.aiTranslation.at(ind)?.aiTranscript}
                  onChange={(e) => this.handleAITranslationChange(e, ind)}
                  readOnly
                />
              </FormGroup>
            </Form>
          </div>
        )}

        {translationMode === "manual" && (
          <div className="mt-4" hidden={!manualTranslation}>
            <Label className="form-label text-dark d-flex justify-conten-between">
              <span>
                {id}:
              </span>
              <button
                onClick={() => this.handleSaveManualTranslation(translation.id)}
                style={{
                  display: 'inline-block',
                  marginLeft: 'auto',
                  marginRight: '0',
                  borderRadius: '0.25rem',
                  fontSize: '11px',
                  lineHeight: '20px',
                  padding: !this.state.manualTranslation?.at(ind)?.manualTranscript.trim() || this.state.isSaving ? '0 10px' : '0 30px',
                  cursor: !this.state.manualTranslation?.at(ind)?.manualTranscript.trim() || this.state.isSaving  ? 'not-allowed' : 'pointer',
                  opacity: !this.state.manualTranslation?.at(ind)?.manualTranscript.trim() || this.state.isSaving ? '0.5' : '1',
                  transition: 'all ease 0.3s'
                }}
                disabled={this.state.isSaving}
                className='btn btn-primary'
              >
                {t('Save')}
              </button>
            </Label>
            <Form>
              <FormGroup>
                <Input
                  type="textarea"
                  value={this.state.manualTranslation.at(ind)?.manualTranscript}
                  onChange={(e) => this.handleManualTranslationChange(e, ind)}
                />
              </FormGroup>
            </Form>
          </div>
        )}
      </div>
    );
  };

  render() {
    const { translationMode, manualTranslationFlag, isOpen } = this.state;
    const { t } = this.props;
    // console.log(translationMode, manualTranslationFlag, this.props.manualText)
    return (
      <Row>
        <Col sm="12">
          <Card className="mb-1 shadow-none">
            <Link
              to="#"
              onClick={this.toggleCollapse}
              style={{ cursor: "pointer" }}
              className="text-dark"
            >
              <CardHeader id="headingOne" className="bg-primary">
                <h6 className="m-0 text-white font-14">
                  {t("Content translator")}
                  <i
                    className={`float-end accor-plus-icon mdi ${
                      isOpen ? "mdi-minus" : "mdi-plus"
                    }`}
                  ></i>
                </h6>
              </CardHeader>
            </Link>

            <Collapse isOpen={isOpen}>
              <CardBody>
                <div>
                  <Label className="form-label text-dark">
                    {t("Language translator")}:
                  </Label>
                  <div
                    className="mt-2"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px", // Adjust the gap as needed
                    }}
                  >
                    <Label>
                      <Input
                        type="radio"
                        id="aiTranslationRadio"
                        name="translationMode"
                        value="ai"
                        onChange={() => this.handleTranslationModeChange("ai")}
                      />{" "}
                      {t("AI-based Transcription")}
                    </Label>
                    <Label>
                      <Input
                        type="radio"
                        id="manualTranslationRadio"
                        name="translationMode"
                        value="manual"
                        onChange={() =>
                          this.handleTranslationModeChange("manual")
                        }
                      />{" "}
                      {t("Manual Transcription")}
                    </Label>
                  </div>
                </div>

                {translationMode &&
                  (translationMode === "ai" || (translationMode === "manual" && manualTranslationFlag) ? (
                    <>
                      <div>
                        {this.renderLanguageSelect()}
                        {this.state.translations
                          ? this.state.translations.map((t, i) =>
                              this.renderSelectedTranslationComponent(t, i)
                            )
                          : null}
                      </div>
                    </>
                    
                  ) : (
                    <p style={{ color: "red" }}>
                      {this.props.t("There is no manual transcript.")}
                    </p>
                  ))}
              </CardBody>
            </Collapse>
          </Card>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  const { token, user } = state.Login;
  const { Organization, App } = state;
  return { token, Organization, user, App };
};

export default withNamespaces()(
  withRouter(
    connect(mapStateToProps, { errorNotification, successNotification })(
      ReportVoiceTranslator
    )
  )
);
