import React, { useEffect, useRef, useState, useMemo } from "react";
import { withNamespaces } from "react-i18next";
import { Dropdown, DropdownToggle, DropdownMenu, Button } from "reactstrap";

import Form from "react-bootstrap/Form";
import { Nav, NavItem, NavLink } from "reactstrap";

import { icons} from "../../../components/table-filter/constants";

import { useQuery } from "@tanstack/react-query";

import Spinner from "react-bootstrap/Spinner";

import { toast } from "react-toastify";
import { connect } from "react-redux";
import {
  REPORT_STATUS_LIST,
} from "../../../../../../../../components/constants";
import TaskManagerService from "src/modules/data-protection/apis/TaskManagerService";
import { HiChevronDoubleUp, HiChevronDoubleDown, HiMenuAlt4 } from 'react-icons/hi';


const TableFilter = ({
  t,
  onFilterChanged,
  onOkButtonClicked,
  defaultValues,
  caseId,
}) => {
  const [filters, setFilters] = useState({
    status: defaultValues.status || null,
    priority: defaultValues.priority || null,
    owner: defaultValues.owner || null,
  });

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("Owner");
  const dateInputRef = useRef();

  const handleFetchOwner = useQuery({
    queryKey: ["fetch-supplier-Analys-Admins"],
		queryFn: async () => {
			const service = TaskManagerService.getInstance();

            return await service.fetchOwners(caseId);
		},
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      toast(t("An error occurred while fetching owners."), {
        type: "error",
      });
    },
  });


  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const priorities = useMemo(() => {
		return [
			{
				value: 'low',
				baseLabel: 'Low',
				label: (
					<>
						<HiChevronDoubleDown className='text-info' />
						<span>{'  '}{t('Low')}</span>
					</>
				),
			},
			{
				value: 'medium',
				baseLabel: 'Medium',
				label: (
					<>
						<HiMenuAlt4 className='text-success' />
						<span>{'  '}{t('Medium')}</span>
					</>
				),
			},
			{
				value: 'high',
				baseLabel: 'High',
				label: (
					<>
						<HiChevronDoubleUp className='text-danger' />
						<span>{'  '}{t('High')}</span>
					</>
				),
			},
		];
	}, [t]);



  const renderOwnerFilterList = () => {
    if (handleFetchOwner.isFetching || handleFetchOwner.isLoading) {
      return (
        <div className="text-center">
          <Spinner animation="border" variant="primary" size="md" />
        </div>
      );
    }

    return (handleFetchOwner.data || []).map((item) => {
      return (
        <div className="filter-item" key={item.id}>
          <Form.Check
            type="radio"
            id={`Owner-${item.id}-filter`}
            checked={filters.owner === item.id}
            onChange={() => {
              setFilters((prevFilters) => ({
                ...prevFilters,
                owner: item.id,
              }));
            }}
            label={`${item.first_name + " " + item.last_name}`}
          />
        </div>
      );
    });
  };


  const renderPriorityFilterList = () => {
    const result = [];
  
    for (const priority of priorities) {
      result.push(
        <div className="filter-item" key={priority.value}>
          <Form.Check
            type="radio"
            id={`priority-${priority.value}-filter`}
            checked={filters.priority === priority.value}
            onChange={() => {
              setFilters((prevFilters) => ({
                ...prevFilters,
                priority: priority.value,
              }));
            }}
            label={priority.label}
          />
        </div>
      );
    }
  
    return result;
  };
  
  const renderStatusFilterList = () => {
    const result = [];
  
    for (const status of REPORT_STATUS_LIST) {
      result.push(
        <div className="filter-item" key={status.id}>
          <Form.Check
            type="radio"
            id={`status-${status.id}-filter`}
            checked={filters.status === status.id}
            onChange={() => {
              setFilters((prevFilters) => ({
                ...prevFilters,
                status: status.id,
              }));
            }}
            label={t(status.titleKey)}
          />
        </div>
      );
    }
  
    return result;
  };
  

  const renderNavContent = () => {
    switch (activeTab) {
      case "Owner":
        return renderOwnerFilterList();

      case "Priority":
        return renderPriorityFilterList();

      case "Status":
        return renderStatusFilterList();

      default:
        return null;
    }
  };

  useEffect(() => {
    if (activeTab === "Owner" && dateInputRef?.current) {
      dateInputRef.current.focus();
    }
  }, [activeTab]);

  return (
    <div className="custom-table-filter">
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle caret color="secondary" outline>
          <i className="ri-filter-3-fill"></i>
          <span>{t("Filter")}</span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-end">
          <div className="filters-container">

            <div className="navs-container d-flex justify-content-center">
              <Nav tabs>
                <NavItem
                  onClick={() => {
                    setActiveTab("Owner");
                    // handleFetchRisksManagers.refetch();
                  }}
                  className={`${activeTab === "Owner" && "active"}`}
                >
                  <NavLink>
                    <img
                      src={
                        activeTab === "Owner"
                          ? icons.Analyst.active
                          : icons.Analyst.default
                      }
                      alt="analyst-filter"
                    />
                    <span>{t("Owner")}</span>
                  </NavLink>
                </NavItem>

                <NavItem
                  onClick={() => {
                    setActiveTab("Priority");
                    
                  }}
                  className={`${activeTab === "Priority" && "active"}`}
                >
                  <NavLink>
                    <HiChevronDoubleUp size={17} />
                    <span>{t("Priority")}</span>
                  </NavLink>
                </NavItem>
                <NavItem
                  onClick={() => setActiveTab("Status")}
                  className={`${activeTab === "Status" && "active"}`}
                >
                  <NavLink>
                    <img
                      src={
                        activeTab === "Status"
                          ? icons.Status.active
                          : icons.Status.default
                      }
                      alt="status-filter"
                    />
                    <span>{t("Status")}</span>
                  </NavLink>
                </NavItem>
          
              </Nav>
            </div>

            <div className="navs-content-container">{renderNavContent()}</div>

            <div className="actions-container d-flex align-items-center justify-content-end">
            <Button
                color="secondry"
                size="sm"
                onClick={() => {
                 setFilters(  {  status: null,
                  category: null,
                  priority: null,
                  owner: null
                })}}
              >
                {t("Reset")}
              </Button>

              <Button
                color="primary"
                size="sm"
                onClick={() => {
                  onOkButtonClicked && onOkButtonClicked(filters);
                  toggle();
                }}
              >
                {t("OK")}
              </Button>

            </div>
          </div>
        </DropdownMenu>
      </Dropdown>

    </div>
  );
};

const mapStatetoProps = (state) => {
  const { token } = state.Login;

  return {
    token,
  };
};


export default withNamespaces()(
    connect(mapStatetoProps)(TableFilter)
);
