import React, { Component } from "react";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  InputGroup,
  InputGroupAddon,
  Button,
  Label,
  Table,
} from "reactstrap";

import { connect } from "react-redux";

import { withRouter } from "react-router-dom";

import { withNamespaces } from "react-i18next";

import { AvForm, AvField } from "availity-reactstrap-validation";

import { isFeatureEnabledForActiveModule } from "src/helpers/module_helper";


import { successNotification, errorNotification } from "../../../../../store/actions";

import Breadcrumbs from "../../../../../components/Common/Breadcrumb";

import Papa from "papaparse";
import axios from "axios";
import { TbLoader } from "react-icons/tb";
import { API_URL_ADMIN_CREATE_GDPR_USER_MANAGEMENT } from "src/modules/data-protection/constants";

class AddUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "SIP", link: "/" },
        { title: this.props.t("New user"), link: "#" },
      ],
      items: [
        {
          first_name: "",
          last_name: "",
          email: "",
          position: "",
          hasTaskOwnerRole: "false",
        },
      ],
      showSubmitProgress: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.addUser = this.addUser.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.uploadCSV = this.uploadCSV.bind(this);
  }

  handleSubmit() {
    this.setState({ showSubmitProgress: true });
    const items = this.state.items.map((i) => {
      const item = {
        first_name: i.first_name,
        last_name: i.last_name,
        email: i.email,
        position: i.position,
      };

      if (i.hasTaskOwnerRole === "true") {
        item["roles"] = ["ROLE_TASK_OWNER"];
      }
      return item;
    });
    axios
      .post(API_URL_ADMIN_CREATE_GDPR_USER_MANAGEMENT, items, {
        headers: {
          Authorization: `Bearer ${this.props.token}`,
        },
      })
      .then((res) => {
        this.setState({ showSubmitProgress: false });
        this.props.successNotification({
          code: "saved",
          message: this.props.t("Data was saved successfully"),
        });
        this.props.history.push("/admin/data-protection/administration/users");
      })
      .catch((err) => {
        this.setState({ showSubmitProgress: false });
        if (axios.isAxiosError(err)) {
          const error = err?.response?.data?.message;
          const entries = Object.values(error).forEach((val) => {
            console.log(val);
            this.props.errorNotification({
              code: "saved",
              message:
                val?.email === "validation.email.already_used"
                  ? this.props.t("This email has already been used.")
                  : this.props.t(JSON.stringify(val)),
            });
          });
        }
      });
  }

  fieldValueChanged(index, key, value) {
    this.setState({
      items: this.state.items.map((item, itemIndex) => {
        if (index === itemIndex) {
          let newValue = item;
          newValue[key] = value;
          return newValue;
        }
        return item;
      }),
    });
  }

  addUser() {
    const items = this.state.items;
    items.push({
      email: "",
      position: "",
      first_name: "",
      last_name: "",
    });
    this.setState({
      items: items,
    });
  }

  deleteUser(index) {
    if (this.state.items.length > 1) {
      this.setState({
        items: this.state.items.filter((item, itemIndex) => {
          return index !== itemIndex;
        }),
      });
    }
  }

  uploadCSV(e) {
    const files = e.target.files;
    if (files && files.length > 0) {
      Papa.parse(files[0], {
        complete: (results, file) => {
          const data = results.data;
          const errors = results.errors;

          if (errors && errors.length > 0) {
            this.props.errorNotification({
              code: "CAN_NOT_PARSE_CSV",
              message: this.props.t("Error to parse the csv file"),
            });
            return;
          }

          if (data && data.length > 0) {
            let firstNameIndex = -1;
            let lastNameIndex = -1;
            let emailIndex = -1;
            let companyPositionIndex = -1;
            let headerIndex = -1;
            const newRows = [];
            for (let i = 0; i < data.length; i++) {
              const items = data[i];
              if (items && items.length > 0 && headerIndex === -1) {
                firstNameIndex = items.findIndex(
                  (label) => label.toLowerCase() === "first_name"
                );
                lastNameIndex = items.findIndex(
                  (label) => label.toLowerCase() === "last_name"
                );
                emailIndex = items.findIndex(
                  (label) => label.toLowerCase() === "email"
                );
                companyPositionIndex = items.findIndex(
                  (label) => label.toLowerCase() === "position"
                );

                if (
                  firstNameIndex !== -1 &&
                  lastNameIndex !== -1 &&
                  emailIndex !== -1 &&
                  companyPositionIndex !== -1
                ) {
                  headerIndex = i;
                }
              }

              if (
                headerIndex > -1 &&
                i > headerIndex &&
                items.length === data[headerIndex].length
              ) {
                newRows.push({
                  first_name: items[firstNameIndex] || "",
                  last_name: items[lastNameIndex] || "",
                  email: items[emailIndex] || "",
                  position: items[companyPositionIndex] || "",
                });
              }
            }

            if (headerIndex === -1) {
              this.props.errorNotification({
                code: "CAN_NOT_PARSE_CSV",
                message: this.props.t(
                  "Invalid format, please check the template for details"
                ),
              });
              return;
            }
            if (newRows.length > 0) {
              this.setState({
                items: newRows,
              });
            }
          }
        },
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.props.t("New user")}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    {isFeatureEnabledForActiveModule("new-user-upload-csv") && (
                      <Row>
                        <Col sm="12" className="text-end">
                          <Button
                            type="button"
                            color="success"
                            className="mb-2 me-2"
                          >
                            <input
                              style={{
                                position: "absolute",
                                width: "100%",
                                height: "100%",
                                left: "0px",
                                top: "0px",
                                right: "0px",
                                bottom: "0px",
                                zIndex: "1",
                                opacity: "0",
                                cursor: "pointer",
                              }}
                              type="file"
                              onChange={this.uploadCSV}
                            />
                            <i className="fa fa-upload"></i>
                            {` ${this.props.t("Upload CSV")}`}
                          </Button>
                        </Col>
                      </Row>
                    )}
                    <AvForm
                      className="needs-validation"
                      onValidSubmit={this.handleSubmit}
                    >
                      <Row>
                        <Col sm="12">
                          <div className="table-responsive">
                            <Table>
                              <thead>
                                <tr>
                                  <th>
                                    {this.props.t("First name")}{" "}
                                    <span className="text-danger">*</span>
                                  </th>
                                  <th>{this.props.t("Last name")}</th>
                                  <th>
                                    {this.props.t("Email")}{" "}
                                    <span className="text-danger">*</span>
                                  </th>
                                  <th>{`${this.props.t(
                                    "Company Position"
                                  )} ${this.props.t("(Optional)")}`}</th>
                                  <th>{this.props.t("Task Owner")}</th>
                                  <th>{this.props.t("Delete")}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.items.map((user, index) => {
                                  return (
                                    <tr>
                                      <td>
                                        <AvField
                                          name={`first_name[${index}]`}
                                          placeholder=""
                                          type="text"
                                          errorMessage={this.props.t(
                                            "This field cannot be blank"
                                          )}
                                          className="form-control"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          id={`first-name-${index}`}
                                          value={user.first_name}
                                          onChange={(e) =>
                                            this.fieldValueChanged(
                                              index,
                                              "first_name",
                                              e.target.value
                                            )
                                          }
                                        />
                                      </td>
                                      <td>
                                        <AvField
                                          name={`last_name[${index}]`}
                                          placeholder=""
                                          type="text"
                                          className="form-control"
                                          validate={{
                                            required: { value: false },
                                          }}
                                          id={`last-name-${index}`}
                                          value={user.last_name}
                                          onChange={(e) =>
                                            this.fieldValueChanged(
                                              index,
                                              "last_name",
                                              e.target.value
                                            )
                                          }
                                        />
                                      </td>
                                      <td>
                                        <AvField
                                          name={`email[${index}]`}
                                          placeholder=""
                                          type="text"
                                          errorMessage={this.props.t(
                                            "This field cannot be blank"
                                          )}
                                          className="form-control"
                                          validate={{
                                            required: { value: true },
                                            email: true,
                                          }}
                                          id={`email-${index}`}
                                          value={user.email}
                                          onChange={(e) =>
                                            this.fieldValueChanged(
                                              index,
                                              "email",
                                              e.target.value
                                            )
                                          }
                                        />
                                      </td>
                                      <td>
                                        <AvField
                                          name={`position[${index}]`}
                                          placeholder=""
                                          type="text"
                                          className="form-control"
                                          id={`position-${index}`}
                                          value={user.position}
                                          onChange={(e) =>
                                            this.fieldValueChanged(
                                              index,
                                              "position",
                                              e.target.value
                                            )
                                          }
                                        />
                                      </td>
                                      <td>
                                        <div className="d-flex w-full justify-content-center">
                                          <AvField
                                            name={`hasTaskOwnerRole[${index}]`}
                                            placeholder=""
                                            type="checkbox"
                                            className="form-control"
                                            id={`hasTaskOwnerRole-${index}`}
                                            value={user.hasTaskOwnerRole}
                                            style={{ padding: "10px" }}
                                            onChange={(e) => {
                                              this.fieldValueChanged(
                                                index,
                                                "hasTaskOwnerRole",
                                                e.target.value === "true"
                                                  ? "false"
                                                  : "true"
                                              );
                                            }}
                                          />
                                        </div>
                                      </td>
                                      <td>
                                        <Button
                                          type="button"
                                          color="link"
                                          className="waves-effect me-1 text-danger"
                                          onClick={() => this.deleteUser(index)}
                                        >
                                          <i className="ri-delete-bin-fill"></i>
                                        </Button>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </Table>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="12">
                          <Button
                            color="secondary"
                            type="button"
                            onClick={this.addUser}
                          >
                            {this.props.t("Add User")}
                          </Button>
                          <Button
                            color="primary"
                            type="submit"
                            style={{ float: "right" }}
                          >
                            {/* {this.props.t("Send Invite")} */}
                            {this.state.showSubmitProgress ? (
                              <TbLoader />
                            ) : (
                              this.props.t("Send Invite")
                            )}
                          </Button>
                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { token } = state.Login;
  const { Modules } = state;
  return { token, state, modules: Modules };
};

export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
      successNotification,
      errorNotification,
    })(AddUser)
  )
);
