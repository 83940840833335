import React, { memo, useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { Col, Container, Row } from "reactstrap";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import Navigator from "../../../components/navigator";
import { withRouter } from "react-router-dom";
import Tabs from "./components/tabs";

import IncidentTable from "./IncidentTable";
import {
  API_URL_GET_REPORT_LIST,
  API_URL_GET_ANALYST_REPORT_LIST,
  API_URL_GET_ANALYST_REPORT_LISTS_COUNT,
} from "../../../constants";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { toast } from "react-toastify";

const IncidentRegister = (props) => {
  const { t } = props;
  const location = useLocation();
  
  const [totalCount, setTotalCount] = useState(0);
  const [assignedToMeCount, setAssignedToMeCount] = useState(0);
  const [deletedCount, setDeletedCount] = useState(0);
  const [reportedByMeCount, setReportedByMeCount] = useState(0);

  const incidentTypes = [
    {type: "all", url: API_URL_GET_ANALYST_REPORT_LIST, assignedToMe: false},
    {type: "assigned-to-me", url: API_URL_GET_ANALYST_REPORT_LIST, assignedToMe: true},
    {type: "deleted-reports",  url: API_URL_GET_ANALYST_REPORT_LIST, assignedToMe: false},
    {type: "reported-by-me", url: API_URL_GET_REPORT_LIST, assignedToMe: false}
  ];
  

  const searchParams = new URLSearchParams(location.search);
  const tab = searchParams.get("tab");

  const [tableFilters, setTableFilters] = useState({
    pageIndex: 1,
    pageSize: 25,
    status: tab === "deleted-reports" ? 5 : null,
    adminCategories: [],
    risk: null,
    analystAssigned: null,
    sortField :null,
    sortOrder: null
  });


  const findTypeFeatures = (type) => {
    return incidentTypes.filter(item => item.type === type)[0];
  }
  
  const handleFetchCount = useQuery({
    queryKey: ["report-count"],
    queryFn: async () => {
      const url = API_URL_GET_ANALYST_REPORT_LISTS_COUNT;
      try {
        const response = await axios.get(`${url}`, {
          params: {
            questionnaire: "data_breach_incident"
          },
          headers: {
            Authorization: `Bearer ${props.token}`,
          },
        });
        return response.data || [];
      } catch (error) {
        throw new Error("An error occurred while fetching counts.");
      }
    },
  });

  const handleFetchReportListQuery = useQuery({
    queryKey: [
      "report-incident-register-fetch-list",
      tableFilters
    ],
    queryFn: async () => {
      const {
        status,
        adminCategories,
        risk,
        analystAssigned,
      } = tableFilters;
      const url = findTypeFeatures(tab || "all")?.url;
      const transformedAdminCategories =
      (adminCategories?.length === 1 && adminCategories[0] === "all") || !adminCategories?.length
        ? null
        : adminCategories;
        
      const param = {
        assignedToMe: findTypeFeatures(tab || "all")?.assignedToMe,
        ...(status !== undefined && status && { status }), // Add status if not null or undefined
        ...(transformedAdminCategories !== null && { adminCategories: transformedAdminCategories }),
        ...(risk !== null && { risk }), // Conditionally add risk if not null
        ...(analystAssigned !== null && { analystAssigned }), // Conditionally add analystAssigned if not null
        pageIndex: tableFilters.pageIndex,
        pageSize: tableFilters.pageSize,
        ...(tableFilters.sortField && { sortField: tableFilters.sortField }),
        ...(tableFilters.sortField && { sortOrder: tableFilters.sortOrder }),
        questionnaire:"data_breach_incident",//used for the incident only
      };
      try {
        const response = await axios.post(url, param, {
          headers: {
            Authorization: `Bearer ${props.token}`,
          },
        });

        switch(tab) {
          case "all":
            setTotalCount(response.data?.data?.itemsCount);
            break;
          case "assigned-to-me": 
            setAssignedToMeCount(response.data?.data?.itemsCount);
            break;
          case "deleted-reports":
            setDeletedCount(response.data?.data?.itemsCount);
            break;
          case "reported-by-me":
            setReportedByMeCount(response.data?.data?.itemsCount);
            break;
        }

        return response.data?.data || [];
      } catch (error) {
        throw new Error(
          "An error occurred while fetching report list."
        );
      }
    },
    enabled: false,
    config: {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      onError: (error) => {
        toast(
          props.t("An error occurred while fetching report list."),
          {
            type: "error",
          }
        );
      },
    },
  });

  useEffect(() => {
    if(!totalCount || !assignedToMeCount || !deletedCount || !reportedByMeCount) {
      const counts = handleFetchCount?.data?.data;
      setTotalCount(counts?.all);
      setAssignedToMeCount(counts?.assigned_to_me);
      setDeletedCount(counts?.deleted);
      setReportedByMeCount(counts?.my_cases);
    }
  }, [handleFetchCount.data]);

  useEffect(() => {
    setTableFilters({
      pageIndex: 1,
      pageSize: 25,
      status: tab === "deleted-reports" ? 5 : null,
      adminCategories: [],
      risk: null,
      analystAssigned: null,
      sortField : null,
      sortOrder: null
    });
  }, [tab]);

  useEffect(() => {
    handleFetchReportListQuery.refetch();
  }, [tableFilters])


  const renderSelectedTabContent = () => {
    switch (tab) {
      case "all":
        return (
          <IncidentTable
            type="all"
            assignedToMe={false}
            url={API_URL_GET_ANALYST_REPORT_LIST}
            handleFetchReportListQuery={handleFetchReportListQuery}
            tableFilters={tableFilters}
            setTableFilters={setTableFilters}
            tab={tab}
          />
        );
      case "assigned-to-me":
        return (
          <IncidentTable
            type="assigned-to-me"
            assignedToMe={true}
            url={API_URL_GET_ANALYST_REPORT_LIST}
            handleFetchReportListQuery={handleFetchReportListQuery}
            tableFilters={tableFilters}
            setTableFilters={setTableFilters}
            tab={tab}
          />
        );
      case "deleted-reports":
        return (
          <IncidentTable
            type="deleted-reports"
            assignedToMe={true}
            status={5}
            url={API_URL_GET_ANALYST_REPORT_LIST}
            handleFetchReportListQuery={handleFetchReportListQuery}
            tableFilters={tableFilters}
            setTableFilters={setTableFilters}
            tab={tab}
          />
        );
      case "reported-by-me":
        return (
          <IncidentTable
            type="reported-by-me"
            assignedToMe={false}
            url={API_URL_GET_REPORT_LIST}
            handleFetchReportListQuery={handleFetchReportListQuery}
            tableFilters={tableFilters}
            setTableFilters={setTableFilters}
            tab={tab}
          />
        );

      default:
        return (
          <IncidentTable
            type="all"
            assignedToMe={false}
            url={API_URL_GET_ANALYST_REPORT_LIST}
            handleFetchReportListQuery={handleFetchReportListQuery}
            tableFilters={tableFilters}
            setTableFilters={setTableFilters}
            tab={tab}
          />
        );
    }
  };

  return (
    <div className="page-content ">
      <Container fluid>
        <Row>
          <Col lg="12" className="mb-4">
            <Navigator
              breadCrumbs={[
                { title: "SIP", link: "/" },
                {
                  title: t("Incident Register"),
                  link: `/admin/data-protection/incident-register`,
                },
              ]}
            />
          </Col>

          <Col lg="12" className="mb-4">
            <div
              className="bg-white"
              style={{
                borderRadius: "7px",
                boxShadow: "0px 0px 4px 0px #4F666E40",
                marginBottom: "40px",
              }}
            >
              <Tabs
                t={t}
                totalCount={totalCount}
                assignedToMeCount={assignedToMeCount}
                deletedCount={deletedCount}
                reportedByMeCount={reportedByMeCount}
              />

              {renderSelectedTabContent()}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStatetoProps = (state) => {
  const { token } = state.Login;
  const { Organization } = state;
  return {
    user: state.Login.user,
    token,
    organization: Organization,
  };
};

export default withNamespaces()(
  withRouter(connect(mapStatetoProps, {})(memo(IncidentRegister)))
);
