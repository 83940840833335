import axios from 'axios';

import {
    API_BASE_URL
} from '../constants/index';

export default class IncidentRegisterRiskAssessmentService {
	static instance;

	static getInstance() {
		if (!IncidentRegisterRiskAssessmentService.instance) {
			IncidentRegisterRiskAssessmentService.instance = new IncidentRegisterRiskAssessmentService();
		}

		return IncidentRegisterRiskAssessmentService.instance;
	}

    async fetchRisks(assetId, params = {}){
        const response = await axios.get(`${API_BASE_URL}/report/${assetId}/risks`, {
            params  :   params,
            headers :   {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`
            },
        });
        return response.data.data
    }

    async fetchMatrix(assetId, params = {}){
        const response = await axios.get(`${API_BASE_URL}/report/${assetId}/risk_rate/matrix`, {
            params  :   params,
            headers :   {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`
            },
        });
        return response.data.data
    }

    async fetchOwners(assetId, payload = {}){
        const response = await axios.post(`${API_BASE_URL}/analystAndAdminAnalyst.list`, payload, {
            params: {},
            headers: {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`,
            },
        });
  
        return response.data.data;
    }

    async fetchManagers(assetId, params = {}){
        const response = await axios.get(`${API_BASE_URL}/manager.list`, {
            params  :   params,
            headers :   {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`
            },
        });

        return response.data.data
    }

    async fetchCategories(assetId, params = {}){
        const response = await axios.get(`${API_BASE_URL}/risk_rate_category/index`, {
            params  :   params,
            headers :   {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`
            },
        });

        return response.data.data
    }

    async fetchLocations(assetId, payload = {}){
        const response = await axios.post(`${API_BASE_URL}/report.country.list`, payload, {
            params: {},
            headers: {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`,
            },
        });
  
        return response.data.data;
    }

    async deleteRisk(assetId, riskId){
        const response = await axios.delete(`${API_BASE_URL}/risk_rate/${riskId}/delete`,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                    )}`,
                }
            }
        );
  
        return response.data.data;
    }    
}