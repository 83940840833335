import {
    ERROR_CODE_INVALID_ARGUMENTS,
    ERROR_CODE_INVALID_AUTHENTICATION,
    ERROR_CODE_FATAL_ERROR,
    ERROR_CODE_UNKNOWN_ERROR,
    ERROR_CODE_INVALID_ARG_NAME,
    ERROR_CODE_INVALID_USER_AUTHORIZE,
    ERROR_CODE_AUTHENTICATION_ATTEMPTS,
    ERROR_CODE_JOIN_CHAT,
    ERROR_CODE_LIST_CHAT
} from '../constants';
import {
    TRANSLATION_KEY_SIGN_IN_NOTIFICATION as KEY_SIGN_IN_NOTIFICATION,
    TRANSLATION_KEY_GLOBAL_NOTIFICATION as KEY_GLOBAL_NOTIFICATION,
    TRANSLATION_KEY_USER_NOTIFICATION as KEY_USER_NOTIFICATION,
    MESSAGE_DATA_DELETED_SUCCESSFULLY,
    MESSAGE_DATA_SAVED_SUCCESSFULLY,
    MESSAGE_SET_PASSWORD_SUCCESSFULLY
} from '../../common/constants';

export default class NotificationUtils {
    getErrorMessageKey = (errorCode) => {
        switch (errorCode) {
            case ERROR_CODE_INVALID_AUTHENTICATION:
                return KEY_SIGN_IN_NOTIFICATION + 'INCORRECT_COMBINED_EMAIL_AND_PASSWORD';
            case ERROR_CODE_INVALID_ARGUMENTS:
                return KEY_GLOBAL_NOTIFICATION + 'INVALID_ARGUMENTS';
            case ERROR_CODE_FATAL_ERROR:
                return KEY_GLOBAL_NOTIFICATION + 'FATAL_ERROR';
            case ERROR_CODE_UNKNOWN_ERROR:
                return KEY_GLOBAL_NOTIFICATION + 'UNKNOWN_ERROR';
            case ERROR_CODE_INVALID_ARG_NAME:
                return KEY_GLOBAL_NOTIFICATION + 'INVALID_ARG_NAME';
            case ERROR_CODE_INVALID_USER_AUTHORIZE:
                return KEY_GLOBAL_NOTIFICATION + 'INVALID_USER_AUTHORIZE';
            case ERROR_CODE_AUTHENTICATION_ATTEMPTS:
                return KEY_GLOBAL_NOTIFICATION + 'AUTHENTICATION_ATTEMPTS';
            case ERROR_CODE_JOIN_CHAT:
                return KEY_GLOBAL_NOTIFICATION + 'CHAT_JOINED';
            case ERROR_CODE_LIST_CHAT:
                return KEY_GLOBAL_NOTIFICATION + 'GET_LIST_CHAT_ERROR';
            default:
                return '';
        }
    };
    
    getSuccessMessageKey = (message) => {
        switch (message) {
            case MESSAGE_DATA_DELETED_SUCCESSFULLY:
                return KEY_GLOBAL_NOTIFICATION + 'DATA_DELETED_SUCCESSFULLY';
            case MESSAGE_DATA_SAVED_SUCCESSFULLY:
                return KEY_GLOBAL_NOTIFICATION + 'DATA_SAVED_SUCCESSFULLY';
            case MESSAGE_SET_PASSWORD_SUCCESSFULLY:
                return KEY_USER_NOTIFICATION + 'SET_PASSWORD_SUCCESSFULLY';
            default:
                return '';
        }
    }
}
