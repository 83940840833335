import {
    MODULE_TPDD_ACTION_TYPE_UPDATE_QUESTIONNAIRE_PAYLOAD,
    MODULE_TPDD_ACTION_TYPE_UPDATE_QUESTIONNAIRE_UNSAVED_STEPS_COUNT,
    MODULE_TPDD_ACTION_TYPE_UPDATE_QUESTIONNAIRE_ALL_QUESTIONS_ANSWERED_STATE,
    MODULE_TPDD_ACTION_TYPE_UPDATE_QUESTIONNAIRE_REPORT_CASE_ID,
    MODULE_TPDD_ACTION_TYPE_RESET_QUESTIONNAIRE_STATE,
    MODULE_TPDD_ACTION_TYPE_UPDATE_LATEST_QUESTIONNAIRE_SAVE_REQUEST_STATUS
} from "./actionTypes";

const initialState = {
    reportCaseId            :   null,
    payload                 :   null,
    finalRequest            :   false,
    unSavedStepsCount       :   0,
    allQuestionsAnswered    :   false,
    latestSaveRequestStatus :   'unknown'
};

export const moduleTPDDQuestionnaire = (state = initialState, action) => {
    switch (action.type) {
        case MODULE_TPDD_ACTION_TYPE_RESET_QUESTIONNAIRE_STATE:
            state = {
                ...initialState
            };
        break;

        case MODULE_TPDD_ACTION_TYPE_UPDATE_QUESTIONNAIRE_PAYLOAD:
            state = {
                ...state,
                payload         :   action.payload.payload,
                finalRequest    :   action.payload.finalRequest
            };
        break;
        
        case MODULE_TPDD_ACTION_TYPE_UPDATE_QUESTIONNAIRE_UNSAVED_STEPS_COUNT:
            state = {
                ...state,
                unSavedStepsCount   :   action.payload.count
            };
            break;

        case MODULE_TPDD_ACTION_TYPE_UPDATE_QUESTIONNAIRE_REPORT_CASE_ID:
            state = {
                ...state,
                reportCaseId    :   action.payload.reportCaseId
            };
            break;

        case MODULE_TPDD_ACTION_TYPE_UPDATE_QUESTIONNAIRE_ALL_QUESTIONS_ANSWERED_STATE:
            state = {
                ...state,
                allQuestionsAnswered    :   action.payload.status
            };
            break;

        case MODULE_TPDD_ACTION_TYPE_UPDATE_LATEST_QUESTIONNAIRE_SAVE_REQUEST_STATUS:
            state = {
                ...state,
                latestSaveRequestStatus     :   action.payload.status
            };
            break;

        default:
            state = { ...state };
        break;
    }
    return state;
};