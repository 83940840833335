import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { API_URL_POST_E_LEARNING_PLAYER_TOKEN } from "src/common/constants";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import "./Style.css"
import Certificate from "./Certificate";

const Player = (props) => {
  const { srcUrl } = props;
  const iframeRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [certificateData, setCertificateData] = useState(null);


  const fetchHtmlContent = async () => {
    try {
      const response = await axios.get(
        srcUrl
        //     , {
        //     headers: {
        //         'Authorization': `Bearer ${props.token}`
        //     }
        // }
      );
      const iframeDocument =
        iframeRef.current.contentDocument ||
        iframeRef.current.contentWindow.document;

      if (iframeDocument) {
        const convertToAbsoluteUrls = (html, baseUrl) => {
          const doc = new DOMParser().parseFromString(html, "text/html");

          const base = doc.createElement("base");
          base.href = baseUrl;
          doc.head.insertBefore(base, doc.head.firstChild);

          doc.querySelectorAll("a").forEach((anchor) => {
            const href = anchor.getAttribute("href");
            if (href && !href.startsWith("http") && !href.startsWith("//")) {
              anchor.href = new URL(href, baseUrl).href;
            }
          });

          doc.querySelectorAll("img, script, link").forEach((tag) => {
            const src = tag.getAttribute("src") || tag.getAttribute("href");
            if (src && !src.startsWith("http") && !src.startsWith("//")) {
              if (tag.hasAttribute("src")) {
                tag.src = new URL(src, baseUrl).href;
              } else {
                tag.href = new URL(src, baseUrl).href;
              }
            }
          });

          return doc.documentElement.outerHTML;
        };

        const baseUrl = new URL(srcUrl).href;
        const absoluteHtmlContent = convertToAbsoluteUrls(
          response.data,
          baseUrl
        );

        iframeDocument.open();
        iframeDocument.write(absoluteHtmlContent);
        iframeDocument.close();

         // Inject custom styles
         const styleElement = iframeDocument.createElement('style');
         styleElement.textContent = `
           .choice-view__choice-container {
             width: auto !important;
             height: auto !important;
           }
         `;
         iframeDocument.head.appendChild(styleElement);

        const loadScripts = (scripts, index = 0) => {
          if (index >= scripts.length) return;

          const oldScript = scripts[index];
          const newScript = iframeDocument.createElement("script");

          if (oldScript.src) {
            // const res = axios.get(oldScript.src, {
            //     headers: {
            //       Authorization: `Bearer ${props.token}`,
            //     },
            //   });
            newScript.src = oldScript.src;
            // newScript.textContent = res.data;
            newScript.type = oldScript.type || "text/javascript";
            newScript.async = oldScript.async || false;
            newScript.defer = oldScript.defer || false;
            newScript.crossOrigin = oldScript.crossOrigin || null;

            if (oldScript.integrity) {
              newScript.integrity = oldScript.integrity;
            }

            newScript.onload = () => loadScripts(scripts, index + 1);
            newScript.onerror = () =>
              console.error(`Failed to load script: ${newScript.src}`);
            iframeDocument.body?.appendChild(newScript);
          } else {
            newScript.textContent = oldScript.textContent;
            iframeDocument.body?.appendChild(newScript);
            loadScripts(scripts, index + 1);
          }
        };

        const scripts = iframeDocument.querySelectorAll("script");
        loadScripts(scripts);
      }
    } catch (error) {
      console.error("Error fetching HTML content:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const searchLocalStorage = (substring) => {
    const result = [];
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key.includes(substring)) {
        const value = localStorage.getItem(key);
        result.push({ key, value });
      }
    }
    return result;
  };

  useEffect(() => {
    const onLoad = () => {
      fetchHtmlContent();
    };

    const iframe = iframeRef.current;

    if (iframe) {
      if (
        iframe.contentDocument &&
        iframe.contentDocument.readyState === "complete"
      ) {
        fetchHtmlContent();
      } else {
        iframe.addEventListener("load", onLoad);
        return () => {
          iframe.removeEventListener("load", onLoad);
        };
      }
    }

    // Cleanup function to log a message when the component unmounts
    return () => {
      (async () => {
        const url = API_URL_POST_E_LEARNING_PLAYER_TOKEN;
        const results = searchLocalStorage("ispring");
        const data = {
          lesson_id:
            Number(props.location.pathname.split("/")[3]) ||
            Number(localStorage.getItem("lessonId")),
          token_key: results[0]?.key,
          token_value: results[0]?.value,
        };
        const header = {
          headers: {
            Authorization: `Bearer ${props.token}`,
          },
        };

        try {
          const res = await axios.post(url, data, header);
        } catch (err) {
          console.log("Error in the system", err);
        }
      })();
    };
  }, [srcUrl]);

  useEffect(() => {
    // Fake data for testing
    const fakeData = {
      name: "John Doe",
      lesson_name: "React Development",
      date: "May 27, 2024",
      additional_info: "Completed with distinction"
    };

    // Simulate an API call with a timeout
    setTimeout(() => {
      setCertificateData(fakeData);
    }, 1000);

    // Uncomment and use the actual API call when ready
    // const fetchCertificateData = async () => {
    //   try {
    //     const response = await fetch('https://api.example.com/certificate');
    //     const data = await response.json();
    //     setCertificateData(data);
    //   } catch (error) {
    //     console.error('Error fetching certificate data:', error);
    //   }
    // };

    // fetchCertificateData();
  }, []);


  return (
    <div className="playerContainer mt-5">
      {isLoading && <div>Loading...</div>}
      {/* {certificateData ? (
        <Certificate
          name={certificateData.name}
          lessonName={certificateData.lesson_name}
          date={certificateData.date}
          additionalInfo={certificateData.additional_info}
        />
      ) : (
        <p>Loading...</p>
      )} */}
      <iframe
        ref={iframeRef}
        className="player"
        style={{ width: "100%", height: "100%" }}
      />
      <style>{`
        .choice-view__choice-container {
          /* Add your custom styles here */
          background-color: red !important;
          padding: 20px;
          border: 1px solid #ccc;
        }
      `}</style>
    </div>
  );
};

const mapStatetoProps = (state) => {
  const { token } = state.Login;
  const { App } = state;
  return { token, App };
};

export default withNamespaces()(withRouter(connect(mapStatetoProps)(Player)));
