import "./style.css";

import React from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Button } from "reactstrap";
import { withNamespaces } from "react-i18next";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { connect } from "react-redux";

const Certificate = (props) => {
  const { name, lessonName, date, additionalInfo, t } = props;
  const handleDownload = () => {
    const input = document.getElementById("certificate");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "PNG", 0, 0);
      pdf.save("certificate.pdf");
    });
  };

  return (
    <div className="certificateContainer">
      <div id="certificate" style={{ padding: 20, border: "1px solid #000" }}>
        <h1>Certificate of Completion</h1>
        <p>This is to certify that</p>
        <h2>{name}</h2>
        <p>has successfully completed the lesson</p>
        <h3>{lessonName}</h3>
        <p>on {date}</p>
        {additionalInfo && <p>{additionalInfo}</p>}
      </div>
      <Button color="primary" className="downloadButton" outline onClick={handleDownload}>
        {t("Download Certificate")}
      </Button>
    </div>
  );
};

const mapStatetoProps = (state) => {
  const { token } = state.Login;
  const { App } = state;
  return { token, App };
};

export default withNamespaces()(
  withRouter(connect(mapStatetoProps)(Certificate))
);
