import { withNamespaces } from 'react-i18next'
import { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'

const defaultOptions = {
    placeholder: 'Search'
}

const getOption = (option, options) => {
    if(!(option in (options || {}))) return defaultOptions[option];

    return options[option];
}

const SearchTextBox = withNamespaces()(({
    t,
    name,
    defaultValue,
    options = {},
    onFilterChange
}) => {
    const [value, setValue] = useState(defaultValue)

    useEffect(() => {
        if (value !== null) {
            onFilterChange &&
                onFilterChange({
                    filterName: name,
                    value: value
                })
        }
    }, [value])

    const placeholder = getOption('placeholder', options)

    return (
        <div className='filter-items-container'>
            <div className="filter-item">
                <InputGroup>
                    <Form.Control
                        placeholder={`${t(placeholder)}...`}
                        aria-describedby={`search_input__${name}`}
                        style={{
                            borderRight: 'none',
                            fontWeight: 700,
                            color: '#4F4F4F'
                        }}
                        onChange={(e) => {
                            setValue(e.target.value)
                        }} 
                        defaultValue={defaultValue}
                    />

                    <InputGroup.Text
                        className='bg-transparent border-left-0'
                        id={`search_input__${name}`}
                        style={{ borderLeft: 'none' }}
                    >
                        <i className='ri-search-line' />
                    </InputGroup.Text>
                </InputGroup>
            </div>
        </div>
    )
})

export { SearchTextBox }
