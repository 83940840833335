import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import { withNamespaces } from "react-i18next";
import { Card, CardBody, CardTitle } from "reactstrap";

class DamageAnalytics extends Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [],
      options: {},
      isCM: localStorage.getItem("module") === "cm",
    };
  }

  render() {
    const { isCM } = this.state;
    return (
      <>
        <Card>
          <CardBody>
            <div className="mb-3">
              <CardTitle>
                {isCM
                  ? this.props.t("Amount of complaints")
                  : this.props.t("Amount of cases")}
              </CardTitle>
            </div>
            <ReactApexChart
              options={this.state.options}
              series={this.state.series}
              type="line"
              height="380"
            />
          </CardBody>
        </Card>
      </>
    );
  }

  componentDidUpdate(prevProps) {
    // console.log(this.state.series)
    if (prevProps.data !== this.props.data || prevProps.t!== this.props.t) {
      const series = [];
      const newCategories = [];
      const data = this.props.data;

      for (const item in data) {
        let newData = [];

        for (const i in data[item]) {
          newData.push(parseInt(data[item][i]));
          if (item === "all") {
            newCategories.push(i);
          }
        }
        // console.log(item)
        series.push({ name: this.props.t(item), data: newData });
      }

      // console.log("newCategories: ", newCategories);

      this.setState({
        series: series,
        options: {
          chart: { zoom: { enabled: !1 }, toolbar: { show: !1 } },
          colors: ["#556ee6", "#fcb92c", "#34c38f"],
          dataLabels: { enabled: !1 },
          stroke: { width: [3, 4, 3], curve: "straight", dashArray: [0, 8, 5] },
          title: {
            text: "",
            align: "left",
            style: { fontWeight: "normal", fill: "#373d3f", opacity: 1 },
          },
          markers: { size: 0, hover: { sizeOffset: 6 } },
          xaxis: { categories: newCategories },
          tooltip: {
            y: [
              {
                title: {
                  formatter: function (e) {
                    return e;
                  },
                },
              },
              {
                title: {
                  formatter: function (e) {
                    return e;
                  },
                },
              },
              {
                title: {
                  formatter: function (e) {
                    return e;
                  },
                },
              },
            ],
          },
          grid: { borderColor: "#f1f1f1" },
        },
      });
    }
  }
}

export default withNamespaces()(DamageAnalytics);
