import { withNamespaces } from "react-i18next";
import { Text, View } from "@react-pdf/renderer";
import HandIcon from "../../components/HandIcon";
import HTMLContent from "../../components/HTMLContent";
import styles from "../../Styles";

const Findings = ({
    t,
    news
}) => {
    return (
        <View>
            <View style={styles.sectionSeparator}>
                <Text>{t('FINDINGS')}</Text>
            </View>

            <View style={styles.note}>
                <HandIcon />
                <Text style={styles.ml1}>
                    {t('Please note that Open Source Findings are displayed here.')}
                </Text>
            </View>

            <View style={styles.section} break>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColHeader}>
                            <Text style={{
                                ...styles.tableCell,
                                ...styles.fontBold
                            }}>{t('Source')}</Text>
                        </View>
                        <View style={{
                            ...styles.tableColHeader,
                            ...styles.borderRightNone
                        }}>
                            <Text style={{
                                ...styles.tableCell,
                                ...styles.fontBold
                            }}>{t('Description')}</Text>
                        </View>
                        <View style={{
                            ...styles.tableColHeader,
                            ...styles.borderRightNone
                        }}>
                            <Text style={{
                                ...styles.tableCell,
                                ...styles.fontBold
                            }}>{t('Source Scoring')}</Text>
                        </View>
                        <View style={{
                            ...styles.tableColHeader,
                            ...styles.borderRightNone
                        }}>
                            <Text style={{
                                ...styles.tableCell,
                                ...styles.fontBold
                            }}>{t('Relevant')} (Y/N/Unknown)</Text>
                        </View>
                    </View>

                    {news.filter((finding) => finding.flag).map((finding, i) => {
                        const rowStyle = {
                            ...styles.tableRow
                        }

                        const relevancyScore = parseInt(finding?.relevancyScore || 0);

                        return (
                            <View style={rowStyle} key={i}> 
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        {finding.news_agency}
                                    </Text>
                                </View>

                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        <HTMLContent content={finding.body} />
                                    </Text>
                                </View>

                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                    </Text>
                                </View>

                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        {relevancyScore < 40 ? 'N' : (relevancyScore <= 69 ? 'Unknown' : 'Y')}
                                    </Text>
                                </View>
                            </View>
                        )
                    })}
                </View>
            </View>
        </View>
    )
};

export default withNamespaces()(Findings);

