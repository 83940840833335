import { useState, memo } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Collapse, Label } from 'reactstrap';
import { Link } from 'react-router-dom';

const ReportContents = function (props) {
	ReportContents.displayName = 'Memorized Component - Report Contents';
	if (process.env.NODE_ENV === 'development') console.log('---- rendering memorized component >>> ReportContents');
	const [isOpen, setIsOpen] = useState(true);

	return (
		<Row>
			<Col sm='12'>
				<Card className='mb-1 shadow-none'>
					{/* header */}
					<Link
						to='#'
						onClick={() => {
							setIsOpen(!isOpen);
						}}
						style={{ cursor: 'pointer' }}
						className='text-dark'>
						<CardHeader id='headingOne' className='bg-primary'>
							<h6 className='m-0 text-white font-14'>
								{props.t('Contents')}
								<i className={`float-end accor-plus-icon mdi ${isOpen ? 'mdi-minus' : 'mdi-plus'}`}></i>
							</h6>
						</CardHeader>
					</Link>

					<Collapse isOpen={isOpen}>
						<CardBody style={{ paddingLeft: '2px' }}>
							{props.questions &&
								props.questions.map((question, index) => {
										const title = question.title || '';
										const answer = question.answers;

										return (
											<Row key={index}>
												<Col sm='12' lg='12'>
													<div className='mb-3'>
														{/* title */}
														<Label className='form-check-label mb-1'>{props.t(title)}</Label>

														{/* description */}
														<div
															className='text-answers'
															style={{
																margin: 0,
															}}>
															{typeof answer === 'object' ? (
																<ul
																	style={{
																		padding: 0,
																		marginBottom: -6,
																		listStyle: 'none',
																	}}>
																	{answer.length
																		? answer.map((item, index) => {
																				const value = typeof item === 'object' ? item.title || item.date : item;
																				const sub =
																					typeof value === 'array' ? value.title || value.date : typeof item.answer === 'object' ? item.answer[0].date : '';

																				return (
																					<li
																						key={index}
																						style={{
																							marginBottom: 6,
																						}}>
																						<div sm='12' md='6'>
																							<p
																								className='text-dark'
																								style={{
																									marginBottom: 0,
																								}}>
																								<i className='fa fa-check'></i>
																								{' ' + props.t(value)}
																								<span
																									style={{
																										display: 'block',
																										paddingLeft: '20px',
																										margin: 0,
																									}}>
																									{sub && sub}
																								</span>
																							</p>
																						</div>
																					</li>
																				);
																		  })
																		: null}
																</ul>
															) : (
																<span>{props.t(answer)}</span>
															)}
														</div>
													</div>
												</Col>
											</Row>
										);
									})}
						</CardBody>
					</Collapse>
				</Card>
			</Col>
		</Row>
	);
};

export default memo(ReportContents);