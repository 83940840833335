import React, { Component } from "react";
// import apexChart
// import LineApexChart from "../AllCharts/apex/chartapex";
// import DashedLine from "../AllCharts/apex/dashedLine";
// import SplineArea from "../AllCharts/apex/SplineArea";
// import Apaexlinecolumn from "../AllCharts/apex/apaexlinecolumn";
// import ColumnWithDataLabels from "../AllCharts/apex/ColumnWithDataLabels";
// import BarChart from "../AllCharts/apex/barchart";
// import LineColumnArea from "../AllCharts/apex/LineColumnArea";
// import RadialChart from "../AllCharts/apex/RadialChart";
// import PieChart from "../AllCharts/apex/PieChart";
// import DonutChart from "../AllCharts/apex/dountchart";
import areachart from "./Charts/AreaChart";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Tooltip,
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import AreaChart from "./Charts/AreaChart";
import { withNamespaces } from "react-i18next";

class MultipleStatistics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Charts", link: "#" },
        { title: "Apex charts", link: "#" },
      ],
      taskStatsTooltip: false,
      categories: [],
      series: [],
    };
  }

  // componentDidMount() {
  //   console.log("PROPS", this.props);
  // }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data || prevProps.t !== this.props.t) {
      const series = [];
      const newCategories = [];
      const data = this.props.data;
      for (const item in data) {
        let newData = [];

        for (const i in data[item]) {
          newData.push(parseInt(data[item][i]));
          if (item === "all") {
            newCategories.push(i);
          }
        }

        series.push({ name: this.props.t(item), data: newData });
      }

      this.setState({
        categories: newCategories,
        series: series,
      });
    }
  }

  render() {
    const { categories, series } = this.state;
    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <div className="mb-4">
              <CardTitle>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>{this.props.t("Task statistics")}</div>
                  {/* <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    id="infoIconCaseType"
                  />
                  <Tooltip
                    placement="top"
                    isOpen={this.state.taskStatsTooltip}
                    target="taskStatas"
                    toggle={() =>
                      this.setState({
                        taskStatsTooltip: !this.state.taskStatsTooltip,
                      })
                    }
                  >
                    {this.props.t(
                      "This chart observes the number of reports that have been in meetings, anonymous and confidential"
                    )}
                  </Tooltip> */}
                </div>
              </CardTitle>
              <CardBody>
                {series.length > 0 && categories.length > 0 && (
                  <>
                    <AreaChart categories={categories} series={series} />
                  </>
                )}
              </CardBody>
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default withNamespaces()(MultipleStatistics);
