import i18next from "i18next";
import PhoneInput from "react-phone-input-2";

// PhoneInput style
import "react-phone-input-2/lib/style.css";
import { getCurrentLanguage } from "../../i18n";

function InputPhoneNumber(props) {
  const {
    name,
    value,
    style,
    country,
    required,
    validate,
    onChange,
    placeholder,
    errorMessage,
  } = props;
  const language = getCurrentLanguage();

  let inputProps = {};
  let selectedLanguage = language === "en" ? "us" : language;
  inputProps.country = country ? country : "us";

  // if (!value) {
  // let selectedLanguage = language === "en" ? "us" : language;
  // inputProps.country = country || selectedLanguage;
  // }
  return (
    <div className="sip-phone-input" style={style}>
      <PhoneInput
        {...inputProps}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        disableInitialCountryGuess={false}
        country={inputProps.country}
        disableCountryGuess={false}
        required={required}
      />
      {required && (
        <div className="invalid-feedback">
          {errorMessage || i18next.t("This field cannot be blank")}
        </div>
      )}
    </div>
  );
}

export default InputPhoneNumber;
