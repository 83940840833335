import { withNamespaces } from "react-i18next";
import { CardBody } from "reactstrap";

const ColumnHeader = ({
    t,
    title,
    subtitle
}) => {
    return (
        <CardBody>
            <h4 className='card-title'>{ t(title) }</h4>
            <p className='mb-1'>{`${t(subtitle)} ${t('Cards')}`}</p>
        </CardBody>
    );
};

export default withNamespaces()(
    ColumnHeader
);