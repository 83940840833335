import { memo } from "react";
import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";

const DeletionFileModal = function (props) {
  DeletionFileModal.displayName = "Memorized Component - Deletion File Modal";
  if (process?.env?.NODE_ENV === "development")
    console.log("---- rendering memorized component >>> DeletionFileModal");

  const { t, isOpen, acceptDeletion, close, fileId, isLoading } = props;

  return (
    <>
      <Modal size="lg" scrollable={true} isOpen={isOpen} backdrop="static">
        {/* header */}
        <ModalHeader toggle={() => close()}>{t("Delete file")}</ModalHeader>

        {/* body */}
        <ModalBody>
          <AvForm className="needs-validation">
            <Row>
              <Label>{t("Are you sure?")}</Label>
            </Row>

            <ModalFooter>
              <Button
                color="danger"
                className="waves-effect waves-light"
                type="submit"
                disabled={isLoading}
                onClick={() => {
                  acceptDeletion(fileId);
                }}
              >
                {t("Delete").toUpperCase()}
              </Button>
              <Button
                color="secondary"
                className="waves-effect waves-light"
                type="button"
                onClick={() => {
                  close();
                }}
              >
                {t("Cancel").toUpperCase()}
              </Button>
            </ModalFooter>
          </AvForm>
        </ModalBody>
      </Modal>
    </>
  );
};

export default memo(DeletionFileModal);
