import {
  HiChevronDoubleDown,
  HiChevronDoubleUp,
  HiMenuAlt4,
} from "react-icons/hi";

export const taskStatuses = [
  { value: "NEW", label: "New" },
  { value: "IN_PROGRESS", label: "In Progress" },
  { value: "REVIEW", label: "Review" },
  { value: "CLOSE", label: "Closed" },
  { value: "CLOSED", label: "Closed" },
];

export const priority = [
  {
    value: "low",
    label: (
      <>
        <HiChevronDoubleDown className="text-info" />
        <span>Low</span>
      </>
    ),
  },
  {
    value: "medium",
    label: (
      <>
        <HiMenuAlt4 className="text-success" />
        <span>Medium</span>
      </>
    ),
  },
  {
    value: "high",
    label: (
      <>
        <HiChevronDoubleUp className="text-danger" />
        <span>High</span>
      </>
    ),
  },
];
