import React, { useEffect, useState, useCallback } from "react";
import { withNamespaces } from "react-i18next";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import Select, { components } from "react-select";
import SupplierFindingsManageKeyWordsServices from "src/modules/3rd-party-management/apis/SupplierFindingsManageKeyWordsServices";

const ManageKeywordsModal = ({ t, modal, setModal, toggle }) => {
  const [tags, setTags] = useState([]);
  const [userTags, setUserTags] = useState([]);
  const [newTagInput, setNewTagInput] = useState("");

  // Fetch All tags
  const listOfTags = useQuery({
    queryKey: ["3rd-party-management-get-findings-all-tags-query"],
    queryFn: async () => {
      const service = SupplierFindingsManageKeyWordsServices.getInstance();
      const response = await service.allListOfTags();
      return response || [];
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError() {
      toast(t("error while fetching keywords"), {
        type: "error",
      });
    },
  });

  // Fetch tags
  const listOfUserTags = useQuery({
    queryKey: ["3rd-party-management-get-findings-user-tags-query"],
    queryFn: async () => {
      const service = SupplierFindingsManageKeyWordsServices.getInstance();
      const response = await service.listOfTags();
      return response || [];
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError() {
      toast(t("error while fetching keywords"), {
        type: "error",
      });
    },
  });

  // Edit Tags
  const editTags = useMutation({
    mutationFn: async (payload) => {
      const tags = payload.map((item) => item.id.toString());
      const service = SupplierFindingsManageKeyWordsServices.getInstance();
      return await service.updateTags(tags);
    },
    onSuccess: () => {
      setModal(false);
      toast(t("Keywords list updated successfully."), {
        type: "success",
      });
    },
    onError: () => {
      toast(t("An error occurred while updating the keywords list."), {
        type: "error",
      });
    },
  });

  // Remove a tag
  const removeTag = (index) => {
    setUserTags((prevTags) => {
      const updatedTags = [...prevTags];
      updatedTags.splice(index, 1); // Remove the tag at the specified index
      return updatedTags;
    });
  };

  // Add a new tag
  const addNewTag = useCallback(
    (selected) => {
      const newTag = selected.trim().toLowerCase();
      if (
        newTag &&
        !userTags.some((tag) => tag.title.toLowerCase() === newTag)
      ) {
        const existingTag = tags.find(
          (tag) => tag.title.toLowerCase() === newTag
        );
        if (existingTag) {
          setUserTags((prevTags) => [...prevTags, existingTag]);
          setNewTagInput(""); // Clear the input field
        }
      }
    },
    [tags, userTags]
  );

  // Generate all suggestion
  useEffect(() => {
    if (!listOfTags.isLoading) {
      setTags(listOfTags.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listOfTags.data]);

  // Generate suggestions filter and user selected
  useEffect(() => {
    if (!listOfUserTags.isLoading) {
      setUserTags(listOfUserTags.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listOfUserTags.data]);

  const handleOnSelect = (e) => {
    setNewTagInput(e.value);
    addNewTag(e.value);
  };

//   console.log(userTags.length)

  return (
    <Modal isOpen={modal} toggle={toggle} className="keyboard-modal-main-content">
      <ModalHeader toggle={toggle}>{t("Manage your keywords")}</ModalHeader>
      <ModalBody>
        <div>
          {/* Add New Tag */}
          <Select
            value={newTagInput}
            options={
              tags.length === userTags.length
                ? [{ value: "", label: t("No more keywords available") }]
                : tags
                    .filter(
                      (tag) =>
                        !userTags.some((userTag) => userTag.title === tag.title)
                    )
                    .map((tag) => ({ value: tag.title, label: tag.title }))
            }
            onChange={(e) => handleOnSelect(e)}
            noOptionsMessage={({ inputValue }) => t("No keyword found")}
            placeholder={t("Enter new keyword")}
            isClearable
            isSearchable
            className="selected-input"
            components={{
              Control: ({ children, ...rest }) => (
                <components.Control {...rest}>{children}</components.Control>
              ),
            }}
          />
          {/* Text Area */}
          <div
            className="manage-keywords-container"
            id="keywords"
            name="keywords"
          >
            {userTags.length &&
              userTags.map((tag, index) => (
                <div className="tag" key={index}>
                  {t(tag.title)}
                  <span className="tag-close" onClick={() => removeTag(index)}>
                    &times;
                  </span>
                </div>
              ))}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          size="sm"
          onClick={() => editTags.mutate(userTags)}
        >
          {t("Save")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default withNamespaces()(ManageKeywordsModal);
