import { useState, memo, useEffect, Fragment } from 'react';
import {
	Row,
	Col,
	Label,
	Dropdown,
	DropdownMenu,
	DropdownItem,
	DropdownToggle,
	Collapse,
	Alert,
	ListGroup,
	ListGroupItem,
	Badge,
	Input,
	Button,
	Tooltip,
} from 'reactstrap';
import { FaRegEye } from 'react-icons/fa';
import { pdf } from '@react-pdf/renderer';
import {
	INTERNATIONAL_DATE_FORMAT,
	EMPTY_LIST,
	TASK_FILE_DELETED_SUCCESSFULLY,
	TASK_FILE_NOT_DELETED_SUCCESSFULLY,

    API_DOWNLOAD_VOICE_FILES
} from '../../../../../../common/constants';

import  {
    API_URL_REPORT_DOWNLOAD_USER_ATTACHMENTS,
	API_URL_REPORT_DOWNLOAD_ANALYST_ATTACHMENTS,
	API_URL_DELETE_VOICE_BY_ANALYST_ADMIN,
} from '../../../../constants';

import { REPORT_STATUS_LIST, ANALYST_REPORT_STATUS_LIST, REPORT_RISK_RATE } from '../../../../../../components/constants';
// TODO: Review after migration
// import { ReportDetailPDF } from '../../../../components/documents';
// import { ReportLogsPDF } from '../../../../components/documents';
import { saveAs } from 'file-saver';
import { map } from 'rxjs/operators';
import Select from 'react-select';
import AnonymouslyIcon from '../../../../../../assets/images/report_anonymously.svg';
import ConfidentialyIcon from '../../../../../../assets/images/report_confidently.svg';
import OtherRequestsIcon from "src/modules/data-protection/assets/images/OtherRequests.png";
import MeetingIcon from '../../../../../../assets/images/arrange_meeting.svg';
import VoiceIcon from '../../../../../../assets/images/microphone.svg';
import DateUtils from '../../../../../../services/utils/DateUtils';
import Request from '../../../../../../services/Request';
import axios from 'axios';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import DeleteConfirmationModal from '../../CommonForTaskAndRisk/DeleteConfirmationModal';

const ReportFields = function (props) {
	ReportFields.displayName = 'Memorized Component - Report Fields';
	if (process.env.NODE_ENV === 'development') console.log('---- rendering memorized component >>> ReportFields');

  const [descriptionTooltip, setDescriptionTooltip] = useState(false);
	const dateUtils = new DateUtils();
	const [adminDescription, setAdminDescription] = useState('');
	const [adminCategories, setAdminCategories] = useState([]);
	const [expandedCategories, setExpandedCategories] = useState(false);
	const [needToSaveAdminDescription, setNeedToSaveAdminDescription] = useState(false);
	const [selectedAdminCategories, setSelectedAdminCategories] = useState(null);
	const [needToSaveSelectedAdminCategory, setNeedToSaveSelectedAdminCategory] = useState(false);


	const [reportType, setReportType] = useState(null);
	const [selectedReadOnlyAnalyst, setSelectedReadOnlyAnalyst] = useState(null);
	const [openDownloadDropdown, setOpenDownloadDropdown] = useState(false);
	const [downloadAttachmentsType, setDownloadAttachmentsType] = useState('case');
	const [description, setDescription] = useState('');
	const [descTooltipOpen, setDescTooltipOpen] = useState(false);
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [deletionModalClosed, setDeletionModalClosed] = useState(false);
	const [selectedFileIdForDeletion, setSelectedFileIdForDeletion] = useState(null);

	const [readOnlyListIsOpen, setReadOnlyListIsOpen] = useState(false);

	const request = new Request();
	/**
	 * this method format and render a date.
	 * @param {String} title a title to show un top-of date.
	 * @param {String} date timestamp
	 * @param {String} format a pattern to format date.
	 * @returns
	 */
	const handleGetFormatedUpdateDate = (title, date, format) => {
		return (
			<small className='text-muted'>
				{title}
				<span className='text-info'>{dateUtils.convertTimeStampToDate(date, format)}</span>
			</small>
		);
	};

	/**
	 * this method renders the status of the case.
	 * @param {Object} status the status of the case you want to render status for.
	 * @param {Boolean} isDeleted the deletion status of the case.
	 * @param {String} caseId the ID of the case you want to render report status for.
	 * @param {Object} reportAnalyst the current analyst of the report.
	 * @param {String} token authorization token.
	 * @returns DOM nodes.
	 */
	const renderReportStatus = (status, isDeleted, caseId, reportAnalyst, token) => {
		const currentStatus = REPORT_STATUS_LIST.find((i) => i.id === status);

		if (currentStatus) {
			status = {
				value: status,
				label: props.t(currentStatus.titleKey),
			};
		}

		let statuses = null;
		if (props.isAnalystAdmin) {
			statuses = REPORT_STATUS_LIST.map((item) => {
				return {
					value: item.id,
					label: props.t(item.titleKey),
				};
			});
		} else if (props.isAnalystOrAnalystAdmin) {
			statuses = ANALYST_REPORT_STATUS_LIST.map((item) => {
				return {
					value: item.id,
					label: props.t(item.titleKey),
				};
			});
		}

		return (
			<Col sm='12' md='6'>
				<div className='mb-3'>
					<Label className='form-label text-dark'>{props.t('Status')}:</Label>
					{!props.reportLoading ? (
						props.isAnalystOrAnalystAdmin ? (
							<>
								<Select
									placeholder={props.t('Select')}
									classNamePrefix='select2-selection'
									options={statuses}
									title={props.t('Status')}
									value={status}
									onChange={(newStatus) => props.handleChangeStatus(newStatus.value, status.value, caseId, reportAnalyst, token)}
									isDisabled={props.isReadOnlyAnalyst || props.report?.deleted_by_name || isDeleted}
								/>
								{handleGetFormatedUpdateDate(`${props.t('Status Updated')} `, props.statusUpdatingDate, INTERNATIONAL_DATE_FORMAT)}
							</>
						) : (
							// non-analyst member
							<>
								<div className={currentStatus.className}>{props.t(status.label)}</div>
								<br />
								{handleGetFormatedUpdateDate(`${props.t('Status Updated')} `, props.statusUpdatingDate, INTERNATIONAL_DATE_FORMAT)}
							</>
						)
					) : (
						// skeleton
						<>
							<p className={`dt-field dt-skeleton ${props.isAnalystOrAnalystAdmin ? 'dt-select-list' : ''}`}></p>
							<p className='dt-field dt-skeleton dt-update-date'></p>
						</>
					)}
				</div>
			</Col>
		);
	};

	/**
	 * this method renders the risk rate for the case.
	 * @param {Object} risk the risk of the case.
	 * @param {String} riskRate current risk rate of the case.
	 * @param {Boolean} isDeleted the deletion status of the case.
	 * @param {Boolean} hasRiskAssessmentFeature the status of the risk assessment feature of the current organization.
	 * @returns DOM nodes.
	 */
	const renderRiskRate = (risk, riskRate, isDeleted, hasRiskAssessmentFeature, token) => {
		// return nothing for deleted case
		if (isDeleted) return;

		if (props.isAnalystOrAnalystAdmin) {
			const translatedRiskRates = REPORT_RISK_RATE.map((i) => {
				return {
					value: i.value,
					baseLabel: i.label,
					label: props.t(i.label),
				};
			});

			if (!hasRiskAssessmentFeature) {
				riskRate = translatedRiskRates.find((i) => i.value === riskRate);
			}

			return (
				<Col sm='12' md='6'>
					<div className='mb-3'>
						<Label className='form-label text-dark'>{`${props.t('Risk')}: `}</Label>
						{!props.reportLoading && !props.riskIsLoading ? (
							hasRiskAssessmentFeature ? (
								<p style={{ color: `#${risk.color}`, lineHeight: '38px' }}>{props.t(risk.name)}</p>
							) : (
								<Select
									id='risk-rate-select'
									classNamePrefix='select2-selection'
									options={translatedRiskRates}
									value={riskRate}
									onChange={(e) => {
										props.handleChangeRiskRate(props.id, e.value, token);
									}}
									title={`${props.t('Risk')}: `}
									isDisabled={props.isReadOnlyAnalyst || isDeleted}
									placeholder={props.t('Select')}
								/>
							)
						) : (
							<p className={`dt-field dt-skeleton ${!hasRiskAssessmentFeature ? 'dt-select-list' : ''}`}></p>
						)}
					</div>
				</Col>
			);
		}
		return null;
	};

	/**
	 * this method updates read-only analysts list.
	 * @param {Object[]} analyst the current analyst of the cas.
	 * @param {Object[]} readOnlyAnalysts the list of read-only analysts of the case.
	 * @param {"add"|"remove"} operation add or remove read-only analyst.
	 * @param {String} token authorization token.
	 */
	const updateReadOnlyAnalysts = async (analyst, readOnlyAnalysts, operation, token) => {
		let analysts = readOnlyAnalysts.map((analyst) => {
			return analyst.toString();
		});

		// eslint-disable-next-line default-case
		switch (operation) {
			case 'add': {
				setSelectedReadOnlyAnalyst(analyst);
				analysts.push(analyst.value.toString());
				break;
			}
			case 'remove': {
				const index = readOnlyAnalysts.findIndex((i) => i === analyst.value);
				if (index > -1) analysts.splice(index, 1);
				break;
			}
		}

		await props.handleChangeReadOnlyAnalysts(analysts, token);
		setSelectedReadOnlyAnalyst(null);
	};

	const renderReadOnlyAnalysts = (analysts, readOnlyAnalysts, token) => {
		const filteredReadOnlyAnalysts = analysts.filter((i) => readOnlyAnalysts.includes(i.value));
		analysts = analysts.filter((i) => !filteredReadOnlyAnalysts.map((i) => i.value).includes(i.value));
		if (props.assignedAnalyst) {
			analysts = analysts.filter((a) => a.value !== props.assignedAnalyst.value);
		}

		return (
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<div
					style={{
						borderRadius: '6px 6px 0 0',
						padding: '5px 10px',
						lineHeight: '35px',
						color: '#ffffff',
						fontWeight: 'bold',
					}}
					className='bg-primary'
				>
					{props.t('Read-Only permission')}
				</div>
				<div
					style={{
						border: '1px solid #dadada',
						borderTop: 'none',
						padding: '10px',
						borderRadius: '0 0 6px 6px',
					}}
				>
					{filteredReadOnlyAnalysts && filteredReadOnlyAnalysts.length > 0 ? (
						<ListGroup type='inline'>
							{filteredReadOnlyAnalysts &&
								filteredReadOnlyAnalysts.map((item) => (
									<ListGroupItem key={item.value}>
										<span
											className='d-inline-flex align-items-center justify-content-center avatar-xs rounded-circle bg-primary'
											style={{
												width: 24,
												height: 24,
												marginRight: 9,
												fontSize: 11,
												color: '#fff',
												paddingTop: 3,
											}}
										>
											{item && item.label ? item.label[0].toUpperCase() : null}
										</span>{' '}
										{item && item.label ? item.label : null}{' '}
										{props.isReadOnlyAnalyst ? null : (
											<Badge
												className='text-dark pt-2'
												color='light'
												style={{
													float: 'right',
													cursor: 'pointer',
												}}
												onClick={(e) => updateReadOnlyAnalysts(item, readOnlyAnalysts, 'remove', token)}
												// isDisabled={props.report?.deleted_by_name || props.report?.is_deleted_content}
											>
												X
											</Badge>
										)}
									</ListGroupItem>
								))}
						</ListGroup>
					) : (
						<p>{props.t(EMPTY_LIST)}</p>
					)}

					{/* analysts list */}
					<div className='mt-2'>
						<Select
							placeholder={props.t('Select')}
							options={analysts}
							value={selectedReadOnlyAnalyst}
							onChange={(analyst) => updateReadOnlyAnalysts(analyst, readOnlyAnalysts, 'add', token)}
							isDisabled={props.isReadOnlyAnalyst || props.report?.deleted_by_name || props.report?.is_deleted_content}
						/>
					</div>
				</div>
			</div>
		);
	};

	/**
	 * this method provides a zip file and prepare download link.
	 * @param {String} caseId the ID of the case you want to download attachments.
	 * @param {String} type the type of download.
	 */
	const downloadAttachments = async (caseId, type) => {
		const url = type === 'case' ? API_URL_REPORT_DOWNLOAD_USER_ATTACHMENTS : API_URL_REPORT_DOWNLOAD_ANALYST_ATTACHMENTS;
		request
			.downloadZip(
				url,
				'POST',
				{
					report_case_id: caseId,
				},
				props.authToken,
				{
					responseType: 'arraybuffer',
				},
			)
			.pipe(
				map((response) => {
					return response;
				}),
			)
			.subscribe((response) => {});
	};

	/**
	 * this method provides a zip file and prepare download link.
	 * @param {String} caseId the ID of the case you want to download attachments.
	 * @param {String} type the type of download.
	 */
	const downloadVoiceFiles = async (caseId, type) => {
		console.log('Download', caseId);
		const url = API_DOWNLOAD_VOICE_FILES;
		// type === "case"
		//   ? API_URL_REPORT_DOWNLOAD_USER_ATTACHMENTS
		//   : API_URL_REPORT_DOWNLOAD_ANALYST_ATTACHMENTS;
		request
			.downloadZip(
				url,
				'POST',
				{
					reportCaseId: caseId,
				},
				props.authToken,
				{
					responseType: 'arraybuffer',
				},
			)
			.pipe(
				map((response) => {
					return response;
				}),
			)
			.subscribe((response) => {});
	};

	/**
	 * this method renders the box of the attachments of the report.
	 * @param {Object[]} attachments attachments list
	 * @returns DOM nodes.
	 */
	const renderAttachments = (attachments, voices = []) => {
		return (
			<Fragment>
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<div
						style={{
							borderRadius: '6px 6px 0 0',
							padding: '5px 10px',
							lineHeight: '35px',
							color: '#ffffff',
							fontWeight: 'bold',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
						}}
						className='bg-primary'
					>
						{props.t('All attachments')}

						<div
							style={{
								display: 'flex',
								flexDirection: 'row-reverse',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							{/* download button */}
							{attachments && attachments.length > 0 && !props.isThirdParty ? (
								<span
									onClick={(e) => downloadAttachments(props.id, downloadAttachmentsType)}
									style={{
										width: '30px',
										height: '30px',
										background: '#ffffff',
										marginLeft: '10px',
										borderRadius: '0.25rem',
										color: '#505d69',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										border: '1px solid #ced4da',
										cursor: 'pointer',
									}}
									className='float-end'
								>
									<i className='fa fa-download'></i>
								</span>
							) : null}

							{/* download type select list */}
							{attachments && attachments.length > 0 && !props.isThirdParty && !props.isEmployee ? (
								<select
									onChange={(e) => {
										setDownloadAttachmentsType(e.target.value);
									}}
									className='form-control d-inline float-end'
									style={{
										width: 'auto',
										height: '30px',
										lineHeight: '1',
										padding: '6px',
										cursor: 'pointer',
									}}
									placeholder={props.t('Select')}
								>
									<option value='case'>{props.t('Case')}</option>
									<option value='internal' hidden={!props.isAnalystOrAnalystAdmin}>
										{props.t('Internal')}
									</option>
								</select>
							) : null}
						</div>
					</div>
					<div
						style={{
							border: '1px solid #dadada',
							borderTop: 'none',
							padding: '10px',
							borderRadius: '0 0 6px 6px',
						}}
					>
						<div
							style={{
								maxHeight: '200px',
								overflow: 'auto',
							}}
						>
							{renderAttachmentsList(attachments)}
						</div>
					</div>
				</div>
				<br />
				{props.type === 4 ? (
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<div
							style={{
								borderRadius: '6px 6px 0 0',
								padding: '5px 10px',
								lineHeight: '35px',
								color: '#ffffff',
								fontWeight: 'bold',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
							}}
							className='bg-primary'
						>
							{props.t('Voice files')}

							<div
								style={{
									display: 'flex',
									flexDirection: 'row-reverse',
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								{/* download button */}
								{voices && voices.length > 0 && !props.isThirdParty ? (
									<span
										onClick={(e) => downloadVoiceFiles(props.report.case_id, downloadAttachmentsType)}
										style={{
											width: '30px',
											height: '30px',
											background: '#ffffff',
											marginLeft: '10px',
											borderRadius: '0.25rem',
											color: '#505d69',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											border: '1px solid #ced4da',
											cursor: 'pointer',
										}}
										className='float-end'
									>
										<i className='fa fa-download'></i>
									</span>
								) : null}
							</div>
						</div>
						<div
							style={{
								border: '1px solid #dadada',
								borderTop: 'none',
								padding: '10px',
								borderRadius: '0 0 6px 6px',
							}}
						>
							<div
								style={{
									maxHeight: '200px',
									overflow: 'auto',
								}}
							>
								{renderVoicesList(voices)}
							</div>
						</div>
					</div>
				) : null}
			</Fragment>
		);
	};

	/**
	 * this method renders the attachments of the report.
	 * @param {Object[]} attachments attachments list.
	 * @returns DOM nodes.
	 */
	const renderAttachmentsList = (attachments) => {
		if (attachments.length === 0) {
			return <p>{props.t(EMPTY_LIST)}</p>;
		} else {
			if (downloadAttachmentsType === 'case') {
				return (
					<ul
						className='list-unstyled categories-list mb-0'
						style={{
							fontSize: '12px',
							display: 'flex',
							flexDirection: 'column',
							gap: '8px',
						}}
					>
						{attachments.map((attachment) => {
							if (!attachment.is_internal)
								return (
									<li key={attachment.id}>
										{/* <i className="mdi mdi-circle-medium me-1"></i>  */}
										<span
											className='bg-warning me-1 badge badge-secondary'
											style={{
												display: 'inline-block',
												lineHeight: '20px',
												padding: '0 10px 1px 10px',
												fontSize: '10px',
												fontWeight: 'bold',
											}}
										>
											{attachment.type}
										</span>
										{attachment.name}
									</li>
								);
							return null;
						})}
					</ul>
				);
			}
			return (
				<ul
					className='list-unstyled categories-list mb-0'
					style={{
						fontSize: '12px',
						display: 'flex',
						flexDirection: 'column',
						gap: '8px',
					}}
				>
					{attachments.map((attachment) => {
						if (attachment.is_internal)
							return (
								<li key={attachment.id}>
									<span
										className='bg-warning me-1 badge badge-secondary'
										style={{
											display: 'inline-block',
											lineHeight: '20px',
											padding: '0 10px 1px 10px',
											fontSize: '10px',
											fontWeight: 'bold',
										}}
									>
										{props.t(attachment.type)}
									</span>
									{attachment.name}
								</li>
							);
						return null;
					})}
				</ul>
			);
		}
	};

	const openFileDeletionModal = (id) => {
		setSelectedFileIdForDeletion(id);
		setDeleteModalOpen(true);
	};

	const handleDeleteVoice = (id) => {
		const url = API_URL_DELETE_VOICE_BY_ANALYST_ADMIN;
		const headers = {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('LOCAL_STORAGE_KEY_ACCESS_TOKEN')}`,
			},
			data: {
				voiceId: id,
			},
		};
		const data = {
			voiceId: id,
		};
		const result = axios
			.delete(url, headers)
			.then((response) => {
				if (response.status === 200) {
					props.successNotification({
						message: props.t(TASK_FILE_DELETED_SUCCESSFULLY),
					});
					setDeleteModalOpen(false);
					return props.fetchReport();
				} else {
					setDeleteModalOpen(false);
					props.errorNotification({
						message: props.t(TASK_FILE_NOT_DELETED_SUCCESSFULLY),
					});
				}
			})
			.catch((err) => {
				setDeleteModalOpen(false);
				props.errorNotification({
					message: props.t(TASK_FILE_NOT_DELETED_SUCCESSFULLY),
				});
			});
		return;
	};

	/**
	 * this method renders the attachments of the report.
	 * @param {Object[]} attachments attachments list.
	 * @returns DOM nodes.
	 */
	const renderVoicesList = (attachments) => {
		if (attachments.length === 0) {
			return <p>{props.t(EMPTY_LIST)}</p>;
		} else {
			if (downloadAttachmentsType === 'case') {
				return (
					<>
						<ul
							className='list-unstyled categories-list mb-0'
							style={{
								fontSize: '12px',
								display: 'flex',
								flexDirection: 'column',
								gap: '8px',
							}}
						>
							{attachments.map((attachment, index) => {
								if (!attachment.is_internal)
									return (
										<li key={attachment.id} className='d-flex justify-content-between align-items-center'>
											<div>
												<span className='bg-warning me-1 badge badge-secondary'>{props.t('voice')}</span>
												{`${attachment.name}-v${index + 1}`}
											</div>
											<div hidden={props.isThirdParty || props.isEmployee}>
												<button onClick={() => openFileDeletionModal(attachment.id)} className='btn btn-link text-danger'>
													<i className='fa fa-times-circle'></i>
												</button>
											</div>
										</li>
									);

								return null;
							})}
						</ul>
					</>
				);
			}
			return (
				<ul
					className='list-unstyled categories-list mb-0'
					style={{
						fontSize: '12px',
						display: 'flex',
						flexDirection: 'column',
						gap: '8px',
					}}
				>
					{attachments.map((attachment) => {
						if (attachment.is_internal)
							return (
								<li key={attachment.id}>
									<span
										className='bg-warning me-1 badge badge-secondary'
										style={{
											display: 'inline-block',
											lineHeight: '20px',
											padding: '0 10px 1px 10px',
											fontSize: '10px',
											fontWeight: 'bold',
										}}
									>
										{props.t(attachment.type)}
									</span>
									{attachment.name}
								</li>
							);
						return null;
					})}
				</ul>
			);
		}
	};

	/**
	 * this methord exports whole data of the report.
	 * @param {String} name file name.
	 * @param {Object[]} internalComments the internal comments of the report.
	 * @param {String} organizationLogo organization logo url.
	 * @param {Object[]} organizationQuestions the questions of the organizations.
	 * @param {Object[]} organizationQuestionsCategories the organization questions categories.
	 * @param {Object} currentUser the authorized user.
	 * @param {Object} report the selected report
	 * @param {Object[]} comments the comments of the case.
	 */
	const handleExportInternal = async (
		name,
		internalComments,
		organizationLogo,
		organizationQuestions,
		organizationQuestionsCategories,
		currentUser,
		report,
		comments,
	) => {
		const fileName = `${name}.pdf`;

		// TODO: Review after migration
		// const blob = await pdf(
		// 	<ReportDetailPDF
		// 		logo={organizationLogo}
		// 		orgQuestions={organizationQuestions}
		// 		categories={organizationQuestionsCategories}
		// 		user={currentUser}
		// 		report={report}
		// 		comments={comments}
		// 		internalComments={internalComments}
		// 		downloadId={1}
		// 	/>,
		// ).toBlob();

		// saveAs(blob, fileName);
	};
  
	const handleUpdateAdminCategories = async (caseId, adminCategories, token) => {
		await props.handleUpdateAdminCategories(caseId, adminCategories, token);
		setSelectedAdminCategories(null);
	};

	/**
	 * this methord exports whole data of the report without internal comments.
	 * @param {String} name file name.
	 * @param {String} organizationLogo organization logo url.
	 * @param {Object[]} organizationQuestions the questions of the organizations.
	 * @param {Object[]} organizationQuestionsCategories the organization questions categories.
	 * @param {Object} currentUser the authorized user.
	 * @param {Object} report the selected report
	 * @param {Object[]} comments the comments of the case.
	 */
	const handleExportCase = async (name, organizationLogo, organizationQuestions, organizationQuestionsCategories, currentUser, report, comments) => {
		const fileName = `${name}.pdf`;

		// const blob = await pdf(
		// 	<ReportDetailPDF
		// 		logo={organizationLogo}
		// 		orgQuestions={organizationQuestions}
		// 		categories={organizationQuestionsCategories}
		// 		user={currentUser}
		// 		report={report}
		// 		comments={comments}
		// 		downloadId={2}
		// 	/>,
		// ).toBlob();

		const blob = null;

		saveAs(blob, fileName);
	};

	/**
	 * this method exports a pdf file that is existing logs.
	 * @param {String} name file name.
	 * @param {Object[]} logs logs
	 * @param {*} organizationLogo organization logo url
	 */
	const handleExportLogs = async (name, logs, organizationLogo) => {
		const fileName = `${name}.pdf`;
		// TODO: Review after migration
		// const blob = await pdf(<ReportLogsPDF data={logs} logo={organizationLogo} />).toBlob();

		// TODO: Review after migration
		// saveAs(blob, fileName);
	};

	useEffect(() => {
		// eslint-disable-next-line default-case
		switch (props.type?.toLowerCase()) {
			case "data breach incident": {
				setReportType({
					title: 'Data Breach Incident',
					src: AnonymouslyIcon,
				});
				break;
			}
			case "data subject request": {
				setReportType({
					title: 'Data Subject Request',
					src: OtherRequestsIcon,
				});
				break;
			}
			case "data deletion request": {
				setReportType({
					title: 'Data Deletion Request',
					src: ConfidentialyIcon,
				});
				break;
			}
			case "processing activity": {
				setReportType({
					title: 'Processing Activity',
					src: MeetingIcon,
				});
				break;
			}
		}
	}, [props.type]);

	useEffect(() => {
		if (props.adminDescription) {
			setAdminDescription(props.adminDescription);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.adminDescription, props.assignedAnalyst]);

	useEffect(() => {
		if (props.adminCategories && Array.isArray(props.adminCategories) && props.categories && Array.isArray(props.categories)) {
			const categories = props.categories
				.filter((c) => props.adminCategories.includes(c.id))
				.map((c) => {
					return {
						value: c.id,
						baseLabel: c.title,
						label: props.t(c.title),
					};
				});
			setAdminCategories(categories);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.adminCategories, props.categories]);

	useEffect(() => {
		if(props.isActive) document.title = 'Report Details | SIP';
	}, [props.isActive]);
  
	return (
		<>
			<Row>
				<Col sm='12' lg='7'>
					<Row>
						{/* type */}
						<Col sm='12' md='6'>
							<div className='mb-3'>
								<Label className='form-label text-dark'>{props.t('Type')}:</Label>
								{!props.reportLoading && reportType ? (
									<p className='dt-field'>
										<img
											style={{ width: '20px', height: '20px', marginRight: 9 }}
											alt={reportType['title'].toLowerCase()}
											className='rounded-circle card-img'
											src={reportType['src']}
										/>
										<span style={{ textTransform: 'capitalize' }}>{props.t(reportType['title']).toLowerCase()}</span>
									</p>
								) : (
									<p className='dt-field dt-skeleton'></p>
								)}
							</div>
						</Col>

						{/* channel */}
						<Col sm='12' md='6'>
							<div className='mb-3'>
								<Label className='form-label text-dark'>{props.t('Channel')}:</Label>
								{!props.reportLoading ? (
									<p className='dt-field'>
										<span className='badge bg-primary'>{props.t("web")}</span>
									</p>
								) : (
									<p className='dt-field dt-skeleton'></p>
								)}
							</div>
						</Col>
					</Row>

					<Row>
						{/* submission date */}
						<Col sm='12' md='6'>
							<div className='mb-3'>
								<Label className='form-label text-dark'>{props.t('Submission date')}:</Label>
								{!props.reportLoading ? (
									<p className='dt-field'>{dateUtils.convertTimeStampToDate(props.submissionDate, INTERNATIONAL_DATE_FORMAT)}</p>
								) : (
									<p className='dt-field dt-skeleton'></p>
								)}
							</div>
						</Col>

						{/* closing date */}
						<Col sm='12' md='6'>
							<div className='mb-3'>
								<Label className='form-label text-dark'>{props.t('Closing date')}:</Label>
								{!props.reportLoading ? (
									<p className='dt-field'>{dateUtils.convertTimeStampToDate(props.closingDate, INTERNATIONAL_DATE_FORMAT)}</p>
								) : (
									<p className='dt-field dt-skeleton'></p>
								)}
							</div>
						</Col>
					</Row>

					{/* admin description and admin category */}
					{props.report && props.isAnalystOrAnalystAdmin ? (
						<Row className='mb-3' hidden={props?.report?.is_reporter_analyst_only} >
							{/* admin description */}
							<Col sm='6' md='6' lg='6'>
								<AvForm>
									<Label className='form-label text-dark' style={{ display: 'flex', justifyContent: 'space-between' }}>
										<span>
											{props.t('Description by the admin')}:
											{!props.isDeleted && (props.isAnalystAdmin || props.assignedAnalyst?.value === props.currentUser?.id) ? (
												<span className='ms-2'>
													<i className='fa fa-info-circle' aria-hidden='true' id='descriptionTooltip' />
													<Tooltip
														placement='top'
														isOpen={descriptionTooltip}
														target='descriptionTooltip'
														toggle={() => setDescriptionTooltip(!descriptionTooltip)}
													>
														{props.t('Please do not use clear names in the description. The description will remain in the deletion protocol')}.
													</Tooltip>
												</span>
											) : null}
										</span>

										{!props.isDeleted && (props.isAnalystAdmin || props.assignedAnalyst?.value === props.currentUser?.id) ? (
											<span
												onClick={() =>
													adminDescription === props.adminDescription || !adminDescription.trim()
														? null
														: props.handleUpdateAdminDescription(props.id, adminDescription, props.authToken)
												}
												style={{
													display: 'inline-block',
													marginLeft: 'auto',
													marginRight: '0',
													borderRadius: '0.25rem',
													fontSize: '11px',
													lineHeight: '20px',
													padding: adminDescription === props.adminDescription || !adminDescription.trim() ? '0 10px' : '0 30px',
													cursor: adminDescription === props.adminDescription || !adminDescription.trim() ? 'not-allowed' : 'pointer',
													opacity: adminDescription === props.adminDescription || !adminDescription.trim() ? '0.5' : '1',
													transition: 'all ease 0.3s',
												}}
												className='btn-primary'
											>
												{props.t('Save')}
											</span>
										) : null}
									</Label>
									{!props.isDeleted && (props.isAnalystAdmin || (props.assignedAnalyst && props.assignedAnalyst.value === props.currentUser?.id)) ? (
										<AvField
											type='textarea'
											name='description'
											value={adminDescription}
											onChange={(e) => setAdminDescription(e.target.value)}
											style={{
												maxHeight: expandedCategories ? '239px' : '119px',
												transition: 'all ease 0.3s',
												height: '1000px',
											}}
										/>
									) : (
										<p className='dt-field'>{adminDescription}</p>
									)}
								</AvForm>
							</Col>

							<Col sm='6' md='6' lg='6'>
								<Label className='form-label text-dark' style={{ display: 'flex', justifyContent: 'space-between' }}>
									{props.t('Category by the admin')}:
									{props.isAnalystAdmin || props.assignedAnalyst?.value === props.currentUser?.id || props.adminCategories?.length > 1 ? (
										<span
											onClick={() => setExpandedCategories(!expandedCategories)}
											style={{
												display: 'inline-block',
												marginLeft: 'auto',
												marginRight: '0',
												borderRadius: '0.25rem',
												fontSize: '11px',
												lineHeight: '20px',
												padding: '0 10px',
												cursor: 'pointer',
											}}
											className='btn-primary'
										>
											{expandedCategories ? props.t('Collapse list') : props.t('Expand list')}
										</span>
									) : null}
								</Label>

								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<div style={{ border: '1px solid #dadada', padding: '10px', borderRadius: '6px' }}>
										{props.categories &&
										props.adminCategories &&
										props.categories.filter((i) => props.adminCategories.includes(i.id)) &&
										props.categories.filter((i) => props.adminCategories.includes(i.id)).length > 0 ? (
											<ListGroup
												type='inline'
												style={{ height: '1000px', maxHeight: expandedCategories ? '171px' : '51px', overflowY: 'scroll', transition: 'all ease 0.3s' }}
											>
												{props.categories.filter((i) => props.adminCategories.includes(i.id)) &&
													props.categories
														.filter((i) => props.adminCategories.includes(i.id))
														.map((item) => (
															<ListGroupItem key={item.id}>
																<span
																	className='d-inline-flex align-items-center justify-content-center avatar-xs rounded-circle bg-primary'
																	style={{
																		width: 24,
																		height: 24,
																		marginRight: 9,
																		fontSize: 11,
																		color: '#fff',
																		paddingTop: 3,
																	}}
																>
																	{item && item.title ? item.title[0].toUpperCase() : null}
																</span>{' '}
																{item && item.title ? item.title : null}{' '}
																{props.isReadOnlyAnalyst ? null : (
																	<Badge
																		className='text-dark pt-2'
																		color='light'
																		style={{
																			float: 'right',
																			cursor: 'pointer',
																		}}
																		onClick={(e) =>
																			handleUpdateAdminCategories(
																				props.id,
																				props.adminCategories.filter((i) => i !== item.id),
																				props.authToken,
																			)
																		}
																	>
																		X
																	</Badge>
																)}
															</ListGroupItem>
														))}
											</ListGroup>
										) : (
											<p
												style={{
													lineHeight: '43px',
													height: '1000px',
													maxHeight: expandedCategories ? '163px' : '43px',
													overflowY: 'scroll',
													transition: 'all ease 0.3s',
												}}
											>
												{props.t(EMPTY_LIST)}
											</p>
										)}

										<div className='mt-2'>
											{!props.isDeleted && (props.isAnalystAdmin || props.assignedAnalyst?.value === props.currentUser?.id) ? (
												<Select
													className={'select--filter-categories'}
													classNamePrefix='select2-selection'
													options={
														props.categories
															? props.categories
																	.filter((i) => i.flag && !props.adminCategories.includes(i.id))
																	.map((i) => {
																		return {
																			value: i.id,
																			baseLabel: i.title,
																			label: props.t(i.title),
																		};
																	})
															: []
													}
													onChange={(e) => {
														handleUpdateAdminCategories(props.id, [...props.adminCategories, e.value], props.authToken);
													}}
													style={{
														height: 30,
														overflowY: 'auto',
													}}
													placeholder={props.t('Select categories')}
												/>
											) : null}
										</div>
									</div>
								</div>
							</Col>
						</Row>
					) : null}


					<Row>
						{/* assigned analyst */}
						{!props.isDeleted ? (
							<Col sm='12' md='6'>
								<div className='mb-3'>
									<Label className='form-label text-dark'>{props.t('Assigned analyst')}</Label>
									{!props.reportLoading && !props.analystsAndAnalystAdminsLoading ? (
										props.isAnalystOrAnalystAdmin ? (
											props.analystsAndAnalystAdmins ? (
												<Select
													placeholder={props.t('Select')}
													classNamePrefix='select2-selection'
													options={props.analystsAndAnalystAdmins}
													title={props.t('Assigned analyst')}
													value={props.assignedAnalyst}
													onChange={(analyst) =>
														props.handleChangeAssignedAnalyst(
															props.report,
															analyst.value,
															props.assignedAnalyst,
															props.analystsAndAnalystAdmins,
															props.authToken,
														)
													}
													isSearchable={true}
													isDisabled={props.isReadOnlyAnalyst || props.report?.deleted_by_name || props.isDeleted}
												/>
											) : null
										) : (
											// render name of assigned analyst for non-analyst members
											<p>{props.assignedAnalyst ? props.assignedAnalyst.label : ''}</p>
										)
									) : (
										// skeleton
										<p className={`dt-field dt-skeleton ${props.isAnalystOrAnalystAdmin ? 'dt-select-list' : ''}`}></p>
									)}
								</div>
							</Col>
						) : null}

						{props.isAnalystOrAnalystAdmin ? (
							!props.isDeleted ? (
								<Col sm='12' md='6'>
									<div className='mb-3'>
										<Label className='form-label text-dark'>{props.t('Link Case')}:</Label>
										{!props.reportLoading && !props.casesLoading ? (
											props.analystsAndAnalystAdmins ? (
												<Select
													placeholder={props.t('Select')}
													classNamePrefix='select2-selection'
													options={props.cases}
													title={props.t('Link Case')}
													value={
														props.linkedCases?.length 
														?
														props.linkedCases : null
													}
													onChange={(selectedOptions, { action, removedValue }) => {
														if(action === "remove-value") {
															props.handleChangeLinkedCase(props.id, removedValue?.value, props.authToken, "remove"); 
														} else {
															props.handleChangeLinkedCase(props.id, selectedOptions[selectedOptions?.length-1]?.value, props.authToken, "add");
														}
													}}
													isDisabled={props.isReadOnlyAnalyst || props.report?.deleted_by_name || props.isDeleted}
													isMulti
													isClearable={false}
												/>
											) : null
										) : (
											// skeleton
											<p className={`dt-field dt-skeleton ${props.isAnalystOrAnalystAdmin ? 'dt-select-list' : ''}`}></p>
										)}
									</div>
								</Col>
							) : null
						) : (
							// status
							renderReportStatus(props.status, props.isDeleted, props.id, props.assignedAnalyst, props.authToken)
						)}
					</Row>

					<Row>
						{props.isAnalystOrAnalystAdmin ? renderReportStatus(props.status, props.isDeleted, props.id, props.assignedAnalyst, props.authToken) : null}

						{renderRiskRate(props.risk, props.riskRate, props.isDeleted, props.hasRiskAssessmentFeature, props.authToken)}
					</Row>

					<Row hidden={!props.linkedToCases?.length}>
						{props.isAnalystOrAnalystAdmin ? (
							!props.isDeleted ? (
								<Col sm='12' md='6'>
									<div className='mb-3'>
										<Label className='form-label text-dark'>{props.t('Linked By Cases')}:</Label>
										{!props.reportLoading && !props.casesLoading ? (
											props.analystsAndAnalystAdmins ? (
												<Select
													// placeholder={props.t('Select')}
													classNamePrefix='select2-selection'
													options={props.cases}
													title={props.t('Link By Cases')}
													value={
														props.linkedToCases?.length 
														?
														props.linkedToCases
															// ? {
															// 		value: props.linkedCase.value,
															// 		label: props.linkedCase.label,
															//   }
															: null
													}
													onChange={(selectedOptions, { action, removedValue }) => {
														if(action === "remove-value") {
															props.handleChangeLinkedCase(props.id, removedValue?.value, props.authToken, "remove"); 
														} else {
															props.handleChangeLinkedCase(props.id, selectedOptions[selectedOptions?.length-1]?.value, props.authToken, "add");
														}
													}}
													// isDisabled={props.isReadOnlyAnalyst || props.report?.deleted_by_name || props.isDeleted}
													isMulti
													isClearable={false}
													isDisabled
												/>
											) : null
										) : (
											// skeleton
											<p className={`dt-field dt-skeleton ${props.isAnalystOrAnalystAdmin ? 'dt-select-list' : ''}`}></p>
										)}
									</div>
								</Col>
							) : null
						) : (
							// status
							renderReportStatus(props.status, props.isDeleted, props.id, props.assignedAnalyst, props.authToken)
						)}
					</Row>		
				</Col>

				{!props.reportLoading ? (
					<Col sm='12' lg='5'>
						{props.isDeleted ? (
							<Row>
								{/* reason for deletion */}
								{props.reasonForDeletion ? (
									<Col sm='12'>
										<div className='mb-3'>
											<Label className='form-label mb-0 text-dark'>{props.t('Reason for Deletion')}:</Label>
											<p>
												{props.reasonForDeletion === 'CASE_GREATER_THAN_5_YEARS_CLOSED'
													? props.t('Case >= 5 years closed')
													: props.reasonForDeletion === 'CASE_GREATER_THAN_10_YEARS_CLOSED'
													? props.t('Case >= 10 years closed')
													: props.reasonForDeletion}
											</p>
										</div>
									</Col>
								) : null}

								{/* status updating date */}
								{props.statusUpdatingDate ? (
									<Col sm='12'>
										<div className='mb-3'>
											<Label className='form-label mb-0 text-dark'>{props.t('Deletion date')}:</Label>
											<p>{dateUtils.convertTimeStampToDate(props.statusUpdatingDate, INTERNATIONAL_DATE_FORMAT)}</p>
										</div>
									</Col>
								) : null}

								{/* assigned analyst */}
								{props.assignedAnalyst ? (
									<Col sm='12'>
										<div className='mb-3'>
											<Label className='form-label mb-0 text-dark'>{props.t('Approver')}:</Label>
											<p>{props.deletedByName}</p>
										</div>
									</Col>
								) : null}
							</Row>
						) : (
							<Row>
								{/* attachments */}
								<Col sm='12' md='12' lg='12'>
									{props.isAnalystOrAnalystAdmin ? (
										!props.reportLoading ? (
											<Col sm='12'>
												<div className='mb-3'>
													<Dropdown isOpen={openDownloadDropdown} toggle={() => setOpenDownloadDropdown(!openDownloadDropdown)}>
														<DropdownToggle color='light' caret>
															{props.t('Download')} <i className='mdi mdi-chevron-down'></i>
														</DropdownToggle>
														<DropdownMenu>
															<DropdownItem
																onClick={(e) =>
																	handleExportInternal(
																		props.id,
																		props.internalComments,
																		props.organizationLogo,
																		props.organizationQuestions,
																		props.organizationQuestionsCategories,
																		props.currentUser,
																		props.report,
																		props.comments,
																	)
																}
															>
																<p className='m-0'>{props.t('Internal Investigation Report')}</p>
																<small className='text-muted'>{props.t('Case and Internal Comments')}</small>
															</DropdownItem>
															<DropdownItem
																onClick={(e) =>
																	handleExportCase(
																		props.id,
																		props.organizationLogo,
																		props.organizationQuestions,
																		props.organizationQuestionsCategories,
																		props.currentUser,
																		props.report,
																		props.comments,
																	)
																}
															>
																<p className='m-0'>{props.t('Case')}</p>
																<small className='text-muted'>{props.t('Case and Messages')}</small>
															</DropdownItem>
															{/* {props.hasHistoryLogsFeature && props.isAnalystOrAnalystAdmin ? (
																<DropdownItem onClick={(e) => handleExportLogs(props.id, props.logs, props.organizationLogo)}>
																	<p className='m-0'>{props.t('Logs')}</p>
																	<small className='text-muted'>{props.t('History Logs')}</small>
																</DropdownItem>
															) : null} */}
														</DropdownMenu>
													</Dropdown>
												</div>
											</Col>
										) : (
											123
										)
									) : null}
									{!props.reportLoading ? renderAttachments(props.attachments, props.report.voices) : <div className='dt-field dt-skeleton dt-accordion'></div>}
								</Col>
								{/* read-only analysts */}
								<Col sm='12' md='12' lg='12'>
                  <br />
									<Alert color='info' hidden={!props.isReadOnlyAnalyst} className='mb-0'>
										<FaRegEye />
										{' ' + props.t("You're in read-only view")}
									</Alert>
									<br />
									{props.isAnalystOrAnalystAdmin && props.hasReadOnlyPermission ? (
										!props.analystsLoading ? (
											renderReadOnlyAnalysts(props.analysts, props.readOnlyAnalysts, props.authToken)
										) : (
											<div className='dt-field dt-skeleton dt-accordion' style={{ height: '141px' }}></div>
										)
									) : null}
									<br />
								</Col>
							</Row>
						)}

						
					</Col>
				) : (
					<Col sm='12' lg='5'>
						<Row>
							<Col>
								<div style={{ height: '4rem' }}></div>
								<div className='dt-field dt-skeleton dt-accordion'></div>
							</Col>

							<Col>
								<div style={{ height: '4rem' }}></div>
								<div className='dt-field dt-skeleton dt-accordion'></div>
							</Col>
						</Row>
					</Col>
				)}
			</Row>

			{deleteModalOpen ? (
				<DeleteConfirmationModal
					modalActivated={deleteModalOpen}
					onModalClosed={() => setDeleteModalOpen(!deleteModalOpen)}
					onDeleteSubmit={() => handleDeleteVoice(selectedFileIdForDeletion)}
					isFile={true}
					t={props.t}
				/>
			) : null}
		</>
	);
};

export default memo(ReportFields);
