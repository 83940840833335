import HorizontalLayout from '../../../components/HorizontalLayout';

import DataDeletionRequest from '../pages/thirdparty/data-deletion-request';

import DataBreachIncident from '../pages/thirdparty/data-breach-incident';

import DSRRequest from '../pages/thirdparty/dsr';

import ReportDetails from '../pages/reporting/ReportDetails';
import NewMyReportList from "../pages/reporting/MyReport/index"
import UserManual from '../pages/UserManual';
// import otherRequests from '../pages/thirdparty/other-requests';

const dataProtectionRoutes = [
	// User Manual
	{
		path: '/third-party/data-protection/user_manual',
		component: UserManual,
		layout: HorizontalLayout,
		type: 'third-party',
		modules: ["gd"]
	},
	{
		path: '/third-party/data-protection/report/detail',
		component: ReportDetails,
		layout: HorizontalLayout,
		type: 'third-party',
		modules: ["gd"]
	},
	{
		path: '/third-party/data-protection/data-subject-request/details/:id',
		component: ReportDetails,
		layout: HorizontalLayout,
		type: 'third-party',
		modules: ["gd"]
	},
	{
		path: '/third-party/data-protection/incident-register/details/:id',
		component: ReportDetails,
		layout: HorizontalLayout,
		type: 'third-party',
		modules: ["gd"]
	},
	{
		path: '/third-party/data-protection/reports',
		component: NewMyReportList,
		layout: HorizontalLayout,
		type: 'third-party',
		modules: ["gd"],
		title: 'List Reports | SIP',
	},
	{
		path: '/third-party/data-protection/data-deletion-request',
		component: DataDeletionRequest,
		layout: HorizontalLayout,
		type: 'third-party',
		modules: ["gd"],
		title: 'Data Deletion Request | SIP',
	},
    {
		path: '/third-party/data-protection/data-breach-incident',
		component: DataBreachIncident,
		layout: HorizontalLayout,
		type: 'third-party',
		modules: ["gd"],
		title: 'Data Breach Incident Request | SIP',
	},
	{
		path			:	'/third-party/data-protection/data-subject-request',
		component		:	DSRRequest,
		layout			:	HorizontalLayout,
		type			:	'third-party',
		modules         :   ["gd"],
		title			:	'DSR Request | SIP',
	},
	// {
	// 	path: 'third-party/data-protection/other-requests',
	// 	component: otherRequests,
	// 	layout: HorizontalLayout,
	// 	type: 'third-party',
	// 	modules: ["gd"],
	// 	title: 'Other Requests | SIP',
	// }
];

export default dataProtectionRoutes;
