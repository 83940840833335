import {
    ACTION_APP_LOAD_STATUS_CHANGED,
    ACTION_APP_ERROR
} from './actionTypes';

export const appLoadStatusChanged = (status) => ({
    type: ACTION_APP_LOAD_STATUS_CHANGED,
    payload: status
});

export const appError = (error) => ({
    type: ACTION_APP_ERROR,
    payload: {
        status: error.status,
        code : error.code,
        message : error.message
    }
});