import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { withNamespaces } from "react-i18next";
import {
  errorNotification,
  successNotification,
} from "../../../../store/actions";
import { Link } from "react-router-dom";
import { actionDefinitions, sectionDefinitions, userRoll } from "./constants";
import { handleAddEllipsis } from "../../../../utils/utils";
import {
  API_URL_DOWNLOAD_LOGS,
  API_URL_GET_HISTORY_LOGS,
  API_URL_GET_USERS,
  CM_API_URL_DOWNLOAD_LOGS,
} from "../../../../common/constants";
import { isFeatureEnabledForActiveModule } from 'src/helpers/module_helper';
import axios from "axios";
import CustomPagination from "../../../../components/CustomPagination";
import CustomRangeDatePicker from "../../../../components/CustomRangeDatePicker";
import clockIcon from "../../../../assets/icons/clockIcon.png";
import viewIcon from "../../../../assets/icons/viewIcon.png";
import CustomTable from "../../../../components/CustomTable";
import Select from "react-select";
import "./styles.scss";
import { DropdownToggle, Label, UncontrolledDropdown } from "reactstrap";
import { TbLoader } from "react-icons/tb";

const HistoryLogs = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(25);
  const module = localStorage.getItem("module");
  const [logs, setLogs] = useState([]);
  const [logs_totalCount, setLogs_totalCount] = useState(null);
  const [logs_isLoading, setLogs_isLoading] = useState(false);

  const [users, setUsers] = useState([]);

  const [tableData, setTableData] = useState([]);
  const [selectedUser, setSelectedUser] = useState();

  const [sections, setSections] = useState([]);
  const [selectedSection, setSelectedSection] = useState("");

  const [actions, setActions] = useState([]);
  const [selectedAction, setSelectedAction] = useState("");
  const [spinner, setSpinner] = useState(false);

  const { t } = props;

  const headerData = [
    [
      { value: "#", width: "10%" },
      { value: props.t("Activities"), width: "15%" },
      { value: props.t("Case ID"), width: "10%" },
      { value: props.t("User"), width: "10%" },
      { value: props.t("User Roll"), width: "15%" },
      { value: props.t("Description"), width: "20%" },
      { value: props.t("Date"), width: "15%" },
      { value: props.t("Action"), width: "10%" },
    ],
  ];

  const [itemsPerPageSelected, setItemsPerPageSelected] = useState(itemPerPage);
  const [selectionDate, setSelectionDate] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);

  const reformatDate = (dateStr) => {
    const date = new Date(dateStr);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(
      2,
      "0"
    )}`;
  };

  const handleFetchLogs = async (pagination, token) => {
    try {
      setLogs_isLoading({ logs_isLoading: true });
      setLogs([]);
      setLogs_totalCount(null);
      // console.log("Selected User",selectedUser)
      const response = await axios.get(API_URL_GET_HISTORY_LOGS, {
        params: {
          pageSize: pagination.pageSize,
          pageIndex: pagination.pageIndex,
          fromDate: selectionDate[0].startDate
            ? reformatDate(selectionDate[0].startDate)
            : null,
          toDate: selectionDate[0].endDate
            ? reformatDate(selectionDate[0].endDate)
            : null,
          user: selectedUser ? selectedUser : null,
          section: selectedSection ? selectedSection : null,
          action: selectedAction ? selectedAction : null,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.data.logs) setLogs(response.data.data.logs);
      else setLogs([]);

      setLogs_totalCount(Number(response?.data?.data?.itemsCount));
      setLogs_isLoading(false);
    } catch (error) {
      props.errorNotification({
        message: props.t("Failed to fetch history logs."),
      });
    }

    setLogs_isLoading(false);
  };

  useEffect(() => {
    // console.log("Called")
    // if (!isFeatureEnabledForActiveModule("history-logs")) {
    handleFetchLogs(
      {
        pageIndex: currentPage,
        pageSize: itemsPerPageSelected,
      },
      props.token
    );
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPage,
    selectionDate[0].startDate,
    selectionDate[0].endDate,
    selectedUser,
    selectedSection,
    selectedAction
  ]);

  useEffect(() => {
    // if (!selectedSection) {
    //   setActions([]);
    //   setSelectedAction("");
    // } else {
    fetchActions();
  }, [selectedSection]);

  useEffect(() => {
    if (currentPage === 1) {
      handleFetchLogs(
        {
          pageIndex: currentPage,
          pageSize: itemsPerPageSelected,
        },
        props.token
      );
    } else {
      setCurrentPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsPerPageSelected]);

  useEffect(() => {
    const tableData = logs.map((log) => {
      // console.log(log)
      return [
        {
          value: log.secondary_id,
          width: "10%",
        },
        {
          value: (
            <p style={{ fontWeight: "700" }}>
              {props.t(sectionDefinitions[log.section])}
            </p>
          ),
          width: "15%",
        },
        {
          value: log.metadata.secondary_case_id
            ? handleAddEllipsis(log.metadata.secondary_case_id, 10)
            : "-",
          width: "10%",
        },
        {
          value: handleAddEllipsis(log.user_name, 10),
          width: "10%",
        },
        {
          value: props.t(userRoll[log.user_role]),
          width: "15%",
        },
        {
          value: handleAddEllipsis(
            log?.action && log?.action!=="download_dashboard"
              ? props.t(actionDefinitions[log?.section][log?.action])
              : "",
            60
          ),
          width: "20%",
        },
        {
          value: (
            <div className="d-flex">
              <img src={clockIcon} width={20} height={20} alt="Clock Icon" />
              <p
                style={{
                  color: "#888AAB",
                  marginLeft: "10px",
                  fontSize: "12px",
                }}
              >
                {log.created_at}
              </p>
            </div>
          ),
          width: "15%",
        },
        {
          value: (
            <Link
              to={`/admin/administration/history-logs/${log.id}`}
              className="d-flex align-items-center text-black"
            >
              <img src={viewIcon} width={20} height={20} alt="Details Icon" />
              <p style={{ margin: "0 5px" }}>{props.t("View")}</p>
            </Link>
          ),
          width: "10%",
        },
      ];
    });

    setTableData(tableData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logs, props.t]);

  const fetchUsers = async () => {
    const url = API_URL_GET_USERS;
    const { token } = props;
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const { data } = response.data;
    const userList = [{ value: "", label: props.t("Select user") }];

    const result = await data.map((user) => {
      return userList.push({
        value: user.id,
        label: user.first_name + " " + user.last_name,
      });
    });
    // console.log("Data", data)
    setUsers(userList);
  };

  const fetchSections = () => {
    // Set Section Options
    setSections([
      { value: "", label: t("Select section") },
      { value: "case", label: t("Case") },
      { value: "risk", label: t("Risk") },
      { value: "task", label: t("Task") },
      { value: "admin", label: t("Admin") },
      { value: "document", label: t("Document") },
    ]);
  }

  const fetchActions = () => {
    let data = [{ value: "", label: t("Select action") }];
    const caseData = [
      { value: "create", label: t("New case created") },
      { value: "update_analyst", label: t("Update Analyst") },
      { value: "update_status", label: t("Update Status") },
      { value: "mark_as_delete", label: t("Marked as delete") },
      { value: "delete", label: t("Deleted") },
      { value: "upload_attachment", label: t("Uploaded attachment") },
      { value: "download_user_attachment", label: t("Download attachment") },
      {
        value: "download_analyst_attachment",
        label: t("Download attachment"),
      },
      { value: "delete_attachment", label: t("Delete Attachment") },
      { value: "add_comment", label: t("Add Comment") },
      { value: "add_internal_comment", label: t("Add Internal Comment") },
      { value: "update_analyst_readonly", label: t("Read-Only Analysts") },
      { value: "update_risk", label: t("Risk updated") },
      { value: "link_case", label: t("Linked case updated") },
      { value: "add_link_case", label: t("Linked case added") },
      { value: "remove_link_case", label: t("Linked case removed") },
      {
        value: "change_admin_description",
        label: t("Admin description updated"),
      },
      { value: "change_admin_category", label: t("Admin category updated") },
    ];

    const riskData = [
      { value: "upload_files", label: t("Uploaded files") },
      { value: "upload_file", label: t("Uploaded file") },
      { value: "download_files", label: t("Download files") },
      { value: "download_file", label: t("Download file") },
      { value: "update_file", label: t("Update file") },
      { value: "delete_file", label: t("Delete file") },
      { value: "create_comment", label: t("New comment created") },
      { value: "create", label: t("New risk created") },
      { value: "create_ai", label: t("New Ai risk created") },
      { value: "update_description", label: t("Description updated") },
      { value: "delete", label: t("Deleted") },
      { value: "assign_analyst", label: t("Assigned to analyst") },
      { value: "update_damage", label: t("Damage updated") },
      { value: "update_likelihood", label: t("Likelihood updated") },
      { value: "update_category", label: t("Category updated") },
      {
        value: "change_description",
        label: t("Description updated by admin"),
      },
      { value: "change_category", label: t("Category updated by admin") },
    ];

    const taskData = [
      { value: "upload_files", label: t("Uploaded files") },
      { value: "upload_file", label: t("Uploaded file") },
      { value: "download_files", label: t("Download files") },
      { value: "download_file", label: t("Download file") },
      { value: "update_file", label: t("Update file") },
      { value: "delete_file", label: t("Delete file") },
      { value: "create_comment", label: t("New comment created") },
      { value: "create", label: t("New task created") },
      { value: "update_description", label: t("Description updated") },
      { value: "delete", label: t("Deleted") },
      { value: "assign_analyst", label: t("Assigned to analyst") },
      { value: "update_status", label: t("Status updated") },
      { value: "update_priority", label: t("Priority updated") },
      { value: "update_deadline", label: t("Deadline updated") },
      { value: "update_category", label: t("Category updated") },
    ];

    const adminData = [
      {
        value: "change_active_directory_settings",
        label: t("Active directory settings"),
      },
      { value: "edit_risk_matrix", label: t("Update matrix") },
      { value: "restore_risk_matrix", label: t("Restore matrix") },
      { value: "edit_user", label: t("Edit user") },
      { value: "create_user", label: t("Created user") },
      { value: "delete_user", label: t("Deleted user") },
      { value: "change_user_status", label: t("Changed user status") },
      { value: "edit_organization", label: t("Edited organization") },
      { value: "update_question", label: t("Updated question") },
      { value: "remove_question", label: t("Removed question") },
      { value: "remove_category", label: t("Removed category") },
      { value: "login", label: t("Login") },
    ];

    const documentData = [
      { value: "create", label: t("Created") },
      { value: "remove", label: t("Removed") },
    ];

    switch (selectedSection) {
      case "case":
        // data.push(caseData);
        caseData.map(c => data.push(c));
        break;
      case "risk":
        riskData.map(c => data.push(c));
        break;
      case "task":
        taskData.map(c => data.push(c));
        break;
      case "admin":
        adminData.map(c => data.push(c));
        break;
      case "document":
        documentData.map(c => data.push(c));
        break;
      default:
        setActions([]);
        setSelectedAction("");
        break;
    }


    setActions(data);
  };

  useEffect(() => {
    const currentTitle = document.title;
    fetchSections();
    fetchActions();

    document.title = `${props.t("History Logs")} | SIP`;

    return () => {
      document.title = currentTitle;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.t]);

  useEffect(() => {
    if (!users?.length) {
      fetchUsers();
    }

    fetchSections();
  }, []);

  const handleClickDownload = () => {
    if(spinner) {
      return;
    }

    setSpinner(true);

    const url = module === "cm" ? CM_API_URL_DOWNLOAD_LOGS : API_URL_DOWNLOAD_LOGS;

    axios
      .get(url ,{
          headers: {
          Authorization: `Bearer ${props.token}`,
        },
        responseType: 'blob',
      }
      )
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob.data]));
        const link = document.createElement("a");

        link.href = url;
        link.setAttribute("download", `history-logs-${Date.now()}.xlsx`);

        document.body.appendChild(link);

        link.click();
        link.parentNode.removeChild(link);

        setSpinner(false)
      })
      .catch((error) => {
        if (error.response) {
          const data = error.response.data;

          props.errorNotification({
            code: data.error || "unknown_error",
            message: props.t(data.message || data.title),
          });
        }

        setSpinner(false);
      });
  }

  const onFilterChange = () => {
    setCurrentPage(1);
  }

  return (
    <Fragment>
      {!isFeatureEnabledForActiveModule("history-logs") ? (
        <div className="page-content">
          <div className="alert alert-warning">
            {props.t("Please upgrade your plan to access this feature")}.
          </div>
        </div>
      ) : (
        <div className="page-content history-logs-page">
          {/* page title */}
          <h2 className="page-title">{props.t("History Logs")}</h2>

          {/* filters */}
          {/* <div className="d-flex"> */}
            <div style={{display: "flex", justifyContent: "space-between"}}>
              <div className="d-flex">
                {/* DATE */}
                <CustomRangeDatePicker
                  selectionDate={selectionDate}
                  setSelectionDate={setSelectionDate}
                  onFilterClick={onFilterChange}
                />

                {/* Users */}
                <div className='select-box-container-component p-2 ms-5'>
                  <Select
                    name="filterbyuser"
                    classNamePrefix="select2-selection"
                    placeholder={props.t("Select user")}
                    options={users}
                    components={{
                      IndicatorSeparator: () => null
                    }}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        minWidth: '220px',
                        background: 'transparent',
                        border: 'none',
                        boxShadow: 'none'
                      }),
                    }}
                    onChange={(event) => {
                      setSelectedUser(event.value);
                      onFilterChange();
                    }}
                  />
                </div>

                {/* Sections */}
                <div className='select-box-container-component p-2 ms-5'>
                  <Select
                    name="filterbysection"
                    classNamePrefix="select2-selection"
                    placeholder={props.t("Select section")}
                    options={sections}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        minWidth: '220px',
                        background: 'transparent',
                        border: 'none',
                        boxShadow: 'none'
                      }),
                    }}
                    onChange={(event) => {
                      onFilterChange();
                      setSelectedSection(event.value);
                    }}
                  />
                </div>

                {/* Actions */}
                <div hidden={!selectedSection}>
                  <div className='select-box-container-component p-2 ms-5'>
                    <Select
                      name="filterbyaction"
                      classNamePrefix="select2-selection"
                      placeholder={props.t("Select action")}
                      options={actions}
                      components={{
                        IndicatorSeparator: () => null
                      }}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          minWidth: '220px',
                          background: 'transparent',
                          border: 'none',
                          boxShadow: 'none'
                        }),
                      }}
                      onChange={(event) => {
                        onFilterChange();
                        setSelectedAction(event.value)
                      }}
                    />
                  </div>
                </div>
              </div>
              <div>
              <UncontrolledDropdown>
                  <DropdownToggle
                    color="primary"
                    onClick={() => {
                      handleClickDownload();
                    }}
                  >
                    {
                    spinner ? (
                      <TbLoader />
                    ) : (
                      props.t("Download")
                    )
                  }
                  </DropdownToggle>
                </UncontrolledDropdown>
              </div>
            </div>
          {/* </div> */}

          {/* <div className="d-flex">
            
          </div> */}

          <div className="page-body">
            {!logs_isLoading ? (
              <CustomTable headerData={headerData} data={tableData} />
            ) : (
              <div className="alert-wrapper">
                <div className="alert alert-info" role="alert">
                  <p
                    style={{
                      textAlign: "center",
                      marginBottom: 0,
                      width: "100%",
                    }}
                  >
                    {props.t("Loading")}...
                  </p>
                </div>
              </div>
            )}

            {/* pagination */}
            <CustomPagination
              page={Number(currentPage)}
              onPageChange={setCurrentPage}
              itemsPerPage={itemPerPage}
              setItemsPerPage={setItemPerPage}
              totalItems={logs_totalCount}
              pageNumberLimit={6}
              setItemsPerPageSelected={setItemsPerPageSelected}
              itemsPerPageSelected={itemsPerPageSelected}
            />
          </div>
        </div>
      )}
    </Fragment>
  );
};

const mapStatetoProps = (state) => {
  return {
    token: state.Login.token,
    organization: state.Organization,
    App: state.App
  };
};

const mapDispachToProps = (dispach) => {
  return {
    errorNotification: (message) => dispach(errorNotification(message)),
    successNotification: (message) => dispach(successNotification(message)),
  };
};

export default withNamespaces()(
  withRouter(connect(mapStatetoProps, mapDispachToProps)(HistoryLogs))
);
