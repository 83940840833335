import FullPageLayout from '../components/FullPageLayout';
import Error404 from '../pages/Error404';

import publicRoutes from './public-routes';
import employeeRoutes from './employee-routes';
import adminRoutes from './admin-routes';

const authProtectedRoutes = [
	...adminRoutes,
	...employeeRoutes,
  
	{
		path: '*',
		exact: true,
		component: Error404,
		layout: FullPageLayout,
	},
];

export { authProtectedRoutes, publicRoutes };