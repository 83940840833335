export const TasksImpacts = {
    both: {
        title: 'Both'
    },
    likelihood: {
        title: 'Likelihood'
    },
    damage: {
        title: 'Damage'
    }
}
