import { connect } from "react-redux";
import { memo, useEffect, useMemo } from "react";
import { withNamespaces } from "react-i18next";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
    Button,
    Card,
    CardBody,
    Col,
    FormGroup,
    Label,
    Row
} from "reactstrap";
import Select from "react-select";
import { Editor } from "react-draft-wysiwyg";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import Spinner from 'react-bootstrap/Spinner';
import {
  CompanySizes
} from "src/modules/3rd-party-management/constants";

import IndustriesService from "src/modules/3rd-party-management/apis/IndustriesService";

import ThirdpartyUsersService from "src/modules/3rd-party-management/apis/ThirdpartyUsersService";

import CommonService from "src/modules/3rd-party-management/apis/CommonService";
import { isFeatureEnabledForActiveModule } from "src/helpers/module_helper";

const filterOptions = (
    candidate,
    input
) => {
    if (input) {
        const candidateInput = candidate.label.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
        const normalizedInput = input.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");

        return candidateInput.indexOf(normalizedInput) >= 0 || ((candidate.data.keywords || []).findIndex((keyword) => {
            const normalizedKeyword = keyword.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");

            return normalizedKeyword.indexOf(normalizedInput) >= 0
        }) >= 0);
    }

    return true;
};

const CompanyDetails = ({
    t,
    lng,
    setCompanyName,
    setIndustries,
    setCompanySize,
    setWebsite,
    companyName,
    website,
    industries,
    companySize,
    description,
    setDescription,
    legalEntities,
    setLegalEntities,
    vatNumber,
    setVatNumber,
    setCompanyDetailsTabCompleted,
    selectedIndusries,
    setSelectedIndustries,
    supplierType,
    modules: {
        featuresLoadingStatus   :   currentModuleFeaturesLoadingStatus
    }
}) => {
    const handleFetchIndustriesListQuery = useQuery({
        queryKey: [
            '3rd-party-management-fetch-industries-list-query',
            lng
        ],
        queryFn: async () => {
            const service = IndustriesService.getInstance();

            return await service.fetchList({
                language: lng !== 'en' ? lng : undefined
            });
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onError: (error) => {
            toast(t('An error occurred while fetching industires list.'), {
                type: 'error',
            });
        }
    });

    const {
        data        :   legalEntitiesData,
        isFetching  :   legalEntitiesListIsLoading,
        refetch     :   fetchLegalsList
    } = useQuery({
        queryKey: ['3rd-party-management-fetch-legal-entities-list'],
        queryFn: async () => {
        const service = CommonService.getInstance();

            return await service.fetchLegalEntities();
        },
        enabled: false,
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onError: (error) => {
            toast(t('An error occurred while fetching entities.'), {
                type: 'error',
            });
        }
    });

    const {
        mutate      :   checkThirdpartyExistenceMutation,
        isLoading   :   checkingThirdpartyExistenceInProcess
    } = useMutation({
        mutationFn: async (payload) => {
            const service = ThirdpartyUsersService.getInstance();

            return await service.checkThirdpartyExistence(payload);
        }
    });

    const onValidSubmit = (e, values) => {
        checkThirdpartyExistenceMutation({
            name        :   values.companyName,
            websiteUrl  :   values.website,
            vatNumber   :   vatNumber || undefined
        }, {
            onSuccess: () => {
                setCompanyDetailsTabCompleted('company-details')
            },
            onError: (error) => {
                if(error.response?.data?.error === 'invalid_vatNumber'){
                    toast(t(`A company with this VAT ID already exists.`), {
                        type: "error",
                    });
                }
                else{
                    toast(t(`A company with this name already exists.`), {
                        type: "error",
                    });
                }
            }
        })
    }

    useEffect(() => {
        if(handleFetchIndustriesListQuery?.data){
            const data = Array.isArray(handleFetchIndustriesListQuery.data) ? handleFetchIndustriesListQuery.data : [];

            const list = data.map((industry) => {
                return {
                    value : industry.id,
                    label : `${industry.class} - ${industry.title}`,
                    keywords: industry?.keywords || []
                };
            });

            setIndustries(list);

            const newSelectedIndustries = selectedIndusries.map((sIndustry) => {
                return list.find((listItem) => listItem.value === sIndustry.value)
            });

            setSelectedIndustries(newSelectedIndustries);
        }
    }, [handleFetchIndustriesListQuery.data]);

    useEffect(() => {
        handleFetchIndustriesListQuery.refetch();
    }, [ lng ]);

    const sizes = [];

    const industriesListIsLoading = handleFetchIndustriesListQuery.isFetching || handleFetchIndustriesListQuery.isLoading;

    for(const sizeKey in CompanySizes){
        sizes.push({
            value : sizeKey,
            label : `${CompanySizes[sizeKey]?.title} ${t("Employees")}`
        });
    }

    const entitiesOptions = useMemo(() => {
        return (legalEntitiesData?.legalEntities || []).map((legal) => {
            return {
                value   :   legal.id,
                label   :   legal.title
            }
        }) 
    }, [ legalEntitiesData ]);

    useEffect(() => {
        if(currentModuleFeaturesLoadingStatus === 'loaded' && isFeatureEnabledForActiveModule('entity_management')){
            fetchLegalsList();
        }
    }, [ currentModuleFeaturesLoadingStatus ])

    const renderCreditorBody = () => {
        return (
            <CardBody className="p-0">
                <p className="sub-header medium">
                    {t("General Information")}
                </p>

                <Row>
                    <Col md="4" sm="12">
                        <Label for="companyName">{t("Company Name")}: </Label>
                        <AvField
                            name={"companyName"}
                            placeholder=""
                            type="text"
                            errorMessage={t("This field cannot be blank")}
                            className="form-control"
                            validate={{
                            required: { value: true },
                            }}
                            value={ companyName }
                            onChange={(e) => setCompanyName(e.target.value)}
                            id="company-name"
                        />
                    </Col>

                    <Col md="4" sm="12">
                        <Label for="industry">{t("Industries")}: </Label>

                        <Select
                            isDisabled={ industriesListIsLoading }
                            isLoading={ industriesListIsLoading }
                            placeholder={t("Select") + "..."}
                            classNamePrefix="select2-selection"
                            id={`industry`}
                            options={industries}
                            menuPortalTarget={document.body}
                            onChange={(e) => setSelectedIndustries(e)}
                            value={selectedIndusries}
                            selectedValues={selectedIndusries}
                            filterOption={filterOptions}
                            isMulti
                        />

                        <AvField
                            name={"industry"}
                            placeholder=""
                            type="hidden"
                            errorMessage={t("This field cannot be blank")}
                            className="form-control"
                            validate={{
                            required: { value: true },
                            }}
                            value={selectedIndusries}
                        />
                    </Col>

                    <Col md="4" sm="12">
                        <Label for="vatNumber">{t("VAT ID")}: </Label>
                        <AvField
                            name={"vatNumber"}
                            placeholder=""
                            type="text"
                            errorMessage={t("This field cannot be blank")}
                            className="form-control"
                            validate={{
                            required: { value: false },
                            }}
                            value={ vatNumber }
                            onChange={(e) => setVatNumber(e.target.value)}
                            id="vatNumber"
                        />
                    </Col>
                </Row>

                <Row className="mt-4">
                    {isFeatureEnabledForActiveModule('entity_management') && (
                        <Col md="4" sm="12">
                            <Label for="industry">{t("Connected Legal Entities")}: </Label>

                            <Select
                                isMulti
                                isDisabled={ legalEntitiesListIsLoading }
                                isLoading={ legalEntitiesListIsLoading }
                                placeholder={t("Select") + "..."}
                                classNamePrefix="select2-selection"
                                id={`legal-entities`}
                                options={entitiesOptions}
                                menuPortalTarget={document.body}
                                onChange={(e) => {
                                    setLegalEntities(e)
                                }}
                                value={legalEntities}
                                filterOption={filterOptions}
                            />
                        </Col>
                    )}

                    <Col md="4" sm="12">
                        <Label for="companySize">{t("Company Size")}: </Label>
                        <Select
                            placeholder={t("Select") + "..."}
                            classNamePrefix="select2-selection"
                            id={`companySize`}
                            options={sizes}
                            menuPortalTarget={document.body}
                            onChange={(e) => setCompanySize(e)}
                            value={companySize}
                            />
                            <AvField
                            name={"companySize"}
                            placeholder=""
                            type="hidden"
                            errorMessage={t("This field cannot be blank")}
                            className="form-control"
                            validate={{
                                required: { value: true },
                            }}
                            value={companySize}
                        />
                    </Col>

                    <Col md="4" sm="12">
                        <Label for="website">{t("Website")}: </Label>
                        <AvField
                            name={"website"}
                            placeholder=""
                            type="text"
                            errorMessage={t("This field cannot be blank")}
                            className="form-control"
                            validate={{
                                required: { value: true },
                            }}
                            value={website}
                            id="website"
                        onChange={(e) => setWebsite(e.target.value)}
                        />
                    </Col>
                </Row>

                <Row className="mt-4">
                    <Col>
                        <Label for="description">{t("Description")}: </Label>
                        <Editor
                            editorState={description}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={(e) => setDescription(e)}
                            toolbar={{
                            options: [
                                "inline",
                                "blockType",
                                "fontSize",
                                "list",
                                "textAlign",
                                "colorPicker",
                                "link",
                                "remove",
                                "history",
                            ],
                            inline: {
                                options: [
                                "bold",
                                "italic",
                                "underline",
                                "strikethrough",
                                "monospace",
                                ],
                                bold: { className: "bordered-option-classname" },
                                italic: { className: "bordered-option-classname" },
                                underline: { className: "bordered-option-classname" },
                                strikethrough: {
                                className: "bordered-option-classname",
                                },
                                code: { className: "bordered-option-classname" },
                            },
                            blockType: {
                                className: "bordered-option-classname",
                            },
                            fontSize: {
                                className: "bordered-option-classname",
                            },
                            }}
                        />
                    </Col>
                </Row>
            </CardBody>
        )
    }

    const renderOwnCompanyBody = () => {
        return (
            <CardBody className="p-0">
                <p className="sub-header medium">
                    {t("General Information")}
                </p>

                <Row>
                    <Col md="4" sm="12">
                        <Label for="industry">{t("Legal Entity")}: </Label>

                        <Select
                            isDisabled={ legalEntitiesListIsLoading }
                            isLoading={ legalEntitiesListIsLoading }
                            placeholder={t("Select") + "..."}
                            classNamePrefix="select2-selection"
                            id="legal-entities"
                            options={entitiesOptions}
                            menuPortalTarget={document.body}
                            onChange={(e) => {
                                setLegalEntities(e)
                                setCompanyName(e.label)
                            }}
                            value={legalEntities}
                            filterOption={filterOptions}
                        />

                        <AvField
                            name="industry"
                            type="hidden"
                            errorMessage={t("This field cannot be blank")}
                            className="form-control"
                            validate={{
                                required: { value: true },
                            }}
                            value={legalEntities}
                        />

                        <AvField
                            name="companyName"
                            type="hidden"
                            value={ companyName }
                        />
                    </Col>

                    <Col md="4" sm="12">
                        <Label for="industry">{t("Industries")}: </Label>

                        <Select
                            isDisabled={ industriesListIsLoading }
                            isLoading={ industriesListIsLoading }
                            placeholder={t("Select") + "..."}
                            classNamePrefix="select2-selection"
                            id={`industry`}
                            options={industries}
                            menuPortalTarget={document.body}
                            onChange={(e) => setSelectedIndustries(e)}
                            value={selectedIndusries}
                            selectedValues={selectedIndusries}
                            filterOption={filterOptions}
                            isMulti
                        />

                        <AvField
                            name={"industry"}
                            placeholder=""
                            type="hidden"
                            errorMessage={t("This field cannot be blank")}
                            className="form-control"
                            validate={{
                            required: { value: true },
                            }}
                            value={selectedIndusries}
                        />
                    </Col>

                    <Col md="4" sm="12">
                        <Label for="vatNumber">{t("VAT ID")}: </Label>
                        <AvField
                            name={"vatNumber"}
                            placeholder=""
                            type="text"
                            errorMessage={t("This field cannot be blank")}
                            className="form-control"
                            validate={{
                            required: { value: false },
                            }}
                            value={ vatNumber }
                            onChange={(e) => setVatNumber(e.target.value)}
                            id="vatNumber"
                        />
                    </Col>
                </Row>

                <Row className="mt-4">
                    <Col md="4" sm="12">
                        <Label for="companySize">{t("Company Size")}: </Label>
                        <Select
                            placeholder={t("Select") + "..."}
                            classNamePrefix="select2-selection"
                            id={`companySize`}
                            options={sizes}
                            menuPortalTarget={document.body}
                            onChange={(e) => setCompanySize(e)}
                            value={companySize}
                            />
                            <AvField
                            name={"companySize"}
                            placeholder=""
                            type="hidden"
                            errorMessage={t("This field cannot be blank")}
                            className="form-control"
                            validate={{
                                required: { value: true },
                            }}
                            value={companySize}
                        />
                    </Col>

                    <Col md="8" sm="12">
                        <Label for="website">{t("Website")}: </Label>
                        <AvField
                            name={"website"}
                            placeholder=""
                            type="text"
                            errorMessage={t("This field cannot be blank")}
                            className="form-control"
                            validate={{
                                required: { value: true },
                            }}
                            value={website}
                            id="website"
                        onChange={(e) => setWebsite(e.target.value)}
                        />
                    </Col>
                </Row>

                <Row className="mt-4">
                    <Col>
                        <Label for="description">{t("Description")}: </Label>
                        <Editor
                            editorState={description}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={(e) => setDescription(e)}
                            toolbar={{
                            options: [
                                "inline",
                                "blockType",
                                "fontSize",
                                "list",
                                "textAlign",
                                "colorPicker",
                                "link",
                                "remove",
                                "history",
                            ],
                            inline: {
                                options: [
                                "bold",
                                "italic",
                                "underline",
                                "strikethrough",
                                "monospace",
                                ],
                                bold: { className: "bordered-option-classname" },
                                italic: { className: "bordered-option-classname" },
                                underline: { className: "bordered-option-classname" },
                                strikethrough: {
                                className: "bordered-option-classname",
                                },
                                code: { className: "bordered-option-classname" },
                            },
                            blockType: {
                                className: "bordered-option-classname",
                            },
                            fontSize: {
                                className: "bordered-option-classname",
                            },
                            }}
                        />
                    </Col>
                </Row>
            </CardBody>
        )
    }

    return (
        <>
            <AvForm
                className="needs-validation"
                onValidSubmit={onValidSubmit}
                id="company_details"
                autocomplete="off"
            >
                <Card className="wizard-steps-content-container">
                    {supplierType === 'own' ? (
                        <>
                            {renderOwnCompanyBody()}
                        </>
                    ) : (
                        <>
                            {renderCreditorBody()}
                        </>
                    )}
                </Card>

                <Row className="mt-2 mb-4">
                    <FormGroup
                        style={{
                            display: "flex",
                            flexFlow: "row wrap",
                            justifyContent: "flex-end",
                        }}
                        className="actions-buttons-container"
                    >
                        <Button disabled={checkingThirdpartyExistenceInProcess} color="primary">
                            {checkingThirdpartyExistenceInProcess && (
                                <Spinner className="me-2" animation="border" variant="white" size="sm"/>
                            )}
                            {t("Next")}
                        </Button>
                    </FormGroup>
                </Row>
            </AvForm>
        </>
    );
};

const mapStateToProps = (state) => {
	return {
		modules				:	state.Modules
	};
};

export default withNamespaces()(connect(mapStateToProps, null)(
    memo(CompanyDetails)
));