import Board from '@asseinfo/react-kanban';
import { memo } from 'react';
import { EMPTY_LIST } from '../../../../common/constants';
import CardBox from './CardBox';
import CardHeader from './CardHeader';

const KanbanBoard = function (props) {
  const content = props.board;
  const totalCount = props.totalCount;
  return (
		<>
			{totalCount ? (
				<div className='d-flex'>
					<Board
						initialBoard={content}
						allowAddCard={false}
						disableColumnDrag={true}
						disableCardDrag={true}
						renderColumnHeader={({ title, columnsubtitle }) => <CardHeader title={title} columnsubtitle={columnsubtitle} t={props.t} />}
						renderCard={({ content }, { dragging }) => (
							<CardBox data={content} dragging={false} t={props.t}>
								{content}
							</CardBox>
						)}
						onNewCardConfirm={(draftCard) => ({
							id: new Date().getTime(),
							...draftCard,
						})}
						id={2}
					/>
				</div>
			) : (
				<div className='alert alert-warning' role='alert'>
					<p
						style={{
							textAlign: 'center',
							marginBottom: 0,
							width: '100%',
						}}
					>
						{props.t(EMPTY_LIST)}
					</p>
				</div>
			)}
		</>
	);
};

export default memo(KanbanBoard);
