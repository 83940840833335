import React, { Fragment, useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { Container, Row } from "reactstrap";
import Breadcrumb from "../../components/Common/Breadcrumb";
import "./styles.scss";
import PlatformUpdateList from "./PlatformUpdateList";
import axios from "axios";
import { connect } from "react-redux";
import { AUTH_API_DOMAIN_URL } from "../../common/constants";
import CustomPagination from "../../components/CustomPagination";

function PlatformUpdate(props) {
  const { t, token } = props;
  const [updates, setUpdates] = useState([])
  const {lng} = props;
  const currentModule = localStorage.getItem("module");
  const module = props.organization?.modules?.find(module => module.name === currentModule)?.id;
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(25);
  const [totalCount, setTotalCount] = useState(0);
  const [itemsPerPageSelected, setItemsPerPageSelected] = useState(25);
  
  useEffect(() => {
    const url = `${AUTH_API_DOMAIN_URL}system_update/list`;
    axios.get(url, {
      params: {
        language: lng,
        module,
        pageIndex: currentPage,
        pageSize: itemPerPage,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(res => {
      if(res.data.ok) {
        setUpdates(res.data.data.systemUpdates)
        setTotalCount(res.data.data.itemsCount)
        setCurrentPage(res.data.data.pageIndex)
        setItemPerPage(res.data.data.pageSize)
      }
    })
  }, [module, lng, token, currentPage, itemPerPage])

  
  return (
    <Fragment>
      <div className="page-content platform-updates-page">
        <Row className="p-4">
          <Breadcrumb
            title={t("Platform Updates")}
            breadcrumbItems={[
              {
                title: t("SIP"),
                link: "#",
              },
              {
                title: t("Platform Updates"),
                link: "/admin/platform-updates",
              },
            ]}
          />
        </Row>
        <Container fluid>
            <PlatformUpdateList updates={updates} />

      <div className="bg-white w-100 mb-3">

      <CustomPagination 
          page={Number(currentPage)}
          onPageChange={setCurrentPage}
          itemsPerPage={itemPerPage}
          setItemsPerPage={setItemPerPage}
          totalItems={totalCount}
          pageNumberLimit={9}
          setItemsPerPageSelected={setItemsPerPageSelected}
          itemsPerPageSelected={itemsPerPageSelected}
      />
      </div>
        </Container>
      </div>
    </Fragment>
  );
}

const mapStatetoProps = (state) => {
  const { user, token } = state.Login;
  const { App } = state;
  const organization = state.Organization;
  return { user, token, App, organization };
};

export default connect(mapStatetoProps, {})(withNamespaces()(PlatformUpdate));
