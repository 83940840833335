import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  ButtonGroup,
  Card,
  Input,
  Label,
  Container,
} from "reactstrap";
import { Link } from "react-router-dom";

class EmailInbox extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <div>
          <Row>
            <Col xs={12}>
              {/*  Right Sidebar */}
              <div className="mb-3">
                <Card>
                  {/* toolbar */}
                  <ul className="message-list">
                    <li>
                      <div className="col-mail col-mail-1">
                        <div className="checkbox-wrapper-mail">
                          <Input type="checkbox" id="chk19" />
                          <Label htmlFor="chk19" className="toggle"></Label>
                        </div>
                        <Link to="#" className="mailTitle">
                          Peter, me (3)
                        </Link>
                      </div>
                      <div className="col-mail col-mail-2">
                        <Link to="#" className="subject">
                          Hello –{" "}
                          <span className="teaser">
                            Trip home from Colombo has been arranged, then Jenna
                            will come get me from Stockholm. :)
                          </span>
                        </Link>
                        <div className="date">Mar 6</div>
                      </div>
                    </li>
                  </ul>
                </Card>

                <Row>
                  <Col xs={7}>Showing 1 - 20 of 1,524</Col>
                  <Col xs={5}>
                    <ButtonGroup className="float-end">
                      <Button
                        type="button"
                        size="sm"
                        color="success"
                        className="waves-effect"
                      >
                        <i className="fa fa-chevron-left"></i>
                      </Button>
                      <Button
                        type="button"
                        size="sm"
                        color="success"
                        className="waves-effect"
                      >
                        <i className="fa fa-chevron-right"></i>
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default EmailInbox;
