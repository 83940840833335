import { useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import backgroundImage from "src/modules/data-protection/assets/images/3rd-party-home-Background2.svg";
import DataBreach from "src/modules/data-protection/assets/images/thirdPartyDataBreachIncident.svg";
import OtherRequests from "src/modules/data-protection/assets/images/thirdPartyOtherRequest.svg";

const WBHome = ({
    t, 
    selectedLanguage,
    Organization
}) => {
    const [translation, setTranslation] = useState({});

    useEffect(() => {
        if (Organization.translations && Organization.translations.length > 0) {
            setTranslation(Organization.translations.find(item => item.lang === selectedLanguage && item.module === 'gd') || {});
          }
    }, [Organization.translations, selectedLanguage]);

    return (
        <>
            <div className="third-party-home-page" style={{ backgroundImage: `url(${backgroundImage})` }}>
                <h1>{translation?.title}</h1>
                
                <div className="main-container" style={{overflowX:'hidden'}}>
                    <div className="new-questionnaire-box">
                        <img src={DataBreach} alt="" />
                        <p className="font-size-20 fw-bold m-0">{t("Data Breach Incident")}</p>
                        <div className="flex flex-column text-center">
                            <Link to={ "/third-party/data-protection/data-breach-incident" }>
                                <Button color="btn btn-primary">{t("Report")}</Button>
                            </Link>
                        </div>
                    </div>
                    <div className="existing-questionnaire-box">
                        <img src={OtherRequests} alt="" />
                        <p className="font-size-20 fw-bold m-0">{t("Other Requests")}</p>
                        <div className="flex flex-column text-center">
                            <Link to={ "/third-party/data-protection/data-subject-request" }>
                                <Button color="btn btn-primary">{t("Send")}</Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <Row>
                <Col lg='12'>
                    <Card>
                        <CardBody>
                            {<div className='introText' dangerouslySetInnerHTML={{ __html: translation?.intro }} />}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

const mapStatetoProps = (state) => {
	const { App, Organization, Modules : modules } = state;

	return {
		App,
		Organization,
		token: state.Login.token,
        modules
	};
};

export default withNamespaces()(withRouter(connect(mapStatetoProps, {
})(WBHome)));