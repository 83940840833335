import { Link, useHistory } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import React, { useState, useEffect, memo } from "react";
import { useQuery } from "@tanstack/react-query";
import { connect } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import overlayFactory from "react-bootstrap-table2-overlay";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import { INTERNATIONAL_DATE_FORMAT } from "../../../../../../common/constants";

import { EMPTY_LIST } from "../components/table-filter/constants";
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import TableFilter from "../components/table-filter";

import { Button, Row, Col } from "reactstrap";

import DateUtils from "../../../../../../services/utils/DateUtils";
import { withRouter } from "react-router-dom";
import { GDPR_REPORT_STATUS_LIST } from "src/modules/data-protection/constants/Common";
import ReportIncidenService from "../components/api/ReportIncidenService";

const IncidentTable = (props) => {
  const history = useHistory();

  const [tableResultTotalCount, setTableResultTotalCount] = useState(0);
  const [reportList, setReportList] = useState([]);
  const [categories, setCategories] = useState([]);

  const { t, handleFetchReportListQuery, tableFilters, setTableFilters, tab } = props;

  const dateUtils = new DateUtils();

  const handleFetchCategories = useQuery({
    queryKey: ["report-incident-fetch-categories"],
    queryFn: async () => {
      const service = ReportIncidenService.getInstance();

      return await service.FetchCategories();
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      toast(props.t("An error occurred while fetching categories."), {
        type: "error",
      });
    },
  });

  useEffect(() => {
    if (handleFetchCategories.data) {
      setCategories(handleFetchCategories.data);
    }
  }, [handleFetchCategories.data]);

  const handleRenderReportStatus = (statusId) => {
    const status = GDPR_REPORT_STATUS_LIST.find(
      (item) => item.id === parseInt(statusId)
    );
    return (
      <div
        style={{
          fontSize: "10px",
          fontWeight: 700,
          padding: "3px 8px",
          background: status.backgroundColor,
          opacity: status?.opacity || 1,
          color:"#1D1D1D",
          borderRadius:'7px',
          whiteSpace:'nowrap'
        }}
        className={"dt-task-status "}
      >
        {props.t(status.titleKey)}
      </div>
    );
  };

  const handleRenderReportCategories = (reportCategories) => {
    const result =
      categories && categories?.length > 0
        ? categories
            .filter((i) => reportCategories?.includes(i.id))
            .map((i) => props.t(i.title))
        : null;
    return result ? result.join(", ") : null;
  };



  const tableColumns = [
    {
      key: 1,
      dataField: "risk_rate",
      sort:true,
      text: props.t("Risk Rating"),
      formatter: (cellContent, row) => {
        return (
          <span
            style={{ backgroundColor: `#${row.risk}` }}
            className="risk-rate"
          ></span>
        );
      },
    },
    {
      key: 3,
      dataField: "case_id",
      sort:true,
      text: props.t("Incident ID"),
      style: {
        display: "table-cell",
        maxWidth: "200px",
      },
      headerStyle: {
        maxWidth: "200px",
      },
      formatter: (cellContent, row) => {
        return (
          <Link
            className="text-dark"
            to={`/admin/data-protection/incident-register/details/${row.case_id}`}

          >
            {row.secondary_case_id}
          </Link>
        );
      },
    },
    {
      key: 4,
      dataField: "category",
      sort:true,
      style: {
        display: "table-cell",
        maxWidth: "200px",
      },
      headerStyle: {
        maxWidth: "200px",
      },
      text: props.t("Category"),
      formatter: (cellContent, row) => {
        return <div>{handleRenderReportCategories(row?.admin_category)}</div>;
      },
    },
    {
      key: 5,
      dataField: "admin_description",
      sort:true,
      text: props.t("Description"),
      style: {
        display: "table-cell",
        maxWidth: "200px",
      },
    },
    {
      key: 6,
      dataField: "submission_date",
      sort:true,
      text: props.t("Submission date"),
      formatter: (cellContent, row) => {
        return (
          <div>
            {dateUtils.convertTimeStampToDate(
              cellContent,
              INTERNATIONAL_DATE_FORMAT
            )}
          </div>
        );
      },
    },
    {
      key: 7,
      dataField: "closing_date",
      sort:true,
      text: props.t("Closing date"),
      formatter: (cellContent, row) => {
        return (
          <div>
            {dateUtils.convertTimeStampToDate(
              cellContent,
              INTERNATIONAL_DATE_FORMAT
            )}
          </div>
        );
      },
    },
    {
      key: 8,
      dataField: "analyst_assigned",
      text: props.t("Assigned analyst"),
      sort:true,
      formatter: (cellContent, row) => {
        if (!cellContent || Array.isArray(cellContent)) return null;
        if (cellContent?.avatar && cellContent?.avatar !== "") {
          // avatar exists and is not an empty string
          return (
            <div className="d-flex gap-1 align-items-center">
            <div className="avatar-xs ">
              <span
                className="avatar-title bg-soft-primary text-primary rounded-circle text-capitalize"
                style={{ overflow: "hidden" }}
              >
                <img
                  alt={cellContent.first_name}
                  src={cellContent?.avatar}
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "block",
                    objectFit: "cover",
                  }}
                />
              </span>
              
            </div>
            {' '}{`${cellContent.first_name} ${cellContent.last_name}`}</div>
          );
      }
        return (
          <div><span className='dt-list-col-bullet'>{cellContent.first_name[0]}</span>{' '}{`${cellContent.first_name} ${cellContent.last_name}`}</div>
        );
      },
    },
    {
      key: 9,
      dataField: "status",
      text: props.t("Status"),
      sort:true,
      formatter: (cellContent, row) => {
        return (
          <div>
            {handleRenderReportStatus(row.status)}
          </div>
        );
      },
    },
  ];

  const NoDataIndication = () =>
    handleFetchReportListQuery.isFetched &&
    !handleFetchReportListQuery.length ? (
      <div className="alert m-0" role="alert">
        <p
          style={{
            textAlign: "center",
            marginBottom: 0,
          }}
        >
          {props.t(EMPTY_LIST)}
        </p>
      </div>
    ) : (
      <></>
    );

  

  useEffect(() => {
    if (handleFetchReportListQuery.data) {
      setReportList(handleFetchReportListQuery.data.reports || []);
      setTableResultTotalCount(
        handleFetchReportListQuery.data?.itemsCount || 0
      );
    }
  }, [handleFetchReportListQuery.data]);

  const onTableFilterOkButtonClicked = (filters) => {
    let result = {};
    for (const tableFilterKey in tableFilters) {
      if (tableFilterKey in filters) {
        if (tableFilterKey === "dateFrom" || tableFilterKey === "dateTo") {
          result[tableFilterKey] =
            filters[tableFilterKey] !== null
              ? dateUtils.convertDateToDate(
                  new Date(filters[tableFilterKey]),
                  "YYYY-MM-DD"
                )
              : null;
        } else {
          result[[tableFilterKey]] = filters[tableFilterKey];
        }
      } else {
        result[[tableFilterKey]] = tableFilters[tableFilterKey];
      }
    }
    setTableFilters(result);
  };

  const handleTableChange = (type, data) => {
    switch (type) {
      case "pagination":
        const { page, sizePerPage } = data
        setTableFilters({
          ...tableFilters,
          pageIndex: page,
          pageSize: sizePerPage,
        });
        break;
        case 'sort': {
          const { sortField, sortOrder } = data
          setTableFilters({
            ...tableFilters,
            sortField: sortField,
            sortOrder: sortOrder,
          });
 
          break
      }
      default:
        return false;
    }
  };

  const handleFetchAnalystsAndAnalystAdmins = useQuery({
    queryKey: ["fetch-supplier-Analys-Admins"],
    queryFn: async () => {
      const service = ReportIncidenService.getInstance();

      return await service.FetchAnalystsAndAnalystAdmins();
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      toast(t("An error occurred while fetching owners."), {
        type: "error",
      });
    },
  });


const handleFetchRisks = useQuery({
    queryKey: ["fetch-risks"],
    queryFn: async () => {
      const service = ReportIncidenService.getInstance();

      return await service.FetchRiskRating();
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      toast(t("An error occurred while fetching managers."), {
        type: "error",
      });
    },
  });

  

  return (
    <div className="p-4" style={{ marginBottom: "300px" }}>
      <PaginationProvider
        pagination={paginationFactory({
          custom: true,
          page: tableFilters.pageIndex,
          sizePerPage: tableFilters.pageSize,
          totalSize: tableResultTotalCount,
          withFirstAndLast: false,
          alwaysShowAllBtns: true,
          prePageText: (
            <span>
              <i className="ri-arrow-left-s-line"></i> {props.t("Back")}
            </span>
          ),
          nextPageText: (
            <span>
              {props.t("Next")} <i className="ri-arrow-right-s-line"></i>
            </span>
          ),
          prePageTitle: props.t("Pre page"),
          firstPageTitle: props.t("Next page"),
          showTotal: true,
          hideSizePerPage: false,
          sizePerPageList: [
            {
              text: "25",
              value: 25,
            },
            {
              text: "50",
              value: 50,
            },
          ],
        })}
      >
        {({ paginationProps, paginationTableProps }) => {
          const { analystAssigned, risk, adminCategories } = tableFilters;

          const filteredAnalystAssigned = handleFetchAnalystsAndAnalystAdmins?.data?.filter(analyst => analyst?.id === analystAssigned)[0];
          const fileteredRisk = handleFetchRisks?.data?.filter(r => r.id === risk)[0]?.name;
          let selectedCategories = null
          if(adminCategories){
             selectedCategories = categories?.filter(category=> adminCategories.includes(category.id));
          }

        return (<React.Fragment>
            <Row className="mb-5 mt-3" >
              <Col sm="12" style={{display: "flex", justifyContent: "flex-end"}}>
                <div className="d-flex gap-4 justify-content-end me-4">
                  <div>
                    {
                      <TableFilter
                        defaultValues={{
                          ...tableFilters,
                        }}
                        categories={categories}
                        onOkButtonClicked={onTableFilterOkButtonClicked}
                        type={props.type}
                        handleFetchAnalystsAndAnalystAdmins={handleFetchAnalystsAndAnalystAdmins}
                        handleFetchRisks={handleFetchRisks}
                        tab={tab}
                      />
                    }
                    <div className="d-flex justify-content-end mt-2" hidden={!filteredAnalystAssigned && !fileteredRisk}>
                      <ul style={{
                              maxWidth:"312px"
                            }}>
                          <li hidden={!filteredAnalystAssigned}>{t("Analyst")}: {`${filteredAnalystAssigned?.first_name} ${filteredAnalystAssigned?.last_name}`}</li>
                          <li hidden={!fileteredRisk}>{t("Risk")}: {t(fileteredRisk)}</li>
                          <li hidden={!selectedCategories?.length}>{t("Categories")}: {`${selectedCategories?.map((category)=> category.title).join(", ")}`}</li>

                      </ul>
                    </div>
                  </div>
                </div>
                <div>
                  <Button
                      color="primary"
                      onClick={() => history.push('/admin/data-protection/incident-register/create')}
                    >
                      {props.t("Add A New Incident")}
                  </Button>
                </div>
                
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <BootstrapTable
                  remote={{
                    pagination: true,
                    filter: false,
                    sort: true,
                    cellEdit: false,
                    search: false,
                  }}
                  loading={
                    handleFetchReportListQuery.isFetching ||
                    handleFetchReportListQuery.isLoading ||
                    handleFetchCategories.isLoading ||
                    handleFetchCategories.isFetching
                  }
                  overlay={overlayFactory({
                    spinner: (
                      <Spinner animation="border" variant="primary" size="md" />
                    ),
                    text: "Loading...",
                  })}
                  onTableChange={handleTableChange}
                  defaultSorted={[]}
                  keyField={"id"}
                  responsive
                  bordered={false}
                  data={reportList}
                  striped={true}
                  columns={tableColumns}
                  wrapperClasses="table-responsive"
                  classes={"table tpdd-table incident-table"}
                  headerWrapperClasses={"thead-light"}
                  style={{
                    overflowX: "auto",
                  }}
                  noDataIndication={() => <NoDataIndication />}
                  {...paginationTableProps}
                />
              </Col>
            </Row>

            <Row>
              <Col sm="12" md="6">
                <div className="tpdd-pagination-style-1">
                  <PaginationListStandalone {...paginationProps} />

                  <SizePerPageDropdownStandalone {...paginationProps} />
                </div>
              </Col>
            </Row>
          </React.Fragment>
        )}}
      </PaginationProvider>
    </div>
  );
};

const mapStatetoProps = (state) => {
  const { token } = state.Login;
  return {
    token,
  };
};

export default withNamespaces()(
  withRouter(connect(mapStatetoProps)(memo(IncidentTable)))
);
