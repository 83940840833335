import React, { Component } from "react";
import { Editor } from "react-draft-wysiwyg";
import { Button } from "reactstrap";
import { MdContentCopy, MdContentPaste } from "react-icons/md";

class AiTranscriptEditor extends Component {
  render() {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            position: "relative",
            flex: 1,
            marginLeft: "1rem",
            margin: "0",
          }}
        >
          <Editor
            editorState={this.props.editorState} // Use editorState from props
            readOnly
            onEditorStateChange={this.props.onEditorStateChange}
            toolbar={this.props.toolbarOptions}
            // toolbar={{options: []}}
            placeholder={this.props.placeholder}
            // toolbarHidden={true}
          />
          {/* Rest of the component remains the same */}
          <div
            style={{
              position: "absolute",
              top: "0",
              right: "0",
              padding: "5px", // Add padding to adjust button size
              backgroundColor: "rgba(255, 255, 255, 0.8)", // Optional: Add a background color
              borderRadius: "4px", // Optional: Add border radius
            }}
            hidden={this.props.isTPOrEmployee}
          >
            <Button
              color="primary"
              onClick={() => this.props.onCopyContent(this.props.editorState)}
              style={{
                width: "32px", // Adjust the button width
                height: "32px", // Adjust the button height
                padding: "0",
                margin: "0", // Ensure no extra margin
                border: "none",
                background: "none",
                cursor: "pointer",
              }}
            >
              <MdContentCopy />
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

class ManualTranscriptEditor extends Component {
  constructor(props) {
    super(props);
    this.editorRef = React.createRef();
  }

  componentDidMount = () => {
    if (!this.props.editorState) {
      const { voiceId } = this.props;
      const fetched = this.props.manualEditorState.filter(
        (s) => s.voice_id === voiceId
      )?.[0]?.manualEditorState;
      if (fetched) {
        this.onEditorStateChange(fetched);
      }
    }
    // console.log("Voice Id", this.props)
  };

  onEditorStateChange = (newEditorState) => {
    // Get the voice_id for this row from the props or any other source
    const voiceId = this.props.voiceId;

    // Call the callback function to update the state in the parent component
    this.props.onManualTranscriptChange(voiceId, newEditorState);
  };

  handlePasteContent = () => {
    const { onPasteContent } = this.props;
    const editor = this.editorRef.current;

    if (editor) {
      // Focus the editor programmatically
      editor.focus();

      // Execute the paste action
      if (onPasteContent) {
        onPasteContent();
      }
    }
  };

  render() {
    // console.log("MANUAL EDITOR STATE", this.props.manualEditorState);
    return (
      <div
        style={{
          position: "relative",
          flex: 1,
          marginRight: "1rem",
          margin: "0",
          padding: "0",
        }}
      >
        <div>
          {this.props.isTPOrEmployee && !this.props.editorState ? (
            <div
              className="alert alert-warning"
              role="alert"
            >
              <p>{this.props.t("There is no manual transcription")}</p>
            </div>
          ) : (
            <Editor
              editorState={this.props.editorState}
              toolbar={this.props.toolbarOptions}
              onEditorStateChange={this.onEditorStateChange}
              placeholder={this.props.placeholder}
              ref={this.editorRef}
              readOnly={this.props.isTPOrEmployee}
              toolbarHidden={this.props.isTPOrEmployee}
            />
          )}
        </div>

        <div
          style={{
            position: "absolute",
            top: "0",
            right: "0",
            padding: "5px", // Add padding to adjust button size
            backgroundColor: "rgba(255, 255, 255, 0.8)", // Optional: Add a background color
            borderRadius: "4px", // Optional: Add border radius
          }}
          hidden={this.props.isTPOrEmployee}
        >
          <Button
            color="primary"
            onClick={this.props.onPasteContent}
            style={{
              width: "32px", // Adjust the button width
              height: "32px", // Adjust the button height
              padding: "0", // Add !important to ensure it takes precedence
              margin: "0", // Add !important to ensure it takes precedence
              border: "none",
              background: "none",
              cursor: "pointer",
            }}
          >
            <MdContentPaste />
          </Button>
        </div>
      </div>
    );
  }
}

export { AiTranscriptEditor, ManualTranscriptEditor };
