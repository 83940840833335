import { memo, useEffect, useState } from 'react';
import { Col, Row, Label, Tooltip} from 'reactstrap';
import { AvField } from 'availity-reactstrap-validation';
import { withNamespaces } from 'react-i18next';
import Switcher from '../../../Switcher';
import './style.scss';
import AiFlagWithTooltip from 'src/modules/data-protection/components/AiFlagWithTooltip';

const Textbase = (props) => {
    const {
        id, 
        title, 
        type,
        isMandatory, 
        key,
        elRefIdPrefix,
        aiSuggestion,
        helpText,
        defaultValue,
        handleChange,
        isAIFilled,
    } = props;

    const [isAISwitcherToggled, setAISwitcherToggled] = useState(false);
    const [inputValue, setInputValue] = useState(defaultValue || '');
    const [helpTooltipTextStatus, setHelpTooltipTextStatus] = useState(false);

    useEffect(() => {
        if(isAISwitcherToggled && aiSuggestion){
            setInputValue(props.t(aiSuggestion));
        }
        else{
            setInputValue(defaultValue || '');
        }
    }, [isAISwitcherToggled]);

    return (
        <Row key={key}>
             <Col sm="12">
                <div className="mb-3 position-relative">
                    <Label
                        className="form-label"
                        htmlFor={`${elRefIdPrefix}-question-${id}`}>
                        {props.t(title)}

                        {
                            helpText && (
                                <span className="ms-2 tooltip-container">
                                    <i
                                        className="fa fa-info-circle help-text-icon"
                                        aria-hidden="true"
                                        id={`${elRefIdPrefix}-question-${id}-help-text`}
                                    />
                                    <Tooltip
                                        style={{
                                            backgroundColor: '#899ba1',
                                            fontWeight: 'bold'
                                        }}
                                        placement="top"
                                        isOpen={ helpTooltipTextStatus }
                                        target={`${elRefIdPrefix}-question-${id}-help-text`}
                                        toggle={() =>
                                            setHelpTooltipTextStatus(!helpTooltipTextStatus)
                                        }>
                                            {
                                                <p>
                                                    {props.t(helpText)}
                                                </p>
                                            }
                                    </Tooltip>
                                </span>
                            )
                        }
                    </Label>
                     {/* <AvField
                        name={"question[" + id + "]"}
                        placeholder=""
                        type={type}
                        errorMessage={props.t(
                            "This field cannot be blank"
                        )}
                        // className="form-control with-ai-suggestion-switcher "
                        validate={{
                            required: { value: isMandatory },
                        }}
                        style={{backgroundColor: "red !important"}}
                        value={inputValue}
                        onChange={(e) => {
                            handleChange(e.target.value)
                            setInputValue(e.target.value)
                        }}
                        id={`${elRefIdPrefix}-question-${id}`} />
                        {
                            aiSuggestion ? 
                            <Switcher
                                className="ai-suggestion-switcher"
                                isToggled={isAISwitcherToggled}
                                onToggle={() => setAISwitcherToggled(!isAISwitcherToggled)} />  
                                
                                
                                : null
                        } */}
                        <div className="input-container">
                            <AvField
                                name={"question[" + id + "]"}
                                placeholder=""
                                type={type}
                                errorMessage={props.t("This field cannot be blank")}
                                validate={{
                                    required: { value: isMandatory },
                                }}
                                value={inputValue}
                                onChange={(e) => {
                                    handleChange(e.target.value);
                                    setInputValue(e.target.value);
                                }}
                                id={`${elRefIdPrefix}-question-${id}`}
                            />
                            <AiFlagWithTooltip  isAIFilled={isAIFilled} />
                            {aiSuggestion &&
                                <Switcher
                                    className="ai-suggestion-switcher"
                                    isToggled={isAISwitcherToggled}
                                    onToggle={() => setAISwitcherToggled(!isAISwitcherToggled)}
                                />
                            }
                        </div>
                        
                </div>
            </Col>
        </Row>
    );
}

export default withNamespaces()(memo(Textbase));