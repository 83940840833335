import { withNamespaces } from "react-i18next";
import { Text, View } from "@react-pdf/renderer";
import styles from "../../Styles";
import DateUtils from "src/services/utils/DateUtils";
import { INTERNATIONAL_DATE_FORMAT } from "src/components/constants";

const Locations = ({
    t,
    locations
}) => {
    const dateUtils = new DateUtils();
    return (
        <View style={styles.section}>
            <Text style={styles.alphListItem}>
                {`${t('a')}) ${t('Factory Sites / Branches')}`}
            </Text>

            <View style={styles.table} break>
                <View style={styles.tableRow}>
                    <View style={styles.tableColHeader}>
                        <Text style={{
                            ...styles.tableCell,
                            ...styles.fontBold
                        }}>{t('Type')}</Text>
                    </View>
                    <View style={{
                        ...styles.tableColHeader,
                        ...styles.borderRightNone
                    }}>
                        <Text style={{
                            ...styles.tableCell,
                            ...styles.fontBold
                        }}>{t('Location')}</Text>
                    </View>
                    <View style={{
                        ...styles.tableColHeader,
                        ...styles.borderRightNone
                    }}>
                        <Text style={{
                            ...styles.tableCell,
                            ...styles.fontBold
                        }}>{t('Potal Code')}</Text>
                    </View>
                    <View style={{
                        ...styles.tableColHeader,
                        ...styles.borderRightNone
                    }}>
                        <Text style={{
                            ...styles.tableCell,
                            ...styles.fontBold
                        }}>{t('Street, Number')}</Text>
                    </View>
                    <View style={{
                        ...styles.tableColHeader,
                        ...styles.borderRightNone
                    }}>
                        <Text style={{
                            ...styles.tableCell,
                            ...styles.fontBold
                        }}>{t('Added On')}</Text>
                    </View>
                </View>

                {locations.map((location, i) => {
                    const rowStyle = {
                        ...styles.tableRow,
                        ...(((i + 1) >= locations.length) && {...styles.tableLastRow})
                    }

                    return (
                        <View style={rowStyle} key={i}> 
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {location.type === 'manufacture' ? t('Factory') : t('Office')}
                                </Text>
                            </View>

                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {location.city}, {t(location.countryName)}
                                </Text>
                            </View>

                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {location.postalCode}
                                </Text>
                            </View>

                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {location.address}
                                </Text>
                            </View>

                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {location.createdAt && dateUtils.convertDateToDate(location.createdAt, INTERNATIONAL_DATE_FORMAT)}
                                </Text>
                            </View>
                        </View>
                    )
                })}
            </View>
        </View>
    )
};

export default withNamespaces()(Locations);

