import axios from 'axios';
import { API_BASE_URL } from '../constants';

export default class DataProtectionDPIAService {
	static instance;
	resource = `${API_BASE_URL}/dpia/`;

	constructor() {}

	static getInstance() {
		if (!DataProtectionDPIAService.instance) {
			DataProtectionDPIAService.instance = new DataProtectionDPIAService();
		}

		return DataProtectionDPIAService.instance;
	}

	async questions(type, softwareId, params){
		const response = await axios.get(`${this.resource}${type}/detail?softwareId=${softwareId}`, {
			params: params,
			headers: {
				Authorization: `Bearer ${localStorage.getItem('LOCAL_STORAGE_KEY_ACCESS_TOKEN')}`,
			}
		});
		return response.data.data || [];
	}
}
