const Score = ({
    t,
    value,
    outOf,
    displayNumericValue,
    width,
    height,
    className
}) => {
    value = value || 0;
    outOf = outOf || 10;

    const style = {};

    if(width){
        style.width = `${width}px`;
    }

    if(height){
        style.height = `${height}px`;
    }

    const items = [];

    for (let index = 0; index < outOf; index++) {
        items.push(
            <div className={`score-item ${index < value ? 'fill' : ''}`}></div>
        )
    }

    return (
        <div className={`score${className ? ` ${className}` : ''}`}>
            <div style={style} className="items">
                { items }
            </div>
            {
                displayNumericValue && (
                    <div className="numeric-value">
                        <span>{`${value}/${outOf}`}</span>
                    </div>
                )
            }
        </div>
    );
}

export default Score;