import { Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import React, { useState, useEffect, memo } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import { connect } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import overlayFactory from "react-bootstrap-table2-overlay";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import axios from "axios";
import { INTERNATIONAL_DATE_FORMAT } from "src/common/constants";

import { EMPTY_LIST } from "../table-filter/constants";
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import TableFilter from "../table-filter";

import { Button, Row, Col, Alert } from "reactstrap";

import DateUtils from "src/services/utils/DateUtils";
import { withRouter } from "react-router-dom";
import UserUtils from "src/services/utils/UserUtils";
import {
  API_URL_ADMIN_GET_USER_MANAGEMENT_LIST,
  API_URL_ADMIN_DELETE_USER_MANAGEMENT,
  API_URL_CUSTOMER_API,
} from "src/common/constants/ApiRoutes";
import { isFeatureEnabledForActiveModule } from "src/helpers/module_helper";
import SweetAlert from "react-bootstrap-sweetalert";

const UserTable = (props) => {
  const { modules } = props;
  const [tableFilters, setTableFilters] = useState({
    pageIndex: 1,
    pageSize: 25,
    name: "",
    role: [],
  });
  const [userIdToDelete, setUserIdToDelete] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const [tableResultTotalCount, setTableResultTotalCount] = useState(0);
  const [users, setUsers] = useState([]);

  const dateUtils = new DateUtils();
  const userUtils = new UserUtils(props.user);

  const handleRenderReportStatus = (status, id) => {
    if (status === "ACTIVE") {
      return (
        <Button
          color="success"
          size="sm"
          onClick={() => updateUserStatus(id, "INACTIVE")}
        >
          {props.t("Active")}
        </Button>
      );
    }
    return (
      <Button
        color="danger"
        size="sm"
        onClick={() => updateUserStatus(id, "ACTIVE")}
      >
        {props.t("Inactive")}
      </Button>
    );
  };

  const tableColumns = [
    {
      key: 1,
      dataField: "first_name",
      text: props.t("First name"),
      sort: true,
    },
    {
      key: 2,
      dataField: "last_name",
      text: props.t("Last name"),
      sort: true,
    },
    {
      key: 3,
      dataField: "email",
      text: props.t("Email"),
      style: {
        display: "table-cell",
        maxWidth: "200px",
      },
      headerStyle: {
        maxWidth: "200px",
      },
      sort: true,
    },
    {
      key: 4,
      dataField: "position",
      style: {
        display: "table-cell",
        maxWidth: "200px",
      },
      headerStyle: {
        maxWidth: "200px",
      },
      text: props.t("position"),
      sort: true,
    },
    {
      key: 5,
      dataField: "roles",
      text: props.t("Assigned Role"),
      style: {
        display: "table-cell",
        maxWidth: "200px",
      },
      sort: false,
      formatter: (cellContent, row) => {
        return <div>{row.roles.map((i) => userUtils.gerUserRole(i))}</div>;
      },
    },
    {
      key: 6,
      dataField: "created_at",
      sort: true,
      text: props.t("Created on"),
      formatter: (cellContent, row) => {
        return (
          <div>
            {dateUtils.convertTimeStampToDate(
              cellContent,
              INTERNATIONAL_DATE_FORMAT
            )}
          </div>
        );
      },
    },
    {
      key: 7,
      dataField: "updated_at",
      sort: true,
      text: props.t("Updated on"),
      formatter: (cellContent, row) => {
        return (
          <div>
            {dateUtils.convertTimeStampToDate(
              cellContent,
              INTERNATIONAL_DATE_FORMAT
            )}
          </div>
        );
      },
    },
    {
      key: 8,
      dataField: "organization_name",
      text: `${props.t("Company")} ${props.t("name")}`,
    },
    {
      key: 9,
      dataField: "status",
      text: props.t("Status"),
      formatter: (cellContent, row) => {
        return <div>{handleRenderReportStatus(row.status, row.id)}</div>;
      },
    },
    {
      key: 10,
      dataField: "edit",
      text: props.t("Edit"),
      formatter: (cellContent, row) => {
        return (
          <div className="d-flex gap-0">
            <Link to={`/admin/administration/users/${row.id}/edit`}>
              <Button color="link" className="waves-effect ">
                <i className="ri-edit-2-fill"></i>
              </Button>
            </Link>
            {isFeatureEnabledForActiveModule("delete-user") && (
              <Button
                onClick={() => setUserIdToDelete(row.id)}
                color="link"
                className="waves-effect  text-danger"
              >
                <i className="ri-delete-bin-fill"></i>
              </Button>
            )}
          </div>
        );
      },
    },
  ];

  const handleFetchUsersQuery = useQuery({
    queryKey: [
      "user-management-fetch-list",
      tableFilters.name,
      tableFilters.role,
    ],
    queryFn: async () => {
      const { name, role } = tableFilters;
      try {
        const response = await axios.post(
            API_URL_ADMIN_GET_USER_MANAGEMENT_LIST,
          {
            name,
            role: role?.map((r) => r.value),
          },
          {
            headers: {
              Authorization: `Bearer ${props.token}`,
            },
          }
        );
        return response.data?.data || [];
      } catch (error) {
        throw new Error("An error occurred while fetching user list.");
      }
    },
    config: {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      onError: (error) => {
        toast("An error occurred while fetching user list.", {
          type: "error",
        });
      },
    },
  });

  useEffect(() => {
    if (handleFetchUsersQuery.data) {
      const users = handleFetchUsersQuery.data;
      setUsers(users);
      setTableResultTotalCount(handleFetchUsersQuery.data?.length || 0);
    }
  }, [handleFetchUsersQuery.data]);

  const deleteUserMutation = useMutation({
    mutationFn: async (userId) => {
      const url = API_URL_ADMIN_DELETE_USER_MANAGEMENT;
      const data = { user_id: userId };
      const header = {
        headers: {
          Authorization: `Bearer ${props.token}`,
        },
      };

      await axios.post(url, data, header);
    },
    onMutate: () => {
      setIsDeleting(true);
    },
    onSuccess: () => {
      toast(props.t("User deleted successfully"), {
        type: "success",
      });
      setUsers((prevUsers) =>
        prevUsers.filter((item) => item.id !== userIdToDelete)
      );
      setUserIdToDelete(null);
      handleFetchUsersQuery.refetch();
    },
    onError: (error) => {
      if (axios.isAxiosError(error)) {
        if (error.response?.data?.error === "user_has_uncompleted_tasks") {
          toast(props.t("This employee has some uncompleted tasks."), {
            type: "error",
          });
        } else if (error.response) {
          const data = error.response.data;
          if (
            data.error === "unknown_error" &&
            data.message === "Access Denied."
          ) {
            toast(props.t("User cannot be deleted"), { type: "error" });
          } else {
            toast(props.t(data.message), { type: "error" });
          }
        }
      } else {
        toast("An error occurred while deleting the user.", { type: "error" });
      }
    },
    onSettled: () => {
      setIsDeleting(false);
    },
  });

  const updateUserStatusMutation = useMutation({
    mutationFn: async ({ id, status }) => {
      const url = `${API_URL_CUSTOMER_API}user/${id}/change_status`;
      const data = { status };
      const header = {
        headers: {
          Authorization: `Bearer ${props.token}`,
        },
      };

      await axios.post(url, data, header);
    },
    onSuccess: () => {
      toast(props.t("User status updated successfully"), { type: "success" });
      handleFetchUsersQuery.refetch();
    },
    onError: (error) => {
      if (axios.isAxiosError(error) && error.response) {
        const data = error.response.data;
        toast(props.t(data.message), { type: "error" });
      } else {
        toast(props.t("An error occurred while updating the user status"), {
          type: "error",
        });
      }
    },
  });

  const NoDataIndication = () =>
    handleFetchUsersQuery.isFetched && !handleFetchUsersQuery.length ? (
      <div className="alert m-0" role="alert">
        <p
          style={{
            textAlign: "center",
            marginBottom: 0,
          }}
        >
          {props.t(EMPTY_LIST)}
        </p>
      </div>
    ) : (
      <></>
    );

  const onTableFilterOkButtonClicked = (filters) => {
    let result = {};

    for (const tableFilterKey in tableFilters) {
      if (tableFilterKey in filters) {
        result[[tableFilterKey]] = filters[tableFilterKey];
      } else {
        result[[tableFilterKey]] = tableFilters[tableFilterKey];
      }
    }
    setTableFilters(result);
  };

  const handleTableChange = (type, data) => {
    switch (type) {
      case "pagination":
        const { page, sizePerPage } = data;
        setTableFilters({
          ...tableFilters,
          pageIndex: page,
          pageSize: sizePerPage,
        });
        break;
      case "sort": {
        const { sortField, sortOrder } = data;

        const sortedList = [...users].sort((a, b) => {
          if (a[sortField] < b[sortField]) return sortOrder === "asc" ? -1 : 1;
          if (a[sortField] > b[sortField]) return sortOrder === "asc" ? 1 : -1;
          return 0;
        });
        setUsers(sortedList);
        break;
      }
      default:
        return false;
    }
  };

  const deleteUser = () => {
    deleteUserMutation.mutate(userIdToDelete);
  };

  const updateUserStatus = (id, status) => {
    updateUserStatusMutation.mutate({ id, status });
  };

  const paginatedUsers = () => {
    const { pageIndex, pageSize } = tableFilters;
    const startIndex = (pageIndex - 1) * pageSize;
    return users.slice(startIndex, startIndex + pageSize);
  };

  return (
    <div className="p-4" style={{ marginBottom: "300px" }}>
      {userIdToDelete !== null ? (
        <SweetAlert
          title={props.t("Are you sure")}
          warning
          showCancel
          confirmBtnBsStyle="success"
          disabled={isDeleting}
          cancelBtnBsStyle="danger"
          cancelBtnText={props.t("Cancel")}
          confirmBtnText={props.t("OK")}
          onCancel={() => setUserIdToDelete(null)}
          onConfirm={deleteUser}
        >
          {props.t("You won't be able to revert this")}
        </SweetAlert>
      ) : null}

        {isFeatureEnabledForActiveModule("list-users") ? (
            <PaginationProvider
                pagination={paginationFactory({
                custom: true,
                page: tableFilters.pageIndex,
                sizePerPage: tableFilters.pageSize,
                totalSize: tableResultTotalCount,
                withFirstAndLast: false,
                alwaysShowAllBtns: true,
                prePageText: (
                    <span>
                    <i className="ri-arrow-left-s-line"></i> {props.t("Back")}
                    </span>
                ),
                nextPageText: (
                    <span>
                    {props.t("Next")} <i className="ri-arrow-right-s-line"></i>
                    </span>
                ),
                prePageTitle: props.t("Pre page"),
                firstPageTitle: props.t("Next page"),
                showTotal: true,
                hideSizePerPage: false,
                sizePerPageList: [
                    {
                    text: "25",
                    value: 25,
                    },
                    {
                    text: "50",
                    value: 50,
                    },
                ],
                })}
            >
                {({ paginationProps, paginationTableProps }) => (
                    <React.Fragment>
                        <Row className="mb-3 mt-3">
                            <Col sm="12">
                                <div className="d-flex gap-4 justify-content-between">
                                    <div>
                                        <p>
                                        {props.t("Task Owner")}:{" "}
                                        {props.Organization.taskOwnerLicenseCount -
                                            props.Organization.remainingTaskOwnerLicenseCount}
                                        /{props.Organization.taskOwnerLicenseCount}
                                        </p>
                                    </div>
                                    <div className="d-flex gap-3 align-items-center">
                                        <TableFilter
                                        defaultValues={{
                                            ...tableFilters,
                                        }}
                                        onOkButtonClicked={onTableFilterOkButtonClicked}
                                        />
                                        {isFeatureEnabledForActiveModule("ad-settings") && (
                                        <div className="flex-inline text-sm-end">
                                            <Link to="/admin/administration/users/ad-settings">
                                            <Button
                                                type="button"
                                                color="primary"
                                                className="btn  mb-3  "
                                            >
                                                {props.t("AD settings")}
                                            </Button>
                                            </Link>
                                        </div>
                                        )}
                                        {isFeatureEnabledForActiveModule("new-user") && (
                                        <div className="flex-inline text-sm-end">
                                            <Link to="/admin/administration/users/add">
                                            <Button
                                                type="button"
                                                color="success"
                                                className="btn mb-3  "
                                            >
                                                <i className="mdi mdi-plus me-1" />{" "}
                                                {props.t("New user")}
                                            </Button>
                                            </Link>
                                        </div>
                                        )}
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm="12">
                                <BootstrapTable
                                    remote={{
                                    pagination: true,
                                    filter: false,
                                    sort: true,
                                    cellEdit: false,
                                    search: false,
                                    }}
                                    loading={
                                    handleFetchUsersQuery.isFetching ||
                                    handleFetchUsersQuery.isLoading
                                    }
                                    overlay={overlayFactory({
                                    spinner: (
                                        <Spinner
                                        animation="border"
                                        variant="primary"
                                        size="md"
                                        />
                                    ),
                                    text: "Loading...",
                                    })}
                                    onTableChange={handleTableChange}
                                    defaultSorted={[]}
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    data={paginatedUsers()}
                                    striped={true}
                                    columns={tableColumns}
                                    wrapperClasses="table-responsive"
                                    classes={"table tpdd-table incident-table"}
                                    headerWrapperClasses={"thead-light"}
                                    style={{
                                    overflowX: "auto",
                                    }}
                                    noDataIndication={() => <NoDataIndication />}
                                    {...paginationTableProps}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col sm="12" md="6">
                                <div className="tpdd-pagination-style-1">
                                <PaginationListStandalone {...paginationProps} />

                                <SizePerPageDropdownStandalone {...paginationProps} />
                                </div>
                            </Col>
                        </Row>
                    </React.Fragment>
                )}
            </PaginationProvider>
        ) : (
            <Alert className="mb-4" color="warning">
                {props.t(
                "You do not have permission to access these pages"
                )}
            </Alert>
        )}
    </div>
  );
};

const mapStatetoProps = (state) => {
  const { token, user } = state.Login;
  const { Organization, Modules } = state;
  return {
    token,
    user,
    Organization,
    modules: Modules
  };
};

export default withNamespaces()(
  withRouter(connect(mapStatetoProps)(memo(UserTable)))
);
