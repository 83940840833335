import {
    ROUTE_PATH_THIRDPARTY_HOME,
    ROUTE_PATH_THIRD_PARTY_REPORTS_LIST,
    ROUTE_PATH_THIRD_PARTY_BREACH_INCIDENT_REQUEST,
    ROUTE_PATH_THIRD_PARTY_DATA_SUBJECT_REQUEST
} from "src/modules/data-protection/constants";

import {
    ROUTE_PATH_THIRD_PARTY_DOCUMENTS,
    ROUTE_PATH_THIRD_PARTY_E_LEARNING,
    ROUTE_PATH_THIRD_PARTY_TUTORIAL
} from "src/common/constants";

const ThirdPartyMenuItems = [
    {
        titleKey    :   "Home",
        linkType    :   "react-router",
        path        :   ROUTE_PATH_THIRDPARTY_HOME,
        icon        :   "ri-home-4-line",
        authProtected   :   false,
        settingKey      :   null,
        moduleFeatures  :   [],
        roles           :   [],
        customValidator :   null
    },
    {
        titleKey    :   "My reports",
        linkType    :   "react-router",
        path        :   ROUTE_PATH_THIRD_PARTY_REPORTS_LIST,
        icon        :   "ri-mail-line",
        authProtected   :   true,
        settingKey      :   null,
        moduleFeatures  :   [],
        roles           :   [],
        customValidator :   null
    },
    {
        titleKey    :   "Add a new report",
        linkType    :   "react-router",
        path        :   null,
        icon        :   "ri-mail-send-line",
        authProtected   :   false,
        settingKey      :   null,
        roles           :   [],
        moduleFeatures  :   [],
        customValidator :   null,
        items: [
            {
                titleKey    :   "Data Breach Incident",
                linkType    :   "react-router",
                path        :   ROUTE_PATH_THIRD_PARTY_BREACH_INCIDENT_REQUEST,
                authProtected   :   false,
                settingKey      :   null,
                roles           :   [],
                moduleFeatures  :   [],
                customValidator :   null
            },
            {
                titleKey    :   "Other Requests",
                linkType    :   "react-router",
                path        :   ROUTE_PATH_THIRD_PARTY_DATA_SUBJECT_REQUEST,
                moduleFeatures  :   [],
                authProtected   :   false,
                settingKey      :   null,
                roles           :   [],
                customValidator :   null
            }
        ]
    },
    // {
    //     titleKey    :   "Documents",
    //     linkType    :   "react-router",
    //     path        :   ROUTE_PATH_THIRD_PARTY_DOCUMENTS,
    //     icon        :   "ri-book-open-line",
    //     moduleFeatures  :   ['Documents'],
    //     authProtected   :   true,
    //     settingKey      :   null,
    //     roles           :   [],
    //     customValidator :   null
    // },
    // {
    //     titleKey    :   "E-Learning",
    //     path        :   ROUTE_PATH_THIRD_PARTY_E_LEARNING,
    //     icon        :   "ri-video-line",
    //     linkType    :   "react-router",
    //     moduleFeatures  :   [],
    //     authProtected   :   true,
    //     settingKey      :   "isElearning",
    //     roles           :   [],
    //     customValidator :   null
    // },
    // {
    //     titleKey    :   "Tutorial",
    //     path        :   ROUTE_PATH_THIRD_PARTY_TUTORIAL,
    //     icon        :   "ri-video-line",
    //     linkType    :   "react-router",
    //     moduleFeatures  :   [],
    //     authProtected   :   true,
    //     settingKey      :   null,
    //     roles           :   [],
    //     customValidator :   null
    // }
];

export default ThirdPartyMenuItems;