import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Progress, UncontrolledTooltip } from "reactstrap";
import DefaultAvatar from './../../../../assets/images/users/def.png'

import DateUtils from "../../../../services/utils/DateUtils";
import { STANDARD_DATE_FORMAT } from "../../../../components/constants";

import {
  HiChevronDoubleDown,
  HiChevronDoubleUp,
  HiMenuAlt4,
} from "react-icons/hi";

import { memo } from "react";
import { taskStatuses } from "../../../Reporting/TaskManager/constants";
import { handleAddEllipsis } from "../../../../utils/utils";

const CardBox = function (props) {
  const { data, t } = props;

  const dateUtils = new DateUtils();

  const handleGetStatusLabel = (status) => {
    const _status = taskStatuses.find((i) => i.value === status);
    return _status ? _status.label : status;
  };

  const priorityIcon = {
    low: <HiChevronDoubleDown />,
    high: <HiChevronDoubleUp />,
    medium: <HiMenuAlt4 />,
  };

  return (
    <React.Fragment>
      <Card className="task-box">
        <div class="progress-container">
          <div class="progress-bar" style={{ width: "0" }}></div>
        </div>
        <CardBody className="borad-width" style={{padding: "5%"}}>
          <div className="float-end ms-2">
            <span style={{ fontSize: 12 }}>
              {t("Deadline")}:{" "}
              {dateUtils.convertTimeStampToDate(
                data["date"],
                STANDARD_DATE_FORMAT
              )}
            </span>
          </div>
          <div className="mb-3">
            <Link
              to={{
                pathname: `/admin/report/task/${data["id"]}`,
                state: "desiredState",
              }}
              onClick={() => {
                localStorage.setItem("caseId", data["primaryCaseId"]);
              }}
            >
              <span style={{ fontSize: 12 }}>#T{data["secondaryId"]}</span>
            </Link>
          </div>
          <div>
            {data["title"] && (
              <h5 className="font-size-16 mb-4">
                <Link
                  to={{
                    pathname: `/admin/report/task/${data["id"]}`,
                    state: "desiredState",
                  }}
                  onClick={() => {
                    localStorage.setItem("caseId", data["primaryCaseId"]);
                  }}
                >
                  <span className="text-datk">{data["title"]}</span>
                </Link>
              </h5>
            )}
            {data["subtitle"] && (
              <div
                className="mb-4"
                style={{
                  fontSize: 12,
                  borderLeft: "4px solid #eee",
                  paddingLeft: 20,
                  color: "#999",
                }}
              >
                <p className="mb-1">
                  {t("Case ID")}: {data["caseId"]}
                </p>
                <p className="mb-1">
                  {t("Case Title")}: {handleAddEllipsis(data["caseTitle"])}
                </p>
              </div>
            )}
          </div>

          <div className="mb-1">
            <div className="flex justify-content-between">
              <div
                className="d-inline-flex align-items-center mb-0"
                style={{ width: "50%" }}
              >
                <span style={{ marginRight: 12, fontSize: 12 }}>
                  {t("Team")}:{" "}
                </span>
                {data["team"].map((member, key) => (
                  <div
                    className="team-member"
                    key={key}
                    style={{ marginRight: 3 }}
                  >
                    <Link
                      to="#"
                      className="team-member d-inline-block"
                      id={"Owner" + member.id}
                    >
                      <div className="avatar-xs">
                        <span className="avatar-title bg-soft-primary text-primary rounded-circle text-capitalize" style={{overflow:'hidden'}}>
                          <img alt="member's avatar" src={member.avatar? member.avatar: DefaultAvatar} style={{ width: '100%', height: '100%', display:'block', objectFit:'cover' }} />
                        </span>
                      </div>
                    </Link>
                    <UncontrolledTooltip
                      target={"Owner" + member.id}
                      placement="top"
                    >
                      <div
                        className="text-capitalize"
                        style={{ textAlign: "left" }}
                      >
                        {t(member.type)}:
                        <br /> {member.name}
                      </div>
                    </UncontrolledTooltip>
                  </div>
                ))}
              </div>
              <div
                className="d-inline-flex mb-0"
                style={{
                  width: "50%",
                  textAlign: "right",
                  flexDirection: "row-reverse",
                }}
              >
                {data.status && (
                  <span
                    className={` me-1 badge badge-success ${
                      data.status.toLowerCase() === "new"
                        ? "bg-success"
                        : data.status.toLowerCase() === "in_progress"
                        ? "bg-primary"
                        : data.status.toLowerCase() === "closed"
                        ? "bg-danger"
                        : "bg-secondary"
                    }`}
                    style={{
                      height: 18,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {t(handleGetStatusLabel(data.status))}
                  </span>
                )}

                {data.priority && (
                  <span
                    className={`me-1 badge badge-success text-capitalize ${
                      data.priority.toLowerCase() === "low"
                        ? "bg-info"
                        : data.priority.toLowerCase() === "medium"
                        ? "bg-success"
                        : data.priority.toLowerCase() === "high"
                        ? "bg-danger"
                        : "bg-secondary"
                    }`}
                    style={{
                      height: 18,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        marginRight: 4,
                        position: "relative",
                        top: -1,
                      }}
                    >
                      {priorityIcon[data.priority]}
                    </span>
                    {t(data.priority)}
                  </span>
                )}
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default memo(CardBox);
