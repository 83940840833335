import { connect } from 'react-redux';

import {
    handlers
} from './modules';

const NotificationsHandler = ({
    modules,
    ...rest
}) => {
    const CurrentModuleHandler = handlers[modules?.active];

    if(!CurrentModuleHandler) return null;

    return (
        <CurrentModuleHandler {...rest} />
    );
}

const mapStatetoProps = (state) => {
	return {
        modules         :   state.Modules
	};
};

const mapDispachToProps = (dispatch) => {
	return { };
};

export default connect(
    mapStatetoProps, mapDispachToProps
)(NotificationsHandler);
