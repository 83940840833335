import axios from 'axios';

import {
    API_BASE_URL
} from '../constants/index';

export default class SupplierFindingsManageKeyWordsServices {
  static instance;

  static getInstance() {
    if (!SupplierFindingsManageKeyWordsServices.instance) {
      SupplierFindingsManageKeyWordsServices.instance = new SupplierFindingsManageKeyWordsServices();
    }

    return SupplierFindingsManageKeyWordsServices.instance;
  }

  async allListOfTags(params) {
    const response = await axios.get(`${API_BASE_URL}/news/all_tags_list`, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
        )}`,
      },
    });

    return response.data.data;
  }


  async listOfTags(params) {
    const response = await axios.get(`${API_BASE_URL}/news/tags_list`, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
        )}`,
      },
    });

    return response.data.data;
  }

  async updateTags(payload) {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/news/edit_tags`,
        { tags: payload },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
            )}`,
          },
        }
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }
}