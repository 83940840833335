import analystLightIcon from "./assets/filter_analyst_light.svg";
import analystBlueIcon from "./assets/filter_analyst_blue.svg";

export const icons = {
  Role: {
    default: analystLightIcon,
    active: analystBlueIcon,
  },
};

export const EMPTY_LIST = "This list is empty";

