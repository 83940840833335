import React, { useState } from "react";
import { withNamespaces } from "react-i18next";
import { Dropdown, DropdownToggle, DropdownMenu, Button } from "reactstrap";
import { Nav, NavItem, NavLink } from "reactstrap";
import { connect } from "react-redux";
import { icons } from "./constants";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { RoleOptions } from "./RoleOptions";

const TableFilter = ({ t, onOkButtonClicked, defaultValues }) => {
  const [filters, setFilters] = useState({
    role: defaultValues.role || [],
    name: defaultValues.name || '',
  });

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("Role");

   const roleOptions = RoleOptions(t);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const renderRoleFilterList = () => {
    const handleRoleChange = (role) => (event) => {
      const { checked } = event.target;
      setFilters((prevFilters) => {
        const roles = checked
          ? [...prevFilters.role, role]
          : prevFilters.role.filter((r) => r.value !== role.value);
  
        return {
          ...prevFilters,
          role: roles,
        };
      });
    };
  
    return (
      <div>
        {roleOptions.map((role) => (
          <div key={role.value} className="filter-item">
            <Form.Check
              checked={filters.role.some((r) => r.value === role.value)}
              onChange={handleRoleChange(role)}
              type="checkbox"
              id={`role-${role.value}-filter`}
              label={role.label}
            />
          </div>
        ))}
      </div>
    );
  };

  const renderNavContent = () => {
    if (activeTab === "Role") {
      return renderRoleFilterList();
    }
    return null;
  };

  const handleNameChange = (event) => {
    const { value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      name: value,
    }));
  };

  const clearNameFilter = () => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      name: '',
    }));
  };

  return (
    <div className="custom-table-filter mb-3">
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle caret color="secondary" outline>
          <i className="ri-filter-3-fill"></i>
          <span>{t("Filter")}</span>
        </DropdownToggle>

        <DropdownMenu  >
          <div className="filters-container">
          <div className="search-input">
          <InputGroup>
            <Form.Control
              placeholder="Search"
              value={filters.name}
              onChange={handleNameChange}
              aria-describedby="search-result-input"
              style={{ borderRight: 'none' }}
            />
            <InputGroup.Text
              className="bg-transparent border-left-0"
              id="search-result-input"
              style={{ borderLeft: 'none', cursor: 'pointer' }}
              onClick={clearNameFilter}
            >
              <i className="ri-close-line"></i>
            </InputGroup.Text>
          </InputGroup>
                </div>
            <div className="navs-container d-flex justify-content-start">
              <Nav tabs>
                <NavItem
                  onClick={() => setActiveTab("Role")}
                  className={`${activeTab === "Role" && "active"}`}
                >
                  <NavLink>
                    <img
                      src={
                        activeTab === "Role"
                          ? icons?.Role?.active
                          : icons?.Role?.default
                      }
                      alt="role-filter"
                    />
                    <span>{t("Role")}</span>
                  </NavLink>
                </NavItem>
              </Nav>
            </div>

            <div className="navs-content-container">{renderNavContent()}</div>

            <div className="actions-container d-flex align-items-center gap-3 justify-content-end">
              <Button
                color="secondary"
                size="sm"
                onClick={() =>
                  setFilters({
                    role: [],
                    search: ''
                  })
                }
              >
                {t("Reset")}
              </Button>

              <Button
                color="primary"
                size="sm"
                onClick={() => {
                  onOkButtonClicked && onOkButtonClicked(filters);
                  toggle();
                }}
              >
                {t("OK")}
              </Button>
            </div>
          </div>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

const mapStatetoProps = (state) => {
  const { token } = state.Login;

  return {
    token,
  };
};

export default withNamespaces()(connect(mapStatetoProps)(TableFilter));
