// @flow
import { all, fork, takeEvery, put } from 'redux-saga/effects';

import { CHANGE_WORKSPACE_EVENT } from './actionTypes';

import { changeWorkspaceAction } from './actions';

/**
 * Changes the workspace type
 * @param {*} param0
 */
function* changeWorkspace({ payload: workspace }) {
	try {
		yield put(changeWorkspaceAction(workspace));
	} catch (error) {}
}

/**
 * Watchers
 */
export function* watchChangeWorkspaceType() {
	yield takeEvery(CHANGE_WORKSPACE_EVENT, changeWorkspace);
}

function* WorkspaceSaga() {
	yield all([fork(watchChangeWorkspaceType)]);
}

export default WorkspaceSaga;
