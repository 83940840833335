import React, { useState } from 'react';
import { withNamespaces } from "react-i18next";
import Select from 'react-select';

/**
 * Renders a multi-select component for selecting multiple items from a list of options.
 * @param {Array} options Array of objects representing the available options.
 *                        Each object should have properties 'id' and 'title'.
 * @param {Array} selectedValues Array of selected values.
 * @param {Function} onChange Function to handle changes in selected values.
 *                            Accepts a new array of selected values as an argument.
 * @param {String} placeholder Placeholder text for the input field.
 * @returns {JSX.Element} JSX representing the MultiSelect component.
 */
const MultiSelect = ({ t, options, selectedValues, onChange, placeholder , isDisabled }) => {
  const [newInputValue, setNewInputValue] = useState("");


  const handleRemove = (index) => {
    const updatedValues = [...selectedValues];
    updatedValues.splice(index, 1); 
    onChange(updatedValues);
  };

  const handleSelectChange = (selected) => {
    if (
        selected &&
        !selectedValues?.some((item) => item.value === selected)
      ) {
        const existingItem = options.find(
          (item) => item.value === selected
        );
        if (existingItem) {
          const updatedValues =  [...selectedValues, existingItem];
          onChange(updatedValues);
          setNewInputValue(""); // Clear the input field
        }
      }
  };

  return (
    <div>
      <Select
        value={newInputValue}
        options={options && options.length> 0 ? options
          ?.filter(
            (option) =>
              !selectedValues?.some((value) => value.value === option.value)
          ) : []}
        onChange={(selected) => {
          setNewInputValue(selected ? selected.value : "");
          handleSelectChange(selected ? selected.value : "");
        }}
        noOptionsMessage={({ inputValue }) => t("No more Options")}
        placeholder={placeholder}
        isClearable
        isSearchable
        className="selected-input"
        isDisabled={isDisabled}
        styles={{
            control: (baseStyles) => ({
                ...baseStyles,
                minWidth          :   '220px',
                width             :   '100%',
                backgroundColor   :   'transparent',
                padding           :   '4px',
                borderColor       :   '#21252980'
            })
        }}
      />
      <div className='mt-1'>
        {selectedValues && selectedValues?.length > 0 ? selectedValues.map((value, index) => (
          <div className="tag" key={index} style={{cursor:`${isDisabled ? 'not-allowed': 'pointer'}`}}>
            {t(value.label)}
            <span className="tag-close"  onClick={() => isDisabled ? null: handleRemove(index)}>
              &times;
            </span>
          </div>
        )): null}
      </div>
    </div>
  );
};

export default withNamespaces()(MultiSelect);