import {
    ACTION_CONFIRM_PROMPT_UPDATE_STATE,
    ACTION_CONFIRM_PROMPT_MAKE_READY_TO_SHOW,
    ACTION_CONFIRM_PROMPT_DISPLAY_PROMPT,
    ACTION_CONFIRM_PROMPT_HIDE_PROMPT,
    ACTION_CONFIRM_PROMPT_UPDATE_CONFIRM_STATUS,
    ACTION_CONFIRM_PROMPT_UPDATE_ALERT_PROPS
} from './actionTypes';

const initialState = {
    confirmStatus   :   undefined,
    readyToShow     :   false,
    show            :   false,
    title           :   '',
    message         :   '',
    callbackFunc    :   null,
    alertProps      :   {
        confirmBtn  :   {
            enabled :   true,
            title   :   'Yes',
            color   :   'primary',
            loading :   false
        },
        cancelBtn   :   {
            enabled :   true,
            title   :   'No',
            color   :   'secondary',
            loading :   false
        }
    }
};

export const ExitConfirmPrompt = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_CONFIRM_PROMPT_UPDATE_STATE:
            return {
                ...state,
                confirmStatus   :   action.payload.confirmStatus,
                readyToShow     :   action.payload.readyToShow,
                show            :   action.payload.show,
                title           :   action.payload.title,
                message         :   action.payload.message,
                callbackFunc    :   action.payload.callback
            };
        case ACTION_CONFIRM_PROMPT_MAKE_READY_TO_SHOW:
            return {
                ...state,
                readyToShow     :   true,
                message         :   action.payload.message,
                title           :   action.payload.title,
                alertProps      :   action.payload.alertProps
            };
        case ACTION_CONFIRM_PROMPT_DISPLAY_PROMPT:
            return {
                ...state,
                show            :   true,
                callbackFunc    :   action.payload.callback
            };
        case ACTION_CONFIRM_PROMPT_HIDE_PROMPT:
            return {
                ...state,
                confirmStatus   :   undefined,
                readyToShow     :   false,
                show            :   false,
                title           :   '',
                message         :   '',
                callbackFunc    :   null,
                alertProps      :   {
                    ...initialState.alertProps
                }
            };
        case ACTION_CONFIRM_PROMPT_UPDATE_CONFIRM_STATUS:
            return {
                ...state,
                confirmStatus   :   action.payload
            };
        case ACTION_CONFIRM_PROMPT_UPDATE_ALERT_PROPS:
            return {
                ...state,
                alertProps      :   action.payload
            };
        default:
            return state;
    }
};
