import { withNamespaces } from 'react-i18next';
import { 
    Row, 
    Col, 
    Card, 
    CardBody, 
    NavItem, 
    NavLink, 
    CardTitle, 
    Button,
    Alert,
    FormGroup,
    Label,
    Input,
    Spinner
} from 'reactstrap';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { toast } from "react-toastify";
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter, useHistory } from "react-router-dom";  
import {
    API_BASE_URL
} from 'src/modules/3rd-party-management/constants';
import QuestionarConfirmForm from 'src/modules/3rd-party-management/components/questionar/ConfirmForm';
import {
	moduleTPDDResetQuestionnaireState,
	moduleTPDDUpdateQuestionnaireAllQuestionsAnsweredState,
	moduleTPDDUpdateQuestionnaireReportCaseId,
    confirmPromptHide
} from 'src/store/actions';
import FillQuestionnaire from './components/fill-questionnaire';
import { useQuery } from '@tanstack/react-query';
import QuestionnaireService from 'src/modules/3rd-party-management/apis/QuestionnaireService';

const BafaQuestionnaire = ({
    t,
    supplierId,

    moduleTPDDQuestionnaire: questionnaireLatestPayload,

    setReportCaseId,
    setAllQuestionsAnswered,
    resetQuestionnaireState,

    hideConfirmPrompt,

    token
}) => {
    const history = useHistory();

    const {
		reportCaseId
	} = questionnaireLatestPayload;

    const [ questionnaireData, setQuestionnaireData ] = useState({
		reportCaseId	:	null,
		questions		:	[],
		answers			:	null
	});
    
    const [ noReportRequestFound, setNoReportRequestFound ] = useState(false);
    const [ questionnaireLoading, setQuestionnaireLoading ] = useState(true);
    const [ isCreateNewFormSubmiting, setIsCreateNewFormSubmiting ] = useState(false);
    const [ declarationFormConfirmed, setDeclarationFormConfirmed ] = useState(false);
	const [ tabs, setTabs ] = useState([
		{
			name		:	'fill-questions-step',
			title		:	'Fill Questionnaire',
			completed	:	false,
			isCurrent	:	true
		},
		{
			name		:	'review-details',
			title		:	'Review Details',
			completed	:	false,
			isCurrent	:	false
		},
		{
			name		:	'declaration',
			title		:	'Declaration',
			completed	:	false,
			isCurrent	:	false
		}	
	]);

    const setActiveTab = (tab) => {
		setTabs((tabs) => {
			const currentActiveTabIndex = tabs.findIndex((t) => t.isCurrent);
			const nextActiveTabIndex = tabs.findIndex((t) => t.name === tab);

			if(currentActiveTabIndex > -1){
				tabs[currentActiveTabIndex].completed = true;
				tabs[currentActiveTabIndex].isCurrent = false;
			}

			if(nextActiveTabIndex > -1){
				tabs[nextActiveTabIndex].isCurrent = true;
			}

			return [
				...tabs
			];
		});
	}

    const handleFetchPrevFilledAnswers = useQuery({
		queryKey: [
            'tpdd-fetch-bafa-questionnaire-query',
            supplierId
        ],
		queryFn: async () => {
			const service = QuestionnaireService.getInstance();
	
			return await service.fetchPrevFilledAnswers('tpdd', supplierId, {});
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		enabled: true,
		retry: 0,
		onError: ({
			response
		}) => {
			if((response.data.error).toLowerCase() === 'there is not any report for this questionnaire'){
				axios.post(`${API_BASE_URL}/report.create`, {
					"questionnaireType"		:	'short_tpdd_bafa',
                    "supplier"              :   supplierId,
					"questionAnswers"	    :	[]
				}, {
					headers: {
						Authorization: `Bearer ${token}`,
					}
				})
				.then(() => {
                    handleFetchPrevFilledAnswers.refetch();
				})
				.catch(({
                    response
                }) => {
                    setQuestionnaireLoading(false);

                    if((response.data.error).toLowerCase() === 'there is not any report request'){
                        toast(t('It is not possible to send a report before the request is created by admin'), {
                            type: 'error',
                        });

                        setNoReportRequestFound(true);
                    }
                    else{
                        toast(t('An error occurred while starting!'), {
                            type: 'error',
                        });
                    }
				});
			}
		},
		onSuccess : (response) => {
			setReportCaseId(response.reportCaseId);
            setQuestionnaireLoading(false);
		}
	});

    const renderStepsWizard = () => {
		const currentActiveTabIndex = tabs.findIndex((t) => t.isCurrent);

		return (
			<Row>
				<Col sm={12}>
					<div className="m-2 bg-white wizard-steps-container mb-5">
						<div className="twitter-bs-wizard">
							<ul className="twitter-bs-wizard-nav nav-justified nav nav-pills p-3">
								{
									tabs.map((tab, index) => {
										return (
											<NavItem key={ index } className={ tab.completed ? 'completed' : '' }>
												<NavLink className={ (index <= currentActiveTabIndex ? 'active ' : '') + (tab.isCurrent ? 'current' : '') }>
													<span className="step-number">
														{
															tab.completed ? (
																<i className='fa fa-check'></i>
															) : ( index + 1 )
														}
													</span>

													<span className="step-title">
														{t(tab.title)}
													</span>
												</NavLink>
											</NavItem>
										);
									})
								}
							</ul>
						</div>
					</div>
				</Col>
			</Row>
		)
	}

	const renderConfirmForm = () => {
		return (
			<div className="m-2">
				<QuestionarConfirmForm 
					questions={ questionnaireData.questions }
					answers={ questionnaireData.answers }
					isFormSubmiting={ false }
					onConfirmButtonClicked={() => {
						setActiveTab('declaration');
					}}
					onBackButtonClicked={() => {
						setActiveTab('fill-questions-step');
					}}
					settings={{
						buttons	:	{
							back	:	{
								enabled	:	false
							},
							confirm	:	{
								enabled	:	true,
								title	:	'Next'
							}
						}
					}}
				/>
			</div>
		)
	}

    const renderDeclarationTabContent = () => {
		return (
			<div className="declaration-confirm-form m-2 mt-0">
				<Card>
					<CardBody>
						<CardTitle className="mb-3">
							{ t('Declaration of Accuracy and Completeness') }
						</CardTitle>

						<p className="mb-5">
							{
								t('I hereby affirm that the information provided in this questionnaire is true, accurate, and complete to the best of my knowledge and belief. I understand that any false statements, omissions, or misrepresentations may result in consequences as determined by the entity requesting this information (which could include legal actions or disqualification from a process, depending on the context). I commit to notify the relevant parties immediately should any information change after the submission of this questionnaire.')
							}
						</p>

						<div>
							<Form.Check
								onChange={({ target }) => setDeclarationFormConfirmed(target.checked)} 
								type='checkbox' 
								id={`confirm-declaration-checkbox`} 
								label={t('I confirm the information above.')} />
						</div>
					</CardBody>
				</Card>

				<div className="d-flex justify-content-end align-items-center mb-4">
					<Button color="primary"
						disabled={ !declarationFormConfirmed || isCreateNewFormSubmiting }
						onClick={ handleSubmit }
						type="button">
                            {
                                isCreateNewFormSubmiting ? (
                                    <>
                                        <Spinner className="me-2" animation="border" size="sm"/>
                                        {t('Submitting')}...
                                    </>
                                ) : (
                                    <>{t('Submit')}</>
                                )
                            }
					</Button>
				</div>
			</div>
		);
	}

	const handleSubmit = () => {
		setIsCreateNewFormSubmiting(true);

		axios.post(`${API_BASE_URL}/report/${questionnaireData.reportCaseId}/submit`, questionnaireData.answers, {
			headers: {
				Authorization: `Bearer ${token}`,
			}
		})
		.then(() => {
			toast(t('Request Submitted'), {
				type: 'success',
			});

			setTimeout(() => {
                history.push(`/admin/3rd-party/suppliers/${supplierId}/details?tab=reports`)
			}, 250);
		})
		.catch(() => {
			toast(t('Error!'), {
				type: 'error',
			});
		})
		.finally(() => {
			setIsCreateNewFormSubmiting(false);
            hideConfirmPrompt();
		});
	}

    useEffect(() => {
		resetQuestionnaireState();
	}, []);

    useEffect(() => {
		if(questionnaireData.answers){
			setActiveTab('review-details');
		}
	}, [ questionnaireData.answers ]);

    const activeTab = tabs.find((t) => t.isCurrent);

    return (
        <div className="p-4">
            {questionnaireLoading ? (
                <>
                    <div className="dt-field dt-skeleton mb-4" style={{width: '30%'}}></div>
                        <div className="d-flex align-items-center flex-row justify-content-start mb-4">
                            <div className="dt-field dt-skeleton me-2" style={{width: '26px', borderRadius: '50%'}}></div>
                            <div className="w-100">
                                <div className="dt-field dt-skeleton mb-2" style={{width: '50%'}}></div>
                                <div className="dt-field dt-skeleton" style={{width: '90%'}}></div>
                            </div>
                        </div>

                        <div className="d-flex align-items-center flex-row justify-content-start mb-4">
                            <div className="dt-field dt-skeleton me-2" style={{width: '26px', borderRadius: '50%'}}></div>
                            <div className="w-100">
                                <div className="dt-field dt-skeleton mb-2" style={{width: '50%'}}></div>
                                <div className="dt-field dt-skeleton" style={{width: '90%'}}></div>
                            </div>
                        </div>

                        <div className="d-flex align-items-center flex-row justify-content-start mb-4">
                            <div className="dt-field dt-skeleton me-2" style={{width: '26px', borderRadius: '50%'}}></div>
                            <div className="w-100">
                                <div className="dt-field dt-skeleton mb-2" style={{width: '50%'}}></div>
                                <div className="dt-field dt-skeleton" style={{width: '90%'}}></div>
                            </div>
                        </div>

                        <div className="d-flex align-items-center flex-row justify-content-start mb-4">
                            <div className="dt-field dt-skeleton me-2" style={{width: '26px', borderRadius: '50%'}}></div>
                            <div className="w-100">
                                <div className="dt-field dt-skeleton mb-2" style={{width: '50%'}}></div>
                                <div className="dt-field dt-skeleton" style={{width: '90%'}}></div>
                            </div>
                        </div>
                </>
            ) : (
                <>
                    {noReportRequestFound ? (
                        <Alert color="warning" className="d-flex justify-content-start">
                            <div className="d-flex align-items-center me-2">
                                <i className="ri-alert-line" style={{fontSize: '32px'}}/>
                            </div>
                            <div className="d-flex align-items-start justify-content-center flex-column">
                                <strong>{t('Please Pay Attention')}</strong>
                                {t('It is not possible to send a report before the request is created by admin')}
                            </div>
                        </Alert>
                    ) : (
                        <>
                            {/* <Row className='mb-3'>
                                <Col sm='12' md='6' lg='4'>
                                    <FormGroup>
                                        <Label>
                                            {t('Report Type')}
                                        </Label>
                                        <Input type='select'>
                                            <option value='1'>
                                                {t('Abbreviated Reporting Obligation')}
                                            </option>
                                            <option value='2'>
                                                {t('Full Reporting Obligation')}
                                            </option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row> */}

                            <Row>
                                <Col sm='12'>
                                    { renderStepsWizard() }
                                </Col>
                            </Row>
                            
                            <Row>
                                <Col sm='12'>
                                    {activeTab.name === 'fill-questions-step' && (
                                        <FillQuestionnaire 
                                            questions={handleFetchPrevFilledAnswers?.data?.questions || []}
                                            onValidSubmit={ ({
                                                reportCaseId,
                                                questions,
                                                answers
                                            }) => {
                                                setQuestionnaireData({
                                                    reportCaseId	:	reportCaseId,
                                                    questions		:	questions,
                                                    answers			:	answers
                                                });
                                            }}
                                        />
                                    )}

                                    { 
                                        activeTab.name === 'review-details' && renderConfirmForm()
                                    }

                                    {
                                        activeTab.name === 'declaration' && renderDeclarationTabContent()
                                    }
                                </Col>
                            </Row>
                        </>
                    )}
                </>
            )}
        </div>
    )
};

const mapStatetoProps = (state) => {
	const { token, user } = state.Login;
	const { Organization, App, moduleTPDDQuestionnaire, ExitConfirmPrompt } = state;
	
	return {
		token,
		user,
		Organization,
		App,
		moduleTPDDQuestionnaire,
		exitConfirmPromptOptions	:	ExitConfirmPrompt
	};
};

export default withNamespaces()(
	withRouter(
		connect(mapStatetoProps, {
            setReportCaseId         :	(payload) => moduleTPDDUpdateQuestionnaireReportCaseId({
                reportCaseId	    :	payload
            }),
            setAllQuestionsAnswered :	(payload) => moduleTPDDUpdateQuestionnaireAllQuestionsAnsweredState({
                status	:	payload
            }),
            resetQuestionnaireState :	() => moduleTPDDResetQuestionnaireState(),
            hideConfirmPrompt       : 	() => confirmPromptHide()
		})(BafaQuestionnaire)
	)
);