import React, { Component } from "react";
import { Row, Col, Card, CardBody, CardTitle, Tooltip } from "reactstrap";
import { withNamespaces } from "react-i18next";

//Import Charts
import ReactApexChart from "react-apexcharts";

const defaultColors = [
  "#5664d2",
  "#1cbb8c",
  "#eeb902",
  "#FF7F50",
  "#9932CC",
  "#90A4AE",
];

class DonutChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      series: [],
      options: {
        labels: [],
        plotOptions: {
          pie: {
            donut: {
              size: "75%",
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        
        colors: defaultColors,
      },
      months: [
        {
          name: "june",
          title: "June",
        },
      ],
      categories: [],
      topFiveCats: false,
      isSet: false
    };
  }

  render() {
    const { t, title, tooltipText } = this.props;
    const allZero = this.state.series.every(amount => amount === 0);

    const emptySeries = [1]; 
    const emptyOptions = {
        ...this.state.options,
        labels: ['No Data'], 
        colors: ['#e0e0e0'], 
        plotOptions: {
            pie: {
                donut: {
                    size: '75%',
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
        legend: {
            show: false,
        },
    };
    const chartKey = JSON.stringify(this.state.series) + JSON.stringify(this.state.options.labels);
    return (
      <Card className="flex-grow-1 card_box_Shadow">
        <CardBody>
          <div className="mb-4">
            <CardTitle>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>{t(title)}</div>

                {tooltipText ? <>  <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  id="infoIconU"
                />

               <Tooltip
                  placement="top"
                  isOpen={this.state.topFiveCats}
                  target="infoIconU"
                  toggle={() =>
                    this.setState({
                      topFiveCats: !this.state.topFiveCats,
                    })
                  }
                >
                  {t(tooltipText)}
                </Tooltip> 
                </>
                : null}
              </div>
            </CardTitle>
          </div>
              
          <div className="mb-4">
            <div id="donut-chart" className="apex-charts mb-3">
            {
              <ReactApexChart
                  // key={this.state.isSet ? "chart" : "skeleton"} 
                  key={chartKey} 
                  options={allZero ? emptyOptions : this.state.options}
                  series={allZero ? emptySeries : this.state.series}
                  type="donut"
                  height="250"
              />
            }
            </div>

            <div className="d-flex flex-wrap gap-2 justify-content-between align-items-center">
              {this.state.categories.map((category, index) => {
                return (
                  <div key={index} className="d-flex gap-2 align-items-center mt-4">
                    <i
                      className="mdi mdi-circle font-size-10 me-1"
                      style={{ color: defaultColors[index] }}
                    ></i>
                    <p
                      className="mb-0 me-2"
                      style={{
                        margin: 0,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        fontSize: "14px",
                      }}
                    >
                      {t(category.title)}
                    </p>
                    <h6 className="my-0 text-nowrap">{category.percent} %</h6>
                  </div>
                );
              })}
            </div>
          </div>
        </CardBody>
      </Card> 
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.categories !== this.props.categories || this.props.t !== prevProps.t) {
      const categories = this.props.categories;
      const series = categories.map(
        (category) => category.amount
      );
      
    const labels = categories.map((category) => {
      const title = this.props.t(category.title);
      return title.length > 50 ? `${title.substring(0, 50)}...` : title; 
    });
      this.setState({
        categories: categories,
        series: series,
        options: {
          ...this.state.options,
          labels: labels,
        },
        isSet: true
      });
    }
  }

  componentWillUnmount() {
    this.setState({
      ...this.state,
      isSet: "false"
    })
  }
}

export default withNamespaces()(DonutChart);
