import axios from "axios";

class NotificationSettingsService {
  static instance;
  resources = {
    gd: process.env.REACT_APP_GDPR_CUSTOMER_API_ENDPOINT,
    tp: process.env.REACT_APP_TPDD_CUSTOMER_API_ENDPOINT,
  };

  constructor() {}

  static getInstance() {
    if (!NotificationSettingsService.instance) {
      NotificationSettingsService.instance = new NotificationSettingsService();
    }
    return NotificationSettingsService.instance;
  }

  getResource(module) {
    return this.resources[module];
  }

  async notificationSettings(module) {
    const resource = this.getResource(module);
    const response = await axios.get(`${resource}/notificationSetting.list`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
        )}`,
      },
    });

    return this.transformDataByModule(module, response.data.data);
  }

  async toggleNotif(module, data) {
    const resource = this.getResource(module);
    const response = await axios.post(`${resource}/notification.edit`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
        )}`,
      },
    });

    return response.data.data;
  }

  async restoreNotif(module) {
    const resource = this.getResource(module);
    const response = await axios.post(
      `${resource}/notification/restore`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
          )}`,
        },
      }
    );

    return response.data.data;
  }

  transformDataByModule(module, data) {
    switch (module) {
      default:
        return data;
    }
  }
}

export default NotificationSettingsService;
