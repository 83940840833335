import { useRef, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { Card, CardBody, Col, Label, Row, Button, Spinner } from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import Dropzone from "react-dropzone";
import { withRouter, Link } from "react-router-dom";
import { useMutation } from '@tanstack/react-query';
import DocumentsService from 'src/modules/3rd-party-management/apis/DocumentsService';
import { toast } from 'react-toastify';
import { DocumentsTypes } from 'src/modules/3rd-party-management/constants';

const CreateDocument = ({
    onCreateSuccessfull,

    t,
    organization
}) => {
    const [ file, setFile ] = useState({
        showError: false,
        src: null
    })

    const createFormRef = useRef();

    const {
        mutate: mutateCreateDocument,
        isLoading: creatingDocumentInProcess
    } = useMutation({
        mutationFn: async ({
            formData
        }) => {
            return await (DocumentsService.getInstance()).create(formData);
        },
        onSuccess: () => {
            onCreateSuccessfull && onCreateSuccessfull();

            createFormRef?.current && createFormRef.current.reset();

            setFile({
                showError: false,
                src: null
            });

            toast(t("Data saved successfully."), {
                type: "success"
            });
        },
        onError: (error) => {
            const data = error.response.data;

            toast(t(data.message || data.title), {
                type: "error"
            });
        }
    });

    const handleCreateDocumentSubmit = (_e, values) => {
        if(!file.src){
            return setFile({
                showError: true,
                src: null
            });
        }

        const formData = new FormData();
        formData.append('title', values.title);
        formData.append('language', values.language);
        formData.append('type', values.type)
        formData.append('src', file.src)

        mutateCreateDocument({
            formData
        })
    }

    const handleAcceptedFiles = (files) => {
        if(files.length > 0){
            const file = files[0]

            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: file.size,
            });

            setFile({
                showError: false,
                src: file
            })
        }
    }

    return (
        <AvForm 
            className="needs-validation" 
            onValidSubmit={handleCreateDocumentSubmit}
            ref={ createFormRef }
        >
            <Card>
                <CardBody>
                    <Row>
                        <Col xs={6} lg={4}>
                            <div className="mb-3">
                                <Label className="form-label">
                                    {t("Title")}
                                </Label>
                                <AvField
                                    type="text"
                                    name="title"
                                    placeholder=""
                                    className="form-control"
                                    errorMessage={t("This field cannot be blank")}
                                    validate={{ required: { value: true } }}
                                />
                            </div>
                        </Col>
                        
                        <Col xs={6} lg={4}>
                            <div className="mb-3">
                                <Label className="form-label">
                                    {t("Type")}
                                </Label>
                                <AvField 
                                    type="select"
                                    name="type"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    errorMessage={t("This field cannot be blank")}
                                >
                                    <option value="" disabled>
                                        {t("Select an item")}
                                    </option>

                                    {DocumentsTypes.map((dType) => {
                                        return (
                                            <option value={dType.typeKey}>
                                                {t(dType.title)}
                                            </option>
                                        )
                                    })}
                                </AvField>
                            </div>
                        </Col>

                        <Col xs={6} lg={4}>
                            <div className="mb-3">
                                <Label className="form-label">
                                    {t("Language")}
                                </Label>

                                <AvField
                                    type="select"
                                    name="language"
                                    errorMessage={t("This field cannot be blank")}
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                >
                                    <option value="" />
                                    {organization.languages.map((language) => {
                                        return (
                                            <option
                                                key={language.language.id}
                                                value={language.language.id}
                                            >
                                                {t(language.name)}
                                            </option>
                                        );
                                    })}
                                </AvField>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12}>
                            <div className="mb-3">
                                <Label className="form-label">
                                    {`${t("Upload a new document")} (PDF)`}
                                </Label>
                                <Dropzone
                                    onDrop={handleAcceptedFiles}
                                    maxFiles={1}
                                    accept=".pdf"
                                    multiple={false}
                                    required
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <div className="dropzone">
                                            <div
                                                className="dz-message needsclick"
                                                {...getRootProps()}
                                            >
                                                <input {...getInputProps()} />
                                                <div className="mb-3">
                                                    <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                                </div>
                                                <h4>
                                                    {t("Drop files here or click to upload")}
                                                </h4>

                                                {file.showError && (
                                                    <p className='text-danger font-size-14'>
                                                        {t("This field cannot be blank")}
                                                    </p>
                                                )}                                                    
                                            </div>
                                        </div>
                                    )}
                                </Dropzone>

                                <div className="dropzone-previews mt-3" id="file-previews">
                                    {file.src && (
                                        <Card
                                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                        >
                                            <div className="p-3">
                                                <Row className="align-items-center">
                                                    <Col>
                                                        <Link to="#" className="text-muted fw-bold">
                                                            {file.src.name}
                                                        </Link>
                                                        <p className="mb-0">
                                                            <strong>{file.src.formattedSize}</strong>
                                                        </p>
                                                    </Col>
                                                    <Col xs={2} style={{ textAlign: "right" }}>
                                                        <Button
                                                            color="danger"
                                                            size="sm"
                                                            onClick={() => setFile({
                                                                showError: true,
                                                                src: null
                                                            })}
                                                        >
                                                            X
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Card>
                                    )}
                                </div>
                            </div>
                        </Col>
                        <Col xs={12}>
                            <div>
                                <Button
                                    color="primary"
                                    type="submit"
                                    disabled={creatingDocumentInProcess}
                                >
                                    {creatingDocumentInProcess && (
                                        <Spinner color='white' size='sm' className='me-1' />
                                    )}

                                    {t("Upload")}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </AvForm>
    );
};

const mapStatetoProps = (state) => {
	const { token, user } = state.Login;
	const { Organization } = state;
	
	return {
		token,
		user,
		organization: Organization
	};
};

export default withNamespaces()(
    withRouter(
        connect(mapStatetoProps, {
        })(
            CreateDocument
        )
    )
);