import { withNamespaces } from 'react-i18next'
import { useEffect, useMemo, useState } from 'react'
import {
    DateRangePicker,
    defaultInputRanges,
    defaultStaticRanges
} from 'react-date-range'
import * as locales from 'react-date-range/dist/locale'

const DateFilter = withNamespaces()(({
    t,
    name,
    defaultValue,
    onFilterChange,
    ...restProps
}) => {
    const [values, setValues] = useState({
        from: defaultValue?.from ? new Date(defaultValue?.from) : null,
        to: defaultValue?.to ? new Date(defaultValue?.to) : null
    })

    const datePickerTranlations = useMemo(() => {
        function translateRange(dictionary) {
            return (item) =>
                dictionary[item.label]
                    ? { ...item, label: dictionary[item.label] }
                    : item
        }

        return {
            staticRanges: defaultStaticRanges.map(
                translateRange({
                    Today: t('Today'),
                    Yesterday: t('Yesterday'),
                    'This Week': t('This Week'),
                    'Last Week': t('Last Week'),
                    'This Month': t('This Month'),
                    'Last Month': t('Last Month')
                })
            ),
            inputRanges: defaultInputRanges.map(
                translateRange({
                    'days up to today': t('days up to today'),
                    'days starting today': t('days starting today')
                })
            ),
            startDatePlaceholder: t('Early'),
            endDatePlaceholder: t('Continuous')
        }
    }, [t])

    useEffect(() => {
        if (values.from && values.to) {
            onFilterChange &&
                onFilterChange({
                    filterName: name,
                    value: values
                })
        }
    }, [values])

    return (
        <DateRangePicker
            ranges={[
                {
                    startDate: values.from,
                    endDate: values.to,
                    key: name
                }
            ]}
            onChange={(range) => {
                setValues({
                    from: range[name].startDate,
                    to: range[name].endDate
                })
            }}
            rangeColors={['#5664d2']}
            locale={locales[restProps?.lng] || locales.enUS}
            {...datePickerTranlations}
        />
    )
})

export { DateFilter }
