import axios from 'axios';

import {
    API_BASE_URL,
    API_URL_GET_RISK_AI_CLASS
} from '../constants/index';

export default class FindingsService {
	static instance;

	static getInstance() {
		if (!FindingsService.instance) {
			FindingsService.instance = new FindingsService();
		}

		return FindingsService.instance;
	}

    async list(supplierId, params = {}){
        const response = await axios.get(`${API_BASE_URL}/news/${supplierId}/list`, {
            params  :   params,
            headers :   {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`
            },
        });

        return response.data.data;
    }

    async toggleFlag(payload){
        const response = await axios.put(`${API_BASE_URL}/news/toggle_flag`, payload,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`,
              },
            }
        );
  
        return response.data.data
    }

    async bulkToggleFlag(payload){
        const response = await axios.put(`${API_BASE_URL}/news/bulk_toggle_flag`, payload,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`,
              },
            }
        );
  
        return response.data.data
    }

    async fetchDetails(supplierId, findingId, params = {}){
        const response = await axios.get(`${API_BASE_URL}/news/${findingId}/detail`, {
            params  :   params,
            headers :   {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`
            },
        });

        return response.data.data;
    }

    async getAIScore(payload){
        const response = await axios.post(`${API_URL_GET_RISK_AI_CLASS}`, payload,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`,
              },
            }
        );
  
        return response.data;
    }

    async translateToEnglish(findingId, payload = {}){
        const response = await axios.post(`${API_BASE_URL}/news/${findingId}/translate`, payload,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`
              }
            }
        );
  
        return response.data;
    }
}
