import { Fragment, useState } from 'react';
import backIcon from '../../assets/icons/prevIcon.png';
import nextIcon from '../../assets/icons/nextIcon.png';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import './style.scss';
import { withNamespaces } from 'react-i18next';

const CustomPagination = ({ page, onPageChange, itemsPerPage, totalItems = 50, pageNumberLimit = 5, setItemsPerPageSelected, itemsPerPageSelected, t }) => {
	const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(pageNumberLimit);
	const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);

	const handlePageNumClick = (event) => {
		onPageChange(event.target.id);
	};

	const pages = [];
	for (let i = 1; i <= Math.ceil(totalItems / itemsPerPageSelected); i++) {
		pages.push(i);
	}

	const renderPageNumbers = pages.map((number, index) => {
		if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
			return (
				<li key={number} id={number} onClick={handlePageNumClick} className={page === number ? 'active' : null}>
					{number}
				</li>
			);
		} else {
			return null;
		}
	});

	const handleNextbtn = () => {
		const pageNum = page + 1;
		onPageChange(pageNum);

		if (page + 1 > maxPageNumberLimit) {
			setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
			setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
		}
	};

	const handlePrevbtn = () => {
		const pageNum = page - 1;
		onPageChange(pageNum);

		if (((page - 1) % maxPageNumberLimit) - 6 === 0 || ((page - 1) % maxPageNumberLimit) - 12 === 0 || ((page - 1) % maxPageNumberLimit) - 18 === 0) {
			setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
			setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
		}
	};

	let pageIncrementBtn = null;
	let pageDecrementBtn = null;

	return (
		<Fragment>
			<div className='custom-pagination'>
				<UncontrolledDropdown className='me-2' direction='up'>
					<DropdownToggle
						style={{
							backgroundColor: ' #f5f4f7',
							border: 'none',
							borderRadius: '8px',
							width: '75px',
							height: '44px',
						}}
					>
						<div className='d-flex justify-content-center'>
							<p className='m-0 drop-down-label'>{itemsPerPageSelected}</p>
							<img src={nextIcon} style={{ transform: 'rotate(90deg)' }} alt='Next Icon' />
						</div>
					</DropdownToggle>
					<DropdownMenu className='mb-5 drop-down-menu'>
						<DropdownItem onClick={() => setItemsPerPageSelected(25)} className={`${itemsPerPageSelected === 25 ? 'selected' : ''}`}>
							25
						</DropdownItem>
						<DropdownItem onClick={() => setItemsPerPageSelected(50)} className={`${itemsPerPageSelected === 50 ? 'selected' : ''}`}>50</DropdownItem>
						<DropdownItem onClick={() => setItemsPerPageSelected(100)} className={`${itemsPerPageSelected === 100 ? 'selected' : ''}`}>100</DropdownItem>
						<DropdownItem onClick={() => setItemsPerPageSelected(150)} className={`${itemsPerPageSelected === 150 ? 'selected' : ''}`}>150</DropdownItem>
					</DropdownMenu>
				</UncontrolledDropdown>
				<ul className='pageNumberContainer'>
					<li>
						<button onClick={handlePrevbtn} disabled={page === pages[0] ? true : false} className='d-flex align-items-center next-prev-btn'>
							<img src={backIcon} width={30} height={30} alt='Back Icon' />
							<p className='m-0'>{t("Back")}</p>
						</button>
					</li>
					{pageDecrementBtn}
					{renderPageNumbers}
					{pageIncrementBtn}
					<li>
						<button
							onClick={handleNextbtn}
							disabled={page === pages[pages.length - 1] ? true : false}
							className='d-flex align-items-center justify-content-center next-prev-btn'
						>
							<p className='m-0'>{t("Next")}</p>
							<img src={nextIcon} alt='Next Icon' />
						</button>
					</li>
				</ul>
			</div>
		</Fragment>
	);
};

export default withNamespaces()(CustomPagination)
