import { AvForm } from 'availity-reactstrap-validation';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { useEffect, useState } from 'react';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { CgLink } from 'react-icons/cg';
import { FaRegComment } from 'react-icons/fa';
import {
	createMarkup
} from 'src/services/utils/Markup'
import {
	UPLOAD_ATTACHMENT_FAILED,
	SUBMIT_COMMENT_FAILED,
	SUBMIT_COMMENT_SUCCESSFUL,
	EMPTY_LIST,
	DELETE_FILE_FAILED,
	DELETE_FILE_SUCCESSFUL,
	DOWNLOAD_FILE_FAILED,
	UPDATE_FILE_SUCCESSFUL,
	UPDATE_FILE_FAILED,
} from 'src/common/constants';

import {
	API_BASE_URL,
	API_URL_TASK_DOWNLOAD_COMMENT_FILES,
	API_URL_TASK_POST_COMMENT,
	API_URL_RISK_UPLOAD_FILES,
	API_URL_TASK_UPLOAD_FILES,
} from '../../../constants';

import { Button, Col, Container, Label, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { convertToHTML } from 'draft-convert';
import axios from 'axios';
import classnames from 'classnames';
import DateUtils from 'src/services/utils/DateUtils';

import DownloadAttachments from '../attachments';
import FileUploader from '../uploader';
import { toast } from 'react-toastify';

const CommentsComponent = function ({
    useCase,
    t,
    id          :   mainId,
    token,
    user,
    comments,
    owner,
    manager,
    fetchComments,
    loadingComments,
    descriptionAttachments,
    creationDate,
    successDeletion,
    updateAttachmentName,
    isReadOnly
}) {
	const dateUtils = new DateUtils();

	const [activeTab, setActiveTab] = useState('comments');
	const [description, setDescription] = useState(EditorState.createEmpty());
	const [showAttachmentsList, setShowAttachmentsList] = useState(false);
	const [showAttachmentsProgress, setShowAttachmentsProgress] = useState(false);
	const [attachmentsList, setAttachmentsList] = useState([]);
	const [deleteFileModalStatus, setDeleteFileModalStatus] = useState(false);
	const [deleteFileId, setDeleteFileId] = useState(false);
	const [replaceFileModalStatus, setReplaceFileModalStatus] = useState(false);
	const [replaceFileId, setReplaceFileId] = useState(false);
	const [showAttachmentsReplaceProgress, setShowAttachmentsReplaceProgress] = useState(false);
	const [renderAttachmentsList, setRenderAttachmentsList] = useState(true);
	const header = {
		headers: {
			Authorization: `Bearer ${token}`,
		}
	};

	const handleRemoveAttachment = (id) => {
		const index = attachmentsList.findIndex((i) => i.id === id);
		if (index > -1) {
			const oldArray = Array.from(attachmentsList);
			oldArray.splice(index, 1);
			setAttachmentsList(oldArray);
		}
	};

    const handleUploadAttachments = async (fs) => {
        try {
            setShowAttachmentsProgress(true);
            const files = [];
            const formData = new FormData();

            for (const f in fs.target.files) {
                if (fs.target.files.hasOwnProperty(f)) {
                    files.push(fs.target.files[f]);
                }
            }

            files.map((file) => {
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                    formattedSize: file.size,
                });
                formData.append('files[]', file);
            });

            let API_URL = '';
            if (useCase === 'task') {
                API_URL = API_URL_TASK_UPLOAD_FILES;
            } else if (useCase === 'risk') {
                API_URL = API_URL_RISK_UPLOAD_FILES;
            }

            const result = await axios.post(API_URL, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (result.status === 200) {
                const fileData = result.data.data;

                const uploadedFiles = fileData.map((fd, i) => {
                    return {
                        id: fd.id,
                        file: fd,
                        name: files[i].name,
                        preview: files[i].preview,
                        formattedSize: files[i].formattedSize,
                    };
                });

                setAttachmentsList((oldArray) => [...oldArray, ...uploadedFiles]);
            } else {
                setShowAttachmentsProgress(false);

                toast(t(UPLOAD_ATTACHMENT_FAILED), {
                    type: 'error',
                });

                setAttachmentsList([]);
            }
        } catch (error) {
            toast(t(UPLOAD_ATTACHMENT_FAILED), {
                type: 'error',
            });

            setAttachmentsList([]);
        }

        setShowAttachmentsProgress(false);
    };

	const onUploadModalChange = (file, comment) => {
		const currentUserFullName = `${user?.first_name} ${user?.last_name}`;
		const commentAuthor = comment.created_by.full_name;
		if (currentUserFullName !== commentAuthor) {
            toast(t('This file can only be updated by creator'), {
                type: 'error',
            });
		} 
        else {
			const id = file.id;
			this.setState({
				...this.state,
				fileUpdateId: id,
				uploadFileModalActivated: true,
			});
		}
	};

	const handleSubmit = async (id) => {
		try {
			if (!description.getCurrentContent().hasText()) {
                toast(t('Comment could not be null.'), {
                    type: 'error',
                });

				return false;
			}

			const data = { content: convertToHTML(description.getCurrentContent()), attachments: attachmentsList.map((f) => String(f.file.id)) };
			let URL = '';
			if (useCase === 'task') {
				data['task'] = id;
				URL = API_URL_TASK_POST_COMMENT;
			} else if (useCase === 'risk') {
				URL = `${API_BASE_URL}/risk_rate/${id}/create_comment`
			}
			const result = await axios.post(URL, data, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			if (result.status === 200) {
                toast(t(SUBMIT_COMMENT_SUCCESSFUL), {
                    type: 'success',
                });

				setDescription(EditorState.createEmpty());
				setAttachmentsList([]);
				fetchComments();
				setRenderAttachmentsList(false);
				setRenderAttachmentsList(true);
			} 
            else {
                toast(t(SUBMIT_COMMENT_FAILED), {
                    type: 'error',
                });
			}
		} 
        catch (error) {
            toast(t(SUBMIT_COMMENT_FAILED), {
                type: 'error',
            });
		}
	};

	const handleDeleteFile = async (fileId) => {
		try {
			let URL = '';
			if (useCase === 'task') {
				URL = `${API_BASE_URL}/task/attachment/${fileId}/delete`
			} 
            else if (useCase === 'risk') {
				URL = `${API_BASE_URL}/risk_rate/attachment/${fileId}/delete`
			}

			const result = await axios.delete(URL, {
				headers: {
					Authorization: `Bearer ${token}`,
				}
			});

			if (result.status === 200) {
                toast(t(DELETE_FILE_SUCCESSFUL), {
                    type: 'success',
                });
                
				successDeletion(fileId);
				setDeleteFileId(null);
				setDeleteFileModalStatus(false);
				fetchComments();
			} 
            else {
                toast(t(DELETE_FILE_FAILED), {
                    type: 'error',
                });
			}
		} 
        catch (error) {
            toast(t('You are not authorized to delete this file'), {
                type: 'error',
            });
		}
	};

	const handleDownloadFile = async (fileId, fileName) => {
		try {
			let URL = '';
			if (useCase === 'task') {
				URL = `${API_URL_TASK_DOWNLOAD_COMMENT_FILES}task/attachment/${fileId}/download_file`
			} else if (useCase === 'risk') {
				URL = `${API_BASE_URL}/risk_rate/attachment/${fileId}/download_file`
			}

			const result = await axios.get(URL, {
				responseType: 'arraybuffer',
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			if (result.status === 200) {
				const blob = new Blob([result.data], { type: '*' });
				const link = document.createElement('a');
				link.href = window.URL.createObjectURL(blob);
				link.download = fileName;
				link.click();
			} 
            else {
                toast(t(DOWNLOAD_FILE_FAILED), {
                    type: 'error',
                });
			}
		} catch (error) {
            toast(t(DOWNLOAD_FILE_FAILED), {
                type: 'error',
            });
		}
	};

	const handleReplaceFile = async (file, currentFileId, token) => {
		try {
			setShowAttachmentsReplaceProgress(true);

			Object.assign(file, {
				preview: URL.createObjectURL(file),
				formattedSize: file.size,
			});

			const formData = new FormData();
			formData.append('file', file);

			let APIURL = '';
			if (useCase === 'task') {
				APIURL = `${API_BASE_URL}/task/attachment/${currentFileId}/update`
			} else if (useCase === 'risk') {
				APIURL = `${API_BASE_URL}/risk_rate/attachment/${currentFileId}/update`
			}

			const result = await axios.post(APIURL, formData, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			if (result.status === 200) {
                toast(t(UPDATE_FILE_SUCCESSFUL), {
                    type: 'success',
                });

				updateAttachmentName(result.data.data.name, currentFileId);
			} 
            else {
                toast(t(UPDATE_FILE_FAILED), {
                    type: 'error',
                });
			}
		} 
        catch (error) {
            toast(t(UPDATE_FILE_FAILED), {
                type: 'error',
            });
		}

		fetchComments();
		setShowAttachmentsReplaceProgress(false);
		setReplaceFileId(null);
		setReplaceFileModalStatus(false);
	};

    const renderDeleteFileModal = () => {
        const close = () => {
            setDeleteFileId(null);
            setDeleteFileModalStatus(false);
        };

        return (
            <Modal size="lg" scrollable={true} isOpen={deleteFileModalStatus} backdrop="static">
                <ModalHeader toggle={ close }>
                    {t("Delete file")}
                </ModalHeader>

                <ModalBody>
                    <AvForm className="needs-validation">
                        <Row>
                            <Label>
                                {t("Are you sure?")}
                            </Label>
                        </Row>

                        <ModalFooter>
                            <Button
                                color="danger"
                                className="waves-effect waves-light"
                                type="submit"
                                onClick={() => {
                                    handleDeleteFile(deleteFileId);
                                }}>
                                    {t("Delete").toUpperCase()}
                            </Button>

                            <Button
                                color="secondary"
                                className="waves-effect waves-light"
                                type="button"
                                onClick={() => {
                                    close();
                                }}>
                                    {t("Cancel").toUpperCase()}
                            </Button>
                        </ModalFooter>
                    </AvForm>
                </ModalBody>
            </Modal>
        )
    }

    const renderReplaceFileModal = () => {
        const close = () => {
            setReplaceFileId(null);
            setReplaceFileModalStatus(false);
        };

        return (
            <Modal size='lg' scrollable={true} isOpen={replaceFileModalStatus} backdrop='static'>
				<ModalHeader toggle={() => close()}>
                    {t('Upload a file')}
                </ModalHeader>

				<ModalBody>
					<AvForm className='needs-validation'>
						<Row>
							<Col sm='12' md='12' lg='12'>
								<FileUploader
									handleAcceptedFiles={(e) => {
                                        e.target?.files[0] && handleReplaceFile(e.target.files[0], replaceFileId)
                                    }}
									uploadedFiles={[]}
									showProg={showAttachmentsReplaceProgress}
								/>
							</Col>
						</Row>
					</AvForm>
				</ModalBody>
			</Modal>
        )
    }

	return (
		<>
            { renderDeleteFileModal() }

            { renderReplaceFileModal() }

			<AvForm className='needs-validation mb-5' onValidSubmit={() => handleSubmit(mainId)}>
				<Row className='mt-5'>
					<Col sm='12' md='12' lg='12'>
						<Editor
							editorState={description}
							toolbarClassName='toolbarClassName'
							wrapperClassName='wrapperClassName'
							editorClassName='editorClassName'
							onEditorStateChange={(e) => setDescription(e)}
							placeholder={t('Add a comment...')}
							editorStyle={{
								height: '10rem',
								overflowY: 'scroll',
								borderColor: '#c9c9c9',
							}}
							toolbar={{
							  options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link', 'remove', 'history'],
							  inline: {
								options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
								bold: { className: 'bordered-option-classname' },
								italic: { className: 'bordered-option-classname' },
								underline: { className: 'bordered-option-classname' },
								strikethrough: { className: 'bordered-option-classname' },
								code: { className: 'bordered-option-classname' },
							  },
							  blockType: {
								className: 'bordered-option-classname',
							  },
							  fontSize: {
								className: 'bordered-option-classname',
							  },
							}}
							toolbarCustomButtons={[
								<>
									<div
										style={{
											textAlign: 'right',
											flex: 'auto',
										}}
									>
										<Button
											color='secondary'
											disabled={isReadOnly}
											outline
											onClick={(e) => {
												setShowAttachmentsList(!showAttachmentsList);
											}}
										>
											<CgLink />
											{' ' + t('Attach')}
										</Button>
										<Button color='primary' outline className='m-1' disabled={isReadOnly}>
											<FaRegComment />
											{' ' + t('Add Comment')}
										</Button>
									</div>
								</>,
							]}
							readOnly={isReadOnly}
						/>
					</Col>
				</Row>
				<br />
				{renderAttachmentsList ? (
					<Row hidden={!showAttachmentsList}>
						<Col>
							<FileUploader
								uploadedFiles={attachmentsList}
								handleAcceptedFiles={(e) => handleUploadAttachments(e)}
								showProg={showAttachmentsProgress}
								handleClickDeleteFiles={(e) => handleRemoveAttachment(e.id)}
							/>
						</Col>
					</Row>
				) : null}
			</AvForm>

			{!loadingComments ? (
				<Container fluid>
					<Row>
						<Col sm='12' md='12' lg='12'>
							<Nav className='mb-2' tabs>
								<NavItem>
									<NavLink
										className={classnames({
											active: activeTab === 'comments',
										})}
										onClick={() => {
											setActiveTab('comments');
										}}
									>
										{t('Comments')}
									</NavLink>
								</NavItem>

								<NavItem>
									<NavLink
										className={classnames({
											active: activeTab === 'attachements',
										})}
										onClick={() => {
											setActiveTab('attachements');
										}}
									>
										{t('Attachments')}
									</NavLink>
								</NavItem>
							</Nav>

							<TabContent activeTab={activeTab} className='mb-5 area'>
								<TabPane tabId='comments'>
									{comments && comments.length > 0 ? (
										comments
											.sort((a, b) => (a.created_at < b.created_at ? 1 : -1))
											.map((comment) => {
												const fullName = comment.created_by.full_name;
												return (
													<>
														<Row style={{ borderBottom: '1px solid #c9c9c9' }} className='m-2' key={comment.id}>
															<Col sm='12' md='2' lg='2'>
																<Label
																	className={
																		fullName === owner
																			? 'badge bg-info text-white rounded p-2 text-black'
																			: fullName === manager
																			? 'badge bg-success text-white rounded p-2 text-black'
																			: 'badge bg-warning text-white rounded p-2 text-black'
																	}
																>
																	{fullName}
																</Label>
															</Col>

															<Col sm='12' md='4' lg='6'>
																<div className='preview' dangerouslySetInnerHTML={createMarkup(comment.content)}></div>
															</Col>

															<Col sm='12' md='2' lg='2'>
																<p>{dateUtils.convertTimestamp(comment.created_at)}</p>
															</Col>

															<Col sm='12' md='2' lg='2' className='mb-2' hidden={comment.attachments.length === 0}>
																<DownloadAttachments
																	t={t}
																	files={comment.attachments}
																	commentSection={true}
																	onUploadModalChange={onUploadModalChange}
																	header={header}
																	type={useCase}
																/>
															</Col>
														</Row>
													</>
												);
											})
									) : (
										<div className='alert alert-warning' role='alert'>
											<p style={{ textAlign: 'center' }}>{t(EMPTY_LIST)}</p>
										</div>
									)}
								</TabPane>

								<TabPane tabId='attachements'>
									<Row
										style={{
											overflowY: 'scroll',
											overflowX: 'scroll',
											borderBottom: '1px solid #c9c9c9',
											maxHeight: '20rem',
										}}
										className='mt-3'
									>
										<Col
											sm='12'
											md='12'
											lg='12'
											style={{
												height: '20rem',
												overflowY: 'scroll',
												overflowX: 'scroll',
											}}
										>
											{comments
												? comments
														.sort((a, b) => (a.created_at < b.created_at ? 1 : -1))
														.map((comment) => {
															if (comment && comment.attachments && comment.attachments.length > 0) {
																const fullName = comment.created_by.full_name;
																return (
																	<>
																		<Row className='p-1' hidden={!comments.length > 0} key={comment.id}>
																			<Col sm='12' md='2' lg='2'>
																				<Label
																					style={{ width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
																					className={
																						fullName === owner
																							? 'badge bg-info text-white rounded p-2'
																							: fullName === manager
																							? 'badge bg-success text-white rounded p-2'
																							: 'badge bg-warning text-white rounded p-2'
																					}
																				>
																					{fullName}
																				</Label>
																			</Col>

																			<Col sm='12' md='8' lg='8'>
																				<Row>
																					<ul>
																						{comment.attachments.map((item, index) => {
																							return (
																								<>
																									<li
																										// style={{ width: "48rem" }}
																										key={item.id}
																									>
																										<Row
																											style={{
																												backgroundColor: index % 2 === 0 ? '#dee2e6' : 'white',
																											}}
																										>
																											<Col sm='12' md='7' lg='7'>
																												<span
																													className='text-primary'
																													onClick={() => {
																														handleDownloadFile(item.id, item.origin_name);
																													}}
																													style={{
																														cursor: 'pointer',
																														userSelect: 'none',
																														fontSize: 14,
																													}}
																												>
																													{t(item.origin_name)}
																												</span>
																											</Col>
																											<Col sm='12' md='5' lg='5'>
																												<span
																													style={{ cursor: 'pointer' }}
																													className='text-primary'
																													onClick={() => {
																														setReplaceFileId(item.id);
																														setReplaceFileModalStatus(true);
																													}}
																												>
																													<span style={{ cursor: 'pointer' }}> {t('Update this version')}</span>
																												</span>
																												<span>{' | '}</span>
																												<span
																													style={{ cursor: 'pointer' }}
																													className='text-danger'
																													onClick={() => {
																														setDeleteFileId(item.id);
																														setDeleteFileModalStatus(true);
																													}}
																												>
																													{t('Delete')}
																												</span>
																											</Col>
																										</Row>
																									</li>
																								</>
																							);
																						})}
																					</ul>
																				</Row>
																			</Col>
																			<Col sm='12' md='2' lg='2'>
																				{dateUtils.convertTimestamp(comment.created_at)}
																			</Col>
																		</Row>
																	</>
																);
															}
														})
												: null}

											{descriptionAttachments && descriptionAttachments.length > 0 ? (
												<Row className='p-1'>
													<Col sm='12' md='2' lg='2'>
														<Label
															style={{ width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
															className={'badge bg-success text-white rounded p-2'}
														>
															{manager}
														</Label>
													</Col>

													<Col sm='12' md='8' lg='8'>
														<Row>
															<ul>
																{descriptionAttachments.map((item, index) => {
																	return (
																		<>
																			<li key={item.id}>
																				<Row
																					style={{
																						backgroundColor: index % 2 === 0 ? '#dee2e6' : 'white',
																					}}
																					key={item.id}
																				>
																					<Col sm='12' md='7' lg='7'>
																						<span
																							className='text-primary'
																							onClick={() => {
																								handleDownloadFile(item.id, item.origin_name);
																							}}
																							style={{
																								cursor: 'pointer',
																								userSelect: 'none',
																								fontSize: 14,
																							}}
																						>
																							{t(item.origin_name)}
																						</span>
																					</Col>
																					<Col sm='12' md='5' lg='5'>
																						<span
																							style={{ cursor: 'pointer' }}
																							className='text-primary'
																							onClick={() => {
																								setReplaceFileId(item.id);
																								setReplaceFileModalStatus(true);
																							}}
																						>
																							<span style={{ cursor: 'pointer' }}> {t('Update this version')}</span>
																						</span>
																						<span>{' | '}</span>
																						<span
																							style={{ cursor: 'pointer' }}
																							className='text-danger'
																							onClick={() => {
																								setDeleteFileId(item.id);
																								setDeleteFileModalStatus(true);
																							}}
																						>
																							{t('Delete')}
																						</span>
																					</Col>
																				</Row>
																			</li>
																		</>
																	);
																})}
															</ul>
														</Row>
													</Col>
													<Col sm='12' md='2' lg='2'>
														{creationDate}
													</Col>
												</Row>
											) : null}
										</Col>
									</Row>
								</TabPane>
							</TabContent>
						</Col>
					</Row>
				</Container>
			) : (
				'loading'
			)}
		</>
	);
};

const mapStatetoProps = (state) => {
    const { token } = state.Login;
    return {
        user            :   state.Login.user,
        token
    };
};

export default withNamespaces()(
    connect(
        mapStatetoProps,
        {}
    )(CommentsComponent)
);
