import { Col, Row } from "reactstrap";

const QuestionsLoadingPlaceholder = ({
    className
}) => {
    return (
        <div className={className}>
            <Row className="mb-4">
                <Col lg="12">
                    <p style={{width: '90%'}} className={`dt-field dt-skeleton`}></p>
                </Col>
                <Col lg="6">
                    <Row>
                        <Col lg="6">
                            <p style={{width: '100%'}} className={`dt-field dt-skeleton`}></p>
                        </Col>
                        <Col lg="6">
                            <p style={{width: '100%'}} className={`dt-field dt-skeleton`}></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="6">
                            <p style={{width: '100%'}} className={`dt-field dt-skeleton`}></p>
                        </Col>
                        <Col lg="6">
                            <p style={{width: '100%'}} className={`dt-field dt-skeleton`}></p>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="mb-4">
                <Col lg="12">
                    <p style={{width: '90%'}} className={`dt-field dt-skeleton`}></p>
                </Col>
                <Col lg="6">
                    <Row>
                        <Col lg="12">
                            <p style={{width: '100%'}} className={`dt-field dt-skeleton`}></p>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="mb-4">
                <Col lg="12">
                    <p style={{width: '90%'}} className={`dt-field dt-skeleton`}></p>
                </Col>
                <Col lg="6">
                    <Row>
                        <Col lg="12">
                            <p style={{width: '100%'}} className={`dt-field dt-skeleton`}></p>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="mb-4">
                <Col lg="12">
                    <p style={{width: '90%'}} className={`dt-field dt-skeleton`}></p>
                </Col>
                <Col lg="6">
                    <Row>
                        <Col lg="12">
                            <p style={{width: '100%'}} className={`dt-field dt-skeleton`}></p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}

export default QuestionsLoadingPlaceholder;