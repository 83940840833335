import { Editor } from "draft-js";
import { withNamespaces } from "react-i18next";
import { MdOutlineModeEdit } from "react-icons/md";
import { TbLoader } from "react-icons/tb";
import { Button, Col, FormGroup, Label, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import { isFeatureEnabledForActiveModule } from "src/helpers/module_helper";
import LocalizedPhoneInput from "src/modules/3rd-party-management/components/LocalizedPhoneInput";

const Creditor = ({
    t,
    goToSpecificTab,
    goToPrevTab,
    onFormSubmit,
    isLoading,
    companyName,
    vatNumber,
    industries,
    companySize,
    website,
    description,
    legalEntities,
    officeAddresses,
    factoryAddresses,
    addedProducts,
    contacts
}) => {
    // some bugs make this to happen.delete this after fix the bug
    const validFactoryAddresses = factoryAddresses.filter((add) => !!add.country && !!add.city)

    const handleBack = () => {
        goToPrevTab(3);
    };

    return (
        <>
            <div className="confirm-form-sections-container">
                {/* Company Detials */}
                <div className="section">
                <div
                    className="section-header"
                    style={{
                    display: "flex",
                    flexFlow: "row wrap",
                    justifyContent: "space-between",
                    }}
                >
                    <span>{`1. ${t("Company Details")}`}</span>
                    <Button color="primary" onClick={() => goToSpecificTab(1)} outline className="edit-section-btn">
                    <MdOutlineModeEdit />
                    </Button>
                </div>
                <div className="section-body mt-0">
                    <Row>
                        <Col lg="4" md="4" sm="12">
                            <Label for="companyName">{t("Company Name")}: </Label>
                            <p>{companyName}</p>
                        </Col>

                        <Col lg="4" md="4" sm="12">
                            <Label for="industry">{t("Industries")}: </Label>
                            <p style={{ whiteSpace: 'pre-line' }}>{(industries || []).map((industry) => industry.label).join('\n')}</p>
                        </Col>

                        <Col lg="4" md="4" sm="12">
                            <Label for="vatNumber">{t("VAT ID")}: </Label>
                            <p>{t(vatNumber)}</p>
                        </Col>
                    </Row>

                    <Row>

                    {isFeatureEnabledForActiveModule('entity_management') && (
                        <Col lg="4" md="6" sm="12">
                            <Label>{t("Connected Legal Entities")}: </Label>
                            <p>{(legalEntities || []).map((legal) => legal.label).join(', ')}</p>
                        </Col>
                    )}
                    
                    <Col lg="4" md="6" sm="12">
                        <Label for="companySize">{t("Company Size")}: </Label>
                        <p>{t(companySize.label)}</p>
                    </Col>

                    <Col lg="4" md="6" sm="12">
                        <Label for="website">{t("Website")}: </Label>
                        <p>{website}</p>
                    </Col>
                    </Row>

                    <Row>
                    <Col>
                        <Label for="description">{t("Description")}: </Label>
                        <Editor
                        editorState={description}
                        readOnly={true}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        />
                    </Col>
                    </Row>
                </div>
                </div>

                {/* Factory Sites and Products */}
                <div className="section">
                <div
                    className="section-header"
                    style={{
                    display: "flex",
                    flexFlow: "row wrap",
                    justifyContent: "space-between",
                    }}
                >
                    <span>{`2. ${t("Factory Sites and Products")}`}</span>
                    <Button color="primary" onClick={() => goToSpecificTab(2)} outline className="edit-section-btn">
                    <MdOutlineModeEdit />
                    </Button>
                </div>
                <div className="section-body mt-0">
                    {/* Office Address */}
                    <label>{t("Office Address")}</label>
                    <ol>
                    {officeAddresses.map((item, i) => {
                        return (
                        <li key={item.id}>
                            <Row className="mb-2">
                                {/* Country */}
                                <Col md="3" sm="12">
                                    <Label for="country">{t("Country")}: </Label>
                                    <p>{t(officeAddresses[i]?.country?.label)}</p>
                                </Col>

                                {/* Postal Code */}
                                <Col md="2" sm="12">
                                    <Label for="postal-code">{t("Postal Code")}: </Label>
                                    <p>{t(officeAddresses[i]?.postalCode)}</p>
                                </Col>

                                {/* City */}
                                <Col md="2" sm="12">
                                    <Label for="city">{t("City")}: </Label>
                                    <p>{t(officeAddresses[i]?.city)}</p>
                                </Col>

                                {/* Extra */}
                                <Col md="5" sm="12">
                                    <Label for="extra">{t("Street, Number")}: </Label>
                                    <p>{t(officeAddresses[i]?.extra)}</p>
                                </Col>
                                {/* <hr></hr> */}
                            </Row>
                        </li>
                        );
                    })}
                    </ol>

                    {/* Factory Address */}
                    <label>{t("Factory Location")}</label>
                    {validFactoryAddresses.length > 0 ? (
                        <ol>
                            {validFactoryAddresses.map((item, i) => {
                                return (
                                    <li key={item.id}>
                                        <Row className="mb-2">
                                            {/* Country */}
                                            <Col md="3" sm="12">
                                                <Label for="country">{t("Country")}: </Label>
                                                <p>{t(validFactoryAddresses[i]?.country?.label)}</p>
                                            </Col>

                                             {/* Postal Code */}
                                            <Col md="2" sm="12">
                                                <Label for="postal-code">{t("Postal Code")}: </Label>
                                                <p>{t(validFactoryAddresses[i]?.postalCode)}</p>
                                            </Col>

                                            {/* City */}
                                            <Col md="2" sm="12">
                                                <Label for="city">{t("City")}: </Label>
                                                <p>{t(validFactoryAddresses[i]?.city)}</p>
                                            </Col>

                                            {/* Extra */}
                                            <Col md="5" sm="12">
                                                <Label for="extra">{t("Street, Number")}: </Label>
                                                <p>{t(validFactoryAddresses[i]?.extra)}</p>
                                            </Col>
                                            {/* <hr></hr> */}
                                        </Row>
                                    </li>
                                )
                            })}
                        </ol>
                    ) : (
                        <div className="alert alert-warning">
                            <p className="m-0">{t('This list is empty')}</p>
                        </div>
                    )}

                    {/* Products */}
                    <div hidden={!addedProducts?.length}>
                    <h4>{t("List of products supplied")}</h4>
                    <Row className="mb-2">
                        <div className="table-container">
                        <table>
                            <thead hidden={!addedProducts.length}>
                            <tr>
                                <td style={{ width: "25%" }}>{t("Product Name")}</td>
                                <td style={{ width: "75%" }}>{t("Description")}</td>
                            </tr>
                            </thead>
                            <tbody>
                            {addedProducts.length
                                ? addedProducts.map((item, index) => {
                                    return (
                                    <tr key={item?.id}>
                                        <td style={{ width: "25%" }}>
                                        {item.productName.label}
                                        </td>
                                        <td style={{ width: "75%" }}>
                                        {item.description}
                                        </td>
                                    </tr>
                                    );
                                })
                                : null}
                            </tbody>
                        </table>
                        </div>
                    </Row>
                    </div>
                </div>
                </div>

                {/* Contact Person */}
                <div className="section">
                <div
                    className="section-header"
                    style={{
                    display: "flex",
                    flexFlow: "row wrap",
                    justifyContent: "space-between",
                    }}
                >
                    <span>{`3. ${t("Responsible Person")}`}</span>
                    <Button color="primary" onClick={() => goToSpecificTab(3)} outline className="edit-section-btn">
                    <MdOutlineModeEdit />
                    </Button>
                </div>

                <div className="section-body mt-0">
                    {contacts.length > 0 ? (
                        <ol>
                            {contacts.map((item, i) => {
                                return (
                                    <li key={i}>
                                        <Row>
                                        <Col md="4" sm="12">
                                            <Label for="fullName">{t("Full Name")}: </Label>
                                            <p>{`(${t(item?.title)}) ${item?.firstName} ${item?.lastName}`}</p>
                                        </Col>

                                        <Col md="4" sm="12">
                                            <Label for="position">{t("Position")}: </Label>
                                            <p>{t(item?.position)}</p>
                                        </Col>

                                        <Col md="4" sm="12">
                                            <Label for="language">{t("Language")}: </Label>
                                            <p>{t(item?.language?.label)}</p>
                                        </Col>
                                        </Row>

                                        <Row>
                                        <Col md="4" sm="12">
                                            <Label for="email">{t("Email")}: </Label>
                                            <p>{item?.email}</p>
                                        </Col>

                                        <Col md="4" sm="12">
                                            <Label for="phone">{t("Phone Number")}: </Label>
                                            <LocalizedPhoneInput
                                                disabled={true}
                                                buttonStyle={{
                                                    display: 'none'
                                                }}
                                                dropdownStyle={{
                                                    border: 'none'
                                                }}
                                                inputStyle={{
                                                    border: 'none',
                                                    paddingLeft: '0px',
                                                    color: '#000',
                                                    fontSize: '14px',
                                                    background: 'transparent'
                                                }}
                                                disableDropdown={true}
                                                value={ item?.phoneNumber }
                                            />
                                        </Col>
                                        </Row>

                                        {/* <hr></hr> */}
                                    </li>
                                );
                            })}
                        </ol>
                    ) : (
                        <div className="alert alert-warning">
                            <p className="m-0">{t('This list is empty')}</p>
                        </div>
                    )}
                </div>
                </div>
            </div>

            <Row className="mb-4">
                <FormGroup
                style={{
                    display: "flex",
                    flexFlow: "row wrap",
                    justifyContent: "space-between",
                }}
                className="actions-buttons-container"
                >
                <Button color="primary" onClick={handleBack} disabled={isLoading} outline>
                    {t("Back")}
                </Button>
                <Button color="primary" onClick={onFormSubmit} disabled={ isLoading } >
                    {
                    isLoading ? (
                        <>
                        <TbLoader /> { t("Creating...") }
                        </>
                    ) : <>{t("Create")}</>
                    }
                </Button>
                </FormGroup>
            </Row>

            <Modal isOpen={isLoading}>
                <ModalHeader>
                    <div>
                        <Spinner className="me-2" animation="border" variant="info" size="sm"/>
                        {t('Creating new third party')}
                    </div>
                </ModalHeader>
                
                <ModalBody>
                    <p>
                        {/* <i className="ri-information-line me-1 font-size-24 text-info" /> */}
                        {t("This might take several minutes.please don't close or refresh the page")}
                    </p>
                </ModalBody>
            </Modal>
        </>
    )
}

const OwnCompany = ({
    t,
    goToSpecificTab,
    goToPrevTab,
    onFormSubmit,
    isLoading,
    companyName,
    vatNumber,
    industries,
    companySize,
    website,
    description,
    legalEntities,
    officeAddresses,
    factoryAddresses,
    addedProducts,
    contacts
}) => {
    // some bugs make this to happen.delete this after fix the bug
    const validFactoryAddresses = factoryAddresses.filter((add) => !!add.country && !!add.city)

    const handleBack = () => {
        goToPrevTab(3);
    };

    return (
        <>
            <div className="confirm-form-sections-container">
                <div className="section">
                    <div
                        className="section-header"
                        style={{
                            display: "flex",
                            flexFlow: "row wrap",
                            justifyContent: "space-between",
                        }}
                    >
                        <span>{`1. ${t("Company Details")}`}</span>
                        <Button color="primary" onClick={() => goToSpecificTab(1)} outline className="edit-section-btn">
                            <MdOutlineModeEdit />
                        </Button>
                    </div>
                    <div className="section-body mt-0">
                        <Row>
                            <Col lg="4" md="4" sm="12">
                                <Label>{t("Connected Legal Entity")}: </Label>
                                <p>{legalEntities.label}</p>
                            </Col>

                            <Col lg="4" md="4" sm="12">
                                <Label for="industry">{t("Industries")}: </Label>
                                <p style={{ whiteSpace: 'pre-line' }}>{(industries || []).map((industry) => industry.label).join('\n')}</p>
                            </Col>

                            <Col lg="4" md="4" sm="12">
                                <Label for="vatNumber">{t("VAT ID")}: </Label>
                                <p>{t(vatNumber)}</p>
                            </Col>
                        </Row>

                        <Row>
                            <Col md="4" sm="12">
                                <Label for="companySize">{t("Company Size")}: </Label>
                                <p>{t(companySize.label)}</p>
                            </Col>

                            <Col md="8" sm="12">
                                <Label for="website">{t("Website")}: </Label>
                                <p>{website}</p>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Label for="description">{t("Description")}: </Label>
                                <Editor
                                    editorState={description}
                                    readOnly={true}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                />
                            </Col>
                        </Row>
                    </div>
                </div>

                <div className="section">
                    <div
                        className="section-header"
                        style={{
                            display: "flex",
                            flexFlow: "row wrap",
                            justifyContent: "space-between",
                        }}
                    >
                        <span>{`2. ${t("Factory Sites and Products")}`}</span>
                        <Button color="primary" onClick={() => goToSpecificTab(2)} outline className="edit-section-btn">
                            <MdOutlineModeEdit />
                        </Button>
                    </div>
                    <div className="section-body mt-0">
                        <label>{t("Office Address")}</label>
                        <ol>
                            {officeAddresses.map((item, i) => {
                                return (
                                    <li key={item.id}>
                                        <Row className="mb-2">
                                            <Col md="3" sm="12">
                                                <Label for="country">{t("Country")}: </Label>
                                                <p>{t(officeAddresses[i]?.country?.label)}</p>
                                            </Col>

                                            <Col md="2" sm="12">
                                                <Label for="postal-code">{t("Postal Code")}: </Label>
                                                <p>{t(officeAddresses[i]?.postalCode)}</p>
                                            </Col>

                                            <Col md="2" sm="12">
                                                <Label for="city">{t("City")}: </Label>
                                                <p>{t(officeAddresses[i]?.city)}</p>
                                            </Col>

                                            <Col md="5" sm="12">
                                                <Label for="extra">{t("Street, Number")}: </Label>
                                                <p>{t(officeAddresses[i]?.extra)}</p>
                                            </Col>
                                        </Row>
                                    </li>
                                );
                            })}
                        </ol>

                        <label>{t("Factory Location")}</label>
                        {validFactoryAddresses.length > 0 ? (
                            <ol>
                                {validFactoryAddresses.map((item, i) => {
                                    return (
                                        <li key={item.id}>
                                            <Row className="mb-2">
                                                <Col md="3" sm="12">
                                                    <Label for="country">{t("Country")}: </Label>
                                                    <p>{t(validFactoryAddresses[i]?.country?.label)}</p>
                                                </Col>

                                                <Col md="2" sm="12">
                                                    <Label for="postal-code">{t("Postal Code")}: </Label>
                                                    <p>{t(validFactoryAddresses[i]?.postalCode)}</p>
                                                </Col>

                                                <Col md="2" sm="12">
                                                    <Label for="city">{t("City")}: </Label>
                                                    <p>{t(validFactoryAddresses[i]?.city)}</p>
                                                </Col>

                                                <Col md="5" sm="12">
                                                    <Label for="extra">{t("Street, Number")}: </Label>
                                                    <p>{t(validFactoryAddresses[i]?.extra)}</p>
                                                </Col>
                                            </Row>
                                        </li>
                                    )
                                })}
                            </ol>
                        ) : (
                            <div className="alert alert-warning">
                                <p className="m-0">{t('This list is empty')}</p>
                            </div>
                        )}

                        <div hidden={!addedProducts?.length}>
                            <h4>{t("List of products supplied")}</h4>
                            <Row className="mb-2">
                                <div className="table-container">
                                    <table>
                                        <thead hidden={!addedProducts.length}>
                                            <tr>
                                                <td style={{ width: "25%" }}>{t("Product Name")}</td>
                                                <td style={{ width: "75%" }}>{t("Description")}</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {addedProducts.length
                                                ? addedProducts.map((item, index) => {
                                                    return (
                                                    <tr key={item?.id}>
                                                        <td style={{ width: "25%" }}>
                                                        {item.productName.label}
                                                        </td>
                                                        <td style={{ width: "75%" }}>
                                                        {item.description}
                                                        </td>
                                                    </tr>
                                                    );
                                                })
                                                : null}
                                        </tbody>
                                    </table>
                                </div>
                            </Row>
                        </div>
                    </div>
                </div>

                <div className="section">
                    <div
                        className="section-header"
                        style={{
                            display: "flex",
                            flexFlow: "row wrap",
                            justifyContent: "space-between",
                        }}
                    >
                        <span>{`3. ${t("Responsible Person")}`}</span>
                        <Button color="primary" onClick={() => goToSpecificTab(3)} outline className="edit-section-btn">
                            <MdOutlineModeEdit />
                        </Button>
                    </div>

                    <div className="section-body mt-0">
                        {contacts.length > 0 ? (
                            <ol>
                                {contacts.map((item, i) => {
                                    return (
                                        <li key={i}>
                                            <Row>
                                                <Col md="4" sm="12">
                                                    <Label>{t("First Name")}: </Label>
                                                    <p>{item?.firstName}</p>
                                                </Col>

                                                <Col md="4" sm="12">
                                                    <Label>{t("Last Name")}: </Label>
                                                    <p>{item?.lastName}</p>
                                                </Col>
                                            </Row>
                                        </li>
                                    );
                                })}
                            </ol>
                        ) : (
                            <div className="alert alert-warning">
                                <p className="m-0">{t('This list is empty')}</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <Row className="mb-4">
                <FormGroup
                    style={{
                        display: "flex",
                        flexFlow: "row wrap",
                        justifyContent: "space-between",
                    }}
                    className="actions-buttons-container"
                >
                    <Button color="primary" onClick={handleBack} disabled={isLoading} outline>
                        {t("Back")}
                    </Button>
                    <Button color="primary" onClick={onFormSubmit} disabled={ isLoading } >
                        {
                            isLoading ? (
                                <>
                                <TbLoader /> { t("Creating...") }
                                </>
                            ) : <>{t("Create")}</>
                        }
                    </Button>
                </FormGroup>
            </Row>

            <Modal isOpen={isLoading}>  
                <ModalHeader>
                    <div>
                        <Spinner className="me-2" animation="border" variant="info" size="sm"/>
                        {t('Creating new own company')}
                    </div>
                </ModalHeader>
                
                <ModalBody>
                    <p>
                        {t("This might take several minutes.please don't close or refresh the page")}
                    </p>
                </ModalBody>
            </Modal>
        </>
    )
}

const ReviewReport = ({
  supplierType,
  ...elseProps
}) => {

    if(supplierType === 'own'){
        return (
            <OwnCompany 
                {...elseProps}
            />
        );
    }

    return (
        <Creditor 
            {...elseProps}
        />
    );
};

export default withNamespaces()(ReviewReport);
