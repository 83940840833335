import { withNamespaces } from "react-i18next";
import { memo,} from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
    Button,
    Modal,
    ModalBody,
} from "reactstrap";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import {
    ROUTE_PATH_ADMIN_HOME,
    ROUTE_PATH_ADMIN_OWN_COMPANY_THIRD_PARTIES
} from "src/modules/3rd-party-management/constants/AppRoutes";

const SuccessModal = ({ t, modal, setModal, supplierType }) => {
    const toggle = () => setModal(!modal);

    return (
        <div>
            <Modal isOpen={modal} toggle={toggle} backdrop="static" style={{top: "30%"}} keyboard={false}>
                <ModalBody>
                    <h5 style={{textAlign: "center"}}>
                        {t("Third party successfully created")}
                    </h5>
                    
                    <div  style={{ display: "flex", justifyContent: "center" }}>
                        {supplierType === 'own' ? (
                            <Link to={ROUTE_PATH_ADMIN_OWN_COMPANY_THIRD_PARTIES}>
                                <Button onClick={toggle} color="primary">
                                    {t("Go To Own Company Management")}
                                </Button>
                            </Link>
                        ) : (
                            <Link to={ROUTE_PATH_ADMIN_HOME}>
                                <Button onClick={toggle} color="primary">
                                    {t("Go to Third Party Management")}
                                </Button>
                            </Link>
                        )}
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
};

const mapStatetoProps = (state) => {
    const { token } = state.Login;
    const { Organization } = state;
    return {
        user: state.Login.user,
        token,
        organization: Organization,
    };
};

export default withNamespaces()(
    withRouter(connect(mapStatetoProps, {})(memo(SuccessModal)))
);
