import { memo, useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { FaChevronRight } from "react-icons/fa";

const AiRiskModal = function ({ t, title, toggle, imageSrc, modal }) {
  const [dotCount, setDotCount] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setDotCount(prevCount => (prevCount % 3) + 1);
    }, 500); // Change the dot every 500 milliseconds

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div>
        <Modal isOpen={modal} toggle={toggle} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', top: "28%"}}>
          <ModalHeader><h6>{`${t("Hi, I'm Botty, the AI assistant from DISS-CO. I select the best results from millions of sources. Screening and scoring can take up to 2 minutes. Please wait")} ${'.'.repeat(dotCount)}`}</h6></ModalHeader>
          <ModalBody style={{display: "flex", justifyContent: "center"}}>
            <img alt="processing" style={{maxWidth: "50%", maxHeight: "50%"}} src={imageSrc} />
          </ModalBody>
          
        </Modal>
      </div>
    </>
  );
};

export default memo(AiRiskModal);
