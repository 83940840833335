import { withNamespaces } from "react-i18next";
import DefaultAvatar from 'src/assets/images/users/def.png';
import { Card, CardBody, UncontrolledTooltip } from "reactstrap";
import { Link } from "react-router-dom";
import DateUtils from "src/services/utils/DateUtils";
import { 
    STANDARD_DATE_FORMAT 
} from "src/components/constants";
import {
    TasksStatuses,
    TasksPriorities
} from "src/modules/3rd-party-management/constants"

const TaskCardItem = ({
    t,
    data
}) => {
    const dateUtils = new DateUtils();

    const renderTaskStatus = () => {
        const statusData = TasksStatuses[data.status];

        return (
            <span className="me-1 badge text-dark" style={{
                backgroundColor :    `${statusData?.color}`
            }}>
                { t(statusData?.title) }
            </span>
        );
    }

    const renderTaskPriority = () => {
        const priorityData = TasksPriorities[data.priority];
        
        return (
            <span className="me-1 badge text-capitalize text-dark"
                style={{
                    backgroundColor :    `${priorityData?.color}`
                }}>
                <span>
                    { priorityData?.icon }
                </span>
                { t(priorityData?.title) }
            </span>
        );
    }

    let taskFullID = `#T${data.supplier['id']}`;

    if(data.relatedRisk['isMitigation']){
        taskFullID += `/R${data.relatedRisk['secondaryId']}`;
    }

    taskFullID += `/T${data["secondaryId"]}`;

    return (
        <Card className="task-box">
            <div class="progress-container">
                <div class="progress-bar"></div>
            </div>
            <CardBody>
                <div className="float-end ms-2">
                    <span>
                        {`${ t("Deadline") }: ${ dateUtils.convertTimeStampToDate(
                            data["endDate"],
                            STANDARD_DATE_FORMAT
                        ) }`}
                    </span>
                </div>

                <div className="mb-3">
                    <Link to={`/admin/3rd-party/suppliers/${data.supplier.id}/tasks/${data.id}/details`}>
                        <span>
                            {taskFullID}
                        </span>
                    </Link>
                </div>

                <div>
                    {data["title"] && (
                        <h5 className="mb-4">
                            <Link to={`/admin/3rd-party/suppliers/${data.supplier.id}/tasks/${data.id}/details`}>
                                <span className="text-datk">{data["title"]}</span>
                            </Link>
                        </h5>
                    )}

                    {
                        data["supplier"] && (
                            <div className="mb-4" style={{
                                    borderLeft: "4px solid #eee",
                                    paddingLeft: 20,
                                    color: "#999",
                            }}>
                                <p className="mb-1">
                                    { `${ t("Supplier ID" )}: ${ data["supplier"]?.id }` }
                                </p>
                                <p className="mb-1">
                                { `${ t("Supplier Name" )}: ${ data["supplier"]?.name }` }
                                </p>
                            </div>
                        )
                    }
                </div>

                <div className="mb-1">
                    <div className="flex justify-content-between">
                        <div className="d-inline-flex align-items-center mb-0 w-50">
                            <span className="me-2">
                                {`${t("Team")} : `}
                            </span>
                            
                            {data["team"].map((member, key) => (
                                <div className="team-member me-1" key={key}>
                                    <Link to="#" className="team-member d-inline-block" id={`owner-${member.id}`}>
                                        <div className="avatar-xs">
                                            <span className="avatar-title bg-soft-primary text-primary rounded-circle text-capitalize" style={{overflow:'hidden'}}>
                                                <img alt="member's avatar" className="w-100 h-100 d-block"
                                                    src={member.avatar? member.avatar: DefaultAvatar} 
                                                    style={{ 
                                                        objectFit:'cover' 
                                                    }} />
                                            </span>
                                        </div>
                                    </Link>
                                    <UncontrolledTooltip target={`owner-${member.id}`} placement="top">
                                        <div className="text-capitalize text-left">
                                            { t(member.type) }:
                                                <br /> 
                                            { member.name }
                                        </div>
                                    </UncontrolledTooltip>
                                </div>
                            ))}
                        </div>

                        <div className="d-inline-flex mb-0 w-50 text-right flex-row-reverse">
                                
                            {
                                data.status && (
                                    <>
                                        { renderTaskStatus() }
                                    </>
                                )
                            }

                            {
                                data.priority && (
                                    <>
                                        { renderTaskPriority() }
                                    </>
                                )
                            }
                        </div>
                    </div>
                </div>
            </CardBody>
        </Card>
    );
}

export  default withNamespaces()(
    TaskCardItem
);