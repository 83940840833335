import { withNamespaces } from "react-i18next";
import { useEffect } from "react";
import { Prompt } from "react-router-dom";
import { connect } from "react-redux";
import {
    confirmPromptUpdateConfirmStatus
} from 'src/store/actions';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const useBeforeUnload = ({ when, message }) => {
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (when) {
                event.preventDefault();
                event.returnValue = message;
                return message;
            }
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => window.removeEventListener("beforeunload", handleBeforeUnload);
    }, [when, message]);
};

export const ConfirmPrompt = ({
    promptOptions,
    updateConfirmPromptConfirmStatus
}) => {
    useBeforeUnload({
        when    :   promptOptions.readyToShow,
        message :   promptOptions.message
    });

    const {
        alertProps
    } = promptOptions;

    return (
        <>
            <Modal isOpen={ promptOptions.readyToShow && promptOptions.show }>
                {
                    promptOptions?.title && (
                        <ModalHeader>
                            { promptOptions.title }
                        </ModalHeader>
                    )
                }
                
                <ModalBody>
                    <p>{ promptOptions?.message }</p>
                </ModalBody>

                {
                    (alertProps?.confirmBtn?.enabled || alertProps?.cancelBtn?.enabled) && (
                        <ModalFooter>
                            {
                                alertProps?.confirmBtn?.enabled && (
                                    <Button disabled={ alertProps?.confirmBtn?.loading } 
                                            color={ alertProps?.confirmBtn?.color } 
                                            onClick={() => updateConfirmPromptConfirmStatus(true) }>
                                        { alertProps?.confirmBtn?.title }
                                    </Button>
                                )
                            }

                            {
                                alertProps?.cancelBtn?.enabled && (
                                    <Button disabled={ alertProps?.cancelBtn?.loading } 
                                            color={ alertProps?.cancelBtn?.color } 
                                            onClick={() => updateConfirmPromptConfirmStatus(false) }>
                                        { alertProps?.cancelBtn?.title }
                                    </Button>
                                )
                            }
                        </ModalFooter>
                    )
                }
            </Modal>

            <Prompt message={ promptOptions.message } when={ promptOptions.readyToShow } />
        </>
    )
}

const mapStatetoProps = (state) => {
    return {
        promptOptions   :   state.ExitConfirmPrompt
    };
};

export default withNamespaces()(
    connect(
        mapStatetoProps,
        {
            updateConfirmPromptConfirmStatus    :   (status) =>  confirmPromptUpdateConfirmStatus(status)
        }
    )(ConfirmPrompt)
);