import {
    ROUTE_PATH_ADMIN_HOME,
    ROUTE_PATH_ADMIN_REPORT_ANONYMOUSLY,
    ROUTE_PATH_ADMIN_REPORT_CONFIDENTLY,
    ROUTE_PATH_ADMIN_DOCUMENTS,
    ROUTE_PATH_ADMIN_KANBAN_BOARD,
    ROUTE_PATH_ADMIN_DASHBOARD
} from "src/common/constants";

const AnalystMenuItems = [
    {
        titleKey    :   "Dashboard",
        path        :   ROUTE_PATH_ADMIN_DASHBOARD,
        icon        :   "ri-home-4-line",
        linkType    :   "react-router",
        authProtected   :   true,
        settingKey      :   null,
        moduleFeatures  :   [],
        roles           :   [],
        customValidator :   null
    },
    {
        titleKey    :   "Cases",
        path        :   ROUTE_PATH_ADMIN_HOME,
        icon        :   "ri-home-4-line",
        linkType    :   "react-router",
        authProtected   :   true,
        settingKey      :   null,
        moduleFeatures  :   [],
        roles           :   [],
        customValidator :   null
    },
    {
        titleKey    :   "Documents",
        path        :   ROUTE_PATH_ADMIN_DOCUMENTS,
        icon        :   "ri-book-open-line",
        linkType    :   "react-router",
        authProtected   :   true,
        settingKey      :   null,
        moduleFeatures  :   [
            'Documents'
        ],
        roles           :   [],
        customValidator :   null
    },
    {
        titleKey    :   "Kanban Board",
        path        :   ROUTE_PATH_ADMIN_KANBAN_BOARD,
        icon        :   "ri-bar-chart-2-line",
        linkType    :   "react-router",
        authProtected   :   true,
        settingKey      :   null,
        moduleFeatures  :   [
            'kanban-board'
        ],
        roles           :   [],
        customValidator :   null
    },
    {
        titleKey    :   "Add a new Case",
        path        :   null,
        icon        :   "ri-mail-send-line",
        linkType    :   "react-router",
        authProtected   :   true,
        settingKey      :   null,
        moduleFeatures  :   [],
        roles           :   [],
        customValidator :   null,
        items: [
            {
                titleKey    :   "Report anonymously",
                path        :   ROUTE_PATH_ADMIN_REPORT_ANONYMOUSLY,
                linkType    :   "react-router",
                authProtected   :   true,
                settingKey      :   "isAnonymously",
                moduleFeatures  :   [],
                roles           :   [],
                customValidator :   null
            },
            {
                titleKey    :   "Report confidentially",
                path        :   ROUTE_PATH_ADMIN_REPORT_CONFIDENTLY,
                linkType    :   "react-router",
                authProtected   :   true,
                settingKey      :   "isConfidentially",
                moduleFeatures  :   [],
                roles           :   [],
                customValidator :   null
            }
        ],
    },
];

export default AnalystMenuItems;