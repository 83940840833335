import { memo } from 'react';
import { Button, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';

const DeletionRiskModal = function (props) {
	DeletionRiskModal.displayName = 'Memorized Component - Deletion Risk Modal';
	if (process?.env?.NODE_ENV === 'development') console.log('---- rendering memorized component >>> DeletionRiskModal');

	return (
		<>
			<Modal size='lg' scrollable={true} isOpen={props.isOpen} backdrop='static'>
				{/* header */}
				<ModalHeader toggle={() => props.close()}>{props.t('Delete Risk')}</ModalHeader>

				{/* body */}
				<ModalBody>
					<AvForm className='needs-validation'>
						<Row>
							<Label>{props.t('Are you sure?')}</Label>
						</Row>

						<ModalFooter>
							<Button
								color='danger'
								className='waves-effect waves-light'
								type='submit'
								onClick={() => {
									props.acceptDeletion(props.riskId);
								}}
							>
								{props.t('Delete').toUpperCase()}
							</Button>
							<Button
								color='secondary'
								className='waves-effect waves-light'
								type='button'
								onClick={() => {
									props.close();
								}}
							>
								{props.t('Cancel').toUpperCase()}
							</Button>
						</ModalFooter>
					</AvForm>
				</ModalBody>
			</Modal>
		</>
	);
};

export default memo(DeletionRiskModal);
