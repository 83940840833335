import { memo, useEffect, useState } from "react";
import {
  Row,
  Col,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from "reactstrap";
import Select from "react-select";

const DeletionReasonModal = function (props) {
	DeletionReasonModal.displayName = 'Memorized Component - Deletion Reason Modal';
  if (process.env.NODE_ENV === 'development') console.log("---- rendering memorized component >>> DeletionReasonModal");

  const deletionReasonsList = [
    {
      value: "CASE_GREATER_THAN_5_YEARS_CLOSED",
      label: props.t("Case >= 5 years closed"),
    },
    {
      value: "CASE_GREATER_THAN_10_YEARS_CLOSED",
      label: props.t("Case >= 10 years closed"),
    },
    {
      value: "OTHER_REASON",
      label: props.t("Other ..."),
    },
  ];
  const [deletionReason, setDeletionReason] = useState(null);
  const [deletionReasonString, setDeletionReasonString] = useState("");
  const [invalidDeletionReason, setInvalidDeletionReason] = useState(false);

  /**
   * this method resets the modal.
   */
  const handleResetForm = () => {
    setDeletionReason(null);
    setDeletionReasonString("");
    setInvalidDeletionReason(false);
  };

  /**
   * this method sets the case to pendind deletion.
   * @param {String} caseId the ID of the case you want to set to pending deletion.
   * @param {String} deletionReason selected deletion reason.
   * @param {String} deletionReasonString custom deletion reason string.
   * @param {String} token authorization token.
   * @returns {Boolean} returns false if validation failed.
   */
  const handleSubmit = async (
    caseId,
    deletionReason,
    deletionReasonString,
    token
  ) => {
    if (
      !deletionReason ||
      (deletionReason === "OTHER_REASON" && !deletionReasonString)
    ) {
      setInvalidDeletionReason(true);
      return false;
    } else {
      await props.handleSetPendingDeletionStatus(
        caseId,
        deletionReason === "OTHER_REASON"
          ? deletionReasonString
          : deletionReason,
        token
      );
    }
  };

  useEffect(() => {
    if (!props.isOpen) handleResetForm();
  }, [props.isOpen]);

  return (
    <Modal
      isOpen={props.isOpen}
      toggle={props.handleToggleDeletionReasonModal}
      backdrop="static"
    >
      {/* header */}
      <ModalHeader toggle={() => props.handleToggleDeletionReasonModal()}>
        {props.t("Delete Case")}
      </ModalHeader>

      {/* body */}
      <ModalBody>
        <Row>
          {/* description */}
          <Col sm="12">
            <p>
              {props.t(
                "You can mark a case for deletion. An Analyst Admin can confirm the deletion afterwards"
              )}
            </p>
          </Col>

          {/* deletion reason select list */}
          <Col sm="12">
            <Label className="form-label mb-0 text-dark">
              {props.t("Reason for deletion")} *
            </Label>
            <Select
              classNamePrefix="select2-selection"
              options={deletionReasonsList}
              value={deletionReason}
              onChange={(reason) => setDeletionReason(reason)}
              isDisabled={props.isDeleted}
              placeholder={props.t("Select")}
            />
          </Col>

          {/* deletion reason string */}
          {deletionReason?.value === "OTHER_REASON" ? (
            <Col sm="12 mt-2">
              <Input
                onChange={(e) => setDeletionReasonString(e.target.value)}
                type="text"
                value={deletionReasonString}
                placeholder={props.t("Enter your reason here")}
              />

              {/* validation message */}
              {invalidDeletionReason ? (
                <div className="text-danger">
                  {props.t("This field cannot be blank")}
                </div>
              ) : null}
            </Col>
          ) : null}
        </Row>

        {/* footer */}
        <ModalFooter>
          {/* cancel button */}
          <Button
            type="button"
            color="light"
            onClick={() => props.handleToggleDeletionReasonModal()}
          >
            {props.t("Cancel")}
          </Button>

          {/* submit button */}
          <Button
            type="button"
            onClick={() =>
              handleSubmit(
                props.reportId,
                deletionReason.value,
                deletionReasonString,
                props.authToken
              )
            }
            color="danger"
          >
            {props.t("Yes")}
          </Button>
        </ModalFooter>
      </ModalBody>
    </Modal>
  );
};

export default memo(DeletionReasonModal);
