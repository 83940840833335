import { withNamespaces } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import { locals } from "./locals";
import { useEffect, useState } from "react";

const LocalizedPhoneInput = (props) => {
    const [lngChanged, setLngChanged] = useState(false);

    useEffect(() => {
        // this is for PhoneInput component bug
        setLngChanged(true);
        setTimeout(() => {
            setLngChanged(false);
        }, 100);
    }, [ props.t ])

    return (
        !lngChanged && (
            <>
                {props.readOnly ? (
                    <span className="read-only-phone-input">
                        <PhoneInput
                            {...props}
                            disabled={true}
                            disableDropdown={true}
                            localization={locals[props.lng]}
                        />
                    </span>
                ) : (
                    <PhoneInput
                        {...props}
                        localization={locals[props.lng]}
                    />
                )}
            </>
        )
    )
};

export default withNamespaces()(LocalizedPhoneInput);