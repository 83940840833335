import { takeEvery, all, fork, call, put } from "redux-saga/effects";

import {
    RELOAD_ORGANIZATION_CURRENT_MODULE_SETTINGS,
    SAVE_ORGANIZATION_ACTIVE_MODULE_ON_REMOTE,
    SET_ORGANIZATION_ACTIVE_MODULE
} from './actionTypes';

import {
    fetch as fetchDefaultLanguageTranslations
} from "../../i18n";

import { setModuleFeatures, setOrganizationActiveModule, onGetInfoOrganizationSuccess, emptyNotificationsList, changeOrganizationActiveModuleOnRedux, setModuleLoadingStatus, appError, errorNotification, setFeaturesLoadingStatus } from "../actions";

import store from "../";

import {
    AUTH_API_DOMAIN_URL,
    MODULES_FEATURES_ROUTES,
    MODULES_SETTINGS_ROUTES
} from "../../common/constants";

import axios from "axios";

const sendSaveModuleRequest = (module) => {
    const { token } = store.getState().Login;

    if(!token) return true;

    return axios.post(
        `${AUTH_API_DOMAIN_URL}module.save`,
        {
            module: module,
        },
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        },
    );
}

const loadModuleFeaturesFromServer = (module) => {
    const { token } = store.getState().Login;
    const { id } = store.getState().Organization;

    const headers = {};

    if(token){
        headers.Authorization = `Bearer ${token}`;
    }

    return axios.post(
        MODULES_FEATURES_ROUTES[module],
        {
            orgId       :   id,
        },
        {
            headers
        },
    );
}

const loadModuleSettingsFromServer = (module) => {
    if(!MODULES_SETTINGS_ROUTES[module]) return false;
    const { token } = store.getState().Login;
    const { id, url } = store.getState().Organization;

    const headers = {};

    if(token){
        headers.Authorization = `Bearer ${token}`;
    }

    return axios.post(
        MODULES_SETTINGS_ROUTES[module],
        {
            id  :   id,
            url :   url
        },
        {
            headers
        },
    );
}

const applyModuleTheme = ({
    font_family,
    font_color,
    primary_color,
    primary_logo,
    primary_logo_size,
    secondary_logo,
    secondary_logo_size,
    signin_picture
}) => {
    document.documentElement.style.setProperty('--color-body', font_color || '#ffffff');
    document.documentElement.style.setProperty('--color-primary', primary_color || '#5664d2');

    if(primary_logo_size){
        document.documentElement.style.setProperty('--navbar-primary-brand-size', `${primary_logo_size}px`);
    }

    if(secondary_logo_size){
        document.documentElement.style.setProperty('--navbar-secondary-brand-size', `${secondary_logo_size}px`);
    }
    
    document.documentElement.style.setProperty('--primary-font', font_family || '"Inter", sans-serif');

    if(signin_picture){
        document.documentElement.style.setProperty('--auth-background', `url("${signin_picture}")`);
    }
}

const setOrganizationActiveModuleToStorage = (selectedModule) => {
	try {
        localStorage.setItem("module", selectedModule);

        document.documentElement.setAttribute('data-module', selectedModule)
	} catch (error) {}
}

function* saveModuleOnRemote({
    payload: module
}){
    try{
        yield 1;
        yield put(setModuleLoadingStatus({
            status  :   true
        }));
        yield call(sendSaveModuleRequest, module);
        yield put(setOrganizationActiveModule(module));
    } catch(error) {

        yield put(setModuleLoadingStatus({
            status  :   false
        }));
        
        yield(put(errorNotification({
            message :   "You don't have permission to access this module"
        })));

    }
}

function* reloadModuleSettings(){
    try{
        yield 1;
        const { active: activeModule } = store.getState().Modules;
        const {
            data    :   {
                data    :   organizationInfo
            }
        } = yield call(loadModuleSettingsFromServer, activeModule);
            
        yield put(onGetInfoOrganizationSuccess(organizationInfo));

        yield call(applyModuleTheme, organizationInfo)

    } catch(error) {}
}

function* startChangingActiveModuleOnTheLocal({
    payload: module
}){
    yield call(setOrganizationActiveModuleToStorage, module);

    try{
        yield put(setFeaturesLoadingStatus({
            status  :   'loading'
        }));

        const {
            data    :   {
                data    :   moduleFeatures
            }
        } = yield call(loadModuleFeaturesFromServer, module);

        yield put(setModuleFeatures({
            module : module,
            features: moduleFeatures
        }));

        yield put(setFeaturesLoadingStatus({
            status  :   'loaded'
        }));
    }
    catch(error){
        yield put(setFeaturesLoadingStatus({
            status  :   'failed'
        }));
    }

    try{
        const {
            data    :   {
                data    :   organizationInfo
            }
        } = yield call(loadModuleSettingsFromServer, module);

        yield put(emptyNotificationsList());
            
        yield put(onGetInfoOrganizationSuccess(organizationInfo));

        yield call(applyModuleTheme, organizationInfo);

        yield put(changeOrganizationActiveModuleOnRedux(module));

        const urlDefaultLang = (new URLSearchParams(window.location.search)).get('lang')

        if(urlDefaultLang){
            yield call(fetchDefaultLanguageTranslations, urlDefaultLang);
        }
        else{
            yield call(fetchDefaultLanguageTranslations);
        }
    } catch(error) {}

    yield put(setModuleLoadingStatus({
        status  :   false
    }))
}

function* watchSaveModuleOnRemote(){
    yield takeEvery(SAVE_ORGANIZATION_ACTIVE_MODULE_ON_REMOTE, saveModuleOnRemote);
}

function* watchActiveModuleChange(){
    yield takeEvery(SET_ORGANIZATION_ACTIVE_MODULE, startChangingActiveModuleOnTheLocal);

    yield takeEvery(RELOAD_ORGANIZATION_CURRENT_MODULE_SETTINGS, reloadModuleSettings);
}

function* modulesSaga() {
    yield all([
        fork(watchSaveModuleOnRemote),
        fork(watchActiveModuleChange)
    ]);
}

export default modulesSaga;