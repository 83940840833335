import axios from "axios";

import i18n from "i18next";

import { errorNotification } from "../store/actions";

import store from "../store";

const translateError = (data) => {
  let message =
    typeof data.message === "object"
      ? i18n.t(Object.entries(data.message[Object.keys(data.message)[0]])[0][1])
      : i18n.t(data.message);

  // i18n.t(data.message[Object.keys(data.message)[0]])
  // : i18n.t(data.message);

  // console.log(message);

  switch (data.error) {
    case "invalid_authentication":
      return i18n.t("Your email and password combination is not correct");
    case "invalid_authorization":
      return i18n.t("Your login session was expired. Please login again.");
    case "invalid_otp":
      return i18n.t("Invalid OTP");
    case "authentication_attempts":
      return i18n.t(
        "You have entered the wrong password 3 times, please try again in 10 minutes"
      );
    // return i18n.t(
    //   "You’ve entered the wrong password 3 times, please try again in {{minute}} minutes",
    //   {
    //     minute: 10,
    //   }
    // );
    case "user_does_not_exist":
      return i18n.t("User does not exist");
    case "unknown_error":
      return message;
    default:
      return message;
  }
};

export const post = (url, data, header) => {
  return new Promise(async (res, rej) => {
    await axios
      .post(url, data, header)
      .then((response) => {
        const data = response.data;
        if (data.ok === false) {
          if (data.error) {
            if (data.message["00"].email) {
              store.dispatch(
                errorNotification({
                  code: "invalid_otp",
                  message: data.message["00"].email,
                })
              );

              console.log(data.message["00"].email);
            }
          } else {
            store.dispatch(
              errorNotification({
                code: "invalid_otp",
                message: translateError("invalid_otp"),
              })
            );
            rej("invalid_otp");
          }
        } else {
          res(response);
        }
      })
      .catch((err) => {
        if (err.response) {
          const data = err.response.data;

          store.dispatch(
            errorNotification({
              code: data.error,
              message: translateError(data),
            })
          );

          rej(data.error);
        }
        rej("fatal_error");
      });
  });
};

export const get = (url, header) => {
  return axios.get(url, header).catch((err) => {
    console.log(err);
  });
};
