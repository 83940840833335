import { ERROR_NOTIFICATION, SUCCESS_NOTIFICATION } from "./actionTypes";

const initialState = {
  message: "",
  error: "",
  ok: "",
};

export const SystemNotification = (state = initialState, action) => {
  switch (action.type) {
    case ERROR_NOTIFICATION:
      return {
        ...state,
        message: action.payload.message,
        ok: false,
      };
    case SUCCESS_NOTIFICATION:
      return {
        ...state,
        message: action.payload.message,
        ok: true,
      };
    default:
      return state;
  }
};
