import React from 'react'
import HistoryLog from '../../../../components/history-log'
export default function index({supplierId}) {
    return (
        <HistoryLog 
            supplierId={supplierId} 
            historyUrl={`/supplier/${supplierId}/logs`} 
            isDownloadButton={false}
        />
    )
}
