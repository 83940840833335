export const TRANSLATION_KEY_FEATURE = 'FEATURE.';
export const TRANSLATION_KEY_SIGN_IN = TRANSLATION_KEY_FEATURE + 'SIGN_IN.';
export const TRANSLATION_KEY_SIGN_IN_INFO = TRANSLATION_KEY_SIGN_IN + 'INFO.';
export const TRANSLATION_KEY_SIGN_IN_NOTIFICATION = TRANSLATION_KEY_SIGN_IN + 'NOTIFICATION.';
export const TRANSLATION_KEY_SIGN_IN_BUTTONS = TRANSLATION_KEY_SIGN_IN + 'UI.BUTTONS.';

export const TRANSLATION_KEY_HEADER = TRANSLATION_KEY_FEATURE + 'HEADER.';
export const TRANSLATION_KEY_HEADER_TABS = TRANSLATION_KEY_HEADER + 'UI.TABS.';

export const TRANSLATION_KEY_HOME = TRANSLATION_KEY_FEATURE + 'HOME.';
export const TRANSLATION_KEY_HOME_INFO = TRANSLATION_KEY_HOME + 'INFO.';
export const TRANSLATION_KEY_HOME_NOTIFICATION = TRANSLATION_KEY_HOME + 'NOTIFICATION.';
export const TRANSLATION_KEY_HOME_TABS = TRANSLATION_KEY_HOME + 'UI.TABS.';
export const TRANSLATION_KEY_HOME_BUTTONS = TRANSLATION_KEY_HOME + 'UI.BUTTONS.';

export const TRANSLATION_KEY_SIDEBAR = TRANSLATION_KEY_FEATURE + 'SIDEBAR.';
export const TRANSLATION_KEY_SIDEBAR_TABS = TRANSLATION_KEY_SIDEBAR + 'UI.TABS.';

export const TRANSLATION_KEY_GLOBAL = 'GLOBAL.';
export const TRANSLATION_KEY_GLOBAL_INFO = TRANSLATION_KEY_GLOBAL + 'INFO.';
export const TRANSLATION_KEY_GLOBAL_BUTTONS = TRANSLATION_KEY_GLOBAL + 'UI.BUTTONS.';
export const TRANSLATION_KEY_GLOBAL_NOTIFICATION = TRANSLATION_KEY_GLOBAL + 'NOTIFICATION.';
export const TRANSLATION_KEY_GLOBAL_PLACEHOLDER = TRANSLATION_KEY_GLOBAL + 'UI.PLACEHOLDER.';

export const TRANSLATION_KEY_REPORT = TRANSLATION_KEY_FEATURE + 'REPORT.';
export const TRANSLATION_KEY_REPORT_INFO = TRANSLATION_KEY_REPORT + 'INFO.';
export const TRANSLATION_KEY_REPORT_NOTIFICATION = TRANSLATION_KEY_REPORT + 'NOTIFICATION.';
export const TRANSLATION_KEY_REPORT_BUTTONS = TRANSLATION_KEY_REPORT + 'UI.BUTTONS.';

export const TRANSLATION_KEY_COMMENT = TRANSLATION_KEY_FEATURE + 'COMMENT.';
export const TRANSLATION_KEY_COMMENT_INFO = TRANSLATION_KEY_COMMENT + 'INFO.';
export const TRANSLATION_KEY_COMMENT_NOTIFICATION = TRANSLATION_KEY_COMMENT + 'NOTIFICATION.';

export const TRANSLATION_KEY_USER = TRANSLATION_KEY_FEATURE + 'USER.';
export const TRANSLATION_KEY_USER_INFO = TRANSLATION_KEY_USER + 'INFO.';
export const TRANSLATION_KEY_USER_NOTIFICATION = TRANSLATION_KEY_USER + 'NOTIFICATION.';
export const TRANSLATION_KEY_USER_BUTTONS = TRANSLATION_KEY_USER + 'UI.BUTTONS.';

export const TRANSLATION_KEY_CHATBOX = TRANSLATION_KEY_FEATURE + 'CHATBOX.';
export const TRANSLATION_KEY_CHATBOX_INFO = TRANSLATION_KEY_CHATBOX + 'INFO.';

export const TRANSLATION_KEY_NOTIFY = TRANSLATION_KEY_FEATURE + 'NOTIFY.';
export const TRANSLATION_KEY_NOTIFY_INFO = TRANSLATION_KEY_NOTIFY + 'INFO.';
export const TRANSLATION_KEY_NOTIFY_NOTIFICATION = TRANSLATION_KEY_NOTIFY + 'NOTIFICATION.';
