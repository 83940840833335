import React, { Component } from "react";
import { Pie } from "react-chartjs-2";

class PieChart extends Component {
	constructor(props) {
		super(props);

		this.state = {
			labels: [],
			datasets: [
				{
					data: [],
					backgroundColor: ["#34c38f", "#ebeff2", "#EC407A", "#7E57C2", "#2196F3", "#00ACC1", "#7CB342", "#FDD835", "#FB8C00", "#F4511E", "#6D4C41"],
				},
			],
		};
	}

	render() {
		return (
			<React.Fragment>
				<Pie width={474} height={260} data={this.state} />
			</React.Fragment>
		);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.data !== this.props.data) {
			const data = this.props.data;
			const series = [];
			const labels = [];
			const categories = [];

			console.log("* ", data);

			for (const item in data) {
				series.push(data[item].amount);
				labels.push(data[item].title);
				// categories.push({ title: data[item].title, amount: data[item].amount, percent: data[item].percent });
			}

			this.setState({
				labels,
				datasets: [
					{
						data: series,
					},
				],
			});
		}
	}
}

export default PieChart;
