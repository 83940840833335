import { withNamespaces } from "react-i18next";
import { useEffect, useState } from "react";
import { Button, Col, Label, Row, Modal, ModalHeader, ModalBody, ModalFooter, Alert } from "reactstrap";
import { connect } from 'react-redux';

import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import Spinner from 'react-bootstrap/Spinner';

import SupplierContactPersonService from "src/modules/3rd-party-management/apis/SupplierContactPersonService";
import LocalizedPhoneInput from "src/modules/3rd-party-management/components/LocalizedPhoneInput";
import { EMPTY_LIST } from "src/common/constants";
import CreationModal from "./components/CreationModal";

const ContactPeople = ({
    t,
    supplierId,
    supplierType,
    Organization
}) => {
    const [ people, setPeople ] = useState([]);
    const [ personToDelete, setPersonToDelete ] = useState(null);

    const [ displayModal, setDisplayModal ] = useState(false);

    const handleFetchPeopleList = useQuery({
		queryKey: [
            '3rd-party-management-fetch-supplier-contact-people-list',
            supplierId
        ],
		queryFn: async () => {
			const service = SupplierContactPersonService.getInstance();

            return await service.list(supplierId, {});
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			toast(t('An error occurred while fetching contact people list.'), {
				type: 'error',
			});
		},
	});

    const handleDeletePersonMutation = useMutation({
        mutationFn: async ({
            id
        }) => {
            const service = SupplierContactPersonService.getInstance();

            return await service.delete(supplierId, id);
        },
        onSuccess: (response, person) => {
            setPeople((currentList) => {
                return currentList.filter((per) => per.id !== person.id)
            });

            toast(t("Contact person deleted successfully."), {
                type: "success",
            });
        },
        onError: () => {
            toast(t("An error occurred while deleting contact person."), {
                type: "error",
            });
        },
        onSettled: () => {
            setPersonToDelete(null);
        }
    });

    useEffect(() => {
        setPeople(handleFetchPeopleList?.data?.contactPersons || []);
    }, [ handleFetchPeopleList.data ]);

    const getLanguageName = (id) => ((Organization?.languages || []).find((lng) => lng?.language?.id === id))?.name

    const renderDeletePersonConfirmModal = () => {
        const deleteInProcess = handleDeletePersonMutation.isLoading || handleDeletePersonMutation.isFetching;

        return (
            <Modal isOpen={ !!personToDelete } backdrop="static" size="md">
                
                <ModalHeader toggle={() => setPersonToDelete(null)}>
                    {t("Confirmation Form")}
                </ModalHeader>

                <ModalBody>
                    <p>
                        {t('Do you want to delete the person?')}
                    </p>
                </ModalBody>

                <ModalFooter>
                    <Button onClick={() => handleDeletePersonMutation.mutate(personToDelete)} disabled={ deleteInProcess } color="danger" type="button" size="md">
                        {
                            deleteInProcess && (
                                <Spinner className="me-2" animation="border" variant="white" size="sm"/>
                            )
                        }
                        { t('Delete') }
                    </Button>

                    <Button onClick={() => setPersonToDelete(null)} disabled={ deleteInProcess } color="secondary" type="button" outline>
                        { t('Cancel') }
                    </Button>
                </ModalFooter>
            </Modal>
        ) 
    }

    return (
        <div className="contact-people pt-0">
            <div className="list">
                {
                    !people.length ? (
                        <Alert color='warning'>
                            {t(EMPTY_LIST)}
                        </Alert>
                    ) : (
                        <>
                            {people.map((person) => {
                                return (
                                    <div className="item mb-3">
                                        <Row className="mb-2">
                                            <Col sm="12" md="4">
                                                <Label className="form-label">
                                                    {t('Full Name')}:
                                                </Label>
                                                <p>
                                                    { `${t(person.title)} ${person.name} ${person.lastName || ''}` }
                                                </p>
                                            </Col>

                                            <Col sm="12" md="4">
                                                <Label className="form-label">
                                                    {t('Position')}:
                                                </Label>
                                                <p>
                                                    { person.position }
                                                </p>
                                            </Col>

                                            <Col sm="12" md="4">
                                                <Label className="form-label">
                                                    {t('Language')}:
                                                </Label>
                                                <p>
                                                    { t(getLanguageName(person.language)) }
                                                </p>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col sm="12" md="4">
                                                <Label className="form-label">
                                                    {t('Email')}:
                                                </Label>
                                                <p>
                                                    { person.email }
                                                </p>
                                            </Col>

                                            <Col sm="12" md="4">
                                                <Label className="form-label">
                                                    {t('Phone Number')}:
                                                </Label>
                                                <LocalizedPhoneInput
                                                    readOnly
                                                    inputClass="w-100"
                                                    country={ "us" }
                                                    disableInitialCountryGuess={ false }
                                                    disableCountryGuess={ false }
                                                    value={person.phoneNumber}
                                                />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col sm="12" className="d-flex justify-content-end">
                                                <Button disabled={person.deletionInProcess} onClick={() => {
                                                    setPersonToDelete(person)
                                                }} color="danger" type="button" outline>
                                                    {t(supplierType === 'own' ? 'Delete Person' : 'Delete Contact')}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                )
                            })}
                        </>
                    )
                }
            </div>

            <Button onClick={() => { setDisplayModal(true) }} 
                color="primary" 
                disabled={ false } 
                outline>
                    <i className="ri-add-line align-middle me-2 font-size-20"></i>
                    {t(supplierType === 'own' ? 'Add A Responsible Person' : 'Add A Contact Person')}
            </Button>

            <CreationModal 
                supplierType={supplierType}
                supplierId={supplierId}
                displayModal={displayModal}
                toggleModal={setDisplayModal}
                onCeationSucceed={() => handleFetchPeopleList.refetch()}
            />

            { renderDeletePersonConfirmModal() }
        </div>
    );
}

const mapStatetoProps = (state) => {
	const { Organization } = state;

	return {
		Organization
	};
};

export default withNamespaces()(connect(mapStatetoProps, null)(ContactPeople));