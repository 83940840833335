import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { Button, Tooltip, Row, Col, Label, Input } from "reactstrap";
import { toast } from "react-toastify";
import { useQuery, useMutation } from "@tanstack/react-query";
import MultiSelect from "src/modules/data-protection/components/MultiSelect";
import AdminstrationSettingsService from "../api/adminstrationSettingsService";
import { FaInfoCircle } from "react-icons/fa";
import UserUtils from "src/services/utils/UserUtils";

const NewsMonitoring = (props) => {
  const { t, organization, currentUser } = props;
  const [tags, setTags] = useState([]);
  const [newTagInput, setNewTagInput] = useState("");
  const inputRef = useRef(null);
  const userUtils = new UserUtils();
  const isAdmin =userUtils.isAnalystAdmin(currentUser);

  const [countriesOptions, setCountriesOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);


  // Tooltip
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  const organizationLanguages = organization?.languages.map((lang) => {
    return {
      id: lang.id,
      title: lang.name,
      value: lang.language_code,
      direction: lang.direction,
      isDefault: lang.is_default,
    };
  });

  const service = AdminstrationSettingsService.getInstance();

  const handleFetchNewsSettings = useQuery({
    queryKey: ["fetch-news-monitoring"],
    queryFn: async () => {
      const service = AdminstrationSettingsService.getInstance();

      return await service.FetchNewsSettings();
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      toast(props.t("An error occurred while fetching news monitoring."), {
        type: "error",
      });
    },
  });

  const handleFetchLocation = useQuery({
    queryKey: ["fetch-location-monitoring"],
    queryFn: async () => {
      const service = AdminstrationSettingsService.getInstance();

      return await service.FetchLocation();
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      toast(props.t("An error occurred while fetching Location."), {
        type: "error",
      });
    },
  });

  // Helper function to transform objects into arrays with id and title fields
  const transformToIdTitleArray = (obj, keyName) => {
    return Object.values(obj || {}).map((item) => ({
      id: item.id,
      title: item[keyName],
    }));
  };

  useEffect(() => {

    if (handleFetchNewsSettings.data) {
      const newsLanguages = transformToIdTitleArray(
        handleFetchNewsSettings.data?.news_languages,
        "name"
      );
      const newsCountries = transformToIdTitleArray(
        handleFetchNewsSettings.data?.news_countries,
        "name"
      );
  
      setSelectedLanguages(newsLanguages);
      setSelectedCountries(newsCountries);
      const returnTags = handleFetchNewsSettings.data?.tags?.map(({ id, ...rest }) => {
        return {title: rest.title, isDefault: rest?.is_default }
      }) ?? [];
      setTags(returnTags);
      setLoading(false);
    }
  }, [handleFetchNewsSettings.data]);

  useEffect(() => {
    if (handleFetchLocation.data) {
      const combinedArray = handleFetchLocation.data?.data.flat();
      const countries = transformToIdTitleArray(combinedArray, "name");
      setCountriesOptions(countries);
    }
  }, [handleFetchLocation.data]);

  const editNewsSettingsMutation = useMutation({
    mutationFn: async (payload) => {
      return await service.EditNewsSettings(payload);
    },
    onError: () => {
      toast(t("An error occurred while saving settings."), {
        type: "error",
      });
    },
    onSuccess: () => {
      toast(t("Settings saved successfully."), {
        type: "success",
      });
    },
  });

  const restoreNewsSettingsMutation = useMutation({
    mutationFn: async () => {
      return await service.RestorNewsSettings();
    },
    onError: () => {
      toast(t("An error occurred while restoring settings."), {
        type: "error",
      });
    },
    onSuccess: (data) => {
      const selectedLanguages = transformToIdTitleArray(
        data?.news_languages,
        "name"
      );
      const selectedCountries = transformToIdTitleArray(
        data?.news_countries,
        "name"
      );
      const returnTags = Object.values(data.tags).map(({ id, title, is_default }) => ({
        id,
        title,
        isDefault: is_default
      })) ?? [];
      setSelectedLanguages(selectedLanguages);
      setSelectedCountries(selectedCountries);
      setTags(returnTags);
      toast(t("Settings restored successfully."), {
        type: "success",
      });
    },
  });

  const handleSave = () => {
    if (selectedLanguages.length === 0) {
      toast.error("Please select at least one language.");
      return;
    }
    if (selectedCountries.length === 0) {
      toast.error("Please select at least one country.");
      return;
    }

    const payload = {
      newsLanguages: selectedLanguages.map((item) => item.id.toString()),
      newsCountries: selectedCountries.map((item) => item.id.toString()),
      tags: tags.map((item) => item.title.toString()),
    };
    editNewsSettingsMutation.mutate(payload);
  };

  const handleRestore = () => {
    restoreNewsSettingsMutation.mutate();
  };

  const handleMultiSelectChange = (setter) => (newSelectedValues) => {
    setter(newSelectedValues);
  };

  // Add a new tag
  const addNewTag = () => {
    const newTag = newTagInput.trim();
    if (
      newTag &&
      !tags.find((tag) => tag?.title?.toLowerCase() === newTag.toLowerCase())
    ) {
      setTags([...tags, { title: newTag, isDefault: false }]);
      setNewTagInput(""); // Clear the input field
      inputRef.current.focus(); // Set focus back on the input
    }
  };

  const removeTag = (index) => {
    const updatedTags = [...tags];
    updatedTags.splice(index, 1);
    setTags(updatedTags);
  };

  // Handle Enter key press
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      addNewTag();
    }
  };

  if (loading) {
    return (
      <div
        className="dt-field dt-skeleton"
        style={{ width: "100%", height: "320px" }}
      ></div>
    );
  }

  return (
    <div className="p-4 settings">
      <Row className="w-100">
        <Col sm="12" md="6" lg="6" className="p-2">
          <Label>{t("Monitoring News period")}</Label>
          <div style={{ color: "#212529", fontSize: "16px" }}>
            {t("Weekly")}
          </div>
        </Col>
        <Col sm="12" md="6" lg="6" className="p-2">
          <div style={{ maxWidth: "625px" }}>
            <Label>{t("Countries Of The News")}</Label>
            <MultiSelect
              options={countriesOptions}
              selectedValues={selectedCountries}
              onChange={handleMultiSelectChange(setSelectedCountries)}
              placeholder="Select..."
              isDisabled={!isAdmin}
            />
          </div>
        </Col>
      </Row>
      <Row className="w-100 mt-5 mb-2">
      <Col sm="12" md="6" lg="6" className="p-2">
          <div style={{ maxWidth: "625px" , marginRight: "20px"}}>
            <Label>
              {t("Keywords")}{" "}
              <Button
                color="link"
                id="infoButton"
                style={{ border: "none", padding: 0, marginBottom: "5px" }}
              >
                <FaInfoCircle />
              </Button>{" "}
              <Tooltip
                placement="right"
                isOpen={tooltipOpen}
                target="infoButton"
                toggle={toggleTooltip}
              >
                {t(
                  "Default keywords are pre-set and cannot be edited or removed. Each additional custom keyword you add will deduct from your available search volume. Duplicate keywords are automatically removed to conserve search volume."
                )}
              </Tooltip>
            </Label>
            {/* Add New Tag */}
            <Input
              type="text"
              value={newTagInput}
              onChange={(e) => setNewTagInput(e.target.value)}
              onKeyPress={handleKeyPress}
              placeholder={t("Enter new tag")}
              ref={inputRef} // Attach ref to the Input
              disabled={!isAdmin}
            />

            {/* Text Area */}
            <div contentEditable="true" id="keywords" style={{marginTop:'5px'}} name="keywords">
              {tags.length
                ? tags.map((tag, index) => (
                    <div className="tag" key={index}>
                      {t(tag?.title)}
                      <span
                        className="tag-close"
                        hidden={tag?.isDefault || !isAdmin}
                        onClick={() => {
                          removeTag(index);
                        }}
                      >
                        &times;
                      </span>
                    </div>
                  ))
                : null}
            </div>
          </div>
        </Col>
       
        <Col sm="12" md="6" lg="6" className="p-2">
          <div style={{ maxWidth: "625px" }}>
            <Label>{t("Language Of The News")}</Label>
            <MultiSelect
              options={organizationLanguages}
              selectedValues={selectedLanguages}
              onChange={handleMultiSelectChange(setSelectedLanguages)}
              placeholder="Select..."
              isDisabled={!isAdmin}
            />
          </div>
        </Col>
      </Row>
      <Row className="w-100">
        <div className="d-flex gap-3 mt-5 justify-content-end">
          <button className="restoreBtn" onClick={handleRestore} style={{cursor :`${isAdmin ? "pointer" : "not-allowed"}`}} disabled={!isAdmin}>
            {t("Restore")}
          </button>
          <button className="saveBtn" onClick={handleSave} style={{cursor :`${isAdmin ? "pointer" : "not-allowed"}`}} disabled={!isAdmin}>
            {t("Save")}
          </button>
        </div>
      </Row>
    </div>
  );
};

const mapStatetoProps = (state) => {
  const { token, user: currentUser } = state.Login;
  const { Organization } = state;
  return {
    currentUser,
    token,
    organization: Organization,
  };
};

export default withNamespaces()(connect(mapStatetoProps)(NewsMonitoring));
