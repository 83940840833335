import React from 'react';
import { Svg, Path, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  checkbox: {
    width: 12,
    height: 12,
    borderWidth: 1,
    borderColor: '#000', 
    display: 'inline-block',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 5,
    marginLeft: 5,
    marginBottom: 5,
    backgroundColor: '#FFF', 
  },
  checked: {
    backgroundColor: '#000', 
    width: 12,
    height: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  xMark: {
    width: 10,
    height: 10,
  },
  xPath: {
    stroke: '#FFF', 
    strokeWidth: 2,
  },
});

const Checkbox = ({ isChecked }) => (
  <View style={[styles.checkbox, isChecked && styles.checked]}>
    {isChecked && (
      <Svg viewBox="0 0 10 10" style={styles.xMark}>
        <Path d="M0 0 L10 10 M10 0 L0 10" style={styles.xPath} />
      </Svg>
    )}
  </View>
);

export default Checkbox;

